<template>
  <div class="pl-4 pr-4 pb-4">
    <div class="mb-4 flex justify-content-end align-items-center">
      <p @click="discardChanges" class="pointer task-opt mr-4 lt-txt-color hk-sb">
        Discard changes
      </p>
      <div>
        <Button
          :disabled="isSubmitDisabled()"
          label="Save changes"
          type="primary"
          @click="save"
          :loading="isBtnLoading"
        />
      </div>
    </div>

    <Spinner v-if="isLoading" class="flex justify-content-between" />

    <Card v-else class="box-shadow" :key="cardKey">
      <template #title>
        <h3 class="customer-name sec-color-txt hk-sb col-4">
          {{ formData.firstName }} {{ formData.middleName }}
          {{ formData.lastName }}
        </h3>
      </template>
      <template #content>
        <div
          class="flex cr-number align-items-center justify-content-between customer-name sec-color-txt hk-sb"
        >
          <div class="col-2">
            <p class="label">CR Number</p>

            <TextField
              :value="formData.crNumber || formData.crNumber"
              placeholder="Enter company name"
              @onInput="getFormData($event, 'crNumber')"
              width="100%"
            />
          </div>
          <div class="col-2">
            <p class="label">ACL Number</p>

            <TextField
              :value="formData.aclNumber || formData.aclNumber"
              placeholder="Enter company name"
              @onInput="getFormData($event, 'aclNumber')"
              width="100%"
            />
          </div>
          <div class="col-4">
            <p class="label">Company name</p>

            <TextField
              :value="formData.brokerGroupName || formData.businessName"
              placeholder="Enter company name"
              @onInput="getFormData($event, 'brokerGroupName')"
              width="100%"
            />
          </div>
          <div class="col-4 flex align-items-center">
            <div class="col">
              <p class="label s">Broker Fee<span style="color: red"> * </span></p>
              <NumberField
                :value="formData.brokerFee"
                placeholder="Fee"
                width="100%"
                @onInput="getFormData($event, 'brokerFee')"
                type="number"
                suffix=" %"
                :mode="'decimal'"
                :maxFractionDigits="2"
                :format="true"
                :key="brokerFeeKey"
              />
            </div>
            <div class="col">
              <p class="label">Status</p>
              <Dropdown
                :value="formData.status"
                placeholder="-select-"
                width="100%"
                optionLabel="name"
                optionValue="value"
                :items="status"
                @change="getFormData($event, 'status')"
              />
            </div>
          </div>
        </div>
        <div class="flex align-items-center">
          <div class="input-field mr-3">
            <p class="label">First name<span style="color: red"> * </span></p>
            <TextField
              :value="formData.firstName"
              @onInput="
                getFormData(
                  $event ? $event.charAt(0).toUpperCase() + $event.slice(1) : '',
                  'firstName'
                )
              "
              placeholder="Enter first name"
              width="100%"
            />
          </div>
          <div class="input-field mr-3">
            <p class="label">Middle name</p>
            <TextField
              @onInput="
                getFormData(
                  $event ? $event.charAt(0).toUpperCase() + $event.slice(1) : '',
                  'middleName'
                )
              "
              :value="formData.middleName"
              placeholder="Enter middle name"
              width="100%"
            />
          </div>
          <div class="input-field">
            <p class="label">Last name<span style="color: red"> * </span></p>
            <TextField
              :value="formData.lastName"
              @onInput="
                getFormData(
                  $event ? $event.charAt(0).toUpperCase() + $event.slice(1) : '',
                  'lastName'
                )
              "
              placeholder="Enter last name"
              width="100%"
            />
          </div>
        </div>
        <div class="mt-2 flex align-items-center">
          <div class="input-field mr-3">
            <p class="label">Date of birth<span style="color: red"> * </span></p>
            <DatePicker2
              :value="formatDate(formData.dob)"
              @change="setDOB"
              placeholder="DD/MM/YY"
              width="100%"
              :maxDate="maxDate"
              :maxYear="maxYear"
              minYear="1920"
            />
          </div>
          <div class="input-field mr-3 disable-input">
            <p class="label">Mobile</p>
            <TextField
              :value="formData.mobile"
              placeholder="Mobile number"
              type="number"
              width="100%"
              :disable="true"
              @onInput="getFormData($event, 'mobile')"
            />
          </div>
          <div class="input-field">
            <p class="label">Email<span style="color: red"> * </span></p>
            <TextField
              :value="formData.email"
              placeholder="Email address"
              width="100%"
              @onInput="getFormData($event, 'email')"
            />
          </div>
        </div>
        <div class="mt-2 flex align-items-center">
          <div class="input-field mr-3">
            <p class="label">Address line 1<span style="color: red"> * </span></p>
            <TextField
              :value="formData.add_1"
              placeholder="Address line 1"
              width="100%"
              @onInput="getFormData($event, 'add_1')"
            />
          </div>
          <div class="input-field mr-3">
            <p class="label">Address line 2</p>
            <TextField
              :value="formData.add_2"
              placeholder="Address line 2"
              width="100%"
              @onInput="getFormData($event, 'add_2')"
            />
          </div>
          <div class="input-field">
            <p class="label">City<span style="color: red"> * </span></p>
            <TextField
              @onInput="getFormData($event, 'city')"
              :value="formData.city"
              placeholder="City"
              width="100%"
            />
          </div>
        </div>
        <div class="mt-2 flex align-items-center">
          <div class="input-field mr-3">
            <p class="label">State<span style="color: red"> * </span></p>
            <TextField
              :value="formData.state"
              placeholder="State"
              width="100%"
              @onInput="getFormData($event, 'state')"
            />
          </div>
          <div class="input-field mr-3">
            <p>Postal code<span style="color: red"> * </span></p>
            <NumberField
              :value="formData.postal_code"
              placeholder="Postal code"
              width="100%"
              @onInput="getFormData($event, 'postal_code')"
              type="number"
              maxValue="4"
            />
          </div>
          <div class="input-field">
            <p class="label">Country<span style="color: red"> * </span></p>
            <Dropdown
              :value="formData.country"
              placeholder="-select-"
              width="100%"
              :items="['Australia']"
              @change="getFormData($event, 'country')"
            />
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "primevue/card";
import { required, email } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
import { _get, _post, _put } from "../../../../services/crudService";
import { SERVER_URL } from "../../../../../constants";
// import { checkMobileNumber } from "../../../../services/validatePhNo"

export default {
  components: { Card },
  data() {
    return {
      data: false,
      maxDate: null,
      maxYear: null,
      formData: {
        brokerGroupName: "",
        brokerFee: 0,
        status: "",
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        mobile: "",
        email: "",
        add_1: "",
        add_2: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
        crNumber: "",
        aclNumber: "",
      },
      status: [
        { name: "Active", value: "Active" },
        { name: "Inactive", value: "InActive" },
        { name: "Rejected", value: "Rejected" },
      ],
      cardKey: 0,
      isLoading: true,
      disableSave: true,
      isBtnLoading: false,
      refreshKey: 10,
      brokerFeeKey: 40,
    };
  },

  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        brokerFee: { required },
        status: { required },
        firstName: { required },
        lastName: { required },
        email: { required, email },
        mobile: { required },
      },
    };
  },

  watch: {
    async refreshKey() {
      await this.getBrokerDetails();
    },
  },

  methods: {
    isSubmitDisabled() {
      return this.v$.$invalid;
    },

    async discardChanges() {
      await this.getBrokerDetails();
    },

    async save() {
      try {
        if (!this.v$.$invalid) {
          this.isBtnLoading = true;
          let data = {
            brokerGroupName: this.formData.brokerGroupName,
            brokerFee: this.formData.brokerFee,
            firstName: this.formData.firstName,
            middleName: this.formData.middleName,
            lastName: this.formData.lastName,
            dob: this.formData.dob,
            mobile: this.formData.mobile,
            email: this.formData.email,
            add_1: this.formData.add_1,
            add_2: this.formData.add_2,
            city: this.formData.city,
            state: this.formData.state,
            postal_code: this.formData.postal_code,
            country: this.formData.country,
            crNumber: this.formData.crNumber,
            aclNumber: this.formData.aclNumber,
          };
          const response = await _put(
            SERVER_URL + "update_broker_crm?id=" + this.$route.params.id,
            data
          );
          await this.updateBrokerStatus();
          if (response) {
            this.isBtnLoading = false;
            this.refreshKey++;
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Broker details updated successfully",
              life: 3000,
            });
          }
        }
      } catch (err) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },

    formatDate(date) {
      if (date) {
        return moment(date).format("MM/DD/YYYY");
      }
    },

    setDOB(value) {
      this.formData["dob"] = value;
    },

    async getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      if (fieldName === "postal_code") {
        this.formData[fieldName] = parseInt(value);
      }

      this.formData = { ...this.formData };

      if (this.formData.brokerFee > 0.35) {
        // this.v$.$invalid = true;
        this.formData.brokerFee = 0.35;
        this.brokerFeeKey += 1;
      }
      // if (fieldName === "status") {
      //   await this.updateBrokerStatus();
      // }
    },

    async updateBrokerStatus() {
      try {
        await _post(SERVER_URL + "broker-status-update ", {
          brokerId: this.$route.params.id,
          status: this.formData.status,
        });

        // if (response) {
        //   this.$toast.add({
        //     severity: "success",
        //     summary: "Success",
        //     detail: "Broker status updated successfully",
        //     life: 3000,
        //   });
        // }
      } catch (err) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Broker status updation failed",

          life: 3000,
        });
      }
    },

    async getBrokerDetails() {
      try {
        this.isLoading = true;
        const response = await _get(
          SERVER_URL + "get_broker_crm_by_id?id=" + this.$route.params.id
        );

        if (response) {
          this.formData = response.data[0];

          if (!this.formData.brokerFee) {
            this.formData.brokerFee = 0.35;
          }
          this.isLoading = false;
        }
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },

  async created() {
    await this.getBrokerDetails();

    let eighteenYearsAgo = new Date();
    eighteenYearsAgo = eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
    this.maxDate = new Date(eighteenYearsAgo);
    this.maxYear = this.maxDate.getFullYear();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

:deep .p-card-body {
  padding: 30px !important;
}

:deep .p-card .p-card-title {
  font-weight: 400 !important;
}

:deep .col-4 {
  padding-left: 0 !important;
  margin-bottom: 0 !important;
}

.customer-name {
  font-size: 20px;
}

:deep .p-card-content {
  padding: 0 !important;
}

.pm-color {
  color: #2cdd80;
}

p {
  margin: 0;
  font-size: 1rem !important;
}

.label {
  font-family: Gordita-medium;
  color: $secondary-color;
  margin-bottom: 5px;
}

.input-field {
  width: 100% !important;
}
.cr-number {
  margin-left: -10px;
  margin-right: -12px;
}
</style>
