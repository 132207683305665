export default {
  namespaced: true,
  state: {
    summaryData: {},
    settlementData: {
      legal_fees: 0,
      legal_disbursements: 0,
      pexa_fees: 0,
      land_titles_office_reg_fee: 0,
      settlement_fees: 0,
    },
    fundsToComplete: {},
    mortgagePayout: {},
    netSales: {},
    endDebtData: {},
    ratesData: {},
    repaymentData: {},
    serviciability: {
      end_debt_6_months: {
        interest_rate: 0.0649,
        buffer: 0,
        selected: true,
      },
      end_debt_12_months: {
        interest_rate: 0.0649,
        selected: false,
        buffer: 0.00,
      },
      peak_debt_12_months: {
        interest_rate: 0.0649,
        buffer: 0.01,
        selected: false,
      },
      end_debt_12_months_affordability: {
        interest_rate: 0.0649,
        buffer: 0.00,
        selected: false,
      },
    },
  },
  getters: {
    summaryData: ({ summaryData }) => summaryData,
    settlementData: ({ settlementData }) => settlementData,
    fundsToComplete: ({ fundsToComplete }) => fundsToComplete,
    mortgagePayout: ({ mortgagePayout }) => mortgagePayout,
    netSales: ({ netSales }) => netSales,
    endDebtData: ({ endDebtData }) => endDebtData,
    ratesData: ({ ratesData }) => ratesData,
    repaymentData: ({ repaymentData }) => repaymentData,
    serviciability: ({ serviciability }) => serviciability,
  },
  mutations: {
    setEndDebtData(storeState, payload) {
      storeState.endDebtData = payload;
    },
    setSettlementData(storeState, payload) {
      storeState.settlementData[payload.field] = payload.value;
    },
    setSummaryData(storeState, payload) {
      storeState.summaryData = payload;
    },
    setServiciability(storeState, payload) {
      storeState.serviciability = payload;
    },
    setFundsComplete(storeState, payload) {
      storeState.fundsToComplete[payload.field] = payload.value;
    },
    setMortgagePayout(storeState, payload) {
      storeState.mortgagePayout[payload.field] = payload.value;
    },
    setNetSales(storeState, payload) {
      storeState.netSales[payload.field] = payload.value;
    },
    setRepaymentData(storeState, payload) {
      storeState.repaymentData = payload;
    },
    setRatesData(storeState, payload) {
      storeState.ratesData = payload;
    },
  },
  actions: {
    setSettlementData({ commit }, payload) {
      commit("setSettlementData", payload);
    },
    setSummaryData({ commit }, payload) {
      commit("setSummaryData", payload);
    },
    setServiciability({ commit }, payload) {
      commit("setServiciability", payload);
    },
    setFundsComplete({ commit }, payload) {
      commit("setFundsComplete", payload);
    },
    setMortgagePayout({ commit }, payload) {
      commit("setMortgagePayout", payload);
    },
    setNetSales({ commit }, payload) {
      commit("setNetSales", payload);
    },
    setEndDebtData({ commit }, payload) {
      commit("setEndDebtData", payload);
    },
    setRepaymentData({ commit }, payload) {
      commit("setRepaymentData", payload);
    },
    setRatesData({ commit }, payload) {
      commit("setRatesData", payload);
    },
  },
};
