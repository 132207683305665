<template>
  <Dialog
    :draggable="false"
    :visible="show"
    :modal="show"
    :style="{ width: '580px' }"
  >
    <div class="modal-content">
      <div>
        Name
        <i class="icon pi pi-times" @click="close" style="margin-left: 87%" />
      </div>
      <Dropdown
        width="100%"
        placeholder="version 3: 12:00 01/11/2021"
        class="mt-2"
      />
      <Checkbox
        @getInputValue="getInputValue"
        class="mt-3"
        :category="category"
      />

      <h1 class="modal-title">Amendments identified</h1>
      <div class="mt-3">
        <p class="p-text text-address">321 Street Sydney NSW 2010</p>
      </div>
      <div class="mr-4 flex align-items-center align-items">
        <p class="lt-text">Previous value</p>
        <p class="lt-txt">New value</p>
      </div>
      <div class="flex flex-content flex-content-item">
        <div class="col-6">
          <div class="call flex align-items-center">
            <div class="label-content col-5">Purchase price</div>
            <div class="disable-input">
              <TextField
                :disable="true"
                placeholder="$1,500,000"
                width="100%"
              />
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="call">
            <TextField placeholder="$1,000,000" width="100%" />
          </div>
        </div>
      </div>
      <div class="flex flex-content flex-content-item">
        <div class="col-6">
          <div class="call flex align-items-center">
            <div class="label-content col-5">Mortgage</div>
            <div class="disable-input">
              <TextField :disable="true" placeholder="$200,000" width="100%" />
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="call">
            <TextField placeholder="$200,000" width="100%" />
          </div>
        </div>
      </div>
      <div>Notes</div>
      <TextArea width="100%" placeholder="Description" class="mt-2" />
      <h1 class="modal-title">Income</h1>
      <div class="mt-3">
        <p class="p-text text-address">Full time income</p>
      </div>
      <div class="mr-4 flex align-items-center align-items">
        <p class="lt-text">Previous value</p>
        <p class="lt-txt">New value</p>
      </div>
      <div class="flex flex-content flex-content-item">
        <div class="col-6">
          <div class="call flex align-items-center">
            <div class="label-content col-5">Total income</div>
            <div class="disable-input">
              <TextField
                :disable="true"
                placeholder="$1,350,000"
                width="100%"
              />
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="call">
            <TextField placeholder="$1,200,000" width="100%" />
          </div>
        </div>
      </div>
      <div>Notes</div>
      <TextArea width="100%" placeholder="Description" class="mt-2" />
      <h1 class="modal-title">Additional notes</h1>
      <div>Notes</div>
      <TextArea width="100%" placeholder="Description" class="mt-2" />
      <div v-if="category.value">
        <h1 class="modal-title">Task</h1>
        <div>Task</div>
        <TextField width="100%" placeholder="Task title" class="mt-2" />
        <div class="flex mt-2">
          <div class="col">
            <div>Assign to</div>
            <Dropdown width="100%" placeholder="User" class="mt-2" />
          </div>
          <div class="col ml-2">
            <div>User</div>
            <Dropdown width="100%" placeholder="-Select-" class="mt-2" />
          </div>
        </div>
        <div class="flex mt-2">
          <div class="col">
            <div>Regarding</div>
            <Dropdown width="100%" placeholder="Application" class="mt-2" />
          </div>
          <div class="col ml-2">
            <div>Application</div>
            <Dropdown width="100%" placeholder="-Select-" class="mt-2" />
          </div>
        </div>

        <div class="mt-2">Task type</div>
        <Dropdown width="100%" placeholder="-Select-" class="mt-2" />
        <div class="flex align-items-center mt-2">
          <div class="col">
            <div>Due date</div>
            <DatePicker style="width: 100%" class="mt-2" />
          </div>
          <div class="col ml-2">
            <div>Application</div>
            <TextField width="100%" class="mt-2" />
          </div>
        </div>
        <div class="mt-2">Description</div>
        <TextArea width="100%" class="mt-2" />
      </div>
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button label="Save new version" type="primary" @click="save" />
        <Button label="Save as draft" type="outline" />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">
          Close
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";

export default {
  name: "AddDocument",
  props: ["show"],
  components: { Dialog },
  data() {
    return {
      category: { id: 1, label: "Create task?", value: false },
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getInputValue(value) {
      this.category.value = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
::v-deep .align-items {
  gap: 5.5rem !important;
  margin-left: 7.5rem !important;
  color: rgba(2, 0, 56, 0.3) !important;
  font-family: Gordita-medium !important;
  font-weight: 600 !important;
}
.label-content {
  white-space: nowrap;
}
.text-address {
  padding: 1rem;
  background: rgba(2, 0, 56, 0.03);
  font-family: Gordita;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #020038;
}
::v-deep .mt-2 {
  margin-bottom: 1rem;
}
</style>
