<template>
  <div class="mr-top" :key="refreshKey">
    <div class="heading flex align-items-center justify-content-between">
      <p class="sub-title">Credit/Store Cards</p>
      <Button @click="addCards" type="primary" label="Add Credit Card" />
    </div>
    <div v-if="details.length > 0">
      <div v-for="(item, i) in details" :key="i">
        <div class="form-inputs mr-top-10px">
          <div class="p-grid align-items-end">
            <!-- Col-1 -->

            <div class="p-col-10">
              <!--Row-1 -->

              <div class="form-inputs mr-top-10px">
                <div class="p-d-flex flex-gap align-items-end mr-b-5px">
                  <div class="p-col-3 p-d-flex flex-gap">
                    <Button
                      class="full-width"
                      type="outline"
                      label="Remove Liability"
                      @click="removeCard(i)"
                    />
                    <!-- <img src="@/assets/Completed.svg" alt="" /> -->
                  </div>
                  <div>
                    <p class="label">Institution</p>
                    <TextField
                      @onInput="(e) => getFormData(e, 'institution', i)"
                      :value="item.institution"
                      width="100%"
                      :inValid="
                        v$.details.$silentErrors[0]?.$response.$data[i]
                          .institution.$invalid && saveBtnClicked
                      "
                    />
                  </div>

                  <div>
                    <p class="label">Credit Limit</p>
                    <NumberField
                      mode="decimal"
                      :format="true"
                      prefix="$"
                      @onInput="(e) => getFormData(e, 'credit_limit_used', i)"
                      :value="item.credit_limit_used"
                      :disable="item.credit_limit_used_value != 'manual_value'"
                      type="number"
                      width="100%"
                      :inValid="
                        v$.details.$silentErrors[0]?.$response.$data[i]
                          .credit_limit_used.$invalid && saveBtnClicked
                      "
                    />
                  </div>

                  <div>
                    <p class="label">Amount Owing</p>
                    <NumberField
                      mode="decimal"
                      :format="true"
                      prefix="$"
                      @onInput="(e) => getFormData(e, 'amount_owing_used', i)"
                      :value="item.amount_owing_used"
                      :disable="item.amount_owing_used_value != 'manual_value'"
                      type="number"
                      width="100%"
                      :inValid="
                        v$.details.$silentErrors[0]?.$response.$data[i]
                          .amount_owing_used.$invalid && saveBtnClicked
                      "
                    />
                  </div>

                  <div>
                    <p class="label">Close&nbsp;Liability?</p>
                    <ToggleSwitch
                      :isChecked="item.closeLiability"
                      @onChange="(e) => getFormData(e, 'closeLiability', i)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Col-2 -->

            <div
              v-if="i === details.length - 1"
              class="p-col-2 mr-b-5px highlight-fields"
            >
              <div class="disable-input">
                <p class="label">Credit Cards/Overdrafts Monthly</p>
                <TotalField
                  mode="decimal"
                  :format="true"
                  prefix="$"
                  :value="subTotal"
                  :disable="true"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["disable"],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      details: {
        $each: helpers.forEach({
          credit_limit_used: { required },
          institution: { required },
          amount_owing_used: { required },
        }),
      },
    };
  },
  data() {
    return {
      details: [],
      refreshKey: 10,
      disableFields: this.$props.disable,
    };
  },
  methods: {
    ...mapActions({
      setLiability: "applications/setLiability",
      setValidation: "applications/setValidation",
      setCardsTotal: "subTotal/setCardsTotal",
      setCloseLiabilityCardtotal: "subTotal/setCloseLiabilityCardtotal",
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    addCards() {
      let newObj = {
        institution: "",
        credit_limit: 0,
        amount_owning: 0,
        closeLiability: false,
        amount_owing_used: 0,
        credit_limit_used: 0,
        credit_limit_verified: 0,
        credit_limit_manual: 0,
        credit_limit_used_value: "manual_value",
        amount_owing_verified: 0,
        amount_owing_manual: 0,
        amount_owing_used_value: "manual_value",
      };
      this.details = [...this.details, newObj];
      return (this.disableFields = false);
    },
    setData() {
      if (this.selectedApplication.application_id) {
        if (
          this.selectedApplication.liability_cards &&
          this.selectedApplication.liability_cards.length > 0
        ) {
          this.details = !this.disable
            ? this.selectedApplication.liability_cards.filter(
                (item) => item.applicant_type === "primary"
              )
            : this.selectedApplication.liability_cards.filter(
                (item) => item.applicant_type === "secondary"
              );
        }
      }
      this.setLiability({
        data: this.details,
        type: !this.disable ? "primary" : "secondary",
        liability_type: "cards",
      });
    },
    removeCard(i) {
      this.details.splice(i, 1);
      if (this.details.length === 0) {
        this.setCardsTotal({
          data: 0,
          type: !this.disable ? "primary" : "secondary",
        });
      }
      this.refreshKey += 1;
    },
    getFormData(value, field, index) {
      this.details[index][field] = value;
      this.details = [...this.details];
      if (field == "credit_limit_used") {
        this.details[index].credit_limit_manual = value;
      }
      if (field == "amount_owing_used") {
        this.details[index].amount_owing_manual = value;
      }
      this.setLiability({
        data: this.details,
        type: !this.disable ? "primary" : "secondary",
        liability_type: "cards",
      });
    },
  },
  computed: {
    ...mapGetters({
      saveBtnClicked: "applications/saveBtnClicked",
      selectedApplication: "applications/selectedApplication",
    }),
    ...mapState({
      liabilityCards: (state) => state.applications.liabilityCards,
      creditCardUpdated: (state) => state.applications.creditCardUpdated,
    }),
    subTotal: {
      get: function () {
        let closeLiabilitytotal = 0;
        let total = this.details.reduce((previousValue, currentValue) => {
          if (!currentValue.closeLiability) {
            return (
              Number(previousValue) +
              Number(currentValue?.credit_limit_used) * 0.038
            );
          } else {
            closeLiabilitytotal =
              closeLiabilitytotal + Number(currentValue?.amount_owing_used);
            return previousValue;
          }
        }, 0);
        this.setCloseLiabilityCardtotal({
          data: closeLiabilitytotal,
          type: !this.disable ? "primary" : "secondary",
        });
        this.setCardsTotal({
          data: total,
          type: !this.disable ? "primary" : "secondary",
        });
        return total;
      },
    },
  },
  created() {
    this.setData();
  },
  watch: {
    details: {
      handler() {
        this.setLiability({
          data: this.details,
          type: !this.disable ? "primary" : "secondary",
          liability_type: "cards",
        });
        this.setValidation({
          type: "liability_cards",
          value: this.isSubmitDisabled(),
        });
      },
      deep: true,
    },

    creditCardUpdated: {
      handler() {
        this.details = !this.disable
          ? [...this.liabilityCards.applicant_1]
          : [...this.liabilityCards.applicant_2];
        this.refreshKey += 1;
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

::v-deep .p-col-10,
.p-col-3,
.p-col-2 {
  padding-bottom: 0;
  padding-top: 0;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.full-width {
  width: 100%;
}

.highlight-fields {
  .label {
    font-family: Gordita-bold !important;
  }
}

::v-deep .p-col-3 {
  padding-left: 0;
}

.heading {
  background: rgba(2, 0, 56, 0.03);
  padding: 12px;

  p {
    margin: 0;
  }
}

.mr-b-5px {
  margin-bottom: 5px;
}

.mr-top {
  margin-top: 2.5rem;
}

.mr-top-10px {
  margin-top: 10px;
}

.flex-gap {
  gap: 0.625rem;
}

.flex-gap-1 {
  gap: 0.313rem;
}

.flex-gap-2 {
  gap: 1.875rem;
}
</style>
