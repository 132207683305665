<template>
  <div>
    <div class="title p-d-flex justify-content-between">
      <p class="title-text">Servicing</p>
    </div>
    <!-- <div v-if="isloading">loading</div> -->
    <div class="mt-2">
      <div class="flex align-items-center justify-content end disable-input">
        <p class="label p-col-6">Income Assesment</p>
        <TextField
          :key="refreshKey"
          class="p-col-6"
          :disable="true"
          width="100%"
          height="30px"
          placeholder="label"
          :value="formData.income_assesment"
          id="income_assesment"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["responseValue", "isloading"],
  data() {
    return {
      refreshKey: 10,

      formData: {
        income_assesment: "",
      },
    };
  },
  watch: {
    calculatedValues(result) {
      this.refreshKey++;

      for (const [key, value] of Object.entries(result)) {
        if (typeof value === "number") {
          this.formData[key] = value.toFixed(2);
        } else {
          this.formData[key] = value;
        }
        if (value === -1) {
          this.formData[key] = "-";
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      calculatedValues: "servicingCalc/calculatedValues",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.title {
  border-bottom: 1px solid rgba(2, 0, 56, 0.15);
  padding-bottom: 10px;

  .title-text {
    font-family: Gordita-bold;
    color: $secondary-color;
    font-size: 20px;
  }
}

p {
  margin: 0;
}

.label {
  padding-right: 0;
  line-height: 19px !important;
  font-size: 14px;
  font-family: Gordita-medium;
  color: $secondary-color;
  text-align: end;
}

.text-highlight {
  font-family: Gordita-bold !important;
}

:deep .p-col-6 {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
