<template>
  <Panel :toggleable="true" :collapsed="collapsed">
    <template #icons>
      <div @click="toggle" class="icon-wrapper">
        <span v-if="collapsed" class="icon pi pi-angle-down"></span>
        <span v-else class="icon pi pi-angle-up"></span>
      </div>
    </template>
    <template #header>
      <div
        @click="toggle"
        class="sec-color-text hk-sb hk-grotest-medium pointer full-width"
      >
        Task Types - Permission
      </div>
    </template>
    <div class="mt-3">
      <CheckboxList
        :categories="taskTypePermission"
        name="piepline-permission"
        @getInputValue="getTaskPermission"
        class="checkbox-list"
        :checkList="
          selectedGroupId &&
          groupPermissionList[this.groupPermissionList.length - 1]
            ?.groupPermissionList
        "
      />
    </div>
  </Panel>
</template>

<script>
import Panel from "primevue/panel";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { Panel },
  data() {
    return {
      collapsed: true,
      taskPermission: {},
    };
  },
  computed: {
    ...mapGetters({
      taskTypePermission: "group/taskTypePermission",
      groupPermissionList: "group/groupPermissionList",
      selectedGroupId: "group/selectedGroupId",
    }),
  },
  methods: {
    ...mapActions({
      getTasktypePermission: "group/getTasktypePermission",
    }),
    toggle() {
      this.collapsed = !this.collapsed;
    },
    getTaskPermission(value) {
      value.forEach((ele) => {
        this.taskPermission[ele] = true;
      });

      Object.keys(this.taskPermission).forEach((opt) => {
        if (value.indexOf(opt) === -1) {
          this.taskPermission[opt] = false;
        }
      });

      this.getTasktypePermission(this.taskPermission);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
  font-family: Gordita-bold !important;
}
.text-content {
  margin-top: 1rem;
}
form {
  gap: 1rem;

  input[type="radio"] {
    width: 1.125rem;
    height: 1.125rem;
  }
}

label {
  margin-left: 0.4rem;
}
.radio-input {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.radio-label {
  font-family: Gordita;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #020038;
}
.pi-minus {
  display: none;
}
::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid #02003826 !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel-content {
  border: none !important;
}

::v-deep .p-panel-header-icon {
  display: none !important;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

::v-deep .p-col-4,
.p-col-2 {
  padding-bottom: 0;
  padding-top: 0;
}

.mr-b-5px {
  margin-bottom: 5px;
}

.mr-top {
  margin-top: 2.5rem;
}

.mr-top-10px {
  margin-top: 10px;
}

.flex-gap {
  gap: 0.625rem;
}
.hk-title {
  font-size: 20px;
  font-weight: 600;
}
.sec-color-text {
  font-size: 16px;
}
.p-panel {
  margin-top: 1rem !important;
}
</style>
