<template>
  <div>
    <div class="content">
      <div class="form-inputs mt-4">
        <div v-if="latest.length" class="p-grid align-items-center header">
          <p class="col"></p>
          <p class="col label">Application Values</p>
          <p class="col label">Current Values</p>
          <p class="col label">Current Value Source</p>
          <div class="col"></div>
        </div>
        <div v-for="(item, i) in latest" :key="i">
          <br />
          <InputRow
            label="Centerlink"
            :value1="original[i] ? original[i].centerlink_payments : '0'"
            :value2="item.centerlink_payments"
          />
          <InputRow
            label="Centerlink ratio %"
            :value1="
              calculateRatio(
                item.centerlink_payments,
                monthlySubtotal(i, 'original')
              )
            "
            :value2="
              calculateRatio(
                item.centerlink_payments,
                monthlySubtotal(i, 'latest')
              )
            "
          />
          <InputRow
            label="Family allowance"
            :value1="original[i] ? original[i].family_allowance : '0'"
            :value2="item.family_allowance"
          />
          <InputRow
            label="Family allowance %"
            :value1="
              calculateRatio(
                item.family_allowance,
                monthlySubtotal(i, 'original')
              )
            "
            :value2="
              calculateRatio(
                item.family_allowance,
                monthlySubtotal(i, 'latest')
              )
            "
          />
          <InputRow
            label="Pension"
            :value1="original[i] ? original[i].govt_pension : '0'"
            :value2="item.govt_pension"
          />
          <InputRow
            label="Pension %"
            :value1="
              calculateRatio(item.govt_pension, monthlySubtotal(i, 'original'))
            "
            :value2="
              calculateRatio(item.govt_pension, monthlySubtotal(i, 'latest'))
            "
          />
          <InputRow
            label="Super"
            :value1="original[i] ? original[i].super : '0'"
            :value2="item.super"
          />
          <InputRow
            label="Super %"
            :value1="calculateRatio(item.super, monthlySubtotal(i, 'original'))"
            :value2="calculateRatio(item.super, monthlySubtotal(i, 'latest'))"
          />
          <InputRow
            label="Child support"
            :value1="original[i] ? original[i].child_support_maintenance : '0'"
            :value2="item.child_support_maintenance"
          />
          <InputRow
            label="Child support %"
            :value1="
              calculateRatio(
                item.child_support_maintenance,
                monthlySubtotal(i, 'original')
              )
            "
            :value2="
              calculateRatio(
                item.child_support_maintenance,
                monthlySubtotal(i, 'latest')
              )
            "
          />
          <InputRow
            label="Family tax benefit"
            :value1="original[i] ? original[i].family_tax_benefits : '0'"
            :value2="item.family_tax_benefits"
          />
          <InputRow
            label="Family tax benefit %"
            :value1="
              calculateRatio(
                item.family_tax_benefits,
                monthlySubtotal(i, 'original')
              )
            "
            :value2="
              calculateRatio(
                item.family_tax_benefits,
                monthlySubtotal(i, 'latest')
              )
            "
          />
          <InputRow
            label="Dividends"
            :value1="original[i] ? original[i].dividends : '0'"
            :value2="item.dividends"
          />
          <InputRow
            label="Dividends %"
            :value1="
              calculateRatio(item.dividends, monthlySubtotal(i, 'original'))
            "
            :value2="
              calculateRatio(item.dividends, monthlySubtotal(i, 'latest'))
            "
          />
          <InputRow
            label="Other"
            :value1="original[i] ? original[i].other : '0'"
            :value2="item.other"
          />
          <InputRow
            label="Other %"
            :value1="calculateRatio(item.other, monthlySubtotal(i, 'original'))"
            :value2="calculateRatio(item.other, monthlySubtotal(i, 'latest'))"
          />
          <InputRow
            label="Monthly sub-total"
            :value1="monthlySubtotal(i, 'original')"
            :value2="monthlySubtotal(i, 'latest')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputRow from "../../InputRow.vue";
import { mapGetters } from "vuex";
export default {
  components: { InputRow },
  props: ["applicant"],
  computed: {
    ...mapGetters({
      originalData: "loans/originalData",
      latestData: "loans/latestData",
    }),
  },
  data() {
    return {
      original: [],
      latest: [],
      originalTotal: 0,
      latestTotal: 0,
    };
  },
  methods: {
    setData() {
      this.latest = [];
      let applicant_type = this.applicant == "1" ? "primary" : "secondary";
      this.latestData.additional_income.map((obj) => {
        if (obj.applicant_type === applicant_type) {
          this.latest.push(obj);
        }
      });
      this.original = [];
      this.originalData.additional_income.map((obj) => {
        if (obj.applicant_type === applicant_type) {
          this.original.push(obj);
        }
      });
    },
    calculateRatio(value, total) {
      if (value && total) {
        let calc = value / total;
        let percent = calc * 100;
        return percent.toFixed(2);
      }
      return 0;
    },
    //Get updated values based on frequency
    getUpdatedValues(valueField, frequencyField, index, type) {
      let value = "",
        frequency = "";
      if (type === "original" && this.original.length) {
        value = this.original[index][valueField];
        frequency = this.original[index][frequencyField];
      } else {
        if (this.latest.length) {
          value = this.latest[index][valueField];
          frequency = this.latest[index][frequencyField];
        }
      }
      switch (frequency) {
        case "Weekly":
          return value * 4;
        case "Fortnightly":
          return value * 2;
        case "Monthly":
          return value * 1;
        case "Quarterly":
          return value / 3;
        case "Yearly":
          return value / 12;
      }
      return 0;
    },
    monthlySubtotal(i, type) {
      let total =
        Number(
          this.getUpdatedValues(
            "child_support_maintenance",
            "child_support_maintenance_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getUpdatedValues(
            "family_allowance",
            "family_allowance_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getUpdatedValues(
            "family_tax_benefits",
            "family_tax_benefits_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getUpdatedValues(
            "govt_pension",
            "govt_pension_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getUpdatedValues("dividends", "dividends_frequency", i, type)
        ) +
        Number(this.getUpdatedValues("super", "super_frequency", i, type));
      Number(this.getUpdatedValues("other", "other_frequency", i, type));
      return total ? total.toFixed(2) : 0;
    },
  },
  watch: {
    latestData() {
      this.setData();
    },
  },
  created() {
    this.setData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
</style>
