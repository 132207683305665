<template>
  <div>
    <div class="module-sub-title">Loans</div>
    <div class="divider"></div>
    <Spinner v-if="isLoading" class="flex justify-content-between" />
    <DataTable
      v-else
      :rows="10"
      selectionMode="multiple"
      data-key="id"
      :rowHover="true"
      :value="loanList.result"
      :rowsPerPageOptions="[10, 20, 30]"
      :rowStyleClass="lists?.result === 'Warning 1' ? 'status-pass' : 'row-accessories'"
      :rowClass="renderClass"
    >
      <template #empty><div class="p-3">No data found.</div></template>

      <Column style="max-width: 5rem" field="loan_id" header="Loan ID">
        <template #body="slotProps">
          <a
            @click="goToApp(slotProps.data.Id, slotProps.data.loan_id)"
            class="color-highlight table-column"
            :class="renderClass(slotProps.data)"
          >
            {{ slotProps.data.loan_id || "-" }}
          </a>
        </template>
      </Column>
      <Column
        style="max-width: 10rem"
        field="applicants"
        header="Primary applicant"
        :style="{ maxWidth: '5rem' }"
      >
        <template #body="slotProps">
          <a
            @click="
              goToApp(
                slotProps.data.Id,
                slotProps.data.loan_id,
                slotProps.data.applicant_1_declared,
                slotProps.data.applicant_2_declared,
                slotProps.data.applicants
              )
            "
            v-if="slotProps.data.applicants && slotProps.data.applicants.length"
            class="color-highlight table-column capital"
            :class="renderClass(slotProps.data)"
          >
            {{ slotProps.data.applicants[0].first_name }}
            {{ slotProps.data.applicants[0].middle_name }}
            {{ slotProps.data.applicants[0].last_name }}
          </a>
          <div class="table-column" :class="renderClass(slotProps.data)" v-else>-</div>
        </template>
      </Column>
      <Column
        field="eligible_loan_amount"
        header="Loan amount"
        :style="{ maxWidth: '7rem' }"
      >
        <template #body="slotProps">
          <div class="table-column" :class="renderClass(slotProps.data)">
            {{
              slotProps.data.eligible_loan_amount
                ? "$" + formatCurrency(slotProps.data.eligible_loan_amount)
                : "-"
            }}
          </div>
        </template>
      </Column>
      <Column field="taskTitle" header="Task" :style="{ maxWidth: '14rem' }">
        <template #body="slotProps">
          <a
            class="color-highlight table-column"
            :class="renderClass(slotProps.data)"
            @click="goToEditTask(slotProps.data.taskId)"
          >
            {{ slotProps.data.taskTitle || "-" }}
          </a>
        </template>
      </Column>
      <Column field="status" style="width: 10rem" header="Stage">
        <template #body="slotProps">
          <div class="table-column" :class="renderClass(slotProps.data)">
            {{ changeCase(slotProps.data.crm_status) }}
          </div>
        </template>
      </Column>
      <Column field="declarations" style="width: 200px" header="Declarations">
        <template #body="slotProps">
          <div class="table-column" :class="renderClass(slotProps.data)">
            {{
              slotProps.data && slotProps.data.applicants
                ? appDeclaration(
                    slotProps.data.applicant_1_declared,
                    slotProps.data.applicant_2_declared,
                    slotProps.data.applicants.length
                  )
                : "0/0"
            }}
          </div>
        </template>
      </Column>
    </DataTable>
    <Paginator
      class="mt-2"
      :rows="10"
      :totalRecords="loanList && loanList.totalCount"
      :rowsPerPageOptions="[10, 20, 30]"
      template="RowsPerPageDropdown  CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
      currentPageReportTemplate="Showing {first} of {totalRecords} loans"
      @page="onChangePage($event)"
    >
    </Paginator>

    <EditTask
      v-if="showEditTask"
      :key="editTaskKey"
      :show="showEditTask"
      :taskId="selectedTaskId"
      @close="showEditTask = false"
      portal="application"
      @save="refreshKey++"
    />
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { _get } from "../../../services/crudService";
import Paginator from "primevue/paginator";
import { APP_URL } from "../../../../constants";
import EditTask from "../Applications/ApplicationData/Task/EditTask.vue";

export default {
  name: "Loans",
  props: ["selectedOpt", "filterField", "filterValue", "refreshKey", "searchTerm"],
  components: {
    DataTable,
    Paginator,
    Column,
    EditTask,
  },
  data() {
    return {
      loanList: [],
      selectedTaskId: "",
      isLoading: false,
      showEditTask: false,
    };
  },
  watch: {
    selectedOpt(value) {
      if (value === "Applications") {
        this.applicationsList(1, 10);
      } else if (value === "Funded") {
        this.fundedlist(1, 10);
      } else if (value === "Arrears") {
        this.arrearList(1, 10);
      }
    },
    filterValue() {
      if (this.refreshKey > 0) {
        if (this.$props.selectedOpt === "Applications") {
          this.applicationsList(1, 10);
        } else if (this.$props.selectedOpt === "Funded") {
          this.fundedlist(1, 10);
        } else if (this.$props.selectedOpt === "Arrears") {
          this.arrearList(1, 10);
        }
      }
    },
  },
  methods: {
    formatCurrency(val) {
      return val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    changeCase(val) {
      return val.split("_").join(" ");
    },
    renderClass(data) {
      let dec_1 = data.applicant_1_declared;
      let dec_2 = data.applicant_2_declared;
      let applicantCount = data && data.applicants && data.applicants.length;

      const declaration = this.appDeclaration(dec_1, dec_2, applicantCount);

      if (declaration == "1/1" || declaration == "2/2") {
        return;
      } else {
        return "highlight-bg";
      }
    },

    goToEditTask(id) {
      if (id) {
        this.selectedTaskId = id;
        this.editTaskKey += 1;
        this.showEditTask = true;
      }
    },

    goToApp(id, loan_id, dec_1, dec_2, applicantCount) {
      // const declaration = this.appDeclaration(dec_1, dec_2, applicantCount);

      // if (declaration == "1/1" || declaration == "2/2") {
      //   }
      if (id && loan_id) {
        let declaration = false;
        if (applicantCount == 2) {
          if (dec_1 && dec_2) {
            declaration = true;
          } else if (dec_1) {
            declaration = false;
          } else {
            declaration = false;
          }
        } else if (applicantCount == 1) {
          if (dec_1) {
            declaration = true;
          } else {
            declaration = false;
          }
        } else {
          declaration = true;
        }
        this.$router.push(
          `/loan/${id}/overview?loanId=${loan_id}&declaration=${declaration}`
        );
      }
    },
    appDeclaration(dec_1, dec_2, applicantCount) {
      if (applicantCount == 2) {
        if (dec_1 && dec_2) {
          return "2/2";
        } else if (dec_1) {
          return "1/2";
        } else {
          return "0/2";
        }
      } else if (applicantCount == 1) {
        if (dec_1) {
          return "1/1";
        } else {
          return "0/1";
        }
      } else {
        return "0/0";
      }
    },
    onChangePage(value) {
      if (this.$props.selectedOpt === "Applications") {
        this.applicationsList(value.page + 1, value.rows);
      } else if (this.$props.selectedOpt === "Funded") {
        this.fundedlist(value.page + 1, value.rows);
      } else if (this.$props.selectedOpt === "Arrears") {
        this.arrearList(value.page + 1, value.rows);
      }
    },
    async applicationsList(page, row) {
      try {
        this.isLoading = true;
        const response = await _get(
          APP_URL +
            `applications_crm_pipeline?pageSize=${row}&pageIndex=${page}&field=${this.filterField}&value=${this.filterValue}&searchTerm=${this.searchTerm}`
        );
        if (response) {
          this.loanList = response.data;

          this.loanList.result &&
            this.loanList.result.map((elem, index) => {
              const declaration =
                elem &&
                elem.applicants &&
                this.appDeclaration(
                  elem.applicant_1_declared,
                  elem.applicant_2_declared,
                  elem.applicants.length
                );

              if (declaration == "1/1" || declaration == "2/2") {
                this.loanList.result.splice(index, 1);
                this.loanList.result.unshift(elem);
              }
            });
          this.isLoading = false;
        }
      } catch (err) {
        this.isLoading = false;

        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
    },

    async fundedlist(page, row) {
      try {
        this.isLoading = true;
        const response = await _get(
          APP_URL +
            `/applications_crm_by_status?pageSize=${row}&pageIndex=${page}&field=${this.filterField}&value=${this.filterValue}&searchTerm=${this.searchTerm}`
        );
        if (response) {
          this.loanList = response.data;

          this.loanList.result.map((elem, index) => {
            const declaration = this.appDeclaration(
              elem.applicant_1_declared,
              elem.applicant_2_declared,
              elem.applicants.length
            );

            if (declaration == "1/1" || declaration == "2/2") {
              this.loanList.result.splice(index, 1);
              this.loanList.result.unshift(elem);
            }
          });

          this.isLoading = false;
        }
      } catch (err) {
        this.isLoading = false;

        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
    },

    async arrearList(page, row) {
      try {
        this.isLoading = true;
        this.taskList = [];

        const response = await _get(
          APP_URL +
            `arrears_applications_crm?pageSize=${row}&pageIndex=${page}&field=${this.filterField}&value=${this.filterValue}&searchTerm=${this.searchTerm}`
        );
        if (response) {
          this.loanList = response.data;

          this.loanList.result.map((elem, index) => {
            const declaration = this.appDeclaration(
              elem.applicant_1_declared,
              elem.applicant_2_declared,
              elem.applicants.length
            );

            if (declaration == "1/1" || declaration == "2/2") {
              this.loanList.result.splice(index, 1);
              this.loanList.result.unshift(elem);
            }
          });
          this.isLoading = false;
        }
      } catch (err) {
        this.isLoading = false;

        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
    },
  },
  created() {
    if (this.selectedOpt === "Applications") {
      this.applicationsList(1, 10);
    } else if (this.selectedOpt === "Funded") {
      this.fundedlist(1, 10);
    } else if (this.selectedOpt === "Arrears") {
      this.arrearList(1, 10);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
  padding: 0rem 0 !important;
}

:deep .p-datatable .p-datatable-thead > tr > th {
  padding: 1rem 0 !important;
}

:deep .color-highlight {
  color: $primary-color !important;
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}

:deep .p-card-content {
  padding-top: 0 !important;
}

.table-column {
  display: block;
  padding: 1rem 0;
}

.highlight-bg {
  background: $pink !important;
}

p {
  margin: 0;
}
</style>
