<template>
  <Dialog
    :draggable="false"
    :visible="show"
    :modal="show"
    :dismissableMask="true"
    :closeOnEscape="true"
    :style="{ width: '580px' }"
  >
    <template #header>
      <h1 class="modal-title">New task</h1>
      <i class="icon pi pi-times" @click="close" style="margin-left: auto" />
    </template>
    <div class="modal-content">
      <div class="flex mt-2">
        <div class="col">
          <div>Assign to</div>
          <Dropdown
            :items="assignTo"
            width="100%"
            placeholder="-Select-"
            class="mt-2"
            @change="getAssignValue"
            :value="formData.assignTo"
            :key="refreshKey"
            :disable="this.$props.portal == 'Customer' || this.$props.portal == 'Broker'"
          />
        </div>
        <div class="col ml-2">
          <div>{{ formData.assignTo }}</div>
          <div class="mt-2">
            <AutoComplete
              forceSelection
              width="100%"
              v-model="selectedUser"
              :suggestions="filteredUserList"
              :disabled="
                loadingUserList ||
                this.$props.portal == 'Customer' ||
                this.$props.portal == 'Broker'
              "
              @complete="getUserList($event)"
              placeholder="Enter name"
              field="name"
              :modelValue="selectedUser"
              :dropdown="true"
              :key="refreshKey"
            />

            <!-- <Dropdown
            :items="usersList"
            :disable="loadingUserList && true"
            optionLabel="name"
            width="100%"
            placeholder="-Select-"
            class="mt-2"
            @change="getAssignedToId"
            :value="formData.assignToType"
          /> -->
          </div>
        </div>
      </div>
      <div class="mt-2">Task type</div>
      <Dropdown
        optionLabel="taskTypes"
        :items="taskTypes"
        width="100%"
        placeholder="-Select-"
        class="mt-2"
        @change="getTaskType"
        :disable="loadingTaskTypes && true"
      />

      <div class="mt-2">Task title</div>

      <TextField
        @onInput="getFormData($event, 'taskTitle')"
        :value="formData.taskTitle"
        width="100%"
        placeholder="Task title"
        class="mt-2"
        :key="refreshKey"
      />
      <div class="flex mt-2">
        <div class="col">
          <div>Regarding</div>
          <Dropdown
            width="100%"
            @change="getRegValue"
            placeholder="-Select-"
            :items="regarding"
            class="mt-2"
            :value="formData.regardingTo"
            :key="refreshKey"
          />
        </div>
        <div class="col ml-2">
          <div>{{ formData.regardingTo || "-select-" }}</div>
          <AutoComplete
            forceSelection
            class="mt-2"
            width="100%"
            v-model="selectedReg"
            :suggestions="filteredRegList"
            @complete="getRegList($event)"
            :placeholder="loanPlaceHolder"
            field="name"
            :modelValue="selectedReg"
            :disabled="loadingRegList"
            :key="refreshKey"
          />

          <!-- <Dropdown
            :value="formData.regardingToType"
            :items="regardingList"
            :disable="loadingRegList"
            optionLabel="name"
            width="100%"
            placeholder="-Select-"
            class="mt-2"
            @change="getRegardingToId"
          /> -->
        </div>
      </div>

      <div class="flex align-items-center mt-2">
        <div class="col">
          <div>Due date</div>
          <DatePicker
            placeholder="DD/MM/YYYY"
            style="width: 100%"
            class="mt-2"
            @change="getFormData($event, 'dueDate')"
            :minDate="minDate"
            :minYear="minDate.getFullYear()"
          />
        </div>
        <div class="col ml-2 time-input">
          <div>Due time</div>
          <Calendar
            v-model="time"
            :showTime="true"
            :timeOnly="true"
            hourFormat="12"
            class="mt-2 full-width"
            placeholder="Enter time"
            @change="getTime"
          />
        </div>
      </div>
      <div class="mt-2">Description</div>
      <TextArea
        @onInput="getFormData($event, 'description')"
        placeholder="Task description"
        width="100%"
        class="mt-2"
      />
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button
          :disabled="isSubmitDisabled()"
          label="Add tasks"
          type="primary"
          @click="save"
          :loading="isloading"
        />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">Close</div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Calendar from "primevue/calendar";
import { _get, _post } from "../../../../../services/crudService";
import { APP_URL, SERVER_URL } from "../../../../../../constants";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
import AutoComplete from "primevue/autocomplete";
import { mapGetters } from "vuex";

export default {
  name: "AddTask",
  props: ["show", "assigned", "regards", "regardId", "assignedId", "portal"],
  components: { Dialog, Calendar, AutoComplete },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      selectedUser: { required },
      selectedReg: { required },
      formData: {
        taskTitle: { required },
        assignTo: { required },
        assignToId: { required },
        regardingTo: { required },
        regardingToId: { required },
        taskType: { required },
        description: { required },
        dueDate: { required },
        dueTime: { required },
      },
    };
  },
  data() {
    return {
      minDate: null,
      appBrokerName: null,
      assignTo: ["User", "Customer", "Broker", "Group"],
      regarding: ["Customer", "Broker", "Loan"],
      taskTypes: [],
      usersList: [],
      filteredUserList: [],
      filteredRegList: [],
      regardingList: [],
      appId: "",
      time: null,
      isloading: false,
      loadingUserList: false,
      loadingRegList: false,
      loadingTaskTypes: false,
      disableReg: false,
      refreshKey: 10,
      selectedUser: "",
      selectedReg: "",
      formData: {
        taskTitle: "",
        assignTo: this.$props.assigned || "User",
        assignToType: "",
        assignToId: "",
        regardingTo: this.$props.regards || "Loan",
        regardingType: "",
        regardingToId: "",
        taskType: "",
        task_type_id: "",
        dueDate: "",
        dueTime: "",
        description: "",
        status: "open",
        action: "Task created",
        application_id: "",
        attachment: "",
      },
    };
  },
  watch: {
    time() {
      this.formData.dueTime = this.time;
    },
    selectedUser(value) {
      if (value && value.Id) {
        this.getAssignedToId(value);
      }
    },
    selectedReg(value) {
      if (value && value.Id) {
        this.getRegardingToId(value);
      }
    },
  },

  computed: {
    ...mapGetters({
      userData: "loans/userData",
      appBrokerId: "applications/appBrokerId",
    }),

    loanPlaceHolder() {
      if (this.formData.regardingTo === "Loan") {
        return "Enter Loan ID";
      }

      return "Enter name";
    },
  },

  methods: {
    clickOutsideSearch() {},
    close() {
      this.$emit("close");
      this.clearData();
    },
    isSubmitDisabled() {
      return this.v$.$invalid;
    },

    clearData() {
      this.selectedUser = "";
      this.selectedReg = "";
      this.time = moment().format("hh:mm a");
      this.formData = {
        taskTitle: "",
        assignTo: this.$props.assigned || "User",
        assignToType: "",
        assignToId: "",
        regardingTo: "Loan",
        regardingType: "",
        regardingToId: "",
        taskType: "",
        task_type_id: "",
        dueDate: "",
        dueTime: "",
        description: "",
        status: "open",
        action: "created",
        application_id: "",
        attachment: "",
      };
    },

    async getUserList(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredUserList = [...this.usersList];
        } else {
          this.filteredUserList = this.usersList.filter((list) => {
            return list.name.toLowerCase().includes(event.query.toLowerCase());
          });

          if (!this.filteredUserList.length) {
            return (this.v$.$invalid = true);
          }

          this.filteredUserList.length &&
            this.filteredUserList.map((list) => {
              if (list.name !== event.query) {
                return (this.v$.$invalid = true);
              }
            });
        }
      }, 250);
    },

    async getRegList(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredRegList = [...this.regardingList];
        } else {
          this.filteredRegList = this.regardingList.filter((list) => {
            return list.name.toString().toLowerCase().includes(event.query.toLowerCase());
          });

          if (!this.filteredRegList.length) {
            return (this.v$.$invalid = true);
          }

          this.filteredUserList.length &&
            this.filteredUserList.map((list) => {
              if (list.name !== event.query) {
                return (this.v$.$invalid = true);
              }
            });
        }
      }, 250);
    },

    async save() {
      try {
        this.isloading = true;
        const response = await _post(SERVER_URL + "insert_task", this.formData);
        if (response) {
          this.$emit("close");
          this.$emit("save");
          this.isloading = false;
          this.clearData();

          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Task added successfully",
            life: 3000,
          });
        }
      } catch (err) {
        this.isloading = false;

        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    updateRegardingOpt(assignTo) {
      if (assignTo === "Customer") {
        this.regarding = ["Loan"];
        this.formData.regardingTo = "Loan";
      } else if (assignTo === "Broker") {
        this.regarding = ["Loan"];
        this.formData.regardingTo = "Loan";
      } else {
        this.regarding = ["Customer", "Broker", "Loan"];
        this.formData.regardingTo = "Customer";
      }
    },
    async getAssignedToId(value) {
      this.formData.assignToType =
        this.formData.assignTo == "Group"
          ? value.title
          : value.first_name + " " + value.last_name;
      this.formData.assignToId = value.Id;

      if (value.active_application_id) {
        this.appId = value.active_application_id;
      }

      if (
        this.formData.regardingTo === "Loan" &&
        this.appId &&
        this.formData.assignTo === "Customer"
      ) {
        const appList = await _get(
          APP_URL + `application_by_customer_crm?application_id=${this.appId}`
        );
        this.regardingList = appList.data.result;
        this.regardingList.map((obj) => {
          obj.name = obj.loan_id;
        });
      } else if (
        this.formData.regardingTo === "Loan" &&
        this.formData.assignToId &&
        this.formData.assignTo === "Broker"
      ) {
        const appList = await _get(
          APP_URL + `application_by_broker_crm?id=${this.formData.assignToId}`
        );
        this.regardingList = appList.data.result;
        this.regardingList.map((obj) => {
          obj.name = obj.loan_id;
        });
      } else if (value === "Loan" && this.formData.assignToId) {
        const appList = await _get(APP_URL + "active_applications_list");
        this.regardingList = appList.data.result;
        this.regardingList.map((obj) => {
          obj.name = obj.loan_id;
        });
      }
    },
    getRegardingToId(value) {
      if (this.formData.regardingTo === "Loan") {
        this.formData.regardingType = value.Id;
        this.formData.regardingToId = value.Id;
      } else {
        this.formData.regardingType = value.firstName + " " + value.lastName;
        this.formData.regardingToId = value.Id;
      }
      // this.formData.application_id = value.Id
    },
    getTaskType(value) {
      this.formData.taskType = value.taskTypes;
      this.formData.taskTitle = value.taskTypes;
      this.refreshKey++;
      this.formData.task_type_id = value.Id;
      this.formData.attachment = value.attachmentRequired;
    },
    async getAssignValue(value) {
      this.formData.assignTo = value;
      this.selectedUser = "";
      this.loadingUserList = true;
      this.loadingTaskTypes = true;
      // this.loadingRegList = true;
      // this.selectedReg = "";
      this.formData.taskType = "";

      if (this.$props.portal == "Loan" && value == "Customer") {
        // this.usersList = cusotmerList.data.result;
        // this.usersList.map((obj) => {
        //   obj.name = `${obj.firstName} ${obj.lastName}`;
        // });
      }

      this.updateRegardingOpt(value);

      if (value === "User") {
        const result = await _get(SERVER_URL + "get_task_types?type=user");
        this.taskTypes = result.data.result;
        this.loadingTaskTypes = false;
      } else {
        const result = await _get(SERVER_URL + "get_task_types?type=group");
        this.taskTypes = result.data.result;
        this.loadingTaskTypes = false;
      }

      if (
        (value === "User" || value === "Group") &&
        // && this.regardingTo === "Loan"
        this.regardingTo != "Loan"
      ) {
        const appList = await _get(APP_URL + "active_applications_list");
        this.regardingList = appList.data.result;
        this.regardingList.map((obj) => {
          obj.name = obj.loan_id;
        });
        this.loadingRegList = false;
      }

      if (value === "User") {
        const response = await _get(SERVER_URL + "crm_user");
        this.usersList = response.data.result;
        this.usersList.map((obj) => {
          obj.name = `${obj.firstName} ${obj.lastName}`;
        });
        this.loadingUserList = false;
      } else if (value === "Customer") {
        if (this.$props.portal == "Loan") {
          const cusotmerList = await _get(
            APP_URL + `get_applicant_details?application_id=${this.$route.params.id}`
          );
          this.usersList = cusotmerList.data.applicants;
          this.usersList.map((obj) => {
            obj.name = `${obj.first_name} ${obj.last_name}`;
            if (obj.applicant_type == "primary") {
              this.selectedUser = obj.name;
            }
          });
          this.loadingUserList = false;
          this.loadingRegList = false;
        } else if (this.$props.portal == "Customer") {
          const cusotmerList = await _get(
            SERVER_URL + `get_customer?id=${this.$route.params.id}`
          );
          this.usersList = cusotmerList.data.result;
          this.usersList.map((obj) => {
            obj.name = `${obj.firstName} ${obj.lastName}`;
          });
          this.loadingUserList = false;
          this.loadingRegList = false;
        } else {
          const cusotmerList = await _get(SERVER_URL + "get_customers");
          this.usersList = cusotmerList.data.result;
          this.usersList.map((obj) => {
            obj.name = `${obj.firstName} ${obj.lastName}`;
          });
          this.loadingUserList = false;
          this.loadingRegList = false;
        }
      } else if (value === "Broker") {
        if (this.$props.portal == "Broker") {
          const brokerList = await _get(
            SERVER_URL + `get_broker_crm_by_id?id=${this.$route.params.id}`
          );
          this.usersList = brokerList.data;
          this.usersList.map((obj) => {
            obj.name = `${obj.firstName} ${obj.lastName}`;
          });
          this.loadingUserList = false;
          this.loadingRegList = false;
        } else {
          const brokersList = await _get(SERVER_URL + "get_broker_crm");
          this.usersList = brokersList.data.result;
          this.usersList.map((obj) => {
            obj.name = `${obj.firstName} ${obj.lastName}`;
          });
          if (this.appBrokerName) {
            this.selectedUser = this.appBrokerName;
            this.formData.assignToId = this.appBrokerId;
            this.formData.assignToType = this.appBrokerName;
          }
          this.loadingUserList = false;
          this.loadingRegList = false;
        }
      } else if (value === "Group") {
        const groupList = await _get(SERVER_URL + "get-group");
        this.usersList = groupList.data.result;
        this.usersList.map((obj) => {
          obj.name = obj.title;
        });
        this.loadingUserList = false;
        this.loadingRegList = false;
      }
      this.formData.assignToType = this.selectedUser;
    },
    async getRegValue(value) {
      this.formData.regardingTo = value;
      this.selectedReg = "";
      this.loadingRegList = true;
      this.loanPlaceHolder;

      if (
        value === "Loan" &&
        this.appId &&
        this.formData.assignToId &&
        this.formData.assignTo === "Customer"
      ) {
        const appList = await _get(
          APP_URL + `application_by_customer_crm?application_id=${this.appId}`
        );
        this.regardingList = appList.data.result;
        this.regardingList.map((obj) => {
          obj.name = obj.loan_id;
        });
        this.loadingRegList = false;
      } else if (
        value === "Loan" &&
        this.formData.assignToId &&
        this.formData.assignTo === "Broker"
      ) {
        const appList = await _get(
          APP_URL + `application_by_broker_crm?id=${this.formData.assignToId}`
        );
        this.regardingList = appList.data.result;
        this.regardingList.map((obj) => {
          obj.name = obj.loan_id;
        });
        this.loadingRegList = false;
      } else if (value === "Loan") {
        const appList = await _get(APP_URL + "active_applications_list");
        this.regardingList = appList.data.result;
        this.regardingList.map((obj) => {
          obj.name = obj.loan_id;
        });
        this.loadingRegList = false;
      }

      if (value === "Customer") {
        const cusotmerList = await _get(SERVER_URL + "get_customers");
        this.regardingList = cusotmerList.data.result;
        this.regardingList.map((obj) => {
          obj.name = `${obj.firstName} ${obj.lastName}`;
        });
        this.loadingRegList = false;
      } else if (value === "Broker") {
        const brokersList = await _get(SERVER_URL + "get_broker_crm");
        this.regardingList = brokersList.data.result;
        this.regardingList.map((obj) => {
          obj.name = `${obj.firstName} ${obj.lastName}`;
        });
        this.loadingRegList = false;
      }
    },
    async getCrmUser() {
      this.loadingUserList = true;
      const response = await _get(SERVER_URL + "get_crm_user_profile");

      if (response) {
        const user = response.data.result[0];
        this.formData.assignToType = user.firstName + " " + user.lastName;
        this.formData.assignToId = user.Id;
        this.selectedUser = this.formData.assignToType;
        this.refreshKey++;
        this.loadingUserList = false;
      }
    },
    getLoanId(id) {
      this.loadingRegList = true;
      this.selectedReg = this.regardingList.find((list) => {
        if (list.Id === id) {
          return list.loan_id;
        }
      });
      this.refreshKey++;

      this.loadingRegList = false;
    },
    async getCustomer() {
      this.loadingUserList = true;

      let filteredUser;

      filteredUser = this.usersList.find((list) => {
        if (list.Id === this.$route.params.id) {
          this.formData.assignToType = list.firstName + " " + list.lastName;
          this.formData.assignToId = list.Id;

          this.appId = list.active_application_id;
          return list;
        }
      });

      this.selectedUser = filteredUser.firstName + " " + filteredUser.lastName;
      await this.getAssignedToId(filteredUser);
      this.getLoanId(this.appId);

      this.refreshKey++;

      this.loadingUserList = false;
    },
  },
  async created() {
    this.minDate = new Date();
    this.time = moment().format("hh:mm a");
    if (this.formData.assignTo === "User") {
      const result = await _get(SERVER_URL + "get_task_types?type=user");
      this.taskTypes = result.data.result;
    } else {
      const result = await _get(SERVER_URL + "get_task_types?type=group");
      this.taskTypes = result.data.result;
    }
    const response = await _get(SERVER_URL + "crm_user");
    this.usersList = response.data.result;
    this.usersList.map((obj) => {
      obj.name = `${obj.firstName} ${obj.lastName}`;
    });

    // broker details
    if (this.appBrokerId) {
      const res = await _get(SERVER_URL + "get_broker_crm_by_id?id=" + this.appBrokerId);

      if (res) {
        this.appBrokerName = res.data[0]?.firstName + " " + res.data[0]?.lastName;
      }
    }

    // loan/task
    if (this.$props.assigned === "User" && this.$props.regardId) {
      await this.getCrmUser();
      await this.getRegValue("Loan");
      this.getLoanId(this.$route.params.id);
    }

    // loan/customer
    else if (this.$props.assigned === "Customer") {
      await this.getAssignValue("Customer");
      this.getCustomer();
      await this.getRegValue("Loan");
    }

    // loan/broker
    else if (this.$props.assigned === "Broker") {
      await this.getAssignValue("Broker");
      this.getCustomer();
      await this.getRegValue("Loan");
    }
  },
  unmounted() {
    this.clearData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
:deep .p-autocomplete-dd .p-autocomplete-dropdown {
  background: $primary-color !important;
  border: 1px solid $primary-color !important;
}

::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

:deep .p-autocomplete-input,
.p-autocomplete {
  width: 100% !important;
}
</style>
