<template>
  <div class="pl-4 pr-4 pb-4">
    <Card>
      <template #title><span class="module-sub-title">Customer details</span></template>
      <template #content>
        <Spinner v-if="isloading" class="flex justify-content-between" />
        <div v-else>
          <div
            v-for="customer of customerDetails"
            :key="customer.Id"
            class="customer-details p-4 mb-4"
          >
            <div class="flex justify-content-between align-items-center">
              <div class="title flex align-items-center capital">
                <a v-if="customer.preferred_name" @click="goToApp(customer.customer_id)"
                  >{{ customer.preferred_name }} {{ customer.middle_name }}
                  {{ customer.last_name }}</a
                >
                <a v-else @click="goToApp(customer.customer_id)"
                  >{{ customer.first_name }} {{ customer.middle_name }}
                  {{ customer.last_name }}</a
                >

                <span class="ml-2 id"></span>
              </div>
              <div
                v-if="
                  customerDetails &&
                  customerDetails.length > 1 &&
                  customer.applicant_type === 'secondary'
                "
                class="flex align-items-center"
              >
                <div class="relationship">
                  Relationship to {{ getPrimaryApplicantName(customerDetails) }}
                </div>
                <Dropdown
                  :disable="true"
                  class="ml-1"
                  :items="items"
                  optionLabel="name"
                  optionValue="value"
                  :value="relationToPrimaryApplicant"
                  placeholder="please select"
                />
                <!-- <div @click="setAsprimaryApplicant(idx)">
                  <Button
                    label="Set as a primary applicant"
                    color="#ed653d"
                    bgcolor="#ffffff"
                    class="ml-1"
                  />
                </div>
                <Button label="Remove Applicant" type="primary" class="ml-1" /> -->
              </div>
            </div>
            <div class="divider mt-2 mb-3"></div>
            <div class="flex">
              <div class="col">
                <div class="sub-title">
                  Address:
                  <span class="bold-500"
                    >{{ customer.add_1 }}, {{ customer.city }},
                    {{ customer.state }}
                  </span>
                </div>
                <div class="sub-title mt-2">
                  Mobile:
                  <span class="bold-500">{{ customer.mobile }}</span>
                </div>
              </div>
              <div class="col">
                <div class="sub-title">
                  Date of birth:
                  <span class="bold-500">{{ formatDate(customer.dob) }}</span>
                </div>
                <div class="sub-title mt-2">
                  Email:
                  <span class="bold-500">{{ customer.email || "-" }}</span>
                </div>
              </div>
              <div class="col">
                <div class="sub-title">
                  Applicant Status:
                  <span
                    v-if="customer.relationShipStatus || customer.relationShipStatus"
                    class="bold-500 capital"
                  >
                    {{ customer.relationShipStatus }}
                  </span>
                  <span v-else>-</span>
                </div>
                <div class="sub-title mt-2">
                  Applicant dependents:
                  <span class="bold-500">{{ customer.dependents + " dependents" }} </span>
                </div>
              </div>
            </div>
          </div>
          <Button
            @click="openAddApplicant"
            v-if="this.customerDetails.length < 2"
            label="Add Applicant"
            type="primary"
            class="mt-4"
          />
        </div>
      </template>
    </Card>
    <AddApplicant
      v-if="showAddApplicant"
      :show="showAddApplicant"
      @close="showAddApplicant = false"
      @save="refreshKey++"
    />
  </div>
</template>

<script>
import Card from "primevue/card";
import { APP_URL } from "../../../../../constants";
import { _get } from "../../../../services/crudService";
import moment from "moment";
import AddApplicant from "./AddApplicant.vue";
import { mapActions } from "vuex";

export default {
  name: "CustomerDetails",
  components: {
    Card,
    AddApplicant,
  },
  data() {
    return {
      showAddApplicant: false,
      showAddApplicantBtn: false,
      crm_status: "",
      items: [
        { name: "Married/Defacto", value: "married" },
        { name: "Siblings", value: "siblings" },
        { name: "Friends", value: "friend" },
        { name: "Other", value: "other" },
      ],

      loanDetails: [],
      isloading: false,
      relationToPrimaryApplicant: "",
      applicantRelationship: "",
      applicationRootDetails: [],
      customerDetails: [],
      refreshKey: 10,
    };
  },
  watch: {
    refreshKey() {
      this.getCustomerDetails();
    },
  },
  methods: {
    ...mapActions({
      reloadAppSummary: "loanOverview/reloadAppSummary",
      setApplicationId: "customers/setApplicationId",
    }),
    goToApp(id) {
      this.setApplicationId(this.$route.params.id);
      this.$router.push(`/customers/${id}/detail`);
    },
    displayAddApplicantBtn() {
      if (this.customerDetails && this.customerDetails.length < 2) {
        this.showAddApplicantBtn = true;
      } else {
        this.showAddApplicantBtn = false;
      }
    },
    openAddApplicant() {
      return (this.showAddApplicant = true);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getPrimaryApplicantName(customer) {
      let applicants = customer.filter((data) => data.applicant_type === "primary");
      let primaryApplicantName = applicants.map((data) => {
        return data.first_name;
      });
      return primaryApplicantName[0];
    },
    getRelationshipStatus(customerDetails) {
      this.applicationRootDetails &&
        this.applicationRootDetails.find((data) => {
          if (customerDetails.applicant_type === "primary") {
            this.applicantRelationship = data.applicant_1_relationship;
          } else if (customerDetails.applicant_type === "secondary") {
            this.applicantRelationship = data.applicant_2_relationship;
          }
        });
      return this.applicantRelationship;
    },
    async getCustomerDetails() {
      try {
        this.isloading = true;
        const response = await _get(
          APP_URL + `get_applicant_details?application_id=${this.$route.params.id}`
        );

        if (response) {
          this.customerDetails = response.data.applicants.sort(function (asc, desc) {
            if (asc.applicant_type < desc.applicant_type) {
              return -1;
            }
            if (asc.applicant_type > desc.applicant_type) {
              return 1;
            }
            return 0;
          });
          this.applicationRootDetails = response.data.application_root;
          this.relationToPrimaryApplicant =
            response.data.application_root &&
            response.data.application_root[0].applicant_2_relationship_with_applicant_1;
          this.isloading = false;
          this.customerDetails.map((obj) => {
            if (obj.applicant_type == "primary") {
              obj.relationShipStatus = this.applicationRootDetails[0].applicant_1_relationship;
              obj.dependents = this.applicationRootDetails[0].applicant_1_dependents;
            } else {
              obj.relationShipStatus = this.applicationRootDetails[0].applicant_2_relationship;
              obj.dependents = this.applicationRootDetails[0].applicant_2_dependents;
            }
          });
        }
      } catch (error) {
        this.isloading = false;
        console.log(error);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    async getAppStatus() {
      try {
        this.isloading = true;

        const getAppStatus = await _get(
          APP_URL + `application_status?id=${this.$route.params.id}`
        );

        if (getAppStatus) {
          this.crm_status = getAppStatus.data.result.crm_status;
          this.isloading = false;
        }
      } catch (err) {
        this.isloading = false;
      }
      switch (this.crm_status) {
        case "application_in_progress":
        case "assessment_ready":
        case "workshopping_application":
        case "pre-approval":
          this.showAddApplicantBtn = true;
          break;
        default:
          this.showAddApplicantBtn = false;
          break;
      }
    },
  },
  async created() {
    this.reloadAppSummary();
    await this.getCustomerDetails();
    await this.displayAddApplicantBtn();
    await this.getAppStatus();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.p-card {
  box-shadow: unset !important;
  border-radius: 6px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  height: 100% !important;
}
::v-deep .p-card-title {
  color: #020038 !important;
}
::v-deep .p-card-content {
  font-size: 14px !important;
  color: #020038 !important;
  font-weight: 500;
  font-size: 14px;
}
.customer-details {
  background: #ffffff;
  border: 1px solid rgba(2, 0, 56, 0.2);
  border-radius: 8px;
  width: 100%;
  .title {
    color: $primary-color;
    font-size: 18px;
    font-weight: 600;
    .id {
      color: $primary-color;
    }
  }
}
.divider {
  border: 1px solid rgba(2, 0, 56, 0.15);
}
.sub-title {
  color: #020038;
  font-weight: bold;
  font-size: 14px;
}
.col {
  padding: unset;
}
.relationship {
  color: rgba(2, 0, 56, 0.4);
  font-weight: 600;
  font-size: 16px;
}
</style>
