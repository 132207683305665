<template>
  <div class="mr-b mt-5 ml-2">
    <Panel :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>
      <template #header>
        <div @click="toggle" class="title-wrapper full-width pointer">
          <p class="title">Completed Tasks</p>
        </div>
      </template>
      <Spinner v-if="isLoading" class="flex justify-content-between" />
      <DataTable
        v-else
        :rows="10"
        selectionMode="multiple"
        dataKey="id"
        :value="taskList"
        :rowsPerPageOptions="[10, 20, 30]"
      >
        <template #empty> No data found. </template>

        <Column style="max-width: 5rem" field="loan_id" header="Loan ID">
          <template #body="slotProps"
            ><a
              @click="
                goToApp(slotProps.data.application_id, slotProps.data.loan_id)
              "
              class="color-highlight"
              >{{ slotProps.data?.loan_id || "-" }}</a
            >
          </template>
        </Column>
        <Column style="max-width: 5rem" field="loan_id" header="Loan ID">
          <template #body="slotProps"
            ><a
              @click="
                goToApp(slotProps.data.application_id, slotProps.data.loan_id)
              "
              class="color-highlight"
              >{{ slotProps.data?.loan_id || "-" }}</a
            >
          </template>
        </Column>
        <Column field="taskTitle" :style="{ maxWidth: '15rem' }" header="Task">
          <template #body="slotProps">
            {{ slotProps.data?.taskTitle || "-" }}
          </template>
        </Column>
        <Column field="crm_status" header="Stage">
          <template #body="slotProps">
            {{
              slotProps.data?.crm_status
                ? removeUnderscore(slotProps.data?.crm_status)
                : "-"
            }}
          </template>
        </Column>
        <Column field="dueDate" header="Task due">
          <template #body="slotProps"
            ><div>{{ slotProps.data?.dueDate || "-" }}</div>
          </template>
        </Column>
      </DataTable>
      <Paginator
        class="mt-2"
        :rows="10"
        :totalRecords="taskList && taskList.length"
        :rowsPerPageOptions="[10, 20, 30]"
        template="RowsPerPageDropdown  CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} of {totalRecords} tasks"
        @page="onChangePage($event)"
      ></Paginator>
    </Panel>
  </div>
</template>

<script>
import Panel from "primevue/panel";
import DataTable from "primevue/datatable";
import Paginator from "primevue/paginator"; 
import Column from "primevue/column";
import { _get } from "../../../../services/crudService";
import { SERVER_URL } from "../../../../../constants";

export default {
  props: ["selectedOpt", "filterField", "filterValue"],
  components: {
    Panel,
    DataTable,
    Column,
    Paginator,
  },
  data() {
    return {
      collapsed: false,
      taskList: [],
      taskToComplete: [],
      isLoading: false,
    };
  },
  watch: {
    selectedOpt(value) {
      if (value === "Applications") {
        this.applicationsList(1, 10);
      } else if (value === "Funded") {
        this.fundedlist(1, 10);
      } else if (value === "Arrears") {
        this.arrearList(1, 10);
      }
    },
    filterValue() {
      if (this.refreshKey > 0) {
        if (this.$props.selectedOpt === "Applications") {
          this.applicationsList(1, 10);
        } else if (this.$props.selectedOpt === "Funded") {
          this.fundedlist(1, 10);
        } else if (this.$props.selectedOpt === "Arrears") {
          this.arrearList(1, 10);
        }
      }
    },
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
    goToApp(id, loan_id) {
      if (id && loan_id) {
        this.$router.push(`/loan/${id}/overview?loanId=${loan_id}`);
      }
    },
    removeUnderscore(value) {
      if (value) {
        return (
          value.charAt(0).toUpperCase() + value.slice(1).replaceAll("_", " ")
        );
      }
    },
    onChangePage(value) {
      if (this.$props.selectedOpt === "Applications") {
        this.applicationsList(value.page + 1, value.rows);
      } else if (this.$props.selectedOpt === "Funded") {
        this.fundedlist(value.page + 1, value.rows);
      } else if (this.$props.selectedOpt === "Arrears") {
        this.arrearList(value.page + 1, value.rows);
      }
    },
    async applicationsList(page, row) {
      this.isLoading = true;
      this.taskList = [];
      const response = await _get(
        SERVER_URL +
          `get_pipeline_task?pageSize=${row}&pageIndex=${page}&field=${this.filterField}&value=${this.filterValue}&status=application`
      );
      if (response) {
        response.data.result.map((data) => {
          if (data.status === "completed") {
            this.taskList.push(data);
          }
        });

        this.isLoading = false;
      }
    },

    async fundedlist(page, row) {
      this.isLoading = true;
      this.taskList = [];

      const response = await _get(
        SERVER_URL +
          `get_pipeline_task?pageSize=${row}&pageIndex=${page}&field=${this.filterField}&value=${this.filterValue}&status=funded`
      );
      if (response) {
        response.data.result.map((data) => {
          if (data.status === "completed") {
            this.taskList.push(data);
          }
        });
        this.isLoading = false;
      }
    },

    async arrearList(page, row) {
      this.isLoading = true;
      this.taskList = [];

      const response = await _get(
        SERVER_URL +
          `get_pipeline_task?pageSize=${row}&pageIndex=${page}&field=${this.filterField}&value=${this.filterValue}&status=arrears`
      );
      if (response) {
        response.data.result.map((data) => {
          if (data.status === "completed") {
            this.taskList.push(data);
          }
        });
        this.isLoading = false;
      }
    },
  },
  created() {
    if (this.selectedOpt === "Applications") {
      this.applicationsList(1, 10);
    } else if (this.selectedOpt === "Funded") {
      this.fundedlist(1, 10);
    } else if (this.selectedOpt === "Arrears") {
      this.arrearList(1, 10);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
.color-highlight {
  color: $primary-color !important;
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0 !important;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid rgba(2, 0, 56, 0.15) !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

:deep .p-panel {
  border: none !important;
  box-shadow: none !important;
  padding: none !important;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.std-padding-x {
  padding: 0 2.188rem;
}

.flex-gap {
  gap: 0.625rem;
}

.title {
  font-size: 20px;
  font-family: Gordita-bold;
  color: $secondary-color;
}

.box {
  background: #ffffff;
  border: 1px solid rgba(2, 0, 56, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 1.25rem;
}

p {
  margin: 0;
}

.mr-top {
  margin-top: 40px;
}
</style>
