<template>
  <div class="mr-top">
    <Panel :key="refreshKey" :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>
      <template #header>
        <div @click="toggle" class="sub-title full-width pointer">
          Mortgage Payout Estimate
        </div>
      </template>
      <div class="form-inputs mr-top-10px">
        <div class="p-d-flex flex-gap align-items-end mr-b-5px">
          <div class="disable-input">
            <p class="label">+ Current Mortgage</p>
            <NumberField
              mode="decimal"
              :format="true"
              prefix="$"
              :value="current_mortgage ? current_mortgage.toFixed(2) : 0"
              :disable="true"
              width="100%"
            />
          </div>

          <div class="disable-input">
            <p class="label">+ Monthly Repayment</p>
            <NumberField
              mode="decimal"
              :format="true"
              prefix="$"
              :disable="true"
              :value="monthly_repayment"
              width="100%"
            />
          </div>

          <div class="disable-input">
            <p class="label">+ 1 Months Interest</p>
            <NumberField
              mode="decimal"
              :format="true"
              prefix="$"
              :disable="true"
              :value="month_interest"
              width="100%"
            />
          </div>

          <div class="disable-input">
            <p class="label">+ Buffer</p>
            <NumberField
              mode="decimal"
              :format="true"
              prefix="$"
              :disable="true"
              :value="buffer"
              width="100%"
            />
          </div>

          <div class="disable-input">
            <p class="label">+ Discharge Fee</p>
            <NumberField
              mode="decimal"
              :format="true"
              prefix="$"
              :disable="true"
              :value="discharge_fee"
              width="100%"
            />
          </div>

          <div class="highlight-fields disable-input">
            <p class="label">Mortgage Payout Estimate</p>
            <NumberField
              mode="decimal"
              :format="true"
              prefix="$"
              :disable="true"
              :value="mortgage_payout ? mortgage_payout.toFixed(2) : 0"
              width="100%"
            />
          </div>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Panel from "primevue/panel";

export default {
  components: { Panel },
  data() {
    return {
      current_mortgage: 0,
      monthly_repayment: 0,
      month_interest: 0,
      buffer: 500,
      discharge_fee: 360,
      mortgage_payout: 0,
      collapsed: true,
      refreshKey: 10,
    };
  },
  computed: {
    ...mapGetters({
      sellingProperty: "applications/sellingProperty",
      selectedApplication: "applications/selectedApplication",
    }),
  },
  methods: {
    ...mapActions({ setMortgagePayout: "summary/setMortgagePayout" }),
    toggle() {
      this.collapsed = !this.collapsed;
    },
  },
  watch: {
    sellingProperty() {
      //Current Mortgage
      this.current_mortgage =
        this.selectedApplication?.calculated_fields &&
        this.selectedApplication?.calculated_fields?.current_mortgage
          ? Number(
              this.selectedApplication?.calculated_fields?.current_mortgage
            )
          : 0;
      this.setMortgagePayout({
        field: "current_mortgage",
        value: this.current_mortgage,
      });
      //Monthly repayments
      this.monthly_repayment =
        this.selectedApplication?.calculated_fields &&
        this.selectedApplication?.calculated_fields?.monthly_repayment
          ? this.selectedApplication?.calculated_fields?.monthly_repayment
          : 0;
      this.setMortgagePayout({
        field: "monthly_repayment",
        value: this.monthly_repayment,
      });
      //Month Interest
      this.month_interest = 0;
      this.setMortgagePayout({
        field: "month_interest",
        value: this.month_interest,
      });
      //Buffer
      this.buffer =
        this.selectedApplication?.calculated_fields &&
        this.selectedApplication?.calculated_fields?.buffer
          ? this.selectedApplication?.calculated_fields?.buffer
          : 500;
      this.buffer = this.monthly_repayment > 0 ? this.buffer : 0;
      this.setMortgagePayout({ field: "buffer", value: this.buffer });
      //Discharge fee
      this.discharge_fee =
        this.selectedApplication?.calculated_fields &&
        this.selectedApplication?.calculated_fields?.discharge_fee
          ? this.selectedApplication?.calculated_fields?.discharge_fee
          : 360;
      this.discharge_fee = this.monthly_repayment > 0 ? this.discharge_fee : 0;
      this.setMortgagePayout({
        field: "discharge_fee",
        value: this.discharge_fee,
      });
      //Mortgage payout
      // this.mortgage_payout =
      //   this.current_mortgage +
      //   this.monthly_repayment +
      //   this.month_interest +
      //   this.buffer +
      //   this.discharge_fee;
      this.mortgage_payout = this.selectedApplication?.calculated_fields &&
        this.selectedApplication?.calculated_fields?.mortgage_payout_estimate
          ? this.selectedApplication?.calculated_fields?.mortgage_payout_estimate
          : 0;
      this.refreshKey += 1;
      this.setMortgagePayout({
        field: "mortgage_payout",
        value: this.mortgage_payout,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid #02003826 !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

.highlight-fields {
  .label {
    font-family: Gordita-bold !important;
  }
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

::v-deep .p-col-4,
.p-col-2 {
  padding-bottom: 0;
  padding-top: 0;
}

.mr-b-5px {
  margin-bottom: 5px;
}

.mr-top {
  margin-top: 2.5rem;
}

.mr-top-10px {
  margin-top: 10px;
}

.flex-gap {
  gap: 0.625rem;
}
</style>
