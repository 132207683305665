<template>
  <div>
    <div class="content">
      <div class="form-inputs mt-4">
        <div class="p-grid align-items-center header">
          <p class="col"></p>
          <p class="col label">Application Values</p>
          <p class="col label">Current Values</p>
          <p class="col label">Current Value Source</p>
          <div class="col"></div>
        </div>

        <InputRow
          label="Outgoing Max LVR%"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.op_lvr_max * 100
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.op_lvr_max * 100
              : ''
          "
        />
        <InputRow
          label="Incoming Max LVR%"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.ip_lvr_max * 100
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.ip_lvr_max * 100
              : ''
          "
        />
        <!-- <InputRow label="Broker Fee $" /> -->
        <InputRow
          label="WALVR%"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.walvr * 100
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.walvr * 100
              : ''
          "
        />
        <InputRow
          label="Initial LVR%"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.initial_lvr * 100
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.initial_lvr * 100
              : ''
          "
        />
        <InputRow
          label="Shaded Value"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.shaded_value
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.shaded_value
              : ''
          "
        />
        <InputRow
          label="Interest (Capitalising Loan)"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.interest_on_caps_loan
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.interest_on_caps_loan
              : ''
          "
        />
        <InputRow
          label="Peak Debt"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.peak_debt
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.peak_debt
              : ''
          "
        />
        <InputRow
          label="Peak Debt LVR%"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.peak_debt_LVR * 100
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.peak_debt_LVR * 100
              : ''
          "
        />
        <InputRow
          label="Selling Costs"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.sale_cost
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.sale_cost
              : ''
          "
        />
        <InputRow
          label="End Debt"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.end_debt
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.end_debt
              : ''
          "
        />
        <InputRow
          label="End Debt LVR%"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.end_debt_LVR * 100
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.end_debt_LVR * 100
              : ''
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputRow from "../../InputRow.vue";
export default {
  components: { InputRow },
  computed: {
    ...mapGetters({
      originalData: "loans/originalData",
      latestData: "loans/latestData",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
</style>
