<!-- eslint-disable no-mixed-spaces-and-tabs -->
<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
	<div :key="refreshKey">
		<div class="heading flex align-items-center justify-content-between">
			<p class="sub-title">Full-time Income</p>

			<Button @click="addIncome" type="primary" label="Add Full-time Income" />
		</div>

		<div>
			<div v-for="(item, i) in details" :key="i">
				<div class="form-inputs mr-top">
					<div class="p-grid align-items-end" id="fullTimeIncomeForm">
						<!-- Col-1 -->
						<div class="p-col-10">
							<!-- Row-1 -->
							<div class="p-grid align-items-end mr-b-5px">
								<div class="p-col-4">
									<p class="label">Name of current employer</p>
									<TextField
										@onInput="
											(e) => getFormData(e, 'name_of_employer', i)
										"
										:value="item.name_of_employer"
										width="100%"
										:inValid="
											v$.details.$silentErrors[0]?.$response.$data[
												i
											].name_of_employer.$invalid && saveBtnClicked
										"
										id="full_time_income_name_of_employer"
									/>
								</div>
								<div class="p-col-4">
									<p class="label">Taxable</p>
									<Dropdown
										:items="taxableArray"
										:value="item.is_taxable"
										width="100%"
										@change="(e) => getFormData(e, 'is_taxable', i)"
										optionLabel="text"
										optionValue="value"
										id="full_time_income_is_taxable"
									/>
								</div>
							</div>

							<!-- Row-2 -->

							<div class="p-d-flex align-items-center mr-b-5px">
								<div class="flex flex-gap-1">
									<div>
										<p class="label">Salary</p>
										<NumberField
											@onInput="
												(e) =>
													getFormData(
														e,
														'salary_amount_used',
														i
													)
											"
											type="number"
											:disable="
												item.salary_amount_used_value !=
												'manual_value'
											"
											:value="item.salary_amount_used"
											width="100%"
											:format="true"
											prefix="$"
											:inValid="
												v$.details.$silentErrors[0]?.$response
													.$data[i].salary_amount_used
													.$invalid && saveBtnClicked
											"
											id="full_time_income_salary_amount_used"
										/>
									</div>

									<div>
										<p class="label">Frequency</p>
										<Dropdown
											:items="frequencyOpt"
											:value="item.salary_frequency"
											width="100%"
											@change="
												(e) =>
													getFormData(e, 'salary_frequency', i)
											"
											:disable="true"
											id="full_time_income_salary_frequency"
										/>
									</div>

									<div class="disable-input">
										<p class="label">Ratio%</p>
										<TextField
											:disable="true"
											:value="
												calculateRatio(
													item.salary_amount_used,
													monthlySubtotal(i),
													item.salary_frequency
												)
											"
											width="100%"
											id="full_time_income_ratio"
										/>
									</div>
								</div>

								<div class="flex flex-gap-1 ml-3">
									<div>
										<p class="label">Salary Packaged</p>
										<NumberField
											type="number"
											@onInput="
												(e) =>
													getFormData(
														e,
														'salary_packaged_used',
														i
													)
											"
											:value="item.salary_packaged_used"
											:disable="
												item.salary_amount_used_value !=
												'manual_value'
											"
											width="100%"
											:format="true"
											prefix="$"
											:inValid="
												v$.details.$silentErrors[0]?.$response
													.$data[i].salary_packaged_used
													.$invalid && saveBtnClicked
											"
											id="full_time_income_salary_packaged_used"
										/>
									</div>

									<div>
										<p class="label">Frequency</p>
										<Dropdown
											:items="frequencyOpt"
											:value="item.salary_frequency"
											width="100%"
											:disable="true"
										/>
									</div>

									<div class="disable-input">
										<p class="label">Ratio%</p>
										<TextField
											:disable="true"
											:value="
												calculateRatio(
													item.salary_packaged_used,
													monthlySubtotal(i),
													item.salary_frequency
												)
											"
											width="100%"
										/>
									</div>
								</div>
							</div>

							<!-- Row-3 -->

							<div class="p-d-flex align-items-center mr-b-5px">
								<div class="flex flex-gap-1">
									<div>
										<p class="label">Overtime</p>
										<NumberField
											type="number"
											:disable="
												item.overtime_pay_amount_used_value !=
												'manual_value'
											"
											@onInput="
												(e) =>
													getFormData(
														e,
														'overtime_pay_amount_used',
														i
													)
											"
											:value="item.overtime_pay_amount_used"
											width="100%"
											:format="true"
											prefix="$"
											:inValid="
												v$.details.$silentErrors[0]?.$response
													.$data[i].overtime_pay_amount_used
													.$invalid && saveBtnClicked
											"
										/>
									</div>

									<div>
										<p class="label">Frequency</p>
										<Dropdown
											:items="frequencyOpt"
											width="100%"
											:value="item.overtime_pay_frequency"
											@change="
												(e) =>
													getFormData(
														e,
														'overtime_pay_frequency',
														i
													)
											"
											:disable="true"
										/>
									</div>

									<div class="disable-input">
										<p class="label">Ratio%</p>
										<TextField
											:disable="true"
											:value="
												calculateRatio(
													item.overtime_pay_amount_used,
													monthlySubtotal(i),
													item.overtime_pay_frequency
												)
											"
											width="100%"
										/>
									</div>
								</div>

								<div class="flex flex-gap-1 ml-3">
									<div>
										<p class="label">Commission</p>
										<NumberField
											type="number"
											:disable="
												item.commissions_amount_used_value !=
												'manual_value'
											"
											@onInput="
												(e) =>
													getFormData(e, 'commissions_used', i)
											"
											:value="item.commissions_used"
											width="100%"
											:format="true"
											prefix="$"
											:inValid="
												v$.details.$silentErrors[0]?.$response
													.$data[i].commissions_used.$invalid &&
												saveBtnClicked
											"
										/>
									</div>

									<div>
										<p class="label">Frequency</p>
										<Dropdown
											:items="frequencyOpt"
											:value="item.commissions_frequency"
											@change="
												(e) =>
													getFormData(
														e,
														'commissions_frequency',
														i
													)
											"
											width="100%"
											:disable="true"
										/>
									</div>

									<div class="disable-input">
										<p class="label">Ratio%</p>
										<TextField
											:disable="true"
											:value="
												calculateRatio(
													item.commissions_used,
													monthlySubtotal(i),
													item.commissions_frequency
												)
											"
											width="100%"
										/>
									</div>
								</div>

								<div class="flex flex-gap-1 ml-3">
									<div>
										<p class="label">Bonuses</p>
										<NumberField
											type="number"
											:disable="
												item.bonuses_amount_used_value !=
												'manual_value'
											"
											@onInput="
												(e) =>
													getFormData(
														e,
														'bonuses_amount_used',
														i
													)
											"
											:value="item.bonuses_amount_used"
											width="100%"
											:format="true"
											prefix="$"
											:inValid="
												v$.details.$silentErrors[0]?.$response
													.$data[i].bonuses_amount_used
													.$invalid && saveBtnClicked
											"
										/>
									</div>

									<div>
										<p class="label">Frequency</p>
										<Dropdown
											:items="frequencyOpt"
											:value="item.bonuses_frequency"
											@change="
												(e) =>
													getFormData(e, 'bonuses_frequency', i)
											"
											width="100%"
											:disable="true"
										/>
									</div>

									<div class="disable-input">
										<p class="label">Ratio%</p>
										<TextField
											:disable="true"
											:value="
												calculateRatio(
													item.bonuses_amount_used,
													monthlySubtotal(i),
													item.bonuses_frequency
												)
											"
											width="100%"
										/>
									</div>
								</div>
							</div>
						</div>

						<!-- Col-2 -->
						<div class="p-col-2 highlight-fields">
							<div class="mr-b-5px disable-input">
								<p class="label">Monthly Sub Total</p>
								<TotalField
									:value="monthlySubtotal(i)"
									:disable="true"
									placeholder="label"
									width="100%"
									mode="decimal"
									:format="true"
									prefix="$"
                  :id="`fullTimeMmonthlySubtotal_${i}`"
								/>
							</div>
						</div>
					</div>
				</div>

				<div class="flex align-items-center">
					<Button
						@click="removeIncome(i)"
						type="outline"
						label="Remove Income"
					/>
					<!-- <img class="ml-3" src="@/assets/Revision.svg" alt="" /> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
	props: ["disable"],
	setup: () => ({ v$: useVuelidate() }),
	validations() {
		return {
			details: {
				// required,
				$each: helpers.forEach({
					name_of_employer: { required },
					salary_amount_used: { required },
					salary_packaged_used: { required },
					salary_frequency: { required },
					overtime_pay_amount_used: { required },
					overtime_pay_frequency: { required },
					commissions_used: { required },
					commissions_frequency: { required },
					bonuses_amount_used: { required },
					bonuses_frequency: { required },
					is_taxable: { required },
				}),
			},
		};
	},
	data() {
		return {
			disableFields: this.$props.disable,
			details: [],
			refreshKey: 10,
			totalKey: 20,
			subTotal: [],
			frequencyOpt: ["Weekly", "Fortnightly", "Monthly", "Quarterly", "Yearly"],
			taxableArray: [
				{ text: "Yes", value: true },
				{ text: "No", value: false },
			],
		};
	},
	computed: {
		...mapGetters({
			selectedApplication: "applications/selectedApplication",
			fullTimeUpdated: "applications/fullTimeUpdated",
			saveBtnClicked: "applications/saveBtnClicked",
		}),
		...mapState({
			fullTimeIncome: (state) => state.applications.fullTimeIncome,
		}),
	},
	methods: {
		...mapActions({
			setFullTimeIncome: "applications/setFullTimeIncome",
			setValidation: "applications/setValidation",
			setFullTimeIncomeTotal: "subTotal/setFullTimeIncomeTotal",
		}),
		calculateRatio(value, total, frequency) {
			if (value && total && frequency) {
				let calc = this.getUpdatedValueFromData(value, frequency) / Number(total);
				let percent = calc * 100;
				return percent.toFixed(2);
			}
			return 0;
		},
		isSubmitDisabled() {
			return this.v$.$invalid;
		},
		//Get updated values based on frequency
		getUpdatedValues(valueField, frequencyField, index) {
			let value = this.details[index][valueField];
			let frequency = this.details[index][frequencyField];
			switch (frequency) {
				case "Weekly":
					return value * 52;
				case "Fortnightly":
					return value * 26;
				case "Monthly":
					return value * 12;
				case "Quarterly":
					return value * 4;
				case "Yearly":
					return value * 1;
			}
			return 0;
		},
		getUpdatedValueFromData(valueField, frequencyField) {
			let value = valueField;
			let frequency = frequencyField;
			switch (frequency) {
				case "Weekly":
					return value * 52;
				case "Fortnightly":
					return value * 26;
				case "Monthly":
					return value * 12;
				case "Quarterly":
					return value * 4;
				case "Yearly":
					return value * 1;
			}
			return 0;
		},
		monthlySubtotal(i) {
			let total =
				Number(
					this.getUpdatedValues("salary_amount_used", "salary_frequency", i)
				) +
				Number(
					this.getUpdatedValues("salary_packaged_used", "salary_frequency", i)
				) +
				Number(
					this.getUpdatedValues(
						"overtime_pay_amount_used",
						"overtime_pay_frequency",
						i
					)
				) +
				Number(
					this.getUpdatedValues("commissions_used", "commissions_frequency", i)
				) +
				Number(
					this.getUpdatedValues("bonuses_amount_used", "bonuses_frequency", i)
				);
			this.subTotal[i] = total;
			this.setFullTimeIncomeTotal({
				data: this.subTotal,
				type: !this.disable ? "primary" : "secondary",
			});
			return total ? (total / 12).toFixed(2) : 0;
		},
		addIncome() {
			let newObj = {
				name_of_employer: "",
				salary_amount: 0,
				salary_packaged: 0,
				salary_frequency: "Monthly",
				overtime_pay_amount: 0,
				overtime_pay_frequency: "Monthly",
				commissions_amount: 0,
				commissions_frequency: "Monthly",
				bonuses_amount: 0,
				bonuses_frequency: "Monthly",
				applicant_type: !this.disable ? "primary" : "secondary",
				jobType: "full_time",
				salary_amount_verified: 0,
				salary_amount_manual: 0,
				salary_amount_used_value: "manual_value",
				salary_packaged_verified: 0,
				salary_packaged_manual: 0,
				salary_packaged_used_value: "manual_value",
				overtime_pay_amount_verified: 0,
				overtime_pay_amount_manual: 0,
				overtime_pay_amount_used_value: "manual_value",
				commissions_amount_verified: 0,
				commissions_amount_manual: 0,
				commissions_amount_used_value: "manual_value",
				bonuses_amount_verified: 0,
				bonuses_amount_manual: 0,
				bonuses_amount_used_value: "manual_value",
				total_income_amount_verified: 0,
				total_income_amount_manual: 0,
				total_income_amount_used_value: "manual_value",
				salary_amount_used: 0,
				salary_packaged_used: 0,
				overtime_pay_amount_used: 0,
				commissions_used: 0,
				bonuses_amount_used: 0,
				total_income_amount_used: 0,
				total_income_amount: 0,
				is_taxable: true,
			};
			this.details = [...this.details, newObj];
			return (this.disableFields = false);
		},
		removeIncome(i) {
			this.details.splice(i, 1);
			this.subTotal.splice(i, 1);
			this.setValidation({
				type: "income_fulltime",
				value: this.isSubmitDisabled(),
			});
			this.refreshKey += 1;
		},
		getFormData(value, field, income) {
			this.details[income][field] = value;
			this.details = [...this.details];

			switch (field) {
				case "salary_amount_used":
					this.details[income].salary_amount_manual = value;
					break;
				case "salary_packaged_used":
					this.details[income].salary_packaged_manual = value;

					break;
				case "overtime_pay_amount_used":
					this.details[income].overtime_pay_amount_manual = value;

					break;
				case "commissions_used":
					this.details[income].commissions_amount_manual = value;

					break;
				case "bonuses_amount_used":
					this.details[income].bonuses_amount_manual = value;
					break;
			}
			this.setFullTimeIncome({
				data: this.details,
				type: !this.disable ? "primary" : "secondary",
			});
		},
		setData() {
			if (this.selectedApplication.application_id) {
				if (
					this.selectedApplication.salaried &&
					this.selectedApplication.salaried.length > 0
				) {
					this.details = !this.disable
						? this.selectedApplication.salaried.filter(
								(item) =>
									item.applicant_type === "primary" &&
									item.jobType === "full_time"
						): this.selectedApplication.salaried.filter(
								(item) =>
									item.applicant_type === "secondary" &&
									item.jobType === "full_time"
						);
				}
				this.details = [...this.details];
				this.setFullTimeIncome({
					data: this.details,
					type: !this.disable ? "primary" : "secondary",
				});
			}
		},
	},
	created() {
		this.setData();
	},
	watch: {
		details: {
			handler() {
				this.setFullTimeIncome({
					data: this.details,
					type: !this.disable ? "primary" : "secondary",
				});
				this.setValidation({
					type: "income_fulltime",
					value: this.isSubmitDisabled(),
				});
			},
			deep: true,
		},
		fullTimeUpdated: {
			handler() {
				this.details = !this.disable
					? [...this.fullTimeIncome.applicant_1]
					: [...this.fullTimeIncome.applicant_2];
				this.refreshKey += 1;
				this.setFullTimeIncome({
					data: this.details,
					type: !this.disable ? "primary" : "secondary",
				});
			},
			deep: true,
		},
	},
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

.sub-title {
	font-size: 1.25rem;
	color: $secondary-color;
	font-family: Gordita-bold;
}

::v-deep .p-col-4 {
	padding-bottom: 0;
	padding-top: 0;
}

.label {
	color: $secondary-color;
	font-size: 0.875rem;
	font-family: Gordita-medium;
	margin: 0;
}

.highlight-fields {
	.label {
		font-family: Gordita-bold !important;
	}
}

.heading {
	background: rgba(2, 0, 56, 0.03);

	p {
		margin: 0;
	}
}

.mr-b-5px {
	margin-bottom: 5px;
}

.mr-top {
	margin-top: 2.5rem;
}

.mr-top-10px {
	margin-top: 10px;
}

.flex-gap {
	gap: 0.625rem;
}

.flex-gap-1 {
	gap: 0.313rem;
}

.flex-gap-2 {
	gap: 1.875rem;
}
</style>
