<template>
  <div class="pl-4 pr-4 pb-4">
    <Card>
      <template #title>
        <Header />
      </template>
      <template #content>
        <router-view></router-view>
      </template>
    </Card>
  </div>
</template>

<script>
import Header from "./Header.vue"
import Card from "primevue/card"

export default {
  components: { Header, Card },
}
</script>

<style></style>
