<template>
  <div>
    <div>
      <Panel :toggleable="true" :collapsed="collapsed">
        <template #icons>
          <div @click="toggle" class="icon-wrapper">
            <span v-if="collapsed" class="icon pi pi-angle-down"></span>
            <span v-else class="icon pi pi-angle-up"></span>
          </div>
        </template>
        <template #header>
          <div @click="toggle" class="sec-color-text hk-sb full-width pointer">
            Task - Permission
          </div>
        </template>
        <div class="mt-3">
          <CheckboxList
            :categories="taskTypePermission"
            name="piepline-permission"
            @getInputValue="getTaskPermission"
            class="checkbox-list"
          />
        </div>
      </Panel>
      <div class="flex align-items-center">
        <Button
          class="mt-3"
          label="Next"
          :loading="isloading"
          type="primary"
          @click="step"
        />
        <div class="back mt-3 ml-4 cursor-pointer" @click="cancel">back</div>
      </div>
    </div>
  </div>
</template>
<script>
import Panel from "primevue/panel";
import { _get, _post, _put } from "../../../../../../services/crudService";
import { SERVER_URL } from "../../../../../../../constants";
import { mapActions, mapGetters } from "vuex";

export default {
  props: [
    "show",
    "groupPermissions",
    "close",
    "userId",
    "selectedGroup",
    "permissionList",
  ],
  components: { Panel },
  computed: {
    ...mapGetters({
      groupPermissionList: "user/groupPermissionList",
      taskTypePermission: "user/taskTypePermission",
      info: "user/info",
      getPermissions: "user/getPermissions",
      getTasktypePermission: "user/getTasktypePermission",
      selectedUserId: "user/selectedUserId",
    }),
  },
  data() {
    return {
      collapsed: true,
      taskPermission: {},
      isloading: false,
      list: [],
      user: {},
    };
  },
  methods: {
    ...mapActions({
      getTasktypePermissionList: "user/getTasktypePermission",
      setTaskTypePermission: "user/setTaskTypePermission",
    }),
    cancel() {
      this.$emit("nxtBtn", "AddUserTwo");
    },
    async step() {
      this.user = {
        ...this.getPermissions,
        ...this.info,
        taskPermissions: [this.getTasktypePermission],
        type: "Group",
      };
      if (this.selectedUserId) {
        try {
          this.isloading = true;
          const response = await _put(
            SERVER_URL + "update_crm_user?id=" + this.selectedUserId,
            this.user
          );

          if (response) {
            this.$toast.add({
              severity: "success",
              summary: "User updated successfully",
              life: 3000,
            });
            this.isloading = false;

            this.$emit("userCreated");
            this.$props.close();
            this.$emit("nxtBtn", "AddUser");
          }
        } catch (error) {
          this.isloading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      } else {
        try {
          this.isloading = true;

          const response = await _post(
            SERVER_URL + "insert_crm_user",
            this.user
          );

          if (response) {
            this.$toast.add({
              severity: "success",
              summary: "User added successfully",
              life: 3000,
            });
            this.isloading = false;
            this.$emit("userCreated");
            this.$emit("nxtBtn", "AddUser");

            this.$props.close();
          }
        } catch (error) {
          this.isloading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
    },
    toggle() {
      this.collapsed = !this.collapsed;
    },
    getTaskPermission(value) {
      value.forEach((ele) => {
        this.taskPermission[ele] = true;
      });

      Object.keys(this.taskPermission).forEach((opt) => {
        if (value.indexOf(opt) === -1) {
          this.taskPermission[opt] = false;
        } else {
          this.taskPermission[opt] = true;
        }
      });

      this.getTasktypePermissionList(this.taskPermission);
    },

    async getTaskTypes() {
      const taskTypeList = await _get(SERVER_URL + "get_task_types?type=user");

      if (taskTypeList) {
        this.setTaskTypePermission(taskTypeList);
      }
    },
  },
  async created() {
    // this.taskTypePermission.forEach((ele) => {
    //   this.taskPermission[ele.name] = true;
    // });
    await this.getTaskTypes();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
  font-family: Gordita-bold !important;
}
.text-content {
  margin-top: 1rem;
}
form {
  gap: 1rem;

  input[type="radio"] {
    width: 1.125rem;
    height: 1.125rem;
  }
}

label {
  margin-left: 0.4rem;
}
.radio-input {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.radio-label {
  font-family: Gordita;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #020038;
}
.pi-minus {
  display: none;
}
::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid #02003826 !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel-content {
  border: none !important;
}

::v-deep .p-panel-header-icon {
  display: none !important;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

::v-deep .p-col-4,
.p-col-2 {
  padding-bottom: 0;
  padding-top: 0;
}

.mr-b-5px {
  margin-bottom: 5px;
}

.mr-top {
  margin-top: 2.5rem;
}

.mr-top-10px {
  margin-top: 10px;
}

.flex-gap {
  gap: 0.625rem;
}
.hk-title {
  font-size: 20px;
  font-weight: 600;
}
.sec-color-text {
  font-size: 16px;
}
.p-panel {
  margin-top: 1rem !important;
}
</style>
