<template>
  <div class="">
    <!-- <h1 class="module-title">Communications</h1> -->
    <iframe
      style="width: 100%; height: 100vh"
      src="https://flex.twilio.com/agent-desktop/"
      target="_top"
    />
    <Card v-if="showCommunications" class="box-shadow mt-5">
      <template #title>
        <div class="flex justify-content-between align-items-center mt-2">
          <h3 class="sec-color-txt hk-sb">Communications history</h3>
          <div class="flex justify-content-end">
            <div class="mr-4 flex align-items-center">
              <img class="mr-2" src="@/assets/plus-inactive.svg" />

              <p class="lt-txt-color">New call</p>
            </div>
            <div class="mr-4 flex align-items-center">
              <img class="mr-2" src="@/assets/plus-inactive.svg" />

              <p class="lt-txt-color">New text</p>
            </div>
            <div
              @click="showSendEmail"
              style="cursor: pointer"
              class="flex align-items-center"
            >
              <img class="mr-2" src="@/assets/plus-inactive.svg" />

              <p class="lt-txt-color">New email</p>
            </div>
            <div class="ml-3">
              <span class="p-input-icon-left ml-2">
                <i class="pi pi-filter" />
                <InputText type="text" placeholder="Filter by type" />
              </span>
            </div>
          </div>
        </div>
      </template>

      <template #content>
        <Spinner v-if="isLoading" class="flex justify-content-between" />

        <DataTable
          v-else
          :expandedRows="expandedRows"
          @row-expand="onRowExpand"
          @row-collapse="onRowCollapse"
          responsiveLayout="scroll"
          :paginator="true"
          :rows="5"
          dataKey="id"
          :value="communicationList"
          :rowsPerPageOptions="[5, 10, 20, 30]"
          :totalRecords="communicationList.length"
          class="mt-2"
        >
          <template #empty> No data found. </template>

          <Column field="type" header="Type" />
          <Column field="description" header="Description" />
          <Column field="sentByName" header="Sent By" />
          <Column field="sentToName" header="Sent To" />
          <Column field="sentDate" header="Sent Date">
            <template #body="slotProps">
              {{ formatDate(slotProps.data.sentDate) || "-" }}
            </template>
          </Column>
          <Column field="Id" header=" " :expander="true">
            <template #body="slotProps">
              <p class="color-highlight flex align-items-center">
                view
                <i
                  @click="onRowExpand(slotProps.data)"
                  v-if="!slotProps.data.expanded"
                  class="pi pi-angle-down ml-2 cursor-pointer"
                />
                <i
                  @click="onRowCollapse(slotProps.data)"
                  v-else
                  class="pi pi-angle-up ml-2 cursor-pointer"
                />
              </p>
            </template>
          </Column>
          <template #expansion>
            <div class="sec-content">
              <div class="flex align-items">
                <div class="col-3">
                  <p class="heading lt-txt-color fs-sm hk-sb">Message ID</p>
                  <p class="text hk-sb sec-color-txt fs-sm">1234</p>
                </div>
                <div class="col-3">
                  <p class="heading lt-txt-color fs-sm hk-sb">From</p>
                  <p class="text hk-sb sec-color-txt fs-sm">
                    hello@bridgit.com.au
                  </p>
                </div>
                <div class="col-3">
                  <p class="heading lt-txt-color fs-sm hk-sb">Date sent</p>
                  <p class="text hk-sb sec-color-txt fs-sm">30/10/2020 10:25</p>
                </div>
                <div class="col-3">
                  <p class="heading lt-txt-color fs-sm hk-sb">Status</p>
                  <p class="text hk-sb sec-color-txt fs-sm">Opened</p>
                </div>
              </div>
              <div class="flex align-items">
                <div class="col-3">
                  <p class="heading lt-txt-color fs-sm hk-sb">Created by</p>
                  <p class="text hk-sb sec-color-txt fs-sm">System</p>
                </div>
                <div class="col-3">
                  <p class="heading lt-txt-color fs-sm hk-sb">To</p>
                  <p class="text hk-sb sec-color-txt fs-sm">04 4996 5017</p>
                </div>
                <div class="col-3">
                  <p class="heading lt-txt-color fs-sm hk-sb">Template</p>
                  <p class="text hk-sb sec-color-txt fs-sm">
                    202 Payment reminder SMS
                  </p>
                </div>
                <div class="col-3">
                  <p class="heading lt-txt-color fs-sm hk-sb">Status updated</p>
                  <p class="text hk-sb sec-color-txt fs-sm">30/10/2020 10:25</p>
                </div>
              </div>

              <div class="mt-4 ml-2">
                <p class="heading lt-txt-color fs-sm hk-sb">Message</p>
                <p class="text hk-sb sec-color-txt fs-sm">
                  Payment Reminder<br />
                  Dear Bob, You have a payment for tomorrow on the 21 October
                  2020<br />
                  Your scheduled payment is $5,0000
                </p>
              </div>
            </div>
          </template>
        </DataTable>
      </template>
    </Card>
    <SendEmail :show="displaySendEmail" @close="displaySendEmail = false" />
  </div>
</template>

<script>
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import SendEmail from "../Customer/CustomerData/SendEmail.vue";
import InputText from "primevue/inputtext";
import moment from "moment";
import { SERVER_URL } from "../../../../constants";
import { _get } from "../../../services/crudService";

export default {
  components: { Card, DataTable, Column, SendEmail, InputText },
  data() {
    return {
      expandedRows: [],
      displaySendEmail: false,
      communicationList: [],
      isLoading: false,
      showCommunications: false,
      show: false,
    };
  },
  methods: {
    showSendEmail() {
      this.displaySendEmail = true;
    },
    collapseAll() {
      this.expandedRows = null;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY hh:mm");
    },
    async onRowExpand(event) {
      this.expandedRows = this.communicationList.filter(
        (data) => event?.id === data.id
      );
      this.communicationList.map((obj) => {
        if (obj.id === event.id) {
          obj.expanded = !obj.expanded;
        } else {
          obj.expanded = false;
        }
      });
    },
    onRowCollapse() {
      this.expandedRows = [];
    },
  },
  async created() {
    this.isLoading = true;
    const response = await _get(SERVER_URL + "get-communications-updated");

    if (response) {
      response.data.map((list) =>
        this.communicationList.push({ ...list, expanded: false })
      );
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}

::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}

.color-highlight {
  color: $primary-color !important;
  text-decoration: none;
}

p {
  font-size: 1rem;
  margin: 0;
}

.sec-content {
  background: rgba(2, 0, 56, 0.03) !important;
  width: 100%;
  padding: 1rem 1rem;
}

.lt-txt-color {
  color: rgba(2, 0, 56, 0.4);
}
::v-deep .router-component {
  // margin-left: -196px;
  z-index: 1000;
}
</style>
