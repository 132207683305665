<template>
  <div>
    <div class="content">
      <div class="form-inputs mt-4">
        <div v-if="latest.length" class="p-grid align-items-center header">
          <p class="col"></p>
          <p class="col label">Application Values</p>
          <p class="col label">Current Values</p>
          <p class="col label">Current Value Source</p>
          <div class="col"></div>
        </div>
        <div v-for="(item, i) in latest" :key="i">
          <InputRow
            :value1="original[i] ? original[i].asset_value : ''"
            :value2="item.asset_value"
            :label="`Asset value ${i + 1}`"
          />
          <InputRow
            :value1="original[i] ? original[i].monthly_repayment : ''"
            :value2="item.monthly_repayment"
            :label="`Monthly repayment ${i + 1}`"
          />
          <InputRow
            :value1="original[i] ? original[i].liability_limit : ''"
            :value2="item.liability_limit"
            :label="`Liability limit ${i + 1}`"
          />
          <InputRow
            :value1="original[i] ? original[i].amount_owning : ''"
            :value2="item.amount_owning"
            :label="`Amount owing ${i + 1}`"
          />
        </div>
        <InputRow
          v-if="latest.length"
          label="Subtotal"
          :value1="originalTotal"
          :value2="latestTotal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputRow from "../../InputRow.vue";
export default {
  components: { InputRow },
  computed: {
    ...mapGetters({
      originalData: "loans/originalData",
      latestData: "loans/latestData",
    }),
  },
  data() {
    return {
      original: [],
      latest: [],
      originalTotal: 0,
      latestTotal: 0,
    };
  },
  methods: {
    setData() {
      this.latest = this.latestData.liability_others;
      this.original = this.originalData.liability_others;
      this.original.map((obj) => {
        this.originalTotal = obj.amount_owning + this.originalTotal;
      });
      this.latest.map((obj) => {
        this.latestTotal = obj.amount_owning + this.latestTotal;
      });
    },
  },
  watch: {
    latestData() {
      this.setData();
    },
  },
  created() {
    this.setData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
</style>
