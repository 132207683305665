<template>
  <div class="p-grid align-items-end servicibility__field__container">
    <div class="left__container p-grid align-items-center mr-b-5px">
      <Checkbox2
        :label="label || 'Input label'"
        labelClass="checkbox__label"
        @getInputValue="handleSelect"
        :value="isSelected"
      />
    </div>
    <div class="p-col-10 right__container">
      <div class="medium__input">
        <p class="label">Servicing rate %</p>
        <NumberField
          mode="decimal"
          :format="true"
          suffix="%"
          :value="interestRate ? interestRate * 100 : 0"
          :disable="false"
          width="100%"
          :key="refreshKeyIntrestRate"
          @onInput="handleIntrestRateChange"
        />
      </div>
      <div class="medium__input">
        <p class="label">Buffer %</p>
        <NumberField
          mode="decimal"
          :format="true"
          suffix="%"
          :value="buffer ? buffer * 100 : 0"
          :disable="false"
          width="100%"
          :key="refreshKeyBuffer"
          @onInput="handleChangeBuffer"
        />
      </div>
      <div class="normal__input">
        <p class="label">Servicing amount</p>
        <NumberField
          mode="decimal"
          :format="true"
          prefix="$"
          :value="servicingAmount"
          :disable="true"
          width="100%"
          :key="refreshKeySA"
          placeholder=" "
        />
      </div>
      <div class="normal__input">
        <p class="label">New loan repayment monthly</p>
        <NumberField
          mode="decimal"
          :format="true"
          prefix="$"
          :value="newLoanRepaymentMonthly"
          :disable="true"
          width="100%"
          :key="refreshKeyNLRM"
          placeholder=" "
        />
      </div>
      <div class="normal__input">
        <p class="label">Monthly Net Surplus</p>
        <NumberField
          mode="decimal"
          :format="true"
          prefix="$"
          :value="monthlyNetSurplus"
          :disable="true"
          width="100%"
          :key="refreshKeyMNS"
          placeholder=" "
        />
      </div>
      <div class="small__input">
        <p class="label">LTI</p>
        <NumberField
          mode="decimal"
          :format="true"
          :value="lti"
          :disable="true"
          width="100%"
          :key="refreshKeyLTI"
          placeholder=" "
        />
      </div>
      <div class="small__input">
        <p class="label">DTI</p>
        <NumberField
          mode="decimal"
          :format="true"
          :value="dti"
          :disable="true"
          width="100%"
          :key="refreshKeyDTI"
          placeholder=" "
        />
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox2 from "@/components/common/Checkbox2";

export default {
  name: "ServicibilityFields",
  components: {
    Checkbox2,
  },
  props: ["label", "name", "value"],
  data() {
    return {
      interestRate: "",
      refreshKey: 1,
      refreshKeyIntrestRate: 2,
      refreshKeyBuffer: 3,
      refreshKeySA: 4,
      refreshKeyNLRM: 5,
      refreshKeyMNS: 6,
      refreshKeyDTI: 7,
      refreshKeyLTI: 8,
      servicingAmount: "",
      newLoanRepaymentMonthly: "",
      monthlyNetSurplus: "",
      lti: "",
      dti: "",
      isSelected: false,
    };
  },
  methods: {
    getData() {
      return {
        interest_rate: this.interestRate,
        buffer: this.buffer,
        servicing_amount: this.servicingAmount,
        new_loan_repayments_monthly: this.newLoanRepaymentsMonthly,
        monthly_net_surplus: this.monthlyNetSurplus,
        lti: this.lti,
        dti: this.dti,
        selected: this.isSelected,
      };
    },
    handleChangeBuffer(value) {
      this.buffer = value / 100;
      const data = this.getData();
      this.$emit("onChange", { name: this.name, value: data });
    },
    handleIntrestRateChange(value) {
      this.interestRate = value / 100;
      const data = this.getData();
      this.$emit("onChange", { name: this.name, value: data });
    },
    handleSelect(value) {
      this.isSelected = value;
      const data = this.getData();
      this.$emit("onChange", { name: this.name, value: data });
    },

    setValues() {
      this.interestRate = this.value[this.name].interest_rate;
      this.buffer = this.value[this.name].buffer;
      this.servicingAmount = this.value[this.name]?.servicing_amount || "";
      this.newLoanRepaymentMonthly =
        this.value[this.name]?.new_loan_repayments_monthly || "";
      this.monthlyNetSurplus = this.value[this.name]?.monthly_net_surplus || "";
      this.lti = this.value[this.name]?.lti || "";
      this.dti = this.value[this.name]?.dti || "";
      this.isSelected = this.value[this.name]?.selected || false;
    },
  },
  created() {
    this.setValues("created");
  },
  watch: {
    value: {
      handler() {
        this.setValues("watch");
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/global.scss";
.checkbox__label {
  color: $secondary-color;
  font-size: 0.875rem !important;
  font-family: Gordita-medium;
  margin: 0;
}
</style>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.servicibility__field__container {
  .right__container {
    display: flex;
    gap: 10px;
    align-items: flex-end;
  }
  .left__container {
    min-width: 16.5%;
    max-width: 16.5%;
    margin-left: 7px;
  }
  .medium__input {
    min-width: 102px;
  }
  .normal__input {
    min-width: 148px;
  }
  .small__input {
    min-width: 50px;
  }

  .serviceability__text {
    display: flex;
    align-items: flex-end;
    /*  width: 142px; */
    margin-left: 5px;
  }
  .label {
    color: $secondary-color;
    font-size: 0.875rem;
    font-family: Gordita-medium;
    margin: 0;
  }
}
</style>
