<template>
  <Dialog
    :visible="show"
    :style="{ width: '580px' }"
    :dismissableMask="true"
    :modal="show"
  >
    <template #header>
      <h1 class="modal-title">New Exception</h1>
      <i class="icon pi pi-times" @click="close" style="margin-left: auto" />
    </template>
    <div class="modal-content">
      <div class="flex mt-3">
        <div class="col-6">
          <div>Type<span style="color: red"> * </span></div>
          <Dropdown
            width="100%"
            optionLabel="field"
            :items="opts"
            placeholder="-select-"
            class="mt-2"
            :value="formData.type"
            @change="getFormData($event, 'type')"
          />
        </div>
        <div class="col-6 ml-2">
          <div>Status<span style="color: red"> * </span></div>
          <Dropdown
            :value="formData.type"
            @change="getFormData($event, 'status')"
            width="100%"
            placeholder="-select-"
            class="mt-2"
            :items="statusOpt"
          />
        </div>
      </div>
      <div v-if="showNotes">
        <div class="mt-3">Notes<span style="color: red"> * </span></div>
        <TextArea
          @onInput="getFormData($event, 'notes')"
          placeholder="Enter your notes here"
          width="100%"
          class="mt-2"
          :key="refreshKey"
          :value="formData.notes"
        />
      </div>

      <div class="disable-input">
        <div class="mt-3">Description</div>
        <TextArea
          @onInput="getFormData($event, 'description')"
          placeholder="Task description"
          width="100%"
          class="mt-2"
          :disable="true"
          :key="refreshKey"
          :value="formData.description"
        />
      </div>
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button
          :disabled="isSubmitDisabled()"
          :loading="isloading"
          label="Save"
          type="primary"
          @click="save"
        />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">
          close
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { _put } from "../../../../../services/crudService";
import { SERVER_URL_2 } from "../../../../../../constants";

export default {
  props: ["show", "opts"],
  components: { Dialog },
  data() {
    return {
      isloading: false,
      selectedId: null,
      showNotes: false,
      refreshKey: 10,
      statusOpt: ["Warning", "Exception"],
      formData: {
        type: "",
        status: "",
        description: "",
        notes: "",
      },
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    if (this.formData.status === "Exception") {
      return {
        formData: {
          type: { required },
          description: { required },
          status: { required },
          notes: { required },
        },
      };
    } else {
      return {
        formData: {
          type: { required },
          description: { required },
          status: { required },
        },
      };
    }
  },
  methods: {
    isSubmitDisabled() {
      return this.v$.$invalid;
    },

    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };

      if (fieldName === "type") {
        this.formData.description = value.description;
        this.selectedId = value.Id;
        this.refreshKey++;

        if (value.result === "Fail" && value.result_type === "Exception") {
          this.statusOpt.push("Fail");
          this.refreshKey++;
        } else {
          this.statusOpt = ["Warning", "Exception"];
          this.refreshKey++;
        }

        // if (value.notes) {
        //   this.showNotes = true;
        //   this.formData.notes = value.notes;
        // } else {
        //   this.showNotes = false;
        // }
      }

      if (fieldName === "status" && value === "Exception") {
        this.showNotes = true;
      } else if (fieldName === "status" && value === "Warning") {
        this.showNotes = false;
      }
    },
    async save() {
      try {
        this.isloading = true;

        if(this.formData.status === 'Fail') {
          const response = await _put(
            SERVER_URL_2 + `remove-exception-fail?id=${this.selectedId}`
          );

          if (response) {
            this.isloading = false;
            this.$emit("save");
            this.$emit("close");
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Exception saved successfully",
              life: 3000,
            });
          }
        }

        if (this.formData.status === "Warning") {
          const response = await _put(
            SERVER_URL_2 + `remove-exception?id=${this.selectedId}`
          );

          if (response) {
            this.isloading = false;
            this.$emit("save");
            this.$emit("close");
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Exception saved successfully",
              life: 3000,
            });
          }
        }

        if (this.formData.status === "Exception") {
          const response = await _put(
            SERVER_URL_2 + `add-exception?id=${this.selectedId}`,
            { notes: this.formData.notes }
          );

          if (response) {
            this.isloading = false;
            this.$emit("save");
            this.$emit("close");
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Exception saved successfully",
              life: 3000,
            });
          }
        }
      } catch (error) {
        console.log(error);
        this.isloading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.attachment {
  color: rgba(2, 0, 56, 0.4);
  font-weight: 500;
  font-size: 14px;
  font-family: Gordita-medium;
}
</style>
