<template>
  <div class="std-padding-x mr-b">
    <Panel class="box" :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>
      <template #header>
        <div
          @click="toggle"
          class="title-wrapper flex align-items-center full-width pointer"
        >
          <img src="@/assets/Expense.svg" alt="" />
          <p class="title">Expenses</p>
        </div>
      </template>

      <Applicant title="Applicant 1" :disable="false" />
      <Applicant v-if="isTwoApplicants" title="Applicant 2" :disable="true" />
      <TotalValue :key="totalKey" />
      <ReviewForm title="Expenses Review" />
    </Panel>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Panel from "primevue/panel";
import Applicant from "./Applicant.vue";
import TotalValue from "./TotalValue.vue";
import ReviewForm from "./ReviewForm.vue";

export default {
  components: { Panel, Applicant, TotalValue, ReviewForm },
  data() {
    return {
      collapsed: true,
      totalKey: 10,
    };
  },
  computed: {
    ...mapGetters({ isTwoApplicants: "applications/isTwoApplicants" }),
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid $secondary-color !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.std-padding-x {
  padding: 0 2.188rem;
}

.flex-gap {
  gap: 0.625rem;
}

.title {
  font-size: 2rem;
  font-family: Gordita-bold;
  margin-left: 0.625rem;
  color: $secondary-color;
}

.box {
  background: #ffffff;
  border: 1px solid rgba(2, 0, 56, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 1.25rem;
}

p {
  margin: 0;
}
</style>
