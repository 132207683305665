<template>
  <Dialog :draggable="false" :visible="show" :modal="show" :style="{ width: '580px' }">
    <template #header>
      <h1 class="modal-title">Send Text</h1>
      <i
        class="icon pi pi-times cursor-pointer"
        @click="close"
        style="margin-left: auto"
      />
    </template>
    <div class="modal-content">
      <div>Text Template</div>
      <Dropdown
        :items="template"
        width="100%"
        placeholder="-select-"
        class="mt-2"
        @change="onChange"
        optionLabel="template"
      />
      <div class="flex mt-2">
        <div class="col">
          <div>To</div>
          <!-- <NumberField
            width="100%"
            placeholder="Enter receipient number"
            class="mt-2"
            @onInput="getFormData($event, 'to')"
          /> -->
          <Dropdown
            width="100%"
            :items="filteredList"
            @change="searchCustomer($event)"
            placeholder="Enter recipient number"
            field="mobile"
            optionLabel="title"
          />
        </div>
      </div>

      <div class="mt-1">
        <div class="mt-2">Body</div>
        <Editor
          :key="textEditorKey"
          :textValue="formData.body"
          @getInputValue="getFormData($event, 'body')"
          class="mt-2"
        />
      </div>
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button
          :disabled="isSubmitDisabled()"
          label="Send"
          type="primary"
          @click="save"
          :loading="isloading"
        />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">Close</div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";

import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { _get } from "../../../../services/crudService";
import { SERVER_URL, APP_URL } from "../../../../../constants";

export default {
  props: ["show", "portal"],
  components: { Dialog },
  data() {
    return {
      template: [],
      isloading: false,
      selectedCountry1: null,
      filteredList: [],
      selectedBroker: "",
      customerList: [],
      formData: {
        to: "",
        body: "",
        selectedTemplate: "",
        type: "",
      },
      numbersList: [],
      textEditorKey: 0,
      filteredNumbersList: [],
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        to: {
          required,
        },
        body: { required },
        selectedTemplate: { required },
      },
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    async onChange(value) {
      this.formData.selectedTemplate = value.templateId;
      await this.getBodyContent();
      this.filteredList = [];
      if (value.type == "customer") {
        this.numbersList.map((obj) => {
          if (obj.type != "broker") {
            this.filteredList.push(obj);
          }
        });
      } else {
        this.numbersList.map((obj) => {
          if (obj.type == "broker") {
            this.filteredList.push(obj);
          }
        });
      }
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    async save() {
      try {
        this.isloading = true;
        await _get(
          APP_URL +
            "sms-template?applicationId=" +
            this.$route.params.id +
            "&templateId=" +
            this.formData.selectedTemplate +
            "&phoneNumber=" +
            this.formData.to +
            "&type=" +
            this.formData.type +
            "&isSend=true"
        );
        this.isloading = false;
        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "SMS send Successfully",
          life: 3000,
        });
        this.$emit("close");
      } catch (error) {
        this.isloading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    async searchCustomer(event) {
      this.formData.to = event.mobile;
      this.formData.type = event.type;
      await this.getBodyContent();
    },
    async getBodyContent() {
      if (this.formData.selectedTemplate && this.formData.to) {
        const result = await _get(
          APP_URL +
            "sms-template?applicationId=" +
            this.$route.params.id +
            "&templateId=" +
            this.formData.selectedTemplate +
            "&phoneNumber=" +
            this.formData.to +
            "&type=" +
            this.formData.type
        );
        this.formData.body = result?.data?.body;
        this.textEditorKey += 1;
      }
    },
    async getCustomers() {
      const customers = await _get(SERVER_URL + "get_customers");

      customers &&
        customers.data.result.map((list) =>
          this.customerList.push({
            id: list.Id,
            email: list.email,
            name: `${list.firstName} ${list.lastName}`,
            mobile: list.mobile,
          })
        );
    },
    async getTemplates() {
      const templates = await _get(
        APP_URL + "sms-template?applicationId=" + this.$route.params.id
      );
      this.template = templates.data?.data;
      templates.data.mobileData.map((obj) => {
        if (obj.isVisible) this.numbersList.push(obj);
      });
      this.filteredList = this.numbersList;
    },
  },
  async created() {
    try {
      await this.getTemplates();
      await this.getCustomers();
    } catch (error) {
      this.$toast.add({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong",
        life: 3000,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

:deep .p-autocomplete,
:deep .p-autocomplete-input {
  width: 100%;
}
</style>
