<template>
  <Dialog
    :draggable="false"
    :visible="show"
    :modal="show"
    :style="{ width: '650px' }"
  >
    <template #header>
      <h1 class="modal-title">{{ title }}</h1>
    </template>
    <div class="modal-content">
      <Card>
        <template #title>
          <div class="title-wrapper">
            <div class="std-padding-x flex">
              <h3
                @click="changingTab('AddGroupOne')"
                :class="
                  component === 'AddGroupOne' ? 'title-active' : 'lt-txt-color'
                "
                class="title"
              >
                Info
              </h3>
              <h3
                @click="changingTab('AddGroupTwo')"
                :class="
                  component === 'AddGroupTwo' ? 'title-active' : 'lt-txt-color'
                "
                class="title"
              >
                Permissions
              </h3>
              <h3
                @click="changingTab('AddGroupThree')"
                :class="
                  component === 'AddGroupThree'
                    ? 'title-active'
                    : 'lt-txt-color'
                "
                class="title"
              >
                Tasks
              </h3>

              <!-- next-phase -->

              <!-- <h3
                @click="changingTab('AddGroupFour')"
                :class="
                  component === 'AddGroupFour' ? 'title-active' : 'lt-txt-color'
                "
                class="title"
              >
                Application limits
              </h3> -->
            </div>
          </div>
        </template>
        <template #content>
          <Spinner v-if="isloading" class="flex justify-content-between" />
          <div v-else>
            <keep-alive>
              <component
                @disableBtn="isBtnDisabled"
                :is="component"
              ></component>
            </keep-alive>
          </div>
        </template>
        <template #footer>
          <div class="flex align-items-center items-center">
            <div>
              <Button
                @click="addNewGroup"
                class="mt-3"
                :label="btnText"
                type="primary"
                :disabled="disableBtn"
                :loading="isBtnLoading"
              />
            </div>
            <div @click="close" class="back mt-3 ml-4 cursor-pointer">
              Close
            </div>
          </div>
        </template>
      </Card>
    </div>
  </Dialog>
</template>
<script>
import Card from "primevue/card";
import Dialog from "primevue/dialog";
import AddGroupOne from "./AddGroupOne.vue";
import AddGroupTwo from "./AddGroupTwo/Index.vue";
import AddGroupThree from "./AddGroupThree.vue";
import AddGroupFour from "./AddGroupFour/Index.vue";
import { _get, _post, _put } from "../../../../../../services/crudService";
import { SERVER_URL } from "../../../../../../../constants";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["show", "title", "btnText", "groupId"],
  components: {
    Dialog,
    Card,
    AddGroupOne,
    AddGroupTwo,
    AddGroupThree,
    AddGroupFour,
  },
  data() {
    return {
      component: "AddGroupOne",
      disableBtn: true,
      isloading: false,
      isBtnLoading: false,
      newGroup: {},
    };
  },
  // watch: {
  //   async groupId() {
  //     try {
  //       this.isloading = true;
  //       this.disableBtn = true;

  //       const getGroupDetails = await _get(
  //         SERVER_URL + `get-group-details?id=${this.$props.groupId}`
  //       );

  //       if (getGroupDetails) {
  //         this.groupInfo["title"] = getGroupDetails.data.result.title;
  //         this.groupInfo["description"] =
  //           getGroupDetails.data.result.descriptions;

  //         const response = await _get(
  //           SERVER_URL + `get-group-by-id?id=${this.$props.groupId}`
  //         );

  //         let taskTypePermissions = [];
  //         let permissions = [];
  //         let filteredTaskType = [];

  //         if (response) {
  //           taskTypePermissions = response.data.result[0].taskPermissions;

  //           response.data.result.map((list) => {
  //             const values = Object.keys(list);

  //             values.map((key) => {
  //               if (list[key] === "true") {
  //                 permissions.push(key);
  //               }
  //             });
  //           });

  //           taskTypePermissions.map((list) => {
  //             const values = Object.keys(list);

  //             values.map((key) => {
  //               if (list[key] === true) {
  //                 filteredTaskType.push(key);
  //               }
  //             });
  //           });

  //           this.groupPermissions = [
  //             ...permissions,
  //             { taskPermissions: filteredTaskType },
  //           ];

  //           this.isloading = false;
  //           this.disableBtn = false;
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       this.$toast.add({
  //         severity: "error",
  //         summary: "Error",
  //         detail: "Something went wrong",
  //         life: 3000,
  //       });
  //     }
  //   },
  // },
  computed: {
    ...mapGetters({
      taskListPermission: "group/taskListPermission",
      pipelinePermission: "group/pipelinePermission",
      appPermission: "group/appPermission",
      taskTypePermission: "group/taskTypePermission",
      info: "group/info",
      getPermissions: "group/getPermissions",
      getTasktypePermission: "group/getTasktypePermission",
      selectedGroupId: "group/selectedGroupId",
    }),
  },
  methods: {
    ...mapActions({
      getTasktypePermission: "group/getTasktypePermission",
      setTaskTypePermission: "group/setTaskTypePermission",
      setPermissionList: "group/setPermissionList",
      setInfo: "group/setInfo",
      setGroupPermissionList: "group/setGroupPermissionList",
      setPermissions: "group/setPermissions",
    }),
    changingTab(cmp) {
      return (this.component = cmp);
    },
    close() {
      this.component = "AddGroupOne";
      this.$emit("close");
    },
    isBtnDisabled(value) {
      this.disableBtn = value;
      return value;
    },
    async addNewGroup() {
      this.newGroup = {
        ...this.info,
        ...this.getPermissions,
        taskPermissions: [this.getTasktypePermission],
        type: "Group",
      };


      if (this.disableBtn === false && !this.selectedGroupId) {
        this.newGroup = {
          ...this.info,
          ...this.getPermissions,
          taskPermissions: [this.getTasktypePermission],
          type: "Group",
        };

        try {
          this.isBtnLoading = true;
          const response = await _post(
            SERVER_URL + "insert-group",
            this.newGroup
          );
          if (response) {
            this.isBtnLoading = false;

            this.$toast.add({
              severity: "success",
              summary: "New group added successfully",
              life: 3000,
            });
            this.component = "AddGroupOne";
            this.$emit("close");
            this.$emit("save");
          }
        } catch (error) {
          this.isBtnLoading = false;

          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
      if (this.selectedGroupId) {
        try {
          this.isBtnLoading = true;
          const response = await _put(
            SERVER_URL + `update_group?id=${this.selectedGroupId}`,
            this.newGroup
          );
          if (response) {
            this.isBtnLoading = false;
            this.$toast.add({
              severity: "success",
              summary: "Group updated successfully",
              life: 3000,
            });
            this.component = "AddGroupOne";
            this.$emit("close");
            this.$emit("save");
          }
        } catch (error) {
          this.isBtnLoading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error Message",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
    },

    async getGroup() {
      try {
        this.isloading = true;
        this.disableBtn = true;

        const getGroupDetails = await _get(
          SERVER_URL + `get-group-details?id=${this.selectedGroupId}`
        );

        if (getGroupDetails) {
          let payloadData = {
            title: getGroupDetails.data.result.title,
            description: getGroupDetails.data.result.descriptions,
          };

          this.setInfo(payloadData);

          const response = await _get(
            SERVER_URL + `get-group-by-id?id=${this.selectedGroupId}`
          );

          let taskTypePermissions = [];
          let permissions = [];
          let filteredTaskType = [];

          if (response) {
            this.setPermissions(response.data.result[0]);
            taskTypePermissions = response.data.result[0].taskPermissions;

            response.data.result.map((list) => {
              const values = Object.keys(list);

              values.map((key) => {
                if (list[key] === true) {
                  permissions.push(key);
                }
              });
            });

            taskTypePermissions.map((list) => {
              const values = Object.keys(list);

              values.map((key) => {
                if (list[key] === true) {
                  filteredTaskType.push(key);
                }
              });
            });

            let data = [...permissions, { taskPermissions: filteredTaskType }];

            this.setGroupPermissionList(data);

            this.isloading = false;
            this.disableBtn = false;
          }
        }
      } catch (error) {
        console.log(error);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },
  async created() {
    this.isloading = true;

    const taskTypeList = await _get(SERVER_URL + "get_task_types?type=group");

    if (taskTypeList) {
      this.setTaskTypePermission(taskTypeList);
      this.isloading = false;
    }

    if (this.selectedGroupId) {
      this.getGroup();
    } else {
      this.setPermissionList();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
  font-family: Gordita-bold !important;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.text-content {
  margin-top: 1rem;
}
form {
  gap: 1rem;

  input[type="radio"] {
    width: 1.125rem;
    height: 1.125rem;
  }
}
.label,
label {
  font-size: 1.125rem;
}

label {
  margin-left: 0.4rem;
}
.radio-input {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
::v-deep .p-card .p-card-body {
  padding: 0;
}

.std-padding-x {
  padding: 0 1.188rem;
  gap: 2rem;
}

p {
  margin: 0;
}

.mr-b {
  margin-bottom: 1.875rem;
}

.title-wrapper {
  border-bottom: 1px solid #02003826;

  .title {
    font-size: 1.15rem;
    // padding: 1.25rem 0;
    color: $light-text-color;
    font-family: Gordita-medium-;
    margin: 0;
    cursor: pointer;
  }

  .title-active {
    border-bottom: 3.5px solid $primary-color;
    color: $primary-color;
  }
}
.fs-18px {
  font-size: 1.125rem;
}
::v-deep .p-card-content {
  padding: 1rem 2rem !important;
}
:deep .p-dialog-content {
  padding: unset !important;
}
::v-deep .p-card {
  background: #ffffff;
  color: #495057;
  box-shadow: unset;
  border-radius: 3px;
}

::v-deep .p-card .p-card-body {
  padding: 0;
  /* margin: -1rem; */
  margin-left: -2rem;
  margin-right: -1.5rem;
}
::v-deep .std-padding-x {
  padding: 0 2.188rem !important;
  gap: 2rem;
}
.back {
  font-weight: 700;
  color: rgba(2, 0, 56, 0.4);
  font-size: 1rem;
}
.items-center {
  padding-left: 2rem !important;
}
</style>
