<template>
  <Dialog
    :draggable="false"
    :visible="show"
    :modal="show"
    :style="{ width: '580px' }"
  >
    <h1 class="modal-title">Add applicant</h1>
    <div class="modal-content">
      <div class="mt-2">
        <div class="col">
          <div>Customer</div>
          <AutoComplete
            forceSelection
            class="full-width"
            width="32rem"
            v-model="selectedUser"
            :suggestions="filteredUserList"
            @complete="getUserList($event)"
            placeholder="Enter name"
            field="name"
            :modelValue="selectedUser"
          />
        </div>
      </div>

      <div class="details mt-3">
        <div class="title">Applicant details</div>
        <div class="flex mt-2">
          <div class="col mr-2">
            <div>First name</div>
            <TextField
              width="100%"
              :value="formData.firstName"
              :key="refreshKey"
              @onInput="getFormData($event, 'firstName')"
              placeholder="applicant first name"
            />
          </div>
          <div class="col">
            <div>Middle name</div>
            <TextField
              :key="refreshKey"
              :value="formData.middleName"
              width="100%"
              @onInput="getFormData($event, 'middleName')"
              placeholder="applicant middle name"
            />
          </div>
        </div>

        <div class="flex mt-2">
          <div class="col mr-2">
            <div>Last name</div>
            <TextField
              width="100%"
              :value="formData.lastName"
              :key="refreshKey"
              @onInput="getFormData($event, 'lastName')"
              placeholder="applicant last name"
            />
          </div>
          <div class="col">
            <div>Email address</div>
            <TextField
              :key="refreshKey"
              :value="formData.email"
              @onInput="getFormData($event, 'email')"
              width="100%"
              placeholder="applicant email address"
            />
          </div>
        </div>

        <div class="flex mt-3">
          <div class="col mr-2">
            <div>Relationship to primary applicant</div>
            <Dropdown
              :value="formData.relationToPrimaryApplicant"
              width="100%"
              :key="refreshKey"
              @change="getFormData($event, 'relationToPrimaryApplicant')"
              placeholder="-Select-"
              :items="relationshipList"
              optionLabel="name"
              optionValue="value"
            />
          </div>
          <div class="col">
            <p class="label">Date of birth</p>
            <DatePicker2
              placeholder="DD/MM/YY"
              width="100%"
              :value="formatDate(formData.dob)"
              :key="refreshKey"
              :maxDate="maxDate"
              :maxYear="maxYear"
              minYear="1920"
              @change="getFormData($event, 'dob')"
            />
          </div>
        </div>

        <div class="flex mt-2">
          <div class="col mr-2">
            <div>Phone number</div>
            <TextField
              :key="refreshKey"
              width="100%"
              :value="formData.mobile"
              @onInput="getFormData($event, 'mobile')"
              placeholder="applicant phone number"
              maxValue="11"
              type="tel"
            />
          </div>
          <div class="col mr-2">
            <div>Address line 1</div>
            <TextField
              width="100%"
              :key="refreshKey"
              :value="formData.add_1"
              @onInput="getFormData($event, 'add_1')"
              placeholder="applicant address line 1"
            />
          </div>
        </div>

        <div class="flex mt-2">
          <div class="col mr-2">
            <div>Address line 2</div>
            <TextField
              :value="formData.add_2"
              @onInput="getFormData($event, 'add_2')"
              :key="refreshKey"
              width="100%"
              placeholder="applicant address line 2"
            />
          </div>
          <div class="col mr-2">
            <div>City</div>
            <TextField
              width="100%"
              :value="formData.city"
              @onInput="getFormData($event, 'city')"
              :key="refreshKey"
              placeholder="applicant city name"
            />
          </div>
        </div>
        <div class="flex mt-2">
          <div class="col mr-2">
            <div>State</div>
            <TextField
              :value="formData.state"
              @onInput="getFormData($event, 'state')"
              width="100%"
              :key="refreshKey"
              placeholder="applicant state"
            />
          </div>
          <div class="col">
            <div>Postal code</div>
            <NumberField
              :value="formData.postal_code"
              @onInput="getFormData($event, 'postal_code')"
              width="100%"
              placeholder="applicant postal code"
              maxValue="4"
              :key="refreshKey"
            />
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex align-items-center">
        <Button
          :disabled="isSubmitDisabled()"
          label="Add applicant"
          type="primary"
          @click="save"
          :loading="isloading"
        />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">
          Close
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import AutoComplete from "primevue/autocomplete";
import Dialog from "primevue/dialog";
import { required, email, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
// import { checkMobileNumber } from "../../../../services/validatePhNo";
import { _post, _get } from "../../../../services/crudService";
import { APP_URL } from "../../../../../constants";
import { SERVER_URL } from "../../../../../constants";
import moment from "moment";

export default {
  name: "AddTask",
  props: ["show"],
  components: { Dialog, AutoComplete },
  data() {
    return {
      selectedUser: "",
      filteredUserList: [],
      usersList: [],
      maxDate: null,
      maxYear: null,
      isloading: false,
      refreshKey: 10,
      relationshipList: [
        { name: "Married/Defacto", value: "married" },
        { name: "Siblings", value: "siblings" },
        { name: "Friends", value: "friend" },
        { name: "Other", value: "other" },
      ],
      formData: {
        firstName: "",
        lastName: "",
        middleName: "",
        mobile: "",
        relationToPrimaryApplicant: "",
        email: "",
        dob: "",
        add_1: "",
        add_2: "",
        city: "",
        state: "",
        postal_code: "",
      },
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        firstName: { required },
        lastName: { required },
        mobile: {
          required,
          maxLength: maxLength(10),
        },
        relationToPrimaryApplicant: { required },
        email: { required, email },
        middleName: {},
        dob: { required },
        add_1: { required },
        add_2: { required },
        city: { required },
        state: { required },
        postal_code: { required },
      },
    };
  },
  watch: {
    selectedUser(value) {
      if (value && value.Id) {
        this.getAssignedToId(value);
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    formatDate(date) {
      date && moment(date).format("MM/DD/YYYY");
    },
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    async getAssignedToId(value) {

      this.formData.firstName = value?.firstName;
      this.formData.lastName = value?.lastName;
      this.formData.middleName = value?.middleName;
      this.formData.mobile = value?.mobile;
      this.formData.email = value?.email;
      this.formData.add_1 = value?.add_1;
      this.formData.add_2 = value?.add_2;
      this.formData.city = value?.city;
      this.formData.state = value?.state;
      this.formData.postal_code = value?.postal_code;
      this.refreshKey++;
    },
    async getUserList(event) {
      const cusotmerList = await _get(
        SERVER_URL + "get_customers_no_applicantion"
      );
      this.usersList = cusotmerList.data.result;
      this.usersList.map((obj) => {
        obj.name = `${obj.firstName} ${obj.lastName}`;
      });
      if (cusotmerList) {
        this.customerList = cusotmerList.data.result;
        this.customerList.map(
          (list) => (list.name = `${list.firstName} ${list.lastName}`)
        );
        this.customerList.push({ name: "Add new customer" });
      }
      this.loadingUserList = false;
      if (!event.query.trim().length) {
        this.filteredUserList = [...this.usersList];
      } else {
        this.filteredUserList = this.usersList.filter((list) => {
          return list.name.toLowerCase().includes(event.query.toLowerCase());
        });

        if (!this.filteredUserList.length) {
          this.filteredUserList.push({ name: "Add new customer" });
        }

        this.filteredUserList.length &&
          this.filteredUserList.map((list) => {
            if (list.name !== event.query) {
              return (this.v$.$invalid = true);
            }
          });
      }
    },

    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };

      if (fieldName === "mobile") {
        this.formData.mobile = value.toString();
      }
    },

    async save() {
      try {
        this.isloading = true;
        const data = { ...this.formData };
        data["application_id"] = this.$route.params.id;
        const response = await _post(APP_URL + "add_applicants_crm", data);

        if (response) {
          this.$emit("save");
          this.$emit("close");
          this.isloading = false;
        }
      } catch (error) {
        this.isloading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },
  created() {
    let eighteenYearsAgo = new Date();
    eighteenYearsAgo = eighteenYearsAgo.setFullYear(
      eighteenYearsAgo.getFullYear() - 18
    );
    this.maxDate = new Date(eighteenYearsAgo);
    this.maxYear = this.maxDate.getFullYear();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}

.full-width {
  width: 33rem;
}

.details {
  .title {
    font-family: Gordita-medium-;
    font-size: 1rem;
  }
}

::v-deep .p-inputtext {
  font-family: Gordita-medium !important;
  width: inherit;
}

.label {
  font-family: Gordita-medium;
  color: $secondary-color;
  font-size: 14px;
}

::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

p {
  margin: 0;
}
</style>
