<template>
  <div class="total-value-wrapper mr-top">
    <div v-if="totalValues" class="total-values">
      <div class="flex flex-gap align-items-center">
        <div v-for="value of totalValues" :key="value.id">
          <p class="title">{{ value.text }}</p>
          <p class="value">{{ value.value }}</p>
        </div>
      </div>
    </div>

    <div
      :class="totalValues && 'mr-top-1'"
      class="total flex align-items-center justify-content-between"
    >
      <p class="title">{{ overallValue[0] }}</p>
      <p class="value">{{ overallValue[1] }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["totalValues", "overallValue"],
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.total-value-wrapper {
  background: #02003808;
  border: 1px solid #02003866;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px;

  p {
    margin: 0;
  }

  .flex-gap {
    gap: 2.5rem;
  }

  .total-values {
    border-bottom: 1px solid #02003826;
    padding-bottom: 20px;

    .title {
      color: $secondary-color;
      font-family: Gordita;
      font-size: 1.125rem;
    }

    .value {
      color: $secondary-color;
      font-family: Gordita-bold;
      font-size: 1.313rem;
    }
  }

  .total {
    .title {
      font-family: Gordita-bold;
      font-size: 1.313rem;
      color: $secondary-color;
      text-transform: uppercase;
    }

    .value {
      font-family: Gordita-bold;
      font-size: 1.313rem;
      color: $secondary-color;
    }
  }
}
.mr-top {
  margin-top: 2.5rem;
}

.mr-top-1 {
  margin-top: 1rem !important;
}
</style>
