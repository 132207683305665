<template>
  <div>
    <Tasks
      :selectedOpt="selectedOpt"
      :filterField="filterField"
      :filterValue="filterValue"
      :getTaskList="getTaskList"
      :refreshKey="refreshKey"
    />
    <CompletedTasks
      :selectedOpt="selectedOpt"
      :filterField="filterField"
      :refreshKey="refreshKey"
      :filterValue="filterValue"
    />
  </div>
</template>

<script>
import Tasks from "./Tasks.vue";
import CompletedTasks from "./CompletedTask.vue";
export default {
  components: { Tasks, CompletedTasks },
  props: [
    "selectedOpt",
    "filterField",
    "filterValue",
    "getTaskList",
    "refreshKey",
  ],
};
</script>

<style></style>
