import { createRouter, createWebHistory } from "vue-router";
import CRMRoutes from './crm'


const routes = [
    {
        path: '/',
        redirect: '/dashboard',
    },
    ...CRMRoutes
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
