export default {
    namespaced: true,
    state: {
        fullTimeIncome: {
            applicant_1: [],
            applicant_2: [],
        },
        partTimeIncome: {
            applicant_1: [],
            applicant_2: [],
        },
        casualIncome: {
            applicant_1: [],
            applicant_2: [],
        },
        selfIncome: {
            applicant_1: [],
            applicant_2: [],
        },
        additionalIncome: {
            applicant_1: 0,
            applicant_2: 0,
        },
        overAllTotal: {
            applicant_1: 0,
            applicant_2: 0,
        },
        expenseTotal: {
            applicant_1: 0,
            applicant_2: 0,
        },
        savingsTotal: {
            applicant_1: 0,
            applicant_2: 0
        },
        savingsPostTotal: {
            applicant_1: 0,
            applicant_2: 0
        },
        sharesTotal: {
            applicant_1: 0,
            applicant_2: 0
        },
        superTotal: {
            applicant_1: 0,
            applicant_2: 0
        },
        propertyTotal: {
            applicant_1: 0,
            applicant_2: 0
        },
        cardsTotal: {
            applicant_1: 0,
            applicant_2: 0,
        },
        vehicleTotal: {
            applicant_1: 0,
            applicant_2: 0,
        },
        personalLoanTotal: {
            applicant_1: 0,
            applicant_2: 0,
        },
        monthlySurplus: 0,
        closeLiablityTotal: {
            applicant_1: 0,
            applicant_2: 0
        },
        closeLiablityPersonalTotal: {
            applicant_1: 0,
            applicant_2: 0
        },
        closeLiablityCardTotal: {
            applicant_1: 0,
            applicant_2: 0
        },
        closeLiabilityVehicleTotal: {
            applicant_1: 0,
            applicant_2: 0
        },
        savingsTotalDetails: {
            towards_purchase_applicant_1: 0,
            post_settlement_applicant_1: 0,
            towards_purchase_applicant_2: 0,
            post_settlement_applicant_2: 0
        },
        superTotalDetails: {
            towards_purchase_applicant_1: 0,
            post_settlement_applicant_1: 0,
            towards_purchase_applicant_2: 0,
            post_settlement_applicant_2: 0
        },
        shareTotalDetails: {
            towards_purchase_applicant_1: 0,
            post_settlement_applicant_1: 0,
            towards_purchase_applicant_2: 0,
            post_settlement_applicant_2: 0
        }
    },
    getters: {
        fullTimeIncomeTotal: ({ fullTimeIncome }) => {
            return fullTimeIncome;
        },
        partTimeIncomeTotal: ({ partTimeIncome }) => {
            return partTimeIncome;
        },
        casualIncomeTotal: ({ casualIncome }) => {
            return casualIncome;
        },
        selfIncomeTotal: ({ selfIncome }) => {
            return selfIncome;
        },
        additionalIncomeTotal: ({ additionalIncome }) => additionalIncome,
        overAllTotal: ({ overAllTotal }) => overAllTotal,
        expenseTotal: ({ expenseTotal }) => expenseTotal,
        savingsTotal: ({ savingsTotal }) => savingsTotal,
        savingsPostTotal: ({ savingsPostTotal }) => savingsPostTotal,
        sharesTotal: ({ sharesTotal }) => sharesTotal,
        superTotal: ({ superTotal }) => superTotal,
        propertyTotal: ({ propertyTotal }) => propertyTotal,
        cardsTotal: ({ cardsTotal }) => cardsTotal,
        vehicleTotal: ({ vehicleTotal }) => vehicleTotal,
        personalLoanTotal: ({ personalLoanTotal }) => personalLoanTotal,
        monthlySurplus: ({ monthlySurplus }) => monthlySurplus,
        closeLiablityTotal: ({ closeLiablityTotal }) => closeLiablityTotal,
        closeLiabilityVehicleTotal: ({ closeLiabilityVehicleTotal }) => closeLiabilityVehicleTotal,
        closeLiablityCardTotal: ({ closeLiablityCardTotal }) => closeLiablityCardTotal,
        closeLiablityPersonalTotal: ({ closeLiablityPersonalTotal }) => closeLiablityPersonalTotal
    },
    mutations: {
        setIncomeEmpty(storeState) {
            storeState.fullTimeIncome.applicant_1 = []
            storeState.fullTimeIncome.applicant_2 = []
            storeState.partTimeIncome.applicant_1 = []
            storeState.partTimeIncome.applicant_2 = []
            storeState.casualIncome.applicant_1 = []
            storeState.casualIncome.applicant_2 = []
            storeState.selfIncome.applicant_1 = []
            storeState.selfIncome.applicant_2 = []
            storeState.additionalIncome.applicant_1 = []
            storeState.additionalIncome.applicant_2 = []
            storeState.expenseTotal.applicant_1 = 0
            storeState.expenseTotal.applicant_2 = 0

            storeState.savingsTotal.applicant_1 = 0
            storeState.savingsTotal.applicant_2 = 0

            storeState.savingsPostTotal.applicant_1 = 0
            storeState.savingsPostTotal.applicant_2 = 0

            storeState.sharesTotal.applicant_1 = 0
            storeState.sharesTotal.applicant_2 = 0

            storeState.superTotal.applicant_1 = 0
            storeState.superTotal.applicant_2 = 0

            storeState.propertyTotal.applicant_1 = 0
            storeState.propertyTotal.applicant_2 = 0

            storeState.cardsTotal.applicant_1 = 0
            storeState.cardsTotal.applicant_2 = 0

            storeState.vehicleTotal.applicant_1 = 0
            storeState.vehicleTotal.applicant_2 = 0

            storeState.personalLoanTotal.applicant_1 = 0
            storeState.personalLoanTotal.applicant_2 = 0

            storeState.closeLiablityTotal.applicant_1 = 0
            storeState.closeLiablityTotal.applicant_2 = 0

            storeState.closeLiablityPersonalTotal.applicant_1 = 0
            storeState.closeLiablityPersonalTotal.applicant_2 = 0

            storeState.closeLiablityCardTotal.applicant_1 = 0
            storeState.closeLiablityCardTotal.applicant_2 = 0

            storeState.closeLiabilityVehicleTotal.applicant_1 = 0
            storeState.closeLiabilityVehicleTotal.applicant_2 = 0
        },
        setFullTimeIncomeTotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.fullTimeIncome.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.fullTimeIncome.applicant_2 = payload.data
                    break;
            }
        },
        setPartTimeIncomeTotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.partTimeIncome.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.partTimeIncome.applicant_2 = payload.data
                    break;
            }
        },
        setCasualIncomeTotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.casualIncome.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.casualIncome.applicant_2 = payload.data
                    break;
            }
        },
        setSelfEmployeeTotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.selfIncome.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.selfIncome.applicant_2 = payload.data
                    break;
            }
        },
        setAdditionalIncomeTotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.additionalIncome.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.additionalIncome.applicant_2 = payload.data
                    break;
            }
        },
        setOverallIncomeTotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.overAllTotal.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.overAllTotal.applicant_2 = payload.data
                    break;
            }
        },
        setExpenseTotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.expenseTotal.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.expenseTotal.applicant_2 = payload.data
                    break;
            }
        },

        setSavingsTotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.savingsTotal.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.savingsTotal.applicant_2 = payload.data
                    break;
            }
        },
        setSavingsPostTotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.savingsPostTotal.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.savingsPostTotal.applicant_2 = payload.data
                    break;
            }
        },
        setSharesTotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.sharesTotal.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.sharesTotal.applicant_2 = payload.data
                    break;
            }
        },
        setSuperTotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.superTotal.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.superTotal.applicant_2 = payload.data
                    break;
            }
        },
        setPropertyTotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.propertyTotal.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.propertyTotal.applicant_2 = payload.data
                    break;
            }
        },
        setCardsTotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.cardsTotal.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.cardsTotal.applicant_2 = payload.data
                    break;
            }
        },
        setVehicleTotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.vehicleTotal.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.vehicleTotal.applicant_2 = payload.data
                    break;
            }
        },
        setPersonalLoanTotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.personalLoanTotal.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.personalLoanTotal.applicant_2 = payload.data
                    break;
            }
        },
        setMonthlySurplus(storeState, payload) {
            storeState.monthlySurplus = payload;
        },
        setCloseLiabilitytotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.closeLiablityTotal.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.closeLiablityTotal.applicant_2 = payload.data
                    break;
            }
        },
        setCloseLiabilityPersonaltotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.closeLiablityPersonalTotal.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.closeLiablityPersonalTotal.applicant_2 = payload.data
                    break;
            }
        },
        setCloseLiabilityCardtotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.closeLiablityCardTotal.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.closeLiablityCardTotal.applicant_2 = payload.data
                    break;
            }
        },
        setCloseLiabilityVehicletotal(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.closeLiabilityVehicleTotal.applicant_1 = payload.data
                    break;
                case "secondary":
                    storeState.closeLiabilityVehicleTotal.applicant_2 = payload.data
                    break;
            }
        },
        setSavingsTotalDetails(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.savingsTotalDetails.towards_purchase_applicant_1 = payload.towards_purchase
                    storeState.savingsTotalDetails.post_settlement_applicant_1 = payload.post_settlement
                    break;
                case "secondary":
                    storeState.savingsTotalDetails.towards_purchase_applicant_2 = payload.towards_purchase
                    storeState.savingsTotalDetails.post_settlement_applicant_2 = payload.post_settlement
                    break;
            }
        },
        setShareTotalDetails(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.shareTotalDetails.towards_purchase_applicant_1 = payload.towards_purchase
                    storeState.shareTotalDetails.post_settlement_applicant_1 = payload.post_settlement
                    break;
                case "secondary":
                    storeState.shareTotalDetails.towards_purchase_applicant_2 = payload.towards_purchase
                    storeState.shareTotalDetails.post_settlement_applicant_2 = payload.post_settlement
                    break;
            }
        },
        setSuperTotalDetails(storeState, payload) {
            switch (payload.type) {
                case "primary":
                    storeState.superTotalDetails.towards_purchase_applicant_1 = payload.towards_purchase
                    storeState.superTotalDetails.post_settlement_applicant_1 = payload.post_settlement
                    break;
                case "secondary":
                    storeState.superTotalDetails.towards_purchase_applicant_2 = payload.towards_purchase
                    storeState.superTotalDetails.post_settlement_applicant_2 = payload.post_settlement
                    break;
            }
        },
    },
    actions: {
        setIncomeEmpty({ commit }) {
            commit('setIncomeEmpty')
        },
        setFullTimeIncomeTotal({ commit }, payload) {
            commit("setFullTimeIncomeTotal", payload)
        },
        setPartTimeIncomeTotal({ commit }, payload) {
            commit("setPartTimeIncomeTotal", payload)
        },
        setCasualIncomeTotal({ commit }, payload) {
            commit("setCasualIncomeTotal", payload)
        },
        setSelfEmployeeTotal({ commit }, payload) {
            commit("setSelfEmployeeTotal", payload)
        },
        setAdditionalIncomeTotal({ commit }, payload) {
            commit("setAdditionalIncomeTotal", payload)
        },
        setOverallIncomeTotal({ commit }, payload) {
            commit("setOverallIncomeTotal", payload)
        },
        //Expenses
        setExpenseTotal({ commit }, payload) {
            commit("setExpenseTotal", payload)
        },
        //Assets
        setSavingsTotal({ commit }, payload) {
            commit("setSavingsTotal", payload)
        },
        setSavingsPostTotal({ commit }, payload) {
            commit('setSavingsPostTotal', payload)
        },
        setSharesTotal({ commit }, payload) {
            commit("setSharesTotal", payload)
        },
        setSuperTotal({ commit }, payload) {
            commit("setSuperTotal", payload)
        },
        setPropertyTotal({ commit }, payload) {
            commit("setPropertyTotal", payload)
        },

        setSavingsTotalDetails({ commit }, payload) {
            commit("setSavingsTotalDetails", payload)
        },
        setShareTotalDetails({ commit }, payload) {
            commit("setShareTotalDetails", payload)
        },
        setSuperTotalDetails({ commit }, payload) {
            commit("setSuperTotalDetails", payload)
        },
        //Liability
        setCardsTotal({ commit }, payload) {
            commit("setCardsTotal", payload)
        },
        setVehicleTotal({ commit }, payload) {
            commit("setVehicleTotal", payload)
        },
        setPersonalLoanTotal({ commit }, payload) {
            commit("setPersonalLoanTotal", payload)
        },
        setCloseLiabilitytotal({ commit }, payload) {
            commit("setCloseLiabilitytotal", payload)
        },
        setCloseLiabilityPersonaltotal({ commit }, payload) {
            commit("setCloseLiabilityPersonaltotal", payload)
        },
        setCloseLiabilityVehicletotal({ commit }, payload) {
            commit("setCloseLiabilityVehicletotal", payload)
        },
        setCloseLiabilityCardtotal({ commit }, payload) {
            commit("setCloseLiabilityCardtotal", payload)
        },
        //Monthly Surplus
        setMonthlySurplus({ commit }, payload) {
            commit("setMonthlySurplus", payload);
        },
    },
}
