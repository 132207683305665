<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
	<div class="mr-top">
		<div class="sub-title">{{ title }} - Expenses</div>
		<div class="form-inputs mr-top-10px">
			<div class="p-grid align-items-end">
				<!-- Col-1 -->
				<div class="p-col-10">
					<!-- Row-1 -->

					<div class="p-grid align-items-center mr-b-5px">
						<div class="p-col-3">
							<p class="label">Groceries</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="details.groceries"
								type="number"
								width="100%"
								@onInput="(e) => getFormData(e, 'groceries')"
								:inValid="v$.details.groceries.$invalid && saveBtnClicked"
							/>
						</div>

						<div class="p-col-3">
							<p class="label">Frequency</p>
							<Dropdown
								:items="frequencyOpt"
								:value="'Monthly'"
								:disable="true"
								width="100%"
							/>
						</div>

						<div class="p-col-3">
							<p class="label">Clothing</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								@onInput="(e) => getFormData(e, 'clothing')"
								:value="details.clothing"
								type="number"
								width="100%"
								:inValid="v$.details.clothing.$invalid && saveBtnClicked"
							/>
						</div>

						<div class="p-col-3">
							<p class="label">Frequency</p>
							<Dropdown
								:items="frequencyOpt"
								value="Monthly"
								:disable="true"
								width="100%"
							/>
						</div>
					</div>

					<!-- Row-2 -->

					<div class="p-grid align-items-center mr-b-5px">
						<div class="p-col-3">
							<p class="label">Education</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="details.education"
								@onInput="(e) => getFormData(e, 'education')"
								type="number"
								:inValid="v$.details.education.$invalid && saveBtnClicked"
								width="100%"
							/>
						</div>

						<div class="p-col-3">
							<p class="label">Frequency</p>
							<Dropdown
								:items="frequencyOpt"
								value="Monthly"
								:disable="true"
								width="100%"
							/>
						</div>

						<div class="p-col-3">
							<p class="label">Insurance</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="details.insurance_premium"
								@onInput="(e) => getFormData(e, 'insurance_premium')"
								type="number"
								:inValid="
									v$.details.insurance_premium.$invalid &&
									saveBtnClicked
								"
								width="100%"
							/>
						</div>

						<div class="p-col-3">
							<p class="label">Frequency</p>
							<Dropdown
								:items="frequencyOpt"
								value="Monthly"
								:disable="true"
								width="100%"
							/>
						</div>
					</div>

					<!-- Row-3 -->

					<div class="p-grid align-items-center mr-b-5px">
						<div class="p-col-3">
							<p class="label">Child care</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="details.child_care"
								@onInput="(e) => getFormData(e, 'child_care')"
								type="number"
								width="100%"
								:inValid="
									v$.details.child_care.$invalid && saveBtnClicked
								"
							/>
						</div>

						<div class="p-col-3">
							<p class="label">Frequency</p>
							<Dropdown
								:items="frequencyOpt"
								value="Monthly"
								:disable="true"
								width="100%"
							/>
						</div>

						<div class="p-col-3">
							<p class="label">Medical</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="details.medical_health"
								@onInput="(e) => getFormData(e, 'medical_health')"
								type="number"
								:inValid="
									v$.details.medical_health.$invalid && saveBtnClicked
								"
								width="100%"
							/>
						</div>

						<div class="p-col-3">
							<p class="label">Frequency</p>
							<Dropdown
								:items="frequencyOpt"
								value="Monthly"
								:disable="true"
								width="100%"
							/>
						</div>
					</div>

					<!-- Row-4 -->

					<div class="p-grid align-items-center mr-b-5px">
						<div class="p-col-3">
							<p class="label">Entertainment</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="details.recreation"
								@onInput="(e) => getFormData(e, 'recreation')"
								type="number"
								:inValid="
									v$.details.recreation.$invalid && saveBtnClicked
								"
								width="100%"
							/>
						</div>

						<div class="p-col-3">
							<p class="label">Frequency</p>
							<Dropdown
								:items="frequencyOpt"
								value="Monthly"
								:disable="true"
								width="100%"
							/>
						</div>

						<div class="p-col-3">
							<p class="label">Communication</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="details.communication"
								@onInput="(e) => getFormData(e, 'communication')"
								type="number"
								:inValid="
									v$.details.communication.$invalid && saveBtnClicked
								"
								width="100%"
							/>
						</div>

						<div class="p-col-3">
							<p class="label">Frequency</p>
							<Dropdown
								:items="frequencyOpt"
								value="Monthly"
								:disable="true"
								width="100%"
							/>
						</div>
					</div>

					<!-- Row-5 -->

					<div class="p-grid align-items-center mr-b-5px">
						<div class="p-col-3">
							<p class="label">Home Property Costs</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="details.home_property_cost"
								@onInput="(e) => getFormData(e, 'home_property_cost')"
								type="number"
								:inValid="
									v$.details.home_property_cost.$invalid &&
									saveBtnClicked
								"
								width="100%"
							/>
						</div>

						<div class="p-col-3">
							<p class="label">Frequency</p>
							<Dropdown
								:items="frequencyOpt"
								value="Monthly"
								:disable="true"
								width="100%"
							/>
						</div>

						<div class="p-col-3">
							<p class="label">Investment Property Costs</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="details.investment_prop_cost"
								@onInput="(e) => getFormData(e, 'investment_prop_cost')"
								type="number"
								width="100%"
								:inValid="
									v$.details.investment_prop_cost.$invalid &&
									saveBtnClicked
								"
							/>
						</div>

						<div class="p-col-3">
							<p class="label">Frequency</p>
							<Dropdown
								:items="frequencyOpt"
								value="Monthly"
								:disable="true"
								width="100%"
							/>
						</div>
					</div>

					<!-- Row-6 -->

					<div class="p-grid align-items-center mr-b-5px">
						<div class="p-col-3">
							<p class="label">Transport</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="details.transport"
								@onInput="(e) => getFormData(e, 'transport')"
								type="number"
								width="100%"
								:inValid="v$.details.transport.$invalid && saveBtnClicked"
							/>
						</div>

						<div class="p-col-3">
							<p class="label">Frequency</p>
							<Dropdown
								:items="frequencyOpt"
								value="Monthly"
								:disable="true"
								width="100%"
							/>
						</div>

						<div class="p-col-3">
							<p class="label">Other</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								@onInput="(e) => getFormData(e, 'others')"
								:value="details.others"
								type="number"
								:inValid="v$.details.others.$invalid && saveBtnClicked"
								width="100%"
							/>
						</div>

						<div class="p-col-3">
							<p class="label">Frequency</p>
							<Dropdown
								:items="frequencyOpt"
								value="Monthly"
								:disable="true"
								width="100%"
							/>
						</div>
					</div>

					<!-- Row-7 -->

					<div class="p-grid align-items-center mr-b-5px">
						<div class="p-col-6">
							<p class="label">Relationship Status</p>
							<Dropdown
								:items="primaryRelationships"
								optionLabel="title"
								optionValue="value"
								:value="applicant_relationship"
								width="100%"
								@change="(e) => setRelationship(e)"
							/>
						</div>

						<div class="p-col-6">
							<p class="label">Dependants</p>
							<Dropdown
								:items="dependentType"
								optionValue="value"
								optionLabel="title"
								:value="no_of_dependents"
								width="100%"
								@change="(e) => setDependent(e)"
								:id="`noOfDependents_${title}`"
							/>
						</div>
					</div>
				</div>

				<!-- Col-2 -->

				<div class="p-col-2 highlight-fields">
					<div class="mr-b-5px disable-input">
						<p class="label">Monthly Expenses</p>
						<TotalField
							mode="decimal"
							:format="true"
							prefix="$"
							:disable="true"
							:value="monthlyExpenses"
							width="100%"
              :id="`monthlyExpense_${title}`"
						/>
					</div>

					<div class="mr-b-5px disable-input">
						<p class="label">HEM Value</p>
						<NumberField
							mode="decimal"
							:format="true"
							prefix="$"
							:disable="true"
							:key="hemKey"
							:value="hemValue"
							width="100%"
							:id="`hem_value_${title}`"
						/>
					</div>
				</div>
			</div>

			<!-- Col-2 -->

			<div class="p-grid align-items-center mr-top-5px disable-input">
				<div class="p-col-10 flex justify-content-end">
					<p class="label highlight-text">Expenses value used</p>
				</div>
				<TotalField
					mode="decimal"
					:format="true"
					prefix="$"
					:value="expenseUsedValue"
					:disable="true"
					class="p-col-2"
					placeholder="label"
					width="100%"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
	components: {},
	props: ["title", "disable"],
	setup: () => ({ v$: useVuelidate() }),
	validations() {
		return {
			no_of_dependents: { required },
			details: {
				groceries: { required },
				clothing: { required },
				education: { required },
				insurance_premium: { required },
				child_care: { required },
				medical_health: { required },
				recreation: { required },
				communication: { required },
				home_property_cost: { required },
				investment_prop_cost: { required },
				transport: { required },
				others: { required },
			},
		};
	},
	data() {
		return {
			hemValue: 0,
			collapsed: true,
			frequencyOpt: ["Weekly", "Fortnightly", "Monthly", "Quarterly", "Yearly"],
			primaryRelationships: [
				{
					title: "Single",
					value: "single",
				},
				{
					title: "Married",
					value: "married",
				},
			],
			dependentType: [
				{
					title: "0",
					value: "0",
				},
				{
					title: "1",
					value: "1",
				},
				{
					title: "2",
					value: "2",
				},
				{
					title: "3+",
					value: "3",
				},
			],
			no_of_dependents: "",
			applicant_relationship: "",
			hemKey: 10,
			details: {
				// groceries: 0,
				// clothing: 0,
				// education: 0,
				// insurance_premium: 0,
				// child_care: 0,
				// medical_health: 0,
				// recreation: 0,
				// communication: 0,
				// home_property_cost: 0,
				// investment_prop_cost: 0,
				// transport: 0,
				// others: 0,
			},
		};
	},
	computed: {
		...mapGetters({
			saveBtnClicked: "applications/saveBtnClicked",
			selectedApplication: "applications/selectedApplication",
		}),
		expenseUsedValue: {
			get: function () {
				let total =
					this.monthlyExpenses > this.hemValue
						? this.monthlyExpenses
						: this.hemValue;
				this.setExpenseTotal({
					data: total ? Number(total) : 0,
					type: !this.disable ? "primary" : "secondary",
				});
				return total ? Number(total) : 0;
			},
		},
		monthlyExpenses: {
			get: function () {
				let total =
					Number(this.details.groceries) +
					Number(this.details.clothing) +
					Number(this.details.education) +
					Number(this.details.insurance_premium) +
					Number(this.details.child_care) +
					Number(this.details.recreation) +
					Number(this.details.medical_health) +
					Number(this.details.communication) +
					Number(this.details.home_property_cost) +
					Number(this.details.investment_prop_cost) +
					Number(this.details.transport) +
					Number(this.details.others);
				return total ? total : 0;
			},
		},
	},
	methods: {
		...mapActions({
			setExpenses: "applications/setExpenses",
			setApplicantRelationship: "applications/setApplicantRelationship",
			setNumberOfDependent: "applications/setNumberOfDependent",
			setValidation: "applications/setValidation",
			setExpenseTotal: "subTotal/setExpenseTotal",
		}),
		isSubmitDisabled() {
			return this.v$.$invalid;
		},
		toggle() {
			this.collapsed = !this.collapsed;
		},
		setRelationship(value) {
			this.setApplicantRelationship({
				data: value,
				type: this.title === "Applicant 1" ? "primary" : "secondary",
			});
		},
		setDependent(value) {
			this.setNumberOfDependent({
				data: value,
				type: this.title === "Applicant 1" ? "primary" : "secondary",
			});
		},
		getFormData(value, field) {
			this.details[field] = value;
			this.details = { ...this.details };
		},
		setData() {
			if (
				this.selectedApplication.applicant_2_relationship_with_applicant_1 ==
					"married" &&
				this.title != "Applicant 1"
			) {
				this.hemValue = 0;
			} else {
				if (this.title === "Applicant 1") {
					this.hemValue =
						this.selectedApplication?.calculated_fields &&
						this.selectedApplication?.calculated_fields?.hem_value
							? Number(
									this.selectedApplication?.calculated_fields?.hem_value
						)
							: 0;
				} else {
					this.hemValue = this.selectedApplication?.applicant_2_hem_value
						? Number(this.selectedApplication?.applicant_2_hem_value)
						: 0;
				}
			}
			this.hemKey += 1;
			this.applicant_relationship =
				this.title === "Applicant 1"
					? this.selectedApplication.applicant_1_relationship
						? this.selectedApplication.applicant_1_relationship
						: ""
					: this.selectedApplication.applicant_2_relationship
					? this.selectedApplication.applicant_2_relationship
					: "";
			if (this.applicant_relationship == "marriedstatus") {
				this.applicant_relationship = "married";
			}
			this.no_of_dependents =
				this.title === "Applicant 1" &&
				this.selectedApplication.applicant_1_dependents
					? this.selectedApplication.applicant_1_dependents
					: this.selectedApplication.applicant_2_dependents;

			this.setNumberOfDependent({
				data: this.no_of_dependents,
				type: this.title === "Applicant 1" ? "primary" : "secondary",
			});
			this.setApplicantRelationship({
				data:
					this.title === "Applicant 1"
						? this.selectedApplication.applicant_1_relationship
							? this.selectedApplication.applicant_1_relationship
							: ""
						: this.selectedApplication.applicant_2_relationship
						? this.selectedApplication.applicant_2_relationship
						: "",
				type: this.title === "Applicant 1" ? "primary" : "secondary",
			});
			if (this.selectedApplication.application_id) {
				if (this.title === "Applicant 1") {
					if (this.selectedApplication.expenses.length > 0) {
						let expenseDetails1 = this.selectedApplication.expenses.filter(
							(item) => item.applicant_type === "primary"
						);
						expenseDetails1.map((item) => {
							this.details[item.type] = item.amount;
						});
					}
					return;
				}
				if (this.title === "Applicant 2") {
					if (this.selectedApplication.expenses.length > 0) {
						let expenseDetails2 = this.selectedApplication.expenses.filter(
							(item) => item.applicant_type === "secondary"
						);
						expenseDetails2.map((item) => {
							this.details[item.type] = item.amount;
						});
					}
					return;
				}
			}
			this.setExpenses({
				data: this.details,
				type: this.title === "Applicant 1" ? "primary" : "secondary",
			});
		},
	},
	created() {
		this.setData();
	},
	watch: {
		details: {
			handler() {
				this.setExpenses({
					data: this.details,
					type: this.title === "Applicant 1" ? "primary" : "secondary",
				});
				this.setValidation({
					type: "expense",
					value: this.isSubmitDisabled(),
				});
			},
			deep: true,
		},
	},
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-panel .p-panel-content {
	padding-left: 0;
	padding-right: 0;
}

::v-deep .p-panel-header {
	background: unset !important;
	border: none !important;
	border-bottom: 1px solid #02003826 !important;
	padding: 0 !important;
	padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
	border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
	display: none !important;
}

.sub-title {
	font-size: 1.25rem;
	color: $secondary-color;
	font-family: Gordita-bold;
	padding: 10px 0;
	border-bottom: 1px solid #02003826 !important;
	margin-bottom: 2rem;
}

.icon-wrapper {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 2px solid rgba(2, 0, 56, 0.4);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

::v-deep .p-col-10 {
	padding-bottom: 0;
	padding-top: 0;
}

.label {
	color: $secondary-color;
	font-size: 0.875rem;
	font-family: Gordita-medium;
	margin: 0;
}

.highlight-fields {
	.label {
		font-family: Gordita-bold !important;
	}
}

.mr-b-5px {
	margin-bottom: 5px;
}

.mr-top {
	margin-top: 2.5rem;
}

.mr-top-5px {
	margin-top: 5px;
}

.highlight-text {
	font-family: Gordita-bold;
	color: $secondary-color;
	font-size: 18px;
}

.mr-top-10px {
	margin-top: 10px;
}

.flex-gap {
	gap: 0.625rem;
}
</style>
