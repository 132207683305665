<template>
  <Dialog
    :draggable="false"
    :visible="show"
    :modal="show"
    :style="{ width: '580px' }"
  >
    <template #header>
      <h1 class="modal-title">Send Offer</h1>
    </template>
    <div class="modal-content">
      <div>Email Template</div>
      <Dropdown width="100%" placeholder="-select" class="mt-2" />
      <div class="flex mt-3">
        <div class="col">
          <div>To</div>
          <TextField
            width="100%"
            placeholder="Enter recipirnt mail"
            class="mt-2"
          />
        </div>
        <div class="col ml-2">
          <div>From</div>
          <TextField width="100%" placeholder="Enter mail id" class="mt-2" />
        </div>
      </div>

      <div class="mt-3">Subject</div>
      <TextField width="100%" placeholder="Subject" class="mt-2" />

      <div class="mt-3">
        <div class="mt-2">Body</div>
        <Editor class="mt-2" />
      </div>
      <div class="attachment mt-3 hk-md">+ Add attachment</div>
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button label="Send" type="primary" @click="save" />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">
          Close
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";

export default {
  props: ["show"],
  components: { Dialog },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.attachment {
  color: rgba(2, 0, 56, 0.4);
  font-weight: 500;
  font-size: 14px;
  font-family: Gordita-medium;
}
</style>
