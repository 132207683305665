<template>
  <div class="pl-4 pr-4 pb-4">
    <Spinner v-if="isLoading" class="flex" />
    <Card v-else>
      <template #content>
        <RecentActivity />
        <Summary />
        <PropertyDetails />
        <Income />
        <Expenses />
        <Assets />
        <Liabilites /> 
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "primevue/card";
import RecentActivity from "./RecentActivity.vue";
import Summary from "./Summary/index.vue";
import PropertyDetails from "./Property-details/index.vue";
import Income from "./Income/index.vue";
import Expenses from "./Expenses/index.vue";
import Assets from "./Assets/index.vue";
import Liabilites from "./Liabilities/index.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Card,
    RecentActivity,
    Summary,
    PropertyDetails,
    Income,
    Expenses,
    Assets,
    Liabilites,
  },
  data() {
    return {
      original: {},
      latest: {},
      isLoading: true,
    };
  },
  methods: {
    ...mapActions({
      getAuditLogs: "loans/getAuditLogs",
    }),
  },
  async created() {
    this.isLoading = true;
    await this.getAuditLogs(this.$route.params.id);
    this.isLoading = false;
  },
};
</script>

<style></style>
