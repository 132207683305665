<template>
  <Dialog
    :draggable="false"
    :visible="show"
    :modal="show"
    :style="{ width: '580px' }"
  >
    <template #header>
      <h1 v-if="!notesId" class="modal-title">New note</h1>
      <h1 v-else class="modal-title">Edit note</h1>
      <i class="icon pi pi-times" @click="close" style="margin-left: auto" />
    </template>
    <div class="modal-content">
      <!-- <div>Note type</div>
      <Dropdown width="100%" placeholder="-Select-" class="mt-2" /> -->
      <div class="mt-2">Body</div>
      <Editor
        :textValue="formData.body"
        @getInputValue="getFormData($event, 'body')"
        class="mt-2"
      />
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button
          :disabled="isSubmitDisabled()"
          @click="click"
          label="Save"
          :loading="isloading"
          type="primary"
        />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">
          Close
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import { _post, _put } from "../../../../../services/crudService";
import { APP_URL } from "../../../../../../constants";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "AddNote",
  props: ["show", "notesId", "notesData"],
  components: { Dialog },
  data() {
    return {
      formData: {
        body: "",
      },
      isloading: false,
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        body: { required },
      },
    };
  },
  methods: {
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    close() {
      this.$emit("close");
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    async click() {
      if (this.$props.notesId) {
        try {
          this.isloading = true;

          const response = await _put(
            APP_URL + `update-notes-by-id?id=${this.$props.notesId}`,
            { notes: this.formData.body }
          );

          if (response) {
            this.isloading = false;
            this.$emit("close");
            this.$emit("save");
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Note saved successfully",
              life: 3000,
            });
          }
        } catch (error) {
          this.isloading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }

        return;
      }

      try {
        this.isloading = true;
        const data = {
          body: this.formData.body,
          application_id: this.$route.params.id,
        };
        const response = await _post(APP_URL + "insert_notes", data);

        if (response) {
          this.isloading = false;
          this.$emit("close");
          this.$emit("save");
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Note sent successfully",
            life: 3000,
          });
        }
      } catch (error) {
        this.isloading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },
  created() {
    if (this.$props.notesId) {
      this.formData.body = this.$props.notesData;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
</style>
