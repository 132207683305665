<template>
  <Toolbar class="padding-x">
    <template #left>
      <img src="@/assets/bridgit.svg" />
    </template>
    <template #right>
      <div class="header-toolbar flex">
        <img src="@/assets/phone.svg" />
        <img class="ml-2" src="@/assets/phone-no.svg" alt="" />
      </div>
    </template>
  </Toolbar>
</template>

<script>
import Toolbar from "primevue/toolbar"

export default {
  name: "Header",
  components: {
    Toolbar,
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.p-toolbar {
  background: $white-color;
  border: unset !important;
  padding: 0;
  padding: 0 1rem;
  height: 80px;
}

.padding-x {
  padding: 0 2rem;
}

.right-col {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
