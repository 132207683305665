<template>
  <Dialog
    :draggable="false"
    :visible="show"
    :modal="show"
    :style="{ width: '580px' }"
  >
    <template #header>
      <h1 class="modal-title">Export Options</h1>
    </template>
    <div class="modal-content">
      <p class="pm-txt-color hk-sb fs-14">What would you like to do?</p>
      <div class="flex flex-gap align-items-center">
        <input type="radio" name="export" id="csv" />
        <label class="pm-txt-color hk-sb fs-14" for="csv"
          >Export loan as CSV</label
        >
      </div>
      <div class="flex flex-gap align-items-center">
        <input type="radio" name="export" id="pdf" />
        <label class="pm-txt-color hk-sb fs-14" for="pdf"
          >Export the Credit Summary as PDF</label
        >
      </div>
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button label="Export" type="primary" @click="save" />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">
          Close
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog"

export default {
  props: ["show"],
  components: { Dialog },
  methods: {
    close() {
      this.$emit("close")
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.fs-14 {
  font-size: 16px;
}

.flex-gap {
  gap: 0.7rem;
}
</style>
