import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()

export default {
  namespaced: true,
  state: {
    isLoggedIn: false,
    token: "",
    userPermission: {},
    loggedUserId: "",
    username: "",
  },
  getters: {
    token: ({ token }) => token,
    isLoggedIn: ({ isLoggedIn }) => isLoggedIn,
    userPermission: ({ userPermission }) => userPermission,
    loggedUserId: ({ loggedUserId }) => loggedUserId,
    username: ({ username }) => username,
  },
  mutations: {
    setLogin(state, value) {
      state.isLoggedIn = true
      state.token = value.token
      state.username = value.username
      localStorage.setItem("token", state.token)
      cookies.set("token", state.token)
      if (!value.token) {
        window.localStorage.clear()
        localStorage.removeItem("vuex")
        cookies.remove("token")
        location.reload()
      }
    },
    setPermission(state, value) {
      state.userPermission = value[0]
      state.loggedUserId = value[0]?.Id
    },
  },
  actions: {
    async setLogin({ commit }, payload) {
      // const response = await _post(SERVER_URL + "login", payload)
      commit("setLogin", payload.data)
      commit("setPermission", payload.data.user_permission)
    },
    setLogout({ commit }) {
      commit("setLogin", "")
    },
  },
}
