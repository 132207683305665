<template>
  <Card class="box-shadow">
    <template #content>
      <div class="flex justify-content-between">
        <img src="@/assets/applications.svg" />
        <div class="flex">
          <div class="filter-opts flex align-items-center mr-2">
            <div class="label mr-2">Filter&nbsp;by</div>
            <Dropdown
              width="100%"
              @change="getFilterValue"
              placeholder="-Select-"
              :items="filterList"
              :value="selectedFilter"
              optionLabel="name"
              optionValue="value"
            />
          </div>
          <div v-if="selectedFilter === 'date'">
            <Datefilter
              class="mr-2 full-width datefilter-component"
              numberOfMonths="2"
              @filter="getFilteredData($event)"
            />
          </div>
          <span v-else class="p-input-icon-left ml-2 full-width">
            <i class="pi pi-search" />
            <TextField
              width="100%"
              type="text"
              v-model="searchValue"
              @onInput="getSearchValue($event)"
              placeholder="Search"
              :value="searchValue"
            />
          </span>
        </div>
      </div>
      <Spinner v-if="isloading" class="flex justify-content-between" />
      <DataTable
        v-else
        class="mt-2"
        :rows="10"
        selectionMode="multiple"
        dataKey="id"
        :value="allCustomers.result"
        :rowsPerPageOptions="[5, 10, 20, 30]"
        :totalRecords="allCustomers.length"
      >
        <template #empty> No data found. </template>

        <Column field="customer_Id" header="Customer ID">
          <template #body="slotProps">
            <a
              class="color-highlight"
              @click="
                goToApp(
                  slotProps.data.Id,
                  slotProps.data.active_application_id,
                  slotProps.data.Id,
                  slotProps.data.mobile,
                  slotProps.data.email,
                  slotProps.data.firstName
                )
              "
            >
              {{ slotProps.data.customer_id || "-" }}
            </a>
          </template>
        </Column>
        <Column field="firstName" header="Name">
          <template #body="slotProps">
            <p v-if="slotProps.data.firstName" class="capital">
              {{ slotProps.data.firstName }} {{ slotProps.data.middleName }}
              {{ slotProps.data.lastName }}
            </p>
            <p v-else>-</p>
          </template>
        </Column>
        <Column field="email" header="Email">
          <template #body="slotProps">
            <p v-if="slotProps.data.email">
              {{ slotProps.data.email }}
            </p>
            <p v-else>-</p>
          </template>
        </Column>
        <Column field="mobile" header="Mobile">
          <template #body="slotProps">
            <p v-if="slotProps.data.mobile">
              {{ slotProps.data.mobile }}
            </p>
            <p v-else>-</p>
          </template>
        </Column>
        <Column field="createdAt" header="Created">
          <template #body="slotProps">
            <p v-if="slotProps.data.createdAt">
              {{ formatDate(slotProps.data.createdAt) }}
            </p>
            <p v-else>-</p>
          </template>
        </Column>
      </DataTable>
      <Paginator
        class="mt-2"
        :rows="10"
        :totalRecords="allCustomers && allCustomers.totalCount"
        :rowsPerPageOptions="[10, 20, 30]"
        template="RowsPerPageDropdown  CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} of {totalRecords} customers"
        @page="onChangePage($event)"
      ></Paginator>
    </template>
  </Card>
</template>

<script>
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import moment from "moment";
import { _get } from "../../../services/crudService";
import { SERVER_URL } from "../../../../constants";
import { mapActions } from "vuex";
import Paginator from "primevue/paginator";
import Datefilter from "../../../components/common/DateFilter.vue";

export default {
  name: "ApplicationsList",
  components: {
    Card,
    DataTable,
    Paginator,
    Column,
    Datefilter,
  },
  data() {
    return {
      isloading: false,
      allCustomers: [],
      selectedFilter: "",
      searchValue: "",
      updateSearch: null,
      showDateFilter: false,
      pagination: {
        page: "1",
        row: "10",
      },
      dateFilter: {
        from_date: "",
        to_date: "",
      },
      filterList: [
        { name: "First name", value: "firstName" },
        { name: "Last name", value: "lastName" },
        { name: "Email", value: "email" },
        { name: "Mobile", value: "mobile" },
        { name: "Date", value: "date" },
      ],
      num: 255,
    };
  },
  methods: {
    ...mapActions({
      setApplicationId: "customers/setApplicationId",
    }),
    goToApp(id, appId, customerId, mobile, email, firstName) {
      if (appId && appId !== "00000000-0000-0000-0000-000000000000") {
        this.setApplicationId(appId);
        this.$emit("changes", {
          customerId: customerId,
          mobile: mobile,
          email: email,
          firstName: firstName || "",
        });
      }
    },
    getFilterValue(value) {
      this.selectedFilter = value;
      this.searchValue = "";
    },
    async dateFilterList() {
      this.isloading = true;
      try {
        const response = await _get(
          SERVER_URL +
            `get_customers?pageSize=${this.pagination.row}&pageIndex=${this.pagination.page}&table=customers&from=${this.dateFilter.from_date}&to=${this.dateFilter.to_date}&fields=${this.selectedFilter}`,
          this.formData
        );

        if (response) {
          this.allCustomers = response.data;
          this.isloading = false;
        }
      } catch (err) {
        this.isloading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    async getFilteredData(event) {
      if (event) {
        if (event[0] && event[1]) {
          let from_date = moment(event[0]).format("YYYY/MM/DD");
          let to_date = moment(event[1]).format("YYYY/MM/DD");

          this.dateFilter.from_date = from_date;
          this.dateFilter.to_date = to_date;

          this.dateFilterList();
        }
      } else {
        this.dateFilter.from_date = "";
        this.dateFilter.to_date = "";

        this.getCustomerList(1, 10);
      }
    },
    getSearchValue(value) {
      this.searchValue = value;
      if (this.selectedFilter !== "" && value !== "") {
        clearTimeout(this.updateSearch);

        this.updateSearch = setTimeout(async () => {
          this.getCustomerList(this.pagination.page, this.pagination.row);
        }, 800);
      }

      if (value === "") {
        this.getCustomerList(1, 10);
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    onChangePage(value) {
      this.pagination.page = value.page;
      this.pagination.rows = value.rows;
      if (this.selectedFilter == "date" && this.dateFilter.from_date) {
        this.dateFilterList();
        return;
      }
      this.getCustomerList(value.page + 1, value.rows);
    },

    async getCustomerList(page, row) {
      if (this.searchValue && this.selectedFilter) {
        let value = this.searchValue;
        let fields = this.selectedFilter;
        try {
          this.isloading = true;
          if (this.selectedFilter == "email") {
            value = encodeURIComponent(this.searchValue);
          }
          if (
            this.selectedFilter == "firstName" ||
            this.selectedFilter == "lastName"
          ) {
            value = this.searchValue.toLowerCase();
          }
          const result = await _get(
            SERVER_URL +
              `get_customers?pageSize=${row}&pageIndex=${page}&table=customers&value=${value}&fields=${fields}`
          );

          if (result) {
            this.isloading = false;
            this.allCustomers = result.data;
          }
        } catch (error) {
          this.isloading = false;
          this.allCustomers = [];

          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      } else {
        try {
          this.isloading = true;
          const result = await _get(
            SERVER_URL + `get_customers?pageSize=${row}&pageIndex=${page}`
          );

          if (result) {
            this.isloading = false;
            this.allCustomers = result.data;
          }
        } catch (error) {
          this.isloading = false;
          this.allCustomers = [];

          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
    },
  },

  async created() {
    await this.getCustomerList(1, 10);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.p-card {
  box-shadow: unset !important;
  border-radius: 0.25rem !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
}
::v-deep .p-card-title {
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem !important;
}
::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
  padding: 1rem 0 !important;
}
.color-highlight {
  color: $primary-color !important;
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}

:deep .p-datatable .p-datatable-thead > tr > th {
  padding: 1rem 0 !important;
}

:deep .p-card-content {
  padding: 0px !important;
}

:deep .p-card-body {
  padding: 25px !important;
}
.p-inputtext:enabled:focus {
  border-color: rgba(2, 0, 56, 0.15);
  border: 1px solid rgba(100, 97, 170, 0.3);
  box-shadow: unset;
}
.p-inputtext:enabled:hover {
  border-color: rgba(2, 0, 56, 0.15);
  border: 1px solid rgba(100, 97, 170, 0.3);
  box-shadow: unset;
}

:deep .input-field {
  padding-left: 2rem !important;
}

.filter-opts :deep .p-dropdown {
  min-width: 120px !important;
}
</style>
