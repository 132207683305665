<template>
	<div class="mr-top" :key="refreshKey">
		<div class="heading flex align-items-center justify-content-between">
			<div class="sub-title">Outgoing Properties</div>
			<div class="right-col flex align-items-center">
				<Button
					@click="addProperty"
					class="ml-3"
					label="Add Property"
					type="primary"
				/>
			</div>
		</div>
		<div v-for="(property, i) in properties" :key="i">
			<div class="form-inputs mr-top-10px">
				<div class="p-grid">
					<!-- Col-1 -->

					<div class="p-col-8">
						<!-- Row-1 -->
						<div class="flex align-items-center justify-content-center">
							<AddressAutocompleteKleber
								class="col col-9 pt-4"
								:isLoading="isAddressLoading"
								@onChange="onAddressChange($event, i)"
							/>
							<div class="p-grid align-items-end mr-b-5px">
								<div class="p-col-12">
									<p class="label">Finance clause</p>
									<SummaryDatePicker
										:value="property.finance_clause"
										placeholder="Enter date"
										width="100%"
										@change="
											(e) => getFormData(e, 'finance_clause', i)
										"
										:yearRange="'2022:2050'"
									/>
								</div>
							</div>
						</div>
						<div :key="addressKey" class="p-grid align-items-end mr-b-5px">
							<div class="p-col-6">
								<p class="label">Address Line 1</p>
								<TextField
									@onInput="(e) => getFormData(e, 'add_1', i)"
									:value="property.add_1"
									width="100%"
									:inValid="
										v$.properties.$silentErrors[0]?.$response.$data[i]
											.add_1.$invalid && saveBtnClicked
									"
								/>
							</div>
							<div class="p-col-3">
								<p class="label">Valuation status</p>
								<Dropdown
									:items="valuationStatus"
									:value="property.valuation_status"
									width="100%"
									@change="(e) => getFormData(e, 'valuation_status', i)"
								/>
							</div>
							<div class="p-col-3">
								<p class="label">Valuation date</p>
								<SummaryDatePicker
									:value="property.valuation_date"
									placeholder="Enter date"
									width="100%"
									@change="(e) => getFormData(e, 'valuation_date', i)"
									:yearRange="'2022:2050'"
								/>
							</div>
						</div>

						<!-- Row-2 -->

						<div :key="addressKey" class="p-grid align-items-center mr-b-5px">
							<div class="p-col-6">
								<p class="label">Address Line 2</p>
								<TextField
									@onInput="(e) => getFormData(e, 'add_2', i)"
									:value="property.add_2"
									width="100%"
								/>
							</div>
							<div class="p-col-3">
								<p class="label">Valuation firm</p>
								<TextField
									@onInput="(e) => getFormData(e, 'valuation_firm', i)"
									:value="property.valuation_firm"
									width="100%"
								/>
							</div>
							<div class="p-col-3">
								<p class="label">Valuation amount</p>
								<NumberField
									@onInput="
										(e) => getFormData(e, 'valuation_amount', i)
									"
									type="number"
									:value="property.valuation_amount"
									width="100%"
									mode="decimal"
									:format="true"
									prefix="$"
								/>
							</div>
							<div class="p-col-3">
								<p class="label">Property sold</p>
								<Dropdown
									:items="propertySoldTypes"
									:value="property.property_sold"
									optionValue="value"
									optionLabel="title"
									width="100%"
									@change="(e) => getFormData(e, 'property_sold', i)"
									:inValid="
										v$.properties.$silentErrors[0]?.$response.$data[i]
											.property_sold.$invalid && saveBtnClicked
									"
								/>
							</div>
						</div>

						<!-- Row-3 -->

						<div :key="addressKey" class="p-grid align-items-center mr-b-5px">
							<div class="p-col-3">
								<p class="label">City</p>
								<TextField
									@onInput="(e) => getFormData(e, 'city', i)"
									:value="property.city"
									:disable="isCityDisabled"
									width="100%"
									:inValid="
										v$.properties.$silentErrors[0]?.$response.$data[i]
											.city.$invalid && saveBtnClicked
									"
								/>
							</div>

							<div class="p-col-3">
								<p class="label">State</p>
								<Dropdown
									:value="property.state"
									:items="states"
									:key="stateKey"
									width="100%"
									:disable="isStateDisabled"
									@change="(e) => getFormData(e, 'state', i)"
									:inValid="
										v$.properties.$silentErrors[0]?.$response.$data[i]
											.state.$invalid && saveBtnClicked
									"
								/>
							</div>

							<div class="p-col-3">
								<p class="label">Postcode</p>
								<NumberField
									:value="property.postal_code"
									type="number"
									width="100%"
									:key="postalKey"
									:disable="isPostalCodeDisabled"
									@onInput="(e) => getFormData(e, 'postal_code', i)"
									:inValid="
										v$.properties.$silentErrors[0]?.$response.$data[i]
											.postal_code.$invalid && saveBtnClicked
									"
								/>
							</div>

							<div class="p-col-3">
								<p class="label">Property Type</p>
								<Dropdown
									:items="propertyTypes"
									:value="property.type_used"
									optionValue="value"
									optionLabel="title"
									:disable="property.type_used_value != 'cos_value'"
									width="100%"
									@change="(e) => getFormData(e, 'type_used', i)"
									:inValid="
										v$.properties.$silentErrors[0]?.$response.$data[i]
											.type_used.$invalid && saveBtnClicked
									"
									:id="`outgoing_property_type_${i+1||0}`"
								/>
							</div>
						</div>

						<!--   <div class="p-grid align-items-center">
              <div class="p-col-3 disable-padding-t">
                <p class="label">Finance clause date</p>
                <DatePicker2 placeholder="Enter date" width="100%" />
              </div>
              <div class="p-col-3 disable-padding-t">
                <p class="label">Valuation status</p>
                <Dropdown
                  :items="statusOpt"
                  placeholder="Please select"
                  width="100%"
                />
              </div>
              <div class="p-col-3 disable-padding-t">
                <p class="label">Valuation date</p>
                <DatePicker2 placeholder="Enter date" width="100%" />
              </div>
              <div class="p-col-3 disable-input disable-padding-t">
                <p class="label">Folio #</p>
                <TextField :disable="true" placeholder="label" width="100%" />
              </div>
            </div> -->
					</div>

					<!-- Col-2 -->

					<div class="p-col-2">
						<div class="mr-b-5px">
							<p class="label">
								<span v-if="property.property_sold != 'sold'"
									>Estimated Settlement Date</span
								><span v-else>Settlement Date</span>
							</p>
							<SummaryDatePicker
								:value="property.settlement_date"
								placeholder="Enter date"
								width="100%"
								@change="(e) => getFormData(e, 'settlement_date', i)"
								:minDate="today"
								:yearRange="'2022:2050'"
								:disable="property.property_sold != 'sold'"
								:inValid="
									v$.properties.$silentErrors[0]?.$response.$data[i]
										.settlement_date.$invalid && saveBtnClicked
								"
							/>
						</div>

						<div class="mr-b-5px">
							<p class="label">Held in Trust</p>
							<Dropdown
								:items="trustTypes"
								:value="property.deposit_held_in_trust"
								optionLabel="title"
								optionValue="value"
								width="100%"
								:inValid="
									v$.properties.$silentErrors[0]?.$response.$data[i]
										.deposit_held_in_trust.$invalid && saveBtnClicked
								"
								@change="
									(e) => getFormData(e, 'deposit_held_in_trust', i)
								"
							/>
						</div>

						<div :key="categoryKey" class="mr-b-5px mt-2">
							<p class="label">Zone</p>
							<TextField
								:key="addressKey"
								:value="property.zone"
								width="100%"
								:disable="true"
							/>
						</div>

						<div :key="categoryKey" class="mr-b-5px mt-3">
							<p class="label">Category</p>
							<TextField
								:key="addressKey"
								:value="property.category"
								width="100%"
								:disable="true"
							/>
						</div>

						<!-- <div class="mr-b-5px disable-input">
              <p class="label">Mortgage #</p>
              <TextField :disalbe="true" placeholder="label" width="100%" />
            </div> -->
					</div>

					<!-- Col-3 -->

					<div class="p-col-2 highlight-fields">
						<div class="mr-b-5px">
							<p class="label">
								<span v-if="property.property_sold != 'sold'"
									>Estimated Sale Price</span
								><span v-else>Sale Price</span>
							</p>
							<!-- :value="
                  property.property_sold != 'sold'
                    ? property.purchase_price_used
                    : property.sale_price
                " -->
							<NumberField
								type="number"
								:value="property.purchase_price_used"
								width="100%"
								@onInput="(e) => getFormData(e, 'purchase_price_used', i)"
								mode="decimal"
								:format="true"
								:disable="
									property.purchase_price_used_value != 'cos_value' ||
									property.property_sold != 'sold'
								"
								prefix="$"
							/>
						</div>

						<div class="mr-b-5px">
							<p class="label"></p>
							<p class="label">
								<span v-if="property.property_sold != 'sold'"
									>Estimated Deposit Amount</span
								><span v-else>Deposit Amount</span>
							</p>
							<NumberField
								:value="property.deposit_amount"
								type="number"
								width="100%"
								@onInput="(e) => getFormData(e, 'deposit_amount', i)"
								mode="decimal"
								:format="true"
								:disable="property.property_sold != 'sold'"
								prefix="$"
								:inValid="
									v$.properties.$silentErrors[0]?.$response.$data[i]
										.deposit_amount.$invalid && saveBtnClicked
								"
							/>
						</div>

						<div class="mr-b-5px mt-2">
							<p class="label">Mortgage</p>
							<NumberField
								:value="property.outstanding_loan_amount"
								@onInput="
									(e) => getFormData(e, 'outstanding_loan_amount', i)
								"
								placeholder="label"
								width="100%"
								mode="decimal"
								:format="true"
								prefix="$"
								:inValid="
									v$.properties.$silentErrors[0]?.$response.$data[i]
										.outstanding_loan_amount.$invalid &&
									saveBtnClicked
								"
							/>
						</div>

						<div class="mr-b-5px mt-3">
							<p class="label">Estimated property price</p>
							<NumberField
								:value="property.purchase_price_used"
								@onInput="(e) => getFormData(e, 'purchase_price_used', i)"
								placeholder="label"
								width="100%"
								mode="decimal"
								:format="true"
								prefix="$"
								:disable="
									property.property_sold == 'sold' ||
									property.purchase_price_used_value != 'cos_value'
								"
							/>
						</div>

						<!-- <div class="mr-b-5px disable-input">
              <p class="label">LVR</p>
              <TextField :disable="true" placeholder="label" width="100%" />
            </div> -->
					</div>
				</div>
			</div>

			<div class="flex align-items-center">
				<div class="p-col-2">
					<Button
						v-if="properties.length > 1"
						@click="removeProperty(i)"
						label="Remove Property"
						type="outline"
					/>
				</div>
				<div class="p-col-3">
					<p class="label">Folio#</p>
					<TextField
						:value="property.folio ? property.folio : ''"
						width="100%"
						:disable="true"
					/>
				</div>
				<div class="p-col-3">
					<p class="label">Mortgage registration#</p>
					<NumberField type="number" width="100%" :disable="true" />
				</div>
				<div class="p-col-2 mb-2">
					<p class="label">LVR%</p>
					<TextField
						:value="
							calculatedFields.initial_lvr
								? (calculatedFields.initial_lvr * 100).toFixed(3)
								: ''
						"
						type="number"
						width="100%"
						:disable="true"
					/>
				</div>
				<div class="p-col-2">
					<Button
						@click="fullValRequest(i)"
						label="Full Val Request"
						type="outline"
					/>
				</div>
			</div>
			<div>
				<div class="mr-b-5px col col-2">
					<p class="label">Residence type</p>
					<Dropdown
						:items="['House', 'Others']"
						:value="property.pricefinder_type"
						width="100%"
						@change="(e) => getFormData(e, 'pricefinder_type', i)"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AddressAutocompleteKleber from "@/components/common/AddressAutocompleteKleber";
import moment from "moment";
import { STATES, APP_URL } from "../../../../../../../../constants";
import { mapGetters, mapActions } from "vuex";
import { _post } from "../../../../../../../services/crudService";
import { required, minLength, helpers, maxLength, minValue } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
	setup: () => ({ v$: useVuelidate() }),
	components: { AddressAutocompleteKleber },
	props: ["refreshComponent"],
	validations() {
		return {
			properties: {
				required,
				// minLength: minLength(1),
				$each: helpers.forEach({
					outstanding_loan_amount: { required },
					deposit_held_in_trust: { required },
					purchase_price_used: { required },
					deposit_amount: { required },
					add_1: { required },
					city: { required },
					state: { required },
					postal_code: {
						required,
						minLength: minLength(3),
						maxLength: maxLength(4),
						minValue: minValue(0),
					},
					type_used: { required },
				}),
			},
		};
	},
	data() {
		return {
			today: new Date(),
			isAddressLoading: false,
			states: STATES,
			properties: [],
			isPostalCodeDisabled: true,
			isCityDisabled: true,
			isStateDisabled: true,
			addressKey: 0,
			stateKey: 10,
			refreshKey: 30,
			postalKey: 20,
			categoryKey: 40,
			trustTypes: [
				{
					title: "Yes",
					value: true,
				},
				{
					title: "No",
					value: false,
				},
			],
			propertySoldTypes: [
				{
					title: "Yes",
					value: "sold",
				},
				{
					title: "Listed for sale",
					value: "listed_for_sale",
				},
				{
					title: "No",
					value: "no",
				},
			],
			propertyTypes: [
				{
					title: "Residential",
					value: "residential",
				},
				{
					title: "Rural Residential",
					value: "rural_residential",
				},
				{
					title: "Off the plan residential",
					value: "off_plan_residential",
				},
				{
					title: "Commercial",
					value: "commercial",
				},
				{
					title: "Vacant Land",
					value: "vacant_land",
				},
			],
			statusOpt: [
				"AVM complete",
				"Full Val Ordered",
				"Full Val Complete",
				"No Va",
				"AVM Failed",
			],
			valuationStatus: [
				"AVM complete",
				"Full Val Ordered",
				"Full Val Complete",
				"No Val",
				"AVM Failed ",
			],
			calculatedFields: {},
		};
	},
	computed: {
		...mapGetters({
			selectedApplication: "applications/selectedApplication",
			sellingProperty: "applications/sellingProperty",
			saveBtnClicked: "applications/saveBtnClicked",
			sellingPropertyUpdated: "applications/sellingPropertyUpdated",
		}),
	},
	methods: {
		...mapActions({
			setSellingProperty: "applications/setSellingProperty",
			setValidation: "applications/setValidation",
			fetchZoneByPostal: "applications/fetchZoneByPostal",
		}),
		async onAddressChange(address, i) {
			this.isAddressLoading = true;
			this.properties[i].add_1 = address.address_1 ? address.address_1 : "";
			this.properties[i].add_2 = address.address_2 ? address.address_2 : "";
			this.properties[i].unitNumber = address.unitNumber ? address.unitNumber : "";
			this.properties[i].streetNumber = address.streetNumber
				? address.streetNumber
				: "";
			this.properties[i].streetType = address.streetType ? address.streetType : "";
			this.properties[i].streetName = address.streetName ? address.streetName : "";
			// this.formData.city = address.address_1 ? address.city : "";
			// this.formData.state = address.state ? address.state : "";
			// let address_2 = "";
			// address_2 = address.city
			//   ? address_2 + address.city + ", "
			//   : address_2 + "";
			// address_2 = address.state
			//   ? address_2 + address.state.toUpperCase() + ", "
			//   : address_2 + "";
			// address_2 = address.postal_code
			//   ? address_2 + address.postal_code
			//   : address_2 + "";
			// this.properties[i].add_2 = address_2;
			this.properties[i].country = address.country ? address.country : "";
			if (address.postal_code) {
				this.properties[i].postal_code = Number(address.postal_code);
				this.isPostalCodeDisabled = true;
				this.postalKey += 1;
			} else {
				this.properties[i].postal_code = "";
				this.isPostalCodeDisabled = false;
				this.postalKey += 1;
			}
			if (address.city) {
				this.properties[i].city = address.city;
				this.isCityDisabled = true;
			} else {
				this.properties[i].city = "";
				this.isCityDisabled = false;
			}
			if (address.state) {
				const isState = this.states.find((obj) => obj == address.state);
				if (isState) {
					this.properties[i].state = address.state;
					this.isStateDisabled = true;
				} else {
					this.properties[i].state = "";
					this.isStateDisabled = false;
				}
			} else {
				this.properties[i].state = "";
				this.isStateDisabled = false;
				this.stateKey += 1;
			}
			try {
				const res = await this.fetchZoneByPostal(
					address.postal_code ? address.postal_code : ""
				);
				this.properties[i].zone = res.zone ? res.zone : "";
				this.properties[i].premium = res.premium;
				this.properties[i].category = res.category;
				this.properties[i].high_density_area = res.high_density_area;
				this.categoryKey += 1;
			} catch (err) {
				this.properties[i].zone = "";
				this.properties[i].premium = false;
				this.properties[i].category = "";
				this.properties[i].high_density_area = false;
				this.isAddressLoading = false;
				this.categoryKey += 1;
			}
			this.addressKey += 1;
			this.isAddressLoading = false;
		},
		formatDate(date) {
			return moment(date).format("DD/MM/YYYY");
		},
		async getFormData(value, field, property) {
			this.properties[property][field] = value;
			this.properties = [...this.properties];
			// if (field == "property_sold" && value == "sold") {
			//   this.properties[property].settlement_date = "";
			//   this.properties[property].deposit_amount = 0;
			//   this.properties[property].sale_price = 0;
			//   this.properties[property].purchase_price_used = 0;
			//   this.properties[property].purchase_price_cos = 0;
			// }
			// if (field == "property_sold" && value != "sold") {
			//   this.properties[property].estimated_price = 0;
			//   this.properties[property].purchase_price_used = 0;
			//   this.properties[property].purchase_price_cos = 0;
			// }
			if (field == "purchase_price_used") {
				this.properties[property].purchase_price_cos = value;
			}
			if (field == "type_used") {
				this.properties[property].type_cos = value;
				this.properties[property].type = value ? value : "";
			}
			if (field === "postal_code" && value && value >= 1000) {
				try {
					const res = await this.fetchZoneByPostal(value);
					this.properties[property].zone = res.zone ? res.zone : "";
					this.properties[property].premium = res.premium;
					this.properties[property].category = res.category;
					this.properties[property].high_density_area = res.high_density_area;
					this.categoryKey += 1;
				} catch (err) {
					this.properties[property].zone = "";
					this.properties[property].premium = false;
					this.properties[property].high_density_area = false;
					this.properties[property].category = "";
					this.categoryKey += 1;
				}
			}
		},
		addProperty() {
			let newObj = {
				add_1: "",
				add_2: "",
				city: "",
				postal_code: "",
				type: "",
				type_used: "",
				settlement_date: "",
				deposit_held_in_trust: false,
				estimated_price: 0,
				deposit_amount: 0,
				outstanding_loan_amount: 0,
				zone: "",
				premium: false,
				high_density_area: false,
				category: "",
				state: "",
				country: "",
				mortage_against: false,
				sale_price: 0,
				property_sold: "sold",
				purchase_price_avm: 0,
				purchase_price_fv: 0,
				purchase_price_cos: 0,
				purchase_price_used_value: "cos_value",
				purchase_price_used: 0,
				type_avm: "",
				type_fv: "",
				type_cos: "",
				type_used_value: "cos_value",
				unitNumber: "",
				streetNumber: "",
				streetType: "",
				streetName: "",
				pricefinder_type: "Others",
			};
			this.properties = [...this.properties, newObj];
		},
		removeProperty(i) {
			this.properties.splice(i, 1);
			this.refreshKey += 1;
		},
		async fullValRequest(index) {
			try {
				await _post(APP_URL + "valuation-request-email", {
					...this.properties[index],
					application_id: this.$route.params.id,
					method: "outgoing",
				});
				this.$toast.add({
					severity: "success",
					summary: "Success",
					detail: "Requested successfully!",
					life: 3000,
				});
			} catch (err) {
				this.$toast.add({
					severity: "error",
					summary: "Error",
					detail: "Something went wrong",
					life: 3000,
				});
			}
		},
		isSubmitDisabled() {
			return this.v$.$invalid;
		},
		setData() {
			if (this.selectedApplication.application_id) {
				this.properties = this.selectedApplication.outgoing_properties
					? this.selectedApplication.outgoing_properties
					: [];
				this.properties = this.properties.map((item) => {
					item.purchase_price_avm = item.purchase_price_avm
						? Number(item.purchase_price_avm)
						: 0;
					return item;
				});
				const { calculated_fields } = this.selectedApplication;
				this.calculatedFields = calculated_fields;
				this.calculatedFields.initial_lvr = this.calculatedFields.initial_lvr
					? Number(this.calculatedFields.initial_lvr)
					: 0;
			}
		},
	},
	created() {
		this.setData();
	},
	watch: {
		refreshComponent() {
			this.setSellingProperty(this.properties);
			this.setValidation({ type: "selling", value: this.isSubmitDisabled() });
		},
		properties() {
			this.setSellingProperty(this.properties);
			this.setValidation({ type: "selling", value: this.isSubmitDisabled() });
		},
		sellingPropertyUpdated: {
			handler() {
				this.properties = this.sellingProperty;
				this.refreshKey += 1;
			},
			deep: true,
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.mr-b-5px {
	margin-bottom: 5px;
}

.v-none {
	visibility: hidden;
}

.disable-padding-t {
	padding-top: 0 !important;
}

.mr-top {
	margin-top: 2.5rem;
}

.mr-top-10px {
	margin-top: 10px;
}

.flex-gap {
	gap: 0.625rem;
}

.highlight-fields {
	.label {
		font-family: Gordita-bold !important;
	}
}

.label {
	color: $secondary-color;
	font-size: 0.875rem;
	font-family: Gordita-medium;
	margin: 0;
}

.sub-title {
	font-size: 1.25rem;
	color: $secondary-color;
	font-family: Gordita-bold;
}

.heading {
	border-bottom: 1px solid rgba(2, 0, 56, 0.15);
	padding-bottom: 13px;
}

::v-deep .p-col-12,
.p-col-8,
.p-col-2 {
	padding-bottom: 0;
}
</style>
