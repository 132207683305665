<template>
  <div>
    <div class="title p-d-flex justify-content-between">
      <p class="title-text">Outgoing Properties</p>
      <div class="count flex flex-gap-20 align-items-center">
        <img
          @click="decrement"
          v-if="!disableMinus"
          class="pointer"
          src="@/assets/minus.svg"
        />
        <img
          v-if="disableMinus"
          class="pointer"
          src="@/assets/minus-inactive.svg"
        />
        <p>{{ outgoingPropertiesCount }}</p>
        <img
          @click="increment"
          v-if="!disableAdd"
          class="pointer"
          src="@/assets/plus.svg"
        />
        <img
          v-if="disableAdd"
          class="pointer"
          src="@/assets/plus-inactive.svg"
        />
      </div>
    </div>
    <div class="form-inputs p-grid mt-2">
      <div class="col">
        <FormInputs
          id="1"
          :responseValue="responseValue"
          @validateOutgoingProp="validateOutgoing1"
          :fieldValues="value[0]"
          :properties="outgoingPropertiesCount"
        />
      </div>
      <div v-if="outgoingPropertiesCount > 1" class="col">
        <FormInputs
          @validateOutgoingProp="validateOutgoing2"
          id="2"
          :responseValue="responseValue"
          :fieldValues="value[1]"
          :properties="outgoingPropertiesCount"
        />
      </div>
      <div v-if="outgoingPropertiesCount == 3" class="col">
        <FormInputs
          @validateOutgoingProp="validateOutgoing3"
          id="3"
          :responseValue="responseValue"
          :fieldValues="value[2]"
          :properties="outgoingPropertiesCount"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FormInputs from "./FormInputs.vue";

export default {
  props: ["responseValue"],
  components: { FormInputs },
  data() {
    return {
      disableAdd: false,
      disableMinus: false,
      value: [],
      validateProp1: true,
      validateProp2: true,
      validateProp3: true,
    };
  },
  watch: {
    outgoingPropertiesCount(value) {
      if (value >= 3 && !this.disableAdd) {
        this.disableAdd = true;
        this.disableMinus = false;
      } else {
        this.disableAdd = false;
      }

      if (value <= 1 && !this.disableMinus) {
        this.disableMinus = true;
        this.disableAdd = false;
      } else {
        this.disableMinus = false;
      }
    },
    outgoingPropertiesValue() {
      this.value = this.outgoingPropertiesValue;
    },
  },
  computed: {
    ...mapGetters({
      outgoingPropertiesValue: "servicingCalc/outgoingProperties",
      outgoingPropertiesCount: "servicingCalc/outgoingPropertiesCount",
    }),
  },
  methods: {
    ...mapActions({
      changeOutgoingPropertyCount: "servicingCalc/changeOutgoingPropertyCount",
    }),
    validateOutgoing1(value) {
      this.$emit("validateOutgoing", value);
      this.validateProp1 = value;
    },
    validateOutgoing2(value) {
      this.$emit("validateOutgoing", value);
      this.validateProp2 = value;
    },
    validateOutgoing3(value) {
      this.$emit("validateOutgoing", value);
      this.validateProp3 = value;
    },
    increment() {
      this.changeOutgoingPropertyCount(+1);
      if (this.outgoingPropertiesCount === 2) {
        return this.$emit("validateOutgoing", this.validateProp2);
      }

      if (this.outgoingPropertiesCount === 3) {
        if (this.validateProp2 && this.validateProp3) {
          this.$emit("validateOutgoing", false);
        } else {
          this.$emit("validateOutgoing", true);
        }
      }
    },
    decrement() {
      this.changeOutgoingPropertyCount(-1);
      if (this.outgoingPropertiesCount === 2) {
        return this.$emit("validateOutgoing", this.validateProp2);
      }

      if (this.outgoingPropertiesCount === 3) {
        if (this.validateProp2 && this.validateProp3) {
          this.$emit("validateOutgoing", false);
        } else {
          this.$emit("validateOutgoing", true);
        }
      }
      this.$emit("validateOutgoing", this.validateProp1);
    },
  },
  created() {
    this.value = this.outgoingPropertiesValue;
    if (this.outgoingPropertiesCount >= 3 && !this.disableAdd) {
      this.disableAdd = true;
      this.disableMinus = false;
    } else {
      this.disableAdd = false;
    }

    if (this.outgoingPropertiesCount <= 1 && !this.disableMinus) {
      this.disableMinus = true;
      this.disableAdd = false;
    } else {
      this.disableMinus = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.title {
  border-bottom: 1px solid rgba(2, 0, 56, 0.15);
  padding-bottom: 10px;

  .title-text {
    font-family: Gordita-bold;
    color: $secondary-color;
    font-size: 20px;
  }

  .count {
    user-select: none;

    p {
      font-size: 18px;
      font-family: Gordita-medium-;
      color: $secondary-color;
    }
  }
}

.pointer {
  cursor: pointer;
}

.flex-gap-20 {
  gap: 20px;
}

p {
  margin: 0;
}
</style>
