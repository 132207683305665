<template>
  <div>
    <div class="content">
      <div class="form-inputs mt-4">
        <div class="p-grid align-items-center header">
          <p class="col"></p>
          <p class="col label">Application Values</p>
          <p class="col label">Current Values</p>
          <p class="col label">Current Value Source</p>
          <div class="col"></div>
        </div>
        <InputRow
          label="Groceries"
          :value1="originalData.groceries"
          :value2="latestData.groceries"
        />
        <InputRow
          label="Clothing"
          :value1="originalData.clothing"
          :value2="latestData.clothing"
        />
        <InputRow
          label="Education"
          :value1="originalData.education"
          :value2="latestData.education"
        />
        <InputRow
          label="Insurance"
          :value1="originalData.insurance_premium"
          :value2="latestData.insurance_premium"
        />
        <InputRow
          label="Child care"
          :value1="originalData.child_care"
          :value2="latestData.child_care"
        />
        <InputRow
          label="Medical"
          :value1="originalData.medical_health"
          :value2="latestData.medical_health"
        />
        <InputRow
          label="Entertainment"
          :value1="originalData.recreation"
          :value2="latestData.recreation"
        />
        <InputRow
          label="Communication"
          :value1="originalData.communication"
          :value2="latestData.communication"
        />
        <InputRow
          label="Home property costs"
          :value1="originalData.home_property_cost"
          :value2="latestData.home_property_cost"
        />
        <InputRow
          label="Investment property costs"
          :value1="originalData.investment_prop_cost"
          :value2="latestData.investment_prop_cost"
        />
        <InputRow
          label="Transport"
          :value1="originalData.transport"
          :value2="latestData.transport"
        />
        <InputRow
          label="Other"
          :value1="originalData.others"
          :value2="latestData.others"
        />
        <InputRow
          label="Dependants"
          :value1="originalData.dependants"
          :value2="latestData.dependants"
        />
        <InputRow
          label="Monthly expenses"
          :value1="originalExpenses"
          :value2="latestExpenses"
        />
        <InputRow
          label="HEM value"
          :value1="
            original.calculated_fields && original.calculated_fields.hem_value
              ? original.calculated_fields.hem_value
              : 0
          "
          :value2="
            latest.calculated_fields && latest.calculated_fields.hem_value
              ? latest.calculated_fields.hem_value
              : 0
          "
        />
        <InputRow
          label="Expenses value used"
          :value1="expensesUsedValueOriginal"
          :value2="expensesUsedValueLatest"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputRow from "../../InputRow.vue";
import { mapGetters } from "vuex";
export default {
  components: { InputRow },
  props: ["latestData", "originalData"],
  data() {
    return {
      originalExpenses: 0,
      latestExpenses: 0,
      expensesUsedValueLatest: 0,
      expensesUsedValueOriginal: 0,
    };
  },
  created() {
    // Find total original expenses
    this.originalExpenses =
      Number(this.originalData.groceries) +
      Number(this.originalData.clothing) +
      Number(this.originalData.education) +
      Number(this.originalData.insurance_premium) +
      Number(this.originalData.child_care) +
      Number(this.originalData.medical_health) +
      Number(this.originalData.recreation) +
      Number(this.originalData.communication) +
      Number(this.originalData.home_property_cost) +
      Number(this.originalData.investment_prop_cost) +
      Number(this.originalData.transport) +
      Number(this.originalData.others) +
      Number(this.originalData.dependants);

    this.expensesUsedValueOriginal =
      this.originalExpenses > this.original?.calculated_fields?.hem_value
        ? this.originalExpenses
        : this.original.calculated_fields.hem_value;
    // Find total latest expenses
    this.latestExpenses =
      Number(this.latestData.groceries) +
      Number(this.latestData.clothing) +
      Number(this.latestData.education) +
      Number(this.latestData.insurance_premium) +
      Number(this.latestData.child_care) +
      Number(this.latestData.medical_health) +
      Number(this.latestData.recreation) +
      Number(this.latestData.communication) +
      Number(this.latestData.home_property_cost) +
      Number(this.latestData.investment_prop_cost) +
      Number(this.latestData.transport) +
      Number(this.latestData.others) +
      Number(this.latestData.dependants);
    this.expensesUsedValueLatest =
      this.latestExpenses > this.latest?.calculated_fields?.hem_value
        ? this.latestExpenses
        : this.latest.calculated_fields.hem_value;
  },
  computed: {
    ...mapGetters({
      original: "loans/originalData",
      latest: "loans/latestData",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
</style>
