<template>
  <div class="mr-top card-box">
    <Panel :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>
      <template #header>
        <div @click="toggle" class="sub-title full-width pointer">
          Live Loan Schedule
        </div>
      </template>

      <!-- <div
        @click="openTransactionModal"
        class="
          flex
          align-items-end
          justify-content-end
          mt-3
          pointer
          hk-sb
          lt-txt-color
        "
      >
        <span class="mr-2">+</span> Discharge loan
      </div> -->

      <div class="form-inputs">
        <div class="flex align-items-center">
          <div class="col-8 flex align-items-center">
            <div class="col disable-input">
              <p class="label">Funded Date</p>
              <TextField
                :value="
                  formData?.funded_date && formatDate(formData?.funded_date)
                "
                :disable="true"
                placeholder="DD/MM/YYYY"
                width="100%"
              />
            </div>
            <div class="col">
              <p class="label">Discharge Date</p>
              <TextField
                :value="
                  formData.discharge_date && formatDate(formData.discharge_date)
                "
                placeholder="DD/MM/YYYY"
                width="100%"
              />
            </div>
            <div class="col">
              <p class="label">Capitalised Interest</p>
              <TextField
                :value="formData.caps_interest || 0"
                placeholder="Capitalised Interest"
                width="100%"
              />
            </div>
          </div>

          <div class="col-4 disable-input">
            <p class="label">Outstanding balance</p>
            <TextField
              :value="
                formData?.outstanding_balance
                  ? formatAmount(formData?.outstanding_balance)
                  : 0
              "
              :disable="true"
              placeholder="Outstanding balance"
              width="100%"
            />
          </div>
        </div>

        <div class="flex align-items-center">
          <div class="col-8 flex align-items-center">
            <div class="col disable-input">
              <p class="label">Special Attendance Fee</p>
              <TextField
                :value="formData.spl_attendance_fee || 0"
                :disable="true"
                placeholder="Special Attendance Fee"
                width="100%"
              />
            </div>
            <div class="col">
              <p class="label">Legal Discharge Fee</p>
              <TextField
                :value="formData.legal_fees || 0"
                placeholder="Legal Discharge Fee<"
                width="100%"
              />
            </div>
            <div class="col">
              <p class="label">Discharge Type</p>
              <Dropdown
                :value="formData.discharge_type || 0"
                :items="transactionType"
                placeholder="-select-"
                width="100%"
                optionLabel="name"
                optionValue="value"
                @change="payout"
              />
            </div>
          </div>

          <div class="col-4" :class="payoutDisable ? 'disable-input' : null">
            <p class="label">Payout Figure</p>
            <TextField
              :value="
                formData.payout_figure
                  ? formatAmount(formData.payout_figure)
                  : 0
              "
              :disable="payoutDisable"
              placeholder="Payout Figure"
              width="100%"
              :key="payoutKey"
            />
          </div>
        </div>

        <div class="flex align-items-center justify-content-end">
          <div class="col-4 disable-input">
            <p class="label">Amount Remaining</p>
            <TextField
              :value="
                formData?.amount_remaining
                  ? formatAmount(formData?.amount_remaining)
                  : 0
              "
              :disable="true"
              placeholder="Amount Remaining"
              width="100%"
              :key="amountKey"
            />
          </div>
        </div>
      </div>

      <Spinner v-if="isloading" class="flex justify-content-between mt-3" />

      <DataTable
        v-else
        class="mt-2"
        :rows="5"
        data-key="id"
        :value="statements"
      >
        <template #empty>No data found.</template>

        <Column field="period" header="Period"></Column>
        <Column field="from" header="From">
          <template #body="slotProps">
            {{
              slotProps.data.from_date
                ? formatDate(slotProps.data.from_date)
                : "-"
            }}
          </template>
        </Column>
        <Column field="to" header="To">
          <template #body="slotProps">
            {{
              slotProps.data.to_date ? formatDate(slotProps.data.to_date) : "-"
            }}
          </template>
        </Column>
        <Column field="opening_balance" header="Opening Balance">
          <template #body="slotProps">
            {{
              slotProps.data.opening_balance
                ? formatAmount(slotProps.data.opening_balance)
                : "-"
            }}
          </template>
        </Column>
        <Column field="loan_advance" header="Loan Advance">
          <template #body="slotProps">
            {{
              slotProps.data.loan_advance
                ? formatAmount(slotProps.data.loan_advance)
                : "-"
            }}
          </template>
        </Column>
        <Column field="interest_cap" header="Interest Capitalised">
          <template #body="slotProps">
            {{ roundUpValue(slotProps.data.interest_cap) }}
          </template>
        </Column>
        <Column field="loan_repayment" header="Loan Repayment">
          <template #body="slotProps">
            {{
              slotProps.data.loan_repayment
                ? formatAmount(slotProps.data.loan_repayment)
                : "-"
            }}
          </template>
        </Column>
        <Column field="closing_bal" header="Closing Balance">
          <template #body="slotProps">
            {{
              slotProps.data.closing_bal
                ? formatAmount(slotProps.data.closing_bal)
                : "-"
            }}
          </template>
        </Column>
      </DataTable>
    </Panel>

    <Transaction
      v-if="showTransactionModal"
      @save="refreshKey++"
      transaction_type="in"
      title="Discharge Loan"
      :show="showTransactionModal"
      @close="showTransactionModal = false"
    />
  </div>
</template>

<script>
import Panel from "primevue/panel";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Transaction from "./Transaction.vue";
import moment from "moment";
import { APP_URL } from "../../../../../../constants";
import { _get } from "../../../../../services/crudService";

export default {
  components: { Panel, DataTable, Column, Transaction },
  props: ["key"],
  data() {
    return {
      payoutDisable: false,
      collapsed: false,
      showTransactionModal: false,
      transactionType: [
        // { name: "Deposit", value: "deposit" },
        // { name: "Withdrawal fee", value: "withdrawal_fee" },
        // { name: 'Valuation fee"', value: "valuation_fee" },
        { name: "Partial discharge", value: "partial_discharge" },
        { name: "Full discharge", value: "full_discharge" },
      ],
      formData: {
        funded_date: "",
        discharge_date: "",
        caps_interest: 0,
        outstanding_balance: 0,
        spl_attendance_fee: 0,
        legal_fees: 0,
        discharge_type: "partial_discharge",
        payout_figure: 0,
        amount_remaining: 0,
      },
      statements: [],
      isloading: false,
      amountKey: 0,
      payoutKey: 10,
    };
  },
  watch: {
    key() {
      this.getList();
    },
  },
  methods: {
    payout(value) {
      if (value === "partial_discharge") {
        this.payoutDisable = false;
        this.formData.amount_remaining = 0;
        // this.formData.amount_remaining = Number(this.formData.outstanding_balance) - Number(this.formData.payout_figure) - Number(this.formData.legal_fees) - Number(this.formData.spl_attendance_fee) - Number(this.formData.caps_interest)

        this.formData.amount_remaining =
          Number(this.formData.outstanding_balance) -
          Number(this.formData.payout_figure) -
          Number(this.formData.legal_fees) -
          Number(this.formData.spl_attendance_fee) -
          Number(this.formData.caps_interest);
        //  this.formData.amount_remaining = 800
      } else {
        this.payoutDisable = true;
        this.formData.payout_figure =
          this.formData.outstanding_balance +
          this.formData.legal_fees +
          this.formData.spl_attendance_fee +
          this.formData.caps_interest;
        this.formData.amount_remaining =
          this.formData.outstanding_balance -
          this.formData.payout_figure -
          this.formData.legal_fees -
          this.formData.spl_attendance_fee -
          this.formData.caps_interest;
      }
      this.amountKey += 1;
      this.payoutKey += 1;
    },
    roundUpValue(value) {
      if (value) {
        return value.toFixed(2);
      } else {
        return "-";
      }
    },
    toggle() {
      this.collapsed = !this.collapsed;
    },
    openTransactionModal() {
      return (this.showTransactionModal = true);
    },
    formatAmount(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      return formatter.format(value);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async getList() {
      try {
        this.isloading = true;

        const response = await _get(
          APP_URL + `live-loan?app_id=${this.$route.params.id}`
        );

        if (response) {
          this.isloading = false;
          this.statements = response.data.error ? [] : response.data.result;

          // response.data.map((list, idx) => {
          //   this.statements.push({ list_no: idx + 1, ...list })
          // })

        }
      } catch (error) {
        this.isloading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    async getLiveLoan() {
      try {
        this.isloading = true;

        const response = await _get(
          APP_URL + `live-loan-schedule?application_id=${this.$route.params.id}`
        );

        if (response) {
          this.formData = response.data;
          this.isloading = false;

          // response.data.map((list, idx) => {
          //   this.statements.push({ list_no: idx + 1, ...list })
          // })
        }
      } catch (error) {
        this.isloading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },
  async created() {
    await this.getList();
    await this.getLiveLoan();
    this.formData.discharge_date = "";
    this.payout("partial_discharge");
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid #02003826 !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
  padding: 1rem 1rem 0.5rem 1rem !important;
}

::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  //   border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
.color-highlight {
  color: $primary-color !important;
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}

:deep .col-8 {
  padding-left: 0 !important;
}

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

.form-inputs {
  margin-top: 20px;
  background: rgba(2, 0, 56, 0.03);
  padding: 30px;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium-;
  margin: 0;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.card-box {
  background: #ffffff;
  padding: 20px;
  border: 1px solid rgba(2, 0, 56, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
}

.mr-top {
  margin-top: 2.5rem;
}
</style>
