<template>
  <div class="pl-4 pr-4 pb-4">
    <Card v-if="!showCustomerDetails" class="box-shadow">
      <template #content>
        <div class="flex justify-content-between">
          <img src="@/assets/applications.svg" />
          <div class="flex">
            <Datefilter
              class="mr-2 full-width datefilter-component"
              numberOfMonths="2"
              @change="getFilteredData($event)"
            />
            <!-- <FilterComponent class="mr-2 full-width" />             -->
            <span class="p-input-icon-left ml-2 full-width">
              <i class="pi pi-search" />
              <TextField
                width="100%"
                type="text"
                v-model="value1"
                placeholder="Search"
                @input="getSearchValue($event)"
              />
            </span>
          </div>
        </div>
        <Spinner v-if="isLoading" class="flex justify-content-between mt-3" />

        <DataTable
          v-else
          class="mt-2"
          :rows="10"
          selectionMode="multiple"
          dataKey="id"
          :value="statements.result"
          :rowsPerPageOptions="[10, 20, 30]"
        >
          <template #empty> No data found. </template>

          <Column field="loan_id" header="Loan ID">
            <template #body="slotProps">
              <a
                class="color-highlight"
                @click="goToApp(slotProps.data.Id, slotProps.data.loan_id)"
              >
                {{ slotProps.data.loan_id }}
              </a>
            </template>
          </Column>
          <Column field="applicants" header="Key Contact">
            <template #body="slotProps">
              <a class="color-highlight" @click="showCustomerDetails = true">
                <!-- {{ slotProps.data.applicants[0].first_name }}
                {{ slotProps.data.applicants[0].middle_name }} -->
                {{ applicantName(slotProps.data.applicants) }}
              </a>
            </template>
          </Column>
          <Column field="request" header="Request">
            <template #body=""> - </template>
          </Column>
          <Column field="loan_term" header="Term">
            <template #body="slotProps">
              <div class="table-column">
                {{ slotProps.data.loan_term || "-" }}
              </div>
            </template>
          </Column>
          <Column field="calculated_values" header="Offer">
            <template #body="slotProps">
              <div class="table-column">
                {{
                  slotProps.data?.calculated_values
                    ? "$" +
                      formatCurrency(
                        slotProps.data?.calculated_values[0].loan_amount
                      )
                    : "-"
                }}
              </div>
            </template>
          </Column>
          <Column field="status" style="width: 10rem" header="Status">
            <template #body="slotProps">
              <div class="table-column">
                {{ slotProps.data.status || "-" }}
              </div>
            </template>
          </Column>
          <Column field="createdAt" header="Created">
            <template #body="slotProps">
              {{ formatDate(slotProps.data.createdAt) || "-" }}
            </template>
          </Column>
        </DataTable>

        <Paginator
          class="mt-2"
          :rows="10"
          :totalRecords="statements && statements.totalCount"
          :rowsPerPageOptions="[10, 20, 30]"
          template="RowsPerPageDropdown  CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} of {totalRecords} applications"
          @page="onChangePage($event)"
        ></Paginator>
      </template>
    </Card>

    <CustomerDetails v-if="showCustomerDetails" :disableHeader="true" />
  </div>
</template>

<script>
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import CustomerDetails from "../../Customer/CustomerData/CustomerDetails.vue";
import { _get } from "../../../../services/crudService";
import { _post } from "../../../../services/crudService";
import { APP_URL } from "../../../../../constants";
import moment from "moment";
import Paginator from "primevue/paginator";
import { mapGetters } from "vuex";
import Datefilter from "./DateFilter.vue";
// import FilterComponent from "./Filter.vue"
import { SERVER_URL } from "../../../../../constants";

export default {
  name: "ApplicationsList",
  components: {
    Card,
    DataTable,
    Column,
    Paginator,
    CustomerDetails,
    Datefilter,
    // FilterComponent,
  },
  data() {
    return {
      showCustomerDetails: false,
      statements: [],
      isLoading: true,
      updateSearch: null,
    };
  },
  computed: {
    ...mapGetters({
      active_application: "customers/active_application",
    }),
  },
  methods: {
    goToApp(id, loan_id) {
      //   this.$emit("change")
      this.$router.push(`/loan/${id}/overview?loanId=${loan_id}`);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatCurrency(val) {
      return val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    applicantName(value) {
      if (value) {
        let name;
        value.map((app) => {
          if (value.length === 1 || app.applicant_type === "primary") {
            if (app.middle_name) {
              name = `${app.first_name} ${app.middle_name} ${app.last_name}`;
            } else {
              name = `${app.first_name}  ${app.last_name}`;
            }
          }
        });
        return name;
      }
    },
    getSearchValue(value) {
      if (value !== "") {
        clearTimeout(this.updateSearch);

        this.updateSearch = setTimeout(async () => {
          this.isloading = true;
          try {
            const response = await _get(
              SERVER_URL + `search-data?table=application&value=${value}`
            );

            if (response) {
              this.statements = response.data;
              this.isloading = false;
            }
          } catch (err) {
            this.isloading = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Something went wrong",
              life: 3000,
            });
          }
        }, 800);
      }

      if (value === "") {
        this.getApp(1, 10);
      }
    },
    async getFilteredData(event) {
      if (event) {
        if (event[0] && event[1]) {
          this.isLoading = true;

          const dateRange = {
            status: [],
            stage: [],
            startDate: event[0],
            endDate: event[1],
          };
          try {
            const response = await _post(APP_URL + "search-data", dateRange);

            if (response) {
              this.statements = response.data;
              this.isLoading = false;
            }
          } catch (err) {
            this.isLoading = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Something went wrong",
              life: 3000,
            });
          }
        }
      } else {
        this.getApp(1, 10);
      }
    },
    onChangePage(value) {
      this.getApp(value.page + 1, value.rows);
    },
    async getApp(page, row) {
      try {
        this.isLoading = true;
        const response = await _get(
          APP_URL +
            `application_by_customer_crm?application_id=${this.active_application}&pageSize=${row}&pageIndex=${page}`
        );
        if (response) {
          this.isLoading = false;
          this.statements = response.data;
        }
      } catch (err) {
        this.isLoading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },
  async created() {
    await this.getApp(1, 10);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.p-card {
  box-shadow: unset !important;
  border-radius: 0.25rem !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
}
::v-deep .p-card-title {
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem !important;
}
::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
.color-highlight {
  color: $primary-color !important;
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}
:deep .input-field {
  padding-left: 2rem !important;
}
</style>
