<template>
  <div
    class="progress-indicator flex align-items-center justify-content-center"
    :class="type === 'increase' ? 'increase' : 'decrease'"
  >
    <img v-if="type === 'increase'" src="@/assets/arrow-up.svg" />
    <img v-else src="@/assets/arrow-down.svg" />{{ value }}%
  </div>
</template>

<script>
export default {
  name: "Indicator",
  props: ["type", "value"],
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.progress-indicator {
  width: 52px;
  height: 28px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
}
.increase {
  color: #51c78e;
  background: rgba(94, 217, 158, 0.1);
}
.decrease {
  background: rgba(254, 103, 27, 0.1);
  color: $primary-color;
}

img {
  -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 11.5.1 */
  -moz-animation: fadein 1.5s; /* Firefox < 16 */
  -ms-animation: fadein 1.5s; /* Internet Explorer */
  -o-animation: fadein 1.5s; /* Opera < 11.5.1 */
  animation: fadein 1.5s;
}
</style>
