<template>
  <div>
    <div v-for="(item, i) in details" :key="i">
      <div class="sub-title">
        <Checkbox2
          :id="item"
          :name="item.name_of_employer"
          :value="item"
          @getInputValue="(e) => selectIncome(e, item)"
          class="mb-2"
        />
        <label>
          {{ `Part time income - ${item.name_of_employer}` }}
        </label>
      </div>

      <div class="form-inputs mt-4">
        <div class="p-grid align-items-center header">
          <p class="p-col-2"></p>
          <p class="p-col-2 label">Frequency</p>
          <p class="p-col-2 label">Application Value</p>
          <p class="p-col-2 label">Verified Value</p>
          <p class="p-col-2 label">Manual Value</p>
          <p class="p-col-2 label">Used Value</p>
        </div>

        <!--Salary -->
        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Salary</p>
          <div class="p-col-2">
            <Dropdown
              :items="frequencyOpt"
              :value="item.salary_frequency"
              @change="(e) => getFormData(e, 'salary_frequency', i)"
              width="100%"
              placeholder="label"
            />
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <NumberField
              prefix="$"
              :value="item.salary_amount"
              :fullWidth="true"
              :disable="true"
              width="100%"
              placeholder="label"
            />
            <div class="orange-dot dot"></div>
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <NumberField
              prefix="$"
              :value="item.salary_amount_verified"
              :fullWidth="true"
              :disable="true"
              width="100%"
              placeholder="label"
            />
            <div class="green-dot dot"></div>
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
            "
          >
            <NumberField
              prefix="$"
              type="number"
              :value="item.salary_amount_manual"
              @onInput="(e) => getFormData(e, 'salary_amount_manual', i)"
              :fullWidth="true"
              width="100%"
            />
            <div class="blue-dot dot"></div>
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
            "
          >
            <Dropdown
              class="full-width"
              :items="usedValueOpts"
              optionLabel="name"
              optionValue="value"
              :value="item.salary_amount_used_value"
              @change="(e) => getFormData(e, 'salary_amount_used_value', i)"
              width="100%"
              placeholder="label"
            />
            <div
              :class="{
                'orange-dot':
                  item.salary_amount_used_value === 'application_value',
                'green-dot': item.salary_amount_used_value === 'verified_value',
                'blue-dot': item.salary_amount_used_value === 'manual_value',
              }"
              class="dot dot-position"
            ></div>
          </div>
        </div>

        <!--Salary Packaged -->
        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Salary packaged</p>
          <div class="p-col-2">
            <Dropdown
              :items="frequencyOpt"
              :value="item.salary_frequency"
              @change="(e) => getFormData(e, 'salary_frequency', i)"
              width="100%"
              placeholder="label"
            />
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <NumberField
              prefix="$"
              :value="item.salary_packaged"
              :fullWidth="true"
              :disable="true"
              width="100%"
              placeholder="label"
            />
            <div class="orange-dot dot"></div>
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <NumberField
              prefix="$"
              :value="item.salary_packaged_verified"
              :fullWidth="true"
              width="100%"
              :disable="true"
              placeholder="label"
            />
            <div class="green-dot dot"></div>
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
            "
          >
            <NumberField
              type="number"
              :value="item.salary_packaged_manual"
              @onInput="(e) => getFormData(e, 'salary_packaged_manual', i)"
              :fullWidth="true"
              prefix="$"
              width="100%"
            />
            <div class="blue-dot dot"></div>
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
            "
          >
            <Dropdown
              class="full-width"
              :items="usedValueOpts"
              optionLabel="name"
              optionValue="value"
              :value="item.salary_packaged_used_value"
              @change="(e) => getFormData(e, 'salary_packaged_used_value', i)"
              width="100%"
              placeholder="label"
            />
            <div
              :class="{
                'orange-dot':
                  item.salary_packaged_used_value === 'application_value',
                'green-dot':
                  item.salary_packaged_used_value === 'verified_value',
                'blue-dot': item.salary_packaged_used_value === 'manual_value',
              }"
              class="dot dot-position"
            ></div>
          </div>
        </div>

        <!--Overtime -->
        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Overtime</p>
          <div class="p-col-2">
            <Dropdown
              :items="frequencyOpt"
              :value="item.overtime_pay_frequency"
              @change="(e) => getFormData(e, 'overtime_pay_frequency', i)"
              width="100%"
              placeholder="label"
            />
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <NumberField
              prefix="$"
              :value="item.overtime_pay_amount"
              :fullWidth="true"
              :disable="true"
              width="100%"
              placeholder="label"
            />
            <div class="orange-dot dot"></div>
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <NumberField
              prefix="$"
              :value="item.overtime_pay_amount_verified"
              :fullWidth="true"
              :disable="true"
              width="100%"
              placeholder="label"
            />
            <div class="green-dot dot"></div>
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
            "
          >
            <NumberField
              type="number"
              :value="item.overtime_pay_amount_manual"
              @onInput="(e) => getFormData(e, 'overtime_pay_amount_manual', i)"
              :fullWidth="true"
              prefix="$"
              width="100%"
            />
            <div class="blue-dot dot"></div>
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
            "
          >
            <Dropdown
              class="full-width"
              :items="usedValueOpts"
              optionLabel="name"
              optionValue="value"
              :value="item.overtime_pay_amount_used_value"
              @change="
                (e) => getFormData(e, 'overtime_pay_amount_used_value', i)
              "
              width="100%"
              placeholder="label"
            />
            <div
              :class="{
                'orange-dot':
                  item.overtime_pay_amount_used_value === 'application_value',
                'green-dot':
                  item.overtime_pay_amount_used_value === 'verified_value',
                'blue-dot':
                  item.overtime_pay_amount_used_value === 'manual_value',
              }"
              class="dot dot-position"
            ></div>
          </div>
        </div>

        <!--Commission -->
        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Commission</p>
          <div class="p-col-2">
            <Dropdown
              :items="frequencyOpt"
              :value="item.commissions_frequency"
              @change="(e) => getFormData(e, 'commissions_frequency', i)"
              width="100%"
              placeholder="label"
            />
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <NumberField
              prefix="$"
              :value="item.commissions_amount"
              :fullWidth="true"
              :disable="true"
              width="100%"
              placeholder="label"
            />
            <div class="orange-dot dot"></div>
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <NumberField
              prefix="$"
              :value="item.commissions_amount_verified"
              :fullWidth="true"
              :disable="true"
              width="100%"
              placeholder="label"
            />
            <div class="green-dot dot"></div>
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
            "
          >
            <NumberField
              type="number"
              :value="item.commissions_amount_manual"
              @onInput="(e) => getFormData(e, 'commissions_amount_manual', i)"
              :fullWidth="true"
              prefix="$"
              width="100%"
            />
            <div class="blue-dot dot"></div>
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
            "
          >
            <Dropdown
              class="full-width"
              :items="usedValueOpts"
              optionLabel="name"
              optionValue="value"
              :value="item.commissions_amount_used_value"
              @change="
                (e) => getFormData(e, 'commissions_amount_used_value', i)
              "
              width="100%"
              placeholder="label"
            />
            <div
              :class="{
                'orange-dot':
                  item.commissions_amount_used_value === 'application_value',
                'green-dot':
                  item.commissions_amount_used_value === 'verified_value',
                'blue-dot':
                  item.commissions_amount_used_value === 'manual_value',
              }"
              class="dot dot-position"
            ></div>
          </div>
        </div>

        <!--Bonuses -->
        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Bonuses</p>
          <div class="p-col-2">
            <Dropdown
              :items="frequencyOpt"
              :value="item.bonuses_frequency"
              @change="(e) => getFormData(e, 'bonuses_frequency', i)"
              width="100%"
              placeholder="label"
            />
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <NumberField
              prefix="$"
              :value="item.bonuses_amount"
              :disable="true"
              :fullWidth="true"
              width="100%"
              placeholder="label"
            />
            <div class="orange-dot dot"></div>
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <NumberField
              prefix="$"
              :value="item.bonuses_amount_verified"
              :disable="true"
              :fullWidth="true"
              width="100%"
              placeholder="label"
            />
            <div class="green-dot dot"></div>
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
            "
          >
            <NumberField
              type="number"
              :value="item.bonuses_amount_manual"
              @onInput="(e) => getFormData(e, 'bonuses_amount_manual', i)"
              :fullWidth="true"
              prefix="$"
              width="100%"
            />
            <div class="blue-dot dot"></div>
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
            "
          >
            <Dropdown
              class="full-width"
              :items="usedValueOpts"
              optionLabel="name"
              optionValue="value"
              :value="item.bonuses_amount_used_value"
              @change="(e) => getFormData(e, 'bonuses_amount_used_value', i)"
              width="100%"
              placeholder="label"
            />
            <div
              :class="{
                'orange-dot':
                  item.bonuses_amount_used_value === 'application_value',
                'green-dot':
                  item.bonuses_amount_used_value === 'verified_value',
                'blue-dot': item.bonuses_amount_used_value === 'manual_value',
              }"
              class="dot dot-position"
            ></div>
          </div>
        </div>

        <!--Total Income -->
        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Total Income</p>
          <div class="p-col-2">
            <Dropdown
              :items="frequencyOpt"
              :value="item.total_frequency"
              @change="(e) => getFormData(e, 'total_frequency', i)"
              width="100%"
              placeholder="label"
            />
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <NumberField
              prefix="$"
              :value="item.total_amount"
              :disable="true"
              :fullWidth="true"
              width="100%"
              placeholder="label"
            />
            <div class="orange-dot dot"></div>
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <NumberField
              prefix="$"
              :value="item.total_income_amount_verified"
              :disable="true"
              :fullWidth="true"
              width="100%"
              placeholder="label"
            />
            <div class="green-dot dot"></div>
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
            "
          >
            <NumberField
              prefix="$"
              type="number"
              :value="item.total_income_amount_manual"
              :fullWidth="true"
              width="100%"
            />
            <div class="blue-dot dot"></div>
          </div>
          <div
            class="
              p-col-2
              relative
              flex
              align-items-center
              justify-content-center
            "
          >
            <Dropdown
              class="full-width"
              :items="usedValueOpts"
              optionLabel="name"
              optionValue="value"
              :value="item.total_income_amount_used_value"
              @change="
                (e) => getFormData(e, 'total_income_amount_used_value', i)
              "
              width="100%"
              placeholder="label"
            />
            <div
              :class="{
                'orange-dot':
                  item.total_income_amount_used_value === 'application_value',
                'green-dot':
                  item.total_income_amount_used_value === 'verified_value',
                'blue-dot':
                  item.total_income_amount_used_value === 'manual_value',
              }"
              class="dot dot-position"
            ></div>
          </div>
        </div>

        <!-- 
        <FormInput title="Total Income" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox2 from "@/components/common/Checkbox2";
import { mapState, mapActions } from "vuex";

export default {
  props: ["disable"],
  components: { Checkbox2 },
  data() {
    return {
      details: [],
      selectedIncome: [],
      frequencyOpt: ["Weekly", "Fortnightly", "Monthly", "Quarterly", "Yearly"],
      usedValueOpts: [
        {
          name: "App",
          value: "application_value",
        },
        {
          name: "Verified",
          value: "verified_value",
        },
        {
          name: "Manual",
          value: "manual_value",
        },
      ],
      category: {
        id: 1,
        label: "Part time income - Business Name",
      },
    };
  },
  computed: {
    ...mapState({
      partTimeIncome: (state) => state.applications.partTimeIncome,
      partTimeUpdated: (state) => state.applications.partTimeUpdated,
    }),
  },
  methods: {
    ...mapActions({
      setPartTimeIncome: "applications/setPartTimeIncome",
      setPartTimeUpdated: "applications/setPartTimeUpdated",
    }),
    selectIncome(value, incomes) {
      this.selectedIncome = !value
        ? this.selectedIncome.filter(
            (item) => item.name_of_employer != incomes.name_of_employer
          )
        : [...this.selectedIncome, incomes];
      this.$emit("incomeSelected", {
        selectedIncome: this.selectedIncome,
        allIncome: this.details,
        type: "part_time",
      });
    },
    getFormData(value, field, income) {
      this.details[income][field] = value;
      this.details = [...this.details];
      //Updating the used value based on the dropdown selected
      switch (field) {
        case "salary_amount_manual":
          if (this.details[income].salary_amount_used_value == "manual_value") {
            this.details[income]["salary_amount_used"] = this.details[income][
              "salary_amount_manual"
            ]
              ? this.details[income]["salary_amount_manual"]
              : 0;
          }
          break;
        case "salary_packaged_manual":
          if (
            this.details[income].salary_packaged_used_value == "manual_value"
          ) {
            this.details[income]["salary_packaged_used"] = this.details[income][
              "salary_packaged_manual"
            ]
              ? this.details[income]["salary_packaged_manual"]
              : 0;
          }
          break;
        case "overtime_pay_amount_manual":
          if (
            this.details[income].overtime_pay_amount_used_value ==
            "manual_value"
          ) {
            this.details[income]["overtime_pay_amount_used"] = this.details[
              income
            ]["overtime_pay_amount_manual"]
              ? this.details[income]["overtime_pay_amount_manual"]
              : 0;
          }
          break;
        case "commissions_amount_manual":
          {
            if (
              this.details[income].commissions_amount_used_value ==
              "manual_value"
            )
              this.details[income]["commissions_used"] = this.details[income][
                "commissions_amount_manual"
              ]
                ? this.details[income]["commissions_amount_manual"]
                : 0;
          }
          break;
        case "bonuses_amount_manual":
          {
            if (
              this.details[income].bonuses_amount_used_value == "manual_value"
            )
              this.details[income]["bonuses_amount_used"] = this.details[
                income
              ]["bonuses_amount_manual"]
                ? this.details[income]["bonuses_amount_manual"]
                : 0;
          }
          break;
      }
      //Salary
      if (field == "salary_amount_used_value") {
        switch (value) {
          case "application_value":
            this.details[income]["salary_amount_used"] = this.details[income][
              "salary_amount"
            ]
              ? this.details[income]["salary_amount"]
              : 0;
            break;
          case "verified_value":
            this.details[income]["salary_amount_used"] = this.details[income][
              "salary_amount_verified"
            ]
              ? this.details[income]["salary_amount_verified"]
              : 0;
            break;
          case "manual_value":
            this.details[income]["salary_amount_used"] = this.details[income][
              "salary_amount_manual"
            ]
              ? this.details[income]["salary_amount_manual"]
              : 0;
            break;
        }
      }
      //Salary Packaged
      if (field == "salary_packaged_used_value") {
        switch (value) {
          case "application_value":
            this.details[income]["salary_packaged_used"] = this.details[income][
              "salary_packaged"
            ]
              ? this.details[income]["salary_packaged"]
              : 0;
            break;
          case "verified_value":
            this.details[income]["salary_packaged_used"] = this.details[income][
              "salary_packaged_verified"
            ]
              ? this.details[income]["salary_packaged_verified"]
              : 0;
            break;
          case "manual_value":
            this.details[income]["salary_packaged_used"] = this.details[income][
              "salary_packaged_manual"
            ]
              ? this.details[income]["salary_packaged_manual"]
              : 0;
            break;
        }
      }

      //Overtime Packaged
      if (field == "overtime_pay_amount_used_value") {
        switch (value) {
          case "application_value":
            this.details[income]["overtime_pay_amount_used"] = this.details[
              income
            ]["overtime_pay_amount"]
              ? this.details[income]["overtime_pay_amount"]
              : 0;
            break;
          case "verified_value":
            this.details[income]["overtime_pay_amount_used"] = this.details[
              income
            ]["overtime_pay_amount_verified"]
              ? this.details[income]["overtime_pay_amount_verified"]
              : 0;
            break;
          case "manual_value":
            this.details[income]["overtime_pay_amount_used"] = this.details[
              income
            ]["overtime_pay_amount_manual"]
              ? this.details[income]["overtime_pay_amount_manual"]
              : 0;
            break;
        }
      }

      //Commisions
      if (field == "commissions_amount_used_value") {
        switch (value) {
          case "application_value":
            this.details[income]["commissions_used"] = this.details[income][
              "commissions_amount"
            ]
              ? this.details[income]["commissions_amount"]
              : 0;
            break;
          case "verified_value":
            this.details[income]["commissions_used"] = this.details[income][
              "commissions_amount_verified"
            ]
              ? this.details[income]["commissions_amount_verified"]
              : 0;
            break;
          case "manual_value":
            this.details[income]["commissions_used"] = this.details[income][
              "commissions_amount_manual"
            ]
              ? this.details[income]["commissions_amount_manual"]
              : 0;
            break;
        }
      }
      //Bonuses
      if (field == "bonuses_amount_used_value") {
        switch (value) {
          case "application_value":
            this.details[income]["bonuses_amount_used"] = this.details[income][
              "bonuses_amount"
            ]
              ? this.details[income]["bonuses_amount"]
              : 0;
            break;
          case "verified_value":
            this.details[income]["bonuses_amount_used"] = this.details[income][
              "bonuses_amount_verified"
            ]
              ? this.details[income]["bonuses_amount_verified"]
              : 0;
            break;
          case "manual_value":
            this.details[income]["bonuses_amount_used"] = this.details[income][
              "bonuses_amount_manual"
            ]
              ? this.details[income]["bonuses_amount_manual"]
              : 0;
            break;
        }
      }
      this.details[income].total_income_amount_manual =
        Number(this.details[income].salary_amount_manual) +
        Number(this.details[income].salary_packaged_manual) +
        Number(this.details[income].overtime_pay_amount_manual) +
        Number(this.details[income].commissions_amount_manual) +
        Number(this.details[income].bonuses_amount_manual);
      this.setPartTimeIncome({
        data: this.details,
        type: !this.disable ? "primary" : "secondary",
      });
      this.setPartTimeUpdated(this.partTimeUpdated + 1);
    },
    setData() {
      this.details = !this.disable
        ? this.partTimeIncome.applicant_1
        : this.partTimeIncome.applicant_2;
      this.details.map((item) => {
        item.total_amount =
          Number(item.salary_amount) +
          Number(item.salary_packaged) +
          Number(item.overtime_pay_amount) +
          Number(item.commissions_amount) +
          Number(item.bonuses_amount);

        item.total_income_amount_verified =
          Number(item.salary_amount_verified) +
          Number(item.salary_packaged_verified) +
          Number(item.overtime_pay_amount_verified) +
          Number(item.commissions_amount_verified) +
          Number(item.bonuses_amount_verified);

        item.total_income_amount_manual =
          Number(item.salary_amount_manual) +
          Number(item.salary_packaged_manual) +
          Number(item.overtime_pay_amount_manual) +
          Number(item.commissions_amount_manual) +
          Number(item.bonuses_amount_manual);
      });
    },
  },
  created() {
    this.setData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

.sub-title {
  margin: 0;
  margin-top: 1.25rem;
  background: rgba(2, 0, 56, 0.03);
  padding: 10px;
}

:deep .p-field-checkbox {
  margin-bottom: 0;
}

::v-deep .p-col-2 {
  padding-left: 0;
}

.relative {
  position: relative;
}

.dot-position {
  right: 45px !important;
}

.dot {
  position: absolute;
  right: 20px;
}

.header {
  ::v-deep .p-col-2 {
    padding-bottom: 0;
  }
}
.sub-title {
  margin: 0;
  margin-top: 1.25rem;
  background: rgba(2, 0, 56, 0.03);
  padding: 10px;
  display: flex;
  align-items: center;
}
.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

p {
  margin: 0;
}
</style>
