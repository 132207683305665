<template>
  <Dialog :draggable="false" :visible="show" :modal="show" :style="{ width: '580px' }">
    <template #header>
      <h1 class="modal-title">Export Options</h1>
    </template>
    <div class="modal-content">
      <p class="pm-txt-color hk-sb fs-14">What would you like to do?</p>
      <div class="flex flex-gap align-items-center">
        <input
          type="radio"
          name="export"
          v-model="selectedType"
          id="csv"
          @click="showdata = !showdata"
          value="csv"
        />
        <label class="pm-txt-color hk-sb fs-14" for="csv">Export loan as CSV</label>
      </div>
      <div class="flex flex-gap align-items-center">
        <input type="radio" name="export" value="pdf" id="pdf" v-model="selectedType" />
        <label class="pm-txt-color hk-sb fs-14" for="pdf"
          >Export the Credit Summary as PDF</label
        >
      </div>
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button :loading="isloading" label="Export" type="primary" @click="save" />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">Close</div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import { _get } from "../../../../../../services/crudService";
import { APP_URL } from "../../../../../../../constants";
import jsPDF from "jspdf";

export default {
  props: ["show"],
  components: { Dialog },
  data() {
    return {
      selectedType: "",
      showdata: true,
      version: "1",
      export: true,
      decodedStr: "",
      isloading: false,
    };
  },
  // watch: {
  //   selectedType(value) {
  //     console.log(value, "valuealfas-fadfksadfo");
  //   },
  // },
  methods: {
    close() {
      this.$emit("close");
    },
    async save() {
      if (this.selectedType) {
        if (this.showdata === false) {
          await this.getJson();
          this.$emit("close");
        } else {
          await this.downloadPdf();
        }
      }
    },
    pdfDocument(data) {
      var pdf = new jsPDF();
      this.decodedPdf = atob(data);
      pdf.text(this.decodedPdf, 10, 10);
      pdf.save("CraftMyPDF");
    },
    downloadPDFFile(pdf) {
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = "CraftMyPDF.pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    async downloadPdf() {
      this.isloading = true;

      try {
        const PDF = await _get(
          APP_URL +
            `v1/download-application-summary?applicationId=${this.$route.params.id}`
        );
        // const documentPdf = this.pdfDocument(PDF.data[0].file);
        this.downloadPDFFile(PDF.data[0].file);
        this.isloading = false;
        this.$emit("close");
      } catch (err) {
        this.isloading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    download(data) {
      const blob = new Blob([data], { type: "csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", "download.csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    myfunction(data) {
      this.decodedStr = atob(data);
      this.download(this.decodedStr);
    },
    async getJson() {
      try {
        this.isloading = true;

        const Json = await _get(
          APP_URL +
            `application-details_csv?id=${this.$route.params.id}&version=${this.version}`
        );
        this.myfunction(Json.data);
        this.isloading = false;
      } catch (error) {
        this.isloading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.fs-14 {
  font-size: 16px;
}

.flex-gap {
  gap: 0.7rem;
}
</style>
