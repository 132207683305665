<template>
  <div>
    <Header />
    <router-view class="mt-5"></router-view>
  </div>
</template>

<script>
import Header from "./Header.vue"

export default {
  components: { Header },
}
</script>

<style></style>
