<template>
  <div class="modal-content">
    <div class="flex mt-3">
      <div class="col">
        <div>Title</div>
        <TextField
          width="100%"
          placeholder="Title"
          class="mt-2"
          :value="formData?.title"
          @getInputValue="getFormData($event, 'title')"
        />
      </div>
    </div>
    <div class="flex mt-3">
      <div class="col">
        <div>Description</div>
        <TextArea
          width="100%"
          placeholder="Description"
          class="mt-2"
          :value="formData?.description"
          @getInputValue="getFormData($event, 'description')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  props: ["groupId", "groupInfo"],
  data() {
    return {
      formData: {
        title: "",
        description: "",
      },
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        title: { required },
        description: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      info: "group/info",
      selectedGroupId: "group/selectedGroupId",
    }),
  },

  methods: {
    ...mapActions({
      setInfo: "group/setInfo",
    }),
    close() {
      this.$emit("close");
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };

      // this.$emit("getInfo", this.info);
      this.setInfo(this.formData);
      this.$emit("disableBtn", this.v$.$invalid);
      // console.log(this.v$.$invalid, "validation");
    },
  },
  created() {
    if (this.info && this.selectedGroupId) {
      this.$emit("disableBtn", this.v$.$invalid);
      this.formData = this.info;
      // this.$emit("getInfo", this.info);
    } else {
      this.$emit("disableBtn", true);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
  font-family: Gordita-bold !important;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.text-content {
  margin-top: 1rem;
}
form {
  gap: 1rem;

  input[type="radio"] {
    width: 1.125rem;
    height: 1.125rem;
  }
}
.label,
label {
  font-size: 1.125rem;
}

label {
  margin-left: 0.4rem;
}
.radio-input {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
::v-deep .p-card-content {
  padding: 1rem 1rem !important;
}
::v-deep .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: unset !important;
}
.back {
  font-weight: 700;
  color: rgba(2, 0, 56, 0.4);
  font-size: 1rem;
}
</style>
