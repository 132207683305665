<template>
  <div>
    <div class="content">
      <div class="form-inputs mt-4">
        <div v-if="assets.length" class="p-grid align-items-center header">
          <p class="col"></p>
          <p class="col label">Application Values</p>
          <p class="col label">Current Values</p>
          <p class="col label">Current Value Source</p>
          <div class="col"></div>
        </div>
        <div v-for="(item, i) in assets" :key="i">
          <InputRow
            :value1="originalAssets[i] ? originalAssets[i].amount : ''"
            :value2="
              item.amount_used_value === 'manual_value'
                ? item.amount_manual
                : item.amount_used_value === 'verified_value'
                ? item.amount_verified
                : item.amount
            "
            :value3="
              item.amount_used_value === 'manual_value'
                ? 'Manual value'
                : item.amount_used_value === 'verified_value'
                ? 'Verified value'
                : 'App value'
            "
            :label="`Superannuation ${i + 1}- $`"
          />
        </div>
        <InputRow
          v-if="assets.length"
          label="Subtotal"
          :value1="originalTotal"
          :value2="latestTotal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputRow from "../../InputRow.vue";
export default {
  components: { InputRow },
  computed: {
    ...mapGetters({
      originalData: "loans/originalData",
      latestData: "loans/latestData",
    }),
  },
  data() {
    return {
      listKey: 0,
      assets: [],
      originalAssets: [],
      originalTotal: 0,
      latestTotal: 0,
    };
  },
  watch: {
    latestData() {
      this.assets = [];
      this.latestData.assets.map((obj) => {
        if (obj.type == "superannuation") {
          this.assets.push(obj);
          let amount =
            obj.amount_used_value === "manual_value"
              ? obj.amount_manual
              : obj.amount_used_value === "verfied_value"
              ? obj.amount_verified
              : obj.amount;
          this.latestTotal = this.latestTotal += amount;
        }
      });
      this.originalAssets = [];
      this.originalData.assets.map((obj) => {
        if (obj.type == "superannuation") {
          if (obj.amount) this.originalTotal += obj.amount;
          this.originalAssets.push(obj);
        }
      });
      this.listKey += 1;
    },
  },
  created() {
    this.assets = [];
    this.latestData.assets.map((obj) => {
      if (obj.type == "superannuation") {
        this.assets.push(obj);
        let amount =
          obj.amount_used_value === "manual_value"
            ? obj.amount_manual
            : obj.amount_used_value === "verfied_value"
            ? obj.amount_verified
            : obj.amount;
        this.latestTotal = this.latestTotal += amount;
      }
    });
    this.originalAssets = [];
    this.originalData.assets.map((obj) => {
      if (obj.type == "superannuation") {
        if (obj.amount) this.originalTotal += obj.amount;
        this.originalAssets.push(obj);
      }
    });
    this.listKey += 1;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
</style>
