<template>
  <div>
    <label class="toggle-switch">
      <input type="checkbox" v-model="check" />
      <span class="text">
        <p class="yes">Yes</p>
        <p class="no">No</p>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: ["isChecked"],
  data() {
    return {
      check: this.$props.isChecked || false,
    };
  },
  watch: {
    check(value) {
      this.$emit("onChange", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 71px;
  height: 30px;

  input {
    display: none;
  }

  p {
    margin: 0;
    user-select: none;
  }

  .text .yes {
    color: #ffffff;
    position: absolute;
    left: 12px;
    font-size: 16px;
    display: none;
    font-family: Gordita-medium-;
  }
  .text .no {
    color: rgba(2, 0, 56, 0.4);
    font-family: Gordita-medium-;
    position: absolute;
    font-size: 16px;
    right: 12px;
  }

  .text {
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(2, 0, 56, 0.05);
    border: 1px solid rgba(2, 0, 56, 0.15);
    box-sizing: border-box;
    border-radius: 15px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .text:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 1px;
    bottom: 1.1px;
    border-radius: 50%;
    background-color: white;
  }

  input:checked + .text .yes {
    display: block;
  }

  input:checked + .text .no {
    display: none;
  }

  input:checked + .text {
    background-color: $primary-color;
  }

  input:focus + .text {
    box-shadow: 0 0 1px $primary-color;
  }

  input:checked + .text:before {
    -webkit-transform: translateX(40px);
    -ms-transform: translateX(40px);
    transform: translateX(40px);
  }
}
</style>
