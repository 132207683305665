<template>
  <div>
    <div class="content">
      <div class="form-inputs mt-4">
        <div class="p-grid align-items-center header">
          <p class="col"></p>
          <p class="col label">Application Values</p>
          <p class="col label">Current Values</p>
          <p class="col label">Current Value Source</p>
          <div class="col"></div>
        </div>

        <InputRow
          label="Funds to complete"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.funds_to_complete_purchase
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.funds_to_complete_purchase
              : ''
          "
        />
        <InputRow
          label="Set-up Fee $"
          :value1="original_setup_fee_amount"
          :value2="latest_setup_fee_amount"
        />

        <InputRow
          label="Broker Fee $"
          :value1="original_broker_fee_amount"
          :value2="latest_broker_fee_amount"
        />
        <InputRow
          label="Total Set-up Fee $"
          :value1="original_total_fee_amount"
          :value2="latest_total_fee_amount"
        />
        <InputRow
          label="Additional Funds"
          :value1="originalData.additional_fund"
          :value2="latestData.additional_fund"
        />
        <InputRow
          label="Initial Loan Amount"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.loan_amount
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.loan_amount
              : ''
          "
        />
        <InputRow
          label="Set-up Fee %"
          :value1="original_setup_fee_percent * 100"
          :value2="latest_setup_fee_percent * 100"
        />
        <InputRow
          label="Broker Fee %"
          :value1="original_broker_fee_percent * 100"
          :value2="latest_broker_fee_percent * 100"
        />
        <InputRow
          label="Total Set-up Fee %"
          :value1="original_total_fee_percent * 100"
          :value2="latest_total_fee_percent * 100"
        />
        <InputRow
          label="Capitalised Interest"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.interest_on_caps_loan
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.interest_on_caps_loan
              : ''
          "
        />
        <InputRow
          label="Peak Debt"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.peak_debt
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.peak_debt
              : ''
          "
        />
        <InputRow
          label="Rate % "
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.cap_loan_rate * 100
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.cap_loan_rate * 100
              : ''
          "
        />
        <InputRow
          label="Term"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.loan_term
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.loan_term
              : ''
          "
        />
        <InputRow
          label="Interest Free Period"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.interest_free_term
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.interest_free_term
              : ''
          "
        />
        <InputRow
          label="Net Sale Proceeds"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.net_sale_proceeds
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.net_sale_proceeds
              : ''
          "
        />
        <InputRow
          label="End Debt"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.end_debt
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.end_debt
              : ''
          "
        />
        <InputRow
          label="Monthly Net Surplus"
          :value1="originalSurplus"
          :value2="latestSurplus"
        />
        <!-- <InputRow label="Current Value Source" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputRow from "../InputRow.vue";
export default {
  components: { InputRow },
  computed: {
    ...mapGetters({
      originalData: "loans/originalData",
      latestData: "loans/latestData",
    }),
  },
  data() {
    return {
      // original amount
      original_setup_fee_amount: 0,
      original_broker_fee_amount: 0,
      original_total_fee_amount: 0,
      // original percent
      original_broker_fee_percent: 0,
      original_setup_fee_percent: 0,
      original_total_fee_percent: 0,
      // latest amount
      latest_setup_fee_amount: 0,
      latest_broker_fee_amount: 0,
      latest_total_fee_amount: 0,
      // latest percent
      latest_broker_fee_percent: 0,
      latest_setup_fee_percent: 0,
      latest_total_fee_percent: 0,
      originalSurplus: 0,
      latestSurplus: 0,
    };
  },
  methods: {
    getUpdatedValues(valueField, frequencyField, index, type) {
      let value = "",
        frequency = "";
      console.log("origial ", this.originalData.salaried, index, valueField);
      if (
        type === "original" &&
        this.originalData &&
        this.originalData.salaried &&
        this.originalData.salaried.length
      ) {
        value = this.originalData.salaried[index][valueField];
        frequency = this.originalData.salaried[index][frequencyField];
      } else {
        if (
          this.latestData &&
          this.latestData.salaried &&
          this.latestData.salaried.length
        ) {
          value = this.latestData.salaried[index][valueField];
          frequency = this.latestData.salaried[index][frequencyField];
        }
      }
      switch (frequency) {
        case "Weekly":
          return value * 4;
        case "Fortnightly":
          return value * 2;
        case "Monthly":
          return value * 1;
        case "Quarterly":
          return value / 3;
        case "Yearly":
          return value / 12;
      }
      return 0;
    },
    monthlySubtotal(i, type) {
      let total =
        Number(
          this.getUpdatedValues(
            "salary_amount_used",
            "salary_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getUpdatedValues(
            "salary_packaged_used",
            "salary_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getUpdatedValues(
            "overtime_pay_amount_used",
            "overtime_pay_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getUpdatedValues(
            "commissions_used",
            "commissions_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getUpdatedValues(
            "bonuses_amount_used",
            "bonuses_frequency",
            i,
            type
          )
        );
      return total ? total.toFixed(2) : 0;
    },
    // additional income
    getAdditionalIncomeUpdatedValues(valueField, frequencyField, index, type) {
      let value = "",
        frequency = "";
      if (
        type === "original" &&
        this.originalData.additional_income &&
        this.originalData.additional_income.length
      ) {
        value = this.originalData.additional_income[index][valueField];
        frequency = this.originalData.additional_income[index][frequencyField];
      } else {
        if (
          this.latestData.additional_income &&
          this.latestData.additional_income.length
        ) {
          value = this.latestData.additional_income[index][valueField];
          frequency = this.latestData.additional_income[index][frequencyField];
        }
      }
      switch (frequency) {
        case "Weekly":
          return value * 4;
        case "Fortnightly":
          return value * 2;
        case "Monthly":
          return value * 1;
        case "Quarterly":
          return value / 3;
        case "Yearly":
          return value / 12;
      }
      return 0;
    },
    monthlyAdditionalSubtotal(i, type) {
      let total =
        Number(
          this.getAdditionalIncomeUpdatedValues(
            "child_support_maintenance",
            "child_support_maintenance_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getAdditionalIncomeUpdatedValues(
            "family_allowance",
            "family_allowance_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getAdditionalIncomeUpdatedValues(
            "family_tax_benefits",
            "family_tax_benefits_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getAdditionalIncomeUpdatedValues(
            "govt_pension",
            "govt_pension_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getAdditionalIncomeUpdatedValues(
            "dividends",
            "dividends_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getAdditionalIncomeUpdatedValues(
            "super",
            "super_frequency",
            i,
            type
          )
        );
      Number(
        this.getAdditionalIncomeUpdatedValues(
          "other",
          "other_frequency",
          i,
          type
        )
      );
      return total ? total.toFixed(2) : 0;
    },
    findOriginalSurplus() {
      let totalExpenses = 0;
      if (
        this.originalData &&
        this.originalData.expenses &&
        this.originalData.expenses.length
      ) {
        totalExpenses = this.originalData.expenses.reduce(
          (previousValue, currentValue) => {
            return Number(previousValue) + Number(currentValue?.amount);
          },
          0
        );
      }

      let totalIncome = 0;
      this.originalData.salaried.map((obj, i) => {
        totalIncome = totalIncome + Number(this.monthlySubtotal(i, "original"));
      });
      this.originalData.additional_income.map((obj, i) => {
        totalIncome =
          totalIncome + Number(this.monthlyAdditionalSubtotal(i, "original"));
      });
      let cardsAmount = 0;
      this.originalData.liability_cards.map((obj) => {
        cardsAmount = obj.credit_limit + cardsAmount;
      });
      this.originalData.liability_vechicles.map((obj) => {
        cardsAmount = obj.amount_owning + cardsAmount;
      });
      this.originalData.liability_others.map((obj) => {
        cardsAmount = obj.amount_owning + cardsAmount;
      });
      this.originalSurplus = totalIncome - totalExpenses - cardsAmount;
    },
    findLatestSurplus() {
      let totalExpenses = 0;
      if (
        this.latestData &&
        this.latestData.expenses &&
        this.latestData.expenses.length
      ) {
        totalExpenses = this.latestData.expenses.reduce(
          (previousValue, currentValue) => {
            return Number(previousValue) + Number(currentValue?.amount);
          },
          0
        );
      }

      let totalIncome = 0;
      this.latestData.salaried.map((obj, i) => {
        totalIncome = totalIncome + Number(this.monthlySubtotal(i, "latest"));
      });
      this.latestData.additional_income.map((obj, i) => {
        totalIncome =
          totalIncome + Number(this.monthlyAdditionalSubtotal(i, "latest"));
      });
      let cardsAmount = 0;
      this.latestData.liability_cards.map((obj) => {
        cardsAmount = obj.amount_owning + cardsAmount;
      });
      this.latestData.liability_vechicles.map((obj) => {
        cardsAmount = obj.monthly_repayment + cardsAmount;
      });
      this.latestData.liability_others.map((obj) => {
        cardsAmount = obj.monthly_repayment + cardsAmount;
      });
      this.latestSurplus = totalIncome - totalExpenses - cardsAmount;
    },
  },

  created() {
    // original
    this.original_broker_fee_percent =
      this.originalData &&
      this.originalData.calculated_fields &&
      this.originalData.calculated_fields.broker_fee
        ? this.originalData.calculated_fields.broker_fee
        : 0;
    this.original_setup_fee_percent =
      this.originalData &&
      this.originalData.calculated_fields &&
      this.originalData.calculated_fields.setup_fee
        ? this.originalData.calculated_fields.setup_fee
        : 0;

    let setupAmount =
      this.originalData &&
      this.originalData.calculated_fields &&
      this.originalData.calculated_fields.setup_fee
        ? this.originalData.calculated_fields.setup_fee
        : 0;
    let brokerAmount =
      this.originalData &&
      this.originalData.calculated_fields &&
      this.originalData.calculated_fields.broker_fee
        ? this.originalData.calculated_fields.broker_fee
        : 0;
    let totalAmount =
      this.originalData &&
      this.originalData.calculated_fields &&
      this.originalData.calculated_fields.initial_amount_financed
        ? this.originalData.calculated_fields.initial_amount_financed
        : 0;
    this.original_total_fee_percent =
      this.original_broker_fee_percent + this.original_setup_fee_percent;

    this.original_setup_fee_amount = setupAmount * totalAmount;
    this.original_broker_fee_amount = brokerAmount * totalAmount;
    this.original_total_fee_amount =
      this.original_setup_fee_amount + this.original_broker_fee_amount;
    this.findOriginalSurplus();
    // latest
    this.latest_broker_fee_percent = this.latestData.calculated_fields
      .broker_fee
      ? this.latestData.calculated_fields.broker_fee
      : 0;
    this.latest_setup_fee_percent = this.latestData.calculated_fields.setup_fee
      ? this.latestData.calculated_fields.setup_fee
      : 0;

    let setupAmount1 = this.latestData.calculated_fields.setup_fee
      ? this.latestData.calculated_fields.setup_fee
      : 0;
    let brokerAmount1 = this.latestData.calculated_fields.broker_fee
      ? this.latestData.calculated_fields.broker_fee
      : 0;
    let totalAmount1 = this.latestData.calculated_fields.initial_amount_financed
      ? this.latestData.calculated_fields.initial_amount_financed
      : 0;
    this.latest_total_fee_percent = this.latest_broker_fee_percent + this.latest_setup_fee_percent
    this.latest_setup_fee_amount = setupAmount1 * totalAmount1;
    this.latest_broker_fee_amount = brokerAmount1 * totalAmount1;
    this.latest_total_fee_amount =
      this.latest_setup_fee_amount + this.latest_broker_fee_amount;
    this.findLatestSurplus();
  },
  watch: {
    latestData() {
      this.findOriginalSurplus();
      this.findLatestSurplus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
</style>
