<template>
  <div>
    <InitialLvr />
    <EndDebt />
    <InitialAmount />
    <Income />
    <Serviceability />
    <Tasks />
    <div class="flex align-items-center">
      <Button class="mt-3" label="Add new user" type="primary" @click="step" />
      <div class="back mt-3 ml-4 cursor-pointer" @click="close">Cancel</div>
    </div>
  </div>
</template>
<script>
import InitialLvr from "./InitialLvr.vue";
import EndDebt from "./EndDebt.vue";
import InitialAmount from "./InitialAmount.vue";
import Income from "./Income.vue";
import Serviceability from "./Serviceability.vue";
import Tasks from "./Tasks.vue";
export default {
  props: ["close", "btnText"],
  components: {
    InitialLvr,
    EndDebt,
    InitialAmount,
    Income,
    Serviceability,
    Tasks,
  },
  methods: {
    data() {
      return {
        collapsed: true,
      };
    },
    toggle() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.title-wrapper {
  border-bottom: 1px solid $secondary-color;
  padding-bottom: 0.625rem;
}

.title {
  font-size: 1.75rem;
  font-family: Gordita-bold;
  margin-left: 0.625rem;
  color: #020038;
  margin-left: -1rem;
}

::v-deep .col-9,
.col-3 {
  padding-top: 0;
  padding-bottom: 0;
}

.mr-b {
  margin-bottom: 1.875rem;
}

.mr-b-5px {
  margin-bottom: 5px;
}

.form-inputs {
  margin-top: 2.5rem;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.box {
  background: #ffffff;
  border: 1px solid rgba(2, 0, 56, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 1.25rem;
}

p {
  margin: 0;
}
.lt-txt {
  font-weight: 600;
}
.lt-text {
  font-weight: 600;
}
.items-center {
  margin-top: -2rem;
}
.title-wrapper[data-v-6a01628b] {
  border-bottom: none !important;
  padding-bottom: 0.625rem;
}
.mt-5 {
  background: #fff;
  margin-top: -2rem !important;
}
.flex-content {
  gap: 3rem;
}
.sec-color-text {
  font-size: 1.45rem;
  font-family: Gordita-bold;
  color: #020038;
  font-weight: 600;
}
</style>
