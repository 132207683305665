<template>
  <div>
    <div class="content">
      <div class="form-inputs mt-4">
        <div class="p-grid align-items-center header" v-if="latest.length">
          <p class="col"></p>
          <p class="col label">Application Values</p>
          <p class="col label">Current Values</p>
          <p class="col label">Current Value Source</p>
          <div class="col"></div>
        </div>
        <div v-for="(item, i) in latest" :key="i">
          <br />
          <InputRow
            label="Business Trading name"
            :value1="original[i] ? original[i].self_emp_trading_name : ''"
            :value2="item.self_emp_trading_name"
          />
          <InputRow
            label="ABN"
            :value1="original[i] ? original[i].self_emp_abn : ''"
            :value2="item.self_emp_abn"
          />
          <InputRow
            label="Income"
            :value1="original[i] ? original[i].self_emp_salary : ''"
            :value2="item.self_emp_salary"
          />
          <InputRow
            label="Frequency"
            :value1="original[i] ? original[i].self_emp_frequency : ''"
            :value2="item.self_emp_frequency"
          />
          <InputRow
            label="Ratio%"
            :value1="
              calculateRatio(
                item.self_emp_salary,
                monthlySubtotal(i, 'original')
              )
            "
            :value2="
              calculateRatio(item.self_emp_salary, monthlySubtotal(i, 'latest'))
            "
          />
          <InputRow
            label="Monthly sub-total"
            :value1="monthlySubtotal(i, 'original')"
            :value2="monthlySubtotal(i, 'latest')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputRow from "../../InputRow.vue";
export default {
  components: { InputRow },
  props: ["applicant"],
  computed: {
    ...mapGetters({
      originalData: "loans/originalData",
      latestData: "loans/latestData",
    }),
  },
  data() {
    return {
      original: [],
      latest: [],
      originalTotal: 0,
      latestTotal: 0,
    };
  },
  methods: {
    setData() {
      let applicant_type = this.applicant == "1" ? "primary" : "secondary";
      this.latest = [];
      this.latestData.self_employed.map((obj) => {
        if (obj.applicant_type === applicant_type) {
          this.latestTotal = this.latestTotal + obj.self_emp_salary;
          this.latest.push(obj);
        }
      });
      this.original = [];
      this.originalData.self_employed.map((obj) => {
        if (obj.applicant_type === applicant_type) {
          this.originalTotal = this.originalTotal + obj.self_emp_salary;
          this.original.push(obj);
        }
      });
    },
    calculateRatio(value, total) {
      if (value && total) {
        let calc = value / total;
        let percent = calc * 100;
        return percent.toFixed(2);
      }
      return 0;
    },
    //Get updated values based on frequency
    getUpdatedValues(valueField, frequencyField, index, type) {
      let value = "",
        frequency = "";
      if (type === "original") {
        value = this.original[index][valueField];
        frequency = this.original[index][frequencyField];
      } else {
        value = this.latest[index][valueField];
        frequency = this.latest[index][frequencyField];
      }
      switch (frequency) {
        case "Weekly":
          return value * 4;
        case "Fortnightly":
          return value * 2;
        case "Monthly":
          return value * 1;
        case "Quarterly":
          return value / 3;
        case "Yearly":
          return value / 12;
      }
      return 0;
    },
    monthlySubtotal(i, type) {
      let total =
        Number(
          this.getUpdatedValues(
            "salary_amount_used",
            "salary_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getUpdatedValues(
            "salary_packaged_used",
            "salary_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getUpdatedValues(
            "overtime_pay_amount_used",
            "overtime_pay_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getUpdatedValues(
            "commissions_used",
            "commissions_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getUpdatedValues(
            "bonuses_amount_used",
            "bonuses_frequency",
            i,
            type
          )
        );
      return total ? total.toFixed(2) : 0;
    },
  },
  watch: {
    latestData() {
      this.setData();
    },
  },
  created() {
    this.setData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
</style>
