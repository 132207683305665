<template>
  <div class="application-header-layout pt-4 pl-4 pr-4 pb-0">
    <h1 class="module-title white-color">Loans</h1>
    <div
      class="flex align-items-end overflow-x txt-no-wrap h-100 mt-4 bold-600"
    >
      <div v-for="(item, i) in list" :key="i">
        <router-link :to="routeLink(i)" class="a-link">
          <div
            class="
              mr-1
              cursor-pointer
              application-option application-option-inactive
            "
          >
            {{ item.title }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplicationHeader",
  data() {
    return {
      list: [
        { title: "Overview", isActive: true },
        { title: "Details ", isActive: false },
        { title: "Customers", isActive: false },
        { title: "Decision seeker", isActive: false },
        { title: "Communications", isActive: false },
        { title: "Documents", isActive: false },
        { title: "Tasks", isActive: false },
        { title: "Notes", isActive: false },
        { title: "Audit", isActive: false },
        { title: "Transaction report", isActive: false },
        { title: "Integrations", isActive: false },
      ],
    };
  },

  methods: {
    routeLink(i) {
      if (this.$route.params.id && this.$route.query.loanId) {
        switch (i) {
          case 0:
            return (
              `/loan/${this.$route.params.id}/overview?loanId=` +
              this.$route.query.loanId +
              "&declaration=" +
              this.$route.query.declaration
            );

          case 1:
            return (
              `/loan/${this.$route.params.id}/details?loanId=` +
              this.$route.query.loanId +
              "&declaration=" +
              this.$route.query.declaration
            );

          case 2:
            return (
              `/loan/${this.$route.params.id}/customer?loanId=` +
              this.$route.query.loanId +
              "&declaration=" +
              this.$route.query.declaration
            );

          case 3:
            return (
              `/loan/${this.$route.params.id}/desicion-seeker?loanId=` +
              this.$route.query.loanId +
              "&declaration=" +
              this.$route.query.declaration
            );

          case 4:
            return (
              `/loan/${this.$route.params.id}/communication?loanId=` +
              this.$route.query.loanId +
              "&declaration=" +
              this.$route.query.declaration
            );

          case 5:
            return (
              `/loan/${this.$route.params.id}/documents?loanId=` +
              this.$route.query.loanId +
              "&declaration=" +
              this.$route.query.declaration
            );

          case 6:
            return (
              `/loan/${this.$route.params.id}/tasks?loanId=` +
              this.$route.query.loanId +
              "&declaration=" +
              this.$route.query.declaration
            );

          case 7:
            return (
              `/loan/${this.$route.params.id}/notes?loanId=` +
              this.$route.query.loanId +
              "&declaration=" +
              this.$route.query.declaration
            );

          case 8:
            return (
              `/loan/${this.$route.params.id}/audit?loanId=` +
              this.$route.query.loanId +
              "&declaration=" +
              this.$route.query.declaration
            );

          case 9:
            return (
              `/loan/${this.$route.params.id}/transaction?loanId=` +
              this.$route.query.loanId +
              "&declaration=" +
              this.$route.query.declaration
            );

          case 10:
            return (
              `/loan/${this.$route.params.id}/integration?loanId=` +
              this.$route.query.loanId +
              "&declaration=" +
              this.$route.query.declaration
            );
        }
      }
    },
    onSelectItem(i) {
      this.list.map((obj) => {
        obj.isActive = false;
      });

      this.list[i].isActive = true;
      if (this.$route.params.id && this.$route.query.loanId) {
        switch (i) {
          case 0:
            this.$router.push(
              `/loan/${this.$route.params.id}/overview?loanId=` +
                this.$route.query.loanId +
                "&declaration=" +
                this.$route.query.declaration
            );
            break;
          case 1:
            this.$router.push(
              `/loan/${this.$route.params.id}/details?loanId=` +
                this.$route.query.loanId +
                "&declaration=" +
                this.$route.query.declaration
            );
            break;
          case 2:
            this.$router.push(
              `/loan/${this.$route.params.id}/customer?loanId=` +
                this.$route.query.loanId +
                "&declaration=" +
                this.$route.query.declaration
            );
            break;
          case 3:
            this.$router.push(
              `/loan/${this.$route.params.id}/desicion-seeker?loanId=` +
                this.$route.query.loanId +
                "&declaration=" +
                this.$route.query.declaration
            );
            break;
          case 4:
            this.$router.push(
              `/loan/${this.$route.params.id}/communication?loanId=` +
                this.$route.query.loanId +
                "&declaration=" +
                this.$route.query.declaration
            );
            break;
          case 5:
            this.$router.push(
              `/loan/${this.$route.params.id}/documents?loanId=` +
                this.$route.query.loanId +
                "&declaration=" +
                this.$route.query.declaration
            );
            break;
          case 6:
            this.$router.push(
              `/loan/${this.$route.params.id}/tasks?loanId=` +
                this.$route.query.loanId +
                "&declaration=" +
                this.$route.query.declaration
            );
            break;
          case 7:
            this.$router.push(
              `/loan/${this.$route.params.id}/notes?loanId=` +
                this.$route.query.loanId +
                "&declaration=" +
                this.$route.query.declaration
            );
            break;
          case 8:
            this.$router.push(
              `/loan/${this.$route.params.id}/audit?loanId=` +
                this.$route.query.loanId +
                "&declaration=" +
                this.$route.query.declaration
            );
            break;
          case 9:
            this.$router.push(
              `/loan/${this.$route.params.id}/transaction?loanId=` +
                this.$route.query.loanId +
                "&declaration=" +
                this.$route.query.declaration
            );
            break;
          case 10:
            this.$router.push(
              `/loan/${this.$route.params.id}/integration?loanId=` +
                this.$route.query.loanId +
                "&declaration=" +
                this.$route.query.declaration
            );
            break;
        }
      }
    },
    routeNavigation() {
      switch (this.$route.name) {
        case "Overview":
          this.onSelectItem(0);
          break;
        case "ApplicationDetails":
          this.onSelectItem(1);
          break;
        case "ApplicantCusotmer":
          this.onSelectItem(2);
          break;
        case "DecisionSeeker":
          this.onSelectItem(3);
          break;
        case "CommunicationComponent":
          this.onSelectItem(4);
          break;
        case "Documents":
          this.onSelectItem(5);
          break;
        case "ApplicantTasksComponent":
          this.onSelectItem(6);
          break;
        case "ApplicationNotes":
          this.onSelectItem(7);
          break;
        case "LoanAudit":
          this.onSelectItem(8);
          break;
        case "TransactionReport":
          this.onSelectItem(9);
          break;
        case "LoanIntegrations":
          this.onSelectItem(10);
          break;
        case "Application":
          this.onSelectItem(0);
          break;
      }
    },
  },
  created() {
    // this.routeNavigation();
  },
  watch: {
    $route() {
      // this.routeNavigation();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.a-link {
  text-decoration: none;
}

.application-header-layout {
  min-height: 122px;
  min-width: 100% !important;
  background: #9193ad;
}
.overflow-x {
  overflow-x: auto;
}
.white-color {
  color: $white-color;
}
.router-link-active .application-option {
  padding: 5px 10px;
  background: #eef4fa;
  border-radius: 8px 8px 0px 0px;
  color: #020038;
}
.application-option-inactive {
  background: rgba(2, 0, 56, 0.2);
  border-radius: 8px 8px 0px 0px;
  color: $white-color;
  padding: 5px 10px;
}

.txt-no-wrap {
  white-space: nowrap !important;
}

.h-100 {
  height: 100%;
}
</style>









