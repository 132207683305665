<template>
  <div>
    <div class="flex align-items-center justify-content end">
      <p :class="textAlign" class="label p-col-6">
        State <span style="color: red"> * </span>
      </p>
      <Dropdown
        class="p-col-6"
        width="100%"
        height="30px"
        placeholder="please select"
        type="text"
        optionLabel="name"
        optionValue="value"
        :value="formData?.state"
        :key="refreshKey"
        :items="statesList"
        @change="getFormData($event, 'state')"
        :inValid="v$.formData.state.$invalid && onSave"
        id="incoming_state"
      />
    </div>
    <div class="flex align-items-center justify-content end mt-1">
      <p :class="textAlign" class="label p-col-6">
        PostCode <span style="color: red"> * </span>
      </p>
      <div class="p-col-6">
        <NumberField
          :key="refreshKey2"
          width="100%"
          height="30px"
          placeholder="Enter your postcode"
          :value="formData?.postal_code"
          @onInput="getFormData($event, 'postal_code')"
          :inValid="v$.formData.postal_code.$invalid && onSave"
          :maxValue="4"
          id="incoming_post"
        />
      </div>
    </div>
    <div class="flex align-items-center justify-content end mt-1">
      <p :class="textAlign" class="label p-col-6">
        Purchase Price $ <span style="color: red"> * </span>
      </p>
      <div class="p-col-6">
        <NumberField
          :key="refreshKey2"
          width="100%"
          height="30px"
          placeholder="Enter amount"
          :value="formData?.purchase_price"
          @onInput="getFormData($event, 'purchase_price')"
          :inValid="v$.formData.purchase_price.$invalid && onSave"
          :maxValue="9"
          prefix="$"
          id="incoming_purchase_price"
        />
      </div>
    </div>
    <div class="flex align-items-center justify-content end mt-1 disable-input">
      <p :class="textAlign" class="label p-col-6">Stamp Duty $</p>
      <div class="p-col-6">
        <NumberField
          width="100%"
          :disable="true"
          height="30px"
          placeholder="Enter amount"
          :value="formData?.stamp_duty"
          @onInput="getFormData($event, 'stamp_duty')"
          :key="refreshKey"
          :maxValue="9"
          prefix="$"
          id="incoming_stamp_duty"
        />
      </div>
    </div>
    <div class="flex align-items-center justify-content-center mt-1">
      <p :class="textAlign" class="label p-col-6">Settlement Date</p>
      <div class="p-col-6">
        <DatePicker2
          placeholder="DD/MM/YYYY"
          style="width: 100%"
          @change="getFormData($event, 'settlement_date')"
          :value="formatDate(formData?.settlement_date)"
          height="30px"
          :key="refreshKey2"
          :minDate="minDate"
          :maxYear="maxYear"
          :minYear="minYear"
          id="incoming_settlement_date"
        />
      </div>
    </div>
    <div class="flex align-items-center justify-content end mt-1">
      <p :class="textAlign" class="label p-col-6">
        When will stamp duty be paid? <span style="color: red"> * </span>
      </p>
      <div class="dropdown p-col-6">
        <Dropdown
          width="100%"
          height="30px"
          optionLabel="name"
          optionValue="value"
          :key="refreshKey"
          placeholder="Please select"
          :value="formData?.stamp_duty_status"
          :inValid="v$.formData.stamp_duty_status.$invalid && onSave"
          :items="stampTo"
          @change="getFormData($event, 'stamp_duty_status')"
          id="incoming_stamp_duty_status"
        />
      </div>
    </div>

    <div class="flex align-items-center justify-content end mt-1">
      <p :class="textAlign" class="label p-col-6">
        How will the deposit be paid? <span style="color: red"> * </span>
      </p>
      <div class="dropdown p-col-6">
        <Dropdown
          width="100%"
          height="30px"
          placeholder="Please select"
          :items="depositPaid"
          :key="refreshKey"
          optionLabel="name"
          optionValue="value"
          :inValid="v$.formData.deposit_been_paid.$invalid && onSave"
          :value="formData?.deposit_been_paid"
          @change="getFormData($event, 'deposit_been_paid')"
          id="incoming_deposit_been_paid"
        />
      </div>
    </div>
    <div class="flex align-items-center justify-content-center mt-1">
      <p :class="textAlign" class="label p-col-6">
        Deposit Amount $ <span style="color: red"> * </span>
      </p>
      <div class="p-col-6">
        <NumberField
          width="100%"
          height="30px"
          placeholder="Enter amount"
          :inValid="v$.formData.deposit_amount.$invalid && onSave"
          :value="formData?.deposit_amount"
          @onInput="getFormData($event, 'deposit_amount')"
          :maxValue="9"
          :key="refreshKey2"
          prefix="$"
          id="incoming_deposit_amount"
        />
      </div>
    </div>
    <div class="flex align-items-center justify-content-center mt-1 disable-input">
      <p :class="textAlign" class="label p-col-6">Property Zone</p>
      <TextField
        class="p-col-6"
        width="100%"
        :value="formData?.zone.replace(/_/g, ' ')"
        :disable="true"
        height="30px"
        placeholder="property zone"
        @onInput="getFormData($event, 'zone')"
        :key="refreshKey"
        id="incoming_zone"
      />
    </div>
    <div class="flex align-items-center justify-content-center mt-1">
      <p :class="textAlign" class="label p-col-6">
        Property Type <span style="color: red"> * </span>
      </p>
      <Dropdown
        class="p-col-6"
        width="100%"
        height="30px"
        :value="formData?.type"
        :items="propertyType"
        optionLabel="name"
        optionValue="value"
        placeholder="please select"
        :inValid="v$.formData.type.$invalid && onSave"
        :key="refreshKey"
        @change="getFormData($event, 'type')"
        id="incoming_type"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SERVER_URL_2 } from "../../../../../constants";
import { required, maxValue, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { _get, _post } from "../../../../services/crudService";
import moment from "moment";

export default {
  props: ["properties", "id", "fieldValues", "responseValue"],
  data() {
    return {
      refreshKey: 10,
      refreshKey2: 10,
      minDate: null,
      maxYear: null,
      minYear: null,
      statesList: [
        { name: "NSW", value: "nsw" },
        { name: "VIC", value: "vic" },
        { name: "WA", value: "wa" },
        { name: "QLD", value: "qld" },
        { name: "SA", value: "sa" },
        { name: "TS", value: "ts" },
      ],
      depositPaid: [
        { name: "Cash", value: "cash" },
        { name: "Deposit bond", value: "bond" },
      ],
      propertyType: [
        { name: "Residential", value: "residential" },
        { name: "Rural Residential", value: "rural_residential" },
        { name: "Off the plan Residential", value: "off_the_plan_residential" },
        { name: "Commercial", value: "commercial" },
        { name: "Vacant Land", value: "vacant_land" },
        { name: "Retirement, Land Lease, or Community home", value: "retirement_land_lease_or_community_home" }
      ],
      stampTo: [
        { name: "To be paid on settlement", value: "at_settlement" },
        {
          name: "To be paid prior to settlement",
          value: "prior_to_settlement",
        },
        { name: "Already paid", value: "already_paid" },
      ],
      formData: {
        id: this.$props.id,
        state: "",
        postal_code: "",
        purchase_price: 0,
        stamp_duty: "",
        settlement_date: "",
        stamp_duty_status: "",
        deposit_been_paid: "",
        deposit_amount: 0,
        zone: "",
        type: "",
        category: "",
      },
    };
  },
  watch: {
    // responseValue(result) {
    //   this.refreshKey++;

    //   for (const [key, value] of Object.entries(result)) {
    //     if (typeof value === "number") {
    //       this.formData[key] = value.toFixed(2);
    //     } else {
    //       this.formData[key] = value;
    //     }
    //   }
    // },
    fieldValues() {
      this.formData = this.$props.fieldValues;
      this.refreshKey++;
      this.refreshKey2++;
      this.$emit("validateIncomingProp", this.v$.$invalid);
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        state: { required },
        postal_code: { required, minLength: minLength(3) },
        purchase_price: { required },
        stamp_duty_status: { required },
        deposit_been_paid: { required },
        deposit_amount: { required, maxValue: maxValue(999999999) },
        type: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      onSave: "servicingCalc/onSave",
    }),
    textAlign() {
      if (this.$props.properties === 1) {
        return "text-al-start";
      } else {
        return "text-al-end";
      }
    },
  },
  methods: {
    ...mapActions({
      addIncomingProperties: "servicingCalc/addIncomingProperties",
    }),
    formatDate(date) {
      let d = new Date(date);

      if (d != "Invalid Date") {
        if (date) {
          return moment(date).format("DD/MM/YYYY");
        }
      }
      return date;
    },
    async getFormData(value, fieldName) {
      if (value == "Invalid Date") return;

      this.formData[fieldName] = value;
      this.formData = { ...this.formData };

      if (fieldName === "postal_code" && this.formData.postal_code > 999) {
        try {
          const result = await _get(SERVER_URL_2 + `post_code?post_code=${value}`);
          if (result) {
            this.$emit("validateIncomingProp", this.v$.$invalid);

            this.formData.zone = result.data.result.sp_category
              .replace("-", "_")
              .toLowerCase();
            this.formData.category = result.data.result.category;

            this.refreshKey++;
          }
        } catch (error) {
          this.$emit("validateIncomingProp", true);
          this.formData.zone = "";
          this.refreshKey++;
        }
      } else {
        this.$emit("validateIncomingProp", this.v$.$invalid);
      }

      // if (this.formData.stamp_duty_status !== "at_settlement") {
      //   this.formData.stamp_duty = 0;
      //   this.refreshKey++;
      // }

      if (fieldName === "state" || fieldName === "purchase_price") {
        try {
          const stampDuty = await _post(SERVER_URL_2 + "find-stamp-duty", {
            purchase_price: this.formData.purchase_price,
            state: this.formData.state,
          });

          if (stampDuty) {
            this.formData.stamp_duty = stampDuty.data.result.toString();
            this.refreshKey++;
            this.$emit("validateIncomingProp", this.v$.$invalid);
          }
        } catch (error) {
          this.$emit("validateIncomingProp", true);
        }
      }

      if (fieldName === "purchase_price") {
        if (this.formData.purchase_price < this.formData.deposit_amount) {
          this.$emit("validateIncomingProp", true);
        }
      }

      if (fieldName === "deposit_amount") {
        if (this.formData.purchase_price < this.formData.deposit_amount) {
          this.$emit("validateIncomingProp", true);
        }
      }

      this.addIncomingProperties({
        value,
        fieldName,
        id: this.$props.id,
        stampDuty: this.formData.stamp_duty.toString(),
        zone: this.formData.zone,
        category: this.formData.category,
        purchase_price_used: this.formData.purchase_price,
        type_used: this.formData.type,
      });
    },
  },
  created() {
    this.minDate = new Date();
    this.minYear = new Date().getFullYear();
    this.maxYear = this.minYear + 50;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

.text-al-end {
  text-align: end;
}

.text-al-start {
  text-align: start;
}

:deep .dropdown .p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
  line-height: 15px;
}

:deep .p-dropdown .p-dropdown-label {
  line-height: 15px;
}

.label {
  padding-right: 0;
  line-height: 19px !important;
  font-size: 14px;
  font-family: Gordita-medium;
  color: $secondary-color;
}

:deep .p-col-6 {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
