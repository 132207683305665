<template>
  <div>
    <div class="std-padding-rv" v-if="!isRoute">
      <h1 class="module-title hk-b">Brokers</h1>
      <BrokerList @changes="changeRoute" class="mt-5" />
    </div>
    <BrokerData v-else />
  </div>
</template>

<script>
import BrokerList from "./BrokerLists.vue";
import BrokerData from "./BrokerData/index.vue";

export default {
  name: "index",
  components: {
    BrokerList,
    BrokerData,
  },
  data() {
    return {
      isRoute: false,
    };
  },
  watch: {
    $route() {
      if (this.$route.name === "Broker") {
        this.isRoute = false;
      } else {
        this.isRoute = true;
      }
    },
  },
  methods: {
    changeRoute({ id, mobile, email, firstName }) {
      this.isRoute = true;
      this.$router.push(
        `/brokers/${id}/detail?mobile=` +
          mobile +
          "&email=" +
          email +
          "&firstName=" +
          firstName
      );
    },
  },
  created() {
    if (this.$route.fullPath == "/brokers") {
      this.isRoute = false;
    } else {
      this.isRoute = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
</style>
