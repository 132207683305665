// import Vue from 'vue'
import { createStore } from "vuex"
import createPersistedState from "vuex-persistedstate"
import customers from "./customers"
import brokers from "./Brokers"
import servicingCalc from "./ServicingCalc"
import auth from "./Auth"
import applications from "./Applications";
import summary from "./Summary";
import loans from './Loan'
import subTotal from "./SubTotal"
import loanDescription from "./LoanDescription"
import group from './group'
import user from './user'
import loanOverview from './LoanOverview'
// import { SERVER_URL } from "./constants"

export default createStore({
  modules: {
    auth,
    customers,
    brokers,
    servicingCalc,
    applications,
    loans,
    summary,
    subTotal,
    loanDescription,
    group,
    user, loanOverview
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      paths: ["customers", "auth"],
    }),
  ],
})
