<template>
  <Card class="full-width box-shadow">
    <template #content>
      <div class="full-width flex justify-content-between">
        <span class="p-input-icon-left full-width" v-if="!isListActive">
          <i class="pi pi-search" />
          <InputText
            style="width: 95%"
            type="text"
            placeholder="Search pipeline"
            @input="getSearch"
            :key="searchKey"
            v-if="!isListActive"
          />
        </span>
        <div v-if="isListActive"></div>
        <div class="flex align-items-center">
          <img
            @click="onClickStage"
            v-if="!isStageActive"
            class="ml-3 pointer"
            src="@/assets/Stage-Inactive.svg"
          />
          <img v-else class="ml-3" src="@/assets/Stage-Active.svg" />
          <img
            @click="onClickList"
            v-if="!isListActive"
            class="ml-3 pointer"
            src="@/assets/Task-Inactive.svg"
          />
          <img v-else class="ml-3" src="@/assets/Task-Active.svg" />
          <img
            @click="onClickSidebar"
            v-if="!isSidebarActive"
            class="ml-3 pointer"
            src="@/assets/List-Inactive.svg"
          />
          <img v-else class="ml-3" src="@/assets/List-Active.svg" />
        </div>
      </div>
      <div class="flex options justify-content-start mt-3">
        <div class="col-3 flex align-items-center flex-gap">
          <div class="filter-title ml-2">Owner</div>
          <Dropdown
            width="150px"
            :items="ownerList"
            placeholder="Select Owner"
            optionLabel="assignedToType"
            optionValue="application_id"
            @change="selectedFilters($event, 'owner')"
          />
        </div>
        <div v-if="applications" class="col-3 flex align-items-center flex-gap">
          <div class="filter-title ml-2">Status</div>
          <Dropdown
            width="150px"
            :items="statusList"
            placeholder="Select Status"
            optionLabel="viewValue"
            optionValue="value"
            :value="selectedStatus"
            @change="selectedFilters($event, 'loan_status')"
          />
        </div>
        <div class="col-3 flex align-items-center flex-gap">
          <div class="filter-title ml-2">Tags</div>
          <Dropdown
            width="150px"
            :items="tagsList"
            placeholder="Select Tags"
            optionLabel="viewValue"
            optionValue="value"
            @change="selectedFilters($event, 'tags')"
          />
        </div>
        <div v-if="!isGridActive" class="col-3">
          <div
            v-if="applications && !isStageActive"
            class="flex align-items-center flex-gap"
          >
            <div class="filter-title ml-2">Stage</div>
            <Dropdown
              width="150px"
              :items="statgeList"
              placeholder="Select Stage"
              optionLabel="viewValue"
              optionValue="value"
              @change="selectedFilters($event, 'crm_status')"
            />
          </div>
        </div>
      </div>
      <Loans
        :isloading="isloading"
        :selectedOpt="selectedOpt"
        class="mt-5"
        v-if="isSidebarActive"
        :filterField="filterField"
        :filterValue="filterValue"
        :refreshKey="refreshKey1"
        :searchTerm="searchTerm"
      />
      <Tasks
        :isloading="isloading"
        :selectedOpt="selectedOpt"
        :filterField="filterField"
        :filterValue="filterValue"
        :refreshKey="refreshKey"
        v-if="isListActive"
      />
      <Spinner v-if="isLoading" class="mt-3 flex justif-content-between" />
      <div v-else>
        <div
          v-if="isStageActive && funded"
          class="stage overflow-x-auto flex-gap-10 flex align-items"
        >
          <Stage
            title="Funded"
            id="fundedStage"
            :lists="loan_funded_app"
            status="loan_funded"
          />
          <Stage
            title="Funded: 1 month"
            :lists="loan_funded_1"
            status="loan_funded_month_1"
            id="fundedStage1"
          />
          <Stage
            title="Funded: 2 month"
            :lists="loan_funded_2"
            status="loan_funded_month_2"
            id="fundedStage2"
          />
          <Stage
            title="Funded: 3 month"
            :lists="loan_funded_3"
            status="loan_funded_month_3"
            id="fundedStage3"
          />
          <Stage
            title="Funded: 4 month"
            :lists="loan_funded_4"
            status="loan_funded_month_5"
            id="fundedStage4"
          />
          <Stage
            title="Funded: 5 month"
            :lists="loan_funded_5"
            status="loan_funded_month_5"
            id="fundedStage5"
          />
          <Stage title="Arrears" :lists="arrears_app" status="arrears" id="arrearStage" />
        </div>

        <div
          v-if="isStageActive && arrears"
          class="stage overflow-x-auto flex-gap-10 flex align-items"
        >
          <Stage
            title="Arrears"
            id="arrearStage2"
            :lists="arrears_app"
            status="arrears"
          />
        </div>

        <div
          v-if="isStageActive && applications && !isloading"
          class="stage overflow-x-auto flex-gap-10 flex align-items"
        >
          <Stage
            @changeStage="changeStage"
            :event="restrictUser('ModifyExistingPipeline') && 'dragover'"
            title="Application in progress"
            :lists="application_in_progress"
            status="application_in_progress"
            id="applicationInProgress"
          />
          <Stage
            @changeStage="changeStage"
            :event="restrictUser('ModifyExistingPipeline') && 'dragover'"
            title="Assessment ready"
            :lists="assessment_ready"
            status="assessment_ready"
            id="assessmentReady"
          />
          <Stage
            @changeStage="changeStage"
            title="Workshopping application"
            :lists="workshopping_application"
            :event="restrictUser('ModifyExistingPipeline') && 'dragover'"
            :isEmpty="noAppInWorkshopping"
            status="workshopping_application"
            id="workshoppingApplication"
          />
          <Stage
            title="Pre-approval"
            @changeStage="changeStage"
            :event="restrictUser('ModifyExistingPipeline') && 'dragover'"
            :lists="pre_approval"
            :isEmpty="noAppInPreApproval"
            status="pre-approval"
            id="preApproval"
          />
          <Stage
            @changeStage="changeStage"
            title="Conditional offer"
            :event="restrictUser('ModifyExistingPipeline') && 'dragover'"
            :lists="conditional_offer"
            status="conditional_offer"
            id="conditionalOffer"
          />
          <Stage
            title="Supporting Documents"
            @changeStage="changeStage"
            :event="restrictUser('ModifyExistingPipeline') && 'dragover'"
            :lists="supporting_documents"
            status="supporting_documents"
            id="supportingDocuments"
          />
          <Stage
            title="Unconditional offer"
            :lists="unconditional_offer"
            @changeStage="changeStage"
            :event="restrictUser('ModifyExistingPipeline') && 'dragover'"
            status="unconditional_offer"
            id="unconditionalOffer"
          />
          <Stage
            title="Solicitors instructed"
            @changeStage="changeStage"
            :event="restrictUser('ModifyExistingPipeline') && 'dragover'"
            :lists="solicitor_instructed"
            status="solicitor_instructed"
            id="solicitorInstructed"
          />
          <Stage
            title="Document Issued"
            :event="restrictUser('ModifyExistingPipeline') && 'dragover'"
            @changeStage="changeStage"
            :lists="document_issued"
            status="document_issued"
            id="documentIssued"
          />
          <Stage
            title="Document Received"
            :event="restrictUser('ModifyExistingPipeline') && 'dragover'"
            @changeStage="changeStage"
            :lists="document_received"
            status="document_received"
            id="documentReceived"
          />
          <Stage
            :event="restrictUser('ModifyExistingPipeline') && 'dragover'"
            title="Settlement Booked"
            @changeStage="changeStage"
            :lists="settlement_booked"
            status="settlement_booked"
            id="settlementBooked"
          />
          <Stage
            @changeStage="changeStage"
            title="Funded"
            :event="restrictUser('ModifyExistingPipeline') && 'dragover'"
            :lists="loan_funded"
            id="loanFunded"
            status="loan_funded"
          />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Loans from "./Loans.vue";
import Tasks from "./Tasks/index.vue";
import Stage from "./Stage/index.vue";
import { _get, _put } from "../../../services/crudService";
import { APP_URL, SERVER_URL } from "../../../../constants";
import { mapGetters, mapActions } from "vuex";
import { debounce } from "debounce";

export default {
  name: "Pipeline",
  props: ["applications", "arrears", "funded", "selectedOpt", "isloading"],
  components: {
    Card,
    InputText,
    Loans,
    Tasks,
    Stage,
  },
  data() {
    return {
      isListActive: false,
      isSidebarActive: false,
      isStageActive: true,
      isLoading: false,
      assessment_ready: [],
      application_in_progress: [],
      workshopping_application: [],
      pre_approval: [],
      conditional_offer: [],
      supporting_documents: [],
      unconditional_offer: [],
      solicitor_instructed: [],
      document_received: [],
      document_issued: [],
      settlement_booked: [],
      loan_funded: [],
      loan_funded_1: [],
      loan_funded_2: [],
      loan_funded_3: [],
      loan_funded_4: [],
      loan_funded_5: [],
      arrears_app: [],
      loan_funded_app: [],
      ownerList: [],
      selectedStatus: "open",
      statusList: [
        { viewValue: "All", value: "all" },
        { viewValue: "Open", value: "open" },
        {
          viewValue: "On Hold",
          value: "on_hold",
        },
        { viewValue: "Repaid", value: "repaid" },
        { viewValue: "Dropped off", value: "dropped_off" },
        { viewValue: "Withdrawn", value: "withdrawn" },
        { viewValue: "Rejected", value: "rejected" },
      ],
      tagsList: [
        { viewValue: "All", value: "all" },
        { viewValue: "VIP", value: "vip" },
        { viewValue: "High risk", value: "high_risk" },
      ],
      statgeList: [
        { viewValue: "Applicaton submission", value: "application_submission" },
        {
          viewValue: "Workshopping application",
          value: "workshopping_application",
        },
        { viewValue: "Pre-approval", value: "pre_approval" },
        { viewValue: "Conditional offer", value: "conditional_offer" },
        { viewValue: "Supporting document", value: "supporting_document" },
        { viewValue: "Unconditional offer", value: "unconditional_offer" },
        { viewValue: "Solicitors", value: "solicitors" },
        { viewValue: "Document", value: "document" },
        { viewValue: "Loan settlement", value: "loan_settlement" },
        { viewValue: "Loan funded", value: "loan_funded" },
        { viewValue: "App Stage", value: "app_stage" },
      ],
      lists: [],
      stageList: [],
      filterField: "loan_status",
      filterValue: "open",
      refreshKey: 1,
      searchTerm: "",
      refreshKey1: 20,
    };
  },
  watch: {
    selectedOpt(value) {
      if (value === "Funded" || value === "Arrears") {
        this.fundedStage();
      } else if (value === "Applications") {
        this.appStage();
      }
    },
  },
  computed: {
    ...mapGetters({ condition: "auth/userPermission" }),
  },
  methods: {
    ...mapActions({
      setLoanCreatedAt: "applications/setLoanCreatedAt",
    }),
    restrictUser(value) {
      return this.condition[value];
    },
    getSearch: debounce(async function (e) {
      this.searchTerm = e?.target?.value;
      if (this.isListActive) this.refreshKey1 += 1;
      else if (this.isStageActive) await this.appStage();
    }, 1000),
    onClickStage() {
      this.searchTerm = "";
      this.searchKey += 1;
      this.isStageActive = true;
      this.isListActive = false;
      this.isSidebarActive = false;
    },
    onClickList() {
      this.searchTerm = "";
      this.searchKey += 1;
      this.isStageActive = false;
      this.isListActive = true;
      this.isSidebarActive = false;
    },
    onClickSidebar() {
      this.searchTerm = "";
      this.searchKey += 1;
      this.isStageActive = false;
      this.isListActive = false;
      this.isSidebarActive = true;
    },
    async changeStage(value) {
      let data = {};
      console.log(value);

      this.stageList.map(async (list) => {
        if (list.Id === value.id) {
          if (
            list.crm_status === "application_in_progress" &&
            value.status === "assessment_ready"
          ) {
            data["crm_status"] = list.crm_status;
            data["status"] = list.status;

            for (let i = 0; i < this.application_in_progress.length; i++) {
              if (this.application_in_progress[i].Id === list.Id) {
                this.application_in_progress[i].crm_status = value.status;

                this.assessment_ready.unshift(this.application_in_progress[i]);
                this.application_in_progress.splice(i, 1);
              }
            }

            try {
              data["id"] = value.id;
              const response = await _put(
                SERVER_URL +
                  `update_application_pipeline_status?id=${data.id}&status=${data.status}&crm_status=${data.crm_status}`
              );

              if (response) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: "Application moved successfully",
                  life: 3000,
                });
              }
            } catch (error) {
              for (let i = 0; i < this.assessment_ready.length; i++) {
                if (this.assessment_ready[i].Id === list.Id) {
                  this.assessment_ready[i].crm_status = value.status;

                  this.application_in_progress.unshift(this.assessment_ready[i]);
                  this.assessment_ready.splice(i, 1);
                }
              }
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: "This application has pending task",
                life: 3000,
              });
              return;
            }

            return;
          } else if (
            list.crm_status === "assessment_ready" &&
            value.status === "workshopping_application"
          ) {
            data["crm_status"] = list.crm_status;
            data["status"] = list.status;
            for (let i = 0; i < this.assessment_ready.length; i++) {
              if (this.assessment_ready[i].Id === list.Id) {
                this.assessment_ready[i].crm_status = value.status;

                this.workshopping_application.unshift(this.assessment_ready[i]);
                this.assessment_ready.splice(i, 1);
              }
            }

            try {
              data["id"] = value.id;
              const response = await _put(
                SERVER_URL +
                  `update_application_pipeline_status?id=${data.id}&status=${data.status}&crm_status=${data.crm_status}`
              );

              if (response) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: "Application moved successfully",
                  life: 3000,
                });
              }
            } catch (error) {
              for (let i = 0; i < this.workshopping_application.length; i++) {
                if (this.workshopping_application[i].Id === list.Id) {
                  this.workshopping_application[i].crm_status = value.status;

                  this.assessment_ready.unshift(this.workshopping_application[i]);
                  this.workshopping_application.splice(i, 1);
                }
              }
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: "This application has pending task",
                life: 3000,
              });
              return;
            }
            return;
          } else if (
            list.crm_status === "workshopping_application" &&
            value.status === "pre-approval"
          ) {
            data["crm_status"] = list.crm_status;
            data["status"] = list.status;
            for (let i = 0; i < this.workshopping_application.length; i++) {
              if (this.workshopping_application[i].Id === list.Id) {
                this.workshopping_application[i].crm_status = value.status;

                this.pre_approval.unshift(this.workshopping_application[i]);
                this.workshopping_application.splice(i, 1);
              }
            }

            try {
              data["id"] = value.id;
              const response = await _put(
                SERVER_URL +
                  `update_application_pipeline_status?id=${data.id}&status=${data.status}&crm_status=${data.crm_status}`
              );

              if (response) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: "Application moved successfully",
                  life: 3000,
                });
              }
            } catch (error) {
              for (let i = 0; i < this.pre_approval.length; i++) {
                if (this.pre_approval[i].Id === list.Id) {
                  this.pre_approval[i].crm_status = value.status;

                  this.workshopping_application.unshift(this.pre_approval[i]);
                  this.pre_approval.splice(i, 1);
                }
              }
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: "This application has pending task",
                life: 3000,
              });
            }
            return;
          } else if (
            list.crm_status === "workshopping_application" &&
            value.status === "conditional_offer"
          ) {
            data["crm_status"] = list.crm_status;
            data["status"] = list.status;
            for (let i = 0; i < this.workshopping_application.length; i++) {
              if (this.workshopping_application[i].Id === list.Id) {
                this.workshopping_application[i].crm_status = value.status;

                this.conditional_offer.unshift(this.workshopping_application[i]);
                this.workshopping_application.splice(i, 1);
              }
            }

            try {
              data["id"] = value.id;
              const response = await _put(
                SERVER_URL +
                  `update_application_pipeline_status?id=${data.id}&status=${data.status}&crm_status=${data.crm_status}`
              );

              if (response) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: "Application moved successfully",
                  life: 3000,
                });
              }
            } catch (error) {
              for (let i = 0; i < this.conditional_offer.length; i++) {
                if (this.conditional_offer[i].Id === list.Id) {
                  this.conditional_offer[i].crm_status = value.status;

                  this.workshopping_application.unshift(this.conditional_offer[i]);
                  this.conditional_offer.splice(i, 1);
                }
              }
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: "This application has pending task",
                life: 3000,
              });
            }
            return;
          } else if (
            list.crm_status === "pre-approval" &&
            value.status === "conditional_offer"
          ) {
            data["crm_status"] = list.crm_status;
            data["status"] = list.status;
            for (let i = 0; i < this.pre_approval.length; i++) {
              if (this.pre_approval[i].Id === list.Id) {
                this.pre_approval[i].crm_status = value.status;

                this.conditional_offer.unshift(this.pre_approval[i]);
                this.pre_approval.splice(i, 1);
              }
            }

            try {
              data["id"] = value.id;
              const response = await _put(
                SERVER_URL +
                  `update_application_pipeline_status?id=${data.id}&status=${data.status}&crm_status=${data.crm_status}`
              );

              if (response) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: "Application moved successfully",
                  life: 3000,
                });
              }
            } catch (error) {
              for (let i = 0; i < this.conditional_offer.length; i++) {
                if (this.conditional_offer[i].Id === list.Id) {
                  this.conditional_offer[i].crm_status = value.status;

                  this.pre_approval.unshift(this.conditional_offer[i]);
                  this.conditional_offer.splice(i, 1);
                }
              }
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: "This application has pending task",
                life: 3000,
              });
            }
            return;
          } else if (
            list.crm_status === "conditional_offer" &&
            value.status === "supporting_documents"
          ) {
            data["crm_status"] = list.crm_status;
            data["status"] = list.status;
            for (let i = 0; i < this.conditional_offer.length; i++) {
              if (this.conditional_offer[i].Id === list.Id) {
                this.conditional_offer[i].crm_status = value.status;

                this.supporting_documents.unshift(this.conditional_offer[i]);
                this.conditional_offer.splice(i, 1);
              }
            }

            try {
              data["id"] = value.id;
              const response = await _put(
                SERVER_URL +
                  `update_application_pipeline_status?id=${data.id}&status=${data.status}&crm_status=${data.crm_status}`
              );

              if (response) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: "Application moved successfully",
                  life: 3000,
                });
              }
            } catch (error) {
              for (let i = 0; i < this.supporting_documents.length; i++) {
                if (this.supporting_documents[i].Id === list.Id) {
                  this.supporting_documents[i].crm_status = value.status;

                  this.conditional_offer.unshift(this.supporting_documents[i]);
                  this.supporting_documents.splice(i, 1);
                }
              }
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: "This application has pending task",
                life: 3000,
              });
            }
            return;
          } else if (
            list.crm_status === "supporting_documents" &&
            value.status === "unconditional_offer"
          ) {
            data["crm_status"] = list.crm_status;
            data["status"] = list.status;
            for (let i = 0; i < this.supporting_documents.length; i++) {
              if (this.supporting_documents[i].Id === list.Id) {
                this.supporting_documents[i].crm_status = value.status;

                this.unconditional_offer.unshift(this.supporting_documents[i]);
                this.supporting_documents.splice(i, 1);
              }
            }

            try {
              data["id"] = value.id;
              const response = await _put(
                SERVER_URL +
                  `update_application_pipeline_status?id=${data.id}&status=${data.status}&crm_status=${data.crm_status}`
              );

              if (response) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: "Application moved successfully",
                  life: 3000,
                });
              }
            } catch (error) {
              for (let i = 0; i < this.unconditional_offer.length; i++) {
                if (this.unconditional_offer[i].Id === list.Id) {
                  this.unconditional_offer[i].crm_status = value.status;

                  this.supporting_documents.unshift(this.unconditional_offer[i]);
                  this.unconditional_offer.splice(i, 1);
                }
              }
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: "This application has pending task",
                life: 3000,
              });
            }
            return;
          } else if (
            list.crm_status === "unconditional_offer" &&
            value.status === "solicitor_instructed"
          ) {
            data["crm_status"] = list.crm_status;
            data["status"] = list.status;
            for (let i = 0; i < this.unconditional_offer.length; i++) {
              if (this.unconditional_offer[i].Id === list.Id) {
                this.unconditional_offer[i].crm_status = value.status;

                this.solicitor_instructed.unshift(this.unconditional_offer[i]);
                this.unconditional_offer.splice(i, 1);
              }
            }

            try {
              data["id"] = value.id;
              const response = await _put(
                SERVER_URL +
                  `update_application_pipeline_status?id=${data.id}&status=${data.status}&crm_status=${data.crm_status}`
              );

              if (response) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: "Application moved successfully",
                  life: 3000,
                });
              }
            } catch (error) {
              for (let i = 0; i < this.solicitor_instructed.length; i++) {
                if (this.solicitor_instructed[i].Id === list.Id) {
                  this.solicitor_instructed[i].crm_status = value.status;

                  this.unconditional_offer.unshift(this.solicitor_instructed[i]);
                  this.solicitor_instructed.splice(i, 1);
                }
              }
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: "This application has pending task",
                life: 3000,
              });
            }
            return;
          } else if (
            list.crm_status === "solicitor_instructed" &&
            value.status === "document_issued"
          ) {
            data["crm_status"] = list.crm_status;
            data["status"] = list.status;
            for (let i = 0; i < this.solicitor_instructed.length; i++) {
              if (this.solicitor_instructed[i].Id === list.Id) {
                this.solicitor_instructed[i].crm_status = value.status;

                this.document_issued.unshift(this.solicitor_instructed[i]);
                this.solicitor_instructed.splice(i, 1);
              }
            }
            try {
              data["id"] = value.id;
              const response = await _put(
                SERVER_URL +
                  `update_application_pipeline_status?id=${data.id}&status=${data.status}&crm_status=${data.crm_status}`
              );

              if (response) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: "Application moved successfully",
                  life: 3000,
                });
              }
            } catch (error) {
              for (let i = 0; i < this.document_issued.length; i++) {
                if (this.document_issued[i].Id === list.Id) {
                  this.document_issued[i].crm_status = value.status;

                  this.solicitor_instructed.unshift(this.document_issued[i]);
                  this.document_issued.splice(i, 1);
                }
              }
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: "This application has pending task",
                life: 3000,
              });
            }
            return;
          } else if (
            list.crm_status === "document_issued" &&
            value.status === "document_received"
          ) {
            data["crm_status"] = list.crm_status;
            data["status"] = list.status;
            for (let i = 0; i < this.document_issued.length; i++) {
              if (this.document_issued[i].Id === list.Id) {
                this.document_issued[i].crm_status = value.status;

                this.document_received.unshift(this.document_issued[i]);
                this.document_issued.splice(i, 1);
              }
            }

            try {
              data["id"] = value.id;
              const response = await _put(
                SERVER_URL +
                  `update_application_pipeline_status?id=${data.id}&status=${data.status}&crm_status=${data.crm_status}`
              );

              if (response) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: "Application moved successfully",
                  life: 3000,
                });
              }
            } catch (error) {
              for (let i = 0; i < this.document_received.length; i++) {
                if (this.document_received[i].Id === list.Id) {
                  this.document_received[i].crm_status = value.status;

                  this.document_issued.unshift(this.document_received[i]);
                  this.document_received.splice(i, 1);
                }
              }
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: "This application has pending task",
                life: 3000,
              });
            }
            return;
          } else if (
            list.crm_status === "document_received" &&
            value.status === "settlement_booked"
          ) {
            data["crm_status"] = list.crm_status;
            data["status"] = list.status;
            for (let i = 0; i < this.document_received.length; i++) {
              if (this.document_received[i].Id === list.Id) {
                this.document_received[i].crm_status = value.status;

                this.settlement_booked.unshift(this.document_received[i]);
                this.document_received.splice(i, 1);
              }
            }

            try {
              data["id"] = value.id;
              const response = await _put(
                SERVER_URL +
                  `update_application_pipeline_status?id=${data.id}&status=${data.status}&crm_status=${data.crm_status}`
              );

              if (response) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: "Application moved successfully",
                  life: 3000,
                });
              }
            } catch (error) {
              for (let i = 0; i < this.settlement_booked.length; i++) {
                if (this.settlement_booked[i].Id === list.Id) {
                  this.settlement_booked[i].crm_status = value.status;

                  this.document_received.unshift(this.settlement_booked[i]);
                  this.settlement_booked.splice(i, 1);
                }
              }
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: "This application has pending task",
                life: 3000,
              });
            }
            return;
          } else if (
            list.crm_status === "settlement_booked" &&
            value.status === "loan_funded"
          ) {
            data["crm_status"] = list.crm_status;
            data["status"] = list.status;
            for (let i = 0; i < this.settlement_booked.length; i++) {
              if (this.settlement_booked[i].Id === list.Id) {
                this.settlement_booked[i].crm_status = value.status;

                this.loan_funded.unshift(this.settlement_booked[i]);
                this.settlement_booked.splice(i, 1);
              }
            }

            try {
              data["id"] = value.id;
              const response = await _put(
                SERVER_URL +
                  `update_application_pipeline_status?id=${data.id}&status=${data.status}&crm_status=${data.crm_status}`
              );

              if (response) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: "Application moved successfully",
                  life: 3000,
                });
              }
            } catch (error) {
              for (let i = 0; i < this.loan_funded.length; i++) {
                if (this.loan_funded[i].Id === list.Id) {
                  this.loan_funded[i].crm_status = value.status;

                  this.settlement_booked.unshift(this.loan_funded[i]);
                  this.loan_funded.splice(i, 1);
                }
              }
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: "This application has pending task",
                life: 3000,
              });
            }
            return;
          }
        }
      });
    },
    appDeclaration(dec_1, dec_2, applicantCount) {
      if (applicantCount == 2) {
        if (dec_1 && dec_2) {
          return "2/2";
        } else if (dec_1) {
          return "1/2";
        } else {
          return "0/2";
        }
      } else if (applicantCount == 1) {
        if (dec_1) {
          return "1/1";
        } else {
          return "0/1";
        }
      } else {
        return "0/0";
      }
    },
    async appStage() {
      try {
        this.isLoading = true;
        const response = await _get(
          APP_URL +
            `applications_crm_by_group?field=${this.filterField}&value=${this.filterValue}&search=${this.searchTerm}`
        );

        if (response) {
          this.stageList = response.data.result;
        }
      } catch (err) {
        this.isLoading = false;
        console.log(err);

        this.isLoading = false;
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      }
      this.mapAppStage();

      this.isLoading = false;
    },
    async fundedStage() {
      try {
        this.isLoading = true;
        const response = await _get(
          APP_URL +
            `applications_crm_by_status?field=${this.filterField}&value=${this.filterValue}`
        );

        if (response) {
          this.stageList = response.data.result;
        }
      } catch (err) {
        this.isLoading = false;
        console.log(err);
        this.isLoading = false;
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      }

      this.mapLoanFunded();

      this.isLoading = false;
    },
    mapLoanFunded() {
      this.loan_funded_app = [];
      this.loan_funded_1 = [];
      this.loan_funded_2 = [];
      this.loan_funded_3 = [];
      this.loan_funded_4 = [];
      this.loan_funded_5 = [];
      this.arrears_app = [];

      this.stageList.map((list) => {
        if (list.crm_status === "loan_funded") {
          this.loan_funded_app.push(list);

          this.loan_funded_app.map((elem, index) => {
            const declaration =
              elem &&
              elem.applicants &&
              this.appDeclaration(
                elem.applicant_1_declared,
                elem.applicant_2_declared,
                elem.applicants.length
              );

            if (declaration == "1/1" || declaration == "2/2") {
              this.loan_funded_app.splice(index, 1);
              this.loan_funded_app.unshift(elem);
            }
          });
        }

        if (list.crm_status === "loan_funded_month_1") {
          this.loan_funded_1.push(list);

          this.loan_funded_1.map((elem, index) => {
            const declaration =
              elem &&
              elem.applicants &&
              this.appDeclaration(
                elem.applicant_1_declared,
                elem.applicant_2_declared,
                elem.applicants.length
              );

            if (declaration == "1/1" || declaration == "2/2") {
              this.loan_funded_1.splice(index, 1);
              this.loan_funded_1.unshift(elem);
            }
          });
        }

        if (list.crm_status === "loan_funded_month_2") {
          this.loan_funded_2.push(list);

          this.loan_funded_2.map((elem, index) => {
            const declaration =
              elem &&
              elem.applicants &&
              this.appDeclaration(
                elem.applicant_1_declared,
                elem.applicant_2_declared,
                elem.applicants.length
              );

            if (declaration == "1/1" || declaration == "2/2") {
              this.loan_funded_2.splice(index, 1);
              this.loan_funded_2.unshift(elem);
            }
          });
        }

        if (list.crm_status === "loan_funded_month_3") {
          this.loan_funded_3.push(list);

          this.loan_funded_3.map((elem, index) => {
            const declaration =
              elem &&
              elem.applicants &&
              this.appDeclaration(
                elem.applicant_1_declared,
                elem.applicant_2_declared,
                elem.applicants.length
              );

            if (declaration == "1/1" || declaration == "2/2") {
              this.loan_funded_3.splice(index, 1);
              this.loan_funded_3.unshift(elem);
            }
          });
        }

        if (list.crm_status === "loan_funded_month_4") {
          this.loan_funded_4.push(list);

          this.loan_funded_4.map((elem, index) => {
            const declaration =
              elem &&
              elem.applicants &&
              this.appDeclaration(
                elem.applicant_1_declared,
                elem.applicant_2_declared,
                elem.applicants.length
              );

            if (declaration == "1/1" || declaration == "2/2") {
              this.loan_funded_4.splice(index, 1);
              this.loan_funded_4.unshift(elem);
            }
          });
        }

        if (list.crm_status === "loan_funded_month_5") {
          this.loan_funded_5.push(list);

          this.loan_funded_5.map((elem, index) => {
            const declaration =
              elem &&
              elem.applicants &&
              this.appDeclaration(
                elem.applicant_1_declared,
                elem.applicant_2_declared,
                elem.applicants.length
              );

            if (declaration == "1/1" || declaration == "2/2") {
              this.loan_funded_5.splice(index, 1);
              this.loan_funded_5.unshift(elem);
            }
          });
        }

        if (list.crm_status === "arrears") {
          this.arrears_app.push(list);

          this.arrears_app.map((elem, index) => {
            const declaration =
              elem &&
              elem.applicants &&
              this.appDeclaration(
                elem.applicant_1_declared,
                elem.applicant_2_declared,
                elem.applicants.length
              );

            if (declaration == "1/1" || declaration == "2/2") {
              this.arrears_app.splice(index, 1);
              this.arrears_app.unshift(elem);
            }
          });
        }
      });

      this.loan_funded_app.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      this.loan_funded_1.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      this.loan_funded_2.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      this.loan_funded_3.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      this.loan_funded_4.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      this.loan_funded_5.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      this.arrears_app.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
    mapAppStage() {
      this.application_in_progress = [];
      this.workshopping_application = [];
      this.assessment_ready = [];
      this.conditional_offer = [];
      this.supporting_documents = [];
      this.unconditional_offer = [];
      this.solicitor_instructed = [];
      this.document_received = [];
      this.document_issued = [];
      this.settlement_booked = [];
      this.loan_funded = [];
      this.pre_approval = [];

      this.stageList.map((list) => {
        if (list.crm_status === "application_in_progress") {
          this.application_in_progress.push(list);

          this.application_in_progress.map((elem, index) => {
            const declaration =
              elem &&
              elem.applicants &&
              this.appDeclaration(
                elem.applicant_1_declared,
                elem.applicant_2_declared,
                elem.applicants.length
              );

            if (declaration == "1/1" || declaration == "2/2") {
              this.application_in_progress.splice(index, 1);
              this.application_in_progress.unshift(elem);
            }
          });
        }

        if (list.crm_status === "workshopping_application") {
          this.workshopping_application.push(list);

          this.workshopping_application.map((elem, index) => {
            const declaration = this.appDeclaration(
              elem.applicant_1_declared,
              elem.applicant_2_declared,
              elem.applicants.length
            );

            if (declaration == "1/1" || declaration == "2/2") {
              this.workshopping_application.splice(index, 1);
              this.workshopping_application.unshift(elem);
            }
          });
        }

        if (list.crm_status === "assessment_ready") {
          this.assessment_ready.push(list);

          this.assessment_ready.map((elem, index) => {
            const declaration =
              elem &&
              elem.applicants &&
              this.appDeclaration(
                elem.applicant_1_declared,
                elem.applicant_2_declared,
                elem.applicants.length
              );

            if (declaration == "1/1" || declaration == "2/2") {
              this.assessment_ready.splice(index, 1);
              this.assessment_ready.unshift(elem);
            }
          });
        }

        if (list.crm_status === "pre-approval") {
          this.pre_approval.push(list);

          this.pre_approval.map((elem, index) => {
            const declaration = this.appDeclaration(
              elem.applicant_1_declared,
              elem.applicant_2_declared,
              elem.applicants.length
            );

            if (declaration == "1/1" || declaration == "2/2") {
              this.pre_approval.splice(index, 1);
              this.pre_approval.unshift(elem);
            }
          });
        }

        if (list.crm_status === "conditional_offer") {
          this.conditional_offer.push(list);

          this.conditional_offer.map((elem, index) => {
            const declaration =
              elem &&
              elem.applicants &&
              this.appDeclaration(
                elem.applicant_1_declared,
                elem.applicant_2_declared,
                elem.applicants.length
              );

            if (declaration == "1/1" || declaration == "2/2") {
              this.conditional_offer.splice(index, 1);
              this.conditional_offer.unshift(elem);
            }
          });
        }

        if (list.crm_status === "supporting_documents") {
          this.supporting_documents.push(list);

          this.supporting_documents.map((elem, index) => {
            const declaration =
              elem &&
              elem.applicants &&
              this.appDeclaration(
                elem.applicant_1_declared,
                elem.applicant_2_declared,
                elem.applicants.length
              );

            if (declaration == "1/1" || declaration == "2/2") {
              this.supporting_documents.splice(index, 1);
              this.supporting_documents.unshift(elem);
            }
          });
        }

        if (list.crm_status === "unconditional_offer") {
          this.unconditional_offer.push(list);

          this.unconditional_offer.map((elem, index) => {
            const declaration =
              elem &&
              elem.applicants &&
              this.appDeclaration(
                elem.applicant_1_declared,
                elem.applicant_2_declared,
                elem.applicants.length
              );

            if (declaration == "1/1" || declaration == "2/2") {
              this.unconditional_offer.splice(index, 1);
              this.unconditional_offer.unshift(elem);
            }
          });
        }

        if (list.crm_status === "solicitor_instructed") {
          this.solicitor_instructed.push(list);

          this.solicitor_instructed.map((elem, index) => {
            const declaration =
              elem &&
              elem.applicants &&
              this.appDeclaration(
                elem.applicant_1_declared,
                elem.applicant_2_declared,
                elem.applicants.length
              );

            if (declaration == "1/1" || declaration == "2/2") {
              this.solicitor_instructed.splice(index, 1);
              this.solicitor_instructed.unshift(elem);
            }
          });
        }

        if (list.crm_status === "document_issued") {
          this.document_issued.push(list);

          this.document_issued.map((elem, index) => {
            const declaration =
              elem &&
              elem.applicants &&
              this.appDeclaration(
                elem.applicant_1_declared,
                elem.applicant_2_declared,
                elem.applicants.length
              );

            if (declaration == "1/1" || declaration == "2/2") {
              this.document_issued.splice(index, 1);
              this.document_issued.unshift(elem);
            }
          });
        }

        if (list.crm_status === "document_received") {
          this.document_received.push(list);

          this.document_received.map((elem, index) => {
            const declaration =
              elem &&
              elem.applicants &&
              this.appDeclaration(
                elem.applicant_1_declared,
                elem.applicant_2_declared,
                elem.applicants.length
              );

            if (declaration == "1/1" || declaration == "2/2") {
              this.document_received.splice(index, 1);
              this.document_received.unshift(elem);
            }
          });
        }

        if (list.crm_status === "settlement_booked") {
          this.settlement_booked.push(list);

          this.settlement_booked.map((elem, index) => {
            const declaration =
              elem &&
              elem.applicants &&
              this.appDeclaration(
                elem.applicant_1_declared,
                elem.applicant_2_declared,
                elem.applicants.length
              );

            if (declaration == "1/1" || declaration == "2/2") {
              this.settlement_booked.splice(index, 1);
              this.settlement_booked.unshift(elem);
            }
          });
        }

        if (list.crm_status === "loan_funded") {
          this.loan_funded.push(list);

          this.loan_funded.map((elem, index) => {
            const declaration =
              elem &&
              elem.applicants &&
              this.appDeclaration(
                elem.applicant_1_declared,
                elem.applicant_2_declared,
                elem.applicants.length
              );

            if (declaration == "1/1" || declaration == "2/2") {
              this.loan_funded.splice(index, 1);
              this.loan_funded.unshift(elem);
            }
          });
        }
      });

      // Filter loans by loan created date
      this.application_in_progress.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      this.workshopping_application.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      this.assessment_ready.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      this.conditional_offer.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      this.supporting_documents.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      this.unconditional_offer.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      this.solicitor_instructed.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      this.document_received.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      this.document_issued.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      this.settlement_booked.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      this.loan_funded.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      this.pre_approval.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
    selectedFilters(value, field) {
      this.filterField = field;
      this.filterValue = value;
      this.refreshKey += 1;
      if (this.isStageActive === true) {
        if (
          this.$props.selectedOpt === "Funded" ||
          this.$props.selectedOpt === "Arrears"
        ) {
          this.fundedStage();
        } else if (this.$props.selectedOpt === "Applications") {
          this.appStage();
          this.mapLoanFunded();
        }
      }
    },
    async getOwnersApplication() {
      try {
        this.ownerList = [];
        this.isLoading = true;
        const response = await _get(SERVER_URL + `get_owners_application`);
        if (response) {
          this.ownerList = response.data.result;
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
  },
  created() {
    this.appStage();
    this.getOwnersApplication();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.p-card {
  box-shadow: unset !important;
  border-radius: 0.25rem !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
}
::v-deep .p-card-title {
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem !important;
}

:deep .p-dropdown {
  line-height: 17px;
}

:deep .p-card-content {
  padding: 0px !important;
}

:deep .p-card-body {
  padding: 30px !important;
}

.filter-title {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 600;
  font-size: 1rem;
}

.options {
  background: rgba(2, 0, 56, 0.03);
  padding: 0 20px;
}

.overflow-x-auto {
  overflow-x: auto;
}

.pointer {
  cursor: pointer;
}

.flex-gap {
  gap: 15px;
}

.flex-gap-10 {
  gap: 10px;
}
</style>
