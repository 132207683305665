<template>
  <div class="checkbox-wrapper">
    <div :class="customClass" class="p-field-checkbox">
      <Checkbox
        :binary="true"
        :id="category && category.id"
        :name="name"
        v-model="value"
        @change="EmitToParent"
        :key="checkboxKey"
      />
      <label v-if="category" class="label-text" :for="category.id">{{
        category.label
      }}</label>
    </div>
  </div>
</template>

<script>
import Checkbox from "primevue/checkbox";

export default {
  props: {
    category: {
      type: Object,
    },
    name: {
      type: String,
      required: true,
    },
    title: String,
    customClass: String,
  },

  emits: ["getInputValue"],

  data() {
    return {
      value: false,
      checkboxKey: 0,
    };
  },
  methods: {
    EmitToParent() {
      this.$emit("getInputValue", this.value);
    },
  },
  components: { Checkbox },
  created() {
    this.value = this.category?.value;
    this.checkboxKey += 1;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.label-text {
  font-family: Gordita-medium;
}
::v-deep .p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: #020038;
}
::v-deep .p-checkbox .p-checkbox-box.p-highlight {
  border-color: unset !important;
  background-color: unset !important;
  background: unset !important;
  border: 2px solid rgba(2, 0, 56, 0.3) !important;
  border-radius: 2px !important;
}
::v-deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ced4da !important;
  border-color: gray;
}
</style>
