<template>
  <div class="pl-4 pr-4 pb-4">
    <div
      v-if="!disableHeader"
      class="mb-4 flex justify-content-between align-items-center"
    >
      <p class="lt-txt-color hk-sb">
        Applicants total:
        <span class="sec-color-txt">{{ applicantTotal || "-" }}</span>
      </p>
      <div class="flex align-items-center">
        <p
          @click="OpenAddRelationshipModal"
          class="mr-4 lt-txt-color hk-sb pointer"
        >
          Add relationship
        </p>
        <p
          class="mr-4 lt-txt-color hk-sb cursor-pointer"
          @click="discardChanges"
        >
          Discard changes
        </p>
        <div @click="save">
          <Button
            :disabled="isSubmitDisabled()"
            label="Save changes"
            type="primary"
            :loading="isBtnLoading"
          />
        </div>
      </div>
    </div>
    <Spinner v-if="isLoading" class="flex justify-content-between" />
    <div v-else>
      <Card class="box-shadow padding-around" :key="cardKey">
        <template #title>
          <div class="flex align-items-center justify-content-between">
            <h3 class="customer-name sec-color-txt hk-sb col-4">
              <span v-if="formData.preferred_name">{{
                formData.preferred_name
              }}</span>
              <span v-else
                >{{ formData.firstName }} {{ formData.middleName }}
                {{ formData.lastName }}</span
              >
            </h3>
            <div class="col-4"></div>
            <div class="col-4">
              <p class="label s">Preferred name</p>
              <TextField
                @onInput="
                  getFormData(
                    $event
                      ? $event.charAt(0).toUpperCase() + $event.slice(1)
                      : '',
                    'preferred_name'
                  )
                "
                :value="formData.preferred_name"
                placeholder="Preferred name"
                width="100%"
              />
            </div>
          </div>
        </template>
        <template #content>
          <div class="flex align-items-center">
            <div class="input-field mr-3">
              <p class="label">First name<span style="color: red"> * </span></p>
              <TextField
                @onInput="
                  getFormData(
                    $event
                      ? $event.charAt(0).toUpperCase() + $event.slice(1)
                      : '',
                    'firstName'
                  )
                "
                :value="formData.firstName"
                placeholder="First name"
                width="100%"
              />
            </div>
            <div class="input-field mr-3">
              <p class="label">Middle name</p>
              <TextField
                @onInput="
                  getFormData(
                    $event
                      ? $event.charAt(0).toUpperCase() + $event.slice(1)
                      : '',
                    'middleName'
                  )
                "
                :value="formData.middleName"
                placeholder="Middle name"
                width="100%"
              />
            </div>
            <div class="input-field">
              <p class="label">Last name<span style="color: red"> * </span></p>
              <TextField
                @onInput="
                  getFormData(
                    $event
                      ? $event.charAt(0).toUpperCase() + $event.slice(1)
                      : '',
                    'lastName'
                  )
                "
                :value="formData.lastName"
                placeholder="Last name"
                width="100%"
              />
            </div>
          </div>
          <div class="mt-2 flex align-items-center">
            <div class="input-field mr-3">
              <p class="label">
                Date of birth<span style="color: red"> * </span>
              </p>
              <DatePicker2
                placeholder="DD/MM/YY"
                width="100%"
                :value="formatDate(formData.dob)"
                :maxDate="maxDate"
                :maxYear="maxYear"
                minYear="1920"
                @change="setDOB"
              />
            </div>
            <div class="input-field mr-3 disable-input">
              <p class="label">Mobile</p>
              <TextField
                @onInput="getFormData($event, 'mobile')"
                :value="formData.mobile"
                placeholder="Mobile number"
                type="number"
                :disable="true"
                width="100%"
              />
            </div>
            <div class="input-field">
              <p class="label">Email<span style="color: red"> * </span></p>
              <TextField
                @onInput="getFormData($event, 'email')"
                :value="formData.email"
                placeholder="Email address"
                width="100%"
              />
            </div>
          </div>
          <div class="mt-2 flex align-items-center">
            <div class="input-field mr-3">
              <p class="label">
                Address line 1<span style="color: red"> * </span>
              </p>
              <TextField
                @onInput="getFormData($event, 'add_1')"
                :value="formData.add_1"
                placeholder="Address line 1"
                width="100%"
              />
            </div>
            <div class="input-field mr-3">
              <p class="label">Address line 2</p>
              <TextField
                @onInput="getFormData($event, 'add_2')"
                :value="formData.add_2"
                placeholder="Address line 2"
                width="100%"
              />
            </div>
            <div class="input-field">
              <p class="label">City<span style="color: red"> * </span></p>
              <TextField
                @onInput="getFormData($event, 'city')"
                :value="formData.city"
                placeholder="City"
                width="100%"
              />
            </div>
          </div>
          <div class="mt-2 flex align-items-center">
            <div class="input-field mr-3">
              <p class="label">State<span style="color: red"> * </span></p>
              <Dropdown
                @change="getFormData($event, 'state')"
                :value="formData.state"
                placeholder="State"
                width="100%"
                :items="stateDataSet"
              />
            </div>
            <div class="input-field mr-3">
              <p>Postal code<span style="color: red"> * </span></p>
              <NumberField
                @onInput="getFormData(Number($event), 'postal_code')"
                :value="formData.postal_code"
                placeholder="Postal code"
                width="100%"
                maxLength="4"
              />
            </div>
            <div class="input-field">
              <p class="label">Country<span style="color: red"> * </span></p>
              <Dropdown
                :items="['Australia']"
                @change="getFormData($event, 'country')"
                :value="formData.country"
                placeholder="Country"
                width="100%"
              />
            </div>
          </div>
          <div class="mt-2 flex align-items-center">
            <div class="input-field mr-3">
              <p class="label">
                Relationship status<span style="color: red"> * </span>
              </p>
              <Dropdown
                :items="relationshipStatus"
                optionLabel="name"
                optionValue="value"
                placeholder="Select"
                width="100%"
                @change="getFormData($event, 'relationshipStatus')"
                :value="formData.relationshipStatus"
              />
            </div>
            <div class="input-field mr-3">
              <p class="label">Number of dependants</p>
              <Dropdown
                :items="['0', '1', '2', '3', '3+']"
                placeholder="Select"
                width="100%"
                @change="getFormData($event, 'noOfDependants')"
                :value="formData.noOfDependants"
              />
            </div>

            <div class="input-field mr-3">
              <p class="label">Driver's License #</p>
              <TextField
                placeholder="Enter license number"
                width="100%"
                @onInput="getFormData($event, 'driver_license')"
                :value="formData.driver_license"
              />
            </div>

            <div class="input-field">
              <p class="label">Medicare #</p>
              <TextField
                placeholder="Enter medicare number"
                width="100%"
                @onInput="getFormData($event, 'medicare')"
                :value="formData.medicare"
              />
            </div>
          </div>
        </template>
      </Card>
      <Spinner
        v-if="isRelationLoading"
        class="flex justify-content-between mt-4"
      />
      <Card
        v-else
        class="mt-2 box-shadow"
        v-for="list of customerRelationships"
        :key="list.Id"
      >
        <template #content>
          <div>
            <div class="flex justify-content-between align-items-center">
              <h3 class="sec-color-txt hk-sb relationship">
                {{ list.firstName }}
                {{ list.lastName }}
              </h3>
              <div class="flex align-items-center">
                <div class="mr-3">
                  <p class="lt-txt-color">Relationship to primary applicant</p>
                </div>
                <div class="mr-3">
                  <Dropdown
                    :items="relationshipList"
                    placeholder="-Select-"
                    width="100%"
                    @change="getRelationship($event, list.Id)"
                    optionLabel="name"
                    optionValue="value"
                    :value="list?.relationshipToApplicant"
                  />
                </div>
                <div @click="removeRelationship(list.Id)" class="flex pointer">
                  <p class="pm-color">Remove relationship</p>
                  <img class="ml-1" src="@/assets/x.svg" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <AddRelationship
      v-if="showAddRelatinship"
      :show="showAddRelatinship"
      @close="showAddRelatinship = false"
      @save="refreshKey++"
    />
  </div>
</template>

<script>
import Card from "primevue/card";
import AddRelationship from "./AddRelationship.vue";
import { mapActions, mapGetters } from "vuex";
import { APP_URL, SERVER_URL, STATES } from "../../../../../constants";
import moment from "moment";
import { _delete, _get, _put } from "../../../../services/crudService";
import { required, email } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  components: { Card, AddRelationship },
  props: ["disableHeader"],

  data() {
    return {
      isLoading: false,
      showAddRelatinship: false,
      maxDate: null,
      maxYear: null,
      formData: {
        add_1: "",
        add_2: "",
        city: "",
        country: "",
        email: "",
        firstName: "",
        lastName: "",
        dob: "",
        middleName: "",
        mobile: "",
        postal_code: "",
        state: "",
        relationshipStatus: "",
        noOfDependants: 0,
        preferred_name: "",
        driver_license: "",
        medicare: "",
      },
      customerRelationships: [],
      cardKey: 0,
      relationshipList: [
        { name: "Married/Defacto", value: "married" },
        { name: "Siblings", value: "siblings" },
        { name: "Friends", value: "friend" },
        { name: "Other", value: "other" },
      ],
      relationshipStatus: [
        { name: "Married/Defacto", value: "married" },
        { name: "Single", value: "single" },
      ],
      stateDataSet: STATES,
      refreshKey: 10,
      isBtnLoading: false,
      isRelationLoading: false,
      applicantTotal: null,
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        firstName: { required },
        add_1: { required },
        city: { required },
        country: { required },
        email: { required, email },
        lastName: { required },
        postal_code: { required },
        state: { required },
        relationshipStatus: { required },
        // noOfDependants: { required },
        //preferred_name: { required },
        dob: { required },
      },
    };
  },
  watch: {
    async refreshKey() {
      console.log("--");
      await this.getCustomer();
    },
    // async $route() {
    //   console.log("route change");
    //   await this.getCustomer();
    // },
  },
  computed: {
    ...mapGetters({
      active_application: "customers/active_application",
    }),
  },
  methods: {
    ...mapActions({
      reloadAppSummary: "loanOverview/reloadAppSummary",
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    async discardChanges() {
      await this.getCustomer();
    },
    getFormData(value, fieldName) {
      // if (value == "Invalid Date") return;
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    setDOB(value) {
      if (value) {
        this.formData["dob"] = value;
      }
    },
    OpenAddRelationshipModal() {
      return (this.showAddRelatinship = true);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
      // console.log("date **********", date, moment(date).format("DD/MM/YYYY"));
      // if (date) {
      // return moment(date).format("DD/MM/YYYY");
      // let d = new Date(date);

      // if (d != "Invalid Date") {
      //   if (date) {
      //     return moment(date).format("DD/MM/YYYY");
      //   }
      // }
      // return date;
      // } else {
      //   return "";
      // }
    },
    getRelationship(value, id) {
      this.customerRelationships.map((list, idx) => {
        if (list.Id === id) {
          this.customerRelationships[idx].relationshipToApplicant = value;
        }
      });
    },
    async removeRelationship(id) {
      try {
        this.isRelationLoading = true;
        const response = await _delete(
          SERVER_URL + `remove_customer_relationship?id=${id}`
        );

        if (response) {
          this.isRelationLoading = false;
          this.refreshKey++;
        }
      } catch (error) {
        this.isRelationLoading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },

    async save() {
      try {
        if (!this.v$.$invalid) {
          const obj = this.formData;
          delete obj.createdAt;
          delete obj.Id;

          this.isBtnLoading = true;
          const response = await _put(
            SERVER_URL + "update_customer?id=" + this.$route.params.id,
            { ...obj, customer_relationships: this.customerRelationships }
          );

          if (response) {
            this.isBtnLoading = false;
            this.getCustomer();
          }

          this.reloadAppSummary();

          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Customer details updated successfully",
            life: 3000,
          });
        }
      } catch (err) {
        this.isBtnLoading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },

    async getCustomer() {
      try {
        this.isLoading = true;
        this.customerRelationships = [];
        const response = await _get(
          SERVER_URL + "get_customer?id=" + this.$route.params.id
        );
        if (response) {
          this.formData = response.data.result[0];
          this.formData.dob = response.data.result[0].dob;
          if (response.data.result[0].active_application_id) {
            const getApptotal = await _get(
              APP_URL +
                `get_applicant_details?application_id=${response.data.result[0].active_application_id}`
            );

            getApptotal
              ? (this.applicantTotal = getApptotal.data.applicants.length)
              : "-";
            // this.formData.dob = getApptotal?.data?.applicants[0]?.dob;
            response.data.result[0].customer_relationships &&
              response.data.result[0].customer_relationships.map((list) => {
                if (!list.relationship_removed_status) {
                  this.customerRelationships.push(list);
                }
              });
          }

          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        // this.$toast.add({
        //   severity: "error",
        //   summary: "Error",
        //   detail: "Something went wrong",
        //   life: 3000,
        // });
      }
    },
  },
  async created() {
    await this.getCustomer();
    let eighteenYearsAgo = new Date();
    eighteenYearsAgo = eighteenYearsAgo.setFullYear(
      eighteenYearsAgo.getFullYear() - 18
    );
    this.maxDate = new Date(eighteenYearsAgo);
    this.maxYear = this.maxDate.getFullYear();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

:deep .p-card .p-card-title {
  font-weight: 400 !important;
}

.padding-around {
  padding: 30px !important;
}

:deep .p-card-content {
  padding: 0 !important;
}

.pm-color {
  color: #2cdd80;
}

.customer-name {
  font-size: 20px;
  padding-left: 0 !important;
}

p {
  margin: 0;
  font-size: 1rem !important;
}

.label {
  font-family: Gordita-medium;
  color: $secondary-color;
  margin-bottom: 5px;
}

.input-field {
  width: 100% !important;
}

.relationship {
  font-size: 20px;
}
</style>
