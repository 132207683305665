<template>
  <div class="mr-top">
    <div class="heading">
      <div class="sub-title">Rates</div>
    </div>
    <Spinner v-if="isLoading" />
    <div v-else class="form-inputs mr-top-10px">
      <div class="p-grid">
        <!-- Col-1 -->
        <div class="p-col-10">
          <!-- Row-1 -->

          <div class="p-grid align-items-end mr-b-5px">
            <div class="p-col-4 disable-input">
              <p class="label">Yield %</p>
              <TextField
                :value="
                  calculatedFields.yield_value || calculatedFields.yield_value
                "
                :disable="true"
                placeholder="label"
                width="100%"
              />
            </div>

            <div class="p-col-4 disable-input">
              <p class="label">Loan Rate %</p>
              <NumberField
                placeholder="label"
                width="100%"
                mode="decimal"
                :key="refreshRateKey"
                :value="calculatedFields.cap_loan_rate * 100"
                @onInput="
                  getFormData($event ? $event / 100 : '', 'cap_loan_rate')
                "
                :disable="true"
              />
            </div>

            <div class="p-col-4 disable-input">
              <p class="label">Set-up Fee%</p>
              <NumberField
                :key="refreshSetupKey"
                mode="decimal"
                placeholder="label"
                width="100%"
                :max="9"
                :value="calculatedFields.setup_fee * 100"
                @onInput="getFormData($event ? $event / 100 : '', 'setup_fee')"
                :disable="true"
              />
            </div>
          </div>

          <!-- Row-2 -->

          <div class="p-grid align-items-center mr-b-5px">
            <div class="p-col-4">
              <p class="label">Loan Term</p>
              <Dropdown
                :key="termRefreshKey"
                placeholder="label"
                width="100%"
                optionValue="value"
                optionLabel="title"
                :items="loanTermList"
                :value="calculatedFields.loan_term"
                @change="getFormData($event, 'loan_term')"
              />
            </div>

            <div class="p-col-4">
              <p class="label">Interest Free Term</p>
              <Dropdown
                placeholder="label"
                width="100%"
                optionValue="value"
                optionLabel="title"
                :key="freeTermRefreshKey"
                :items="interestFreeTermList"
                :value="calculatedFields.interest_free_term"
                @change="getFormData($event, 'interest_free_term')"
              />
            </div>

            <div class="p-col-4 disable-input">
              <p class="label">Broker Fee%</p>
              <NumberField
                :key="refreshBrokerKey"
                mode="decimal"
                :max="0.25"
                :value="calculatedFields.broker_fee"
                @onInput="getFormData($event, 'broker_fee')"
                :disable="true"
              />
            </div>
            <div class="p-col-4">
              <p class="label">Valuation Fee Paid by Bridgit($)</p>
              <NumberField
                mode="decimal"
                :value="
                  calculatedFields.valuation_fee_by_brigit ||
                  calculatedFields.valuation_fee_by_brigit
                "
                @onInput="getFormData($event, 'valuation_fee_by_brigit')"
                placeholder="Label"
              />
            </div>
          </div>
        </div>

        <!-- Col-2 -->

        <div class="p-col-2 highlight-fields">
          <div class="mr-b-5px disable-input">
            <p class="label">Set-up Fee$</p>
            <NumberField
              mode="decimal"
              :format="true"
              prefix="$"
              :disable="true"
              :key="totalRefreshKey"
              :value="
                calculatedFields.setup_fee_amount
                  ? calculatedFields.setup_fee_amount
                  : 0
              "
              width="100%"
            />
          </div>

          <div class="mr-b-5px disable-input">
            <p class="label">Broker Fee$</p>
            <NumberField
              mode="decimal"
              :format="true"
              prefix="$"
              :disable="true"
              :key="totalRefreshKey"
              :value="
                calculatedFields.broker_fee_amount
                  ? calculatedFields.broker_fee_amount.toFixed(2)
                  : 0
              "
              width="100%"
            />
          </div>

          <div class="mr-b-5px disable-input">
            <p class="label">Valuation Fee$</p>
            <NumberField
              mode="decimal"
              :format="true"
              prefix="$"
              :disable="true"
              :value="calculatedFields.valuation_fee"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      calculatedFields: {
        setup_fee_percentage: 0,
        loan_term: 0,
        interest_free_term: 0,
        valuation_fee: 0,
        setup_fee_amount: 0,
        broker_fee_amount: 0,
        setup_fee: 0,
        broker_fee: 0,
        cap_loan_rate: 0,
        yield_value: 0,
        valuation_fee_by_brigit: 0,
      },
      refreshSetupKey: 10,
      totalRefreshKey: 20,
      refreshBrokerKey: 30,
      termRefreshKey: 40,
      refreshRateKey: 70,
      freeTermRefreshKey: 50,
      isLoading: 100,
      loanTermList: [
        { title: "6 months", value: "6" },
        { title: "7 months", value: "7" },
        { title: "8 months", value: "8" },
        { title: "9 months", value: "0" },
        { title: "10 months", value: "10" },
        { title: "11 months", value: "11" },
        { title: "12 months", value: "12" },
      ],
      interestFreeTermList: [
        { title: "1 month", value: 1 },
        { title: "2 month", value: 2 },
        { title: "3 month", value: 3 },
      ],
    };
  },
  computed: {
    ...mapGetters({ selectedApplication: "applications/selectedApplication" }),
  },
  created() {
    this.setData();
  },
  methods: {
    ...mapActions({
      setRatesData: "summary/setRatesData",
      setSummaryData: "summary/setSummaryData",
    }),
    setData() {
      this.isLoading = true;
      if (this.selectedApplication.application_id) {
        const { calculated_fields } = this.selectedApplication;
        this.calculatedFields.broker_fee = calculated_fields.broker_fee
          ? Number(calculated_fields.broker_fee) * 100
          : 0;
        this.calculatedFields.broker_fee =
          this.calculatedFields.broker_fee * 100;
        this.calculatedFields.yield_value = calculated_fields.yield_value
          ? calculated_fields.yield_value * 100
          : 0;
        this.calculatedFields.setup_fee = calculated_fields.setup_fee
          ? calculated_fields.setup_fee * 100
          : 0;

        this.calculatedFields.valuation_fee = calculated_fields.valuation_fee
          ? calculated_fields.valuation_fee
          : 0;
        this.calculatedFields.setup_fee_percentage =
          calculated_fields.setup_fee_percentage
            ? calculated_fields.setup_fee_percentage
            : 0;
        this.calculatedFields.loan_term = calculated_fields.loan_term
          ? calculated_fields.loan_term
          : 0;
        this.calculatedFields.interest_free_term =
          calculated_fields.interest_free_term
            ? calculated_fields.interest_free_term
            : 0;

        let setupAmount = this.calculatedFields?.setup_fee
          ? this.calculatedFields?.setup_fee
          : 0;
        let brokerAmount = calculated_fields?.broker_fee
          ? calculated_fields?.broker_fee * 100
          : 0;
        let totalAmount = calculated_fields?.initial_amount_financed
          ? calculated_fields?.initial_amount_financed
          : 0;
        let fundsAmount = calculated_fields?.funds_to_complete_purchase
          ? Number(calculated_fields?.funds_to_complete_purchase)
          : 0;
        this.calculatedFields.setup_fee_amount =
          (setupAmount * fundsAmount) / 100;
        this.calculatedFields.broker_fee_amount =
          (brokerAmount * totalAmount) / 100;
        this.calculatedFields.cap_loan_rate = calculated_fields.cap_loan_rate
          ? calculated_fields.cap_loan_rate * 100
          : 0;
        this.calculatedFields.valuation_fee_by_brigit =
          calculated_fields.valuation_fee_by_brigit
            ? calculated_fields.valuation_fee_by_brigit
            : 0;
        this.refreshBrokerKey += 1;
        this.setRatesData(this.calculatedFields);
      }
      this.isLoading = false;
    },
    getFormData(value, fieldName) {
      const { calculated_fields } = this.selectedApplication;
      this.calculatedFields[fieldName] = value;
      this.calculatedFields = { ...this.calculatedFields };
      if (fieldName == "cap_loan_rate") {
        if (value > 100) {
          this.calculatedFields.cap_loan_rate = 100;
          this.refreshRateKey += 1;
        }
      }
      // if (fieldName == "loan_term") {
      //   if (value > 6) {
      //     this.calculatedFields.loan_term = 6;
      //     this.termRefreshKey += 1;
      //   }
      // }
      if (fieldName == "interest_free_term") {
        if (value > 3) {
          this.calculatedFields.interest_free_term = 3;
          this.freeTermRefreshKey += 1;
        }
      }
      if (fieldName == "setup_fee") {
        if (value > 9) {
          this.calculatedFields.setup_fee = 9;
          this.refreshSetupKey += 1;
        }
        //Calculating the Total from broker fee + setup free
        let setupAmount = this.calculatedFields?.setup_fee
          ? this.calculatedFields?.setup_fee
          : 0;
        let brokerAmount = this.calculatedFields?.broker_fee
          ? this.calculatedFields?.broker_fee
          : 0;
        this.calculatedFields.qouted_setup_fee = setupAmount + brokerAmount;
        let totalAmount = calculated_fields?.initial_amount_financed
          ? calculated_fields?.initial_amount_financed
          : 0;
        let fundsAmount = calculated_fields?.funds_to_complete_purchase
          ? Number(calculated_fields?.funds_to_complete_purchase)
          : 0;
        this.calculatedFields.setup_fee_amount =
          (setupAmount * fundsAmount) / 100;
        this.calculatedFields.broker_fee_amount =
          (brokerAmount * totalAmount) / 100;
        this.calculatedFields.total_fee_amount =
          this.calculatedFields.setup_fee_amount +
          this.calculatedFields.broker_fee_amount;
        this.totalRefreshKey += 1;
      }

      if (fieldName == "broker_fee") {
        if (value > 0.25) {
          this.calculatedFields.broker_fee = 0.25;
          this.refreshBrokerKey += 1;
        }
        //Calculating the Total from broker fee + setup free
        let setupAmount = this.calculatedFields?.setup_fee
          ? this.calculatedFields?.setup_fee
          : 0;
        let brokerAmount = this.calculatedFields?.broker_fee
          ? this.calculatedFields?.broker_fee * 100
          : 0;
        this.calculatedFields.qouted_setup_fee = setupAmount + brokerAmount;
        let totalAmount = calculated_fields.initial_amount_financed
          ? calculated_fields.initial_amount_financed
          : 0;
        this.calculatedFields.setup_fee_amount =
          (setupAmount * totalAmount) / 100;
        this.calculatedFields.broker_fee_amount =
          (brokerAmount * totalAmount) / 100;
        this.calculatedFields.total_fee_amount =
          this.calculatedFields.setup_fee_amount +
          this.calculatedFields.broker_fee_amount;
        this.totalRefreshKey += 1;
      }
      console.log("this.calculatedFields ", this.calculatedFields);
      this.setRatesData(this.calculatedFields);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.mr-b-5px {
  margin-bottom: 5px;
}

.mr-top {
  margin-top: 2.5rem;
}

.mr-top-10px {
  margin-top: 10px;
}

.flex-gap {
  gap: 0.625rem;
}

.highlight-fields {
  .label {
    font-family: Gordita-bold !important;
  }
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

.heading {
  border-bottom: 1px solid rgba(2, 0, 56, 0.15);
  padding-bottom: 13px;
}

::v-deep .p-col-12,
.p-col-8,
.p-col-2 {
  padding-bottom: 0;
}
</style>
