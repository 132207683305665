<template>
  <div class="mr-top" :key="refreshKey">
    <div class="heading flex align-items-center justify-content-between">
      <div class="sub-title">Incoming Properties</div>
      <div class="right-col flex align-items-center">
        <Button
          @click="addProperty"
          class="ml-3"
          label="Add Property"
          type="primary"
          v-if="properties.length == 0"
        />
      </div>
    </div>
    <div v-for="(property, i) in properties" :key="i">
      <div class="form-inputs mr-top-10px">
        <div class="p-grid">
          <!-- Col-1 -->

          <div class="p-col-8">
            <!-- Row-1 -->
            <div class="flex align-items-center justify-content-center">
              <AddressAutocompleteKleber
                class="col col-9 pt-4"
                :isLoading="isAddressLoading"
                @onChange="onAddressChange($event, i)"
              />
              <div class="p-grid align-items-end mr-b-5px">
                <div class="p-col-12">
                  <p class="label">Finance clause</p>
                  <SummaryDatePicker
                    :value="property.finance_clause"
                    placeholder="Enter date"
                    width="100%"
                    @change="(e) => getFormData(e, 'finance_clause', i)"
                    :yearRange="'2022:2050'"
                  />
                </div>
              </div>
            </div>
            <div class="p-grid align-items-end mr-b-5px">
              <div class="p-col-6">
                <p class="label">Address Line 1</p>
                <TextField
                  :key="addressKey"
                  @onInput="(e) => getFormData(e, 'add_1', i)"
                  :value="property.add_1"
                  width="100%"
                />
              </div>
              <div class="p-col-3">
                <p class="label">Valuation status</p>
                <Dropdown
                  :items="valuationStatus"
                  :value="property.valuation_status"
                  width="100%"
                  @change="(e) => getFormData(e, 'valuation_status', i)"
                />
              </div>
              <div class="p-col-3">
                <p class="label">Valuation date</p>
                <SummaryDatePicker
                  :value="property.valuation_date"
                  placeholder="Enter date"
                  width="100%"
                  @change="(e) => getFormData(e, 'valuation_date', i)"
                  :yearRange="'2022:2050'"
                />
              </div>
            </div>

            <!-- Row-2 -->

            <div class="p-grid align-items-center mr-b-5px">
              <div class="p-col-6">
                <p class="label">Address Line 2</p>
                <TextField
                  :key="addressKey"
                  @onInput="(e) => getFormData(e, 'add_2', i)"
                  :value="property.add_2"
                  width="100%"
                />
              </div>
              <div class="p-col-3">
                <p class="label">Valuation firm</p>
                <TextField
                  @onInput="(e) => getFormData(e, 'valuation_firm', i)"
                  :value="property.valuation_firm"
                  width="100%"
                />
              </div>
              <div class="p-col-3">
                <p class="label">Valuation amount</p>
                <NumberField
                  @onInput="(e) => getFormData(e, 'valuation_amount', i)"
                  type="number"
                  :value="property.valuation_amount"
                  width="100%"
                  mode="decimal"
                  :format="true"
                  prefix="$"
                />
              </div>
            </div>

            <!-- Row-3 -->

            <div :key="addressKey" class="p-grid align-items-center mr-b-5px">
              <div class="p-col-3">
                <p class="label">City</p>
                <TextField
                  @onInput="(e) => getFormData(e, 'city', i)"
                  :value="property.city"
                  width="100%"
                  :disable="isCityDisabled"
                />
              </div>

              <div class="p-col-3">
                <p class="label">State</p>
                <Dropdown
                  :disable="isStateDisabled"
                  :items="states"
                  :value="property.state"
                  width="100%"
                  @change="(e) => getFormData(e, 'state', i)"
                />
              </div>

              <div class="p-col-3">
                <p class="label">Postcode</p>
                <NumberField
                  :disable="isPostalCodeDisabled"
                  @onInput="(e) => getFormData(e, 'postal_code', i)"
                  :value="property.postal_code"
                  width="100%"
                />
              </div>

              <div
                v-if="!property.purchase_property_selected && !property.contract_sale"
                class="p-col-3"
              >
                <p class="label">Postalcode Looking for</p>
                <NumberField
                  @onInput="(e) => getFormData(e, 'postalcode_area_looking', i)"
                  width="100%"
                  :value="property.postalcode_area_looking"
                />
              </div>

              <div class="p-col-3">
                <p class="label">Property Type</p>
                <Dropdown
                  :items="propertyTypes"
                  :value="property.type_used"
                  optionLabel="title"
                  optionValue="value"
                  width="100%"
                  :inValid="
                    v$.properties.$silentErrors[0]?.$response.$data[i].type_used
                      .$invalid && saveBtnClicked
                  "
                  :disable="property.type_used_value != 'cos_value'"
                  @change="(e) => getFormData(e, 'type_used', i)"
                  :id="`incoming_property_type_${i+1||0}`"
                />
              </div>
            </div>

            <!-- <div class="p-grid align-items-center">
              <div class="p-col-3 disable-padding-t">
                <p class="label">Finance clause date</p>
                <DatePicker2 placeholder="Enter date" width="100%" />
              </div>
              <div class="p-col-3 disable-padding-t">
                <p class="label">Valuation status</p>
                <Dropdown
                  :items="statusOpt"
                  placeholder="Please select"
                  width="100%"
                />
              </div>
              <div class="p-col-3 disable-padding-t">
                <p class="label">Valuation date</p>
                <DatePicker2 placeholder="Enter date" width="100%" />
              </div>
              <div class="p-col-3 disable-input disable-padding-t">
                <p class="label">Folio #</p>
                <TextField :disable="true" placeholder="label" width="100%" />
              </div>
            </div> -->
          </div>

          <!-- Col-2 -->

          <div class="p-col-2">
            <div class="mr-b-5px">
              <p class="label">
                <span v-if="selectedApplication.isPreApproval"
                  >Estimated Settlement Date</span
                ><span v-else>Settlement Date</span>
              </p>
              <SummaryDatePicker
                :value="property.settlement_date"
                placeholder="Enter date"
                width="100%"
                @change="(e) => getFormData(e, 'settlement_date', i)"
                :minDate="today"
                :yearRange="'2022:2050'"
                :inValid="
                  v$.properties.$silentErrors[0]?.$response.$data[i].settlement_date
                    .$invalid && saveBtnClicked
                "
              />
            </div>

            <div class="mr-b-5px">
              <p class="label">
                <span v-if="selectedApplication.isPreApproval"
                  >Estimated Deposit Paid</span
                ><span v-else>Deposit Paid</span>
              </p>
              <Dropdown
                :items="depositTypes"
                :value="property.deposit_type"
                optionLabel="title"
                optionValue="value"
                width="100%"
                @change="(e) => getFormData(e, 'deposit_type', i)"
              />
            </div>

            <div class="mr-b-5px">
              <p class="label">Stamp Duty Payment</p>
              <Dropdown
                :items="stampDutyTypes"
                :value="property.stamp_duty_status"
                optionLabel="title"
                optionValue="value"
                width="100%"
                :inValid="
                  v$.properties.$silentErrors[0]?.$response.$data[i].stamp_duty_status
                    .$invalid && saveBtnClicked
                "
                @change="(e) => getFormData(e, 'stamp_duty_status', i)"
              />
            </div>

            <div :key="categoryKey" class="mr-b-5px mt-2">
              <p class="label">Zone</p>
              <TextField
                :key="addressKey"
                :value="property.zone"
                width="100%"
                :disable="true"
              />
            </div>

            <!-- <div class="mr-b-5px disable-input">
              <p class="label">Mortgage #</p>
              <TextField :disalbe="true" placeholder="label" width="100%" />
            </div> -->
          </div>

          <!-- Col-3 -->

          <div class="p-col-2 highlight-fields">
            <div class="mr-b-5px">
              <p class="label">
                <span v-if="selectedApplication.isPreApproval"
                  >Estimated Purchase Price</span
                ><span v-else>Purchase Price</span>
              </p>
              <NumberField
                @onInput="(e) => getFormData(e, 'purchase_price_used', i)"
                type="number"
                v-if="selectedApplication.isPreApproval"
                :value="property.purchase_price_used"
                width="100%"
                mode="decimal"
                :format="true"
                prefix="$"
                :inValid="
                  v$.properties.$silentErrors[0]?.$response.$data[i].purchase_price_used
                    .$invalid && saveBtnClicked
                "
              />
              <NumberField
                v-else
                @onInput="(e) => getFormData(e, 'purchase_price_used', i)"
                type="number"
                :disable="property.purchase_price_used_value != 'cos_value'"
                :value="property.purchase_price_used"
                width="100%"
                mode="decimal"
                :format="true"
                prefix="$"
                :inValid="
                  v$.properties.$silentErrors[0]?.$response.$data[i].purchase_price_used
                    .$invalid && saveBtnClicked
                "
              />
            </div>

            <div class="mr-b-5px">
              <p class="label">
                <span v-if="selectedApplication.isPreApproval"
                  >Estimated Deposit Amount</span
                ><span v-else>Deposit Amount</span>
              </p>
              <NumberField
                type="number"
                :value="property.deposit_amount"
                @onInput="(e) => getFormData(e, 'deposit_amount', i)"
                placeholder="label"
                width="100%"
                mode="decimal"
                :format="true"
                prefix="$"
              />
            </div>

            <div class="mr-b-5px">
              <p class="label">Stamp Duty</p>
              <NumberField
                :key="stampDutyKey"
                type="number"
                :disable="true"
                :value="property.stamp_duty ? Number(property.stamp_duty).toFixed(2) : 0"
                @onInput="(e) => getFormData(e ? e.toString() : '', 'stamp_duty', i)"
                width="100%"
                mode="decimal"
                :format="true"
                prefix="$"
                :inValid="
                  v$.properties.$silentErrors[0]?.$response.$data[i].stamp_duty
                    .$invalid && saveBtnClicked
                "
              />
            </div>

            <div :key="categoryKey" class="mr-b-5px mt-2">
              <p class="label">Category</p>
              <TextField
                :key="addressKey"
                :value="property.category"
                width="100%"
                :disable="true"
              />
            </div>

            <!-- <div class="mr-b-5px disable-input">
              <p class="label">LVR</p>
              <TextField :disable="true" placeholder="label" width="100%" />
            </div> -->
          </div>
        </div>
      </div>
      <div class="flex align-items-center justify-content-between mt-3">
        <div class="p-col-2">
          <Button
            @click="removeProperty(i)"
            label="Remove Property"
            type="outline"
            v-if="properties.length"
          />
        </div>
        <div class="p-col-3">
          <p class="label">Folio#</p>
          <TextField
            :value="property.folio ? property.folio : ''"
            width="100%"
            :disable="true"
          />
        </div>
        <div class="p-col-3">
          <p class="label">Mortgage registration#</p>
          <NumberField type="number" width="100%" :disable="true" />
        </div>
        <div class="p-col-2">
          <p class="label">LVR%</p>
          <TextField
            :value="
              calculatedFields.initial_lvr
                ? (calculatedFields.initial_lvr * 100).toFixed(3)
                : ''
            "
            type="number"
            width="100%"
            :disable="true"
          />
        </div>
        <div class="p-col-2">
          <Button
            @click="fullValRequest(i)"
            label="Full Val Request"
            type="outline"
            style="width: 100%"
          />
        </div>
      </div>
      <div>
        <div class="mr-b-5px col col-2">
          <p class="label">Residence type</p>
          <Dropdown
            :items="['House', 'Others']"
            :value="property.pricefinder_type"
            width="100%"
            @change="(e) => getFormData(e, 'pricefinder_type', i)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import AddressAutocompleteKleber from "@/components/common/AddressAutocompleteKleber";
import { APP_URL, STATES } from "../../../../../../../../constants";
import { mapGetters, mapActions } from "vuex";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { _post } from "../../../../../../../services/crudService";

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: { AddressAutocompleteKleber },
  validations() {
    return {
      properties: {
        // required,
        // minLength: minLength(0),
        $each: helpers.forEach({
          // add_1: { required },
          // city: { required },
          // state: { required },
          // postal_code: {
          //   required,
          //   minLength: minLength(3),
          //   maxLength: maxLength(4),
          //   minValue: minValue(0),
          // },
          settlement_date: { required },
          stamp_duty_status: { required },
          purchase_price: { required },
          stamp_duty: { required },
          type_used: { required },
        }),
      },
    };
  },
  data() {
    return {
      today: new Date(),
      isAddressLoading: false,
      stampDutyKey: 20,
      categoryKey: 30,
      states: STATES,
      calculatedFields: {},
      refreshKey: 10,
      properties: [],
      isPostalCodeDisabled: true,
      isCityDisabled: true,
      isStateDisabled: true,
      addressKey: 0,
      depositTypes: [
        {
          title: "Cash",
          value: "cash",
        },
        {
          title: "Bond",
          value: "bond",
        },
      ],
      stampDutyTypes: [
        {
          title: "To be paid on settlement",
          value: "at_settlement",
        },
        {
          title: "To be paid prior to settlement",
          value: "prior_to_settlement",
        },
        {
          title: "Already Paid",
          value: "already_paid",
        },
      ],
      propertyTypes: [
        {
          title: "Residential",
          value: "residential",
        },
        {
          title: "Rural Residential",
          value: "rural_residential",
        },
        {
          title: "Off the plan residential",
          value: "off_plan_residential",
        },
        {
          title: "Commercial",
          value: "commercial",
        },
        {
          title: "Vacant Land",
          value: "vacant_land",
        },
        {
          title: "Retirement, Land Lease, or Community home",
          value: "retirement_land_lease_or_community_home",
        },
      ],
      statusOpt: [
        "AVM complete",
        "Full Val Ordered",
        "Full Val Complete",
        "No Va",
        "AVM Failed",
      ],
      valuationStatus: [
        "AVM complete",
        "Full Val Ordered",
        "Full Val Complete",
        "No Val",
        "AVM Failed ",
      ],
    };
  },
  computed: {
    ...mapGetters({
      selectedApplication: "applications/selectedApplication",
      buyingPropertyUpdated: "applications/buyingPropertyUpdated",
      saveBtnClicked: "applications/saveBtnClicked",
      buyingProperty: "applications/buyingProperty",
    }),
  },
  methods: {
    ...mapActions({
      setBuyingProperty: "applications/setBuyingProperty",
      setValidation: "applications/setValidation",
      fetchZoneByPostal: "applications/fetchZoneByPostal",
      fetchStampDuty: "applications/fetchStampDuty",
      setSettlementDate: "applications/setSettlementDate",
    }),
    async fullValRequest(index) {
      try {
        await _post(APP_URL + "valuation-request-email", {
          ...this.properties[index],
          application_id: this.$route.params.id,
          method: "incoming",
        });
        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "Requested successfully!",
          life: 3000,
        });
      } catch (err) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    async onAddressChange(address, i) {
      this.isAddressLoading = true;
      this.properties[i].add_1 = address.address_1 ? address.address_1 : "";
      this.properties[i].add_2 = address.address_2 ? address.address_2 : "";
      // this.formData.city = address.address_1 ? address.city : "";
      // this.formData.state = address.state ? address.state : "";
      this.properties[i].country = address.country ? address.country : "";
      this.properties[i].unitNumber = address.unitNumber ? address.unitNumber : "";
      this.properties[i].streetNumber = address.streetNumber ? address.streetNumber : "";
      this.properties[i].streetType = address.streetType ? address.streetType : "";
      this.properties[i].streetName = address.streetName ? address.streetName : "";
      // let address_2 = "";
      // address_2 = address.city
      //   ? address_2 + address.city + ", "
      //   : address_2 + "";
      // address_2 = address.state
      //   ? address_2 + address.state.toUpperCase() + ", "
      //   : address_2 + "";
      // address_2 = address.postal_code
      //   ? address_2 + address.postal_code
      //   : address_2 + "";
      // this.properties[i].add_2 = address_2;
      if (address.postal_code) {
        this.properties[i].postalcode_area_looking = 0;
        this.properties[i].purchase_property_selected = true;
        this.properties[i].postal_code = Number(address.postal_code);
        this.isPostalCodeDisabled = true;
        this.postalKey += 1;
      } else {
        this.properties[i].postalcode_area_looking = 0;
        this.properties[i].purchase_property_selected = true;
        this.properties[i].postal_code = "";
        this.isPostalCodeDisabled = false;
        this.postalKey += 1;
      }
      if (address.city) {
        this.properties[i].city = address.city;
        this.isCityDisabled = true;
      } else {
        this.properties[i].city = "";
        this.isCityDisabled = false;
      }
      if (address.state) {
        const isState = this.states.find((obj) => obj == address.state);
        if (isState) {
          this.properties[i].state = address.state;
          this.isStateDisabled = true;
        } else {
          this.properties[i].state = "";
          this.isStateDisabled = false;
        }
      } else {
        this.properties[i].state = "";
        this.isStateDisabled = false;
        this.stateKey += 1;
      }
      if (
        this.properties[i].purchase_price_used &&
        (this.properties[i].state || this.properties[i].postalcode_area_looking)
      ) {
        try {
          const result = await this.fetchStampDuty({
            purchase_price: this.properties[i].purchase_price_used,
            state: this.properties[i].state,
            postcode: this.properties[i].postalcode_area_looking
              ? this.properties[i].postalcode_area_looking.toString()
              : "",
          });
          this.properties[i].stamp_duty = result;
          this.stampDutyKey += 1;
        } catch (err) {
          this.properties[i].stamp_duty = "0";
          this.stampDutyKey += 1;
        }
      }
      try {
        const res = await this.fetchZoneByPostal(
          address.postal_code ? address.postal_code : ""
        );
        this.properties[i].zone = res.zone ? res.zone : "";
        this.properties[i].premium = res.premium;
        this.properties[i].category = res.category;
        this.properties[i].high_density_area = res.high_density_area;
        this.categoryKey += 1;
      } catch (err) {
        this.properties[i].zone = "";
        this.properties[i].premium = false;
        this.properties[i].category = "";
        this.properties[i].high_density_area = false;
        this.isAddressLoading = false;
        this.categoryKey += 1;
      }
      this.addressKey += 1;
      this.isAddressLoading = false;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async getFormData(value, field, property) {
      this.properties[property][field] = value;
      this.properties = [...this.properties];
      //Fetch Zones if the postal code is updated
      if (field == "purchase_price_used") {
        this.properties[property].purchase_price_cos = value;
      }
      if (field == "type_used") {
        this.properties[property].type_cos = value;
        this.properties[property].type = value ? value : "";
      }
      if (
        (field === "postal_code" || field === "postalcode_area_looking") &&
        value &&
        value >= 1000
      ) {
        try {
          const res = await this.fetchZoneByPostal(value);
          this.properties[property].zone = res.zone ? res.zone : "";
          this.properties[property].premium = res.premium;
          this.properties[property].category = res.category;
          this.properties[property].high_density_area = res.high_density_area;
          this.categoryKey += 1;
        } catch (err) {
          this.properties[property].zone = "";
          this.properties[property].premium = false;
          this.properties[property].category = "";
          this.properties[property].high_density_area = false;
          this.categoryKey += 1;
        }
      }
      //Fetch stampDutyTypes
      if (
        this.properties[property].purchase_price_used &&
        (this.properties[property].state ||
          this.properties[property].postalcode_area_looking)
      ) {
        try {
          const result = await this.fetchStampDuty({
            purchase_price: this.properties[property].purchase_price_used,
            state: this.properties[property].state,
            postcode: this.properties[property].postalcode_area_looking
              ? this.properties[property].postalcode_area_looking.toString()
              : "",
          });
          this.properties[property].stamp_duty = result;
          this.stampDutyKey += 1;
        } catch (err) {
          this.properties[property].stamp_duty = "0";
          this.stampDutyKey += 1;
        }
      }
    },
    addProperty() {
      let newObj = {
        type: "",
        type_used: "",
        contract_sale: false,
        purchase_property_selected: this.selectedApplication.isPreApproval ? false : true,
        postalcode_area_looking: 0,
        add_1: "",
        add_2: "",
        city: "",
        state: "",
        country: "",
        postal_code: 0,
        zone: "",
        premium: false,
        high_density_area: false,
        category: "",
        deposit_amount: 0,
        purchase_price_used: 0,
        purchase_price: 0,
        settlement_date: "",
        deposit_type: "",
        stamp_duty: "0",
        stamp_duty_status: "",
        purchase_price_avm: 0,
        purchase_price_fv: 0,
        purchase_price_cos: 0,
        purchase_price_used_value: "cos_value",
        type_avm: "",
        type_fv: "",
        type_cos: "",
        type_used_value: "cos_value",
        unitNumber: "",
        streetNumber: "",
        streetType: "",
        streetName: "",
        pricefinder_type: "Others",
        type_used_value_lvr: "application_value",
        purchase_price_used_value_lvr: "application_value",
      };
      this.properties = [...this.properties, newObj];
      console.log("incoming properties", this.properties);
    },
    removeProperty(i) {
      if (this.properties[0].settlement_date)
        this.setSettlementDate(this.properties[0].settlement_date);
      this.properties.splice(i, 1);
      this.refreshKey += 1;
    },
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    setData() {
      if (this.selectedApplication.application_id) {
        this.properties = this.selectedApplication.incoming_properties
          ? this.selectedApplication.incoming_properties
          : [];
        this.properties = this.properties.map((item) => {
          item.purchase_price_avm = item.purchase_price_avm
            ? Number(item.purchase_price_avm)
            : 0;
          return item;
        });
        this.setBuyingProperty(this.properties);
        this.setValidation({ type: "buying", value: this.isSubmitDisabled() });
        const { calculated_fields } = this.selectedApplication;
        this.calculatedFields = calculated_fields;
        this.calculatedFields.initial_lvr = this.calculatedFields.initial_lvr
          ? Number(this.calculatedFields.initial_lvr)
          : 0;
      }
    },
  },
  created() {
    this.setData();
  },
  watch: {
    properties: {
      handler() {
        this.setBuyingProperty(this.properties);
        this.setValidation({ type: "buying", value: this.isSubmitDisabled() });
      },
      deep: true,
    },
    buyingPropertyUpdated: {
      handler() {
        this.properties = this.buyingProperty;
        this.refreshKey += 1;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.mr-b-5px {
  margin-bottom: 5px;
}

.mr-top {
  margin-top: 2.5rem;
}

.disable-padding-t {
  padding-top: 0 !important;
}

.mr-top-10px {
  margin-top: 10px;
}

.flex-gap {
  gap: 0.625rem;
}

.highlight-fields {
  .label {
    font-family: Gordita-bold !important;
  }
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

.heading {
  border-bottom: 1px solid rgba(2, 0, 56, 0.15);
  padding-bottom: 13px;
}

::v-deep .p-col-12,
.p-col-8,
.p-col-2 {
  padding-bottom: 0;
}
</style>
