<template>
  <Dialog :draggable="false" :visible="show" :modal="show" :style="{ width: '580px' }">
    <template #header>
      <h1 class="modal-title">New file upload</h1>
    </template>
    <div class="modal-content">
      <div>Name</div>
      <TextField
        width="100%"
        placeholder="File name"
        @onInput="getFormData($event, 'document_name')"
        :value="formData.document_name"
        class="mt-2"
      />
      <div class="mt-2">Select files</div>
      <div class="file" @dragover="dragover" @dragleave="dragleave" @drop="drop">
        <input
          type="file"
          name="fields[assetsFieldHandle][]"
          id="assetsFieldHandle"
          class="w-px h-px opacity-0 overflow-hidden absolute"
          @change="onChange"
          ref="fileV"
          multiple="multiple"
          accept=".pdf,.jpg,.jpeg,.png"
          :key="fileKey"
        />

        <label for="assetsFieldHandle" class="filelabel">
          <div class="icon-text p-d-flex p-jc-center">
            <img class="icon" :src="require('@/assets/upload_icon.svg')" alt="" />
            <p class="text">Drag and drop files here or <span>browse</span></p>
          </div>
        </label>

        <div class="file-list mt-2" v-if="this.filelist.length" v-cloak>
          <p class="pm-txt-color modal-content">Uploaded files</p>
          <li
            class="text-sm p-1 p-d-flex p-ai-center"
            v-for="file of filelist"
            :key="file.name"
          >
            <div class="p-d-flex icon-name">
              <img :src="require('@/assets/tick_icon.svg')" alt="" />
              <div class="file-details">
                <p class="file-name pm-text-color">{{ file.name }}</p>
                <div class="time-size p-d-flex">
                  <p class="time lt-txt-color">Uploaded: {{ formatDate() }}</p>
                  <p class="size lt-txt-color">Size: {{ getSize(file.size) }}</p>
                </div>
              </div>
            </div>
            <img
              class="icon"
              @click="remove(filelist.indexOf(file))"
              :src="require('@/assets/cross_icon.svg')"
              alt=""
            />
          </li>
        </div>
      </div>
      <div class="flex">
        <div class="col">
          <div class="mt-2 disable-input">Type</div>
          <Dropdown
            @change="getFormData($event, 'documentType')"
            :value="formData.documentType"
            width="100%"
            placeholder="-Select-"
            class="mt-2"
            :items="documentTypeList"
            optionLabel="name"
            optionValue="value"
            :disable="true"
          />
        </div>
      </div>
      <div class="mt-2">Notes</div>
      <TextArea
        @onInput="getFormData($event, 'notes')"
        :value="formData.notes"
        width="100%"
        placeholder="Notes"
        class="mt-2"
      />
      <!-- <Checkbox @getInputValue="getInputValue" class="mt-3" :category="category" /> -->
      <Task
        v-if="category.value"
        @validateTask="isTaskValidate"
        :taskSelected="category.value"
        @taskData="taskData"
        :taskTitle="formData.document_name"
      />
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button
          :disabled="isSubmitDisabled()"
          label="Add document"
          type="primary"
          @click="send"
          :loading="isloading"
        />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">Close</div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import moment from "moment";
import Dialog from "primevue/dialog";
import { convertFiletoBase64 } from "../../../../../services/FileConvert";
import { SERVER_URL } from "../../../../../../constants";
import { _post } from "../../../../../services/crudService";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Task from "../../../Applications/ApplicationData/Document/Task.vue";

export default {
  name: "AddDocument",
  props: ["show"],
  components: { Dialog, Task },
  data() {
    return {
      category: { id: 1, label: "Create task?", value: false },
      filelist: [],
      taskValidate: true,
      formData: {
        document_name: "",
        documentType: "file",
        notes: "",
        application_id: this.$route.params.id,
        is_supporting: false,
        type: "broker",
        is_task: false,
      },
      fileKey: 0,
      task: {},
      appId: "",
      isloading: false,
      documentTypeList: [
        { name: "Files", value: "file" },
        { name: "Supporting Document", value: "supporting_document" },
      ],
      documentStatusList: [
        {
          name: "Completed",
          value: "completed",
        },
        {
          name: "No longer required",
          value: "no_longer_required",
        },
        {
          name: "Review Required",
          value: "review_required",
        },
      ],
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      // filelist: { required },
      formData: {
        document_name: { required },
        documentType: { required },
        notes: { required },
      },
    };
  },
  watch: {
    time() {
      this.task.dueTime = this.time;
    },
  },
  methods: {
    getSize(totalBytes) {
      if (totalBytes < 1000000) {
        return Math.floor(totalBytes / 1000) + "KB";
      } else {
        return Math.floor(totalBytes / 1000000) + "MB";
      }
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.fileKey += 1;
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      // if (!event.currentTarget.classList.contains("bg-green-300")) {
      //   event.currentTarget.classList.remove("bg-gray-100");
      //   event.currentTarget.classList.add("bg-green-300");
      // }
    },
    dragleave() {
      // Clean up
      // event.currentTarget.classList.add("bg-gray-100");
      // event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.fileV.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      // event.currentTarget.classList.add("bg-gray-100");
      // event.currentTarget.classList.remove("bg-green-300");
    },
    formatDate() {
      return moment().format("DD/MM/YYYY");
    },
    isSubmitDisabled() {
      if (this.category.value) {
        if (this.v$.$invalid && this.taskValidate) {
          return true;
        } else {
          return false;
        }
      } else {
        return this.v$.$invalid;
      }
      // return this.v$.$invalid
    },
    isTaskValidate(value) {
      this.taskValidate = value;
    },
    close() {
      this.$emit("close");
    },
    getInputValue(value) {
      this.formData.is_task = value;
      this.category.value = value;
    },
    onChange() {
      this.filelist = [...this.filelist, ...this.$refs.fileV.files];
    },
    taskData(value) {
      return (this.task = value);
    },
    async send() {
      try {
        this.isloading = true;
        let document = [];
        await Promise.all(
          this.filelist.map(async (file) => {
            if (file && file.name) {
              const payload = {
                fileName: file.name,
                fileContent: await convertFiletoBase64(file),
              };
              document.push(payload);
            }
          })
        );
        if (this.category.value) {
          const response = await _post(
            SERVER_URL + `upload-documents-api?Id=${this.$route.params.id}`,
            {
              ...this.formData,
              files: document,
              document_name: this.$route.params.id + "/" + this.formData.document_name,
            }
          );

          if (response) {
            this.isloading = false;
            this.$emit("close");
            this.$emit("save");
            this.$emit("completed");
            this.clearData();

            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Document added successfully",
              life: 3000,
            });
          }
        } else {
          console.log("this.formData ", this.formData);
          const response = await _post(SERVER_URL + `upload-documents-api`, {
            ...this.formData,
            document_name: this.formData.document_name,
            files: document,
          });

          if (response) {
            this.isloading = false;
            this.$emit("close");
            this.$emit("completed");
            this.$emit("save");
            this.clearData();
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Document added successfully",
              life: 3000,
            });
          }
        }
      } catch (err) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
        this.isloading = false;
      }
    },
    getFormData(value, fieldName) {
      if (fieldName === "documentType") {
        if (value === "supporting_document") {
          this.formData.is_supporting = true;
        } else {
          this.formData.is_supporting = false;
        }
      }
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    clearData() {
      this.category = { id: 1, label: "Create task?", value: false };
      this.filelist = null;
      this.taskValidate = true;
      this.formData = {
        name: "",
        type: "",
        description: "",
      };
      this.task = {};
      this.appId = "";
      this.isloading = false;
    },
  },
  unmounted() {
    this.clearData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
::placeholder {
  color: rgba(2, 0, 56, 0.3) !important;
  opacity: 1; /* Firefox */
}

//File upload
[v-cloak] {
  display: none;
}

.file-list {
  width: 100%;

  li {
    gap: 1rem;
    justify-content: space-between;
    border-bottom: 1px solid $light-text-color;

    .icon-name {
      gap: 1rem;
      padding: 1rem 0;
      align-items: center;
    }

    .file-name {
      margin: 0;
      font-size: 0.875rem;
    }

    .time-size {
      gap: 0.5rem;
      margin-top: 0.5rem;

      .time {
        border-right: 1px solid $light-text-color;
        padding-right: 0.5rem;
      }

      .time,
      .size {
        font-size: 0.75rem;
        margin: 0;
      }
    }

    .icon {
      cursor: pointer;
    }
  }
}

.title {
  font-size: 1.25rem;
}

.content {
  .heading {
    font-size: 0.875rem;
  }
}

.file {
  margin-top: 1.5rem;

  .icon-text {
    gap: 1rem;

    .text {
      margin: 0;
      font-size: 0.875rem;

      span {
        color: $primary-color;
      }
    }
  }
}

.modal-foodter {
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .close {
    margin: 0;
    cursor: pointer;
  }
}

.filelabel {
  width: 100%;
  border: 2px dashed #54b7f9;
  background: #54b7f91a;
  border-radius: 5px;
  display: block;
  padding: 1rem 1rem;
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
}
</style>
