<template>
  <Dialog :draggable="false" :visible="show" :modal="show" :style="{ width: '580px' }">
    <template #header>
      <h1 class="modal-title">Files</h1>
    </template>
    <div class="modal-content">
      <Spinner v-if="isLoading" class="flex justify-content-between" />
      <div v-else>
        <div>Document title</div>
        <TextField
          v-if="portal == 'broker'"
          :disabled="true"
          width="100%"
          placeholder="Task title"
          class="mt-2"
          @onInput="getFormData($event, 'displayName')"
          :value="displayName"
          :disable="disableDocumentName"
        />
        <TextField
          v-else
          width="100%"
          placeholder="Task title"
          class="mt-2"
          @onInput="getFormData($event, 'document_name')"
          :value="formData.document_name"
          :disable="disableDocumentName"
        />
        <div class="flex mt-2">
          <div class="col">
            <div>Document type</div>
            <Dropdown
              @change="getFormData($event, 'document_type')"
              :value="formData.document_type"
              width="100%"
              placeholder="-Select-"
              class="mt-2"
              :items="documentTypeList"
              optionLabel="name"
              optionValue="value"
              :disable="disableDocumentName"
            />
          </div>
          <div class="col ml-2">
            <div>Status</div>
            <Dropdown
              width="100%"
              placeholder="-Select-"
              class="mt-2"
              :value="formData.type"
              @change="getFormData($event, 'status')"
              :items="['Completed', 'Review Required']"
            />
          </div>
        </div>
        <div class="mt-3">
          <div
            v-for="files of documentsList"
            :key="files.Id"
            class="flex align-items-center justify-content-between"
          >
            <div
              class="flex align-items-center cursor-pointer"
              @click="downloadDocument(files.document_url, files.file_name)"
            >
              <i class="pi pi-check mr-2"></i>
              <a class="color-highlight pm-color-txt">{{ files.file_name }}</a>
            </div>
            <span
              v-if="!disableDocumentName && !filesId"
              class="delete-document pm-color-txt"
              @click="deleteDocument(files.Id)"
              >Delete</span
            >
          </div>
        </div>

        <div class="mt-2">Notes</div>
        <TextArea
          placeholder="Document description"
          width="100%"
          class="mt-2"
          @onInput="getFormData($event, 'notes')"
          :value="formData.notes"
        />
        <div v-if="portal === 'broker'" class="label mt-3 pointer color-highlight">
          <a
            :href="formData?.document_url"
            class="pointer color-highlight"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ displayFileName(brokerDocument) }}
          </a>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button label="Save changes" type="primary" @click="save" :loading="isloading" />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">Close</div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import { SERVER_URL } from "../../../../../../constants";
import { _get, _delete, _post } from "../../../../../services/crudService";

export default {
  name: "EditTask",
  props: ["show", "filesId", "modalRefreshKey", "portal"],
  components: { Dialog },
  data() {
    return {
      uploadNewDoc: false,
      category: { id: 1, label: "Mark task as complete", value: false },
      isloading: false,
      isLoading: false,
      timeStamp: "",
      displayName: "",
      brokerDocument: "",
      formData: {
        document_name: "",
        document_type: "",
        notes: "",
        id: "",
        status: "",
      },
      documentTypeList: [
        { name: "Supporting Document", value: "supporting_document" },
        { name: "Files", value: "file" },
      ],
      documentsList: [],
      disableDocumentName: false,
    };
  },
  watch: {
    modalRefreshKey() {
      this.getFilesById();
    },
  },
  methods: {
    uploadNew() {
      this.uploadNewDoc = true;
    },
    close() {
      this.clearData();
      this.$emit("close");
    },
    getFormData(value, fieldName) {
      if (fieldName == "displayName") {
        this.displayName = value;
        this.formData["document_name"] = this.timeStamp + "/" + value;
        return;
      }
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    displayFileName(name) {
      if (!name) return "-";
      var str = name;
      let time_stamp = str.split("/");

      this.timeStamp = time_stamp[0];
      str = /\/(.+)/.exec(str)[1];

      return str;
    },
    async getFilesById() {
      try {
        this.isLoading = true;
        const response = await _get(SERVER_URL + `get-document-by-id?id=${this.filesId}`);

        if (response) {
          this.formData.document_name = response.data.result.document_name;
          if (this.$props.portal == "broker") {
            this.displayName = this.displayFileName(this.formData.document_name);
            this.brokerDocument = response.data.result.document_name;
          }
          this.formData.document_url = response.data.result.document_url;
          this.formData.document_type = response.data.result.documentType;
          this.formData.id = response.data.result.Id;
          this.formData.status = response.data.result.status;
          this.formData.notes = response.data.result.notes;
          this.documentsList = response.data.result.files;
          this.isLoading = false;
          if (
            response.data.result.document_name === "Credit report" ||
            response.data.result.document_name === "Title report" ||
            response.data.result.document_name === "Pricefinder report" ||
            response.data.result.document_name === "Aplyid report"
          ) {
            this.disableDocumentName = true;
          } else {
            this.disableDocumentName = false;
          }
        }
      } catch (error) {
        this.isLoading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    async save() {
      try {
        this.isloading = true;
        const response = await _post(
          SERVER_URL + `update-document?id=${this.formData.id}`,
          { ...this.formData }
        );

        if (response) {
          this.isloading = false;
          this.$emit("close");
          this.$emit("save");
          this.$emit("completed");
          this.clearData();

          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Document Saved successfully",
            life: 3000,
          });
        }
      } catch (err) {
        await this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
        this.isloading = false;
      }
    },
    clearData() {
      (this.category = { id: 1, label: "Mark task as complete", value: false }),
        (this.formData = {
          document_name: "",
          document_type: "",
          notes: "",
          id: "",
          status: "",
        }),
        (this.isloading = false);
    },
    async downloadDocument(fileName, name) {
      if (name === "Bank statement") {
        window.open(fileName, "_blank");
        return;
      } else {
        try {
          const response = await _get(
            SERVER_URL + `get-documents-api?fileName=${fileName}`
          );
          if (response) {
            this.isloading = false;
            const docName = fileName.split(".");
            var a = document.createElement("a");
            a.href = "data:image/" + docName[1] + ";base64," + response.data.content; //Image Base64 Goes here
            a.download = fileName;
            a.click();
          }
        } catch (error) {
          this.isloading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
    },
    async deleteDocument(docId) {
      try {
        const response = await _delete(SERVER_URL + `remove-document?id=${docId}`);
        if (response) {
          this.isloading = false;
          this.getFilesById();
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Document Deleted successfully",
            life: 3000,
          });
        }
      } catch (error) {
        this.isloading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },

  unmounted() {
    this.clearData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.timestamp {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 600;
  font-size: 14px;
}

p {
  margin: 0;
}
.divider {
  border: 1px solid rgba(2, 0, 56, 0.15);
}
.delete-document {
  cursor: pointer;
}

.color-highlight {
  color: $primary-color !important;
}
.upload {
  font-weight: 600;
  font-size: 16px;
  color: rgba(2, 0, 56, 0.4);
}
</style>
