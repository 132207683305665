<template>
  <div class="mr-top">
    <Panel :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>
      <template #header>
        <div @click="toggle" class="sub-title full-width pointer">Net Sales Proceeds</div>
      </template>

      <div class="form-inputs mr-top-10px">
        <div class="p-d-flex flex-gap align-items-end mr-b-5px">
          <div class="disable-input">
            <p class="label">+Sale Property</p>
            <NumberField
              :format="true"
              prefix="$"
              :value="sale_property"
              :disable="true"
              width="100%"
            />
          </div>

          <div class="disable-input">
            <p class="label">-Sale Costs</p>
            <NumberField
              :format="true"
              :value="sale_cost"
              :disable="true"
              width="100%"
              prefix="$"
            />
          </div>

          <div class="disable-input">
            <p class="label">-Shaded Value</p>
            <NumberField
              :format="true"
              :value="shaded_value"
              :disable="true"
              width="100%"
              prefix="$"
            />
          </div>

          <div class="disable-input">
            <p class="label">-Deposit Released</p>
            <NumberField
              :format="true"
              :value="deposit_release"
              :disable="true"
              width="100%"
              prefix="$"
            />
          </div>

          <div class="disable-input">
            <p class="label">+Savings Post Settlement</p>
            <NumberField
              :format="true"
              prefix="$"
              @onInput="(e) => setSavingsPost(e)"
              :value="savings_post"
              width="100%"
              :key="savingsKey"
            />
          </div>

          <div class="highlight-fields disable-input">
            <p class="label">Net Sale Proceeds</p>
            <NumberField
              :format="true"
              prefix="$"
              :value="net_sale ? net_sale.toFixed(2) : 0"
              :disable="true"
              width="100%"
              :key="net_sale_key"
            />
          </div>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Panel from "primevue/panel";

export default {
  components: { Panel },
  data() {
    return {
      collapsed: true,
      sale_property: 0,
      sale_cost: 0,
      shaded_value: 0,
      deposit_release: 0,
      savings_post: 0,
      net_sale: 0,
      net_sale_key: 10,
      savingsKey: 20,
    };
  },
  computed: {
    ...mapGetters({
      sellingProperty: "applications/sellingProperty",
      selectedApplication: "applications/selectedApplication",
      assetSavings: "applications/assetSavings",
      savingsPostTotal: "subTotal/savingsPostTotal",
    }),
  },
  methods: {
    ...mapActions({ setNetSales: "summary/setNetSales" }),
    toggle() {
      this.collapsed = !this.collapsed;
    },
    setSavingsPost(value) {
      this.savings_post = value;
      this.setNetSales({ field: "savings_post", value: this.savings_post });
      this.net_sale =
        this.sale_property +
        this.sale_cost +
        this.shaded_value -
        this.deposit_release +
        this.savings_post;
      this.net_sale_key += 1;
      this.setNetSales({ field: "net_sale", value: this.net_sale });
    },
    setData() {
      if (this.selectedApplication.application_id) {
        let { calculated_fields } = this.selectedApplication;
        this.sale_property = calculated_fields.sale_property
          ? calculated_fields.sale_property
          : 0;
        this.setNetSales({ field: "sale_property", value: this.sale_property });
        this.sale_cost = calculated_fields.sale_cost ? calculated_fields.sale_cost : 0;
        this.setNetSales({ field: "sale_cost", value: this.sale_cost });
        this.shaded_value = calculated_fields.shaded_value
          ? calculated_fields.shaded_value
          : 0;
        this.setNetSales({ field: "shaded_value", value: this.shaded_value });
        this.deposit_release = calculated_fields.deposit_release
          ? calculated_fields.deposit_release
          : 0;
        this.setNetSales({
          field: "deposit_release",
          value: this.deposit_release,
        });
        this.savings_post = calculated_fields.savings_post
          ? calculated_fields.savings_post
          : 0;
        this.setNetSales({ field: "savings_post", value: this.savings_post });
        this.net_sale = calculated_fields.net_sale_proceeds
          ? calculated_fields.net_sale_proceeds
          : 0;
        // this.net_sale =
        //   Number(this.sale_property) +
        //   Number(this.sale_cost) +
        //   Number(this.shaded_value) -
        //   Number(this.deposit_release) +
        //   Number(this.savings_post);
        // this.net_sale_key += 1;
        this.setNetSales({ field: "net_sale", value: this.net_sale });
      }
    },
  },
  created() {
    this.setData();
  },
  watch: {
    savingsPostTotal: {
      handler() {
        if (this.selectedApplication.application_id) {
          let { calculated_fields } = this.selectedApplication;
          this.savings_post = calculated_fields.savings_post
            ? calculated_fields.savings_post
            : 0;
        }
        this.savings_post =
          this.savingsPostTotal.applicant_1 +
          this.savingsPostTotal.applicant_2 +
          this.savings_post;
        this.setNetSales({ field: "savings_post", value: this.savings_post });
        this.savingsKey += 1;
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid #02003826 !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

.highlight-fields {
  .label {
    font-family: Gordita-bold !important;
  }
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

::v-deep .p-col-4,
.p-col-2 {
  padding-bottom: 0;
  padding-top: 0;
}

.mr-b-5px {
  margin-bottom: 5px;
}

.mr-top {
  margin-top: 2.5rem;
}

.mr-top-10px {
  margin-top: 10px;
}

.flex-gap {
  gap: 0.625rem;
}
</style>
