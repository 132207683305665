<template>
  <div class="mr-top ml-2">
    <h3 class="title">{{ title }}</h3>
    <div @[event].prevent="dragOver" @drop.prevent="drop" class="list-wrapper">
      <div v-if="lists && lists.length">
        <div v-for="list of lists" :key="list?.Id">
          <div
            :draggable="
              isDraggable(
                list.applicant_1_declared,
                list.applicant_2_declared,
                list.applicants?.length
              )
            "
            @dragstart="draggable"
            @dragOver.stop
            :id="list.Id"
            :class="
              list && list.applicants
                ? renderClass(
                    list.applicant_1_declared,
                    list.applicant_2_declared,
                    list.applicants?.length
                  )
                : 'card'
            "
          >
            <div class="flex align-item-center justify-content-between">
              <a
                v-if="list.applicants"
                @click="
                  goToApp(
                    list.Id,
                    list.loan_id,
                    list.applicant_1_declared,
                    list.applicant_2_declared,
                    list.applicants.length,
                    list.createdAt
                  )
                "
                class="color-highlight pointer capital"
              >
                {{ applicantName(list.applicants) }}
                <!-- {{ list.applicants[0].first_name }}
                {{ list.applicants[0].last_name }} -->
              </a>
              <img
                @click="goToApp(list.Id, list.loan_id)"
                class="pointer"
                src="@/assets/external-link.svg"
                alt
              />
            </div>

            <div class="content" :id="list.Id">
              <div class="flex align-items-center">
                <p class="heading lt-txt-color">Loan Id</p>
                <p class="text ml-2">{{ list.loan_id }}</p>
              </div>
              <div class="flex align-items-center mr-1">
                <p class="heading lt-txt-color">Loan amount</p>
                <p class="text ml-2">
                  {{ findLoanAmount(list?.calculated_values) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="no-content">
        <div class="text">No active application</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["title", "lists", "status", "event"],
  data() {
    return {
      isListEmpty: true,
      count: 0,
    };
  },

  methods: {
    ...mapActions({
      setLoanCreatedAt: "applications/setLoanCreatedAt",
    }),
    listApp(list) {
      if (list.crm_status == this.$props.status) {
        return true;
      } else {
        // this.isListEmpty = true
        return false;
      }
    },
    isDraggable(dec_1, dec_2, applicantCount) {
      if (!applicantCount) return false;

      const declaration = this.appDeclaration(dec_1, dec_2, applicantCount);

      if (this.$props.event === "dragover") {
        if (declaration == "1/1" || declaration == "2/2") {
          return true;
        } else {
          return false;
        }
      } else return false;
    },
    formatCurrency(val) {
      return "$" + val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    appDeclaration(dec_1, dec_2, applicantCount) {
      if (!applicantCount) return;
      if (applicantCount == 2) {
        if (dec_1 && dec_2) {
          return "2/2";
        } else if (dec_1) {
          return "1/2";
        } else {
          return "0/2";
        }
      } else if (applicantCount == 1) {
        if (dec_1) {
          return "1/1";
        } else {
          return "0/1";
        }
      } else {
        return "0/0";
      }
    },
    renderClass(dec_1, dec_2, applicantCount) {
      if (!applicantCount) return;
      const declaration = this.appDeclaration(dec_1, dec_2, applicantCount);

      if (declaration == "1/1" || declaration == "2/2") {
        return "card";
      } else {
        return "highlight-card card";
      }
    },
    goToCustomer(id) {
      this.$router.push(`customers/${id}/detail`);
    },
    findLoanAmount(data) {
      data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      return this.formatCurrency(Number(data[0].initial_amount_financed));
    },
    applicantName(value) {
      if (value) {
        let name;
        value.map((app) => {
          if (value.length === 1 || app.applicant_type === "primary") {
            if (app.middle_name) {
              name = `${app.first_name} ${app.middle_name} ${app.last_name}`;
            } else {
              name = `${app.first_name}  ${app.last_name}`;
            }
          }
        });
        return name;
      }
    },
    goToApp(id, loan_id, dec_1, dec_2, applicantCount, createdAt) {
      let declaration = false;
      if (applicantCount == 2) {
        if (dec_1 && dec_2) {
          declaration = true;
        } else if (dec_1) {
          declaration = false;
        } else {
          declaration = false;
        }
      } else if (applicantCount == 1) {
        if (dec_1) {
          declaration = true;
        } else {
          declaration = false;
        }
      } else {
        declaration = true;
      }
      console.log("createdAt ", createdAt);
      this.setLoanCreatedAt(createdAt);
      this.$router.push(
        `/loan/${id}/overview?loanId=${loan_id}&declaration=${declaration}`
      );
    },
    drop(e) {
      const cardId = e.dataTransfer.getData("cardId");

      const card = document.getElementById(cardId);

      const card_id = card.getAttribute("id");

      this.$emit("changeStage", {
        id: card_id,
        status: this.$props.status,
        card: e.target,
      });
    },
    draggable(e) {
      const target = e.target;
      e.dataTransfer.setData("cardId", target.id);
      // setTimeout(() => {
      //   target.style.opacity = "0.5"
      // }, 0)
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

.mr-top {
  margin-top: 20px;
}

.title {
  font-family: Gordita-bold;
  font-size: 1rem;
  color: $secondary-color;
  margin-bottom: 1rem;
}

.list-wrapper {
  overflow: auto;
  width: 230px;
  height: 400px;
  background: rgba(2, 0, 56, 0.03);
  border-radius: 4px;
  padding: 5px;

  .card {
    border: 1px solid rgba(2, 0, 56, 0.15);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 1rem;
    margin: 0.3rem 0;

    .color-highlight {
      color: $primary-color;
      font-size: 16px;
      font-family: Gordita-medium-;
    }

    .content {
      margin-top: 10px;

      .heading {
        font-family: Gordita-medium;
        font-size: 14px;
      }

      .text {
        font-family: Gordita-medium;
        font-size: 14px;
      }
    }
  }

  .no-content {
    .text {
      font-family: Gordita-medium-;
      font-size: 16px;
      color: rgba(2, 0, 56, 0.3);
      margin: 15px;
    }
  }
}

.highlight-card {
  background: $pink !important;
  border: 1px solid rgba(2, 0, 56, 0.15);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1rem;
  margin: 0.3rem 0;
}
.on-drag-hover {
  width: 100%;
  height: 100px;
  background: #9c9c9c3a;
}
</style>
