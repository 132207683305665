<template>
  <div class="mr-b mr-top">
    <Panel class="box" :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>
      <template #header>
        <div
          @click="toggle"
          class="title-wrapper flex align-items-center full-width pointer"
        >
          <img src="@/assets/Expense.svg" alt="" />
          <p class="title">Expenses</p>
        </div>
      </template>
      <Applicant
        :originalData="applicant1Original"
        :latestData="applicant1Latest"
        title="Applicant 1 - Expenses"
      />
      <Applicant
        :originalData="applicant2Original"
        :latestData="applicant2Latest"
        title="Applicant 2 - Expenses"
      />
    </Panel>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Panel from "primevue/panel";
import Applicant from "./Applicant/index.vue";

export default {
  components: {
    Panel,
    Applicant,
  },
  data() {
    return {
      collapsed: true,
      applicant1Original: {
        groceries: 0,
        clothing: 0,
        education: 0,
        insurance_premium: 0,
        child_care: 0,
        medical_health: 0,
        recreation: 0,
        communication: 0,
        home_property_cost: 0,
        investment_prop_cost: 0,
        transport: 0,
        others: 0,
        dependants: "",
      },
      applicant2Original: {
        groceries: 0,
        clothing: 0,
        education: 0,
        insurance_premium: 0,
        child_care: 0,
        medical_health: 0,
        recreation: 0,
        communication: 0,
        home_property_cost: 0,
        investment_prop_cost: 0,
        transport: 0,
        others: 0,
        dependants: "",
      },
      applicant1Latest: {
        groceries: 0,
        clothing: 0,
        education: 0,
        insurance_premium: 0,
        child_care: 0,
        medical_health: 0,
        recreation: 0,
        communication: 0,
        home_property_cost: 0,
        investment_prop_cost: 0,
        transport: 0,
        others: 0,
        dependants: "",
      },
      applicant2Latest: {
        groceries: 0,
        clothing: 0,
        education: 0,
        insurance_premium: 0,
        child_care: 0,
        medical_health: 0,
        recreation: 0,
        communication: 0,
        home_property_cost: 0,
        investment_prop_cost: 0,
        transport: 0,
        others: 0,
        dependants: "",
      },
    };
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
    setValue() {
      this.latestData.expenses.map((obj) => {
        if (obj.applicant_type == "primary") {
          switch (obj.type) {
            case "groceries":
              this.applicant1Latest.groceries = obj.amount;
              break;
            case "clothing":
              this.applicant1Latest.clothing = obj.amount;
              break;
            case "education":
              this.applicant1Latest.education = obj.amount;
              break;
            case "insurance_premium":
              this.applicant1Latest.insurance_premium = obj.amount;
              break;
            case "child_care":
              this.applicant1Latest.child_care = obj.amount;
              break;
            case "medical_health":
              this.applicant1Latest.medical_health = obj.amount;
              break;
            case "recreation":
              this.applicant1Latest.recreation = obj.amount;
              break;
            case "communication":
              this.applicant1Latest.communication = obj.amount;
              break;
            case "home_property_cost":
              this.applicant1Latest.home_property_cost = obj.amount;
              break;
            case "investment_prop_cost":
              this.applicant1Latest.investment_prop_cost = obj.amount;
              break;
            case "transport":
              this.applicant1Latest.transport = obj.amount;
              break;
            case "others":
              this.applicant1Latest.others = obj.amount;
              break;
          }
        }
      });
      this.latestData.expenses.map((obj) => {
        if (obj.applicant_type == "secondary") {
          switch (obj.type) {
            case "groceries":
              this.applicant2Latest.groceries = obj.amount;
              break;
            case "clothing":
              this.applicant2Latest.clothing = obj.amount;
              break;
            case "education":
              this.applicant2Latest.education = obj.amount;
              break;
            case "insurance_premium":
              this.applicant2Latest.insurance_premium = obj.amount;
              break;
            case "child_care":
              this.applicant2Latest.child_care = obj.amount;
              break;
            case "medical_health":
              this.applicant2Latest.medical_health = obj.amount;
              break;
            case "recreation":
              this.applicant2Latest.recreation = obj.amount;
              break;
            case "communication":
              this.applicant2Latest.communication = obj.amount;
              break;
            case "home_property_cost":
              this.applicant2Latest.home_property_cost = obj.amount;
              break;
            case "investment_prop_cost":
              this.applicant2Latest.investment_prop_cost = obj.amount;
              break;
            case "transport":
              this.applicant2Latest.transport = obj.amount;
              break;
            case "others":
              this.applicant2Latest.others = obj.amount;
              break;
          }
        }
      });
      this.originalData.expenses.map((obj) => {
        if (obj.applicant_type == "primary") {
          switch (obj.type) {
            case "groceries":
              this.applicant1Original.groceries = obj.amount;
              break;
            case "clothing":
              this.applicant1Original.clothing = obj.amount;
              break;
            case "education":
              this.applicant1Original.education = obj.amount;
              break;
            case "insurance_premium":
              this.applicant1Original.insurance_premium = obj.amount;
              break;
            case "child_care":
              this.applicant1Original.child_care = obj.amount;
              break;
            case "medical_health":
              this.applicant1Original.medical_health = obj.amount;
              break;
            case "recreation":
              this.applicant1Original.recreation = obj.amount;
              break;
            case "communication":
              this.applicant1Original.communication = obj.amount;
              break;
            case "home_property_cost":
              this.applicant1Original.home_property_cost = obj.amount;
              break;
            case "investment_prop_cost":
              this.applicant1Original.investment_prop_cost = obj.amount;
              break;
            case "transport":
              this.applicant1Original.transport = obj.amount;
              break;
            case "others":
              this.applicant1Original.others = obj.amount;
              break;
          }
        }
      });
      this.originalData.expenses.map((obj) => {
        if (obj.applicant_type == "secondary") {
          switch (obj.type) {
            case "groceries":
              this.applicant2Original.groceries = obj.amount;
              break;
            case "clothing":
              this.applicant2Original.clothing = obj.amount;
              break;
            case "education":
              this.applicant2Original.education = obj.amount;
              break;
            case "insurance_premium":
              this.applicant2Original.insurance_premium = obj.amount;
              break;
            case "child_care":
              this.applicant2Original.child_care = obj.amount;
              break;
            case "medical_health":
              this.applicant2Original.medical_health = obj.amount;
              break;
            case "recreation":
              this.applicant2Original.recreation = obj.amount;
              break;
            case "communication":
              this.applicant2Original.communication = obj.amount;
              break;
            case "home_property_cost":
              this.applicant2Original.home_property_cost = obj.amount;
              break;
            case "investment_prop_cost":
              this.applicant2Original.investment_prop_cost = obj.amount;
              break;
            case "transport":
              this.applicant2Original.transport = obj.amount;
              break;
            case "others":
              this.applicant2Original.others = obj.amount;
              break;
          }
        }
      });
      this.applicant1Original.dependants = this.originalData.no_of_dependents;
      this.applicant1Latest.dependants = this.latestData.no_of_dependents;
    },
  },
  computed: {
    ...mapGetters({
      originalData: "loans/originalData",
      latestData: "loans/latestData",
    }),
  },
  watch: {
    latestData() {
      this.setValue();
    },
  },
  created() {
    this.setValue();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid $secondary-color !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.std-padding-x {
  padding: 0 2.188rem;
}

.flex-gap {
  gap: 0.625rem;
}

.title {
  font-size: 2rem;
  font-family: Gordita-bold;
  margin-left: 0.625rem;
  color: $secondary-color;
}

.box {
  background: #ffffff;
  border: 1px solid rgba(2, 0, 56, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 1.25rem;
}

p {
  margin: 0;
}

.mr-top {
  margin-top: 40px;
}
</style>
