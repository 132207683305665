<template>
  <div class="checkbox-wrapper">
    <h4 class="title">{{ title }}</h4>
    <div :class="customClass" class="p-field-checkbox sd">
      <Checkbox
        :id="id"
        :name="name"
        :value="label"
        v-model="editValue"
        @change="EmitToParent"
        :binary="true"
      />
      <label class="label-text" :class="labelClass || ''" :for="id">{{
        label
      }}</label>
    </div>
  </div>
</template>

<script>
import Checkbox from "primevue/checkbox";

export default {
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    labelClass: String,
    value: String,
    title: String,
    customClass: String,
  },
  emits: ["getInputValue"],
  data() {
    return {
      editValue: "",
    };
  },
  methods: {
    EmitToParent() {
      this.$emit("getInputValue", this.editValue);
    },
  },
  watch: {
    value() {
      this.editValue = this.value;
    },
  },
  created() {
    this.editValue = this.value;
  },
  components: { Checkbox },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.label-text {
  font-size: 1rem;
}
::v-deep .p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: #020038;
}
::v-deep .p-checkbox .p-checkbox-box.p-highlight {
  border-color: unset !important;
  background-color: unset !important;
  background: unset !important;
  border: 2px solid rgba(2, 0, 56, 0.3) !important;
  border-radius: 2px !important;
}
::v-deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: unset !important;
  border: 2px solid rgba(2, 0, 56, 0.3) !important;
}
::v-deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  box-shadow: unset !important;
  border: 2px solid rgba(2, 0, 56, 0.3) !important;
}
::v-deep .p-checkbox .p-checkbox-box.p-highlight {
  border-color: unset !important;
  background-color: unset !important;
  background: unset !important;
  border: 2px solid rgba(2, 0, 56, 0.3) !important;
  border-radius: 2px !important;
}
::v-deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ced4da !important;
  border-color: gray;
}
</style>
