<template>
  <div class="pl-4 pr-4 pb-4">
    <Card>
      <template #title><span class="module-sub-title">Tasks</span></template>
      <template #content>
        <span class="p-input-icon-left full-width">
          <i class="pi pi-search" />
          <InputText
            type="text"
            style="width: 100%"
            v-model="searchValue"
            placeholder="Search"
            @onInput="getSearchValue"
          />
        </span>
        <div class="flex flex-gap justify-content-between opt mt-3">
          <div class="col">
            <div class="filter-title">Status</div>
            <Dropdown
              width="100%"
              @change="getDropdownValue($event, 'status')"
              :items="statusList"
              optionLabel="name"
              optionValue="value"
              placeholder="-select-"
            />
          </div>
          <div class="col">
            <div class="filter-title">Task</div>
            <Dropdown
              width="100%"
              @change="getDropdownValue($event, 'task')"
              :items="statgeList"
              placeholder="-select-"
              optionLabel="taskTypes"
              optionValue="type"
            />
          </div>
          <div class="col">
            <div class="filter-title">Assigned to</div>
            <Dropdown
              width="100%"
              @change="getDropdownValue($event, 'assigned')"
              :items="tagsList"
              placeholder="-select-"
              optionLabel="name"
              optionValue="value"
            />
          </div>
          <div class="col">
            <div class="filter-title">From date</div>
            <DatePicker2
              width="100%"
              @change="getDropdownValue($event, 'fromDate')"
              placeholder="DD/MM/YYY"
              :value="dropDownValue.fromDate"
            />
          </div>
          <div class="col">
            <div class="filter-title">To date</div>
            <DatePicker2
              width="100%"
              @change="getDropdownValue($event, 'toDate')"
              placeholder="DD/MM/YYY"
              :value="dropDownValue.toDate"
            />
          </div>
        </div>
        <div class="divider mt-2"></div>

        <div
          class="
            mt-2
            flex
            align-items-center
            justify-content-between
            mt-3
            total-task
          "
        >
          <div>
            Total tasks:
            <span class="total-task-count">
              {{ (statements && statements.totalCount) || 0 }}</span
            >
          </div>
          <div class="flex align-items-center">
            <div
              class="flex align-items-center ml-3 task-opt cursor-pointer"
              v-if="restrictUser('addNewTasks')"
              @click="addNewTasks"
            >
              <span class="mr-2">+</span> New tasks
            </div>
            <!-- <div
              @click="completeTasks"
              v-if="restrictUser('ModifyExistingTasks')"
              class="flex align-items-center ml-3"
              :class="disableComplete ? '' : 'cursor-pointer task-opt'"
            >
              <span class="mr-2">+</span> Complete
            </div>
            <div
              v-if="restrictUser('ModifyExistingTasks')"
              @click="cancelTasks"
              class="flex align-items-center ml-3"
              :class="disableCancel ? '' : 'cursor-pointer task-opt'"
            >
              <span class="mr-2">+</span> Cancel
            </div>
            <div
              v-if="restrictUser('ModifyExistingTasks')"
              @click="OpenReAssign"
              sclass="cursor-pointer pointer task-opt flex align-items-center ml-6 re-assign"
            >
              <span class="mr-2">+</span> Re-assign
            </div> -->
          </div>
        </div>
        <Spinner v-if="isLoading" class="flex justify-content-between" />

        <DataTable
          v-else
          class="mt-4"
          :rows="10"
          selectionMode="multiple"
          dataKey="id"
          :value="statements.result"
          :rowsPerPageOptions="[10, 20, 30]"
        >
          <!-- <Column selectionMode="multiple"></Column> -->

          <template #empty> No data found. </template>

          <Column field="Id" header="">
            <template #body="slotProps">
              <div class="flex align-items-center justify-content-center">
                <input
                  type="checkbox"
                  class="table-checkbox pointer"
                  name="slotProps.data.Id"
                  :value="{
                    Id: slotProps.data.Id,
                    status: slotProps.data.status,
                    assignTo: slotProps.data.assignTo,
                    progressType: slotProps.data.progress_type,
                    regardingTo: slotProps.data.regardingTo,
                    regardingToId: slotProps.data.regardingToId,
                    application_id: slotProps.data.application_id,
                    crm_status: slotProps.data.crm_status,
                    taskId: [{ Id: slotProps.data.Id }],
                  }"
                  v-model="selectedRow"
                />
              </div>
            </template>
          </Column>
          <Column field="taskTitle" header="Task">
            <template #body="slotProps"
              ><a
                class="color-highlight"
                @click="goToEditTask(slotProps.data.Id)"
                >{{ slotProps.data.taskTitle || "-" }}</a
              >
            </template>
          </Column>
          <Column field="type" header="File">
            <template #body="slotProps">
              {{ slotProps.data.type ? slotProps.data.type : "-" }}
            </template>
          </Column>
          <Column field="task_type" header="Type"> </Column>
          <Column field="status" header="Status"> </Column>
          <Column field="assignedToType" header="Assigned to"> </Column>
          <Column field="dueDate" header="Due date">
            <template #body="slotProps" class="not-assigned">
              <div style="width: 100%; text-align: end">
                <span class="not-assigned" style="text-align: end">
                  {{
                    slotProps.data.dueDate
                      ? formatDate(slotProps.data.dueDate)
                      : "-"
                  }}
                </span>
              </div>
            </template>
          </Column>
          <Column
            field="dateCompleted"
            style="text-align: right"
            header="Date completed"
            class="date-completed"
          >
            <template #body="slotProps" class="not-assigned">
              <div style="width: 100%; text-align: end">
                <span class="not-assigned" style="text-align: end">
                  {{
                    slotProps.data.dateCompleted
                      ? formatDate(slotProps.data.dateCompleted)
                      : "N/A"
                  }}
                </span>
              </div>
            </template>
          </Column>
        </DataTable>
        <Paginator
          class="mt-2"
          :rows="10"
          :totalRecords="statements && statements.totalCount"
          :rowsPerPageOptions="[10, 20, 30]"
          template="RowsPerPageDropdown  CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} of {totalRecords} tasks"
          @page="onChangePage($event)"
        ></Paginator>
      </template>
    </Card>
    <AddTask
      @save="refreshKey++"
      :show="showAddTask"
      v-if="showAddTask"
      @close="showAddTask = false"
      :placeholder="assignedto"
      assigned="Customer"
      :assignedId="this.$route.params.id"
      regards="Loan"
      portal="Customer"
      :regardId="this.$route.params.id"
    />
    <EditTask
      :key="editTaskKey"
      preSelectReg="Customer"
      v-if="showEditTask"
      :show="showEditTask"
      :taskId="selectedTaskId"
      @save="refreshKey++"
      @close="showEditTask = false"
      portal="customer"
    />
    <ReAssignTask
      :key="reAssignKey"
      :show="showReAssign"
      v-if="showReAssign"
      @save="refreshKey++"
      :taskId="selectedTaskId"
      @close="showReAssign = false"
      portal="customer"
    />
  </div>
</template>

<script>
import moment from "moment";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import AddTask from "../../../Applications/ApplicationData/Task/AddTask.vue";
import EditTask from "../../../Applications/ApplicationData/Task/EditTask.vue";
import ReAssignTask from "../../../Applications/ApplicationData/Task/ReAssign.vue";
import { _get, _put } from "../../../../../services/crudService";
import { APP_URL, SERVER_URL } from "../../../../../../constants";
import Paginator from "primevue/paginator";
import { mapGetters } from "vuex";

export default {
  components: {
    Card,
    DataTable,
    Column,
    Paginator,
    InputText,
    AddTask,
    EditTask,
    ReAssignTask,
  },
  data() {
    return {
      regardingto: "Loan",
      assignedto: "Customer",
      showAddTask: false,
      showEditTask: false,
      showReAssign: false,
      selectedTaskId: null,
      disableComplete: false,
      disableCancel: false,
      statements: [],
      ownerList: ["All"],
      statusList: [
        { name: "Review", value: "review" },
        { name: "Open", value: "open" },
        { name: "Completed", value: "completed" },
        { name: "Cancelled", value: "cancelled" },
      ],
      tagsList: [{ name: "All", value: "all" }],
      statgeList: [{ name: "All", value: "all" }],
      isLoading: true,
      selectedRow: [],
      refreshKey: 0,
      searchValue: "",
      updateSearch: null,
      dropDownValue: {
        status: "",
        task: "",
        assigned: "",
        fromDate: "",
        toDate: "",
      },
    };
  },
  watch: {
    selectedRow() {
      if (this.selectedRow.length === 0) {
        this.disableComplete = false;
        this.disableCancel = false;
      }

      this.selectedRow.map((task) => {
        if (task.status === "Open") {
          this.disableComplete = true;
        } else {
          this.disableComplete = false;
        }
        if (task.status === "Review") {
          this.disableCancel = true;
        } else {
          this.disableCancel = false;
        }
      });
    },

    searchValue(value) {
      if (value !== "") {
        clearTimeout(this.updateSearch);

        this.updateSearch = setTimeout(async () => {
          this.getTasks(1, 10, value);
        }, 800);
      }
    },
    refreshKey() {
      this.getTasks(1, 10);
    },
  },
  computed: {
    ...mapGetters({
      active_application: "customers/active_application",
      condition: "auth/userPermission",
    }),
  },
  methods: {
    formatDate(date) {
      let dateFormat = date.includes("/");
      if (dateFormat === false) {
        return moment(date).format("DD/MM/YYYY HH:MM");
      } else {
        if (dateFormat) {
          let date1 = date.split("/");
          let dateFormat1 = (
            date1[2] +
            "-" +
            date1[1] +
            "-" +
            date1[0]
          ).toString();
          return moment(dateFormat1).format("DD/MM/YYYY HH:MM");
        }
      }
    },
    restrictUser(value) {
      return this.condition[value];
    },
    addNewTasks() {
      this.showAddTask = true;
    },
    getSearchValue(value) {
      this.searchValue = value;
    },
    OpenReAssign() {
      this.reAssignKey += 1;
      if (this.selectedRow.length && this.selectedRow.length === 1) {
        this.showReAssign = true;

        this.selectedTaskId = this.selectedRow[0].Id;
      }
    },
    goToEditTask(id) {
      if (this.restrictUser("ModifyExistingTasks")) {
        this.selectedTaskId = id;
        this.editTaskKey += 1;
        this.showEditTask = true;
      }
    },
    async getDropdownValue(value, fieldName) {
      try {
        this.isLoading = true;

        const getAppStatus = await _get(
          APP_URL + `application_status?id=${this.active_application}`
        );

        if (getAppStatus) {
          const response = await _get(
            SERVER_URL +
              `get_tasks_customer_crm?assignedToId=${this.$route.params.id}&progressType=${getAppStatus.data.result.status}&fieldName=${fieldName}&value=${value}`
          );

          if (response) {
            this.isLoading = false;
            this.statements = response.data;
          }
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    async completeTasks() {
      if (this.selectedRow.length !== 0 && !this.disableComplete) {
        let obj = {
          taskId: [],
          application_id: this.selectedRow[0].application_id,
          progressType: this.selectedRow[0].progressType,
          crm_status: this.selectedRow[0].crm_status,
        };

        this.selectedRow.map((list) => {
          obj["taskId"].push({ Id: list.Id });
        });

        this.isloading = true;

        await _put(SERVER_URL + "update_task_completed?portal=application", obj)
          .then(() => {
            this.selectedRow = [];
            this.refreshKey++;
            this.isloading = false;

            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Task updated successfully",
              life: 3000,
            });
          })
          .catch(() => {
            this.isloading = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Cannot complete this task(s)",
              life: 3000,
            });
          });
      }
    },

    async cancelTasks() {
      if (this.selectedRow.length !== 0 && !this.disableCancel) {
        let data = [];
        this.selectedRow.map((Id) => {
          data.push({ id: Id.Id, status: "cancelled" });
        });

        try {
          const response = await _put(SERVER_URL + "cancel_tasks", data);

          response &&
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Task cancelled successfully",
              life: 3000,
            });

          if (response) {
            this.selectedRow = [];
            this.refreshKey++;
          }
        } catch (err) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
    },
    onChangePage(value) {
      this.getTasks(value.page + 1, value.rows);
    },
    async getTasks(page, row, search = "") {
      try {
        this.isLoading = true;

        const getAppStatus = await _get(
          APP_URL + `application_status?id=${this.active_application}`
        );

        if (getAppStatus) {
          const response = await _get(
            SERVER_URL +
              `get_tasks_customer_crm?assignedToId=${this.$route.params.id}&progressType=${getAppStatus.data.result.status}&pageSize=${row}&pageIndex=${page}&search=${search}`
          );

          if (response) {
            this.statements = response.data;
            this.isLoading = false;
          }
        }
      } catch (error) {
        this.isLoading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    async getTaskType() {
      try {
        this.statgeList = [];
        this.isLoading = true;
        const response = await _get(SERVER_URL + `get_task_types?type=group`);
        if (response) {
          this.statgeList = response.data.result;
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },

  async created() {
    await this.getTasks(1, 10);
    this.getTaskType();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.p-card {
  box-shadow: unset !important;
  border-radius: 6px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  height: 100% !important;
}
::v-deep .p-card-title {
  color: #020038 !important;
}
::v-deep .p-card-content {
  text-align: center !important;
  font-size: 14px !important;
  color: #020038 !important;
  font-weight: 500;
}
::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}

:deep .date-completed .p-column-title {
  width: 100% !important;
}
.opt :deep .p-dropdown {
  border: none !important;
}

.flex-gap {
  gap: 1rem;
}
.p-dropdown {
  border: unset !important;
}
::v-deep .p-inputtext {
  text-align: left !important;
}
.filter-title {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 600;
  font-size: 1rem;
  text-align: left;
}
.col {
  padding: unset !important;
}
.divider {
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
.color-highlight {
  color: $primary-color !important;
}
.total-task {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 600;
  font-size: 16px;
}
.total-task-count {
  color: #020038;
}

.table-checkbox {
  width: 18px !important;
  height: 18px !important;
}

:deep .datepicker-layout {
  border: none !important;
}
</style>
