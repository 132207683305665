<template>
  <div class="std-padding-rv">
    <h1 class="module-title">Servicing Calculator</h1>

    <Card :key="refreshKey" class="box-shadow mt-3">
      <template #content>
        <div class="border-bottom">
          <div class="p-grid">
            <div class="col" :class="outgoingPropertiesCount === 3 && 'col-3'">
              <IncomingProperties
                :responseValue="responseValue"
                @validateIncoming="validateIncoming"
              />
            </div>
            <div class="col">
              <OutgoingProperites
                :responseValue="responseValue"
                @validateOutgoing="validateOutgoing"
              />
            </div>
          </div>
          <div class="p-grid mt-2">
            <div class="p-col-3">
              <Fees :responseValue="responseValue" @validateFees="validateFees" />
            </div>
            <div class="p-col-3">
              <IncomeExpenses
                :responseValue="responseValue"
                @validateIncomeExpenses="validateIncomeExpenses"
              />
            </div>
            <div class="p-col-3">
              <EndDebtCalculator :responseValue="responseValue" :isloading="isloading" />
            </div>
            <div class="p-col-3">
              <LVR :responseValue="responseValue" :isloading="isloading" />
              <Servicing :responseValue="responseValue" />
            </div>
          </div>
        </div>
        <div class="flex align-items-center flex-gap">
          <Button
            @click="runCalculator"
            label="Run Calculator"
            class="mt-5"
            type="primary"
            :loading="isloading"
          />

          <Button
            @click="generateApp"
            label="Generate application"
            class="mt-5"
            type="primary"
            :disabled="disableGenerateApp"
            :loading="isGenApploading"
          />
        </div>
        <LoanScenario
          v-if="showScenario"
          :genAppId="genAppId"
          :show="showScenario"
          @close="showScenario = false"
        />
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "primevue/card";
import IncomingProperties from "./Incoming-properties/index.vue";
import OutgoingProperites from "./Outgoing-properties/index.vue";
import Fees from "./Fees/index.vue";
import IncomeExpenses from "./IncomeExpenses/index.vue";
import EndDebtCalculator from "./EndDebtCalculator/index.vue";
import Servicing from "./Servicing/index.vue";
import LVR from "./LVR/index.vue";
import LoanScenario from "./LoanScenario/index.vue";
import { mapActions, mapGetters } from "vuex";
import { _get, _post } from "../../../services/crudService";
import { APP_URL, SERVER_URL_2 } from "../../../../constants";

export default {
  name: "ServicingCalculator",
  components: {
    Card,
    IncomingProperties,
    OutgoingProperites,
    Fees,
    IncomeExpenses,
    Servicing,
    EndDebtCalculator,
    LVR,
    LoanScenario,
  },
  data() {
    return {
      showScenario: false,
      isloading: false,
      disableGenerateApp: true,
      responseValue: null,
      isInValidate: true,
      isOutValidate: true,
      isIncomeExpensesValidate: true,
      isFeesValidate: true,
      isGenApploading: false,
      genAppId: null,
      values: null,
      refreshKey: 10,
      isPageLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      incomingProperties: "servicingCalc/incomingProperties",
      outgoingProperties: "servicingCalc/outgoingProperties",
      fees: "servicingCalc/fees",
      incomeAndExpenses: "servicingCalc/incomeAndExpenses",
      applicantDetails: "servicingCalc/applicantDetails",
      incomingPropertiesCount: "servicingCalc/incomingPropertiesCount",
      outgoingPropertiesCount: "servicingCalc/outgoingPropertiesCount",
      calculatedValues: "servicingCalc/calculatedValues",
      no_of_applicants: "servicingCalc/no_of_applicants",
      applicantDob: "servicingCalc/applicantDob",
    }),
  },
  watch: {
    $route() {
      if (this.$route.name === "ServicingCalculator") {
        // this.$router.go();

        this.setPrefillCalculatedValues({
          field: "incomingProperties",
          value: [
            {
              state: "",
              postal_code: "",
              purchase_price: 0,
              settlement_date: "",
              stamp_duty_be_paid: "",
              stamp_duty: 0,
              deposit_been_paid: "",
              deposit_amount: 0,
              type: "",
              category: "",
              zone: "",
            },
          ],
        });

        this.setPrefillCalculatedValues({
          field: "outgoingProperties",
          value: [
            {
              state: "",
              postal_code: 0,
              estimated_price: 0,
              current_mortgage: 0,
              settlement_date: "",
              property_sold: "",
              deposit_held_in_trust: "",
              deposit_amount: 0,
              outstanding_loan_amount: 0,
              category: "",
              type: "",
              sale_price: "",
            },
            {
              state: "",
              postal_code: 0,
              estimated_price: 0,
              current_mortgage: 0,
              outstanding_loan_amount: 0,
              settlement_date: "",
              property_sold: "",
              deposit_held_in_trust: "",
              deposit_amount: 0,
              type: "",
              category: "",
              sale_price: "",
            },
            {
              state: "",
              postal_code: 0,
              estimated_price: 0,
              current_mortgage: 0,
              outstanding_loan_amount: 0,
              settlement_date: "",
              property_sold: "",
              deposit_held_in_trust: "",
              category: "",
              deposit_amount: 0,
              type: "",
              sale_price: "",
            },
          ],
        });
        this.setPrefillCalculatedValues({
          field: "incomeAndExpenses",
          value: {
            application_expenses: 0,
            implied_loan_term: 30,
            monthly_liability_payment: 0,
            applicant_1_relationship: "",
            applicant_2_relationship: "",
            applicant_1_dependants: "",
            applicant_2_dependants: "",
            applicant_1_annual_income: 0,
            applicant_2_annual_income: 0,
            joint_application: "",
          },
        });
        this.setPrefillCalculatedValues({
          field: "applicantDetails",
          value: [{ age: 0 }, { age: 0 }],
        });
        this.setPrefillCalculatedValues({
          field: "calculatedValues",
          value: {},
        });
        this.setPrefillCalculatedValues({
          field: "applicantDob",
          value: {},
        });
        this.setOutgoingPropertyCount(1);
        this.set_no_of_applicants(2);
        // this.responseValue = null;
        this.refreshKey++;
      } else if (this.$route.name === "ServicingCalculatorDraft") {
        this.getDraftApp();
      }
    },
  },
  methods: {
    ...mapActions({
      setcalculatedValuess: "servicingCalc/setcalculatedValuess",
      setPrefillCalculatedValues: "servicingCalc/setPrefillCalculatedValues",
      setOutgoingPropertyCount: "servicingCalc/setOutgoingPropertyCount",
      set_no_of_applicants: "servicingCalc/set_no_of_applicants",
      setOnSave: "servicingCalc/setOnSave",
    }),
    async generateApp() {
      let incomingProp = this.incomingProperties;
      let outgoingProp = this.outgoingProperties;

      outgoingProp = this.outgoingProperties.slice(0, this.outgoingPropertiesCount);
      const data = {
        incoming_properties: incomingProp,
        outgoing_properties: outgoingProp,
        income_and_expenses: this.incomeAndExpenses,
        applicant_details: this.applicantDetails,
        caculated_values: this.calculatedValues,
        no_of_applicants: this.no_of_applicants,
        applicantDob: this.applicantDob,
        outgoingPropertiesCount: this.outgoingPropertiesCount,
        created_user_type: "crm",
        savings_end_debt: this.fees.savings_end_debt,
        add_funds_requested: this.fees.add_funds_requested || 0,
        broker_fee_inc_gst: this.fees.broker_fee_inc_gst,
        total_savings_to_purchase: this.fees.total_savings_to_purchase,
        initial_amount_financed: 0,
      };

      try {
        this.isGenApploading = true;

        const response = await _post(APP_URL + "generate_applications_crm", {
          data: JSON.stringify(data),
        });

        if (response) {
          this.genAppId = response.data;
          this.showScenario = true;
          this.isGenApploading = false;
        }
      } catch (error) {
        this.isGenApploading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    validateIncoming(value) {
      this.isInValidate = value;
    },
    validateOutgoing(value) {
      this.isOutValidate = value;
    },
    validateIncomeExpenses(value) {
      this.isIncomeExpensesValidate = value;
    },
    validateFees(value) {
      this.isFeesValidate = value;
    },
    async runCalculator() {
      this.setOnSave(true);

      if (
        this.isOutValidate ||
        this.isInValidate ||
        this.isIncomeExpensesValidate ||
        this.isFeesValidate
      ) {
        return;
      }
      this.isloading = true;
      let incomingProp = this.incomingProperties;
      let outgoingProp = this.outgoingProperties;

      // if (this.incomingPropertiesCount === 1) {
      //   incomingProp = this.incomingProperties.slice(0, 1)
      // }

      outgoingProp = this.outgoingProperties.slice(0, this.outgoingPropertiesCount);
      this.incomeAndExpenses.joint_application = this.incomeAndExpenses.joint_application
        ? this.incomeAndExpenses.joint_application
        : false;
      const data = {
        incoming_properties: incomingProp,
        outgoing_properties: outgoingProp,
        income_and_expenses: this.incomeAndExpenses,
        applicant_details: this.applicantDetails,
        savings_end_debt: this.fees.savings_end_debt,
        add_funds_requested: this.fees.add_funds_requested,
        broker_fee_inc_gst: this.fees.broker_fee_inc_gst,
        total_savings_to_purchase: this.fees.total_savings_to_purchase,
        initial_amount_financed: 0,
        // fees: this.fees,
      };

      try {
        const response = await _post(SERVER_URL_2 + "crm-service-calculator", data);

        if (response) {
          // this.responseValue = response.data;
          this.setcalculatedValuess(response.data);
          this.disableGenerateApp = false;
          this.isloading = false;
        }
      } catch (err) {
        this.isloading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    async getDraftApp() {
      if (this.$route.params.id) {
        try {
          this.isPageLoading = true;
          const response = await _get(
            APP_URL + `list_one_generated_application?id=${this.$route.params.id}`
          );

          this.values = await JSON.parse(response.data[0].data);
          if (response) {
            this.setPrefillCalculatedValues({
              field: "incomingProperties",
              value: this.values.incoming_properties,
            });
            this.setPrefillCalculatedValues({
              field: "outgoingProperties",
              value: this.values.outgoing_properties,
            });
            this.setPrefillCalculatedValues({
              field: "incomeAndExpenses",
              value: this.values.income_and_expenses,
            });
            this.setPrefillCalculatedValues({
              field: "applicantDetails",
              value: this.values.applicant_details,
            });
            this.setPrefillCalculatedValues({
              field: "calculatedValues",
              value: this.values.caculated_values,
            });
            this.setPrefillCalculatedValues({
              field: "applicantDob",
              value: this.values.applicantDob,
            });
            const feesValue = {
              add_funds_requested: this.values.add_funds_requested,
              total_savings_to_purchase: this.values.total_savings_to_purchase,
              broker_fee_inc_gst: this.values.broker_fee_inc_gst,
              savings_end_debt: this.values.savings_end_debt,
            };
            this.setPrefillCalculatedValues({
              field: "fees",
              value: feesValue,
            });
            this.setOutgoingPropertyCount(this.values.outgoingPropertiesCount);
            this.set_no_of_applicants(this.values.no_of_applicants);
            // this.responseValue = this.values.caculated_values;
            this.isPageLoading = false;
          }
        } catch (error) {
          this.isPageLoading = false;

          console.log(error);
        }
      }
    },
  },
  async created() {
    if (this.$route.params.id) {
      await this.getDraftApp();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.border-bottom {
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(2, 0, 56, 0.15) !important;
}

.flex-gap {
  gap: 2rem;
}
</style>
