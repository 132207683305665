export default {
  namespaced: true,

  state: {
    selectedCustomerId: "",
    active_application: "",
    customerCommunications: [],
  },
  getters: {
    allCustomers: ({ allCustomers }) => allCustomers,
    customerCommunications: ({ customerCommunications }) =>
      customerCommunications,
    selectedCustomerId: ({ selectedCustomerId }) => selectedCustomerId,
    active_application: ({ active_application }) => active_application,
  },
  mutations: {
    setApplicationId(state, id) {
      state.active_application = id
    },
  },
  actions: {
    async getAllCustomers({ commit }, payload) {
      commit("setAllCustomers", payload)
    },
    async getCustomerById({ commit }, payload) {
      commit("setCustomerProfile", payload)
    },
    setApplicationId({ commit }, payload) {
      commit("setApplicationId", payload)
    },
  },
}
