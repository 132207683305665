<template>
  <div class="std-padding-x mr-t">
    <Panel class="box" :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>
      <template #header>
        <div
          @click="toggle"
          class="title-wrapper flex align-items-center full-width pointer"
        >
          <img src="@/assets/liability.svg" alt="" />
          <p class="title">Liabilities</p>
        </div>
      </template>

      <Repayments />
      <Applicant title="Applicant 1" />
      <Applicant v-if="isTwoApplicants" title="Applicant 2" />
      <TotalValue :overallValue="overallValue" />
      <ReviewForm title="Liabilities Review" />
    </Panel>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Panel from "primevue/panel";
import Repayments from "./Repayments.vue";
import TotalValue from "../../../TotalValue.vue";
import Applicant from "./Applicant/index.vue";

import ReviewForm from "./ReviewForm.vue";

export default {
  components: {
    Panel,
    TotalValue,
    Repayments,
    Applicant,
    ReviewForm,
  },
  data() {
    return {
      collapsed: true,
      overallValue: ["Total Applications Liability", "-"],
    };
  },
  computed: {
    ...mapGetters({
      isTwoApplicants: "applications/isTwoApplicants",
      cardsTotal: "subTotal/cardsTotal",
      selectedApplication: "applications/selectedApplication",
      personalLoanTotal: "subTotal/personalLoanTotal",
      vehicleTotal: "subTotal/vehicleTotal",
      propertyTotal: "subTotal/propertyTotal",
    }),
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
  },
  created() {
    const { calculated_fields } = this.selectedApplication;

    let newMonthlyRepayment = calculated_fields?.new_loan_repayment_monthly
      ? Number(calculated_fields?.new_loan_repayment_monthly)
      : 0;
    this.overallValue[1] =
      "$" +
      (
        this.cardsTotal.applicant_1 +
        this.cardsTotal.applicant_2 +
        this.personalLoanTotal.applicant_2 +
        this.vehicleTotal.applicant_2 +
        this.personalLoanTotal.applicant_1 +
        this.vehicleTotal.applicant_1 +
        newMonthlyRepayment
      ).toLocaleString(undefined, { minimumFractionDigits: 2 });
  },
  watch: {
    cardsTotal: {
      handler() {
        const { calculated_fields } = this.selectedApplication;

        let newMonthlyRepayment = calculated_fields?.new_loan_repayment_monthly
          ? Number(calculated_fields?.new_loan_repayment_monthly)
          : 0;
        this.overallValue[1] =
          "$" +
          (
            this.cardsTotal.applicant_1 +
            this.cardsTotal.applicant_2 +
            this.personalLoanTotal.applicant_2 +
            this.vehicleTotal.applicant_2 +
            this.personalLoanTotal.applicant_1 +
            this.vehicleTotal.applicant_1 +
            this.propertyTotal.applicant_1 +
            this.propertyTotal.applicant_2 +
            newMonthlyRepayment
          ).toLocaleString(undefined, { minimumFractionDigits: 2 });
      },
      deep: true,
    },
    vehicleTotal: {
      handler() {
        const { calculated_fields } = this.selectedApplication;

        let newMonthlyRepayment = calculated_fields?.new_loan_repayment_monthly
          ? Number(calculated_fields?.new_loan_repayment_monthly)
          : 0;
        this.overallValue[1] =
          "$" +
          (
            this.cardsTotal.applicant_1 +
            this.cardsTotal.applicant_2 +
            this.personalLoanTotal.applicant_2 +
            this.vehicleTotal.applicant_2 +
            this.personalLoanTotal.applicant_1 +
            this.vehicleTotal.applicant_1 +
            this.propertyTotal.applicant_1 +
            this.propertyTotal.applicant_2 +
            newMonthlyRepayment
          ).toLocaleString(undefined, { minimumFractionDigits: 2 });
      },
      deep: true,
    },
    personalLoanTotal: {
      handler() {
        const { calculated_fields } = this.selectedApplication;

        let newMonthlyRepayment = calculated_fields?.new_loan_repayment_monthly
          ? Number(calculated_fields?.new_loan_repayment_monthly)
          : 0;
        this.overallValue[1] =
          "$" +
          (
            this.cardsTotal.applicant_1 +
            this.cardsTotal.applicant_2 +
            this.personalLoanTotal.applicant_2 +
            this.vehicleTotal.applicant_2 +
            this.personalLoanTotal.applicant_1 +
            this.vehicleTotal.applicant_1 +
            this.propertyTotal.applicant_1 +
            this.propertyTotal.applicant_2 +
            newMonthlyRepayment
          ).toLocaleString(undefined, { minimumFractionDigits: 2 });
      },
      deep: true,
    },
    propertyTotal: {
      handler() {
        const { calculated_fields } = this.selectedApplication;

        let newMonthlyRepayment = calculated_fields?.new_loan_repayment_monthly
          ? Number(calculated_fields?.new_loan_repayment_monthly)
          : 0;
        this.overallValue[1] =
          "$" +
          (
            this.cardsTotal.applicant_1 +
            this.cardsTotal.applicant_2 +
            this.personalLoanTotal.applicant_2 +
            this.vehicleTotal.applicant_2 +
            this.personalLoanTotal.applicant_1 +
            this.vehicleTotal.applicant_1 +
            this.propertyTotal.applicant_1 +
            this.propertyTotal.applicant_2 +
            newMonthlyRepayment
          ).toLocaleString(undefined, { minimumFractionDigits: 2 });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid $secondary-color !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.std-padding-x {
  padding: 0 2.188rem;
}

.flex-gap {
  gap: 0.625rem;
}

.mr-t {
  margin-top: 1.875rem;
}

.title {
  font-size: 2rem;
  font-family: Gordita-bold;
  margin-left: 0.625rem;
  color: $secondary-color;
}

.box {
  background: #ffffff;
  border: 1px solid rgba(2, 0, 56, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 1.25rem;
}

p {
  margin: 0;
}
</style>
