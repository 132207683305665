<template>
  <div :key="refreshKey" class="mr-top">
    <div class="heading flex align-items-center justify-content-between">
      <p class="sub-title">Self-employed/contractor income</p>
      <Button @click="addIncome" type="primary" label="Add Self-employed Income" />
    </div>

    <div>
      <div v-for="(item, i) in details" :key="i">
        <div class="form-inputs mr-top">
          <div class="p-grid align-items-end">
            <!-- Col-1 -->

            <div class="p-col-10">
              <!-- Row-1 -->
              <div class="p-grid align-items-end mr-b-5px">
                <div class="p-col-4">
                  <p class="label">Business Trading name</p>
                  <TextField
                    @onInput="(e) => getFormData(e, 'self_emp_trading_name', i)"
                    :value="item.self_emp_trading_name"
                    width="100%"
                    :inValid="
                      v$.details.$silentErrors[0]?.$response.$data[i]
                        .self_emp_trading_name.$invalid && saveBtnClicked
                    "
                  />
                </div>
                <div class="p-col-4">
                  <p class="label">ABN</p>
                  <TextField
                    :value="item.self_emp_abn"
                    @onInput="(e) => getFormData(e, 'self_emp_abn', i)"
                    width="100%"
                    :inValid="
                      v$.details.$silentErrors[0]?.$response.$data[i].self_emp_abn
                        .$invalid && saveBtnClicked
                    "
                  />
                </div>
                <div class="p-col-4">
                  <p class="label">Taxable</p>
                  <Dropdown
                    :items="taxableArray"
                    :value="item.is_taxable"
                    width="100%"
                    @change="(e) => getFormData(e, 'is_taxable', i)"
                    optionLabel="text"
                    optionValue="value"
                  />
                </div>
              </div>

              <!-- Row-2 -->

              <div class="p-grid align-items-center mr-b-5px">
                <div class="p-col-4">
                  <p class="label">Income</p>
                  <NumberField
                    :value="item.self_emp_salary_used"
                    type="number"
                    @onInput="(e) => getFormData(e, 'self_emp_salary_used', i)"
                    width="100%"
                    :format="true"
                    prefix="$"
                    :inValid="
                      v$.details.$silentErrors[0]?.$response.$data[i].self_emp_salary_used
                        .$invalid && saveBtnClicked
                    "
                    :disable="item.self_emp_salary_used_value != 'manual_value'"
                  />
                </div>

                <div class="p-col-4">
                  <p class="label">Frequency</p>
                  <Dropdown
                    :items="frequencyOpt"
                    :value="item.self_emp_frequency"
                    width="100%"
                    @change="(e) => getFormData(e, 'self_emp_frequency', i)"
                    :disable="true"
                  />
                </div>

                <div class="p-col-4">
                  <p class="label">Ratio%</p>
                  <TextField
                    :disable="false"
                    :value="
                      calculateRatio(
                        item.self_emp_salary,
                        monthlySubtotal(i),
                        item.self_emp_frequency
                      )
                    "
                    width="100%"
                  />
                </div>
              </div>
            </div>

            <!-- Col-2 -->

            <div class="p-col-2 highlight-fields">
              <div class="mr-b-5px disable-input">
                <p class="label">Monthly Sub Total</p>
                <TotalField
                  :value="monthlySubtotal(i)"
                  :disable="true"
                  placeholder="label"
                  width="100%"
                  mode="decimal"
                  :format="true"
                  prefix="$"
                  :id="`selfEmploymentMmonthlySubtotal_${i}`"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex align-items-center">
          <Button @click="removeIncome(i)" type="outline" label="Remove Income" />
          <!-- <img class="ml-3" src="@/assets/Revision.svg" alt="" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["disable"],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      details: {
        // required,
        $each: helpers.forEach({
          self_emp_trading_name: { required },
          self_emp_abn: { required },
          self_emp_salary: { required },
          self_emp_frequency: { required },
          is_taxable: { required },
        }),
      },
    };
  },
  data() {
    return {
      details: [],
      subTotal: [],
      refreshKey: 10,
      disableFields: this.$props.disable,
      frequencyOpt: ["Weekly", "Fortnightly", "Monthly", "Quarterly", "Yearly"],
      taxableArray: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
    };
  },
  methods: {
    ...mapActions({
      setSelfEmployeeIncome: "applications/setSelfEmployeeIncome",
      setSelfEmployeeTotal: "subTotal/setSelfEmployeeTotal",
      setValidation: "applications/setValidation",
    }),
    calculateRatio(value, total, frequency) {
      if (value && total && frequency) {
        let calc = this.getUpdatedValueFromData(value, frequency) / Number(total);
        let percent = calc * 100;
        return percent.toFixed(2);
      }
      return 0;
    },
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    addIncome() {
      let newObj = {
        self_emp_trading_name: "",
        self_emp_abn: "",
        self_emp_salary: 0,
        self_emp_frequency: "Monthly",
        applicant_type: !this.disable ? "primary" : "secondary",
        jobType: "self_employed",
        is_gst_registered: false,
        is_sole_trader: false,
        is_fy: false,
        add_1: "",
        add_2: "",
        self_emp_occupation: "",
        self_emp_industry: "",
        self_emp_salary_used: 0,
        self_emp_salary_manual: 0,
        self_emp_salary_used_value: "manual_value",
        is_taxable: true,
      };
      this.details = [...this.details, newObj];
      return (this.disableFields = false);
    },
    //Get updated values based on frequency
    getUpdatedValues(valueField, frequencyField, index) {
      let value = 0;
      value = this.details[index][valueField] ? this.details[index][valueField] : 0;
      let frequency = this.details[index][frequencyField];
      switch (frequency) {
        case "Weekly":
          return value * 52;
        case "Fortnightly":
          return value * 26;
        case "Monthly":
          return value * 12;
        case "Quarterly":
          return value * 4;
        case "Yearly":
          return value * 1;
      }
      return 0;
    },
    getUpdatedValueFromData(valueField, frequencyField) {
      let value = valueField;
      let frequency = frequencyField;
      switch (frequency) {
        case "Weekly":
          return value * 52;
        case "Fortnightly":
          return value * 26;
        case "Monthly":
          return value * 12;
        case "Quarterly":
          return value * 4;
        case "Yearly":
          return value * 1;
      }
      return 0;
    },
    monthlySubtotal(i) {
      let total = Number(
        this.getUpdatedValues("self_emp_salary_used", "self_emp_frequency", i)
      );
      this.subTotal[i] = total;
      this.setSelfEmployeeTotal({
        data: this.subTotal,
        type: !this.disable ? "primary" : "secondary",
      });
      return total ? (total / 12).toFixed(2) : 0;
    },
    removeIncome(i) {
      this.details.splice(i, 1);
      this.subTotal.splice(i, 1);
      this.setValidation({
        type: "income_self",
        value: this.isSubmitDisabled(),
      });
      this.refreshKey += 1;
    },
    getFormData(value, field, income) {
      this.details[income][field] = value;
      this.details = [...this.details];
      if (field == "self_emp_salary_used")
        this.details[income].self_emp_salary_manual = value;
    },
    setData() {
      if (this.selectedApplication.application_id) {
        if (
          this.selectedApplication.self_employed &&
          this.selectedApplication.self_employed.length > 0
        ) {
          this.details = !this.disable
            ? this.selectedApplication.self_employed.filter(
                (item) => item.applicant_type === "primary"
              )
            : this.selectedApplication.self_employed.filter(
                (item) => item.applicant_type === "secondary"
              );
        }
        this.setSelfEmployeeIncome({
          data: this.details,
          type: !this.disable ? "primary" : "secondary",
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      saveBtnClicked: "applications/saveBtnClicked",
      selectedApplication: "applications/selectedApplication",
      selfEmpUpdated: "applications/selfEmpUpdated",
    }),
    ...mapState({
      selfIncome: (state) => state.applications.selfIncome,
    }),
  },
  created() {
    this.setData();
  },
  watch: {
    details() {
      this.setSelfEmployeeIncome({
        data: this.details,
        type: !this.disable ? "primary" : "secondary",
      });
      this.setValidation({
        type: "income_self",
        value: this.isSubmitDisabled(),
      });
    },
    selfEmpUpdated: {
      handler() {
        this.details = !this.disable
          ? [...this.selfIncome.applicant_1]
          : [...this.selfIncome.applicant_2];
        this.refreshKey += 1;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

::v-deep .p-col-4 {
  padding-bottom: 0;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.highlight-fields {
  .label {
    font-family: Gordita-bold !important;
  }
}

.heading {
  background: rgba(2, 0, 56, 0.03);
  padding: 12px;

  p {
    margin: 0;
  }
}

.mr-b-5px {
  margin-bottom: 5px;
}

.mr-top {
  margin-top: 2.5rem;
}

.mr-top-10px {
  margin-top: 10px;
}

.flex-gap {
  gap: 0.625rem;
}

.flex-gap-1 {
  gap: 0.313rem;
}

.flex-gap-2 {
  gap: 1.875rem;
}
</style>
