export const NUMBERS_OBJ = {
  ZERO: 0,
  FOUR: 4,
  FIVE: 5,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  PLUS_SIXTY_ONE: +61,
  SIXTY_ONE: 61,
  TWELVE: 12,
};

export const TOKEN = "token";

export const API = {
  AUTHENTICATION: {
    SIGNIN: "",
  },
};

// dev url

export const SERVER_URL = process.env.VUE_APP_SERVER_URL;
export const APP_URL = process.env.VUE_APP_APP_URL;
export const SERVER_URL_2 = process.env.VUE_APP_SERVER_URL_2;

// pre-prod url

// export const SERVER_URL = "https://fl0-portalapi-prod-aue-stagingslot.azurewebsites.net/techlend/dev/all_users/flows/"
// export const APP_URL = "https://fl0-portalapi-prod-aue-stagingslot.azurewebsites.net/techlend/dev/applications/flows/"
// export const SERVER_URL_2 = "https://fl0-portalapi-prod-aue-stagingslot.azurewebsites.net/techlend/dev/calculator/flows/"

export const ROUTES = {
  SIGNIN: "/login",
  DASHBOARD: "/dashboard",
  AUTH: "/login",
};

export const STATES = ["NSW", "VIC", "WA", "QLD", "SA", "TAS", "ACT"];
