<template>
  <div class="mr-b mr-top">
    <Panel :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>
      <template #header>
        <div @click="toggle" class="title-wrapper full-width pointer">
          <p class="title">Savings</p>
        </div>
      </template>

      <InputForm />
    </Panel>
  </div>
</template>

<script>
import Panel from "primevue/panel";
import InputForm from "./InputForm.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Panel,
    InputForm,
  },
  data() {
    return {
      collapsed: true,
    };
  },
  computed: {
    ...mapGetters({
      originalData: "loans/originalData",
      latestData: "loans/latestData",
    }),
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid $secondary-color !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

:deep .p-panel {
  border: none !important;
  box-shadow: none !important;
  padding: none !important;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.std-padding-x {
  padding: 0 2.188rem;
}

.flex-gap {
  gap: 0.625rem;
}

.title {
  font-size: 20px;
  font-family: Gordita-bold;
  color: $secondary-color;
}

.box {
  background: #ffffff;
  border: 1px solid rgba(2, 0, 56, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 1.25rem;
}

p {
  margin: 0;
}

.mr-top {
  margin-top: 40px;
}
</style>
