<template>
  <div>
    <div :key="errorKey">
      <ul v-for="(item, i) in errorArray" :key="i">
        <li class="error-msg">{{ item }}</li>
      </ul>
    </div>
    <div class="footer-wrapper flex align-items-center justify-content-end">
      <p @click="openActivityDialog" class="Gordita-bold cursor-pointer">
        <span v-if="showSaveVersion">Save version</span>
      </p>
      <!-- <Button
        label="Run decision engine"
        @click="runDecisionEngine"
        type="primary"
        :loading="isLoading"
      /> -->
      <Toast group="bc" position="center">
        <template #message>
          <div class="p-d-flex p-flex-column">
            <div class="p-text-center">
              <i class="pi pi-check" style="font-size: 3rem"></i>
              <div class="p-grid p-fluid">
                <div class="p-col-12">
                  <h4>Decision engine result for the selected version</h4>
                  <h3
                    style="
                      color: black;
                      text-transform: uppercase;
                      font-weight: bold;
                    "
                  >
                    {{ dsMessage }}
                  </h3>
                </div>
              </div>
            </div>
            <div class="p-grid p-fluid">
              <div class="p-col-12">
                <Button
                  class="p-button-success"
                  label="Ok"
                  @click="onConfirm"
                />
              </div>
            </div>
          </div>
        </template>
      </Toast>
      <ActivityDialog
        :item="changeLogItem"
        :show="showActivityDialog"
        @close="showActivityDialog = false"
        :key="changeLogItemKey"
        @saveVersion="saveVersion"
      />
    </div>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import { mapActions, mapGetters } from "vuex";
// import _ from "lodash";
import ActivityDialog from "../ActivityDialog";
import { detailedDiff } from "deep-object-diff";

export default {
  data() {
    return {
      isLoading: false,
      dsMessage: "",
      errorArray: [],
      errorKey: 10,
      changeLogItem: [],
      changeLogItemKey: 10,
      showActivityDialog: false,
      showSaveVersion: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedApplication: "applications/selectedApplication",
      validationErrorMessage: "applications/validationErrorMessage",
      oldApplication: "applications/oldApplication",
    }),
  },
  components: { Toast, ActivityDialog },
  methods: {
    ...mapActions({
      saveCurrentVersion: "applications/saveCurrentVersion",
      runApplicationDecisionEngine: "applications/runApplicationDecisionEngine",
      checkValidation: "applications/checkValidation",
      getNewVersionData: "applications/getNewVersionData",
    }),
    onConfirm() {
      this.$toast.removeGroup("bc");
      this.dsMessage = "";
    },
    async openActivityDialog() {
      // let oldApplication = this.selectedApplication;
      let oldApplication = this.oldApplication;
      delete oldApplication.summary;
      let newApplication = await this.getNewVersionData();
      const resultObj = await this.difference(newApplication, oldApplication);
      // let newArray = [];
      // this.changeLogItem = [];
      // let keyArray = Object.keys(resultObj);
      // keyArray.map((obj) => {
      //   newArray = [...newArray, obj];
      //   if (typeof resultObj[obj] == "object") {
      //     if (Array.isArray(resultObj[obj])) {
      //       // newArray = [...newArray, obj];
      //       this.changeLogItem = newArray;
      //       Object.keys(resultObj[obj]).map((i) => {
      //         if (typeof resultObj[obj][i] == "object") {
      //           newArray = [...newArray, resultObj[obj][i]];
      //           this.changeLogItem = newArray;
      //         } else {
      //           newArray = [...newArray, { i: resultObj[obj][i] }];
      //           this.changeLogItem = newArray;
      //         }
      //       });
      //     } else {
      //       newArray = [...newArray, resultObj[obj]];
      //       this.changeLogItem = newArray;
      //     }
      //   } else {
      //     newArray.push({ value: resultObj[obj] });
      //   }
      //   this.changeLogItem = newArray;
      // });
      this.changeLogItem = resultObj;
      this.changeLogItemKey += 1;
      return (this.showActivityDialog = true);
    },
    difference(object, base) {
      return detailedDiff(base, object);
      // function changes(object, base) {
      //   return _.transform(object, function (result, value, key) {
      //     if (!_.isEqual(value, base[key])) {
      //       result[key] =
      //         _.isObject(value) && _.isObject(base[key])
      //           ? changes(value, base[key])
      //           : value;
      //     }
      //   });
      // }
      // return changes(object, base);
    },
    async saveVersion() {
      if (!this.selectedApplication.application_id) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select the version of the application!",
          life: 3000,
        });
        return;
      }
      this.errorArray = [];
      await this.checkValidation();
      if (this.validationErrorMessage.length > 0) {
        this.errorArray = this.validationErrorMessage;
        this.errorKey += 1;
        return;
      }
      try {
        await this.saveCurrentVersion();
        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "Version saved successfully!",
          life: 3000,
        });
        this.$router.go();
      } catch (err) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Oops! Error in creating version",
          life: 3000,
        });
      }
    },
    async runDecisionEngine() {
      this.isLoading = true;
      if (!this.selectedApplication.application_id) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select the version of the application!",
          life: 3000,
        });
        this.isLoading = false;
        return;
      }
      try {
        const result = await this.runApplicationDecisionEngine({
          version: this.selectedApplication.version,
          id: this.selectedApplication.application_id,
        });
        this.dsMessage = result;
        this.$toast.add({
          severity: "success",
          summary: "Are you sure?",
          detail: "Proceed to confirm",
          group: "bc",
          closable: false,
        });
        this.isLoading = false;
      } catch (err) {
        (this.dsMessage = ""),
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Oops! Error in decision seeker",
            life: 3000,
          });
        this.isLoading = false;
      }
    },
  },
  created() {
    this.showSaveVersion =
      this.$route?.query?.declaration && this.$route.query.declaration == "true"
        ? true
        : false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
  color: white;
  font-size: 0.875rem;
}

.cursor-pointer {
  cursor: pointer;
}

.footer-wrapper {
  gap: 2.25rem;
  margin-top: 2.5rem;
  background: $secondary-color;
  height: 60px;
  border-radius: 4px;
  padding: 10px;
}

.error-msg {
  color: red;
  font-size: 14px;
}
</style>
