<template>
  <Dialog :draggable="false" :visible="show" :modal="show" :style="{ width: '750px' }">
    <!-- <div> -->
    <div class="disable-input">
      <h3 class="heading">Amendments Identified</h3>
    </div>
    <Spinner v-if="isLoadingPage" />
    <DataTable v-else dataKey="Id" :value="changedValues" class="mt-2">
      <template #empty> No data found. </template>

      <Column field="label" header="Label" style="text-transform: capitalize">
        <template #body="slotProps">
          {{ slotProps.data.label.replaceAll("_", " ") || "-" }}
        </template>
      </Column>
      <Column field="original" header="Previous value" style="text-transform: capitalize">
        <template #body="slotProps">
          <span v-if="slotProps.data.original">
            {{ slotProps.data.original }}
          </span>
        </template>
      </Column>
      <Column
        field="updatedValue"
        header="Updated value"
        style="text-transform: capitalize"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.updatedValue">
            {{ slotProps.data.updatedValue }}
          </span>
        </template>
      </Column>
    </DataTable>
    <!-- <div v-if="item.length">
      <div>
        <h3 class="heading">Amendments Identified</h3>
        <div v-for="(item, i) in item" :key="i">
          <div v-if="typeof item == 'string'" style="color: green">
            {{ item }}
          </div>
          <div v-if="typeof item == 'object'" style="color: #515076">
            <div v-for="i in Object.keys(item)" :key="i">
              {{ i }} : {{ item[i] }}
            </div>
            <br />
          </div>
        </div>
      </div> -->

    <!-- </div> -->
    <!-- <h3 v-else class="heading">No Amendments Identified</h3> -->
    <!-- Notes Section -->
    <div>
      <div class="mt-5">
        <p class="label mr-b-5">Summary Notes <span class="required-class">*</span></p>
        <TextArea
          width="100%"
          height="80px"
          resize="none"
          placeholder="Description"
          @onInput="getFormData($event, 'summary_notes')"
          :value="notesData.summary_notes"
        />
      </div>
      <div v-if="showProperty" class="mt-5">
        <p class="label mr-b-5">Property Notes <span class="required-class">*</span></p>
        <TextArea
          width="100%"
          height="80px"
          resize="none"
          placeholder="Description"
          @onInput="getFormData($event, 'property_notes')"
          :value="notesData.property_notes"
        />
      </div>
      <div v-if="showIncome" class="mt-5">
        <p class="label mr-b-5">Income Notes <span class="required-class">*</span></p>
        <TextArea
          width="100%"
          height="80px"
          resize="none"
          placeholder="Description"
          @onInput="getFormData($event, 'income_review')"
          :value="notesData.income_review"
        />
      </div>
      <div v-if="showExpenses" class="mt-5">
        <p class="label mr-b-5">Expenses Notes <span class="required-class">*</span></p>
        <TextArea
          width="100%"
          height="80px"
          resize="none"
          placeholder="Description"
          @onInput="getFormData($event, 'expense_review')"
          :value="notesData.expense_review"
        />
      </div>

      <div v-if="showAssets" class="mt-5">
        <p class="label mr-b-5">Assets Notes <span class="required-class">*</span></p>
        <TextArea
          width="100%"
          height="80px"
          resize="none"
          placeholder="Description"
          @onInput="getFormData($event, 'asset_review')"
          :value="notesData.asset_review"
        />
      </div>
      <div v-if="showLiability" class="mt-5">
        <p class="label mr-b-5">
          Liabilities Notes <span class="required-class">*</span>
        </p>
        <TextArea
          width="100%"
          height="80px"
          resize="none"
          placeholder="Description"
          @onInput="getFormData($event, 'liability_review')"
          :value="notesData.liability_review"
        />
      </div>
    </div>
    <div class="flex align-items-center mt-3">
      <div @click="close" class="ml-2 modal-close-button cursor-pointer">Close</div>
      <Button
        class="p-button-success ml-3"
        label="Save new version"
        @click="saveVersion"
        :disabled="isSubmitDisabled() || !changedValues.length"
        :loading="isLoading"
      />
    </div>
    <p v-if="!changedValues.length" class="required-class mt-2">
      *No changes to save a new version
    </p>
  </Dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { required, requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

const _ = require("lodash");

export default {
  props: ["show", "item"],
  setup: () => ({ v$: useVuelidate() }),
  components: {
    Dialog,
    DataTable,
    Column,
  },
  validations() {
    return {
      notesData: {
        summary_notes: {
          required,
        },
        property_notes: {
          required: requiredIf(() => this.showProperty == true),
        },
        income_review: {
          required: requiredIf(() => this.showIncome == true),
        },
        expense_review: {
          required: requiredIf(() => this.showExpenses == true),
        },
        asset_review: {
          required: requiredIf(() => this.showAssets == true),
        },
        liability_review: {
          required: requiredIf(() => this.showLiability == true),
        },
      },
    };
  },
  data() {
    return {
      isLoadingPage: true,
      changedValues: [],
      isLoading: false,
      notesData: {},
      notes: [
        {
          madeBy: "Sally Sullivan",
          notes:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut tortor feugiat, pulvinar dui vel, sollicitudin justo.",
        },
      ],
      showProperty: false,
      showIncome: false,
      showExpenses: false,
      showAssets: false,
      showLiability: false,
    };
  },
  computed: {
    ...mapGetters({
      summary_notes: "loanDescription/summary_notes",
      property_notes: "loanDescription/property_notes",
      income_review: "loanDescription/income_review",
      expense_review: "loanDescription/expense_review",
      asset_review: "loanDescription/asset_review",
      liability_review: "loanDescription/liability_review",
      oldApplication: "applications/oldApplication",
    }),
  },
  methods: {
    ...mapActions({
      setReviewData: "loanDescription/setReviewData",
      getNewVersionData: "applications/getNewVersionData",
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    close() {
      this.$emit("close");
    },
    getFormData(value, fieldName) {
      this.notesData[fieldName] = value;
      this.notesData = { ...this.notesData };
      this.setReviewData({ value: value, type: fieldName });
    },
    async saveVersion() {
      this.isLoading = true;
      await this.$emit("saveVersion");
      // this.isLoading = false;
    },
    paths(dataObj) {
      const reducer = (aggregator, val, key) => {
        let paths = [key];
        if (_.isObject(val)) {
          paths = _.reduce(val, reducer, []);
          paths = _.map(paths, (path) => key + "." + path);
        }
        aggregator.push(...paths);
        return aggregator;
      };
      const arrayIndexRegEx = /\.(\d+)/gi;
      let paths = _.reduce(dataObj, reducer, []);
      paths = _.map(paths, (path) => path.replace(arrayIndexRegEx, "[$1]"));

      return paths;
    },
    ordinal_suffix_of(i) {
      var j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    },

    // paths(obj, parentKey) {
    //   var result;
    //   if (_.isArray(obj)) {
    //     var idx = 0;
    //     result = _.flatMap(obj, function (obj) {
    //       return this.paths(obj, (parentKey || "") + "[" + idx++ + "]");
    //     });
    //   } else if (_.isPlainObject(obj)) {
    //     result = _.flatMap(_.keys(obj), function (key) {
    //       return _.map(this.paths(obj[key], key), function (subkey) {
    //         return (parentKey ? parentKey + "." : "") + subkey;
    //       });
    //     });
    //   } else {
    //     result = [];
    //   }
    //   return _.concat(result, parentKey || []);
    // },
  },
  async created() {
    this.isLoadingPage = true;
    this.notesData.summary_notes = this.summary_notes ? this.summary_notes : "";
    this.notesData.property_notes = this.property_notes ? this.property_notes : "";
    this.notesData.income_review = this.income_review ? this.income_review : "";
    this.notesData.expense_review = this.expense_review ? this.expense_review : "";
    this.notesData.asset_review = this.asset_review ? this.asset_review : "";
    this.notesData.liability_review = this.liability_review ? this.liability_review : "";
    // Fetch updated values
    const updatedPaths = this.paths(this.item.updated);
    let originalObject = this.oldApplication;
    delete originalObject.summary;
    let updatedObject = await this.getNewVersionData();
    this.changedValues = [];

    updatedPaths.forEach((path) => {
      const original = _.get(originalObject, path);
      const updatedValue = _.get(updatedObject, path);
      const fullPath = path;

      this.changedValues.push({
        original,
        updatedValue,
        label: fullPath,
      });
    });
    // Fetch add values
    const addedPaths = this.paths(this.item.added);

    addedPaths.forEach((path) => {
      const original = "";
      const updatedValue = _.get(updatedObject, path);
      const fullPath = path;

      this.changedValues.push({
        original,
        updatedValue,
        label: fullPath,
      });
    });
    let list = [];
    this.changedValues.map((obj) => {
      const sentence = obj.label.split(".");
      const mappedSentence = sentence.map((item, i) => {
        // check to see if it is [0] or [1] whatever
        let digit = item.match(/\d+/);
        if (item.match(digit) && i == 0) {
          let val = item.split("[");
          let numberValue = this.ordinal_suffix_of(Number(digit) + 1);
          return val[0] + " " + numberValue;
        }
        return item;
      });
      obj.label = mappedSentence.join(" ");
      if (obj.label == "qouted_setup_fee") obj.label = "quoted_setup_fee";
      if (obj.updatedValue != "" || obj.original != "") {
        if (
          (obj.original == "" && obj.updatedValue == "0") ||
          (obj.updatedValue == "" && obj.original == "0") ||
          obj.original == obj.updatedValue
        ) {
          console.log("");
        } else {
          list.push(obj);
        }
        //   &&
        //   obj.updatedValue != obj.original &&
        //   obj.updatedValue != "" &&
        //   obj.original != "0"
        // )
      }
    });

    this.changedValues = list;

    // Show property notes
    let index1 = this.changedValues.findIndex((element) =>
      element.label.includes("incoming")
    );
    let index2 = this.changedValues.findIndex((element) =>
      element.label.includes("outgoing")
    );
    this.showProperty = index1 > -1 || index2 > -1;
    // Show income notes
    let index3 = this.changedValues.findIndex((element) =>
      element.label.includes("additional_income")
    );
    let index4 = this.changedValues.findIndex((element) =>
      element.label.includes("self_employed")
    );
    let index5 = this.changedValues.findIndex((element) =>
      element.label.includes("salaried")
    );
    this.showIncome = index3 > -1 || index4 > -1 || index5 > -1;
    // Show expenses
    let index6 = this.changedValues.findIndex((element) =>
      element.label.includes("expenses")
    );
    let index7 = this.changedValues.findIndex((element) =>
      element.label.includes("no_of_dependents")
    );
    let index8 = this.changedValues.findIndex((element) =>
      element.label.includes("applicant_1_relationship")
    );
    let index9 = this.changedValues.findIndex((element) =>
      element.label.includes("applicant_2_relationship")
    );
    this.showExpenses = index6 > -1 || index7 > -1 || index8 > -1 || index9 > -1;
    // Show assets
    let index10 = this.changedValues.findIndex((element) =>
      element.label.includes("assets")
    );
    let index11 = this.changedValues.findIndex((element) =>
      element.label.includes("assets_properties")
    );
    this.showAssets = index10 > -1 && index10 != index11;
    // Show liability

    let index12 = this.changedValues.findIndex((element) =>
      element.label.includes("liability")
    );
    this.showLiability = index12 > -1 || index11 > -1;
    // Format label name

    this.isLoadingPage = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid $secondary-color !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid #02003826 !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
  padding: 1rem 1rem 0.5rem 1rem !important;
}

::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  //   border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
.color-highlight {
  color: $primary-color !important;
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}

::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.notes-title {
  font-size: 16px;
  font-family: Gordita-medium-;
}

p {
  margin: 0;
}

.heading {
  font-family: Gordita-medium-;
  font-size: 20px;
  color: $secondary-color;
  margin: 20px 0 15px 0;
}

.title {
  background: rgba(2, 0, 56, 0.03);
  font-size: 16px;
  font-family: Gordita-medium-;
  padding: 10px;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

p {
  margin: 0;
}

::v-deep .col-5 {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col-5 {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
  font-size: 14px !important;
}
::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
  font-size: 14px !important;
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
</style>
