<template>
  <div id="app">
    <Toast position="top-right" />

    <router-view></router-view>
  </div>
</template>

<script>
import Toast from "primevue/toast";

export default {
  name: "App",
  components: {
    Toast,
  },
};
</script>

<style lang="scss">
@import "@/styles/global.scss";
#app {
  font-family: Gordita !important;
}
</style>
