<template>
  <div>
    <div class="title p-d-flex justify-content-between">
      <p class="title-text">Fees</p>
    </div>
    <div class="mt-2">
      <div class="flex align-items-center justify-content end disable-input">
        <p class="label p-col-6">Capitalising Loan Rate %</p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          :disable="true"
          width="100%"
          height="30px"
          :value="formData.cap_loan_rate"
          placeholder="Enter value"
          @onInput="getFormData($event, 'cap_loan_rate')"
          mode="decimal"
          id="fee_cap_loan_rate"
        />
      </div>
      <div class="flex align-items-center justify-content disable-input end mt-1">
        <p class="label p-col-6">Set-Up Fee %</p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          width="100%"
          height="30px"
          :disable="true"
          :value="formData.setup_fee"
          placeholder="Enter value"
          type="number"
          mode="decimal"
          @onInput="getFormData($event, 'setup_fee')"
          id="fee_setup_fee"
        />
      </div>
      <div class="flex align-items-center justify-content end mt-1">
        <p class="label p-col-6">
          Broker Fee % inc GST<span style="color: red"> * </span>
        </p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          width="100%"
          height="30px"
          :value="formData.broker_fee_inc_gst"
          type="number"
          mode="decimal"
          placeholder="Enter value"
          @onInput="getFormData($event, 'broker_fee_inc_gst')"
          :decimalValue="0.35"
          :inValid="v$.formData.broker_fee_inc_gst.$invalid && onSave"
          :maxFractionDigits="2"
          id="fee_broker_fee_inc_gst"
        />
      </div>
      <div class="flex align-items-center justify-content end disable-input mt-1">
        <p class="label p-col-6">Quoted Set-Up Fee %</p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          width="100%"
          height="30px"
          :value="formData.qouted_setup_fee"
          type="number"
          mode="decimal"
          :disable="true"
          placeholder="Enter value"
          @onInput="getFormData($event, 'qouted_setup_fee')"
          id="fee_qouted_setup_fee"
        />
      </div>
      <div class="flex align-items-center disable-input justify-content end mt-1">
        <p class="label p-col-6">Valuation Fee $</p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          width="100%"
          :disable="true"
          height="30px"
          :value="formData.valuation_fee"
          type="number"
          :maxValue="9"
          prefix="$"
          placeholder="Enter amount"
          @onInput="getFormData($event, 'valuation_fee')"
          id="fee_valuation_fee"
        />
      </div>
      <div class="flex align-items-center justify-content end mt-1">
        <p class="label p-col-6">Loan Deposit $<span style="color: red"> * </span></p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          width="100%"
          height="30px"
          placeholder="Enter amount"
          type="number"
          :value="formData.loan_deposit"
          @onInput="getFormData($event, 'loan_deposit')"
          :inValid="v$.formData.loan_deposit.$invalid && onSave"
          :maxValue="9"
          prefix="$"
          id="fee_loan_deposit"
        />
      </div>
      <div class="flex align-items-center justify-content end disable-input mt-1">
        <p class="label p-col-6">Term</p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          :disable="true"
          width="100%"
          height="30px"
          placeholder="Enter value"
          type="number"
          :value="formData.term"
          @onInput="getFormData($event, 'term')"
          suffix=" months"
          id="fee_term"
        />
      </div>
      <div class="flex align-items-center justify-content end mt-1 disable-input">
        <p class="label p-col-6">Requested Term</p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          width="100%"
          :disable="true"
          height="30px"
          type="number"
          placeholder="Enter value"
          :value="formData.req_term"
          suffix=" months"
          @onInput="getFormData($event, 'req_term')"
          id="fee_req_term"
        />
      </div>
      <div class="flex align-items-center justify-content end mt-1 disable-input">
        <p class="label p-col-6">Interest Free Term</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          height="30px"
          type="number"
          placeholder="Enter value"
          suffix=" months"
          :value="formData.interest_free_term"
          @onInput="getFormData($event, 'interest_free_term')"
          id="fee_interest_free_term"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["responseValue"],
  data() {
    return {
      refreshKey: 10,
      formData: {
        cap_loan_rate: 5.99,
        setup_fee: 0,
        broker_fee_inc_gst: 0,
        add_funds_requested: 0,
        qouted_setup_fee: 0,
        valuation_fee: 0,
        loan_deposit: 0,
        req_term: 6,
        term: 6,
        interest_free_term: 3,
      },
    };
  },
  watch: {
    // responseValue(result) {
    //   this.refreshKey++;
    //   for (const [key, value] of Object.entries(result)) {
    //     if (typeof value === "number") {
    //       this.formData[key] = value.toFixed(2);
    //     } else {
    //       this.formData[key] = value;
    //     }
    //   }
    // },

    calculatedValues(result) {
      this.refreshKey++;
      this.$emit("validateFees2", this.v$.$invalid);

      for (const [key, value] of Object.entries(result)) {
        if (typeof value === "number") {
          this.formData[key] = value.toFixed(2);
        } else {
          this.formData[key] = value;
        }
        if (value === -1) {
          this.formData[key] = "-";
        }
      }
    },
    fees(result) {
      for (const [key, value] of Object.entries(result)) {
        this.formData[key] = value;
      }
    },
  },
  computed: {
    ...mapGetters({
      calculatedValues: "servicingCalc/calculatedValues",
      onSave: "servicingCalc/onSave",
      fees: "servicingCalc/fees",
    }),
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        cap_loan_rate: { required },
        setup_fee: { required },
        broker_fee_inc_gst: { required },
        add_funds_requested: { required },
        qouted_setup_fee: { required },
        valuation_fee: { required },
        loan_deposit: { required },
        req_term: { required },
        term: { required },
        interest_free_term: { required },
      },
    };
  },
  methods: {
    ...mapActions({
      addFees: "servicingCalc/addFees",
    }),
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
      this.addFees({ value, fieldName });

      if (fieldName === "broker_fee_inc_gst") {
        if (value > 0.35) {
          this.formData.broker_fee_inc_gst = 0.35;
          this.refreshKey++;
          return this.$emit("validateFees2", true);
        }
      }
      this.$emit("validateFees2", this.v$.$invalid);
    },
  },
  mounted() {
    this.$emit("validateFees2", this.v$.$invalid);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.title {
  border-bottom: 1px solid rgba(2, 0, 56, 0.15);
  padding-bottom: 10px;

  .title-text {
    font-family: Gordita-bold;
    color: $secondary-color;
    font-size: 20px;
  }
}

p {
  margin: 0;
}

.label {
  padding-right: 0;
  line-height: 19px !important;
  font-size: 14px;
  font-family: Gordita-medium;
  color: $secondary-color;
  text-align: end;
}

:deep .p-col-6 {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
