<template>
  <div class="pl-4 pr-4 pb-4">
    <Card class="box-shadow p-4">
      <template #title>
        <h3 class="module-sub-title">Documents</h3>
      </template>
      <template #content>
        <Spinner v-if="isloading" class="flex justify-content-between" />
        <div v-else>
          <div class="border-box">
            <div
              class="flex full-width justify-content-between align-items-center"
            >
              <span class="module-sub-title">Supporting documents</span>
              <div
                class="flex align-items-center cursor-pointer"
                @click="uploadNew"
              >
                <img
                  width="24"
                  height="24"
                  src="@/assets/upload-inactive.svg"
                />
                <div class="upload ml-2">New required supporting document</div>
              </div>
            </div>
            <Spinner
              v-if="isSuppFileLoading"
              class="flex justify-content-between"
            />
            <DataTable v-else :value="statements.result">
              <template #empty> No data found. </template>

              <Column
                field="document_name"
                :style="{ maxWidth: '12rem' }"
                header="File name"
              >
                <template #body="slotProps"
                  ><a
                    v-if="slotProps.data.document_name"
                    class="color-highlight pointer"
                    @click="
                      openSupportingDoc(
                        slotProps.data.filesId,
                        slotProps.data.Id
                      )
                    "
                    >{{ slotProps.data.document_name }}</a
                  >
                </template>
              </Column>
              <Column
                field="is_task"
                header="Task"
                :style="{ maxWidth: '14rem' }"
              >
                <template #body="slotProps">
                  <!-- <a
                    class="color-highlight pointer"
                    @click="goToEditTask(slotProps.data.taskID.Id)"
                  >
                    {{
                      slotProps.data.taskID?.taskTitle
                        ? slotProps.data?.taskID?.taskTitle
                        : "-"
                    }}
                  </a> -->
                  <img
                    @click="goToEditTask(slotProps.data.taskID.Id)"
                    v-if="slotProps.data.is_task"
                    width="40"
                    height="40"
                    src="@/assets/task_active.svg"
                  />
                  <img v-else width="40" height="40" src="@/assets/task.svg" />
                </template>
              </Column>
              <Column
                field="is_task"
                header="Type"
                :style="{ maxWidth: '14rem' }"
              >
                <template #body="slotProps">
                  <img
                    v-if="
                      slotProps.data &&
                      slotProps.data.taskID &&
                      slotProps.data.taskID.modal_field == 'bank'
                    "
                    width="30"
                    height="30"
                    src="@/assets/ilion.svg"
                  />
                  <img
                    v-else
                    width="30"
                    height="30"
                    src="@/assets/supporting_doc.svg"
                  />
                </template>
              </Column>
              <Column
                field="is_task"
                header="Origin"
                :style="{ maxWidth: '14rem' }"
              >
                <template #body="slotProps">
                  {{ getTaskType(slotProps.data) }}
                </template>
              </Column>
              <!-- <Column field="documentType" header="Document type">
                <template #body="slotProps">
                  {{ slotProps.data?.documentType.replaceAll("_", " ") || "-" }}
                </template>
              </Column> -->
              <Column field="status" header="Status" class="capital">
                <template #body="slotProps">
                  {{
                    slotProps.data?.status
                      ? slotProps.data?.status.replaceAll("_", " ")
                      : "-"
                  }}
                </template>
              </Column>
              <Column field="notes" header="Notes">
                <template #body="slotProps">
                  {{ slotProps.data.notes || "-" }}
                </template>
              </Column>
              <Column field="total" header="Total">
                <template #body="slotProps">
                  {{ displayFileLength(slotProps.data) }}
                </template>
              </Column>
              <Column :sortable="true" field="created_at" header="Created">
                <template #body="slotProps">
                  {{ formatDate(slotProps.data.created_at) || "-" }}
                </template>
              </Column>
              <Column :sortable="true" field="dateCompleted" header="Completed">
                <template #body="slotProps">
                  <span v-if="slotProps.data.dateCompleted">
                    {{ formatDate(slotProps.data.dateCompleted) }}
                  </span>
                  <span v-else>-</span>
                </template>
              </Column>
            </DataTable>
            <Paginator
              class="mt-2"
              :rows="20"
              :totalRecords="statements.totalCount && statements.totalCount"
              :rowsPerPageOptions="[20, 30]"
              template="RowsPerPageDropdown  CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
              currentPageReportTemplate="Showing {first} of {totalRecords} supporting documents"
              @page="onChangePageSupporting($event)"
            ></Paginator>
          </div>

          <div class="mt-5 border-box">
            <div
              class="flex full-width justify-content-between align-items-center"
            >
              <span class="module-sub-title">Files</span>
              <div
                class="flex align-items-center cursor-pointer"
                @click="uploadNewFile"
              >
                <img
                  width="24"
                  height="24"
                  src="@/assets/upload-inactive.svg"
                />
                <div class="upload ml-2">Upload new file</div>
              </div>
            </div>
            <DataTable dataKey="id" :value="files.result">
              <template #empty> No data found. </template>

              <Column field="name" header="File name">
                <template #body="slotProps"
                  ><a
                    class="color-highlight pointer"
                    @click="openFiles(slotProps.data.Id)"
                    >{{ slotProps.data.document_name }}</a
                  >
                </template>
              </Column>
              <Column
                field="is_task"
                header="Task"
                :style="{ maxWidth: '14rem' }"
              >
                <template #body="slotProps">
                  <!-- <a
                    class="color-highlight pointer"
                    @click="goToEditTask(slotProps.data.taskID.Id)"
                  >
                    {{
                      slotProps.data.taskID?.taskTitle
                        ? slotProps.data?.taskID?.taskTitle
                        : "-"
                    }}
                  </a> -->
                  <img
                    @click="goToEditTask(slotProps.data.taskID.Id)"
                    v-if="slotProps.data.is_task"
                    width="40"
                    height="40"
                    src="@/assets/task_active.svg"
                  />
                  <img v-else width="40" height="40" src="@/assets/task.svg" />
                </template>
              </Column>
              <Column
                field="is_task"
                header="Type"
                :style="{ maxWidth: '14rem' }"
              >
                <template #body>
                  <img
                    width="30"
                    height="30"
                    src="@/assets/supporting_doc.svg"
                  />
                </template>
              </Column>
              <Column
                field="is_task"
                header="Origin"
                :style="{ maxWidth: '14rem' }"
              >
                <template #body="slotProps">
                  <div v-if="slotProps.data.is_automated_document">System</div>
                  <div v-else>User</div>
                </template>
              </Column>
              <Column field="status" header="Status">
                <template #body="slotProps">
                  {{
                    slotProps.data?.status
                      ? slotProps.data?.status.replace("_", " ")
                      : "-"
                  }}
                </template>
              </Column>
              <Column field="notes" header="Notes">
                <template #body="slotProps">
                  {{ slotProps.data.notes || "-" }}
                </template>
              </Column>
              <Column field="documents_files" header="Total">
                <template #body="slotProps">
                  {{
                    slotProps.data?.documents_files
                      ? slotProps.data?.documents_files.length
                      : "-"
                  }}
                </template>
              </Column>
              <Column :sortable="true" field="created_at" header="Created">
                <template #body="slotProps">
                  {{ formatDate(slotProps.data.created_at) || "-" }}
                </template>
              </Column>
              <Column :sortable="true" field="dateCompleted" header="Completed">
                <template #body="slotProps">
                  <span v-if="slotProps.data.dateCompleted">
                    {{ formatDate(slotProps.data.dateCompleted) }}
                  </span>
                  <span v-else>-</span>
                </template>
              </Column>
            </DataTable>
            <Paginator
              class="mt-2"
              :rows="10"
              :totalRecords="files && files.totalCount"
              :rowsPerPageOptions="[10, 20, 30]"
              template="RowsPerPageDropdown  CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
              currentPageReportTemplate="Showing {first} of {totalRecords} files"
              @page="onChangePageFile($event)"
            ></Paginator>
          </div>
        </div>
        <AddNewDoc
          v-if="uploadNewDoc"
          @save="refreshKey++"
          :show="uploadNewDoc"
          @close="uploadNewDoc = false"
        />
        <AddFile
          v-if="uploadFileDialog"
          :show="uploadFileDialog"
          @close="uploadFileDialog = false"
          @save="refreshKey++"
        />
      </template>
    </Card>

    <SupportingDoc
      @save="refreshKey++"
      :show="showSupportingDoc"
      :supportingDocId="supportingDocId"
      @close="showSupportingDoc = false"
      :modalRefreshKey="modalRefreshKey"
    />
    <Files
      @save="refreshKey++"
      :show="showFiles"
      @close="showFiles = false"
      :filesId="filesId"
      :modalRefreshKey="modalFileRefreshKey"
    />

    <EditTask
      v-if="showEditTask"
      :key="editTaskKey"
      :show="showEditTask"
      :taskId="selectedTaskId"
      @close="showEditTask = false"
      portal="application"
      @save="refreshKey++"
    />
  </div>
</template>

<script>
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import AddNewDoc from "./AddDocument.vue";
import SupportingDoc from "./SupportingDoc.vue";
import Files from "./Files.vue";
import { _get } from "../../../../../services/crudService";
import { SERVER_URL } from "../../../../../../constants";
import Paginator from "primevue/paginator";
import moment from "moment";
import AddFile from "./AddFile.vue";
import EditTask from "../Task/EditTask.vue";

export default {
  name: "Documents",
  components: {
    Card,
    DataTable,
    Column,
    AddNewDoc,
    SupportingDoc,
    Files,
    Paginator,
    AddFile,
    EditTask,
  },
  data() {
    return {
      uploadNewDoc: false,
      showSupportingDoc: false,
      showFiles: false,
      refreshKey: 10,
      selectedTaskId: "",
      showEditTask: false,
      isloading: false,
      statements: [],
      files: [],
      isSuppFileLoading: false,
      isFileLoading: false,
      supportingDocId: "",
      filesId: "",
      modalRefreshKey: 10,
      modalFileRefreshKey: 10,
      uploadFileDialog: false,
    };
  },
  watch: {
    refreshKey() {
      this.isloading = true;
      this.getSuppDoc();
      this.getFiles();
    },
  },
  methods: {
    goToEditTask(id) {
      if (!id) return;
      this.selectedTaskId = id;
      this.editTaskKey += 1;
      this.showEditTask = true;
    },
    uploadNew() {
      this.uploadNewDoc = true;
    },
    getTaskType(data) {
      if(data.taskID) {
        if(data.taskID.is_automated_task) {
          return data.taskID.is_edited ? 'System: Edited' : 'System: Generated'
        } else {
          return 'User: ' + data.taskID.createdUser
        }
      }
    },
    uploadNewFile() {
      this.uploadFileDialog = true;
    },
    formatDate(date) {
      date = new moment(date).add(600, "m").toDate();
      let d = moment
        .utc(date, "YYYYMMDDHHmmss")
        .local()
        .format("DD/MM/YYYY hh:mm:ss a");

      if (d === "Invalid date") {
        return "-";
      } else {
        return d;
      }
    },
    displayFileLength(data) {
      if (data?.filesList && data?.filesList.length) {
        if (data?.documents_files && data?.documents_files.length > 1) {
          return data?.filesList.length + data?.documents_files.length - 1;
        }

        return data?.filesList.length;
      } else {
        return data?.documents_files ? data?.documents_files.length : 0;
      }
    },
    openSupportingDoc(filesId, id) {
      if (filesId && filesId.length > 1) {
        this.supportingDocId = filesId;
      } else {
        this.supportingDocId = id;
      }
      this.modalRefreshKey += 1;
      this.showSupportingDoc = true;
    },
    openFiles(value) {
      this.modalFileRefreshKey += 1;
      this.filesId = value;
      this.showFiles = true;
    },
    getStatus(value) {
      if (value) {
        return value.replaceAll('"', "");
      }
    },
    async onChangePageSupporting(value) {
      this.isSuppFileLoading = true;
      await this.getSuppDoc(value.page + 1, value.rows);
      this.isSuppFileLoading = false;
    },
    async onChangePageFile(value) {
      this.isFileLoading = true;
      await this.getFiles(value.page + 1, value.rows);
      this.isFileLoading = false;
    },
    async getSuppDoc(page = 1, row = 20) {
      try {
        const response = await _get(
          SERVER_URL +
            `get-all-documents-api?portal=customer&applicationID=${this.$route.params.id}&pageSize=${row}&pageIndex=${page}&type=supporting_document`
        );

        if (response) {
          this.isloading = false;
          this.statements = response.data;
        }
      } catch (error) {
        this.isloading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }

      let files = [...this.statements.result];

      for (let i = 0; i < files.length; i++) {
        files[i]["filesId"] = [files[i].Id];
        files[i]["filesList"] = files[i].documents_files
          ? [files[i].documents_files]
          : [];
        for (let j = i + 1; j < files.length; j++) {
          if (files[j]?.taskID && files[i]?.taskID) {
            if (files[i]?.taskID?.Id === files[j]?.taskID?.Id) {
              if (files[i].filesId && files[i].filesId.length) {
                files[i].filesId = [...files[i].filesId, files[j]?.Id];

                if (files[j].documents_files) {
                  files[i].filesList.push(files[j].documents_files);
                }
                files = files.filter((list) => {
                  return list.Id !== files[j].Id;
                });
                j = i + 0;
              }
            }
          }
        }
      }

      this.statements.result = files;
    },
    async getFiles(page = 1, row = 10) {
      try {
        this.isloading = true;
        const response = await _get(
          SERVER_URL +
            `get-all-documents-api?portal=crm&applicationID=${this.$route.params.id}&pageSize=${row}&pageIndex=${page}&type=file`
        );

        if (response) {
          this.isloading = false;
          this.files = response.data;
        }
      } catch (error) {
        this.isloading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },
  created() {
    this.isloading = true;
    this.getSuppDoc();
    this.getFiles();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
:deep .p-datatable .p-sortable-column:focus {
  box-shadow: none !important;
}

:deep .p-datatable .p-sortable-column:hover {
  background: none !important;
}
.p-card {
  box-shadow: unset !important;
  border-radius: 6px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  height: 100% !important;
}
::v-deep .p-card-title {
  color: #020038 !important;
}
::v-deep .p-card-content {
  text-align: center !important;
  font-size: 14px !important;
  color: #020038 !important;
  font-weight: 500;
  font-size: 14px;
}
::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}

.color-highlight {
  color: $primary-color !important;
}
.upload {
  font-weight: 600;
  font-size: 16px;
  color: rgba(2, 0, 56, 0.4);
}
</style>
