<template>
  <div>
    <div class="content">
      <div class="form-inputs mt-4">
        <div class="p-grid align-items-center header">
          <p class="col"></p>
          <p class="col label">Application Values</p>
          <p class="col label">Current Values</p>
          <p class="col label">Current Value Source</p>
          <div class="col"></div>
        </div>
        <InputRow
          label="PEXA Fees"
          :value1="originalData.pexa_fees"
          :value2="latestData.pexa_fees"
        />
        <InputRow
          label="Total monthly commitments"
          :value1="commitments"
          :value2="commitments_latest"
        />
        <InputRow
          label="Monthly Net Surplus"
          :value1="
            originalData.calculated_fields &&
            originalData.calculated_fields.monthly_surplus
              ? originalData.calculated_fields.monthly_surplus
              : 0
          "
          :value2="
            latestData.calculated_fields &&
            latestData.calculated_fields.monthly_surplus
              ? latestData.calculated_fields.monthly_surplus
              : 0
          "
        />
        <InputRow
          label="LTI"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.LTI
              : ''
          "
          :value2="
            latestData.calculated_fields ? latestData.calculated_fields.LTI : ''
          "
        />
        <InputRow
          label="DTI"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.DTI
              : ''
          "
          :value2="
            latestData.calculated_fields ? latestData.calculated_fields.DTI : ''
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputRow from "../../InputRow.vue";
import { mapGetters } from "vuex";
export default {
  components: { InputRow },
  computed: {
    ...mapGetters({
      originalData: "loans/originalData",
      latestData: "loans/latestData",
    }),
  },
  data() {
    return {
      commitments: 0,
      commitments_latest: 0,
      overAllTotal: 0,
    };
  },
  created() {
    let other_liability = this.originalData.liability_others.reduce(
      (prev, current) => prev + current.amount_owning,
      0
    );
    let other_liability_latest = this.latestData.liability_others.reduce(
      (prev, current) => prev + current.amount_owning,
      0
    );
    let vehicles = this.originalData.liability_vechicles.reduce(
      (prev, current) => prev + current.amount_owning,
      0
    );
    let vehicles_latest = this.latestData.liability_vechicles.reduce(
      (prev, current) => prev + current.amount_owning,
      0
    );
    let cards = this.originalData.liability_cards.reduce(
      (prev, current) => prev + current.amount_owning,
      0
    );
    let cards_latest = this.latestData.liability_cards.reduce(
      (prev, current) => prev + current.amount_owning,
      0
    );

    this.commitments = other_liability + vehicles + cards;
    this.commitments_latest =
      other_liability_latest + vehicles_latest + cards_latest;
    this.expenseTotal = this.originalData.expenses.reduce(
      (prev, current) => prev + current.amount,
      0
    );
    // this.overAllTotal = this.incomeTotal - this.expenseTotal - this.commitments;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
</style>
