
export default {
  namespaced: true,
  state: {
    incomingPropertiesCount: 1,
    outgoingPropertiesCount: 1,
    no_of_applicants: 2,
    onSave: false,
    incomingProperties: [
      {
        state: "",
        postal_code: "",
        purchase_price: 0,
        purchase_price_used: 0,
        settlement_date: "",
        stamp_duty_be_paid: "",
        stamp_duty: 0,
        deposit_been_paid: "",
        deposit_amount: 0,
        type: "",
        type_used: '',
        category: "",
        zone: "",
      },

    ],
    outgoingProperties: [
      {
        state: "",
        postal_code: 0,
        estimated_price: 0,
        current_mortgage: 0,
        settlement_date: "",
        property_sold: "",
        deposit_held_in_trust: "",
        deposit_amount: 0,
        outstanding_loan_amount: 0,
        category: "",
        type: "",
        type_used: '',
        sale_price: "",
        purchase_price_used: ''
      },
      {
        state: "",
        postal_code: 0,
        estimated_price: 0,
        current_mortgage: 0,
        outstanding_loan_amount: 0,
        settlement_date: "",
        property_sold: "",
        deposit_held_in_trust: "",
        deposit_amount: 0,
        type: "",
        category: "",
        sale_price: "",
        purchase_price_used: ''
      },
      {
        state: "",
        postal_code: 0,
        estimated_price: 0,
        current_mortgage: 0,
        outstanding_loan_amount: 0,
        settlement_date: "",
        property_sold: "",
        deposit_held_in_trust: "",
        category: "",
        deposit_amount: 0,
        type: "",
        sale_price: "",
        purchase_price_used: ''
      },
    ],
    fees: {
      add_funds_requested: 0,
      total_savings_to_purchase: 0,
      savings_end_debt: 0,
      cap_loan_rate: 5.99,
      broker_fee_inc_gst: 0,
      qouted_setup_fee_percentage: 0,
      loan_deposit: 0,
    },
    incomeAndExpenses: {
      application_expenses: 0,
      implied_loan_term: 30,
      monthly_liability_payment: 0,
      applicant_1_relationship: "",
      applicant_2_relationship: "",
      applicant_1_dependants: "",
      applicant_2_dependants: "",
      applicant_1_annual_income: 0,
      applicant_2_annual_income: 0,
      joint_application: "",
    },
    applicantDetails: [{ age: 0 }, { age: 0 }],
    applicantDob: {
      applicant_1: "",
      applicant_2: "",
    },
    calculatedValues: {},
    EndDebt: {},
    lvr: {},
  },
  getters: {
    incomingPropertiesCount: (state) => state.incomingPropertiesCount,
    outgoingPropertiesCount: (state) => state.outgoingPropertiesCount,
    incomingProperties: (state) => state.incomingProperties,
    outgoingProperties: (state) => state.outgoingProperties,
    applicantDetails: (state) => state.applicantDetails,
    incomeAndExpenses: (state) => state.incomeAndExpenses,
    calculatedValues: (state) => state.calculatedValues,
    applicantDob: (state) => state.applicantDob,
    fees: (state) => state.fees,
    no_of_applicants: (state) => state.no_of_applicants,
    onSave: (state) => state.onSave,
  },
  mutations: {
    changeIncomingPropertyCount(state, payload) {
      state.incomingPropertiesCount = payload
    },
    changeOutgoingPropertyCount(state, payload) {
      state.outgoingPropertiesCount += payload
    },
    addApplicantDetails(state, payload) {
      state.applicantDetails = payload
    },
    setOutgoingPropertyCount(state, payload) {
      state.outgoingPropertiesCount = payload
    },
    addIncomingProperties(
      { incomingProperties },
      { value, fieldName, id, stampDuty, zone, category, purchase_price_used, type_used }
    ) {
      let property = incomingProperties[id - 1]

      property[fieldName] = value

      if (value === null) {
        property[fieldName] = 0
      }

      property["stamp_duty"] = stampDuty
      property["zone"] = zone
      property["category"] = category
      property["purchase_price_used"] = purchase_price_used
      property["type_used"] = type_used
    },
    addOutgoingProperties({ outgoingProperties }, { value, fieldName, id, zone, category, type_used, outstanding_loan_amount }) {
      let property = outgoingProperties[id - 1]

      property[fieldName] = value

      property.sale_price = property.estimated_price
      property.purchase_price_used = property.estimated_price

      if (value === null) {
        property[fieldName] = 0
      }
      property["zone"] = zone
      property["category"] = category
      property["type_used"] = type_used
      property['outstanding_loan_amount'] = outstanding_loan_amount

    },
    addFees({ fees }, { value, fieldName }) {
      fees[fieldName] = value

      if (value === null) {
        fees[fieldName] = 0
      }
    },
    addIncomeAndExpenses({ incomeAndExpenses }, { value, fieldName }) {
      incomeAndExpenses[fieldName] = value

      if (value === null) {
        incomeAndExpenses[fieldName] = 0
      }
    },
    setcalculatedValuess(state, payload) {
      state.calculatedValues = payload
    },
    setApplicantDob(state, payload) {
      state.applicantDob = payload

    },
    set_no_of_applicants(state, payload) {
      state.no_of_applicants = payload

    },
    prefillCalculatedValues(state, { field, value }) {
      state[field] = value
    },
    setOnSave(state, payload) {
      state.onSave = payload
    }
  },
  actions: {
    changeIncomingPropertyCount(context, payload) {
      context.commit("changeIncomingPropertyCount", payload)
    },
    changeOutgoingPropertyCount(context, payload) {
      context.commit("changeOutgoingPropertyCount", payload)
    },
    addIncomingProperties(context, payload) {
      context.commit("addIncomingProperties", payload)
    },
    addOutgoingProperties(context, payload) {
      context.commit("addOutgoingProperties", payload)
    },
    addFees(context, payload) {
      context.commit("addFees", payload)
    },
    addIncomeAndExpenses(context, payload) {
      context.commit("addIncomeAndExpenses", payload)
    },
    addApplicantDetails(context, payload) {
      context.commit("addApplicantDetails", payload)
    },
    setcalculatedValuess(context, payload) {
      context.commit("setcalculatedValuess", payload)
    },
    setApplicantDob(context, payload) {
      context.commit("setApplicantDob", payload)
    },
    setPrefillCalculatedValues(context, { field, value }) {
      context.commit("prefillCalculatedValues", { field, value })
    },
    set_no_of_applicants(context, payload) {
      context.commit("set_no_of_applicants", payload)
    },
    setOutgoingPropertyCount(context, payload) {
      context.commit("setOutgoingPropertyCount", payload)
    },
    setOnSave(context, payload) {
      context.commit('setOnSave', payload)
    }
  },
}

