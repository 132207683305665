<template>
  <div>
    <div class="title p-d-flex justify-content-between">
      <p class="title-text">Income and Expenses</p>
    </div>
    <div class="mt-2">
      <div class="flex align-items-center justify-content-end">
        <p class="label p-col-6">
          No of applicants <span style="color: red"> * </span>
        </p>
        <div class="p-col dropdown">
          <Dropdown
            @change="getApplicantCount($event)"
            width="100%"
            height="30px"
            :value="no_of_applicants.toString()"
            placeholder="-select-"
            :key="refreshKey"
            :items="['1', '2']"
            id="expense_applicants"
          />
        </div>
      </div>
      <div class="flex align-items-center justify-content-end">
        <p class="label p-col-6">
          Applicant 1 Annual Income <span style="color: red"> * </span>
        </p>
        <NumberField
          class="p-col-6"
          width="100%"
          :key="refreshKey"
          height="30px"
          :value="formData.applicant_1_annual_income"
          placeholder="Enter amount"
          @onInput="getFormData($event, 'applicant_1_annual_income')"
          :maxValue="9"
          :inValid="v$.formData.applicant_1_annual_income.$invalid && onSave"
          prefix="$"
          id="expense_applicant_1_annual_income"
        />
      </div>
      <div
        class="flex align-items-center justify-content-end mt-1"
        :class="disableInput() ? 'disable-input' : ''"
      >
        <p class="label p-col-6">
          Applicant 2 Annual Income <span style="color: red"> * </span>
        </p>
        <NumberField
          class="p-col-6"
          width="100%"
          height="30px"
          :value="formData.applicant_2_annual_income"
          placeholder="Enter amount"
          @onInput="getFormData($event, 'applicant_2_annual_income')"
          :maxValue="9"
          prefix="$"
          :disable="disableInput() ? true : false"
          :inValid="
            this.no_of_applicants == '2' &&
            v$.formData.applicant_2_annual_income.$invalid &&
            onSave
          "
          :key="refreshKey"
          id="expense_applicant_2_annual_income"
        />
      </div>
      <div class="flex align-items-center my-1">
        <p class="label p-col-6">
          Joint Application? <span style="color: red"> * </span>
        </p>
        <div class="p-col dropdown">
          <Dropdown
            @change="getFormData($event, 'joint_application')"
            width="100%"
            height="30px"
            :value="formData.joint_application"
            placeholder="-select-"
            :items="jointApplicant"
            optionLabel="name"
            :key="refreshKey"
            optionValue="value"
            :disable="disableInput() ? true : false"
            :inValid="
              this.no_of_applicants == '2' &&
              v$.formData.joint_application.$invalid &&
              onSave
            "
            id="expense_joint_application"
          />
        </div>
      </div>

      <div class="mb-3">
        <div class="flex align-items-center my-1">
          <p class="label p-col-6">
            Applicant 1 Relationship <span style="color: red"> * </span>
          </p>
          <div class="p-col-6 dropdown">
            <Dropdown
              @change="getFormData($event, 'applicant_1_relationship')"
              width="100%"
              :key="refreshKey"
              height="30px"
              :items="relationship"
              optionLabel="name"
              optionValue="value"
              :value="formData.applicant_1_relationship"
              :inValid="v$.formData.applicant_1_relationship.$invalid && onSave"
              placeholder="please select"
              id="expense_applicant_1_relationship"
            />
          </div>
        </div>
        <div
          class="flex align-items-center my-1"
          :class="disableInput() ? 'disable-input' : ''"
        >
          <p class="label p-col-6">
            Applicant 2 Relationship <span style="color: red"> * </span>
          </p>
          <div class="p-col-6 dropdown">
            <Dropdown
              @change="getFormData($event, 'applicant_2_relationship')"
              :items="relationship"
              optionLabel="name"
              optionValue="value"
              width="100%"
              height="30px"
              :value="formData.applicant_2_relationship"
              :disable="disableInput() ? true : false"
              :key="refreshKey"
              :inValid="
                this.no_of_applicants == '2' &&
                v$.formData.applicant_2_relationship.$invalid &&
                onSave
              "
              placeholder="-select-"
              id="expense_applicant_2_relationship"
            />
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="flex align-items-center my-1">
          <p class="label p-col-6">
            Applicant 1 Dependants <span style="color: red"> * </span>
          </p>
          <div class="p-col dropdown">
            <Dropdown
              @change="getFormData($event, 'applicant_1_dependants')"
              width="100%"
              optionLabel="name"
              optionValue="value"
              :key="refreshKey"
              height="30px"
              :items="appDependantsOpt"
              :value="formData.applicant_1_dependants"
              :inValid="v$.formData.applicant_1_dependants.$invalid && onSave"
              placeholder="-select-"
              id="expense_applicant_1_dependants"
            />
          </div>
        </div>
        <div
          class="flex align-items-center"
          :class="disableInput() ? 'disable-input' : ''"
        >
          <p class="label p-col-6">
            Applicant 2 Dependants <span style="color: red"> * </span>
          </p>
          <div class="p-col dropdown">
            <Dropdown
              @change="getFormData($event, 'applicant_2_dependants')"
              width="100%"
              height="30px"
              optionLabel="name"
              optionValue="value"
              :items="appDependantsOpt"
              :value="formData.applicant_2_dependants"
              :disable="disableInput() ? true : false"
              :key="refreshKey"
              :inValid="
                this.no_of_applicants == '2' &&
                v$.formData.applicant_2_dependants.$invalid &&
                onSave
              "
              placeholder="-select-"
              id="expense_applicant_2_dependants"
            />
          </div>
        </div>
        <div class="flex align-items-center my-1">
          <p class="label p-col-6">
            Applicant 1 age <span style="color: red"> * </span>
          </p>
          <!-- <NumberField
            class="p-col-6"
            width="100%"
            height="30px"
            :value="formData.applicant_1_age"
            placeholder="Enter age"
            @onInput="getApplicantAge($event, 'applicant_1_age')"
            maxValue="2"
          /> -->
          <div class="p-col-6">
            <DatePicker2
              placeholder="DD/MM/YYYY"
              :key="refreshKey"
              style="width: 100%"
              @change="getApplicantAge($event, 'applicant_1')"
              :value="formatDate(applicantDob.applicant_1)"
              height="30px"
              :maxDate="maxDate"
              :maxYear="maxYear"
              :inValid="v$.applicantDob.applicant_1.$invalid && onSave"
              minYear="1920"
              id="expense_applicant_1_dob"
            />
          </div>
        </div>
        <div
          class="flex align-items-center my-1 mt-2"
          :class="disableInput() ? 'disable-input' : ''"
        >
          <p class="label p-col-6">
            Applicant 2 age <span style="color: red"> * </span>
          </p>

          <!-- <NumberField
            class="p-col-6"
            width="100%"
            height="30px"
            :value="formData.applicant_2_age"
            placeholder="Enter age"
            @onInput="getApplicantAge($event, 'applicant_2_age')"
            :disable="disableInput() ? true : false"
            :key="refreshKey"
            maxValue="2"
          /> -->

          <div class="p-col-6" :class="disableInput() ? 'disable-input' : ''">
            <DatePicker2
              placeholder="DD/MM/YYYY"
              :key="refreshKey"
              style="width: 100%"
              @change="getApplicantAge($event, 'applicant_2')"
              :value="formatDate(applicantDob?.applicant_2)"
              height="30px"
              :inValid="
                this.no_of_applicants == '2' &&
                v$.applicantDob.applicant_2.$invalid &&
                onSave
              "
              :disable="disableInput() ? true : false"
              id="expense_applicant_2_dob"
            />
          </div>
        </div>
      </div>

      <div class="flex align-items-center justify-content-end mb-3">
        <p class="label p-col-6">
          Additional Monthly Liability Payments
          <span style="color: red"> * </span>
        </p>
        <NumberField
          class="p-col-6"
          :key="refreshKey"
          width="100%"
          height="30px"
          :value="formData.monthly_liability_payment"
          placeholder="Enter amount"
          @onInput="getFormData($event, 'monthly_liability_payment')"
          prefix="$"
          :inValid="v$.formData.monthly_liability_payment.$invalid && onSave"
          maxValue="9"
          id="expense_monthly_liability_payment"
        />
      </div>
      <div
        class="flex align-items-center disable-input justify-content-end mt-1"
      >
        <p class="label p-col-6">Implied Loan Term</p>
        <NumberField
          class="p-col-6"
          width="100%"
          :key="refreshKey"
          height="30px"
          :value="formData.implied_loan_term"
          placeholder="Enter amount"
          @onInput="getFormData($event, 'implied_loan_term')"
          suffix=" years"
          maxValue="2"
          id="expense_implied_loan_term"
          :disable="true"
        />
      </div>
      <div
        class="flex align-items-center justify-content-end mt-1 disable-input"
      >
        <p class="label p-col-6">Application Expenses</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          height="30px"
          :value="calculatedFields.hem_value"
          placeholder="Enter amount"
          @onInput="getFormData($event, 'hem_value')"
          prefix="$"
          maxValue="9"
          id="expense_hem_value"
        />
      </div>

      <div
        class="flex align-items-center disable-input justify-content-end mt-1"
      >
        <p class="label p-col-6">Monthly Income After Tax</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          height="30px"
          :value="calculatedFields.net_income_per_month"
          placeholder="Enter amount"
          @onInput="getFormData($event, 'net_income_per_month')"
          prefix="$"
          maxValue="9"
          id="expense_net_income_per_month"
        />
      </div>
      <div
        class="flex align-items-center disable-input justify-content-end mt-1"
      >
        <p class="label p-col-6">New Loan Repayment Monthly</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          height="30px"
          :value="calculatedFields.loan_repayment"
          placeholder="Enter amount"
          @onInput="getFormData($event, 'loan_repayment')"
          prefix="$"
          maxValue="9"
          id="expense_loan_repayment"
        />
      </div>
      <div
        class="flex align-items-center disable-input justify-content-end mt-1"
      >
        <p class="label p-col-6">Total Commitments</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          height="30px"
          :value="calculatedFields.total_commitments"
          placeholder="Enter amount"
          @onInput="getFormData($event, 'total_commitments')"
          prefix="$"
          maxValue="9"
          id="expense_total_commitments"
        />
      </div>
      <div
        class="flex align-items-center disable-input justify-content-end mt-1"
      >
        <p class="label text-highlight p-col-6">Net Surplus - Per Month</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          :value="calculatedFields.net_surplus_month"
          height="30px"
          @onInput="getFormData($event, 'net_surplus_month')"
          prefix="$"
          maxValue="9"
          placeholder="Enter amount"
          id="expense_net_surplus_month"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, maxValue } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";

export default {
  props: ["responseValue", "isloading"],

  data() {
    return {
      maxDate: null,
      maxYear: null,
      appDependantsOpt: [
        { name: "0", value: "0" },
        { name: "1", value: "1" },
        { name: "2", value: "2" },
        { name: "3", value: "3" },
        { name: "3+", value: "3" },
      ],
      jointApplicant: [
        { name: "Yes", value: true },
        { value: false, name: "No" },
      ],
      relationship: [
        { name: "Single", value: "Single" },
        {
          name: "Married/Defacto",
          value: "Married",
        },
      ],
      applicantDob: {
        applicant_1: "",
        applicant_2: "",
      },
      calculatedFields: {
        hem_value: 0,
        total_commitments: "",
        net_surplus_month: 0,
        net_income_per_month: 0,
        loan_repayment: "",
      },
      formData: {
        implied_loan_term: 30,
        monthly_liability_payment: 0,
        applicant_1_relationship: "",
        applicant_2_relationship: "",
        applicant_1_dependants: "",
        applicant_2_dependants: "",
        applicant_1_annual_income: 0,
        applicant_2_annual_income: 0,
        joint_application: "",
      },
      applicantDetails: [{ age: 0 }, { age: 0 }],
      refreshKey: 10,
      refreshKey2: 10,
    };
  },
  watch: {
    refreshKey2() {
      console.log("refresh++");
    },
    calculatedValues(result) {
      this.refreshKey++;
      for (const [key, value] of Object.entries(result)) {
        if (typeof value === "number") {
          this.calculatedFields[key] = value.toFixed(2);
        } else {
          this.calculatedFields[key] = value;
        }
        if (value === -1) {
          this.calculatedFields[key] = "-";
        }
      }
    },
    incomeAndExpenses() {
      this.formData = this.incomeAndExpenses;
      this.refreshKey++;
      this.$emit("validateIncomeExpenses", this.v$.$invalid);
    },
    applicantDetailsStore() {
      this.applicantDetails = this.applicantDetailsStore;
      this.refreshKey++;
      this.$emit("validateIncomeExpenses", this.v$.$invalid);
    },
    getApplicantDob() {
      this.applicantDob = this.getApplicantDob;
      if (this.no_of_applicants === 1) {
        this.applicantDob.applicant_2 = "";
      }
      // this.refreshKey2++;
      this.$emit("validateIncomeExpenses", this.v$.$invalid);
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    if (this.no_of_applicants == "2") {
      return {
        formData: {
          implied_loan_term: { required },
          monthly_liability_payment: {
            required,
            maxValue: maxValue(999999999),
          },
          applicant_1_relationship: { required },
          applicant_2_relationship: { required },
          applicant_1_dependants: { required },
          applicant_2_dependants: { required },
          applicant_1_annual_income: {
            required,
            maxValue: maxValue(999999999),
          },
          applicant_2_annual_income: {
            required,
            maxValue: maxValue(999999999),
          },
          joint_application: { required },
        },
        applicantDob: {
          applicant_1: { required },
          applicant_2: { required },
        },
      };
    } else if (this.no_of_applicants == "1") {
      return {
        formData: {
          implied_loan_term: { required },
          monthly_liability_payment: {
            required,
            maxValue: maxValue(999999999),
          },
          applicant_1_relationship: { required },
          applicant_1_dependants: { required },
          applicant_1_annual_income: {
            required,
            maxValue: maxValue(999999999),
          },
          joint_application: {},
        },
        applicantDob: {
          applicant_1: { required },
        },
      };
    }
  },
  methods: {
    ...mapActions({
      addApplicantDetails: "servicingCalc/addApplicantDetails",
      addIncomeAndExpenses: "servicingCalc/addIncomeAndExpenses",
      setApplicantDob: "servicingCalc/setApplicantDob",
      set_no_of_applicants: "servicingCalc/set_no_of_applicants",
    }),
    formatDate(date) {
      let d = new Date(date);

      if (d != "Invalid Date") {
        if (date) {
          return moment(date).format("DD/MM/YYYY");
        }
      }
      return date;
    },
    getApplicantCount(value) {
      // this.no_of_applicants = value;

      this.set_no_of_applicants(value);

      if (value === "1") {
        this.formData.applicant_2_age = "";
        this.formData.applicant_2_annual_income = 0;
        this.formData.applicant_2_dependants = "";
        this.formData.applicant_2_relationship = "";
        this.refreshKey++;
      }
    },
    getAge(date) {
      if (date == "Invalid Date") return 0;
      var dob = new Date(date);
      var month_diff = Date.now() - dob.getTime();
      var age_dt = new Date(month_diff);
      var year = age_dt.getUTCFullYear();
      var age = Math.abs(year - 1970);
      return age;
    },
    disableInput() {
      if (this.no_of_applicants == "1") {
        return true;
      } else {
        false;
      }
    },
    getFormData(value, fieldName) {
      if (value == "Invalid Date") return;

      this.formData[fieldName] = value;
      this.formData = { ...this.formData };

      this.addIncomeAndExpenses({ value, fieldName });
      this.$emit("validateIncomeExpenses", this.v$.$invalid);
    },
    getApplicantAge(value, fieldName) {
      this.$emit("validateIncomeExpenses", this.v$.$invalid);
      if (value == "Invalid Date" || !value) return;

      this.applicantDob[fieldName] = value;
      this.applicantDob = { ...this.applicantDob };

      if (fieldName === "applicant_1") {
        this.applicantDetails[0].age = this.getAge(value);
      } else if (fieldName === "applicant_2" && value) {
        this.applicantDetails[1].age = this.getAge(value);
      }

      this.addApplicantDetails(this.applicantDetails);
      this.setApplicantDob(this.applicantDob);

      this.$emit("validateIncomeExpenses", this.v$.$invalid);
    },

    insertApplicantDetail(idx) {
      if (this.applicantDetails[idx].age === 0) {
        return "";
      } else {
        return this.applicantDetails[idx].age;
      }
    },
  },
  computed: {
    ...mapGetters({
      incomeAndExpenses: "servicingCalc/incomeAndExpenses",
      applicantDetailsStore: "servicingCalc/applicantDetails",
      no_of_applicants: "servicingCalc/no_of_applicants",
      getApplicantDob: "servicingCalc/applicantDob",
      onSave: "servicingCalc/onSave",
      calculatedValues: "servicingCalc/calculatedValues",
    }),
  },
  created() {
    let eighteenYearsAgo = new Date();
    eighteenYearsAgo = eighteenYearsAgo.setFullYear(
      eighteenYearsAgo.getFullYear() - 18
    );
    this.maxDate = new Date(eighteenYearsAgo);
    this.maxYear = this.maxDate.getFullYear();

    this.formData = this.incomeAndExpenses;
    this.applicantDetails = this.applicantDetailsStore;
    this.refreshKey++;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.title {
  border-bottom: 1px solid rgba(2, 0, 56, 0.15);
  padding-bottom: 10px;

  .title-text {
    font-family: Gordita-bold;
    color: $secondary-color;
    font-size: 20px;
  }
}

:deep .dropdown .p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
  line-height: 15px;
}

.disable-input :deep .p-dropdown {
  background: rgba(2, 0, 56, 0.05) !important;
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

p {
  margin: 0;
}

:deep .dropdown .p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
  line-height: 15px;
}

:deep .p-dropdown .p-dropdown-label {
  line-height: 15px;
}

.label {
  padding-right: 0;
  line-height: 19px !important;
  font-size: 14px;
  font-family: Gordita-medium;
  color: $secondary-color;
  text-align: end;
}

.text-highlight {
  font-family: Gordita-bold !important;
}

:deep .p-col-6 {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
