<template>
  <Dialog
    :draggable="false"
    :visible="show"
    :modal="show"
    :style="{ width: '580px' }"
    header="Unsaved changes"
    position="top"
  >
    <div class="content">
      <div class="flex align-items-center">
        <div class="icon pi pi-exclamation-circle mr-3"></div>
        <div>
          This page contains unsaved changes. Do you want to leave this page?
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex align-items-center justify-content-end">
        <Button
          label="No"
          type="outline"
          :loading="isloading"
          @click="$emit('close')"
        />
        <Button
          label="Yes"
          type="primary"
          @click="$emit('save')"
          :loading="isloading"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";

export default {
  props: ["show"],

  components: {
    Dialog,
  },
};
</script>

<style scoped>
.icon {
  font-size: 2rem !important;
}
</style>