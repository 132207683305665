<template>
  <div>
    <div class="title p-d-flex justify-content-between">
      <p class="title-text">End Debt Calculator</p>
    </div>
    <!-- <div v-if="isloading">loading</div> -->
    <div class="mt-2">
      <div class="flex align-items-center justify-content-end disable-input">
        <p class="label p-col-6">(+) Purchase Property</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          height="30px"
          placeholder="label"
          :value="formData.purchase_property"
          prefix="$"
          id="end_debt_purchase_property"
        />
      </div>
      <div class="flex align-items-center justify-content-end disable-input mt-1">
        <p class="label p-col-6">(-) Deposit Paid</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          height="30px"
          :value="formData.deposit_paid"
          placeholder="label"
          prefix="$"
          id="end_debt_deposit_paid"
        />
      </div>
      <div class="flex align-items-center justify-content-end disable-input mt-1">
        <p class="label p-col-6">(+) Stamp duty</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          height="30px"
          :value="formData.stamp_duty"
          placeholder="label"
          prefix="$"
          id="end_debt_stamp_duty"
        />
      </div>
      <div class="flex align-items-center justify-content-end disable-input mt-1">
        <p class="label p-col-6">(+) Current mortgage</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          height="30px"
          :value="formData.op_current_mortgage"
          prefix="$"
          placeholder="label"
          id="end_debt_op_current_mortgage"
        />
      </div>
      <div class="flex align-items-center justify-content-center disable-input mt-1">
        <p class="label p-col-6">(+) Other Liabilities</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          height="30px"
          :value="formData.other_liabilities"
          placeholder="label"
          prefix="$"
          id="end_debt_other_liabilities"
        />
      </div>
      <div class="flex align-items-center justify-content-end disable-input mt-1">
        <p class="label p-col-6">(-) Savings</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          height="30px"
          :value="formData.savings"
          placeholder="label"
          prefix="$"
          id="end_debt_savings"
        />
      </div>

      <div class="flex align-items-center justify-content-end disable-input mt-1">
        <p class="label p-col-6">(+) Settlement Fees and Charges</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          prefix="$"
          height="30px"
          :value="formData.settlement_fee"
          placeholder="label"
          id="end_debt_settlement_fee"
        />
      </div>
      <div class="flex align-items-center justify-content-center disable-input mt-1">
        <p class="label text-highlight p-col-6">Funds to complete purchase</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          height="30px"
          :value="formData.funds_to_complete_purchase"
          placeholder="label"
          prefix="$"
          id="end_debt_funds_to_complete_purchase"
        />
      </div>
      <div class="flex align-items-center justify-content-center disable-input mt-3">
        <p class="label text-highlight p-col-6">Initial Amount Financed</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          prefix="$"
          width="100%"
          height="30px"
          :value="formData.initial_amount_financed"
          placeholder="label"
          id="end_debt_initial_amount_financed"
        />
      </div>
      <div class="flex align-items-center justify-content-center disable-input mt-1">
        <p class="label p-col-6">(+) Interest (on Capitalising Loan)</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          height="30px"
          :value="formData.interest_on_caps_loan"
          placeholder="label"
          prefix="$"
          id="end_debt_interest_on_caps_loan"
        />
      </div>
      <div class="flex align-items-center justify-content-center disable-input mt-1">
        <p class="label text-highlight p-col-6">Peak Debt</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          :value="formData.peak_debt"
          height="30px"
          placeholder="label"
          prefix="$"
          id="end_debt_peak_debt"
        />
      </div>
      <div class="flex align-items-center justify-content-center disable-input mt-3">
        <p class="label p-col-6">(-) Valuation (Existing Home)</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          height="30px"
          :value="formData.existing_home_valuation_fee"
          placeholder="label"
          prefix="$"
          id="end_debt_existing_home_valuation_fee"
        />
      </div>

      <div class="flex align-items-center justify-content-center disable-input mt-1">
        <p class="label p-col-6">(+) Margin (Existing Home Valuation)</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          :value="formData.existing_home_margin"
          height="30px"
          placeholder="label"
          prefix="$"
          id="end_debt_existing_home_margin"
        />
      </div>
      <div class="flex align-items-center justify-content-center disable-input mt-1">
        <p class="label p-col-6">(+) Selling costs (Existing Home)</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          prefix="$"
          class="p-col-6"
          :value="formData.existing_home_selling_cost"
          width="100%"
          height="30px"
          id="end_debt_existing_home_selling_cost"
          placeholder="label"
        />
      </div>
      <div class="flex align-items-center justify-content-center disable-input mt-1">
        <p class="label p-col-6">(+) Deposit received (Existing Home)</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          width="100%"
          height="30px"
          prefix="$"
          :value="formData.existing_home_deposit_received"
          placeholder="label"
          id="end_debt_existing_home_deposit_received"
        />
      </div>
      <div class="flex align-items-center justify-content-center disable-input mt-1">
        <p class="label p-col-6">(-) Savings to Contribute to End debt</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          class="p-col-6"
          prefix="$"
          width="100%"
          height="30px"
          :value="formData.savings_to_end_debt"
          placeholder="label"
          id="end_debt_savings_to_end_debt"
        />
      </div>
      <div class="flex align-items-center justify-content-center disable-input mt-1">
        <p class="label text-highlight p-col-6">End Debt</p>
        <NumberField
          :key="refreshKey"
          :disable="true"
          prefix="$"
          class="p-col-6"
          width="100%"
          :value="formData.end_debt"
          height="30px"
          placeholder="label"
          id="end_debt_end_debt"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["responseValue", "isloading"],
  data() {
    return {
      formData: {
        purchase_property: 0,
        deposit_paid: 0,
        stamp_duty: 0,
        op_current_mortgage: 0,
        other_liabilities: 0,
        savings: 0,
        settlement_fee: 0,
        funds_to_complete_purchase: 0,
        initial_amount_financed: 0,
        interest_on_caps_loan: 0,
        peak_debt: 0,
        existing_home_valuation_fee: 0,
        existing_home_margin: 0,
        existing_home_selling_cost: 0,
        existing_home_deposit_received: 0,
        savings_to_end_debt: 0,
        end_debt: 0,
      },
      refreshKey: 10,
    };
  },
  watch: {
    // responseValue(result) {
    //   this.refreshKey++;

    //   for (const [key, value] of Object.entries(result)) {
    //     if (typeof value === "number") {
    //       this.formData[key] = value.toFixed(2);
    //     } else {
    //       this.formData[key] = value;
    //     }
    //   }
    // },

    calculatedValues(result) {
      this.refreshKey++;

      for (const [key, value] of Object.entries(result)) {
        if (typeof value === "number") {
          this.formData[key] = value.toFixed(2);
        } else {
          this.formData[key] = value;
        }
        if (value === -1) {
          this.formData[key] = "-";
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      calculatedValues: "servicingCalc/calculatedValues",
    }),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.title {
  border-bottom: 1px solid rgba(2, 0, 56, 0.15);
  padding-bottom: 10px;

  .title-text {
    font-family: Gordita-bold;
    color: $secondary-color;
    font-size: 20px;
  }
}

:deep .dropdown .p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
  line-height: 15px;
}

.disable-input :deep .p-dropdown {
  background: rgba(2, 0, 56, 0.05) !important;
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

p {
  margin: 0;
}

.label {
  padding-right: 0;
  line-height: 19px !important;
  font-size: 14px;
  font-family: Gordita-medium;
  color: $secondary-color;
  text-align: end;
}

.text-highlight {
  font-family: Gordita-bold !important;
}

:deep .p-col-6 {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
