<template>
  <div class="mr-top">
    <Panel :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>
      <template #header>
        <div @click="toggle" class="sub-title full-width pointer">
          Settlement Fees & Charges
        </div>
      </template>

      <div :key="fieldKey" class="form-inputs mr-top-10px">
        <div class="p-d-flex flex-gap align-items-end mr-b-5px">
          <div>
            <p class="label flex align-items-start">
              + Legal Fees <span><i class="pi pi-info-circle ml-1"></i></span>
            </p>
            <NumberField
              mode="decimal"
              :format="true"
              prefix="$"
              width="100%"
              @onInput="(e) => getFormData(e, 'legal_fees')"
              :value="settlementFormData.legal_fees"
            />
          </div>

          <div>
            <p class="label flex align-items-end">
              + Disbursements Fee
              <span><i class="pi pi-info-circle ml-1"></i></span>
            </p>
            <NumberField
              mode="decimal"
              :format="true"
              prefix="$"
              width="100%"
              :value="settlementFormData.legal_disbursements"
              @onInput="(e) => getFormData(e, 'legal_disbursements')"
            />
          </div>

          <div>
            <p class="label flex align-items-start">
              + PEXA Fees
              <span><i class="pi pi-info-circle ml-1"></i></span>
            </p>
            <NumberField
              mode="decimal"
              :format="true"
              prefix="$"
              width="100%"
              @onInput="(e) => getFormData(e, 'pexa_fees')"
              :value="settlementFormData.pexa_fees"
            />
          </div>

          <div>
            <p class="label flex align-items-end">
              + Land Titles Office<br />
              Registration Fee
              <span><i class="pi pi-info-circle ml-1"></i></span>
            </p>
            <NumberField
              mode="decimal"
              :format="true"
              prefix="$"
              width="100%"
              @onInput="(e) => getFormData(e, 'land_titles_office_reg_fee')"
              :value="settlementFormData.land_titles_office_reg_fee"
            />
          </div>

          <div>
            <p class="label flex align-items-start">
              + Buffer
              <span><i class="pi pi-info-circle ml-1"></i></span>
            </p>
            <NumberField
              mode="decimal"
              :format="true"
              prefix="$"
              width="100%"
              @onInput="(e) => getFormData(e, 'settlement_buffer')"
              :value="settlementFormData.settlement_buffer"
            />
          </div>

          <div class="highlight-fields disable-input">
            <p class="label">Settlement Fees and Charges</p>
            <NumberField
              mode="decimal"
              :format="true"
              prefix="$"
              :value="settlement_fees"
              :disable="true"
              width="100%"
              :key="totalRefreshKey"
            />
          </div>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Panel from "primevue/panel";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      settlementFormData: {
        legalFees: { required },
        legal_disbursements: { required },
        pexa_fees: { required },
        land_titles_office_reg_fee: { required },
        settlement_buffer: { required },
      },
    };
  },
  components: { Panel },
  emits: ["settlementFormData"],
  data() {
    return {
      collapsed: true,
      fieldKey: 0,
      settlementFormData: {
        legal_fees: 1650,
        legal_disbursements: 115,
        pexa_fees: 115,
        land_titles_office_reg_fee: 0,
        settlement_buffer: 5000,
      },
    };
  },
  computed: {
    ...mapGetters({ selectedApplication: "applications/selectedApplication" }),
    // settlement_fees: {
    //   get: function () {
    //     let total =
    //       this.settlementFormData.legal_fees +
    //       this.settlementFormData.legal_disbursements +
    //       this.settlementFormData.pexa_fees +
    //       this.settlementFormData.land_titles_office_reg_fee +
    //       this.settlementFormData.settlement_buffer;
    //     this.setSettlementData({ field: "settlement_fees", value: total });
    //     return total;
    //   },
    // },
  },
  methods: {
    ...mapActions({ setSettlementData: "summary/setSettlementData" }),
    toggle() {
      this.collapsed = !this.collapsed;
    },
    getFormData(value, fieldName) {
      this.settlementFormData[fieldName] = value;
      this.settlementFormData = { ...this.settlementFormData };
      this.setSettlementData({ field: fieldName, value: value });
      this.totalRefreshKey += 1;
    },
    setData() {
      if (this.selectedApplication?.application_id) {
        this.settlementFormData.legal_fees = this.selectedApplication.legal_fees
          ? this.selectedApplication.legal_fees
          : 0;
        this.settlementFormData.legal_disbursements = this.selectedApplication
          .legal_disbursements
          ? this.selectedApplication.legal_disbursements
          : 0;
        this.settlementFormData.pexa_fees = this.selectedApplication.pexa_fees
          ? this.selectedApplication.pexa_fees
          : 0;
        this.settlementFormData.land_titles_office_reg_fee = this
          .selectedApplication.land_titles_office_reg_fee
          ? this.selectedApplication.land_titles_office_reg_fee
          : 0;
        this.settlementFormData.settlement_buffer = this.selectedApplication
          .settlement_buffer
          ? this.selectedApplication.settlement_buffer
          : 0;
        Object.keys(this.settlementFormData).map((key) => {
          this.setSettlementData({
            field: key,
            value: this.settlementFormData[key],
          });
        });
        this.settlement_fees =
          this.selectedApplication &&
          this.selectedApplication.calculated_fields &&
          this.selectedApplication.calculated_fields.settlement_fee;
      }
    },
  },
  created() {
    this.setData();
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid #02003826 !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

.highlight-fields {
  .label {
    font-family: Gordita-bold !important;
  }
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

::v-deep .p-col-4,
.p-col-2 {
  padding-bottom: 0;
  padding-top: 0;
}

.mr-b-5px {
  margin-bottom: 5px;
}

.mr-top {
  margin-top: 2.5rem;
}

.mr-top-10px {
  margin-top: 10px;
}

.flex-gap {
  gap: 0.625rem;
}
</style>
