<template>
	<div>
		<div class="input-wrapper">
			<InputNumber
				v-if="mode === 'decimal' || prefix || suffix"
				class="input-field"
				:class="inValid && 'p-invalid'"
				v-model="editValue"
				:disabled="disable"
				:suffix="suffix"
				@keydown="keydown"
				:prefix="prefix"
				:max="max"
				:placeholder="placeholder"
				:mode="mode"
				:style="{
					height: `${height}`,
					width: `${width}`,
					border: `1px solid ${color} !important`,
					color: `${color}`,
				}"
				@input="onInputNumber"
				:key="refreshKey || key"
				:minFractionDigits="mode === 'decimal' && 2"
				:maxFractionDigits="maxFractionDigits"
				:format="true"
				:inputId="id"
				:id="id"
			/>

			<InputNumber
				v-else
				:inputId="id || 'integeronly'"
				:id="id"
				:mode="mode"
				:minFractionDigits="minFractionDigits"
				:maxFractionDigits="maxFractionDigits"
				:class="inValid && 'p-invalid'"
				class="input-field"
				@keydown="keydown"
				:placeholder="placeholder"
				:format="format ? format : false"
				:max="max"
				:disabled="disable"
				min="0"
				:value="editValue"
				@input="onNumberInput"
				autocomplete="off"
				:key="refreshKey || key"
				:style="{
					height: `${height}`,
					width: `${width}`,
					border: `1px solid ${color} !important`,
					color: `${color}`,
				}"
			/>
		</div>
	</div>
</template>

<script>
import InputNumber from "primevue/inputnumber";

export default {
	name: "TextField",
	props: [
		"icon",
		"placeholder",
		"label",
		"disable",
		"height",
		"width",
		"border",
		"color",
		"value",
		"required",
		"errorMessages",
		"key",
		"type",
		"disabled",
		"format",
		"max",
		"mode",
		"minFractionDigits",
		"maxFractionDigits",
		"prefix",
		"suffix",
		"maxValue",
		"inValid",
		"id",
	],
	data() {
		return {
			editValue: "",
			refreshKey: 10,
		};
	},
	components: { InputNumber },
	methods: {
		onInputNumber(value) {
			if (value.value) {
				if (
					value.value.toString().length > this.maxValue - 1 &&
					!this.minFractionDigits
				) {
					// this.$emit("onInput", this.editValue)
					this.$emit("onInput", value.value);
					this.refreshKey += 1;
					return;
				}
			}

			if (!this.minFractionDigits) {
				this.editValue = value.value;
			}
			this.$emit("onInput", value.value);
		},
		onNumberInput(value) {
			if (value.value) {
				if (
					value.value.toString().length > this.maxValue &&
					!this.minFractionDigits
				) {
					this.$emit("onInput", this.editValue);
					this.refreshKey += 1;
					return;
				}
			}
			if (!this.minFractionDigits) {
				this.editValue = value.value;
			}
			this.$emit("onInput", value.value);
		},

		keydown(e) {
			let max = this.maxValue ? this.maxValue : 9;
			if (this.editValue && this.editValue.toString().length > max - 1) {
				e.preventDefault();
				return false;
			}
		},
	},

	watch: {
		editValue() {
			this.$emit("change", this.editValue);
		},
	},
	mounted() {
		this.editValue = this.value;
	},
};
</script>

<style lang="scss" scoped>
.input-wrapper {
	display: flex;
	align-items: center;
}
.input-field {
	border-color: unset;
	outline: 0 none;
	outline-offset: 0;
	box-shadow: unset !important;
	border-radius: unset !important;
}

.icons {
	font-weight: bold !important;
	height: 100%;
	font-size: 1.2rem;
	padding: 10px;
	min-width: 40px;
	// border-right: 1px solid #ced4da;
	border: 1px solid #ced4da;
	margin-top: 1rem;
}

.p-inputtext {
	border: 1px solid rgba(2, 0, 56, 0.15);
	border-radius: 4px !important;
	font-family: Gordita-medium !important;
}
::v-deep .p-inputtext {
	font-family: Gordita-medium !important;
	width: 100%;
}
// .p-inputtext:enabled:focus {
//   border-color: rgba(2, 0, 56, 0.15);
//   border: 1px solid rgba(2, 0, 56, 0.3);
//   box-shadow: unset;
// }
.p-inputtext:enabled:hover {
	border-color: rgba(2, 0, 56, 0.15);
	border: 1px solid rgba(100, 97, 170, 0.3);
	box-shadow: unset;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.label {
	color: #020038;
}
.p-inputtext:enabled:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.1rem rgba(2, 0, 56, 0.3) !important;
	border-color: rgba(2, 0, 56, 0.15);
}
</style>
