<template>
  <div class="mr-top">
    <Panel :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>
      <template #header>
        <div @click="toggle" class="sec-color-text hk-sb full-width pointer">
          Special Conditions
        </div>
      </template>
      <Card>
        <template #content>
          <Spinner v-if="isloading" class="flex justify-content-between mt-3" />
          <DataTable
            v-else
            responsiveLayout="scroll"
            dataKey="id"
            :value="lists"
            id="special-condition"
          >
            <template #empty> <div class="p-2">No data found.</div> </template>

            <Column field="description" header="Description">
              <template #body="slotProps">
                <a
                  class="table-column color-highlight"
                  :class="!editAccess ? '' : 'pointer'"
                  @click="editSplConditon(slotProps.data)"
                >
                  {{ slotProps.data?.description || "-" }}
                </a>
              </template>
            </Column>
            <Column field="status" header="Status">
              <template #body="slotProps">
                <div class="table-column">
                  {{ slotProps.data?.status || "-" }}
                </div>
              </template>
            </Column>
            <Column field="created_by" header="Created by">
              <template #body="slotProps">
                <div class="table-column">
                  {{ slotProps.data?.created_by || "-" }}
                </div>
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </Panel>
  </div>
</template>

<script>
import Panel from "primevue/panel";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { _get } from "../../../../services/crudService";
import { APP_URL } from "../../../../../constants";
export default {
  components: { Panel, Card, DataTable, Column },
  props: ["selectedOutcome", "selectedVersion", "playVersion", "key", "editAccess"],
  data() {
    return {
      collapsed: false,
      expandedRows: [],
      data: [],
      lists: [],
      pending: [],
      cancelled: [],
      complete: [],
      isloading: false,
    };
  },
  watch: {
    key() {
      this.getList();
    },
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
    editSplConditon(data) {
      if (!this.$props.editAccess) return;
      this.$emit("edit", data);
    },
    async getList() {
      this.isloading = true;
      try {
        const response = await _get(
          APP_URL + `loan_special_conditions?application_id=${this.$route.params.id}`
        );

        if (response) {
          if (response.data.result) {
            this.data = response.data.result;

            this.complete = this.data.filter((list) => list.status == "complete");
            this.pending = this.data.filter((list) => list.status == "pending");
            this.cancelled = this.data.filter((list) => list.status == "cancelled");

            this.lists = [...this.complete, ...this.pending, ...this.cancelled];
          }
          this.isloading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

.highlight-bg1 {
  background-color: rgba(245, 174, 69, 0.89) !important;
}
.highlight-bg2 {
  background-color: rgba(252, 239, 69, 0.863) !important;
}
.highlight-bg3 {
  background-color: rgba(240, 108, 108, 0.89) !important;
}
.highlight-bg4 {
  background-color: rgba(185, 252, 77, 0.89) !important;
}

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid #02003826 !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
  padding: unset !important;
}

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

.highlight-fields {
  .label {
    font-family: Gordita-bold !important;
  }
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

::v-deep .p-col-4,
.p-col-2 {
  padding-bottom: 0;
  padding-top: 0;
}

.mr-b-5px {
  margin-bottom: 5px;
}

.mr-top {
  margin-top: 2.5rem;
}

.mr-top-10px {
  margin-top: 10px;
}

.flex-gap {
  gap: 0.625rem;
}
.align-items {
  gap: 26.5rem;
  margin-left: -0.5rem;
  color: rgba(2, 0, 56, 0.3);
  font-family: Gordita-medium;
  font-weight: 600;
}
.label-content {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
  white-space: nowrap;
  margin-left: -1rem;
  font-weight: 600;
}
.call {
  gap: 4rem;
}
.input-text {
  margin-top: -1rem;
}
.sec-color-text {
  font-size: 1.15rem;
  font-family: Gordita-bold;
  color: #020038;
  font-weight: 600;
  padding-bottom: 1rem;
}
.flex-content-item {
  gap: 2rem;
}
.col-4 {
  flex: 0 0 auto;
  padding: unset !important;
  width: 33.3333%;
}
::v-deep .p-float-label {
  margin-bottom: unset !important;
}
.col-6 {
  flex: 0 0 auto;
  padding: unset !important;
  width: 50%;
}
::v-deep .p-panel {
  margin-top: 2.5rem;
  border: 1px solid rgba(2, 0, 56, 0.2);
  padding: 1rem;
  border-radius: 4px;
}
::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}

::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
  padding: 1rem 0 !important;
}
.color-highlight {
  color: $primary-color !important;
  text-decoration: none;
}

::v-deep .p-paginator-bottom {
  display: none !important;
}
p {
  font-size: 1rem;
  margin: 0;
}

.sec-content {
  background: rgba(2, 0, 56, 0.03) !important;
  width: 100%;
  padding: 1rem 1rem;
}

.table-column {
  display: block;
  padding: 0.5rem 0;
  margin: 5px 15px;
}
::v-deep .p-card {
  background: #ffffff;
  color: #495057;
  box-shadow: unset !important;
  border-radius: 3px;
}
::v-deep .p-panel {
  margin-top: 2.5rem;
  border: 1px solid #ced4da !important;
  padding: 1rem;
  border-radius: 4px;
}
::v-deep .p-card .p-card-content {
  padding: 0 0 !important;
}
::v-deep .p-card .p-card-body {
  padding: 0 !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
::v-deep .p-panel .p-panel-content {
  padding: 0rem !important;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 0 none;
}
::v-deep .p-datatable-wrapper {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}
</style>
