<template>
  <div class="mr-top">
    <div class="heading flex align-items-center justify-content-between">
      <p class="sub-title">New Loan Repayments Monthly</p>
    </div>
    <div class="form-inputs mr-top-10px">
      <div class="p-grid">
        <div class="p-col-10 p-grid align-items-end">
          <div class="p-col-3">
            <p class="label">Residual Loan Amount</p>
            <NumberField
              width="100%"
              :value="calculatedFields.residual_loan_amount"
              @onInput="getFormData($event, 'residual_loan_amount')"
              mode="decimal"
              :format="true"
              prefix="$"
            />
          </div>

          <div class="p-col-3">
            <p class="label">Servicing Rate</p>
            <NumberField
              width="100%"
              :key="rateRefreshKey"
              mode="decimal"
              suffix="%"
              :value="calculatedFields.service_rate"
              @onInput="getFormData($event, 'service_rate')"
            />
          </div>

          <div class="p-col-3">
            <p class="label">Implied loan term (years)</p>
            <NumberField
              width="100%"
              :key="termRefreshKey"
              :value="calculatedFields.implied_loan_term"
              @onInput="getFormData($event, 'implied_loan_term')"
            />
          </div>

          <div class="p-col-3">
            <p class="label">Loan Type</p>
            <TextField
              @onInput="(e) => getFormData(e, 'loan_type')"
              :value="calculatedFields.loan_type"
              width="100%"
            />
          </div>
        </div>

        <div class="p-col-2 highlight-fields disable-input">
          <p class="label">New Loan Repayments Monthly</p>
          <NumberField
            mode="decimal"
            :format="true"
            prefix="$"
            :disable="true"
            width="100%"
            :value="
              calculatedFields.new_loan_repayment_monthly
                ? calculatedFields.new_loan_repayment_monthly.toFixed(3)
                : 0
            "
            @getInputValue="getFormData($event, 'new_loan_repayment_monthly')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters({ selectedApplication: "applications/selectedApplication" }),
  },
  data() {
    return {
      calculatedFields: {
        residual_loan_amount: 0,
        new_loan_repayment_monthly: 0,
        service_rate: 0,
        implied_loan_term: 30,
        loan_type: "P/I",
      },
      termRefreshKey: 10,
      rateRefreshKey: 20,
    };
  },
  created() {
    this.setData();
  },
  methods: {
    ...mapActions({ setRepaymentData: "summary/setRepaymentData" }),
    setData() {
      if (this.selectedApplication.application_id) {
        const { calculated_fields } = this.selectedApplication;
        this.calculatedFields.residual_loan_amount =
          calculated_fields.residual_loan_amount
            ? calculated_fields.residual_loan_amount
            : 0;
        this.calculatedFields.loan_type = calculated_fields.loan_type
          ? calculated_fields.loan_type
          : "P/I";
        this.calculatedFields.new_loan_repayment_monthly =
          calculated_fields.new_loan_repayment_monthly
            ? Number(calculated_fields.new_loan_repayment_monthly)
            : 0;
        this.calculatedFields.service_rate = calculated_fields.service_rate
          ? calculated_fields.service_rate * 100
          : 0;
        this.calculatedFields.implied_loan_term =
          calculated_fields.implied_loan_term
            ? calculated_fields.implied_loan_term
            : 30;
        this.setRepaymentData(this.calculatedFields);
      }
    },
    getFormData(value, fieldName) {
      this.calculatedFields[fieldName] = value;
      this.calculatedFields = { ...this.calculatedFields };
      if (fieldName == "implied_loan_term") {
        if (value > 30) {
          this.calculatedFields.implied_loan_term = 30;
          this.termRefreshKey += 1;
        }
      }
      if (fieldName == "service_rate") {
        if (value > 100) {
          this.calculatedFields.service_rate = 100;
          this.rateRefreshKey += 1;
        }
      }
      this.setRepaymentData(this.calculatedFields);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

::v-deep .p-col-10 {
  padding-bottom: 0;
  padding-top: 0;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.highlight-fields {
  .label {
    font-family: Gordita-bold !important;
  }
}

.heading {
  background: rgba(2, 0, 56, 0.03);
  padding: 12px;

  p {
    margin: 0;
  }
}

.mr-b-5px {
  margin-bottom: 5px;
}

.mr-top {
  margin-top: 2.5rem;
}

.mr-top-10px {
  margin-top: 10px;
}

.flex-gap {
  gap: 0.625rem;
}

.flex-gap-1 {
  gap: 0.313rem;
}

.flex-gap-2 {
  gap: 1.875rem;
}
</style>
