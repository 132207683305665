<template>
  <div>
    <div class="content">
      <div class="form-inputs mt-4">
        <div class="p-grid align-items-center header">
          <p class="col"></p>
          <p class="col label">Application Values</p>
          <p class="col label">Current Values</p>
          <p class="col label">Current Value Source</p>
          <div class="col"></div>
        </div>
        <InputRow
          label="Residual loan amount"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.residual_loan_amount
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.residual_loan_amount
              : ''
          "
        />
        <InputRow
          label="Servicing rate"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.service_rate
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.service_rate
              : ''
          "
        />
        <InputRow
          label="New loan repayments monthly"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.monthly_repayment
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.monthly_repayment
              : ''
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputRow from "../../InputRow.vue";
export default {
  components: { InputRow },
  computed: {
    ...mapGetters({
      originalData: "loans/originalData",
      latestData: "loans/latestData",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
</style>
