<template>
  <Dialog
    :draggable="false"
    :visible="show"
    :modal="show"
    :style="{ width: '580px' }"
  >
    <template #header>
      <h1 class="modal-title">Supporting document</h1>
      <i class="icon pi pi-times" @click="close" style="margin-left: auto" />
    </template>
    <div class="modal-content">
      <Spinner v-if="isLoading" class="flex justify-content-between" />
      <div v-else>
        <div>Document title</div>
        <TextField
          width="100%"
          placeholder="Task Title"
          @onInput="getFormData($event, 'document_name')"
          :value="formData.document_name"
          class="mt-2"
        />
        <div class="flex mt-2">
          <div class="col">
            <div>Document type</div>
            <Dropdown
              @change="getFormData($event, 'document_type')"
              :value="formData.document_type"
              width="100%"
              placeholder="-Select-"
              class="mt-2"
              :items="documentTypeList"
              optionLabel="name"
              optionValue="value"
              :disable="true"
            />
          </div>
          <div class="col ml-2">
            <div>Status</div>
            <Dropdown
              width="100%"
              placeholder="Status"
              class="mt-2"
              :value="formData.status"
              @change="getFormData($event, 'status')"
              :items="documentStatusList"
              optionLabel="name"
              optionValue="value"
            />
          </div>
        </div>

        <div class="mt-2">Select files</div>
        <div
          class="file"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop="drop"
        >
          <input
            type="file"
            name="fields[assetsFieldHandle][]"
            id="assetsFieldHandle"
            class="w-px h-px opacity-0 overflow-hidden absolute"
            @change="onChange"
            ref="fileV"
            accept=".pdf,.jpg,.jpeg,.png"
            :key="fileKey"
          />

          <label for="assetsFieldHandle" class="filelabel">
            <div class="icon-text p-d-flex p-jc-center">
              <img
                class="icon"
                :src="require('@/assets/upload_icon.svg')"
                alt=""
              />
              <p class="text">
                Drag and drop files here or <span>browse</span>
              </p>
            </div>
          </label>

          <div class="file-list mt-2" v-if="this.filelist.length" v-cloak>
            <p class="pm-txt-color modal-content">Uploaded files</p>
            <li
              class="text-sm p-1 p-d-flex p-ai-center"
              v-for="file of filelist"
              :key="file.name"
            >
              <div class="p-d-flex icon-name">
                <img :src="require('@/assets/tick_icon.svg')" alt="" />
                <div class="file-details">
                  <p class="file-name pm-text-color">{{ file.name }}</p>
                  <div class="time-size p-d-flex">
                    <p class="time lt-txt-color">
                      Uploaded: {{ formatDate() }}
                    </p>
                    <p class="size lt-txt-color">
                      Size: {{ getSize(file.size) }}
                    </p>
                  </div>
                </div>
              </div>
              <img
                class="icon"
                @click="remove(filelist.indexOf(file))"
                :src="require('@/assets/cross_icon.svg')"
                alt=""
              />
            </li>
          </div>
        </div>

        <div class="mt-3">
          <div
            v-for="files of supportDocuments"
            :key="files.Id"
            class="flex align-items-center justify-content-between"
          >
            <div
              class="flex align-items-center"
              :class="files.document_url ? 'cursor-pointer' : ''"
              @click="downloadDocument(files.document_url)"
            >
              <i class="pi pi-check mr-2"></i>
              <a class="color-highlight pm-color-txt">{{ files.file_name }}</a>
            </div>
            <span
              class="delete-document pm-color-txt"
              @click="deleteDocument(files.Id)"
              >Delete</span
            >
          </div>
        </div>

        <div class="mt-2">Notes</div>
        <TextArea
          placeholder="Document description"
          width="100%"
          class="mt-2"
          @onInput="getFormData($event, 'notes')"
          :value="formData.notes"
        />
      </div>
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button
          label="Save changes"
          type="primary"
          @click="save"
          :loading="isloading"
        />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">
          Close
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import moment from "moment";
import Dialog from "primevue/dialog";
import { convertFiletoBase64 } from "../../../../../services/FileConvert";
import { SERVER_URL } from "../../../../../../constants";
import { _get, _delete, _post } from "../../../../../services/crudService";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "EditTask",
  props: ["show", "supportingDocId", "modalRefreshKey"],
  components: { Dialog },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      filelist: { required },
      formData: {
        document_name: { required },
        document_type: { required },
        notes: { required },
      },
    };
  },
  data() {
    return {
      category: { id: 1, label: "Mark task as complete", value: false },
      filelist: [],
      formData: {
        document_name: "",
        document_type: "",
        notes: "",
        id: "",
        status: "",
      },
      isloading: false,
      isLoading: false,
      documentTypeList: [
        { name: "Supporting Document", value: "supporting_documents" },
      ],
      supportDocuments: [],
      documentStatusList: [
        {
          name: "Completed",
          value: "completed",
        },
        {
          name: "No longer required",
          value: "no_longer_required",
        },
        {
          name: "Review required",
          value: "review_required",
        },
      ],
    };
  },
  watch: {
    modalRefreshKey() {
      this.getSupportingDocsById();
    },
  },
  methods: {
    getSize(totalBytes) {
      if (totalBytes < 1000000) {
        return Math.floor(totalBytes / 1000) + "KB";
      } else {
        return Math.floor(totalBytes / 1000000) + "MB";
      }
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.fileKey += 1;
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      // if (!event.currentTarget.classList.contains("bg-green-300")) {
      //   event.currentTarget.classList.remove("bg-gray-100");
      //   event.currentTarget.classList.add("bg-green-300");
      // }
    },
    dragleave() {
      // Clean up
      // event.currentTarget.classList.add("bg-gray-100");
      // event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.fileV.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      // event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    formatDate() {
      return moment().format("DD/MM/YYYY");
    },
    close() {
      this.clearData();
      this.$emit("close");
    },
    onChange() {
      this.filelist = [...this.filelist, ...this.$refs.fileV.files];
    },
    async save() {
      try {
        this.isloading = true;
        let document = [];
        await Promise.all(
          this.filelist.map(async (file) => {
            if (file && file.name) {
              const payload = {
                fileName: file.name,
                fileContent: await convertFiletoBase64(file),
              };
              document.push(payload);
            }
          })
        );

        if (
          typeof this.supportingDocId !== "string" &&
          this.supportingDocId.length
        ) {
          if (document.length) {
            await _post(
              SERVER_URL + `update-document?id=${this.supportingDocId[0]}`,
              {
                ...this.formData,
                document: document,
                application_id: this.$route.params.id,
                is_supporting: true,
              }
            );
          }
          let promises = await this.supportingDocId.map(async (list) => {
            const res = await _post(SERVER_URL + `update-document?id=${list}`, {
              ...this.formData,
            });

            return res.data;
          });

          Promise.all(promises).then(() => {
            this.isloading = false;
            this.$emit("close");
            this.$emit("save");
            this.$emit("completed");
            this.clearData();

            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Document updated successfully",
              life: 3000,
            });
          });
        } else {
          const response = await _post(
            SERVER_URL + `update-document?id=${this.formData.id}`,
            {
              ...this.formData,
              document: document,
              application_id: this.$route.params.id,
              is_supporting: true,
            }
          );

          if (response) {
            this.isloading = false;
            this.$emit("close");
            this.$emit("save");
            this.$emit("completed");
            this.clearData();

            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Document updated successfully",
              life: 3000,
            });
          }
        }
      } catch (err) {
        await this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
        this.isloading = false;
      }
    },
    async getSupportingDocsById() {
      try {
        this.isLoading = true;
        this.supportDocuments = [];

        if (
          typeof this.supportingDocId !== "string" &&
          this.supportingDocId.length
        ) {
          let promises = await this.supportingDocId.map(async (list) => {
            const res = await _get(
              SERVER_URL + `get-document-by-id?id=${list}`
            );

            return res.data.result;
          });

          Promise.all(promises).then((res) => {
            // this.formData.document_name = res[0].document_name;
            this.formData.document_type = res[0].documentType;
            this.formData.taskTitle = res[0].taskTitle;
            this.formData.id = res[0].Id;
            this.formData.status = res[0].status;
            this.formData.type = res[0].status;
            this.formData.type = res[0].status;
            this.formData.document_name = res[0]?.taskID?.taskTitle;
            res.map((list) => {
              this.supportDocuments.push(...list.files);
            });
            this.isLoading = false;
          });
        } else {
          const response = await _get(
            SERVER_URL + `get-document-by-id?id=${this.supportingDocId}`
          );

          if (response) {
            // this.formData.document_name = response.data.result.document_name;
            this.formData.document_type = response.data.result.documentType;
            this.formData.id = response.data.result.Id;
            this.formData.status = response.data.result.status;
            this.formData.type = response.data.result.type;
            this.supportDocuments = response.data.result.files;

            this.formData.notes = response?.data?.result?.taskID
              ? response?.data?.result?.taskID?.description
              : response.data.result.notes;
            this.formData.document_name =
              response.data.result?.taskID?.taskTitle ||
              response.data.result.document_name;
            this.isLoading = false;
          }
        }
      } catch (error) {
        this.isLoading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    async deleteDocument(docId) {
      try {
        const response = await _delete(
          SERVER_URL + `remove-document?id=${docId}`
        );
        if (response) {
          this.isloading = false;
          this.getSupportingDocsById();
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Document Deleted successfully",
            life: 3000,
          });
        }
      } catch (error) {
        this.isloading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },

    async downloadDocument(fileName) {
      if (fileName) {
        try {
          const response = await _get(
            SERVER_URL + `get-documents-api?fileName=${fileName}`
          );
          if (response) {
            this.isloading = false;
            const docName = fileName.split(".");
            var a = document.createElement("a");
            a.href =
              "data:image/" + docName[1] + ";base64," + response.data.content; //Image Base64 Goes here
            a.download = fileName;
            a.click();
          }
        } catch (error) {
          this.isloading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
    },

    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    clearData() {
      this.category = { id: 1, label: "Mark task as complete", value: false };
      this.filelist = [];
      this.formData = {
        document_name: "",
        document_type: "",
        notes: "",
        id: "",
        status: "",
      };
      this.supportDocuments = [];
      this.isloading = false;
    },
  },
  unmounted() {
    this.clearData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.timestamp {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 600;
  font-size: 14px;
}

p {
  margin: 0;
}
.divider {
  border: 1px solid rgba(2, 0, 56, 0.15);
}

//File upload
[v-cloak] {
  display: none;
}

.file-list {
  width: 100%;

  li {
    gap: 1rem;
    justify-content: space-between;
    border-bottom: 1px solid $light-text-color;

    .icon-name {
      gap: 1rem;
      padding: 1rem 0;
      align-items: center;
    }

    .file-name {
      margin: 0;
      font-size: 0.875rem;
    }

    .time-size {
      gap: 0.5rem;
      margin-top: 0.5rem;

      .time {
        border-right: 1px solid $light-text-color;
        padding-right: 0.5rem;
      }

      .time,
      .size {
        font-size: 0.75rem;
        margin: 0;
      }
    }

    .icon {
      cursor: pointer;
    }
  }
}

.title {
  font-size: 1.25rem;
}

.content {
  .heading {
    font-size: 0.875rem;
  }
}

.file {
  margin-top: 1.5rem;

  .icon-text {
    gap: 1rem;

    .text {
      margin: 0;
      font-size: 0.875rem;

      span {
        color: $primary-color;
      }
    }
  }
}

.modal-foodter {
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .close {
    margin: 0;
    cursor: pointer;
  }
}

.filelabel {
  width: 100%;
  border: 2px dashed #54b7f9;
  background: #54b7f91a;
  border-radius: 5px;
  display: block;
  padding: 1rem 1rem;
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
}
.delete-document {
  cursor: pointer;
}
</style>
