<template>
  <div>
    <div class="content">
      <div class="form-inputs mt-4">
        <div class="p-grid align-items-center header">
          <p class="col"></p>
          <p class="col label">Application Values</p>
          <p class="col label">Current Values</p>
          <p class="col label">Current Value Source</p>
          <div class="col"></div>
        </div>
        <InputRow
          label="Purchase Price"
          :value1="purchase_price"
          :value2="purchase_price_latest"
        />
        <InputRow
          label="Deposit Paid"
          :value1="deposit_amount"
          :value2="deposit_amount_latest"
        />
        <InputRow label="Savings" :value1="savings" :value2="savings_latest" />
        <InputRow
          label="Settlement Fees and Charges"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.settlement_fee
              : '0'
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.settlement_fee
              : '0'
          "
        />
        <InputRow
          label="Stamp Duty"
          :value1="stamp_duty"
          :value2="stamp_duty_latest"
        />
        <InputRow
          label="Other Liabilities"
          :value1="other_liability"
          :value2="other_liability_latest"
        />
        <InputRow
          label="Mortgage Payout Estimate"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.mortgage_payout_estimate
              : 0
          "
          :value2="
            latestData.funds_to_complete
              ? latestData.funds_to_complete.mortgage_payout
              : 0
          "
        />
        <InputRow
          label="Funds to Complete"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.funds_to_complete_purchase
              : 0
          "
          :value2="
            latestData.funds_to_complete
              ? latestData.funds_to_complete.funds_to_complete
              : 0
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputRow from "../../InputRow.vue";
export default {
  components: { InputRow },
  computed: {
    ...mapGetters({
      originalData: "loans/originalData",
      latestData: "loans/latestData",
    }),
  },
  data() {
    return {
      purchase_price: 0,
      purchase_price_latest: 0,
      deposit_amount: 0,
      deposit_amount_latest: 0,
      savings: 0,
      savings_latest: 0,
      settlement_charge: 0,
      stamp_duty: 0,
      stamp_duty_latest: 0,
      requested_surplus: 0,
      other_liability: 0,
      other_liability_latest: 0,
      mortgage_payout: 0,
      funds_to_complete: 0,
    };
  },
  created() {
    if (this.originalData && this.originalData.incoming_properties) {
      this.purchase_price = this.originalData.incoming_properties.reduce(
        (prev, current) => prev + current.purchase_price,
        0
      );
      this.deposit_amount = this.originalData.incoming_properties.reduce(
        (prev, current) => prev + current.deposit_amount,
        0
      );

      this.stamp_duty = this.originalData.incoming_properties.reduce(
        (prev, current) =>
          prev + current.stamp_duty ? Number(current.stamp_duty) : 0,
        0
      );
    }
    if (this.originalData && this.originalData.additional_funds) {
      this.savings =
        this.originalData && this.originalData.additional_funds
          ? this.originalData.additional_funds.add_fund_amount
          : 0;
    }
    if (this.originalData && this.originalData.liability_others) {
      this.other_liability = this.originalData.liability_others.reduce(
        (previousValue, currentValue) => {
          return (
            Number(previousValue) + Number(currentValue?.monthly_repayment)
          );
        },
        0
      );
    }
    if (this.latestData && this.latestData.incoming_properties) {
      this.purchase_price_latest = this.latestData.incoming_properties.reduce(
        (prev, current) => prev + current.purchase_price,
        0
      );

      this.deposit_amount_latest = this.latestData.incoming_properties.reduce(
        (prev, current) => prev + current.deposit_amount,
        0
      );
      this.stamp_duty_latest = this.latestData.incoming_properties.reduce(
        (prev, current) =>
          prev + current.stamp_duty ? Number(current.stamp_duty) : 0,
        0
      );
    }
    if (this.latestData && this.latestData.additional_funds) {
      this.savings_latest =
        this.latestData && this.latestData.additional_funds
          ? this.latestData.additional_funds.add_fund_amount
          : 0;
    }
    if (this.latestData && this.latestData.liability_others) {
      this.other_liability = this.originalData.liability_others.reduce(
        (previousValue, currentValue) => {
          return (
            Number(previousValue) + Number(currentValue?.monthly_repayment)
          );
        },
        0
      );
      this.other_liability_latest = this.latestData.liability_others.reduce(
        (previousValue, currentValue) => {
          return (
            Number(previousValue) + Number(currentValue?.monthly_repayment)
          );
        },
        0
      );
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
</style>
