<template>
  <div>
    <div class="content">
      <div class="form-inputs mt-4">
        <div class="p-grid align-items-center header">
          <p class="col"></p>
          <p class="col label">Application Values</p>
          <p class="col label">Current Values</p>
          <p class="col label">Current Value Source</p>
          <div class="col"></div>
        </div>

        <InputRow
          label="Sale Property"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.sale_property
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.sale_property
              : ''
          "
        />
        <InputRow
          label="Sale Costs"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.sale_cost
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.sale_cost
              : ''
          "
        />
        <InputRow
          label="Shaded Value"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.shaded_value
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.shaded_value
              : ''
          "
        />
        <InputRow
          label="Deposit Released"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.deposit_release
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.deposit_release
              : ''
          "
        />
        <InputRow
          label="Savings Post Settlement"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.savings_post
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.savings_post
              : ''
          "
        />
        <InputRow
          label="Net Sale Proceeds"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.net_sale_proceeds
              : ''
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.net_sale_proceeds
              : ''
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputRow from "../../InputRow.vue";
import { mapGetters } from "vuex";

export default {
  components: { InputRow },
  computed: {
    ...mapGetters({
      originalData: "loans/originalData",
      latestData: "loans/latestData",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
</style>
