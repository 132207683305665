import { _get } from "../services/crudService";
import { APP_URL } from '../../constants'

export default {
    namespaced: true,
    state: {
        originalData: {},
        latestData: {},
        userData: {}
    },
    getters: {
        originalData: ({ originalData }) => originalData,
        latestData: ({ latestData }) => latestData,
        userData: ({userData}) => userData
    },
    mutations: {
        setLatest(state, data) {
            state.latestData = data.latest
            state.originalData = data.original
        },
        setUserData(state, data) {
            state.userData = data
        }
    },
    actions: {
        async getAuditLogs({ commit }, loanId) {
            const result = await _get(APP_URL +
                "get-application-audit?id=" +
                loanId
            );
            commit('setLatest', result.data.applications)
        },
        setUserData({commit}, data) {
            commit('setUserData', data)
        }
    },
}
