<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
	<div class="std-padding-x mr-b">
		<div class="box">
			<div class="title-wrapper flex align-items-center">
				<img src="@/assets/Summary.svg" alt="" />
				<p class="title">Summary</p>
			</div>
			<div class="form-inputs">
				<!-- Col-1 -->
				<!-- Row-1 -->
				<div class="p-grid align-items-center mr-b-5px">
					<div class="col-10 flex align-items-center rm-padding-r">
						<div class="col disable-input">
							<p class="label">Loan term (months)</p>
							<TextField
								:value="calculatedFields.loan_term"
								:disable="true"
								width="100%"
								id="loanTermMonths"
							/>
						</div>
						<div class="col disable-input rm-padding-l">
							<p class="label">Interest Free Period (months)</p>
							<TextField
								:value="calculatedFields.interest_free_term"
								:disable="true"
								width="100%"
								id="interestFreePeriodMonths"
							/>
						</div>
					</div>
					<div class="col-2 disable-input highlight-fields">
						<p class="label">Funds to complete</p>
						<NumberField
							mode="decimal"
							:format="true"
							prefix="$"
							:value="
								calculatedFields.funds_to_complete_purchase
									? Number(
											calculatedFields.funds_to_complete_purchase
									  ).toFixed(2)
									: 0
							"
							:disable="true"
							width="100%"
							id="fundsToComplete"
						/>
					</div>
				</div>

				<!-- Row-2 -->

				<div class="p-grid align-items-center mr-b-5px">
					<div class="p-col-10 flex align-items-center rm-padding-r">
						<div class="p-col-3 disable-input">
							<p class="label">Set-up Fee ($)</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:disable="true"
								:key="totalRefreshKey"
								width="100%"
								:value="
									calculatedFields.setup_fee_amount
										? calculatedFields.setup_fee_amount.toFixed(2)
										: 0
								"
								id="setupFee"
							/>
						</div>
						<div class="p-col-3 disable-input rm-padding-l">
							<p class="label">Broker Fee ($)</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:key="totalRefreshKey"
								:value="
									calculatedFields.broker_fee_amount
										? calculatedFields.broker_fee_amount.toFixed(2)
										: 0
								"
								:disable="true"
								width="100%"
								id="brokerFee"
							/>
						</div>
						<div class="p-col-3 disable-input bg-group rm-padding-l">
							<p class="label">Total set-up fee ($)</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:key="totalRefreshKey"
								:value="
									calculatedFields.total_fee_amount
										? calculatedFields.total_fee_amount.toFixed(2)
										: 0
								"
								:disable="true"
								width="100%"
								id="totalSetupFee"
							/>
						</div>
						<div class="p-col-3 disable-input bg-group rm-padding-l">
							<p class="label">Additional Funds</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="
									calculatedFields.additional_fund ||
									calculatedFields.additional_fund
								"
								:disable="true"
								width="100%"
								@onInput="getAdditionalFund($event, 'additional_fund')"
								id="additionalFunds"
							/>
						</div>
					</div>
					<div class="p-col-2 rm-padding-l">
						<div class="col disable-input bg-group highlight-fields">
							<p class="label">Initial Loan Amount</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="
									calculatedFields.initial_amount_financed ||
									calculatedFields.initial_amount_financed
								"
								:disable="true"
								width="100%"
								id="initialAmountFinanced"
							/>
						</div>
					</div>
				</div>

				<!-- Row-3 -->

				<div class="p-grid align-items-center mr-b-5px">
					<div class="p-col-10 flex align-items rm-padding-r">
						<div class="p-col-3">
							<p class="label">Set-up Fee (%)</p>
							<NumberField
								mode="decimal"
								:key="refreshSetupKey"
								width="100%"
								:max="9"
								@onInput="getFormData($event, 'setup_fee')"
								:value="
									calculatedFields.setup_fee ||
									calculatedFields.setup_fee
								"
								:inValid="
									v$.calculatedFields.setup_fee.$invalid &&
									saveBtnClicked
								"
								id="setUpFee"
							/>
						</div>
						<div class="p-col-3 rm-padding-l">
							<p class="label">Broker Fee (%)</p>
							<NumberField
								mode="decimal"
								:max="0.35"
								:key="refreshBrokerKey"
								width="100%"
								@onInput="getFormData($event, 'broker_fee')"
								:value="
									calculatedFields.broker_fee ||
									calculatedFields.broker_fee
								"
								:inValid="
									v$.calculatedFields.broker_fee.$invalid &&
									saveBtnClicked
								"
								id="brokerFee"
							/>
						</div>
						<div class="p-col-3 disable-input rm-padding-l">
							<p class="label">Total set-up fee (%)</p>
							<TextField
								:key="totalRefreshKey"
								:value="
									calculatedFields.qouted_setup_fee
										? calculatedFields.qouted_setup_fee.toFixed(3)
										: 0
								"
								:disable="true"
								width="100%"
								id="totalSetupFee1"
							/>
						</div>
						<div class="p-col-3 disable-input bg-group rm-padding-l">
							<p class="label">Capitilised Interest</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="
									calculatedFields.interest_on_caps_loan
										? calculatedFields.interest_on_caps_loan.toFixed(
												2
										  )
										: 0
								"
								:disable="true"
								width="100%"
								id="CapitilisedInterest"
							/>
						</div>
					</div>
					<div class="p-col-2 rm-padding-l highlight-fields">
						<div class="col disable-input bg-group">
							<p class="label">Peak Debt</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="
									calculatedFields.peak_debt ||
									calculatedFields.peak_debt
								"
								:disable="true"
								width="100%"
								id="PeakDebt"
							/>
						</div>
					</div>
				</div>

				<!-- Row-4 -->

				<div class="p-grid align-items-center mr-b-5px">
					<div class="p-col-10 flex align-items-center rm-padding-r">
						<div class="p-col-3">
							<p class="label">Rate %</p>
							<NumberField
								mode="decimal"
								width="100%"
								:key="refreshRateKey"
								@onInput="getFormData($event, 'cap_loan_rate')"
								:value="calculatedFields.cap_loan_rate"
								:inValid="
									v$.calculatedFields.cap_loan_rate.$invalid &&
									saveBtnClicked
								"
								id="rate"
							/>
						</div>
						<div class="p-col-3 rm-padding-l">
							<p class="label">Valuation Fee ($)</p>
							<NumberField
								width="100%"
								@onInput="getFormData($event, 'valuation_fee')"
								:value="calculatedFields.valuation_fee"
								:mode="'decimal'"
								prefix="$"
								:format="true"
								:inValid="
									v$.calculatedFields.valuation_fee.$invalid &&
									saveBtnClicked
								"
								id="valuationFee"
							/>
						</div>
						<div class="p-col-3 rm-padding-l">
							<p class="label">Deposit ($)</p>
							<NumberField
								width="100%"
								@onInput="getDeposit($event)"
								:value="selectedApplication.deposit"
								:mode="'decimal'"
								prefix="$"
								:format="true"
								:inValid="v$.depositAmount.$invalid && saveBtnClicked"
								id="deposit"
							/>
						</div>
						<div class="p-col-3 disable-input bg-group rm-padding-l">
							<p class="label">Net Sale Proceeds</p>
							<NumberField
								:value="calculatedFields.net_sale_proceeds"
								:disable="true"
								width="100%"
								:key="refreshNetKey"
								:format="true"
								prefix="$"
								:mode="'decimal'"
								id="netSalesProceeds"
							/>
						</div>
					</div>

					<div class="p-col-2 rm-padding-l highlight-fields">
						<div class="col disable-input bg-group">
							<p class="label">End Debt</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="
									calculatedFields.end_debt || calculatedFields.end_debt
								"
								:disable="true"
								width="100%"
								id="endDebt"
							/>
						</div>
					</div>
				</div>
			</div>

			<!-- Col-2 -->

			<div class="flex align-items-center justify-content-between">
				<div
					class="p-col-3 highlight-fields rm-padding"
					v-if="buyingProperty.length == 0"
				>
					<div class="col mt-2 disable-input">
						<p class="label">Settlement Date<br /></p>
						<SummaryDatePicker
							:value="selectedApplication.settlement_date"
							placeholder="Enter date"
							width="100%"
							@change="(e) => getSettlementDate(e, 'settlement_date')"
						/>
					</div>
				</div>
				<div class="p-col-3 highlight-fields rm-padding">
					<div class="col mt-2">
						<p class="label">Loan Purpose<br /></p>
						<Dropdown
							:items="loanPurposeList"
							@change="(e) => getLoanPurpose(e, 'loan_purpose')"
							width="100%"
							placeholder="label"
							:optionLabel="'title'"
							:optionValue="'value'"
							:value="selectedApplication.loan_purpose"
							id="loan_purpose"
						/>
					</div>
				</div>

				<div class="p-col-3 highlight-fields rm-padding">
					<div class="col mt-2 disable-input">
						<p class="label">Income Assessment<br /></p>
						<TextField
							:value="calculatedFields.income_assesment"
							:disable="true"
							width="100%"
							id="incomeAssessment"
						/>
					</div>
				</div>
				<div class="p-col-2 highlight-fields rm-padding">
					<div class="mt-2 disable-input">
						<p class="label">Monthly Net Surplus</p>
						<NumberField
							mode="decimal"
							:format="true"
							prefix="$"
							:value="calculatedFields.monthly_surplus"
							:disable="true"
							width="100%"
							:key="refreshSurplusKey"
							id="monthlyNetSurplus"
						/>
					</div>
				</div>
			</div>

			<div
				v-if="selectedApplication.reason_for_fund"
				class="flex align-items-center justify-content-between"
			>
				<div class="p-col-6 highlight-fields rm-padding">
					<div class="col mt-2 disable-input">
						<p class="label">Reason for Funds<br /></p>
						<TextField
							:value="selectedApplication.reason_for_fund"
							:disable="true"
							width="100%"
							id="reasonForFund"
						/>
					</div>
				</div>
				<div
					v-if="
						selectedApplication.reason_for_fund === 'Other' &&
						selectedApplication.reason_describe
					"
					class="p-col-6 highlight-fields rm-padding"
				>
					<div class="col mt-2 disable-input">
						<p class="label">Reason Description<br /></p>
						<TextField
							:value="selectedApplication.reason_describe"
							:disable="true"
							width="100%"
							id="reasonDescription"
						/>
					</div>
				</div>
			</div>
			<EndDebt />
			<FundsToComplete @validateFunds="validateFunds" />
			<MortgagePayout />
			<SettlementFees @validateSettlement="validateSettlement" />
			<NetSalesProceeds />
			<Serviceability />
			<ReviewForm @validateReviewForm="validateReviewForm" class="mt-7" />
			<Footer
				:isFundsValidate="isFundsValidate"
				:isSettlementValidate="isSettlementValidate"
				:isReviewValidate="isReviewValidate"
			/>
		</div>
	</div>
</template>

<script>
import EndDebt from "./EndDebt.vue";
import FundsToComplete from "./FundsToComplete.vue";
import MortgagePayout from "./MortgagePayout.vue";
import SettlementFees from "./SettlementFee.vue";
import NetSalesProceeds from "./NetSaleProceeds.vue";
import Serviceability from "./Serviceability.vue";
import ReviewForm from "./ReviewForm.vue";
import Footer from "./Footer.vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters, mapActions } from "vuex";

export default {
	setup: () => ({ v$: useVuelidate() }),
	validations() {
		return {
			depositAmount: { required },
			calculatedFields: {
				setup_fee: { required },
				broker_fee: { required },
				cap_loan_rate: { required },
				valuation_fee: { required },
			},
		};
	},
	components: {
		EndDebt,
		FundsToComplete,
		MortgagePayout,
		SettlementFees,
		NetSalesProceeds,
		Serviceability,
		ReviewForm,
		Footer,
	},
	data() {
		return {
			summaryFormData: {
				setupFeePercent: "",
				brokerFeePercent: "",
				ratePercent: "",
				validationFeePercent: "",
				depositPercent: "",
			},
			depositAmount: "",
			isFundsValidate: true,
			isSummaryValidate: true,
			isSettlementValidate: true,
			isReviewValidate: true,
			totalRefreshKey: 10,
			refreshSetupKey: 20,
			refreshBrokerKey: 30,
			refreshRateKey: 40,
			refreshSurplusKey: 50,
			calculatedFields: {
				funds_to_complete_purchase: 0,
				setup_fee: 0,
				additional_fund: 0,
				initial_amount_financed: 0,
				broker_fee: 0,
				peak_debt: 0,
				qouted_setup_fee: 0,
				loan_term: 0,
				interest_free_term: 3,
				cap_loan_rate: 0,
				interest_on_caps_loan: 0,
				rate_percent: 0,
				valuation_fee: 0,
				deposit_percent: 0,
				net_sale_proceeds: 0,
				monthly_surplus: 0,
				end_debt: 0,
				setup_fee_amount: 0,
				broker_fee_amount: 0,
				total_fee_amount: 0,
				income_assesment: "",
			},
			refreshNetKey: 60,
			loanPurposeList: [
				{
					title: "Buy now, sell later",
					value: "buy_now",
				},
				/* {
          title: "Renovate before I sell",
          value: "renovate",
        },
        {
          title: "Cash advance on the sale of my property",
          value: "cash_advance",
        }, */
				{
					title: "Equity release",
					value: "equity_release",
				},
			],
		};
	},
	computed: {
		...mapGetters({
			selectedApplication: "applications/selectedApplication",
			monthlySurplus: "subTotal/monthlySurplus",
			saveBtnClicked: "applications/saveBtnClicked",
			netSales: "summary/netSales",
			buyingProperty: "applications/buyingProperty",
		}),
	},
	methods: {
		...mapActions({
			setSummaryData: "summary/setSummaryData",
			setAdditionalfund: "applications/setAdditionalfund",
			setDeposit: "applications/setDeposit",
			setValidation: "applications/setValidation",
			setSettlementDate: "applications/setSettlementDate",
			setLoanPurpose: "applications/setLoanPurpose",
		}),
		isSubmitDisabled() {
			if (this.v$?.depositAmount?.$invalid || this.v$?.calculatedFields?.$invalid) {
				return true;
			} else {
				return false;
			}
		},
		getFormData(value, fieldName) {
			this.calculatedFields[fieldName] = value;
			this.calculatedFields = { ...this.calculatedFields };

			if (fieldName == "cap_loan_rate") {
				if (value > 100) {
					this.calculatedFields.cap_loan_rate = 100;
					this.refreshRateKey += 1;
				}
			}

			if (fieldName == "setup_fee") {
				if (value > 9) {
					this.calculatedFields.setup_fee = 9;
					this.refreshSetupKey += 1;
				}
				//Calculating the Total from broker fee + setup free
				let setupAmount = this.calculatedFields?.setup_fee
					? this.calculatedFields?.setup_fee
					: 0;
				let brokerAmount = this.calculatedFields?.broker_fee
					? this.calculatedFields?.broker_fee
					: 0;
				this.calculatedFields.qouted_setup_fee = setupAmount + brokerAmount;
				let totalAmount = this.calculatedFields?.initial_amount_financed
					? this.calculatedFields?.initial_amount_financed
					: 0;
				// let fundsAmount = this.calculatedFields?.funds_to_complete_purchase
				//   ? Number(this.calculatedFields?.funds_to_complete_purchase)
				//   : 0;
				this.calculatedFields.setup_fee_amount =
					(setupAmount * totalAmount) / 100;
				this.calculatedFields.broker_fee_amount =
					(brokerAmount * totalAmount) / 100;
				this.calculatedFields.total_fee_amount =
					this.calculatedFields.setup_fee_amount +
					this.calculatedFields.broker_fee_amount;
				this.totalRefreshKey += 1;
			}

			if (fieldName == "broker_fee") {
				if (value > 0.35) {
					this.calculatedFields.broker_fee = 0.35;
					this.refreshBrokerKey += 1;
				}
				//Calculating the Total from broker fee + setup free
				let setupAmount = this.calculatedFields?.setup_fee
					? this.calculatedFields?.setup_fee
					: 0;
				let brokerAmount = this.calculatedFields?.broker_fee
					? this.calculatedFields?.broker_fee
					: 0;
				this.calculatedFields.qouted_setup_fee = setupAmount + brokerAmount;
				let totalAmount = this.calculatedFields?.initial_amount_financed
					? this.calculatedFields?.initial_amount_financed
					: 0;
				// let fundsAmount = this.calculatedFields?.funds_to_complete_purchase
				//   ? Number(this.calculatedFields?.funds_to_complete_purchase)
				//   : 0;
				this.calculatedFields.setup_fee_amount =
					(setupAmount * totalAmount) / 100;
				this.calculatedFields.broker_fee_amount =
					(brokerAmount * totalAmount) / 100;
				this.calculatedFields.total_fee_amount =
					this.calculatedFields.setup_fee_amount +
					this.calculatedFields.broker_fee_amount;
				this.totalRefreshKey += 1;
			}

			this.setSummaryData(this.calculatedFields);
			console.log(this.calculatedFields, "------");
		},
		getAdditionalFund(value) {
			this.setAdditionalfund(value);
		},
		getDeposit(value) {
			this.depositAmount = value;
			this.setDeposit(value);
		},
		getSettlementDate(value) {
			this.setSettlementDate(value);
		},
		getLoanPurpose(value) {
			this.setLoanPurpose(value);
		},
		validateFunds(value) {
			this.isFundsValidate = value;
		},
		validateSettlement(value) {
			this.isSettlementValidate = value;
		},
		validateReviewForm(value) {
			this.isReviewValidate = value;
		},
		setData() {
			if (this.selectedApplication.application_id) {
				const { calculated_fields } = this.selectedApplication;
				Object.keys(calculated_fields).map((key) => {
					if (key in this.calculatedFields) {
						this.calculatedFields[key] = calculated_fields[key];
					}
				});
				//All fields
				this.calculatedFields = {
					...this.calculatedFields,
				};
				//Interest Free Term
				this.calculatedFields.interest_free_term =
					calculated_fields.interest_free_term
						? calculated_fields.interest_free_term
						: 3;
				//Capital Interest
				this.calculatedFields.cap_loan_rate = this.calculatedFields.cap_loan_rate
					? this.calculatedFields.cap_loan_rate * 100
					: 0;
				// let totalLoanAmount = this.calculatedFields?.initial_amount_financed
				//   ? this.calculatedFields?.initial_amount_financed
				//   : 0;
				// this.calculatedFields.cap_loan_rate_amount = capLoanRate
				//   ? capLoanRate * totalLoanAmount
				//   : 0;
				//Additional Funds
				// console.log("this.selectedApplication ", this.selectedApplication);
				this.calculatedFields.additional_fund = this.selectedApplication
					.additional_fund
					? this.selectedApplication.additional_fund
					: 0;
				//Setup Fee percent
				this.calculatedFields.setup_fee = this.calculatedFields.setup_fee
					? this.calculatedFields.setup_fee * 100
					: 0;
				//Broker Fee percent
				this.calculatedFields.broker_fee = this.calculatedFields.broker_fee
					? this.calculatedFields.broker_fee * 100
					: 0;
				//Total set up fee
				this.calculatedFields.qouted_setup_fee = this.calculatedFields
					.qouted_setup_fee
					? this.calculatedFields.qouted_setup_fee
					: 0;
				//Setup fee amount calculation
				let setupAmount = this.calculatedFields?.setup_fee
					? this.calculatedFields?.setup_fee
					: 0;
				let brokerAmount = this.calculatedFields?.broker_fee
					? this.calculatedFields?.broker_fee
					: 0;
				this.calculatedFields.qouted_setup_fee = setupAmount + brokerAmount;
				let totalAmount = this.calculatedFields?.initial_amount_financed
					? this.calculatedFields?.initial_amount_financed
					: 0;
				// let fundsAmount = this.calculatedFields?.funds_to_complete_purchase
				//   ? Number(this.calculatedFields?.funds_to_complete_purchase)
				//   : 0;
				this.calculatedFields.setup_fee_amount =
					(setupAmount * totalAmount) / 100;
				this.calculatedFields.broker_fee_amount =
					(brokerAmount * totalAmount) / 100;
				this.calculatedFields.total_fee_amount =
					this.calculatedFields.setup_fee_amount +
					this.calculatedFields.broker_fee_amount;
				//Deposit
				this.calculatedFields.deposit_percent = this.calculatedFields
					.deposit_percent
					? this.calculatedFields.deposit_percent
					: 0;
				//Montly Surplus
				this.calculatedFields.monthly_surplus = this.monthlySurplus
					? this.monthlySurplus
					: 0;
				this.calculatedFields.net_sale_proceeds = this.netSales?.net_sale
					? this.netSales.net_sale
					: 0;
				// console.log(
				//   "calculated_fields.income_assesment ",
				//   calculated_fields.income_assesment
				// );
				this.calculatedFields.income_assesment =
					calculated_fields.income_assesment;
				this.refreshNetKey += 1;
				this.setSummaryData(this.calculatedFields);
			}
		},
	},
	created() {
		this.setData();
		this.depositAmount = this.selectedApplication.deposit;
	},
	watch: {
		depositAmount() {
			this.setValidation({
				type: "summary",
				value: this.isSubmitDisabled(),
			});
		},
		calculatedFields: {
			handler() {
				this.setValidation({
					type: "summary",
					value: this.isSubmitDisabled(),
				});
			},
			deep: true,
		},
		monthlySurplus: {
			handler() {
				this.calculatedFields.monthly_surplus = this.monthlySurplus
					? this.monthlySurplus
					: 0;
				this.setSummaryData(this.calculatedFields);
				this.refreshSurplusKey += 1;
			},
			deep: true,
		},
		netSales: {
			handler() {
				this.calculatedFields.net_sale_proceeds = this.netSales?.net_sale
					? this.netSales.net_sale
					: 0;
				this.refreshNetKey += 1;
			},
			deep: true,
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.bg-group {
	background: rgba(2, 0, 56, 0.05);
	border-radius: 2px;
}

.rm-padding {
	padding: 0 !important;
}

.rm-padding-r {
	padding-right: 0 !important;
}

.rm-padding-l {
	padding-left: 0 !important;
}

.std-padding-x {
	padding: 0 2.188rem;
}

.flex-gap {
	gap: 0.625rem;
}

.highlight-fields {
	::v-deep .label {
		font-family: Gordita-bold !important;
	}
}

.title-wrapper {
	border-bottom: 1px solid $secondary-color;
	padding-bottom: 0.625rem;
}

.title {
	font-size: 2rem;
	font-family: Gordita-bold;
	margin-left: 0.625rem;
	color: $secondary-color;
}

.mr-b {
	margin-bottom: 1.875rem;
}

.mr-b-5px {
	margin-bottom: 5px;
}

.mr-5px {
	margin: 8px 0;
}

.form-inputs {
	margin-top: 2.5rem;
}

.label {
	color: $secondary-color;
	font-size: 0.875rem;
}

.box {
	background: #ffffff;
	border: 1px solid rgba(2, 0, 56, 0.4);
	box-sizing: border-box;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	padding: 1.25rem;
}

p {
	margin: 0;
}
</style>
