<template>
  <div class="application-header-layout pt-4 pl-4 pr-4 pb-0">
    <h1 class="module-title white-color">Settings</h1>
    <div class="flex h-100 mt-4 bold-600">
      <div v-for="(item, i) in list" :key="i">
        <div
          :class="
            item.isActive ? 'application-option' : 'application-option-inactive'
          "
          class="mr-1 cursor-pointer"
          @click="onSelectItem(i)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplicationHeader",
  data() {
    return {
      list: [
        { title: "Account", isActive: true },
        { title: "System", isActive: false },
      ],
    }
  },
  methods: {
    onSelectItem(i) {
      this.list.map((obj) => {
        obj.isActive = false
      })
      this.list[i].isActive = true
      switch (i) {
        case 0:
          this.$router.push({
            name: "AccountComponent",
          })
          break
        case 1:
          this.$router.push({ name: "UsersComponent" })
          break
      }
    },
    routeNavigation() {
      switch (this.$route.name) {
        case "AccountComponent":
          this.onSelectItem(0)
          break
        case "UsersComponent":
          this.onSelectItem(1)
          break
      }
    },
  },
  watch: {
    $route() {
      this.routeNavigation()
    },
  },
  created() {
    this.routeNavigation()
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.application-header-layout {
  min-height: 122px;
  width: 100%;
  background: #9193ad;
}
.white-color {
  color: $white-color;
}
.application-option {
  padding: 5px 10px;
  background: #eef4fa;
  border-radius: 8px 8px 0px 0px;
  color: #020038;
}
.application-option-inactive {
  background: rgba(2, 0, 56, 0.2);
  border-radius: 8px 8px 0px 0px;
  color: $white-color;
  padding: 5px 10px;
}
.h-100 {
  height: 100%;
}
</style>
