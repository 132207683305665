<template>
  <Dialog
    :draggable="false"
    :visible="show"
    position="top"
    :modal="show"
    :style="{ width: '680px' }"
  >
    <template #header>
      <h1 class="modal-title">Split Selected</h1>
    </template>
    <div class="modal-content">
      <div :key="refreshKey" class="flex align-items-center">
        <p class="col-2"></p>
        <div class="col-10">
          <div>
            <p class="label">Employer name</p>
            <TextField
              :value="employerName"
              @change="(e) => setEmployerName(e)"
              width="100%"
            />
          </div>
        </div>
      </div>
      <!-- Salary -->
      <div :key="refreshKey" class="form-inputs mt-2">
        <div class="p-grid align-items-center header">
          <p class="p-col-2"></p>
          <p class="p-col flex justify-content-start label">
            Application Value
          </p>
          <p class="p-col label">Verified Value</p>
          <p class="p-col label">Manual Value</p>
        </div>

        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Salary</p>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedSalaryApplicationValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedSalaryVerifiedValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div class="p-col flex align-items-center justify-content-center">
            <TextField
              :value="calculatedSalaryManualValue"
              :disable="true"
              width="100%"
            />
          </div>
        </div>
      </div>

      <!-- Salary packaged -->
      <div :key="refreshKey" class="form-inputs mt-2">
        <div class="p-grid align-items-center header">
          <p class="p-col-2"></p>
          <p class="p-col flex justify-content-start label">
            Application Value
          </p>
          <p class="p-col label">Verified Value</p>
          <p class="p-col label">Manual Value</p>
        </div>

        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Packaged</p>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedSalaryPackageApplicationValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedSalaryVerifiedValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div class="p-col flex align-items-center justify-content-center">
            <TextField
              :value="calculatedSalaryPackageManualValue"
              :disable="true"
              width="100%"
            />
          </div>
        </div>
      </div>

      <!-- Overtime -->
      <div :key="refreshKey" class="form-inputs mt-2">
        <div class="p-grid align-items-center header">
          <p class="p-col-2"></p>
          <p class="p-col flex justify-content-start label">
            Application Value
          </p>
          <p class="p-col label">Verified Value</p>
          <p class="p-col label">Manual Value</p>
        </div>

        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Overtime</p>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedOvertimeApplicationValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedOvertimeVerifiedValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div class="p-col flex align-items-center justify-content-center">
            <TextField
              :value="calculatedOvertimeManualValue"
              :disable="true"
              width="100%"
            />
          </div>
        </div>
      </div>

      <!-- Commission -->
      <div :key="refreshKey" class="form-inputs mt-2">
        <div class="p-grid align-items-center header">
          <p class="p-col-2"></p>
          <p class="p-col flex justify-content-start label">
            Application Value
          </p>
          <p class="p-col label">Verified Value</p>
          <p class="p-col label">Manual Value</p>
        </div>

        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Commission</p>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedCommissionApplicationValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedCommissionVerifiedValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div class="p-col flex align-items-center justify-content-center">
            <TextField
              :value="calculatedCommissionManualValue"
              :disable="true"
              width="100%"
            />
          </div>
        </div>
      </div>

      <!-- Bonuses -->
      <div :key="refreshKey" class="form-inputs mt-2">
        <div class="p-grid align-items-center header">
          <p class="p-col-2"></p>
          <p class="p-col flex justify-content-start label">
            Application Value
          </p>
          <p class="p-col label">Verified Value</p>
          <p class="p-col label">Manual Value</p>
        </div>

        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Bonuses</p>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedBonusesApplicationValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedBonusesVerifiedValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div class="p-col flex align-items-center justify-content-center">
            <TextField
              :value="calculatedBonusesManualValue"
              :disable="true"
              width="100%"
            />
          </div>
        </div>
      </div>

      <!-- Total Income -->
      <div :key="refreshKey" class="form-inputs mt-2">
        <div class="p-grid align-items-center header">
          <p class="p-col-2"></p>
          <p class="p-col flex justify-content-start label">
            Application Value
          </p>
          <p class="p-col label">Verified Value</p>
          <p class="p-col label">Manual Value</p>
        </div>

        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Total Income</p>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedTotalApplicationValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedTotalVerifiedValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div class="p-col flex align-items-center justify-content-center">
            <TextField
              :value="calculatedTotalManualValue"
              :disable="true"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button
          label="Split selected"
          type="primary"
          @click="saveSplited"
          :disabled="!employerName"
        />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">
          Close
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Dialog from "primevue/dialog";

export default {
  name: "AddTask",
  props: ["show", "selectedIncome", "allIncome", "disable", "type"],
  components: { Dialog },
  data() {
    return {
      savings: {},
      otherSavings: {},
      refreshKey: 10,
      employerName: "",
      //Salary
      calculatedSalaryApplicationValue: 0,
      calculatedSalaryVerifiedValue: 0,
      calculatedSalaryManualValue: 0,
      //Salary Package
      calculatedSalaryPackageApplicationValue: 0,
      calculatedSalaryPackageVerifiedValue: 0,
      calculatedSalaryPackageManualValue: 0,
      //Overtime
      calculatedOvertimeApplicationValue: 0,
      calculatedOvertimeVerifiedValue: 0,
      calculatedOvertimeManualValue: 0,
      //Commission
      calculatedCommissionApplicationValue: 0,
      calculatedCommissionVerifiedValue: 0,
      calculatedCommissionManualValue: 0,
      //Bonuses
      calculatedBonusesApplicationValue: 0,
      calculatedBonusesVerifiedValue: 0,
      calculatedBonusesManualValue: 0,
      //Total
      calculatedTotalApplicationValue: 0,
      calculatedTotalVerifiedValue: 0,
      calculatedTotalManualValue: 0,
    };
  },
  computed: {
    ...mapState({
      fullTimeUpdated: (state) => state.applications.fullTimeUpdated,
      partTimeUpdated: (state) => state.applications.partTimeUpdated,
      casualUpdated: (state) => state.applications.casualUpdated,
    }),
  },
  methods: {
    ...mapActions({
      setFullTimeIncome: "applications/setFullTimeIncome",
      setPartTimeIncome: "applications/setPartTimeIncome",
      setCasualIncome: "applications/setCasualIncome",
      setFullTimeUpdated: "applications/setFullTimeUpdated",
      setPartTimeUpdated: "applications/setPartTimeUpdated",
      setCasualUpdated: "applications/setCasualUpdated",
    }),
    setEmployerName(e) {
      this.employerName = e.target.value;
    },
    saveSplited() {
      let allArr = this.allIncome.map((item) => {
        if (item.name_of_employer === this.selectedIncome[0].name_of_employer) {
          item.salary_amount_verified = 0;
          item.salary_packaged_verified = 0;
          item.overtime_pay_amount_verified = 0;
          item.commissions_amount_verified = 0;
          item.bonuses_amount_verified = 0;
          item.total_income_amount_verified = 0;
          return item;
        }
        return item;
      });
      let arr = [
        ...allArr,
        {
          name_of_employer: this.employerName,
          salary_amount: this.calculatedSalaryApplicationValue,
          salary_amount_used: this.calculatedSalaryManualValue,
          salary_packaged_used: this.calculatedSalaryPackageManualValue,
          overtime_pay_amount_used: this.calculatedOvertimeManualValue,
          commissions_used: this.calculatedBonusesManualValue,
          bonuses_amount_used: this.calculatedCommissionApplicationValue,
          salary_packaged: this.calculatedSalaryPackageApplicationValue,
          salary_frequency: "Monthly",
          overtime_pay_amount: this.calculatedOvertimeApplicationValue,
          overtime_pay_frequency: "Monthly",
          commissions_amount: this.calculatedCommissionApplicationValue,
          commissions_frequency: "Monthly",
          bonuses_amount: this.calculatedCommissionApplicationValue,
          bonuses_frequency: "Monthly",
          applicant_type: !this.disable ? "primary" : "secondary",
          jobType: this.type,
          salary_amount_verified: this.calculatedSalaryVerifiedValue,
          salary_amount_manual: this.calculatedSalaryManualValue,
          salary_amount_used_value: "manual_value",
          salary_packaged_verified: this.calculatedSalaryPackageVerifiedValue,
          salary_packaged_manual: this.calculatedSalaryPackageManualValue,
          salary_packaged_used_value: "manual_value",
          overtime_pay_amount_verified: this.calculatedOvertimeVerifiedValue,
          overtime_pay_amount_manual: this.calculatedOvertimeManualValue,
          overtime_pay_amount_used_value: "manual_value",
          commissions_amount_verified: this.calculatedCommissionVerifiedValue,
          commissions_amount_manual: this.calculatedCommissionManualValue,
          commissions_amount_used_value: "manual_value",
          bonuses_amount_verified: this.calculatedBonusesVerifiedValue,
          bonuses_amount_manual: this.calculatedBonusesManualValue,
          bonuses_amount_used_value: "manual_value",
          total_income_amount_verified: this.calculatedTotalVerifiedValue,
          total_income_amount_manual: this.calculatedTotalManualValue,
          total_income_amount_used_value: "manual_value",
        },
      ];
      if (this.type == "full_time") {
        this.setFullTimeIncome({
          data: arr,
          type: !this.disable ? "primary" : "secondary",
        });
        this.setFullTimeUpdated(this.fullTimeUpdated + 1);
      } else if (this.type == "part_time") {
        this.setPartTimeIncome({
          data: arr,
          type: !this.disable ? "primary" : "secondary",
        });
        this.setPartTimeUpdated(this.partTimeUpdated + 1);
      } else {
        this.setCasualIncome({
          data: arr,
          type: !this.disable ? "primary" : "secondary",
        });
        this.setCasualUpdated(this.casualUpdated + 1);
      }
      this.$emit("splitSelected");
    },
    close() {
      this.refreshKey += 1;
      this.$emit("close");
    },
  },
  watch: {
    selectedIncome() {
      if (this.selectedIncome.length > 0) {
        //Salary
        this.calculatedSalaryApplicationValue = 0;
        this.calculatedSalaryVerifiedValue =
          this.selectedIncome[0].salary_amount_verified;
        this.calculatedSalaryManualValue = 0;
        //Salary Package
        this.calculatedSalaryPackageApplicationValue = 0;
        this.calculatedSalaryPackageVerifiedValue =
          this.selectedIncome[0].salary_packaged_verified;
        this.calculatedSalaryPackageManualValue = 0;
        //Overtime
        this.calculatedOvertimeApplicationValue = 0;
        this.calculatedOvertimeVerifiedValue =
          this.selectedIncome[0].overtime_pay_amount_verified;
        this.calculatedOvertimeManualValue = 0;
        //Commission
        this.calculatedCommissionApplicationValue = 0;
        this.calculatedCommissionVerifiedValue =
          this.selectedIncome[0].commissions_amount_verified;
        this.calculatedCommissionManualValue = 0;
        //Bonuses
        this.calculatedBonusesApplicationValue = 0;
        this.calculatedBonusesVerifiedValue =
          this.selectedIncome[0].bonuses_amount_verified;
        this.calculatedBonusesManualValue = 0;
        //Total
        this.calculatedTotalApplicationValue = 0;
        this.calculatedTotalVerifiedValue =
          this.selectedIncome[0].total_income_amount_verified;
        this.calculatedTotalManualValue = 0;
        this.refreshKey += 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.checkbox-list {
  border: 1px solid rgba(2, 0, 56, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
}

.color-highlight {
  color: $secondary-color;
  font-family: Gordita-medium;
  font-size: 14px;
}

.modal-title {
  font-family: Gordita-medium-;
  font-size: 20px;
  color: $secondary-color;
}

.text,
label,
.label {
  font-family: Gordita-medium-;
  font-size: 14px;
  color: $secondary-color;
}

.color-highlight {
  color: $primary-color;
  text-decoration: underline;
}

p {
  margin: 0;
}

.fg-10 {
  gap: 10px;
}
::v-deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ced4da !important;
  border-color: #2196f3;
}
</style>
