<template>
  <div>
    <Spinner v-if="isLoading" class="flex justify-content-between" />
    <div v-else class="mr-top">
      <div class="flex mt-3">
        <div class="col">
          <div>First name</div>
          <TextField
            width="100%"
            placeholder="First name"
            @getInputValue="getFormData($event, 'firstName')"
            class="mt-2"
            :value="formData.firstName"
          />
        </div>
        <div class="col ml-2">
          <div>Last name</div>
          <TextField
            width="100%"
            placeholder="Last name"
            @getInputValue="getFormData($event, 'lastName')"
            class="mt-2"
            :value="formData.lastName"
          />
        </div>
      </div>
      <div class="flex mt-3">
        <div class="col">
          <div>Email</div>
          <TextField
            width="100%"
            placeholder="Email"
            @getInputValue="getFormData($event, 'email')"
            class="mt-2"
            :value="formData.email"
          />
        </div>
        <div class="col ml-2">
          <div>Phone number</div>
          <TextField
            width="100%"
            placeholder="Phone number"
            @onInput="getFormData($event, 'phoneNumber')"
            class="mt-2"
            :value="formData.phoneNumber"
            type="tel"
          />
        </div>
      </div>
      <div class="flex mt-3">
        <div class="col">
          <div>User name</div>
          <TextField
            width="100%"
            placeholder="User name"
            @getInputValue="validateUserName"
            class="mt-2"
            :value="formData.userName"
          />
          <span class="user-error" v-if="showError"
            >Username already exist!</span
          >
        </div>
      </div>
      <div class="flex mt-3">
        <div class="col">
          <div>Password</div>
          <TextField
            width="100%"
            placeholder="Password"
            type="password"
            @getInputValue="getFormData($event, 'password')"
            class="mt-2"
            :value="formData.password"
          />
        </div>
      </div>
      <div class="flex mt-3">
        <div class="col">
          <div>Group</div>

          <div class="full-width">
            <AutoComplete
              forceSelection
              width="100%"
              v-model="group"
              :suggestions="filteredGroupList"
              @complete="getGroupList($event)"
              placeholder="Enter group name"
              field="name"
              :modelValue="group"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex align-items-center">
      <Button
        :disabled="isSubmitDisabled()"
        class="mt-3"
        label="Next"
        type="primary"
        @click="step"
      />
      <div @click="close" class="back mt-3 ml-4 cursor-pointer">Cancel</div>
    </div>
  </div>
</template>
<script>
import { required, email, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { _get } from "../../../../../../services/crudService";
import { SERVER_URL } from "../../../../../../../constants";
import AutoComplete from "primevue/autocomplete";
// import { checkMobileNumber } from "../../../../../../services/validatePhNo";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["close"],
  components: { AutoComplete },
  data() {
    return {
      info: {},
      groupList: [],
      selectedGroup: null,
      isLoading: false,
      filteredGroupList: [],
      checkUserName: null,
      showError: false,
      userName: "",
      groupChanged: 10,
      group: "",
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        userName: "",
        password: "",
      },
    };
  },
  watch: {
    group(value) {
      if (typeof value === "object") {
        this.groupChanged++;
        if (value && this.groupChanged !== 11) {
          this.$emit("selectGroup", value.Id);
          this.groupChanged = true;
        }
      }
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        password: { required },
        firstName: { required },
        lastName: { required },
        email: { required, email },
        phoneNumber: {
          required,
          maxLength: maxLength(10),
        },
        userName: { required },
      },
      group: { required },
    };
  },
  computed: {
    ...mapGetters({
      selectedUserId: "user/selectedUserId",
    }),
  },
  methods: {
    ...mapActions({
      setInfo: "user/setInfo",
      setPermissions: "user/setPermissions",
      setGroupPermissionList: "user/setGroupPermissionList",
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    step() {
      this.$emit("nxtBtn", "AddUserTwo");

      let data = {
        ...this.formData,
        groupId: this.group.Id,
        group: this.group.name,
      };

      this.setInfo(data);
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };

      if (fieldName === "phoneNumber") {
        this.formData.phoneNumber = value.toString();
      }
    },
    async getGroupList(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredGroupList = [...this.groupList];
        } else {
          this.filteredGroupList = this.groupList.filter((list) => {
            return list.name.toLowerCase().includes(event.query.toLowerCase());
          });

          if (!this.filteredGroupList.length) {
            return (this.v$.$invalid = true);
          }

          this.groupList.map((list) => {
            if (list.name !== event.query) {
              return (this.v$.$invalid = true);
            }
          });
        }
      }, 250);
    },
    validateUserName(value) {
      this.formData.userName = value;
      if (this.selectedUserId) {
        if (this.userName !== this.formData.userName) {
          clearTimeout(this.checkUserName);
          this.checkUserName = setTimeout(async () => {
            try {
              const response = await _get(
                SERVER_URL +
                  `get_crm_username?userName=${this.formData.userName}`
              );

              if (response) {
                this.showError = false;
              }
            } catch (err) {
              this.showError = true;
              return (this.v$.$invalid = true);
            }
          }, 300);
        }
      } else {
        clearTimeout(this.checkUserName);
        this.checkUserName = setTimeout(async () => {
          try {
            const response = await _get(
              SERVER_URL + `get_crm_username?userName=${this.formData.userName}`
            );

            if (response) {
              this.showError = false;
            }
          } catch (err) {
            this.showError = true;
            return (this.v$.$invalid = true);
          }
        }, 300);
      }
    },
    async getSelectedUser() {
      this.isLoading = true;

      const getUser = await _get(
        SERVER_URL + "get_crm_user?id=" + this.selectedUserId
      );

      if (getUser) {
        this.setPermissions(getUser.data.result[0]);

        const user = getUser.data.result[0];
        this.formData.firstName = user.firstName;
        this.formData.lastName = user.lastName;
        this.formData.email = user.email;
        this.formData.phoneNumber = user.phoneNumber;
        this.formData.password = user.password;
        this.formData.userName = user.userName;
        this.group = { name: user.group, Id: user.groupId };
        this.userName = user.userName;
        this.selectedGroup = user.groupId;
        this.currentGroup = user.groupId;
        this.groupChanged = 10;

        let taskTypePermissions = [];
        let permissions = [];
        let filteredTaskType = [];

        taskTypePermissions = getUser.data.result.taskPermissions;

        getUser.data.result.map((list) => {
          const values = Object.keys(list);

          values.map((key) => {
            if (list[key] === true) {
              permissions.push(key);
            }
          });
        });

        taskTypePermissions &&
          taskTypePermissions.map((list) => {
            const values = Object.keys(list);

            values.map((key) => {
              if (list[key] === true) {
                filteredTaskType.push(key);
              }
            });
          });

        let data = [...permissions, { taskPermissions: filteredTaskType }];

        this.setGroupPermissionList(data);

        this.isLoading = false;
      }
    },
  },
  async created() {
    this.isLoading = true;

    const getGroup = await _get(SERVER_URL + "get-group-count");

    if (getGroup) {
      const result = getGroup.data.data;
      this.isLoading = false;

      result.map((group) =>
        this.groupList.push({ Id: group.id, name: group.name })
      );
    }

    if (this.selectedUserId) {
      this.getSelectedUser();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
  font-family: Gordita-bold !important;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.text-content {
  margin-top: 1rem;
}
form {
  gap: 1rem;

  input[type="radio"] {
    width: 1.125rem;
    height: 1.125rem;
  }
}
.label,
label {
  font-size: 1.125rem;
}

label {
  margin-left: 0.4rem;
}
.radio-input {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

:deep .p-autocomplete,
:deep .p-autocomplete-input {
  width: 100%;
}

::v-deep .p-card-content {
  padding: 1rem 1rem !important;
}
::v-deep .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: unset !important;
}
.back {
  font-weight: 700;
  color: rgba(2, 0, 56, 0.4);
  font-size: 1rem;
}
.user-error {
  font-weight: 700;
  color: red;
  margin-top: 5px;
}
</style>
