<template>
  <div class="datepicker-layout flex align-items-center">
    <i class="pi pi-calendar ml-2" />
    <Calendar
      v-model="editValue"
      :numberOfMonths="numberOfMonths"
      selectionMode="range"
      placeholder="Filter by date"
      dateFormat="dd/mm/yy"
    >
      <template #footer>
        <div class="flex justify-content-end align-items-center mt-4 pb-3">
          <div @click="onClear" class="cursor-pointer">Clear all</div>
          <Button class="ml-3" type="primary" label="Filter by date" />
        </div>
      </template>
    </Calendar>
  </div>
</template>

<script>
import Calendar from "primevue/calendar";

export default {
  name: "Datefilter",
  components: {
    Calendar,
  },
  props: ['numberOfMonths','value'],
  data() {
    return {
      editValue: "",
      displayValue: "",
      isOpen: true,
      menu: false,
    };
  },
  methods: {
    onClear() {
      this.value = ""
    },
  },
  watch: {
    editValue() {
      this.$emit("change", this.editValue)
    },
  },
  mounted() {
    this.editValue = this.value
  },
};
</script>

<style lang="scss">
@import "@/styles/global.scss";
.p-datepicker-other-month{
    background-color: #ffffff !important;
}
.p-datepicker table td {
  padding: 0 !important;
  background-color: bisque;
}
.p-datepicker table td > span {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 0 !important;
  font-size: 14px !important;
  
}
.p-datepicker table th > span {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  color: #02003866 !important;

}
.p-datepicker table td > span.p-highlight {
  background: #2CDD80 !important;
  color: #fff !important;
}
.p-datepicker .p-datepicker-header {
  border-bottom: unset !important;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: unset !important;
}
.p-datepicker {
  padding: 1rem 2rem !important;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: #2CDD80 !important;
    color: #fff !important;
}
.p-datepicker table td.p-datepicker-today > span {
    background: #2CDD80 !important;
    color: #fff !important;
    border-color: transparent;
}
.datepicker-layout {
  border: 1px solid rgba(100, 97, 170, 0.3);
  border-radius: 4px !important;
  background: $white-color !important;
  .p-inputtext {
    border: unset !important;
    font-family: $font-family !important;
    background: $white-color !important;
  }
  .p-inputtext:enabled:focus {
    box-shadow: unset !important;
  }
}
</style>
