<template>
  <div class="mr-top" :key="refreshKey">
    <div class="heading flex align-items-center justify-content-between">
      <p class="sub-title">Additional Properties</p>
      <Button @click="addProperty" type="primary" label="Add Properties" />
    </div>
    <div v-if="details.length > 0">
      <div v-for="(item, i) in details" :key="i">
        <div class="form-inputs mr-top-10px">
          <div class="p-grid align-items-end">
            <!-- Col-1 -->

            <div class="p-col-10">
              <!--Row-1 -->

              <div class="p-grid align-items-end mr-b-5px">
                <div class="p-col-3 p-d-flex flex-gap">
                  <Button
                    class="full-width"
                    type="outline"
                    label="Remove Liability"
                    @click="removeProperty(i)"
                  />
                  <!--  <img src="@/assets/Revision.svg" alt="" /> -->
                </div>
                <div class="p-col-5">
                  <p class="label">Details</p>
                  <TextField
                    @onInput="(e) => getFormData(e, 'details', i)"
                    :value="item.details"
                    width="100%"
                  />
                </div>

                <div class="p-col-4">
                  <p class="label">Property Value ($)</p>
                  <NumberField
                    mode="decimal"
                    :format="true"
                    prefix="$"
                    @onInput="(e) => getFormData(e, 'property_value', i)"
                    type="number"
                    :value="item.property_value"
                    width="100%"
                    :inValid="
                      v$.details.$silentErrors[0]?.$response.$data[i].property_value
                        .$invalid && saveBtnClicked
                    "
                  />
                </div>
              </div>
              <div>
                <div class="p-grid align-items-end mr-b-5px ml-0">
                  <div class="p-col-3">
                    <p class="label">Monthly Rental Income</p>
                    <NumberField
                      mode="decimal"
                      :format="true"
                      prefix="$"
                      @onInput="(e) => getFormData(e, 'rental_income', i)"
                      type="number"
                      :value="item.rental_income"
                      width="100%"
                      :inValid="
                        v$.details.$silentErrors[0]?.$response.$data[i].rental_income
                          .$invalid && saveBtnClicked
                      "
                    />
                  </div>

                  <div class="p-col-3">
                    <p class="label">Liability Limit</p>
                    <NumberField
                      mode="decimal"
                      :format="true"
                      prefix="$"
                      @onInput="(e) => getFormData(e, 'mortgage_limit', i)"
                      type="number"
                      :value="item.mortgage_limit"
                      width="100%"
                      :inValid="
                        v$.details.$silentErrors[0]?.$response.$data[i].mortgage_limit
                          .$invalid && saveBtnClicked
                      "
                    />
                  </div>

                  <div class="p-col-3">
                    <p class="label">Amount Owing</p>
                    <NumberField
                      mode="decimal"
                      :format="true"
                      prefix="$"
                      @onInput="(e) => getFormData(e, 'amount_owning', i)"
                      type="number"
                      :value="item.amount_owning"
                      width="100%"
                      :inValid="
                        v$.details.$silentErrors[0]?.$response.$data[i].amount_owning
                          .$invalid && saveBtnClicked
                      "
                    />
                  </div>

                  <div class="p-col-3">
                    <p class="label">Close Liability?</p>
                    <ToggleSwitch
                      :isChecked="item.closeLiability"
                      @onChange="(e) => getFormData(e, 'closeLiability', i)"
                    />
                  </div>
                </div>
              </div>
              <AddressAutocompleteKleber
                :isLoading="isAddressLoading"
                @onChange="onAddressChange($event, i)"
              />
              <div class="p-col-12">
                <div class="flex">
                  <div class="col col-4">
                    <p class="label">Address Line 1</p>
                    <TextField
                      :key="addressKey"
                      @onInput="(e) => getFormData(e, 'add_1', i)"
                      :value="item.add_1"
                      width="100%"
                      :inValid="
                        v$.details.$silentErrors[0]?.$response.$data[i].add_1.$invalid &&
                        saveBtnClicked
                      "
                    />
                  </div>
                  <div class="ml-3 col-4">
                    <div>
                      <p class="label">Address Line 2</p>
                      <TextField
                        :key="addressKey"
                        @onInput="(e) => getFormData(e, 'add_2', i)"
                        :value="item.add_2"
                        width="100%"
                        :inValid="
                          v$.details.$silentErrors[0]?.$response.$data[i].add_2
                            .$invalid && saveBtnClicked
                        "
                      />
                    </div>
                  </div>
                  <div class="ml-3 col-4">
                    <p class="label">City</p>
                    <TextField
                      :key="addressKey"
                      @onInput="(e) => getFormData(e, 'city', i)"
                      :value="item.city"
                      width="100%"
                      :disable="item.city ? true : false"
                      :inValid="
                        v$.details.$silentErrors[0]?.$response.$data[i].city.$invalid &&
                        saveBtnClicked
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="p-col-12">
                <div class="flex">
                  <div class="col-4">
                    <p class="label">State</p>
                    <TextField
                      :key="addressKey"
                      @onInput="(e) => getFormData(e, 'state', i)"
                      :value="item.state"
                      width="100%"
                      :inValid="
                        v$.details.$silentErrors[0]?.$response.$data[i].state.$invalid &&
                        saveBtnClicked
                      "
                      :disable="item.state ? true : false"
                    />
                  </div>
                  <div class="ml-3 col-4">
                    <p class="label">Country</p>
                    <TextField
                      :key="addressKey"
                      @onInput="(e) => getFormData(e, 'country', i)"
                      :value="item.country"
                      width="100%"
                      :inValid="
                        v$.details.$silentErrors[0]?.$response.$data[i].country
                          .$invalid && saveBtnClicked
                      "
                      :disable="item.country ? true : false"
                    />
                  </div>
                  <div class="col-3 ml-3 mr-3">
                    <div>
                      <p class="label">Postal Code</p>
                      <TextField
                        :key="addressKey"
                        @onInput="(e) => getFormData(e, 'postal_code', i)"
                        :value="item.postal_code"
                        width="100%"
                        :inValid="
                          v$.details.$silentErrors[0]?.$response.$data[i].postal_code
                            .$invalid && saveBtnClicked
                        "
                        :disable="item.postal_code ? true : false"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Col-2 -->

            <div v-if="i === details.length - 1" class="p-col-2 highlight-fields">
              <div class="mr-b-5px disable-input">
                <p class="label">Sub Total</p>
                <TextField
                  :value="subTotal"
                  :disable="true"
                  placeholder="label"
                  width="100%"
                  :key="totalKey"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { PMT } from "../../../../../../../../../services/findPMT";
import AddressAutocompleteKleber from "@/components/common/AddressAutocompleteKleber";

export default {
  props: ["disable"],
  setup: () => ({ v$: useVuelidate() }),
  components: { AddressAutocompleteKleber },
  validations() {
    return {
      details: {
        $each: helpers.forEach({
          property_value: { required },
          rental_income: { required },
          mortgage_limit: { required },
          amount_owning: { required },
          add_1: { required },
          city: { required },
          state: { required },
          country: { required },
          postal_code: { required },
        }),
      },
    };
  },
  data() {
    return {
      disableFields: this.$props.disable,
      details: [],
      refreshKey: 10,
      totalKey: 20,
      isAddressLoading: false,
    };
  },
  methods: {
    ...mapActions({
      setAssetProperty: "applications/setAssetProperty",
      setValidation: "applications/setValidation",
      setPropertyTotal: "subTotal/setPropertyTotal",
    }),
    addProperty() {
      let newObj = {
        property_value: 0,
        institution: "",
        closeLiability: false,
        details: "",
        rental_income: 0,
        mortgage_limit: 0,
        amount_owning: 0,
        add_1: "",
        add_2: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
      };
      this.details = [...this.details, newObj];
      return (this.disableFields = false);
    },
    async onAddressChange(address, i) {
      this.isAddressLoading = true;
      this.details[i].add_1 = address.address_1 ? address.address_1 : "";
      this.details[i].add_2 = address.address_2 ? address.address_2 : "";
      this.details[i].city = address.city ? address.city : "";
      this.details[i].state = address.state ? address.state : "";
      this.details[i].country = address.country ? address.country : "";
      this.details[i].postal_code = address.postal_code ? address.postal_code : "";
      this.isAddressLoading = false;
    },
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    removeProperty(i) {
      this.details.splice(i, 1);
      this.refreshKey += 1;
    },
    getFormData(value, field, index) {
      this.details[index][field] = value;
      this.details = [...this.details];
      this.totalKey += 1;
    },
    setData() {
      if (
        this.selectedApplication &&
        this.selectedApplication.assets_properties &&
        this.selectedApplication.assets_properties.length > 0
      ) {
        this.details = !this.disable
          ? this.selectedApplication.assets_properties.filter(
              (item) => item.applicant_type === "primary"
            )
          : this.selectedApplication.assets_properties.filter(
              (item) => item.applicant_type === "secondary"
            );
      }
    },
  },
  computed: {
    ...mapGetters({
      saveBtnClicked: "applications/saveBtnClicked",
      selectedApplication: "applications/selectedApplication",
    }),
    subTotal: {
      get: function () {
        let total = this.details.reduce((previousValue, currentValue) => {
          if (!currentValue.closeLiability) {
            return Number(previousValue) + Number(currentValue?.amount_owning);
          } else {
            return previousValue;
          }
        }, 0);

        let loan_term = this.selectedApplication.calculated_fields.implied_loan_term
          ? Number(this.selectedApplication.calculated_fields.implied_loan_term) * 12
          : 30 * 12;
        let tempValue = PMT(
          Number(this.selectedApplication.calculated_fields.service_rate) / 12,
          loan_term,
          -total
        );
        this.setPropertyTotal({
          data: tempValue,
          type: !this.disable ? "primary" : "secondary",
        });
        return "$" + tempValue.toFixed(2);
      },
    },
  },
  created() {
    this.setData();
  },
  watch: {
    details: {
      handler() {
        this.setAssetProperty({
          data: this.details,
          type: !this.disable ? "primary" : "secondary",
        });
        this.setValidation({
          type: "liability_cards",
          value: this.isSubmitDisabled(),
        });
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

::v-deep .p-col-10 {
  padding-bottom: 0;
  padding-top: 0;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.full-width {
  width: 100%;
}

.highlight-fields {
  .label {
    font-family: Gordita-bold !important;
  }
}

.heading {
  background: rgba(2, 0, 56, 0.03);
  padding: 12px;

  p {
    margin: 0;
  }
}

.mr-b-5px {
  margin-bottom: 5px;
}

.mr-top {
  margin-top: 2.5rem;
}

.mr-top-10px {
  margin-top: 10px;
}

.flex-gap {
  gap: 0.625rem;
}

.flex-gap-1 {
  gap: 0.313rem;
}

.flex-gap-2 {
  gap: 1.875rem;
}
</style>
