<template>
  <div class="mr-top">
    <Panel :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>

      <template #header>
        <div @click="toggle" class="sub-title full-width pointer">Funds To Complete</div>
      </template>

      <div :key="refreshKey" class="form-inputs mr-top-10px">
        <div class="p-grid align-items-end">
          <!-- Col-1 -->

          <div class="p-col-10">
            <!-- Row-1 -->

            <div class="p-grid align-items-center mr-b-5px">
              <div class="p-col-4 disable-input">
                <p class="label">+ Purchase Property</p>
                <NumberField
                  mode="decimal"
                  :format="true"
                  :key="purchasePriceKey"
                  prefix="$"
                  :value="purchase_price"
                  :disable="true"
                  width="100%"
                />
              </div>

              <div class="p-col-4 disable-input">
                <p class="label">- Deposit Paid</p>
                <NumberField
                  mode="decimal"
                  :format="true"
                  :key="depositKey"
                  prefix="$"
                  :value="Math.abs(deposit_amount)"
                  :disable="true"
                  width="100%"
                />
              </div>

              <div class="p-col-4 disable-input">
                <p class="label">- Savings</p>
                <NumberField
                  mode="decimal"
                  :format="true"
                  prefix="$"
                  :key="savingsKey"
                  :value="Math.abs(savings)"
                  width="100%"
                  @onInput="getFormData($event, 'savings')"
                />
              </div>
            </div>

            <!-- Row-2 -->

            <div class="p-d-flex flex-gap align-items-end mr-b-5px">
              <div>
                <p class="label">+ Settlement Fees and Charges</p>
                <NumberField
                  mode="decimal"
                  :format="true"
                  prefix="$"
                  width="100%"
                  @getInputValue="getFormData($event, 'settlement_charge')"
                  :value="settlement_charge"
                  :disable="true"
                  :key="settlementKey"
                />
              </div>

              <div class="disable-input">
                <p class="label">+ Stamp Duty</p>
                <NumberField
                  mode="decimal"
                  :format="true"
                  :key="stampDutyKey"
                  prefix="$"
                  :value="stamp_duty ? Number(stamp_duty).toFixed(2) : 0"
                  :disable="true"
                  width="100%"
                />
              </div>

              <div class="disable-input">
                <p class="label disable-input">+ Requested Surplus</p>
                <NumberField
                  mode="decimal"
                  :format="true"
                  prefix="$"
                  :disable="false"
                  :value="requested_surplus"
                  width="100%"
                  @onInput="getFormData($event, 'requested_surplus')"
                />
              </div>

              <div class="disable-input">
                <p class="label disable-input">+ Other Liabilities</p>
                <NumberField
                  mode="decimal"
                  :format="true"
                  prefix="$"
                  :key="liabilityKey"
                  :disable="true"
                  :value="other_liability"
                  width="100%"
                />
              </div>

              <div class="disable-input">
                <p class="label disable-input">+ Mortgage Payout Estimate</p>
                <NumberField
                  mode="decimal"
                  :format="true"
                  prefix="$"
                  :key="mortgageKey"
                  :disable="true"
                  :value="mortgage_payout ? mortgage_payout.toFixed(2) : 0"
                  width="100%"
                />
              </div>
            </div>
          </div>

          <!-- Col-2 -->

          <div class="p-col-2 highlight-fields">
            <div class="mr-b-5px disable-input">
              <p class="label">Funds to complete</p>
              <NumberField
                mode="decimal"
                :format="true"
                prefix="$"
                :value="funds_to_complete ? Number(funds_to_complete).toFixed(2) : 0"
                :disable="true"
                placeholder="label"
                width="100%"
                :key="totalKey"
              />
            </div>
          </div>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Panel from "primevue/panel";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      fundsFormData: {
        settlementFees: { required },
      },
    };
  },
  components: { Panel },
  emits: ["fundsFormData"],
  computed: {
    ...mapGetters({
      monthlySurplus: "subTotal/monthlySurplus",
      buyingProperty: "applications/buyingProperty",
      mortgagePayoutData: "summary/mortgagePayout",
      settlementData: "summary/settlementData",
      personalLoanTotal: "subTotal/personalLoanTotal",
      savingsTotal: "subTotal/savingsTotal",
      closeLiablityCardTotal: "subTotal/closeLiablityCardTotal",
      closeLiabilityVehicleTotal: "subTotal/closeLiabilityVehicleTotal",
      closeLiablityPersonalTotal: "subTotal/closeLiablityPersonalTotal",
    }),
    ...mapGetters({ selectedApplication: "applications/selectedApplication" }),
  },
  data() {
    return {
      collapsed: true,
      purchase_price: 0,
      deposit_amount: 0,
      savings: 0,
      settlement_charge: 0,
      stamp_duty: 0,
      requested_surplus: 0,
      other_liability: 0,
      mortgage_payout: 0,
      funds_to_complete: 0,
      mortgageKey: 10,
      liabilityKey: 20,
      savingsKey: 30,
      settlementKey: 40,
      purchasePriceKey: 50,
      depositKey: 60,
      stampDutyKey: 70,
      totalKey: 80,
      fundsFormData: {
        settlementFees: "",
      },
    };
  },
  methods: {
    ...mapActions({
      setFundsComplete: "summary/setFundsComplete",
      setAdditionalfund: "applications/setAdditionalfund",
    }),
    toggle() {
      this.collapsed = !this.collapsed;
    },
    getFormData(value, fieldName) {
      this.fundsFormData[fieldName] = value;
      this.fundsFormData = { ...this.fundsFormData };
      if (fieldName == "savings") {
        this.savings = value;
        this.setFundsComplete({
          field: "total_savings_to_purchase",
          value: this.savings,
        });
        this.$emit("validateFunds", this.v$.$invalid);
      }
      if (fieldName == "requested_surplus") {
        this.setAdditionalfund(value);
      }
      //Final Total
      // this.funds_to_complete =
      //   Number(this.purchase_price) +
      //   Number(this.deposit_amount) +
      //   Number(this.savings) +
      //   Number(this.settlement_charge) +
      //   Number(this.stamp_duty) +
      //   Number(this.requested_surplus) +
      //   Number(this.other_liability) +
      //   Number(this.mortgage_payout);
      // this.setFundsComplete({
      //   field: "funds_to_complete",
      //   value: this.funds_to_complete,
      // });
      // this.totalKey += 1;
    },
    setData() {
      //Get total purchase price
      // this.purchase_price = this.buyingProperty.reduce(
      //   (prev, current) => prev + current.purchase_price_used,
      //   0
      // );
      this.purchase_price =
        this.selectedApplication && this.selectedApplication.calculated_fields
          ? this.selectedApplication.calculated_fields.purchase_property
          : 0;
      this.setFundsComplete({
        field: "purchase_price",
        value: this.purchase_property,
      });
      //Get total deposit amount + purchase price
      // let deposit = this.buyingProperty.reduce((prev, current) => {
      //   if (current.deposit_type == "cash") {
      //     return Number(prev) + Number(current.deposit_amount);
      //   } else {
      //     return prev;
      //   }
      // }, 0);
      // this.deposit_amount = -deposit;
      this.deposit_amount =
        this.selectedApplication &&
        this.selectedApplication.calculated_fields &&
        this.selectedApplication.calculated_fields.deposit_paid
          ? Number(this.selectedApplication.calculated_fields.deposit_paid)
          : 0;
      this.setFundsComplete({
        field: "deposit_amount",
        value: this.deposit_amount,
      });
      //- Savings
      let savingsOverAll =
        this.selectedApplication && this.selectedApplication.calculated_fields
          ? Number(this.selectedApplication.calculated_fields.total_savings_to_purchase)
          : 0;
      this.savings = savingsOverAll;
      this.setFundsComplete({
        field: "total_savings_to_purchase",
        value: this.savings,
      });
      //+ settlement Fees
      // this.settlement_charge = this.selectedApplication.calcalculatedFields.settlement_fee;
      this.settlement_charge =
        this.selectedApplication &&
        this.selectedApplication.calculated_fields &&
        this.selectedApplication.calculated_fields.settlement_fee;
      this.setFundsComplete({
        field: "settlement_charge",
        value: this.settlement_charge,
      });
      //Get stamp duty + prev
      // let stampDuty = this.buyingProperty.reduce((prev, current) => {
      //   let stampvalue =
      //     current.stamp_duty_status == "at_settlement"
      //       ? current.stamp_duty
      //         ? Number(current.stamp_duty)
      //         : 0
      //       : 0;
      //   return prev + Number(stampvalue);
      // }, 0);
      // this.stamp_duty = stampDuty;
      this.stamp_duty =
        this.selectedApplication &&
        this.selectedApplication.calculated_fields &&
        this.selectedApplication.calculated_fields.stamp_duty
          ? Number(this.selectedApplication.calculated_fields.stamp_duty)
          : 0;
      this.setFundsComplete({ field: "stamp_duty", value: this.stamp_duty });
      //Request surplus
      this.requested_surplus = this.selectedApplication.additional_fund
        ? this.selectedApplication.additional_fund
        : 0;
      this.setFundsComplete({
        field: "requested_surplus",
        value: this.requested_surplus,
      });
      //Other liability
      // let liabilityTotal =
      //   this.personalLoanTotal.applicant_1 + this.personalLoanTotal.applicant_2;
      // this.other_liability = liabilityTotal ? liabilityTotal : 0;
      // this.other_liability =
      //   this.closeLiablityCardTotal.applicant_1 +
      //   this.closeLiablityCardTotal.applicant_2 +
      //   this.closeLiabilityVehicleTotal.applicant_1 +
      //   this.closeLiabilityVehicleTotal.applicant_2 +
      //   this.closeLiablityPersonalTotal.applicant_1 +
      //   this.closeLiablityPersonalTotal.applicant_2;
      this.other_liability =
        this.selectedApplication &&
        this.selectedApplication.calculated_fields &&
        this.selectedApplication.calculated_fields.other_liabilities
          ? Number(this.selectedApplication.calculated_fields.other_liabilities)
          : 0;
      this.setFundsComplete({
        field: "other_liability",
        value: this.other_liability,
      });
      //MortgagePayout
      this.mortgage_payout =
        this.selectedApplication &&
        this.selectedApplication.calculated_fields &&
        this.selectedApplication.calculated_fields.mortgage_payout_estimate
          ? Number(this.selectedApplication.calculated_fields.mortgage_payout_estimate)
          : 0;
      this.setFundsComplete({
        field: "mortgage_payout",
        value: this.mortgage_payout,
      });
      //Final Total
      // this.funds_to_complete =
      //   Number(this.purchase_price) +
      //   Number(this.deposit_amount) +
      //   Number(this.savings) +
      //   Number(this.settlement_charge) +
      //   Number(this.stamp_duty) +
      //   Number(this.requested_surplus) +
      //   Number(this.other_liability) +
      //   Number(this.mortgage_payout);
      this.funds_to_complete =
        this.selectedApplication &&
        this.selectedApplication.calculated_fields &&
        this.selectedApplication.calculated_fields.funds_to_complete_purchase;
      this.setFundsComplete({
        field: "funds_to_complete",
        value: this.funds_to_complete,
      });
      this.totalKey += 1;
    },
  },
  created() {
    this.setData();
  },
  watch: {
    savingsTotal: {
      handler() {
        let savingsTotalValue = this.selectedApplication?.calculated_fields
          ?.total_savings_to_purchase
          ? Number(this.selectedApplication?.calculated_fields?.total_savings_to_purchase)
          : 0;
        this.savings =
          Math.abs(savingsTotalValue) +
          this.savingsTotal.applicant_1 +
          this.savingsTotal.applicant_2;
        this.savingsKey += 1;
        this.setFundsComplete({
          field: "total_savings_to_purchase",
          value: this.savings,
        });
      },
      deep: true,
    },
  },
  // watch: {
  //   // mortgagePayoutData: {
  //   //   handler() {
  //   //     //MortgagePayout
  //   //     this.mortgage_payout = this.mortgagePayoutData.mortgage_payout
  //   //       ? this.mortgagePayoutData.mortgage_payout
  //   //       : 0;
  //   //     this.setFundsComplete({
  //   //       field: "mortgage_payout",
  //   //       value: this.mortgage_payout,
  //   //     });
  //   //     this.mortgageKey += 1;
  //   //     //Final Total
  //   //     this.funds_to_complete =
  //   //       Number(this.purchase_price) -
  //   //       Number(this.deposit_amount) -
  //   //       Number(this.savings) +
  //   //       Number(this.settlement_charge) +
  //   //       Number(this.stamp_duty) +
  //   //       Number(this.requested_surplus) +
  //   //       Number(this.other_liability) +
  //   //       Number(this.mortgage_payout);
  //   //     this.setFundsComplete({
  //   //       field: "funds_to_complete",
  //   //       value: this.funds_to_complete,
  //   //     });
  //   //     this.totalKey += 1;
  //   //   },
  //   //   deep: true,
  //   // },
  //   personalLoanTotal: {
  //     handler() {
  //       //Other liability
  //       // let liabilityTotal =
  //       //   this.personalLoanTotal.applicant_1 +
  //       //   this.personalLoanTotal.applicant_2;
  //       // this.other_liability = liabilityTotal ? liabilityTotal : 0;
  //       this.other_liability =
  //         this.selectedApplication &&
  //         this.selectedApplication.calculated_fields &&
  //         this.selectedApplication.calculated_fields.other_liabilities
  //           ? this.selectedApplication.calculated_fields.other_liabilities
  //           : 0;
  //       this.setFundsComplete({
  //         field: "other_liability",
  //         value: this.other_liability,
  //       });
  //       this.liabilityKey += 1;
  //       //Final Total
  //       this.funds_to_complete =
  //         Number(this.purchase_price) +
  //         Number(this.deposit_amount) +
  //         Number(this.savings) +
  //         Number(this.settlement_charge) +
  //         Number(this.stamp_duty) +
  //         Number(this.requested_surplus) +
  //         Number(this.other_liability) +
  //         Number(this.mortgage_payout);
  //       this.setFundsComplete({
  //         field: "funds_to_complete",
  //         value: this.funds_to_complete,
  //       });
  //       this.totalKey += 1;
  //     },
  //     deep: true,
  //   },
  //   closeLiablityCardTotal: {
  //     handler() {
  //       //Other liability
  //       // let liabilityTotal =
  //       //   this.personalLoanTotal.applicant_1 +
  //       //   this.personalLoanTotal.applicant_2;
  //       // this.other_liability = liabilityTotal ? liabilityTotal : 0;
  //       this.other_liability =
  //         this.selectedApplication &&
  //         this.selectedApplication.calculated_fields &&
  //         this.selectedApplication.calculated_fields.other_liabilities
  //           ? this.selectedApplication.calculated_fields.other_liabilities
  //           : 0;
  //       this.setFundsComplete({
  //         field: "other_liability",
  //         value: this.other_liability,
  //       });
  //       this.liabilityKey += 1;
  //       //Final Total
  //       this.funds_to_complete =
  //         Number(this.purchase_price) +
  //         Number(this.deposit_amount) +
  //         Number(this.savings) +
  //         Number(this.settlement_charge) +
  //         Number(this.stamp_duty) +
  //         Number(this.requested_surplus) +
  //         Number(this.other_liability) +
  //         Number(this.mortgage_payout);
  //       this.setFundsComplete({
  //         field: "funds_to_complete",
  //         value: this.funds_to_complete,
  //       });
  //       this.totalKey += 1;
  //     },
  //     deep: true,
  //   },
  //   closeLiabilityVehicleTotal: {
  //     handler() {
  //       //Other liability
  //       // let liabilityTotal =
  //       //   this.personalLoanTotal.applicant_1 +
  //       //   this.personalLoanTotal.applicant_2;
  //       // this.other_liability = liabilityTotal ? liabilityTotal : 0;
  //       this.other_liability =
  //         this.selectedApplication &&
  //         this.selectedApplication.calculated_fields &&
  //         this.selectedApplication.calculated_fields.other_liabilities
  //           ? this.selectedApplication.calculated_fields.other_liabilities
  //           : 0;
  //       this.setFundsComplete({
  //         field: "other_liability",
  //         value: this.other_liability,
  //       });
  //       this.liabilityKey += 1;
  //       //Final Total
  //       this.funds_to_complete =
  //         Number(this.purchase_price) +
  //         Number(this.deposit_amount) +
  //         Number(this.savings) +
  //         Number(this.settlement_charge) +
  //         Number(this.stamp_duty) +
  //         Number(this.requested_surplus) +
  //         Number(this.other_liability) +
  //         Number(this.mortgage_payout);
  //       this.setFundsComplete({
  //         field: "funds_to_complete",
  //         value: this.funds_to_complete,
  //       });
  //       this.totalKey += 1;
  //     },
  //     deep: true,
  //   },
  //   closeLiablityPersonalTotal: {
  //     handler() {
  //       //Other liability
  //       // let liabilityTotal =
  //       //   this.personalLoanTotal.applicant_1 +
  //       //   this.personalLoanTotal.applicant_2;
  //       // this.other_liability = liabilityTotal ? liabilityTotal : 0;
  //       this.other_liability =
  //         this.selectedApplication &&
  //         this.selectedApplication.calculated_fields &&
  //         this.selectedApplication.calculated_fields.other_liabilities
  //           ? this.selectedApplication.calculated_fields.other_liabilities
  //           : 0;
  //       this.setFundsComplete({
  //         field: "other_liability",
  //         value: this.other_liability,
  //       });
  //       this.liabilityKey += 1;
  //       //Final Total
  //       this.funds_to_complete =
  //         Number(this.purchase_price) +
  //         Number(this.deposit_amount) +
  //         Number(this.savings) +
  //         Number(this.settlement_charge) +
  //         Number(this.stamp_duty) +
  //         Number(this.requested_surplus) +
  //         Number(this.other_liability) +
  //         Number(this.mortgage_payout);
  //       this.setFundsComplete({
  //         field: "funds_to_complete",
  //         value: this.funds_to_complete,
  //       });
  //       this.totalKey += 1;
  //     },
  //     deep: true,
  //   },
  //   // savingsTotal: {
  //   //   handler() {
  //   //     //- Savings
  //   //     let savingsOverAll =
  //   //       this.savingsTotal.applicant_1 + this.savingsTotal.applicant_2;
  //   //     this.savings = savingsOverAll ? savingsOverAll : 0;
  //   //     this.setFundsComplete({ field: "savings", value: this.savings });
  //   //     this.savingsKey += 1;
  //   //     //Final Total
  //   //     this.funds_to_complete =
  //   //       Number(this.purchase_price) -
  //   //       Number(this.deposit_amount) -
  //   //       Number(this.savings) +
  //   //       Number(this.settlement_charge) +
  //   //       Number(this.stamp_duty) +
  //   //       Number(this.requested_surplus) +
  //   //       Number(this.other_liability) +
  //   //       Number(this.mortgage_payout);
  //   //     this.setFundsComplete({
  //   //       field: "funds_to_complete",
  //   //       value: this.funds_to_complete,
  //   //     });
  //   //     this.totalKey += 1;
  //   //   },
  //   //   deep: true,
  //   // },
  //   buyingProperty: {
  //     handler() {
  //       //Get total purchase price
  //       this.purchase_price = this.buyingProperty.reduce(
  //         (prev, current) => prev + current.purchase_price_used,
  //         0
  //       );
  //       this.setFundsComplete({
  //         field: "purchase_price",
  //         value: this.purchase_price,
  //       });
  //       this.purchasePriceKey += 1;
  //       //Get total deposit amount + purchase price
  //       let deposit = this.buyingProperty.reduce((prev, current) => {
  //         if (current.deposit_type == "cash") {
  //           return Number(prev) + Number(current.deposit_amount);
  //         } else {
  //           return prev;
  //         }
  //       }, 0);
  //       this.deposit_amount = -deposit;
  //       this.setFundsComplete({
  //         field: "deposit_amount",
  //         value: this.deposit_amount,
  //       });
  //       this.depositKey += 1;
  //       //Get stamp duty + prev
  //       let stampDuty = this.buyingProperty.reduce((prev, current) => {
  //         let stampvalue =
  //           current.stamp_duty_status == "at_settlement"
  //             ? current.stamp_duty
  //               ? Number(current.stamp_duty)
  //               : 0
  //             : 0;
  //         return prev + Number(stampvalue);
  //       }, 0);
  //       this.stamp_duty = stampDuty;
  //       this.setFundsComplete({ field: "stamp_duty", value: this.stamp_duty });
  //       this.stampDutyKey += 1;
  //       //Final Total
  //       this.funds_to_complete =
  //         Number(this.purchase_price) +
  //         Number(this.deposit_amount) +
  //         Number(this.savings) +
  //         Number(this.settlement_charge) +
  //         Number(this.stamp_duty) +
  //         Number(this.requested_surplus) +
  //         Number(this.other_liability) +
  //         Number(this.mortgage_payout);
  //       this.setFundsComplete({
  //         field: "funds_to_complete",
  //         value: this.funds_to_complete,
  //       });
  //       this.totalKey += 1;
  //     },
  //     deep: true,
  //   },
  // },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
}

::v-deep label {
  font-family: Gordita-medium;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid #02003826 !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

.highlight-fields {
  ::v-deep .label {
    font-family: Gordita-bold !important;
  }
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.mr-b-5px {
  margin-bottom: 5px;
}

.mr-top {
  margin-top: 2.5rem;
}

.mr-top-10px {
  margin-top: 10px;
}

.flex-gap {
  gap: 0.625rem;
}
</style>
