<template>
  <div>
    <div class="content">
      <div class="form-inputs mt-4">
        <div class="p-grid align-items-center header">
          <p class="col"></p>
          <p class="col label">Application Values</p>
          <p class="col label">Current Values</p>
          <p class="col label">Current Value Source</p>
          <div class="col"></div>
        </div>

        <InputRow
          label="Legal Fees"
          :value1="originalData.legal_fees"
          :value2="latestData.legal_fees"
        />
        <InputRow
          label="Disbursement Fees"
          :value1="originalData.disbursement_fees"
          :value2="latestData.disbursement_fees"
        />
        <InputRow
          label="PEXA Fees"
          :value1="originalData.pexa_fees"
          :value2="latestData.pexa_fees"
        />
        <InputRow
          label="Land Titles Office Registration Fee"
          :value1="
            originalData.land_titles_office_reg_fee
              ? originalData.land_titles_office_reg_fee
              : 0
          "
          :value2="latestData.land_titles_office_reg_fee"
        />
        <InputRow
          label="Buffer"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.buffer
              : 0
          "
          :value2="
            latestData.mortgage_payout ? latestData.mortgage_payout.buffer : 0
          "
        />
        <InputRow
          label="Settlement Fees and Charges"
          :value1="
            originalData.settlement_fees ? originalData.settlement_fees : 0
          "
          :value2="latestData.settlement_fees ? latestData.settlement_fees : 0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputRow from "../../InputRow.vue";
import { mapGetters } from "vuex";
export default {
  components: { InputRow },
  computed: {
    ...mapGetters({
      originalData: "loans/originalData",
      latestData: "loans/latestData",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
</style>
