<template>
  <div class="pl-4 pr-4 pb-4">
    <Card class="box-shadow">
      <template #title>
        <div class="flex justify-content-between">
          <span class="module-sub-title">Communication history</span>
          <div class="flex align-items-center total-task">
            <div class="flex align-items-center ml-3">
              <span class="mr-2">+</span> New call
            </div>
            <div
              @click="displaySendText = true"
              class="pointer flex align-items-center ml-3"
            >
              <span class="mr-2">+</span> New text
            </div>
            <div
              class="flex align-items-center ml-3 cursor-pointer"
              @click="displaySendEmail = true"
            >
              <span class="mr-2">+</span> New email
            </div>
            <div class="flex align-items-center mr-2">
              <div class="label-filter mr-2 ml-2">Filter&nbsp;by</div>
              <Dropdown
                width="100%"
                @change="getFilterValue"
                placeholder="-Select-"
                :items="filterList"
                :value="selectedFilter"
                optionLabel="name"
                optionValue="value"
              />
            </div>
          </div>
        </div>
      </template>
      <template #content>
        <Spinner v-if="isLoading" class="flex justify-content-between" />

        <DataTable
          v-else
          :expandedRows="expandedRows"
          @row-expand="onRowExpand"
          @row-collapse="onRowCollapse"
          responsiveLayout="scroll"
          dataKey="Id"
          :value="communicationList"
          class="mt-2"
        >
          <template #empty> No data found. </template>

          <Column field="type" header="Type">
            <template #body="slotProps">
              {{ slotProps.data.type || "Email" }}
            </template>
          </Column>
          <!-- <Column field="description" header="Description">
            <template #body="slotProps">
              {{ slotProps.data.description || "-" }}
            </template>
          </Column> -->
          <Column field="from" header="Sent By">
            <template #body="slotProps">
              {{ slotProps.data.from || "-" }}
            </template>
          </Column>
          <Column field="to" header="Sent To">
            <template #body="slotProps">
              {{ slotProps.data.to || "-" }}
            </template>
          </Column>
          <Column field="date_sent" header="Sent Date">
            <template #body="slotProps">
              {{ formatDate(slotProps.data.date_sent) || "-" }}
            </template>
          </Column>
          <Column field="Id" header=" " :expander="true">
            <template #body="slotProps">
              <p
                @click="onRowExpand(slotProps.data)"
                v-if="!slotProps.data.expanded"
                class="pointer color-highlight flex align-items-center"
              >
                view
                <i class="pi pi-angle-down ml-2 cursor-pointer" />
              </p>
              <p
                @click="onRowCollapse(slotProps.data)"
                v-else
                class="pointer color-highlight flex align-items-center"
              >
                view
                <i class="pi pi-angle-up ml-2 cursor-pointer" />
              </p>
            </template>
          </Column>
          <template #expansion="slotProps">
            <div class="sec-content">
              <div class="flex align-items">
                <div class="col-3">
                  <p class="heading lt-txt-color fs-sm hk-sb">Message ID</p>
                  <p class="text hk-sb sec-color-txt fs-sm">
                    {{ slotProps.data.Id || "-" }}
                  </p>
                </div>
                <div class="col-3">
                  <p class="heading lt-txt-color fs-sm hk-sb">From</p>
                  <p class="text hk-sb sec-color-txt fs-sm">
                    {{ slotProps.data.from || "-" }}
                  </p>
                </div>
                <div class="col-3">
                  <p class="heading lt-txt-color fs-sm hk-sb">Date sent</p>
                  <p class="text hk-sb sec-color-txt fs-sm">
                    {{
                      slotProps.data.date_sent
                        ? formatDate(slotProps.data.date_sent)
                        : "-"
                    }}
                  </p>
                </div>
                <div class="col-3">
                  <p class="heading lt-txt-color fs-sm hk-sb">Status</p>
                  <p class="text hk-sb sec-color-txt fs-sm">
                    {{ slotProps.data.status_name || "-" }}
                  </p>
                </div>
              </div>
              <div class="flex align-items">
                <div class="col-3">
                  <p class="heading lt-txt-color fs-sm hk-sb">Created by</p>
                  <p class="text hk-sb sec-color-txt fs-sm">
                    {{ slotProps.data.created_by || "-" }}
                  </p>
                </div>
                <div class="col-3">
                  <p class="heading lt-txt-color fs-sm hk-sb">To</p>
                  <p class="text hk-sb sec-color-txt fs-sm">
                    {{ slotProps.data.to }}
                  </p>
                </div>
                <!-- <div class="col-3">
                  <p class="heading lt-txt-color fs-sm hk-sb">Template</p>
                  <p class="text hk-sb sec-color-txt fs-sm">
                    {{ slotProps.data.templateName || "-" }}
                  </p>
                </div> -->
                <div class="col-3">
                  <p class="heading lt-txt-color fs-sm hk-sb">Status updated</p>
                  <p class="text hk-sb sec-color-txt fs-sm">
                    {{
                      slotProps.data.status_updated
                        ? formatDate(slotProps.data.status_updated)
                        : "-"
                    }}
                  </p>
                </div>
              </div>

              <div class="mt-4 ml-2">
                <p class="heading lt-txt-color fs-sm hk-sb">Message</p>
                <p class="text hk-sb sec-color-txt fs-sm">
                  {{ removeTags(slotProps.data.message) || "-" }}
                </p>
              </div>
            </div>
          </template>
        </DataTable>
        <Paginator
          class="mt-2"
          :rows="10"
          :totalRecords="totalCount"
          :rowsPerPageOptions="[10, 20, 30]"
          template="RowsPerPageDropdown  CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} of {totalRecords} communications"
          @page="onChangePage($event)"
        ></Paginator>
      </template>
    </Card>
    <SendEmail
      v-if="displaySendEmail"
      portal="broker"
      @save="refreshKey++"
      :show="displaySendEmail"
      @close="displaySendEmail = false"
    />
    <SendText
      portal="application"
      @save="refreshKey++"
      :show="displaySendText"
      @close="displaySendText = false"
    />
  </div>
</template>

<script>
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import SendEmail from "../../Customer/CustomerData/SendEmail.vue";
import SendText from "../../Customer/CustomerData/SendText.vue";
import { _get } from "../../../../services/crudService";
import { SERVER_URL, APP_URL } from "../../../../../constants";
import Paginator from "primevue/paginator";
import moment from "moment";

export default {
  components: {
    Card,
    DataTable,
    Paginator,
    Column,
    SendEmail,
    SendText,
  },
  data() {
    return {
      refreshKey: 10,
      expandedRows: [],
      displaySendEmail: false,
      displaySendText: false,
      filterList: [
        { name: "Call", value: "call" },
        { name: "Email", value: "email" },
        { name: "Live chat", value: "live_chat" },
        { name: "Sms", value: "sms" },
      ],
      selectedFilter: "",
      isLoading: false,
      communicationList: [],
      totalCount: 0,
    };
  },
  watch: {
    refreshKey() {
      this.getCommunicationList(1, 10);
    },
  },
  methods: {
    collapseAll() {
      this.expandedRows = null;
    },
    removeTags(str) {
      if (str === null || str === "") return false;
      else str = str.toString();
      return str.replace(/(<([^>]+)>)/gi, "");
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY hh:mm");
    },
    async getFilterValue(value) {
      this.selectedFilter = value;
      if (value !== "") {
        this.isLoading = true;
        try {
          const response = await _get(
            SERVER_URL + `filter-data?table=application&value=${value}`
          );

          if (response) {
            this.communicationList = response.data;
            this.isLoading = false;
          }
        } catch (err) {
          this.isLoading = false;

          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
    },
    onRowExpand(event) {
      this.expandedRows = this.communicationList.filter(
        (data) => event?.Id === data.Id
      );
      this.communicationList.map((obj) => {
        if (obj.Id === event.Id) {
          obj.expanded = !obj.expanded;
        } else {
          obj.expanded = false;
        }
      });
    },
    onRowCollapse(event) {
      this.expandedRows = this.expandedRows.filter(
        (data) => event?.Id !== data.Id
      );
      this.communicationList.map((obj) => {
        if (obj.Id === event.Id) {
          obj.expanded = false;
        } else {
          obj.expanded = false;
        }
      });
    },
    async getCommunicationList() {
      try {
        this.isLoading = true;
        const response = await _get(
          APP_URL +
            `get-comms-by-app-id?type=Broker&mobile=` +
            this.$route.query.mobile +
            "&email=" +
            encodeURIComponent(this.$route.query.email)
        );

        if (response) {
          response.data.result.map((list) =>
            this.communicationList.push({ ...list, expanded: false })
          );
          this.totalCount = response.data.totalCount || "";

          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },
  async created() {
    this.getCommunicationList(1, 10);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.p-card {
  box-shadow: unset !important;
  border-radius: 6px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  height: 100% !important;
}
::v-deep .p-card-title {
  color: #020038 !important;
}
::v-deep .p-card-content {
  text-align: center !important;
  font-size: 14px !important;
  color: #020038 !important;
  font-weight: 500;
  font-size: 14px;
}
::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}
.total-task {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
}
.color-highlight {
  color: $primary-color !important;
}
.inner-box-layout {
  background: rgba(2, 0, 56, 0.03);
  min-height: 243px;
  font-size: 14px;
  border-radius: 4px;
  .title {
    color: rgba(2, 0, 56, 0.4);
    font-weight: 500;
  }
  .content {
    font-weight: 600;
    color: #020038;
  }
}
.expandor {
  color: $primary-color;
}
::v-deep .p-input-icon-left > i:first-of-type {
  left: 0.5rem;
  color: rgba(2, 0, 56, 0.3);
}
::placeholder {
  color: rgba(2, 0, 56, 0.3);
  opacity: 1; /* Firefox */
}
</style>
