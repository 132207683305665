export default {
  namespaced: true,
  state: {
    brokerList: [],
    brokerDetails: null,
    selectedBroker: null,
  },
  getters: {
    brokerList: (state) => state.brokerList,
    brokerDetails: (state) => state.brokerDetails,
    selectedBroker: (state) => state.selectedBroker,
  },
  mutations: {
    setBrokerList(state, payload) {
      state.brokerList = payload
    },
    setBrokerDetails(state, payload) {
      return (state.brokerDetails = payload)
    },
    setSelectedBroker(state, payload) {
      return (state.selectedBroker = payload)
    },
  },
  actions: {
    async getBrokerList(context) {
      context.commit("setBrokerList")
    },
    setSelectedBroker(context, payload) {
      context.commit("setSelectedBroker", payload)
    },
  },
}
