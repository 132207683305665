<template>
  <div>
    <div
      class="flex align-items-center justify-content-between std-padding-x mr-b"
    >
      <div>
        <p class="hk-b lt-txt-color fs-18px">Field Values</p>
      </div>
      <div class="icons">
        <img
          class="pointer"
          @click="goToDecisionSeeker"
          src="@/assets/play.svg"
          alt=""
        />
    <!-- <img
          class="pointer"
          src="@/assets/mail-icon.svg"
           @click="showSendOffer"
          alt=""
        /> -->
        <img
          class="pointer"
          src="@/assets/external-link.svg"
          @click="showExportDialog"
          alt=""
        />
      </div>
    </div>

    <PropertyDetails />
    <Income />
    <AssetsLiabilities />
    <Footer class="std-margin-x" />
    <SendOffer :show="displaySendEmail" @close="displaySendEmail = false" />
    <Export :show="displayExportDialog" @close="displayExportDialog = false" />
  </div>
  
</template>

<script>
import PropertyDetails from "./PropertyDetails/index.vue"
import Income from "./Income/index.vue"
import AssetsLiabilities from "./Assets-Liabilities/index.vue"
import SendOffer from "./SendOffer.vue"
import Export from "./Export.vue"
import Footer from "./Footer.vue"

export default {
  components: { PropertyDetails, Income, AssetsLiabilities, SendOffer, Export, Footer },
  data() {
    return {
      displaySendEmail: false,
      displayExportDialog: false,
    }
  },
   methods: {
     showSendOffer() {
      return (this.displaySendEmail = true)
    },
    showExportDialog() {
      return (this.displayExportDialog = true)
    },
    goToDecisionSeeker() {
      return this.$router.push({ name: "DecisionSeeker" })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.std-padding-x {
  padding: 0 2.188rem;
}

.std-margin-x {
  margin: 0 2.188rem;
}

p {
  margin: 0;
}

.mr-b {
  margin-bottom: 1.875rem;
}

.icons {
  display: flex;
  gap: 1.5rem;
}
</style>
