<template>
  <div class="mr-top">
    <div class="heading flex align-items-center justify-content-between">
      <p class="sub-title">Additional Income</p>
      <!-- <Button type="primary" label="Add Full-time Income" /> -->
    </div>
    <div class="form-inputs mr-top-10px">
      <div class="p-grid align-items-end">
        <!-- Col-1 -->

        <div class="p-col-10 c1">
          <!-- Row-1 -->

          <div class="p-grid align-items-end mr-b-5px">
            <div class="p-col-6 r1">
              <div class="flex rmv-mrg align-items-end flex-gap">
                <!-- <img class="" src="@/assets/Revision.svg" alt="" /> -->
                <div>
                  <p class="label">Centerlink</p>
                  <NumberField
                    :value="details.centerlink_payments"
                    type="number"
                    width="auto"
                    :format="true"
                    prefix="$"
                    :inValid="v$.details.centerlink_payments.$invalid && saveBtnClicked"
                    @onInput="(e) => getFormData(e, 'centerlink_payments')"
                  />
                </div>
                <div>
                  <p class="label">Frequency</p>
                  <Dropdown
                    :value="details.centerlink_payments_frequency"
                    :items="frequencyOpt"
                    width="150px"
                    @change="(e) => getFormData(e, 'centerlink_payments_frequency')"
                    :inValid="
                      v$.details.centerlink_payments_frequency.$invalid && saveBtnClicked
                    "
                  />
                </div>
                <div class="disable-input">
                  <p class="label">Ratio%</p>
                  <TextField
                    :disable="true"
                    :value="
                      calculateRatio(
                        details.centerlink_payments,
                        details.centerlink_payments_frequency
                      )
                    "
                    width="100%"
                  />
                </div>
              </div>
            </div>

            <div class="p-col-6 r1">
              <div class="flex rmv-mrg align-items-end flex-gap ml-2">
                <!-- <img class="" src="@/assets/Revision.svg" alt="" /> -->
                <div>
                  <p class="label">Child Support</p>
                  <NumberField
                    :value="details.child_support_maintenance"
                    type="number"
                    width="auto"
                    :format="true"
                    prefix="$"
                    @onInput="(e) => getFormData(e, 'child_support_maintenance')"
                    :inValid="
                      v$.details.child_support_maintenance.$invalid && saveBtnClicked
                    "
                  />
                </div>
                <div>
                  <p class="label">Frequency</p>
                  <Dropdown
                    :items="frequencyOpt"
                    width="150px"
                    :value="details.child_support_maintenance_frequency"
                    @change="(e) => getFormData(e, 'child_support_maintenance_frequency')"
                    :inValid="
                      v$.details.child_support_maintenance_frequency.$invalid &&
                      saveBtnClicked
                    "
                  />
                </div>
                <div class="disable-input">
                  <p class="label">Ratio%</p>
                  <TextField
                    :disable="true"
                    :value="
                      calculateRatio(
                        details.child_support_maintenance,
                        details.child_support_maintenance_frequency
                      )
                    "
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Row-2 -->

          <div class="p-grid align-items-end mr-b-5px">
            <div class="p-col-6 r1">
              <div class="flex rmv-mrg align-items-end flex-gap">
                <!-- <img class="" src="@/assets/Revision.svg" alt="" /> -->
                <div>
                  <p class="label">Family Allowance</p>
                  <NumberField
                    type="number"
                    :value="details.family_allowance"
                    width="auto"
                    :format="true"
                    prefix="$"
                    :inValid="v$.details.family_allowance.$invalid && saveBtnClicked"
                    @onInput="(e) => getFormData(e, 'family_allowance')"
                  />
                </div>
                <div>
                  <p class="label">Frequency</p>
                  <Dropdown
                    :value="details.family_allowance_frequency"
                    :items="frequencyOpt"
                    width="150px"
                    @change="(e) => getFormData(e, 'family_allowance_frequency')"
                    :inValid="
                      v$.details.family_allowance_frequency.$invalid && saveBtnClicked
                    "
                  />
                </div>
                <div class="disable-input">
                  <p class="label">Ratio%</p>
                  <TextField
                    :disable="true"
                    :value="
                      calculateRatio(
                        details.family_allowance,
                        details.family_allowance_frequency
                      )
                    "
                    width="100%"
                  />
                </div>
              </div>
            </div>

            <div class="p-col-6 r1">
              <div class="flex rmv-mrg align-items-end flex-gap ml-2">
                <!-- <img class="" src="@/assets/Revision.svg" alt="" /> -->
                <div>
                  <p class="label">Family Tax Benefit</p>
                  <NumberField
                    :value="details.family_tax_benefits"
                    type="number"
                    width="auto"
                    :format="true"
                    prefix="$"
                    :inValid="v$.details.family_tax_benefits.$invalid && saveBtnClicked"
                    @onInput="(e) => getFormData(e, 'family_tax_benefits')"
                  />
                </div>
                <div>
                  <p class="label">Frequency</p>
                  <Dropdown
                    :value="details.family_tax_benefits_frequency"
                    :items="frequencyOpt"
                    width="150px"
                    @change="(e) => getFormData(e, 'family_tax_benefits_frequency')"
                    :inValid="
                      v$.details.family_tax_benefits_frequency.$invalid && saveBtnClicked
                    "
                  />
                </div>
                <div class="disable-input">
                  <p class="label">Ratio%</p>
                  <TextField
                    :disable="true"
                    :value="
                      calculateRatio(
                        details.family_tax_benefits,
                        details.family_tax_benefits_frequency
                      )
                    "
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Row-3 -->

          <div class="p-grid align-items-end mr-b-5px">
            <div class="p-col-6 r1">
              <div class="flex rmv-mrg align-items-end flex-gap">
                <!-- <img class="" src="@/assets/Revision.svg" alt="" /> -->
                <div>
                  <p class="label">Pension</p>
                  <NumberField
                    :value="details.govt_pension"
                    type="number"
                    width="auto"
                    :format="true"
                    prefix="$"
                    :inValid="v$.details.govt_pension.$invalid && saveBtnClicked"
                    @onInput="(e) => getFormData(e, 'govt_pension')"
                  />
                </div>
                <div>
                  <p class="label">Frequency</p>
                  <Dropdown
                    :items="frequencyOpt"
                    :value="details.govt_pension_frequency"
                    width="150px"
                    @change="(e) => getFormData(e, 'govt_pension_frequency')"
                    :inValid="
                      v$.details.govt_pension_frequency.$invalid && saveBtnClicked
                    "
                  />
                </div>
                <div class="disable-input">
                  <p class="label">Ratio%</p>
                  <TextField
                    :disable="true"
                    :value="
                      calculateRatio(details.govt_pension, details.govt_pension_frequency)
                    "
                    width="100%"
                  />
                </div>
              </div>
            </div>

            <div class="p-col-6 r1">
              <div class="flex rmv-mrg align-items-end flex-gap ml-2">
                <!-- <img class="" src="@/assets/Revision.svg" alt="" /> -->
                <div>
                  <p class="label">Dividends</p>
                  <NumberField
                    :value="details.dividends"
                    type="number"
                    width="auto"
                    :format="true"
                    prefix="$"
                    :inValid="v$.details.dividends.$invalid && saveBtnClicked"
                    @onInput="(e) => getFormData(e, 'dividends')"
                  />
                </div>
                <div>
                  <p class="label">Frequency</p>
                  <Dropdown
                    :value="details.dividends_frequency"
                    :items="frequencyOpt"
                    width="150px"
                    :inValid="v$.details.dividends_frequency.$invalid && saveBtnClicked"
                    @change="(e) => getFormData(e, 'dividends_frequency')"
                  />
                </div>
                <div class="disable-input">
                  <p class="label">Ratio%</p>
                  <TextField
                    :disable="true"
                    :value="
                      calculateRatio(details.dividends, details.dividends_frequency)
                    "
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Row-5 -->

          <div class="p-grid align-items-end mr-b-5px">
            <div class="p-col-6 r1">
              <div class="flex rmv-mrg align-items-end flex-gap">
                <!-- <img class="" src="@/assets/Revision.svg" alt="" /> -->
                <div>
                  <p class="label">Super</p>
                  <NumberField
                    :value="details.super"
                    type="number"
                    width="auto"
                    :format="true"
                    prefix="$"
                    :inValid="v$.details.super.$invalid && saveBtnClicked"
                    @onInput="(e) => getFormData(e, 'super')"
                  />
                </div>
                <div>
                  <p class="label">Frequency</p>
                  <Dropdown
                    :value="details.super_frequency"
                    :items="frequencyOpt"
                    width="150px"
                    :inValid="v$.details.super_frequency.$invalid && saveBtnClicked"
                    @change="(e) => getFormData(e, 'super_frequency')"
                  />
                </div>
                <div class="disable-input">
                  <p class="label">Ratio%</p>
                  <TextField
                    :disable="true"
                    :value="calculateRatio(details.super, details.super_frequency)"
                    width="100%"
                  />
                </div>
              </div>
            </div>

            <div class="p-col-6 r1">
              <div class="flex rmv-mrg align-items-end flex-gap ml-2">
                <!-- <img class="" src="@/assets/Revision.svg" alt="" /> -->
                <div>
                  <p class="label">Other</p>
                  <NumberField
                    type="number"
                    :value="details.other"
                    width="auto"
                    :format="true"
                    :inValid="v$.details.other.$invalid && saveBtnClicked"
                    prefix="$"
                    @onInput="(e) => getFormData(e, 'other')"
                  />
                </div>
                <div>
                  <p class="label">Frequency</p>
                  <Dropdown
                    :items="frequencyOpt"
                    :value="details.other_frequency"
                    width="150px"
                    :inValid="v$.details.other_frequency.$invalid && saveBtnClicked"
                    @change="(e) => getFormData(e, 'other_frequency')"
                  />
                </div>
                <div class="disable-input">
                  <p class="label">Ratio%</p>
                  <TextField
                    :disable="true"
                    :value="calculateRatio(details.other, details.other_frequency)"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Col-2 -->

        <div class="highlight-fields flex p-col-10">
          <div class="mr-b-5px disable-input p-col-4">
            <p class="label">Monthly Sub Total</p>
            <TotalField
              :value="monthlySubtotal"
              :disable="true"
              placeholder="Total"
              width="100%"
              mode="decimal"
              :format="true"
              prefix="$"
            />
          </div>
          <div class="p-col-4">
            <p class="label">Taxable</p>
            <Dropdown
              :items="taxableArray"
              width="100%"
              optionLabel="text"
              optionValue="value"
              :value="details.is_taxable"
              @change="(e) => getFormData(e, 'is_taxable')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["disable"],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      details: {
        centerlink_payments: { required },
        centerlink_payments_frequency: { required },
        child_support_maintenance: { required },
        child_support_maintenance_frequency: { required },
        family_allowance: { required },
        family_allowance_frequency: { required },
        family_tax_benefits: { required },
        family_tax_benefits_frequency: { required },
        govt_pension: { required },
        govt_pension_frequency: { required },
        dividends: { required },
        dividends_frequency: { required },
        super: { required },
        super_frequency: { required },
        other: { required },
        other_frequency: { required },
      },
    };
  },
  data() {
    return {
      details: {
        // centerlink_payments: 0,
        // centerlink_payments_frequency: "",
        // child_support_maintenance: 0,
        // child_support_maintenance_frequency: "",
        // family_allowance: 0,
        // family_allowance_frequency: "",
        // family_tax_benefits: 0,
        // family_tax_benefits_frequency: "",
        // govt_pension: 0,
        // govt_pension_frequency: "",
        // dividends: 0,
        // dividends_frequency: "",
        // super: 0,
        // super_frequency: "",
        // other: 0,
        // other_frequency: "",
        // applicant_type: !this.disable ? "primary" : "secondary",
      },
      frequencyOpt: ["Weekly", "Fortnightly", "Monthly", "Quarterly", "Yearly"],
      taxableArray: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      saveBtnClicked: "applications/saveBtnClicked",
      selectedApplication: "applications/selectedApplication",
    }),
    monthlySubtotal: {
      get: function () {
        let total =
          Number(
            this.getUpdatedValues("centerlink_payments", "centerlink_payments_frequency")
          ) +
          Number(
            this.getUpdatedValues(
              "child_support_maintenance",
              "child_support_maintenance_frequency"
            )
          ) +
          Number(
            this.getUpdatedValues("family_allowance", "family_allowance_frequency")
          ) +
          Number(
            this.getUpdatedValues("family_tax_benefits", "family_tax_benefits_frequency")
          ) +
          Number(this.getUpdatedValues("govt_pension", "govt_pension_frequency")) +
          Number(this.getUpdatedValues("dividends", "dividends_frequency")) +
          Number(this.getUpdatedValues("super", "super_frequency")) +
          Number(this.getUpdatedValues("other", "other_frequency"));
        this.setAdditionalIncomeTotal({
          data: total,
          type: !this.disable ? "primary" : "secondary",
        });
        return total ? (total / 12).toFixed(2) : 0;
      },
    },
  },
  methods: {
    ...mapActions({
      setAdditionalIncome: "applications/setAdditionalIncome",
      setValidation: "applications/setValidation",
      setAdditionalIncomeTotal: "subTotal/setAdditionalIncomeTotal",
    }),
    //Get updated values based on frequency
    getUpdatedValues(valueField, frequencyField) {
      let value = this.details[valueField];
      let frequency = this.details[frequencyField];
      switch (frequency) {
        case "Weekly":
          return value * 52;
        case "Fortnightly":
          return value * 26;
        case "Monthly":
          return value * 12;
        case "Quarterly":
          return value * 4;
        case "Yearly":
          return value * 1;
      }
      return 0;
    },
    getUpdatedValueFromData(valueField, frequencyField) {
      let value = valueField;
      let frequency = frequencyField;
      switch (frequency) {
        case "Weekly":
          return value * 52;
        case "Fortnightly":
          return value * 26;
        case "Monthly":
          return value * 12;
        case "Quarterly":
          return value * 4;
        case "Yearly":
          return value * 1;
      }
      return 0;
    },
    calculateRatio(value, frequency) {
      if (value && this.monthlySubtotal && frequency) {
        let calc = this.getUpdatedValueFromData(value, frequency) / this.monthlySubtotal;
        let percent = calc * 100;
        return percent.toFixed(2);
      }
      return 0;
    },
    setData() {
      if (this.selectedApplication.application_id) {
        if (this.selectedApplication.additional_income.length) {
          let addIncomes = "";
          addIncomes = this.disable
            ? this.selectedApplication.additional_income.find(
                (item) => item.applicant_type !== "primary"
              )
            : this.selectedApplication.additional_income.find(
                (item) => item.applicant_type === "primary"
              );
          if (addIncomes) {
            this.details = addIncomes;
            return;
          }
          this.setAdditionalIncome({
            data: this.details,
            type: !this.disable ? "primary" : "secondary",
          });
        }
      }
    },
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getFormData(value, field) {
      this.details[field] = value;
      this.details = { ...this.details };
      this.details.applicant_type = !this.disable ? "primary" : "secondary";
    },
  },
  created() {
    this.setData();
  },
  watch: {
    details: {
      handler() {
        this.setAdditionalIncome({
          data: this.details,
          type: !this.disable ? "primary" : "secondary",
        });
        this.setValidation({
          type: "additional_income",
          value: this.isSubmitDisabled(),
        });
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.highlight-fields {
  .label {
    font-family: Gordita-bold !important;
  }
}

::v-deep .p-col-10 {
  padding-bottom: 0;
}

.heading {
  background: rgba(2, 0, 56, 0.03);
  padding: 12px;

  p {
    margin: 0;
  }
}

.mr-b-5px {
  margin-bottom: 5px;
}

.mr-top {
  margin-top: 2.5rem;
}

.mr-top-10px {
  margin-top: 10px;
}

.flex-gap {
  gap: 0.625rem;
}

.flex-gap-1 {
  gap: 0.313rem;
}

.flex-gap-2 {
  gap: 1.875rem;
}

@media screen and (max-width: 1500px) {
  .c1 {
    width: 100%;
  }
}

@media screen and (max-width: 1380px) {
  .r1 {
    width: 100%;
  }

  .rmv-mrg {
    margin-left: 0 !important;
  }
}
</style>
