<template>
  <Dialog
    :visible="show"
    :style="{ width: '580px' }"
    :dismissableMask="true"
    :modal="show"
  >
    <template #header>
      <h1 class="modal-title">Special condition</h1>
      <i class="icon pi pi-times" @click="close" style="margin-left: auto" />
    </template>
    <div class="modal-content">
      <div class="flex mt-3">
        <div class="col disable-input">
          <div>Created by</div>
          <TextField
            width="100%"
            :value="formData.created_by"
            :disable="true"
            class="mt-2"
          />
        </div>
        <div class="col ml-2">
          <div>Status</div>
          <Dropdown
            width="100%"
            placeholder="-select-"
            :items="statusOpts"
            :value="formData.status"
            class="mt-2"
            @change="getFormData($event, 'status')"
            optionLabel="label"
            optionValue="value"
          />
        </div>
      </div>

      <div class="mt-3">Description</div>
      <TextArea
        @onInput="getFormData($event, 'description')"
        placeholder="Task description"
        :value="formData.description"
        width="100%"
        class="mt-2"
        height="100px"
        :key="refreshKey"
      />
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button
          :disabled="isSubmitDisabled()"
          :label="selectedSplCondition ? 'Save' : 'Create'"
          type="primary"
          :key="refreshKey"
          @click="save"
          :loading="isloading"
        />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">
          close
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { _post, _put } from "../../../../../services/crudService";
import { APP_URL } from "../../../../../../constants";
import { mapGetters } from "vuex";

export default {
  props: ["show", "selectedSplCondition", "appStatus"],
  components: { Dialog },
  data() {
    return {
      isloading: false,
      refreshKey: 10,
      statusOpts: [
        { label: "Pending", value: "pending" },
        { label: "Complete", value: "complete" },
      ],
      formData: {
        description: "",
        status: "",
        created_by: "",
        application_id: this.$route.params.id,
      },
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        description: { required },
        status: { required },
      },
    };
  },
  watch: {
    selectedSplCondition(value) {
      this.formData = value;
      this.refreshKey++;
    },
  },

  computed: {
    ...mapGetters({ condition: "auth/username" }),
  },
  methods: {
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    close() {
      this.$emit("close");
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    async save() {
      this.isloading = true;
      if (this.$props.selectedSplCondition) {
        try {
          const response = await _put(
            APP_URL +
              `loan_special_conditions?id=${this.$props.selectedSplCondition.Id}`,
            { ...this.$props.appStatus, ...this.formData }
          );

          if (response) {
            this.$emit("close");
            this.$emit("save");
            this.isloading = false;
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Special condition changed successfully",
              life: 3000,
            });
          }
        } catch (error) {
          this.isloading = false;

          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      } else {
        try {
          const response = await _post(
            APP_URL + "loan_special_conditions",
            this.formData
          );

          if (response) {
            this.$emit("close");
            this.$emit("save");
            this.isloading = false;
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Special condition added successfully",
              life: 3000,
            });
          }
        } catch (error) {
          this.isloading = false;

          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
    },
  },
  created() {
    if (this.$props.selectedSplCondition) {
      this.formData = this.$props.selectedSplCondition;
      this.statusOpts = [
        { label: "Pending", value: "pending" },
        { label: "Complete", value: "complete" },
        { label: "Cancel", value: "cancelled" },
      ];
    } else {
      this.statusOpts = [
        { label: "Pending", value: "pending" },
        { label: "Complete", value: "complete" },
      ];
      this.formData.created_by = this.condition;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.attachment {
  color: rgba(2, 0, 56, 0.4);
  font-weight: 500;
  font-size: 14px;
  font-family: Gordita-medium;
}
</style>
