<template>
  <div>
    <Spinner v-if="isLoading" class="flex" />
    <div v-else>
      <div v-if="list.length">
        <DataTable
          class="mt-2"
          :rows="5"
          dataKey="id"
          :value="list"
          :rowsPerPageOptions="[5, 10, 20, 30]"
          :totalRecords="totalCount"
        >
          <template #empty> No data found. </template>

          <Column
            field="report_name"
            header="Report name"
            class="cursor-pointer"
          >
            <template #body="slotProps">
              <div class="report-name" @click="getReport(slotProps.data)">
                {{ slotProps.data.report_name }}
              </div>
            </template>
          </Column>
          <Column field="period_from" header="Reporting period">
            <template #body="slotProps">
              {{ getDate(slotProps.data.period_from) }} -
              {{ getDate(slotProps.data.period_to) }}
            </template>
          </Column>
          <Column field="created_by" header="Generated by" class="capital">
          </Column>
          <Column field="created_at" header="Generated">
            <template #body="slotProps">
              {{ formatDate(slotProps.data.created_at) }}
            </template>
          </Column>
        </DataTable>
      </div>
      <div
        v-else
        class="flex justify-content-center align-items-center report-content"
      >
        <div>
          <div class="flex justify-content-center align-items-center">
            <img src="@/assets/Reports.png" alt="" />
          </div>
          <h4 class="text-field align-text-center">
            You have not generated any reports.<br />
            <div class="pt-1">
              Start by selecting report name and date and then press generate.
            </div>
          </h4>
        </div>
      </div>
    </div>
    <Paginator
      v-if="list.length"
      class="mt-2"
      :rows="5"
      :totalRecords="totalCount"
      :rowsPerPageOptions="[5, 10, 20, 30]"
      template="RowsPerPageDropdown  CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
      currentPageReportTemplate="Showing {first} of {totalRecords} reports"
      @page="onChangePage($event)"
    ></Paginator>
  </div>
</template>

<script>
import Paginator from "primevue/paginator";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { _get } from "../../../services/crudService";
import { APP_URL } from "../../../../constants";
import moment from "moment";

export default {
  name: "ReportsTable",
  components: {
    Paginator,
    DataTable,
    Column,
  },
  data() {
    return {
      list: [],
      isLoading: true,
      totalCount: 0,
    };
  },
  methods: {
    async onChangePage(value) {
      this.isLoading = true;
      const result = await _get(
        APP_URL +
          `generated-reports?pageSize=${value.rows}&pageIndex=${value.page + 1}`
      );
      if (result) {
        this.list = result?.data?.data ? result.data.data : [];
        this.totalCount = result?.data?.count ? result?.data?.count : 0;
      }
      this.isLoading = false;
    },
    formatDate(date) {
      date = new moment(date).add(600, "m").toDate();
      return moment(date).format("hh:mm a DD/MM/YYYY ");
    },
    getDate(date) {
      if (date) {
        date = moment(date).format("DD/MM/YYYY");
        // return date.split(" ")[0];
        return date;
      } else {
        return "-";
      }
    },
    async getReport(value) {
      try {
        const result = await _get(APP_URL + "csv-data-by-id?id=" + value.Id);
        if (result.data && result.data[0] && result.data[0].data) {
          let arrayOfValues = JSON.parse(result.data[0].data);
          if (arrayOfValues.length) {
            const headers = arrayOfValues.length
              ? Object.keys(arrayOfValues[0])
              : [];
            this.exportCSVFile(headers, arrayOfValues, value.report_name);
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Report downloaded successfully",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "There is no data to download",
              life: 3000,
            });
          }
        }
      } catch (err) {
        console.log(err.response)
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = this.convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle + ".csv" || "export.csv";

      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    convertToCSV(objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    },
  },
  async created() {
    this.isLoading = true;
    const result = await _get(
      APP_URL + "generated-reports?pageSize=5&pageIndex=1"
    );
    if (result) {
      this.list = result?.data?.data ? result.data.data : [];
      this.totalCount = result?.data?.count ? result?.data?.count : 0;
    }
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
.color-highlight {
  color: $primary-color !important;
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}
.report-content {
  // border-bottom: 1px solid rgba(2, 0, 56, 0.15);
  height: 60vh;
}
.text-field {
  font-size: 1rem;
  font-weight: 600;
  color: rgba(2, 0, 56, 0.3);
}

.title {
  font-size: 36px;
  font-family: Gordita-bold;
  color: $secondary-color;
}
.title-content {
  font-family: Gordita-medium-;
  font-size: 20px;
  color: $secondary-color;
}
.align-text-center {
  text-align: center;
}
.report-name {
  &:hover {
    color: $primary-color;
  }
}
</style>