<template>
  <div>
    <div>Task type</div>
    <Dropdown
      optionLabel="taskTypes"
      :items="taskTypes"
      width="100%"
      placeholder="-Select-"
      class="mt-2"
      @change="getTaskType"
      :disable="loadingTaskTypes && true"
    />

    <div class="mt-2">Task title</div>
    <TextField
      @onInput="getTaskData($event, 'taskTitle')"
      :value="task.taskTitle"
      width="100%"
      placeholder="Task title"
      class="mt-2"
      :key="refreshKey"
    />
    <div class="flex mt-2">
      <div class="col">
        <div>Assign to</div>
        <Dropdown
          :items="assignTo"
          width="100%"
          placeholder="-Select-"
          class="mt-2"
          @change="getAssignValue"
          :value="task.assignTo"
        />
      </div>
      <div class="col ml-2">
        <div>{{ task.assignTo }}</div>
        <div class="mt-2">
          <AutoComplete
            forceSelection
            width="100%"
            v-model="selectedUser"
            :suggestions="filteredUserList"
            :disabled="loadingUserList"
            @complete="getUserList($event)"
            placeholder="Enter name"
            field="name"
            :modelValue="selectedUser"
          />

          <!-- <Dropdown
            :items="usersList"
            :disable="loadingUserList && true"
            optionLabel="name"
            width="100%"
            placeholder="-Select-"
            class="mt-2"
            @change="getAssignedToId"
            :value="task.assignToType"
          /> -->
        </div>
      </div>
    </div>
    <div class="flex mt-2">
      <div class="col">
        <div>Regarding</div>
        <Dropdown
          width="100%"
          @change="getRegValue"
          placeholder="-Select-"
          :items="regarding"
          class="mt-2"
          :value="task.regardingTo"
        />
      </div>
      <div class="col ml-2">
        <div>{{ task.regardingTo }}</div>
        <AutoComplete
          forceSelection
          class="mt-2"
          width="100%"
          v-model="selectedReg"
          :suggestions="filteredRegList"
          @complete="getRegList($event)"
          :placeholder="loanPlaceHolder()"
          field="name"
          :modelValue="selectedReg"
          :disabled="loadingRegList"
        />

        <!-- <Dropdown
            :value="task.regardingToType"
            :items="regardingList"
            :disable="loadingRegList"
            optionLabel="name"
            width="100%"
            placeholder="-Select-"
            class="mt-2"
            @change="getRegardingToId"
          /> -->
      </div>
    </div>

    <div class="flex align-items-center mt-2">
      <div class="col">
        <div>Due date</div>
        <DatePicker
          placeholder="DD/MM/YYYY"
          style="width: 100%"
          class="mt-2"
          @change="getTaskData($event, 'dueDate')"
          :minDate="minDate"
          :minYear="minDate.getFullYear()"
        />
      </div>
      <div class="col ml-2 time-input">
        <div>Due time</div>
        <Calendar
          v-model="time"
          :showTime="true"
          :timeOnly="true"
          hourFormat="12"
          class="mt-2 full-width"
          placeholder="Enter time"
          @change="getTime"
        />
      </div>
    </div>
    <div class="mt-2">Description</div>
    <TextArea
      @onInput="getTaskData($event, 'description')"
      placeholder="Task description"
      width="100%"
      class="mt-2"
    />
  </div>
</template>

<script>
import Calendar from "primevue/calendar"
import { required } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"
import moment from "moment"
import AutoComplete from "primevue/autocomplete"
import { SERVER_URL } from "../../../../../constants"
import { _get } from "../../../../services/crudService"

export default {
  name: "AddTask",
  props: ["taskSelected"],
  components: { Calendar, AutoComplete },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    if (this.taskSelected) {
      return {
        task: {
          taskTitle: { required },
          assignTo: { required },
          assignToType: { required },
          assignToId: { required },
          regardingTo: { required },
          regardingType: { required },
          regardingToId: { required },
          taskType: { required },
          description: { required },
          dueDate: { required },
          dueTime: { required },
        },
      }
    }
  },
  data() {
    return {
      minDate: null,
      assignTo: ["User", "Group"],
      regarding: ["Broker"],
      taskTypes: [],
      usersList: [],
      filteredUserList: [],
      filteredRegList: [],
      regardingList: [],
      appId: "",
      time: null,
      isloading: false,
      loadingUserList: false,
      loadingRegList: false,
      loadingTaskTypes: false,
      refreshKey: 10,
      selectedUser: "",
      selectedReg: "",
      task: {
        taskTitle: "",
        assignTo: "User",
        assignToType: "",
        assignToId: "",
        regardingTo: "Broker",
        regardingType: "",
        regardingToId: "",
        taskType: "",
        task_type_id: "",
        dueDate: "",
        dueTime: "",
        description: "",
        status: "open",
        action: "Task created",
        application_id: "",
        attachment: "",
      },
    }
  },
  watch: {
    time() {
      this.task.dueTime = this.time
    },
    selectedUser(value) {
      if (value && value.Id) {
        this.getAssignedToId(value)
      }
    },
    selectedReg(value) {
      if (value && value.Id) {
        this.getRegardingToId(value)
      }
    },
  },

  computed: {},

  methods: {
    close() {
      this.$emit("close")
      this.clearData()
    },
    isSubmitDisabled() {
      return this.v$.$invalid
    },

    loanPlaceHolder() {
      if (this.task.regardingTo === "Loan") {
        return "Enter Loan ID"
      }

      return "Enter name"
    },

    clearData() {
      this.selectedUser = ""
      this.selectedReg = ""
      this.time = moment().format("hh:mm a")
      this.task = {
        taskTitle: "",
        assignTo: "User",
        assignToType: "",
        assignToId: "",
        regardingTo: "Loan",
        regardingType: "",
        regardingToId: "",
        taskType: "",
        task_type_id: "",
        dueDate: "",
        dueTime: "",
        description: "",
        status: "open",
        action: "Task created",
        application_id: "",
        attachment: "",
      }
    },

    async getUserList(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredUserList = [...this.usersList]
        } else {
          this.filteredUserList = this.usersList.filter((list) => {
            return list.name.toLowerCase().includes(event.query.toLowerCase())
          })

          if (!this.filteredUserList.length) {
            return (this.v$.$invalid = true)
          }

          this.filteredUserList.length &&
            this.filteredUserList.map((list) => {
              if (list.name !== event.query) {
                return (this.v$.$invalid = true)
              }
            })
        }
      }, 250)
    },

    async getRegList(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredRegList = [...this.regardingList]
        } else {
          this.filteredRegList = this.regardingList.filter((list) => {
            return list.name
              .toString()
              .toLowerCase()
              .includes(event.query.toLowerCase())
          })

          if (!this.filteredRegList.length) {
            return (this.v$.$invalid = true)
          }

          this.filteredUserList.length &&
            this.filteredUserList.map((list) => {
              if (list.name !== event.query) {
                return (this.v$.$invalid = true)
              }
            })
        }
      }, 250)
    },

    getTaskData(value, fieldName) {
      this.task[fieldName] = value
      this.task = { ...this.task }
      this.$emit("taskData", this.task)
      this.$emit("validateTask", this.v$.$invalid)
    },
    updateRegardingOpt(assignTo) {
      if (assignTo === "Customer") {
        this.regarding = ["Loan"]
        this.task.regardingTo = "Loan"
      } else if (assignTo === "Broker") {
        this.regarding = ["Loan"]
        this.task.regardingTo = "Loan"
      } else {
        this.regarding = ["Customer", "Broker", "Loan"]
        this.task.regardingTo = "Customer"
      }
    },
    async getAssignedToId(value) {
      this.task.assignToType = value.firstName + " " + value.lastName
      this.task.assignToId = value.Id

      if (value.active_application_id) {
        this.appId = value.active_application_id
      }

      // if (
      //   this.task.regardingTo === "Loan" &&
      //   this.appId &&
      //   this.task.assignTo === "Customer"
      // ) {
      //   const appList = await _get(
      //     APP_URL + `application_by_customer_crm?application_id=${this.appId}`
      //   )
      //   this.regardingList = appList.data.result
      //   this.regardingList.map((obj) => {
      //     obj.name = obj.Id
      //   })
      // } else if (
      //   this.task.regardingTo === "Loan" &&
      //   this.task.assignToId &&
      //   this.task.assignTo === "Broker"
      // ) {
      //   const appList = await _get(
      //     APP_URL + `application_by_broker_crm?id=${this.task.assignToId}`
      //   )
      //   this.regardingList = appList.data.result
      //   this.regardingList.map((obj) => {
      //     obj.name = obj.Id
      //   })
      // } else if (value === "Loan" && this.task.assignToId) {
      //   const appList = await _get(APP_URL + "active_applications_list")
      //   this.regardingList = appList.data.result
      //   this.regardingList.map((obj) => {
      //     obj.name = obj.Id
      //   })
      // }
    },
    getRegardingToId(value) {
      if (this.task.regardingTo === "Loan") {
        this.task.regardingType = value.Id
        this.task.regardingToId = value.Id
      } else {
        this.task.regardingType = value.firstName + " " + value.lastName
        this.task.regardingToId = value.Id
      }
      // this.task.application_id = value.Id
    },
    getTaskType(value) {
      this.task.taskType = value.taskTypes
      this.task.taskTitle = value.taskTypes
      this.refreshKey++
      this.task.task_type_id = value.Id
      this.task.attachment = value.attachmentRequired
    },
    async getAssignValue(value) {
      this.task.assignTo = value
      this.selectedUser = ""
      this.loadingUserList = true
      this.loadingTaskTypes = true
      this.task.taskTitle = ""
      this.refreshKey++

      // this.loadingRegList = true
      // this.selectedReg = ""

      this.updateRegardingOpt(value)

      if (value === "User") {
        const result = await _get(SERVER_URL + "get_task_types?type=user")
        this.taskTypes = result.data.result
        this.loadingTaskTypes = false
      } else {
        const result = await _get(SERVER_URL + "get_task_types?type=group")
        this.taskTypes = result.data.result
        this.loadingTaskTypes = false
      }

      // if (
      //   value === "User" ||
      //   (value === "Group" && this.regardingTo === "Loan")
      // ) {
      //   const appList = await _get(APP_URL + "active_applications_list")
      //   this.regardingList = appList.data.result
      //   this.regardingList.map((obj) => {
      //     obj.name = obj.Id
      //   })
      //   this.loadingRegList = false
      // }

      if (value === "User") {
        const response = await _get(SERVER_URL + "crm_user")
        this.usersList = response.data.result
        this.usersList.map((obj) => {
          obj.name = `${obj.firstName} ${obj.lastName}`
        })
        this.loadingUserList = false
      } else if (value === "Customer") {
        const cusotmerList = await _get(SERVER_URL + "get_customers")
        this.usersList = cusotmerList.data.result
        this.usersList.map((obj) => {
          obj.name = `${obj.firstName} ${obj.lastName}`
        })
        this.loadingUserList = false
        // this.loadingRegList = false
      } else if (value === "Broker") {
        const brokersList = await _get(SERVER_URL + "get_broker_crm")
        this.usersList = brokersList.data.result
        this.usersList.map((obj) => {
          obj.name = `${obj.firstName} ${obj.lastName}`
        })
        this.loadingUserList = false
        // this.loadingRegList = false
      } else if (value === "Group") {
        const groupList = await _get(SERVER_URL + "get-group")
        this.usersList = groupList.data.result
        this.usersList.map((obj) => {
          obj.name = obj.title
        })
        this.loadingUserList = false
        // this.loadingRegList = false
      }
    },
    async getRegValue(value) {
      // this.task.regardingTo = value
      // this.selectedReg = ""
      this.loadingRegList = true
      this.loanPlaceHolder()

      // if (value === "Loan" && this.appId && this.task.assignTo === "Customer") {
      //   const appList = await _get(
      //     APP_URL + `application_by_customer_crm?application_id=${this.appId}`
      //   )
      //   this.regardingList = appList.data.result
      //   this.regardingList.map((obj) => {
      //     obj.name = obj.Id
      //   })
      //   this.loadingRegList = false
      // } else if (
      //   value === "Loan" &&
      //   this.task.assignToId &&
      //   this.task.assignTo === "Broker"
      // ) {
      //   const appList = await _get(
      //     APP_URL + `application_by_broker_crm?id=${this.task.assignToId}`
      //   )
      //   this.regardingList = appList.data.result
      //   this.regardingList.map((obj) => {
      //     obj.name = obj.Id
      //   })
      //   this.loadingRegList = false
      // } else if (value === "Loan" && this.task.assignToId) {
      //   const appList = await _get(APP_URL + "active_applications_list")
      //   this.regardingList = appList.data.result
      //   this.regardingList.map((obj) => {
      //     obj.name = obj.Id
      //   })
      //   this.loadingRegList = false
      // }

      if (value === "Customer") {
        const cusotmerList = await _get(SERVER_URL + "get_customers")
        this.regardingList = cusotmerList.data.result
        this.regardingList.map((obj) => {
          obj.name = `${obj.firstName} ${obj.lastName}`
        })
        this.loadingRegList = false
      } else if (value === "Broker") {
        const brokersList = await _get(SERVER_URL + "get_broker_crm")
        this.regardingList = brokersList.data.result
        this.regardingList.map((obj) => {
          obj.name = `${obj.firstName} ${obj.lastName}`
        })
        this.loadingRegList = false
      }
    },
  },
  async created() {
    this.minDate = new Date()
    this.time = moment().format("hh:mm a")
    this.loanPlaceHolder()
    if (this.task.assignTo === "User") {
      const result = await _get(SERVER_URL + "get_task_types?type=user")
      this.taskTypes = result.data.result
    } else {
      const result = await _get(SERVER_URL + "get_task_types?type=group")
      this.taskTypes = result.data.result
    }
    const response = await _get(SERVER_URL + "crm_user")
    this.usersList = response.data.result
    this.usersList.map((obj) => {
      obj.name = `${obj.firstName} ${obj.lastName}`
    })

    this.loadingRegList = true
    const brokersList = await _get(SERVER_URL + "get_broker_crm")
    this.regardingList = brokersList.data.result
    this.regardingList.map((obj) => {
      obj.name = `${obj.firstName} ${obj.lastName}`
    })
    this.loadingRegList = false
    // await this.getRegValue("Loan")
  },
  unmounted() {
    this.clearData()
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

:deep .p-autocomplete-input,
.p-autocomplete {
  width: 100% !important;
}
</style>
