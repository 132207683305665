<template>
  <div :key="refreshKey">
    <div class="heading flex align-items-center justify-content-between">
      <p class="sub-title">Savings</p>
      <Button @click="addSavings" type="primary" label="Add Savings" />
    </div>
    <!-- <div v-if="details.length > 0">
      <div v-for="(item, i) in details" :key="i">
        <div class="form-inputs mr-top-10px">
          <div class="p-grid align-items-end">
            <div class="p-col-10">
              <div class="p-grid align-items-end mr-b-5px">
                <div class="p-col-3 p-d-flex flex-gap">
                  <Button
                    class="full-width"
                    type="outline"
                    label="Remove Assets"
                    @click="removeSavings(i)"
                  />
                </div>
                <div class="p-col-3">
                  <p class="label">Savings ($)</p>
                  <NumberField
                    mode="decimal"
                    :format="true"
                    prefix="$"
                    @onInput="(e) => getFormData(e, 'amount_used', i)"
                    type="number"
                    :disable="item.amount_used_value != 'manual_value'"
                    :value="item.amount_used"
                    width="100%"
                    :inValid="
                      v$.details.$silentErrors[0]?.$response.$data[i]
                        .amount_used.$invalid && saveBtnClicked
                    "
                  />
                </div>

                <div class="p-col-2">
                  <p class="label">Institution</p>
                  <TextField
                    @onInput="(e) => getFormData(e, 'institution', i)"
                    :value="item.institution"
                    width="100%"
                    :inValid="
                      v$.details.$silentErrors[0]?.$response.$data[i]
                        .institution.$invalid && saveBtnClicked
                    "
                  />
                </div>

                <div class="p-col-2">
                  <p class="label">Use as savings?</p>
                  <ToggleSwitch
                    :isChecked="item.useSavings"
                    @onChange="(e) => getFormData(e, 'useSavings', i)"
                  />
                </div>
                <div class="p-col-2">
                  <p class="label">Use as savings post settlement?</p>
                  <ToggleSwitch
                    :isChecked="item.useSavingsPost"
                    @onChange="(e) => getFormData(e, 'useSavingsPost', i)"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="i === details.length - 1"
              class="p-col-2 highlight-fields"
            >
              <div class="mr-b-5px disable-input">
                <p class="label">Subtotal</p>
                <NumberField
                  mode="decimal"
                  :key="subTotalKey"
                  :format="true"
                  prefix="$"
                  :value="subTotal"
                  :disable="true"
                  placeholder="label"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div v-if="details.length > 0">
      <div v-for="(item, i) in details" :key="i">
        <div class="form-inputs mr-top-10px">
          <div class="p-grid align-items-end">
            <!-- Col-1 -->
            <div class="p-col-12">
              <!--Row-1 -->
              <div class="p-grid align-items-end mr-b-5px">
                <div class="p-col-2 p-d-flex flex-gap">
                  <Button
                    class="full-width"
                    type="outline"
                    label="Remove"
                    @click="removeSavings(i)"
                    color="#FFBCBD"
                    border="1px solid #FFBCBD"
                  />
                  <!--  <img src="@/assets/Revision.svg" alt="" /> -->
                </div>
                <div class="p-col-2">
                  <p class="label">Institution</p>
                  <TextField
                    @onInput="(e) => getFormData(e, 'institution', i)"
                    :value="item.institution"
                    width="100%"
                    :inValid="
                      v$.details.$silentErrors[0]?.$response.$data[i].institution
                        .$invalid && saveBtnClicked
                    "
                  />
                </div>
                <div class="p-col-2">
                  <p class="label">Amount ($)</p>
                  <div class="flex align-items-center">
                    <NumberField
                      mode="decimal"
                      :format="true"
                      prefix="$"
                      @onInput="(e) => getFormData(e, 'amount_used', i)"
                      type="number"
                      :disable="item.amount_used_value != 'manual_value'"
                      :value="item.amount_used"
                      width="100%"
                      :inValid="
                        v$.details.$silentErrors[0]?.$response.$data[i].amount_used
                          .$invalid && saveBtnClicked
                      "
                    />
                    <div class="ml-3">:</div>
                  </div>
                </div>

                <div class="p-col-2">
                  <p class="label">Towards purchase ($)</p>
                  <div class="flex align-items-center">
                    <NumberField
                      mode="decimal"
                      :format="true"
                      prefix="$"
                      @onInput="(e) => getFormData(e, 'towards_purchase', i)"
                      type="number"
                      :value="item.towards_purchase ? item.towards_purchase : 0"
                      width="100%"
                      :key="purchaseKey"
                    />
                    <div class="ml-3">+</div>
                  </div>
                </div>
                <div class="p-col-2">
                  <p class="label">Post settlement ($)</p>
                  <div class="flex align-items-center">
                    <NumberField
                      mode="decimal"
                      :format="true"
                      prefix="$"
                      @onInput="(e) => getFormData(e, 'post_settlement', i)"
                      type="number"
                      :value="item.post_settlement ? item.post_settlement : 0"
                      width="100%"
                      :key="postKey"
                    />
                    <div class="ml-3">=</div>
                  </div>
                </div>
                <div class="p-col-2">
                  <div class="disable-input">
                    <p class="label">Subtotal ($)</p>
                    <NumberField
                      mode="decimal"
                      :format="true"
                      prefix="$"
                      :value="item.subTotal"
                      :disable="true"
                      placeholder="label"
                      width="100%"
                      :key="totalKey"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="details.length > 0" class="bottom-border"></div>
    <div v-if="details.length > 0" class="form-inputs mr-top-10px">
      <div class="p-grid align-items-end">
        <div class="p-col-6"></div>
        <div class="p-col-2">
          <div class="disable-input">
            <p class="label">Subtotal ($)</p>
            <div class="flex align-items-center">
              <NumberField
                mode="decimal"
                :format="true"
                prefix="$"
                :disable="true"
                placeholder="label"
                width="100%"
                :value="total1"
                :key="subTotalKey"
              />
              <div class="ml-3">+</div>
            </div>
          </div>
        </div>
        <div class="p-col-2">
          <div class="disable-input">
            <p class="label">Subtotal ($)</p>
            <div class="flex align-items-center">
              <NumberField
                mode="decimal"
                :format="true"
                prefix="$"
                :disable="true"
                placeholder="label"
                width="100%"
                :value="total2"
                :key="subTotalKey2"
              />
              <div class="ml-3">=</div>
            </div>
          </div>
        </div>
        <div class="p-col-2">
          <div class="disable-input">
            <p class="label">Total ($)</p>
            <NumberField
              mode="decimal"
              :format="true"
              prefix="$"
              :disable="true"
              placeholder="label"
              width="100%"
              :value="total3"
              :key="subTotalKey3"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["disable"],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      details: {
        $each: helpers.forEach({
          amount_used: { required },
          institution: { required },
        }),
      },
    };
  },
  data() {
    return {
      details: [],
      refreshKey: 10,
      subTotalKey: 20,
      disableFields: this.$props.disable,
      postKey: 30,
      purchaseKey: 40,
      total1: 0,
      total2: 0,
      total3: 0,
      subTotalKey3: 50,
      subTotalKey2: 60,
      totalKey: 70,
    };
  },
  methods: {
    ...mapActions({
      setAssets: "applications/setAssets",
      setValidation: "applications/setValidation",
      setSavingsTotal: "subTotal/setSavingsTotal",
      setSavingsPostTotal: "subTotal/setSavingsPostTotal",
      setSavingsTotalDetails: "subTotal/setSavingsTotalDetails",
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    addSavings() {
      let newObj = {
        amount: 0,
        amount_used: 0,
        institution: "",
        amount_verified: 0,
        amount_manual: 0,
        amount_used_value: "manual_value",
        useSavings: true,
        useSavingsPost: false,
        towards_purchase: 0,
        post_settlement: 0,
        subTotal: 0,
      };
      this.details = [...this.details, newObj];
      return (this.disableFields = false);
    },
    removeSavings(i) {
      this.details.splice(i, 1);
      if (this.details.length === 0) {
        this.setSavingsTotal({
          data: 0,
          type: !this.disable ? "primary" : "secondary",
        });
      }
      this.findOverallTotal();
      this.refreshKey += 1;
    },
    getFormData(value, field, index) {
      if (field == "towards_purchase") {
        if (
          value + this.details[index].post_settlement >
          this.details[index].amount_used
        ) {
          value = this.details[index].amount_used - this.details[index].post_settlement;
          this.purchaseKey += 1;
        }
      }
      if (field == "post_settlement") {
        if (
          value + this.details[index].towards_purchase >
          this.details[index].amount_used
        ) {
          value = this.details[index].amount_used - this.details[index].towards_purchase;
          this.postKey += 1;
        }
      }
      this.details[index][field] = value;
      this.details = [...this.details];
      if (field == "amount_used") {
        this.details[index].amount_manual = value;
        if (
          value <
          this.details[index].towards_purchase + this.details[index].post_settlement
        ) {
          this.details[index].towards_purchase = 0;
          this.details[index].post_settlement = 0;
          this.postKey += 1;
          this.purchaseKey += 1;
        }
      }
      this.details[index].post_settlement = this.details[index].post_settlement
        ? this.details[index].post_settlement
        : 0;
      this.details[index].towards_purchase = this.details[index].towards_purchase
        ? this.details[index].towards_purchase
        : 0;
      this.totalKey += 1;
      this.details[index].subTotal =
        this.details[index].post_settlement + this.details[index].towards_purchase;
      this.totalKey += 1;
      this.findOverallTotal();
      this.setAssets({
        data: this.details,
        type: !this.disable ? "primary" : "secondary",
        asset_type: "savings",
      });
    },
    findOverallTotal() {
      this.total1 = this.details.reduce((prev, current) => {
        if (current.towards_purchase) {
          return Number(prev) + Number(current.towards_purchase);
        } else {
          return prev;
        }
      }, 0);

      this.total2 = this.details.reduce((prev, current) => {
        if (current.post_settlement) {
          return Number(prev) + Number(current.post_settlement);
        } else {
          return prev;
        }
      }, 0);
      this.total3 = this.total1 + this.total2;
      this.subTotalKey += 1;
      this.subTotalKey2 += 1;
      this.subTotalKey3 += 1;
      this.setSavingsTotalDetails({
        towards_purchase: this.total1,
        post_settlement: this.total2,
        type: !this.disable ? "primary" : "secondary",
      });
    },
    setData() {
      if (this.selectedApplication.application_id) {
        if (
          this.selectedApplication.assets &&
          this.selectedApplication.assets.length > 0
        ) {
          this.details = !this.disable
            ? this.selectedApplication.assets.filter(
                (item) => item.applicant_type === "primary" && item.type === "savings"
              )
            : this.selectedApplication.assets.filter(
                (item) => item.applicant_type === "secondary" && item.type === "savings"
              );
        }
      }
      this.setAssets({
        data: this.details,
        type: !this.disable ? "primary" : "secondary",
        asset_type: "savings",
      });
    },
  },
  computed: {
    ...mapGetters({
      saveBtnClicked: "applications/saveBtnClicked",
      selectedApplication: "applications/selectedApplication",
    }),
    ...mapState({
      assetSavings: (state) => state.applications.assetSavings,
      savingsUpdated: (state) => state.applications.savingsUpdated,
    }),
    subTotal: {
      get: function () {
        let total = this.details.reduce((previousValue, currentValue) => {
          if (currentValue.amount_used) {
            return Number(previousValue) + Number(currentValue?.amount_used);
          } else {
            return previousValue;
          }
        }, 0);
        this.setSavingsTotal({
          data: total,
          type: !this.disable ? "primary" : "secondary",
        });
        return total;
      },
    },
  },
  created() {
    this.setData();
    this.findOverallTotal();
  },
  watch: {
    details: {
      handler() {
        this.setValidation({
          type: "asset_savings",
          value: this.isSubmitDisabled(),
        });
        this.setAssets({
          data: this.details,
          type: !this.disable ? "primary" : "secondary",
          asset_type: "savings",
        });
        let total = this.details.reduce((previousValue, currentValue) => {
          if (currentValue.useSavingsPost) {
            return Number(previousValue) + Number(currentValue?.amount_used);
          } else {
            return previousValue;
          }
        }, 0);
        this.setSavingsPostTotal({
          data: total,
          type: !this.disable ? "primary" : "secondary",
        });
      },
      deep: true,
    },
    savingsUpdated: {
      handler() {
        this.details = !this.disable
          ? [...this.assetSavings.applicant_1]
          : [...this.assetSavings.applicant_2];
        this.refreshKey += 1;
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

::v-deep .p-col-10 {
  padding-bottom: 0;
  padding-top: 0;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.full-width {
  width: 100%;
}

.highlight-fields {
  .label {
    font-family: Gordita-bold !important;
  }
}

.heading {
  background: rgba(2, 0, 56, 0.03);
  padding: 12px;

  p {
    margin: 0;
  }
}

.mr-b-5px {
  margin-bottom: 5px;
}

.mr-top {
  margin-top: 2.5rem;
}

.mr-top-10px {
  margin-top: 10px;
}

.flex-gap {
  gap: 0.625rem;
}

.flex-gap-1 {
  gap: 0.313rem;
}

.flex-gap-2 {
  gap: 1.875rem;
}
.bottom-border {
  border: 1px solid #858aa1;
}
</style>
