import { APP_URL, SERVER_URL_2 } from "../../constants";
import { _get, _post, _put } from "../services/crudService.js";

export default {
  namespaced: true,
  state: {
    selectedVersionNumber: "",
    appBrokerId: null,
    loanCreatedDate: "",
    applicationVersions: [],
    selectedApplication: {},
    isTwoApplicants: false,
    saveBtnClicked: false,
    buyingProperty: [],
    sellingProperty: [],
    fullTimeIncome: {
      applicant_1: [],
      applicant_2: [],
    },
    partTimeIncome: {
      applicant_1: [],
      applicant_2: [],
    },
    casualIncome: {
      applicant_1: [],
      applicant_2: [],
    },
    selfIncome: {
      applicant_1: [],
      applicant_2: [],
    },
    additionalIncome: {
      applicant_1: [],
      applicant_2: [],
    },
    expenseData: {
      applicant_1: [],
      applicant_2: [],
    },
    applicant_1_relationship: "",
    applicant_2_relationship: "",
    no_of_dependents: "",
    applicant_2_dependents: "",
    assetsProperty: {
      applicant_1: [],
      applicant_2: [],
    },
    assetSavings: {
      applicant_1: [],
      applicant_2: [],
    },
    assetShares: {
      applicant_1: [],
      applicant_2: [],
    },
    assetSuperannuation: {
      applicant_1: [],
      applicant_2: [],
    },
    liabilityCards: {
      applicant_1: [],
      applicant_2: [],
    },
    liabilityVehicles: {
      applicant_1: [],
      applicant_2: [],
    },
    liabilityOthers: {
      applicant_1: [],
      applicant_2: [],
    },
    overAllValidation: {},
    validationErrorMessage: [],
    currentApplicationStatus: "",
    currentApplicationReason: "",
    currentApplicationReasonText: "",
    //Updates Tracker
    buyingPropertyUpdated: 0,
    sellingPropertyUpdated: 0,
    fullTimeUpdated: 0,
    partTimeUpdated: 0,
    casualUpdated: 0,
    selfEmpUpdated: 0,
    savingsUpdated: 0,
    creditCardUpdated: 0,
    //Calculators field
    comRefResult: {},
    hemCalcResult: {},
    oldApplication: {},
  },
  getters: {
    loanCreatedDate: ({ loanCreatedDate }) => loanCreatedDate,
    appBrokerId: ({ appBrokerId }) => appBrokerId,
    saveBtnClicked: ({ saveBtnClicked }) => saveBtnClicked,
    applicationVersions: ({ applicationVersions }) => applicationVersions,
    selectedApplication: ({ selectedApplication }) => selectedApplication,
    isTwoApplicants: ({ isTwoApplicants }) => isTwoApplicants,
    buyingProperty: ({ buyingProperty }) => buyingProperty,
    sellingProperty: ({ sellingProperty }) => sellingProperty,
    fullTimeIncome: ({ fullTimeIncome }) => [
      ...fullTimeIncome.applicant_1,
      ...fullTimeIncome.applicant_2,
    ],
    partTimeIncome: ({ partTimeIncome }) => [
      ...partTimeIncome.applicant_1,
      ...partTimeIncome.applicant_2,
    ],
    casualIncome: ({ casualIncome }) => [
      ...casualIncome.applicant_1,
      ...casualIncome.applicant_2,
    ],
    selfIncome: ({ selfIncome }) => [
      ...selfIncome.applicant_1,
      ...selfIncome.applicant_2,
    ],
    additionalIncome: ({ additionalIncome }) => [
      ...additionalIncome.applicant_1,
      ...additionalIncome.applicant_2,
    ],
    expenseData: ({ expenseData }) => [
      ...expenseData.applicant_1,
      ...expenseData.applicant_2,
    ],
    expenseFinalData: ({ expenseData }) => {
      let newArr = [];
      newArr = [
        ...newArr,
        ...Object.entries(expenseData.applicant_1).map(([key, value]) => {
          return {
            type: key,
            amount: Number(value),
            applicant_type: "primary",
          };
        }),
      ];
      newArr = [
        ...newArr,
        ...Object.entries(expenseData.applicant_2).map(([key, value]) => {
          return {
            type: key,
            amount: Number(value),
            applicant_type: "secondary",
          };
        }),
      ];
      return newArr;
    },
    assetsProperty: ({ assetsProperty }) => [
      ...assetsProperty.applicant_1,
      ...assetsProperty.applicant_2,
    ],
    assetSavings: ({ assetSavings }) => [
      ...assetSavings.applicant_1,
      ...assetSavings.applicant_2,
    ],
    assetShares: ({ assetShares }) => [
      ...assetShares.applicant_1,
      ...assetShares.applicant_2,
    ],
    assetSuperannuation: ({ assetSuperannuation }) => [
      ...assetSuperannuation.applicant_1,
      ...assetSuperannuation.applicant_2,
    ],
    liabilityCards: ({ liabilityCards }) => [
      ...liabilityCards.applicant_1,
      ...liabilityCards.applicant_2,
    ],
    liabilityVehicles: ({ liabilityVehicles }) => [
      ...liabilityVehicles.applicant_1,
      ...liabilityVehicles.applicant_2,
    ],
    liabilityOthers: ({ liabilityOthers }) => [
      ...liabilityOthers.applicant_1,
      ...liabilityOthers.applicant_2,
    ],
    overAllValidation: ({ overAllValidation }) => overAllValidation,
    validationErrorMessage: ({ validationErrorMessage }) =>
      validationErrorMessage,
    currentApplicationStatus: ({ currentApplicationStatus }) =>
      currentApplicationStatus,
    currentApplicationReason: ({ currentApplicationReason }) =>
      currentApplicationReason,
    currentApplicationReasonText: ({ currentApplicationReasonText }) =>
      currentApplicationReasonText,
    //Update Tracker
    buyingPropertyUpdated: ({ buyingPropertyUpdated }) => buyingPropertyUpdated,
    sellingPropertyUpdated: ({ sellingPropertyUpdated }) =>
      sellingPropertyUpdated,
    fullTimeUpdated: ({ fullTimeUpdated }) => fullTimeUpdated,
    partTimeUpdated: ({ partTimeUpdated }) => partTimeUpdated,
    selfEmpUpdated: ({ selfEmpUpdated }) => selfEmpUpdated,
    casualUpdated: ({ casualUpdated }) => casualUpdated,
    savingsUpdated: ({ savingsUpdated }) => savingsUpdated,
    creditCardUpdated: ({ creditCardUpdated }) => creditCardUpdated,
    //Calculators
    comRefResult: ({ comRefResult }) => comRefResult,
    hemCalcResult: ({ hemCalcResult }) => hemCalcResult,
    oldApplication: ({ oldApplication }) => oldApplication,
  },
  mutations: {
    setLoanCreatedAt(storeState, payload) {
      storeState.loanCreatedDate = payload;
    },
    setAppBrokerId(storeState, payload) {
      storeState.appBrokerId = payload;
    },
    setSaveBtnClicked(storeState, payload) {
      storeState.saveBtnClicked = payload;
    },
    setApplicationVersions(storeState, payload) {
      storeState.applicationVersions = payload ? payload : [];
    },
    setSelectedApplication(storeState, payload) {
      storeState.overAllValidation = {};
      let obj = {};
      let { summary, calculated_fields, applicant_2_hem_value } = payload;
      let summaryDetails = JSON.parse(summary);
      let calculatedFields = JSON.parse(calculated_fields);
      obj = { ...payload, ...summaryDetails, applicant_2_hem_value };
      obj.calculated_fields = calculatedFields;
      storeState.isTwoApplicants =
        obj && obj.number_of_applicants && obj.number_of_applicants === 2
          ? true
          : false;
      storeState.selectedApplication = obj ? obj : {};
      storeState.selectedVersionNumber = payload.version;
    },
    setSelectedApplicationEmpty(storeState) {
      storeState = {};
      storeState.selectedApplication = {};
      storeState.isTwoApplicants = false;
    },

    //BuyingProperty
    setBuyingProperty(storeState, payload) {
      storeState.buyingProperty = payload;
    },
    setBuyingPropertyUpdated(storeState, payload) {
      storeState.buyingPropertyUpdated = payload;
    },
    //SellingProperty
    setSellingProperty(storeState, payload) {
      storeState.sellingProperty = payload;
    },
    setSellingPropertyUpdated(storeState, payload) {
      storeState.sellingPropertyUpdated = payload;
    },
    setFullTimeIncome(storeState, payload) {
      switch (payload.type) {
        case "primary":
          storeState.fullTimeIncome.applicant_1 = [...payload.data];
          break;
        case "secondary":
          storeState.fullTimeIncome.applicant_2 = [...payload.data];
          break;
      }
    },
    setFullTimeUpdated(storeState, payload) {
      storeState.fullTimeUpdated = payload;
    },
    setPartTimeIncome(storeState, payload) {
      switch (payload.type) {
        case "primary":
          storeState.partTimeIncome.applicant_1 = payload.data;
          break;
        case "secondary":
          storeState.partTimeIncome.applicant_2 = payload.data;
          break;
      }
    },
    setPartTimeUpdated(storeState, payload) {
      storeState.partTimeUpdated = payload;
    },
    setCasualIncome(storeState, payload) {
      switch (payload.type) {
        case "primary":
          storeState.casualIncome.applicant_1 = payload.data;
          break;
        case "secondary":
          storeState.casualIncome.applicant_2 = payload.data;
          break;
      }
    },
    setCasualUpdated(storeState, payload) {
      storeState.casualUpdated = payload;
    },
    setSelfEmployeeIncome(storeState, payload) {
      switch (payload.type) {
        case "primary":
          storeState.selfIncome.applicant_1 = payload.data;
          break;
        case "secondary":
          storeState.selfIncome.applicant_2 = payload.data;
          break;
      }
    },
    setSelfEmpUpdated(storeState, payload) {
      storeState.selfEmpUpdated = payload;
    },
    setAdditionalIncome(storeState, payload) {
      switch (payload.type) {
        case "primary":
          storeState.additionalIncome.applicant_1 = [payload.data];
          break;
        case "secondary":
          storeState.additionalIncome.applicant_2 = [payload.data];
          break;
      }
    },
    setExpenses(storeState, payload) {
      switch (payload.type) {
        case "primary":
          storeState.expenseData.applicant_1 = payload.data;
          break;
        case "secondary":
          storeState.expenseData.applicant_2 = payload.data;
          break;
      }
    },
    setApplicantRelationship(storeState, payload) {
      switch (payload.type) {
        case "primary":
          storeState.applicant_1_relationship = payload.data;
          break;
        case "secondary":
          storeState.applicant_2_relationship = payload.data;
          break;
      }
    },
    setNumberOfDependent(storeState, payload) {
      switch (payload.type) {
        case "primary":
          storeState.no_of_dependents = payload.data;
          break;
        case "secondary":
          storeState.applicant_2_dependents = payload.data;
          break;
      }
    },
    setAssetProperty(storeState, payload) {
      let newArr = [];
      newArr = payload.data.map((item) => {
        item.applicant_type = payload.type;
        return item;
      });
      switch (payload.type) {
        case "primary":
          storeState.assetsProperty.applicant_1 = newArr;
          break;
        case "secondary":
          storeState.assetsProperty.applicant_2 = newArr;
          break;
      }
    },
    setAssets(storeState, payload) {
      let newArr = [];
      newArr = payload.data.map((item) => {
        item.type = payload.asset_type;
        item.applicant_type = payload.type;
        return item;
      });
      switch (payload.asset_type) {
        case "savings":
          switch (payload.type) {
            case "primary":
              storeState.assetSavings.applicant_1 = newArr;
              break;
            case "secondary":
              storeState.assetSavings.applicant_2 = newArr;
              break;
          }
          break;
        case "shares":
          switch (payload.type) {
            case "primary":
              storeState.assetShares.applicant_1 = newArr;
              break;
            case "secondary":
              storeState.assetShares.applicant_2 = newArr;
              break;
          }
          break;
        case "superannuation":
          switch (payload.type) {
            case "primary":
              storeState.assetSuperannuation.applicant_1 = newArr;
              break;
            case "secondary":
              storeState.assetSuperannuation.applicant_2 = newArr;
              break;
          }
          break;
      }
    },
    setAssetsEmpty(storeState) {
      storeState.assetSavings.applicant_1 = [];
      storeState.assetSavings.applicant_2 = [];
      storeState.assetShares.applicant_1 = [];
      storeState.assetShares.applicant_1 = [];
      storeState.assetSuperannuation.applicant_1 = [];
      storeState.assetSuperannuation.applicant_1 = [];
      storeState.assetsProperty.applicant_1 = [];
      storeState.assetsProperty.applicant_2 = [];
      storeState.liabilityCards.applicant_1 = [];
      storeState.liabilityCards.applicant_2 = [];
      storeState.liabilityVehicles.applicant_1 = [];
      storeState.liabilityVehicles.applicant_2 = [];
      storeState.liabilityOthers.applicant_1 = [];
      storeState.liabilityOthers.applicant_2 = [];
    },
    setSavingsUpdated(storeState, payload) {
      storeState.savingsUpdated = payload;
    },
    setLiability(storeState, payload) {
      let newArr = [];
      newArr = payload.data.map((item) => {
        item.applicant_type = payload.type;
        return item;
      });
      switch (payload.liability_type) {
        case "cards":
          switch (payload.type) {
            case "primary":
              storeState.liabilityCards.applicant_1 = newArr;
              break;
            case "secondary":
              storeState.liabilityCards.applicant_2 = newArr;
              break;
          }
          break;
        case "vehicles":
          switch (payload.type) {
            case "primary":
              storeState.liabilityVehicles.applicant_1 = newArr;
              break;
            case "secondary":
              storeState.liabilityVehicles.applicant_2 = newArr;
              break;
          }
          break;
        case "others":
          switch (payload.type) {
            case "primary":
              storeState.liabilityOthers.applicant_1 = newArr;
              break;
            case "secondary":
              storeState.liabilityOthers.applicant_2 = newArr;
              break;
          }
          break;
      }
    },
    setCreditCardUpdated(storeState, payload) {
      storeState.creditCardUpdated = payload;
    },
    checkValidation(storeState) {
      let newArr = [];
      Object.keys(storeState.overAllValidation).map((key) => {
        if (storeState.overAllValidation[key]) {
          switch (key) {
            case "summary":
              newArr.push("Some fields are missing in Summary");
              break;
            case "buying":
              newArr.push("Some fields are missing in Incoming properties");
              break;
            case "selling":
              newArr.push("Some fields are missing in Outgoing properties");
              break;
            //Income
            case "additional_income":
              newArr.push(
                "Some fields are missing in Additional Income section"
              );
              break;
            case "income_casual":
              newArr.push("Some fields are missing in Income - Casual section");
              break;
            case "income_fulltime":
              newArr.push(
                "Some fields are missing in Income - Full-time section"
              );
              break;
            case "income_parttime":
              newArr.push(
                "Some fields are missing in Income - Part-time section"
              );
              break;
            case "income_self":
              newArr.push(
                "Some fields are missing in Income - Self employed section"
              );
              break;
            //Expense
            case "expense":
              newArr.push("Some fields are missing in Expense section");
              break;
            //Assets
            case "asset_savings":
              newArr.push(
                "Some fields are missing in Assets - Savings section"
              );
              break;
            case "asset_shares":
              newArr.push("Some fields are missing in Assets - Shares section");
              break;
            case "asset_super":
              newArr.push(
                "Some fields are missing in Assets - Superannuation section"
              );
              break;
            case "asset_property":
              newArr.push(
                "Some fields are missing in Assets - Property section"
              );
              break;
            //Liability
            case "liability_cards":
              newArr.push(
                "Some fields are missing in Liability - Cards section"
              );
              break;
            case "liability_vehicle":
              newArr.push(
                "Some fields are missing in Liability - Vehicle section"
              );
              break;
            case "liability_others":
              newArr.push(
                "Some fields are missing in Liability - Personal Loans/Others"
              );
              break;
          }
        }
      });
      storeState.validationErrorMessage = newArr;
    },
    setValidation(storeState, payload) {
      let newObj = {};
      if (typeof payload.value !== "undefined") {
        newObj[payload.type] = payload.value;
        storeState.overAllValidation = {
          ...storeState.overAllValidation,
          ...newObj,
        };
      }
    },
    setApplicationStatus(storeState, payload) {
      storeState.currentApplicationStatus = payload;
    },
    setApplicationStatusReason(storeState, payload) {
      storeState.currentApplicationReason = payload;
    },
    setApplicationStatusReasonText(storeState, payload) {
      storeState.currentApplicationReasonText = payload;
    },
    //Calculators
    setCommReferenceFields(storeState, payload) {
      storeState.comRefResult = payload;
    },
    setHemCalculatorFields(storeState, payload) {
      storeState.hemCalcResult = payload
        ? {
            ...payload[0],
            ...payload[1],
          }
        : {};
    },
    //Price finder
    setPricefinderProperty(storeState, payload) {
      if (!payload) return;
      storeState.buyingProperty = payload.incoming_properties
        ? payload.incoming_properties
        : [];
      storeState.sellingProperty = payload.outgoing_properties
        ? payload.outgoing_properties
        : [];
    },
    setOldVersionData(storeState, payload) {
      storeState.oldApplication = payload;
    },
    // set additional fund
    setAdditionalfund(storeState, payload) {
      storeState.selectedApplication.additional_fund = payload;
    },
    // set deposit
    setDeposit(storeState, payload) {
      storeState.selectedApplication.deposit = payload;
    },
    setSettlementDate(storeState, payload) {
      storeState.selectedApplication.settlement_date = payload;
    },
    setLoanPurpose(storeState, payload) {
      storeState.selectedApplication.loan_purpose = payload;
    },
  },
  actions: {
    setLoanCreatedAt({ commit }, payload) {
      commit("setLoanCreatedAt", payload);
    },
    setAppBrokerId({ commit }, payload) {
      commit("setAppBrokerId", payload);
    },
    setSaveBtnClicked({ commit }, payload) {
      commit("setSaveBtnClicked", payload);
    },
    setAdditionalfund({ commit }, payload) {
      commit("setAdditionalfund", payload);
    },
    setDeposit({ commit }, payload) {
      commit("setDeposit", payload);
    },
    setSettlementDate({ commit }, payload) {
      commit("setSettlementDate", payload);
    },
    setLoanPurpose({ commit }, payload) {
      commit("setLoanPurpose", payload);
    },
    async getApplicationVersions({ commit }, payload) {
      const response = await _get(
        `${APP_URL}get-all-application-summary?id=${payload}`
      );
      commit("setApplicationVersions", response.data);
    },
    setSelectedApplication({ commit }, payload) {
      commit("setSelectedApplication", payload);
    },
    setSelectedApplicationEmpty({ commit }) {
      commit("setSelectedApplicationEmpty", "");
    },
    async getApplicantsFromApplication(storeState, payload) {
      const { data } = await _get(
        `${APP_URL}get-applicants-for-email?applicationId=${payload}`
      );
      return data.data ? data.data : [];
    },
    async sendApplicantMail(storeState, payload) {
      await _post(`${APP_URL}applicants-send-email`, { applicants: payload });
    },
    setApplicationStatus({ commit }, payload) {
      commit("setApplicationStatus", payload);
    },
    setApplicationStatusReason({ commit }, payload) {
      commit("setApplicationStatusReason", payload);
    },
    setApplicationStatusReasonText({ commit }, payload) {
      commit("setApplicationStatusReasonText", payload);
    },
    async runApplicationDecisionEngine(storeState, payload) {
      try {
        const res = await _post(
          SERVER_URL_2 +
            `run-decision-seeker-engine?application_id=${payload.id}&version=${payload.version}`
        );
        const { data } = res;
        return data?.decision_seeker_result;
      } catch (err) {
        return "fail";
      }
    },
    async getNewVersionData({ rootState, state, getters }) {
      const {
        fundsToComplete,
        settlementData,
        summaryData,
        ratesData,
        netSales,
      } = rootState.summary;
      // endDebtData, netSales, mortgagePayout,repaymentData
      //Update the Setupfee, Broker fee, Caps loan rate percent
      ratesData.setup_fee = ratesData.setup_fee ? ratesData.setup_fee / 100 : 0;
      ratesData.broker_fee = ratesData.broker_fee
        ? ratesData.broker_fee / 100
        : 0;
      ratesData.cap_loan_rate = ratesData.cap_loan_rate
        ? ratesData.cap_loan_rate / 100
        : 0;

      // const { credit_history_review,
      //     summary_notes,
      //     property_notes,
      //     income_review,
      //     expense_review,
      //     asset_review,
      //     liability_review } = rootState.loanDescription
      // let descriptionData = {
      //     credit_history_review: credit_history_review,
      //     summary_notes: summary_notes,
      //     property_notes: property_notes,
      //     income_review: income_review,
      //     expense_review: expense_review,
      //     asset_review: asset_review,
      //     liability_review: liability_review,
      // }
      fundsToComplete.settlement_charge = fundsToComplete.settlement_charge
        ? fundsToComplete.settlement_charge.toString()
        : "0";
      fundsToComplete.funds_to_complete = fundsToComplete.funds_to_complete
        ? fundsToComplete.funds_to_complete.toString()
        : "0";
      summaryData.loan_term = summaryData.loan_term
        ? summaryData.loan_term.toString()
        : "0";
      //Updated values from the application
      // let updatedValues = {
      //     //Summary part
      //     setup_fee: summaryData.setup_fee ? summaryData.setup_fee / 100 : 0,
      //     broker_fee: summaryData.broker_fee ? summaryData.broker_fee / 100 : 0,
      //     cap_loan_rate: summaryData.cap_loan_rate ? summaryData.cap_loan_rate / 100 : 0,
      //     valuation_fee: summaryData.valuation_fee ? summaryData.valuation_fee : 0,
      //     deposit_percent: summaryData.deposit_percent ? summaryData.deposit_percent : 0,
      //     //Net sales proceeds
      //     savings_post: netSales.savings_post ? netSales.savings_post : 0,
      //     //Property rates
      //     interest_free_term: ratesData.interest_free_term ? ratesData.interest_free_term : 0,
      //     loan_term: ratesData.loan_term ? ratesData.loan_term.toString() : "6",
      //     //Liability payment
      //     residual_loan_amount: repaymentData.residual_loan_amount ? Number(repaymentData.residual_loan_amount) : 0,
      //     service_rate: repaymentData.service_rate ? repaymentData.service_rate : 0,
      //     implied_loan_term: repaymentData.implied_loan_term ? repaymentData.implied_loan_term.toString() : "0",
      //     loan_type: repaymentData.loan_type ? repaymentData.loan_type : "",
      // }
      // let updatedValueArr = [];
      // updatedValueArr.push(updatedValues)
      let newObj = {
        ...state.selectedApplication,
        //Buying
        incoming_properties: state.buyingProperty ? state.buyingProperty : [],
        properties_intend_to_buy: state.buyingProperty
          ? state.buyingProperty.length
          : 0,
        //Selling
        outgoing_properties: state.sellingProperty ? state.sellingProperty : [],
        properties_intend_to_sell: state.sellingProperty
          ? state.sellingProperty.length
          : 0,
        //Income
        salaried: [
          ...state.fullTimeIncome.applicant_1,
          ...state.fullTimeIncome.applicant_2,
          ...state.partTimeIncome.applicant_1,
          ...state.partTimeIncome.applicant_2,
          ...state.casualIncome.applicant_1,
          ...state.casualIncome.applicant_2,
        ],
        self_employed: [
          ...state.selfIncome.applicant_1,
          ...state.selfIncome.applicant_2,
        ],
        additional_income: [
          ...state.additionalIncome.applicant_1,
          ...state.additionalIncome.applicant_2,
        ],
        //Expenses
        expenses: getters.expenseFinalData ? getters.expenseFinalData : [],
        applicant_1_dependents: state.no_of_dependents
          ? state.no_of_dependents
          : "0",
        applicant_2_dependents: state.applicant_2_dependents
          ? state.applicant_2_dependents
          : "0",
        applicant_1_relationship: state.applicant_1_relationship
          ? state.applicant_1_relationship
          : "",
        applicant_2_relationship: state.applicant_2_relationship
          ? state.applicant_2_relationship
          : "",
        //Assets
        assets: [
          ...getters.assetSavings,
          ...getters.assetShares,
          ...getters.assetSuperannuation,
        ],
        assets_properties: [...getters.assetsProperty],
        liability_cards: [...getters.liabilityCards],
        liability_vechicles: [...getters.liabilityVehicles],
        liability_others: [...getters.liabilityOthers],
        // broker_fee: 0,
        // default_interest: 0,
        valuation: summaryData.valuation_fee ? summaryData.valuation_fee : 0,
        deposit: state.selectedApplication.deposit
          ? state.selectedApplication.deposit
          : 0,
        setup_fee: summaryData.setup_fee ? summaryData.setup_fee.toFixed(3) : 0,
        broker_fee: summaryData.broker_fee
          ? summaryData.broker_fee.toFixed(3)
          : 0,
        qouted_setup_fee: summaryData.qouted_setup_fee
          ? summaryData.qouted_setup_fee.toFixed(3)
          : 0,
        cap_loan_rate: summaryData.cap_loan_rate
          ? summaryData.cap_loan_rate
          : 0,
        savings_post: netSales.savings_post ? netSales.savings_post : 0,
        total_savings_to_purchase: fundsToComplete.total_savings_to_purchase
          ? fundsToComplete.total_savings_to_purchase
          : 0,
        interest_free_term: ratesData.interest_free_term
          ? ratesData.interest_free_term
          : 0,
        loan_term: ratesData.loan_term ? ratesData.loan_term.toString() : "6",
        valuation_fee_by_brigit: ratesData?.valuation_fee_by_brigit
          ? ratesData?.valuation_fee_by_brigit
          : 0,

        // funds_to_complete: { ...fundsToComplete },
        // end_debt_data: { ...endDebtData },
        // net_sales: { ...netSales },
        // mortgage_payout: { ...mortgagePayout },
        // summary_data: { ...summaryData },
        // reviews_data: { ...descriptionData },
        ...settlementData,
      };
      delete newObj.summary;
      //Get liability values for hem calculator
      // const applicant_1_credit_card = newObj.liability_cards.filter(item => item.applicant_type == 'primary')
      // const applicant_1_other_mortgage = newObj.assets_properties.filter(item => item.applicant_type == 'primary');
      // const applicant_1_other_loan = newObj.liability_others.filter(item => item.applicant_type == 'primary');
      //API to call calculate the calulated fields
      // const payload = {
      //     incoming_properties: state.buyingProperty ? state.buyingProperty : [],
      //     outgoing_properties: state.sellingProperty ? state.sellingProperty : [],
      //     applicant_details: [...state.selectedApplication.applicant_details],
      //     broker_fee_inc_gst: state.selectedApplication.broker_fee_inc_gst ? Number(state.selectedApplication.broker_fee_inc_gst) : 0,
      //     total_savings_to_purchase: state?.selectedApplication?.additional_funds.add_fund_amount ? Number(state?.selectedApplication?.additional_funds.add_fund_amount) : 0,
      //     initial_amount_financed: 0,
      //     savings_end_debt: 0,
      //     add_funds_requested: state?.selectedApplication.additional_fund ? Number(state?.selectedApplication?.additional_fund) : 0,
      //     constants: [...updatedValueArr],
      //     hem_req: {
      //         applicant_1_annual_income: 0,
      //         applicant_2_annual_income: 0,
      //         applicant_1_age: newObj.number_of_applicants > 0 ? newObj.applicant_details[0]?.age : 0,
      //         applicant_2_age: newObj.number_of_applicants > 1 ? newObj.applicant_details[1]?.age : 0,
      //         applicant_1_relationship: newObj?.applicant_1_relationship ? newObj?.applicant_1_relationship : '',
      //         no_of_dependents: newObj.no_of_dependents ? newObj.no_of_dependents : "0",
      //         savings_end_debt: 0,
      //         current_mortgage: 0,
      //         ip_deposit_amount: newObj.incoming_properties[0] ? newObj.incoming_properties[0].deposit_amount : 0,
      //         assets_properties: applicant_1_other_mortgage ? applicant_1_other_mortgage : [],
      //         liability_cards: applicant_1_credit_card ? applicant_1_credit_card : [],
      //         liability_others: applicant_1_other_loan ? applicant_1_other_loan : []
      //     }
      // }
      // const result = await _post(SERVER_URL_2 + 'service-calculator-version', payload)
      // let data = result.data
      // let hemObj = data && data.hem ? { ...data.hem[0], ...data.hem[1] } : {}
      // delete data.hem
      // data = { ...data, ...hemObj }
      // newObj.calculated_fields = {
      //     ...newObj.calculated_fields,
      //     ...data,
      //     end_debt: data.end_debt ? Number(data.end_debt) : 0,
      // }
      return newObj;
    },
    async saveCurrentVersion({ rootState, state, getters }) {
		const { subTotal } = rootState;
		const {
			fundsToComplete,
			endDebtData,
			netSales,
			mortgagePayout,
			settlementData,
			summaryData,
			ratesData,
			repaymentData,
			serviciability,
		} = rootState.summary;
		//Update the Setupfee, Broker fee, Caps loan rate percent

		ratesData.setup_fee = ratesData.setup_fee ? ratesData.setup_fee / 100 : 0;
		ratesData.broker_fee = ratesData.broker_fee ? ratesData.broker_fee / 100 : 0;
		ratesData.cap_loan_rate = ratesData.cap_loan_rate
			? ratesData.cap_loan_rate / 100
			: 0;
		ratesData.valuation_fee_by_brigit = ratesData.valuation_fee_by_brigit
			? ratesData.valuation_fee_by_brigit
			: 0;

		const {
			credit_history_review,
			summary_notes,
			property_notes,
			income_review,
			expense_review,
			asset_review,
			liability_review,
			initial_decision,
			final_decision,
		} = rootState.loanDescription;
		let descriptionData = {
			credit_history_review: credit_history_review,
			summary_notes: summary_notes,
			property_notes: property_notes,
			income_review: income_review,
			expense_review: expense_review,
			asset_review: asset_review,
			liability_review: liability_review,
		};
		fundsToComplete.settlement_charge = fundsToComplete.settlement_charge
			? fundsToComplete.settlement_charge.toString()
			: "0";
		fundsToComplete.funds_to_complete = fundsToComplete.funds_to_complete
			? fundsToComplete.funds_to_complete.toString()
			: "0";
		summaryData.loan_term = summaryData.loan_term
			? summaryData.loan_term.toString()
			: "0";
		//Updated values from the application
		let updatedValues = {
			//Summary part
			setup_fee: summaryData.setup_fee ? summaryData.setup_fee / 100 : 0,
			broker_fee: summaryData.broker_fee ? summaryData.broker_fee / 100 : 0,
			cap_loan_rate: summaryData.cap_loan_rate
				? summaryData.cap_loan_rate / 100
				: 0,
			valuation_fee: summaryData.valuation_fee ? summaryData.valuation_fee : 0,
			deposit_percent: summaryData.deposit_percent
				? summaryData.deposit_percent
				: 0,
			//Net sales proceeds
			savings_post: netSales.savings_post ? netSales.savings_post : 0,
			//Property rates
			interest_free_term: ratesData.interest_free_term
				? ratesData.interest_free_term
				: 0,
			loan_term: ratesData.loan_term ? ratesData.loan_term.toString() : "6",

			//Liability payment
			residual_loan_amount: repaymentData.residual_loan_amount
				? Number(repaymentData.residual_loan_amount)
				: 0,
			service_rate: repaymentData.service_rate
				? repaymentData.service_rate / 100
				: 0,
			implied_loan_term: repaymentData.implied_loan_term
				? repaymentData.implied_loan_term.toString()
				: "0",
			loan_type: repaymentData.loan_type ? repaymentData.loan_type : "",
		};
		let updatedValueArr = [];
		updatedValueArr.push(updatedValues);
		let newObj = {
			...state.selectedApplication,
			//Buying
			incoming_properties: state.buyingProperty ? state.buyingProperty : [],
			properties_intend_to_buy: state.buyingProperty
				? state.buyingProperty.length
				: 0,
			//Selling
			outgoing_properties: state.sellingProperty ? state.sellingProperty : [],
			properties_intend_to_sell: state.sellingProperty
				? state.sellingProperty.length
				: 0,
			//Income
			salaried: [
				...state.fullTimeIncome.applicant_1,
				...state.fullTimeIncome.applicant_2,
				...state.partTimeIncome.applicant_1,
				...state.partTimeIncome.applicant_2,
				...state.casualIncome.applicant_1,
				...state.casualIncome.applicant_2,
			],
			self_employed: [
				...state.selfIncome.applicant_1,
				...state.selfIncome.applicant_2,
			],
			additional_income: [
				...state.additionalIncome.applicant_1,
				...state.additionalIncome.applicant_2,
			],
			//Expenses
			expenses: getters.expenseFinalData ? getters.expenseFinalData : [],
			applicant_1_dependents: state.no_of_dependents ? state.no_of_dependents : "0",
			applicant_2_dependents: state.applicant_2_dependents
				? state.applicant_2_dependents
				: "0",
			applicant_1_relationship: state.applicant_1_relationship
				? state.applicant_1_relationship
				: "",
			applicant_2_relationship: state.applicant_2_relationship
				? state.applicant_2_relationship
				: "",
			//Assets
			assets: [
				...getters.assetSavings,
				...getters.assetShares,
				...getters.assetSuperannuation,
			],
			assets_properties: [...getters.assetsProperty],
			liability_cards: [...getters.liabilityCards],
			liability_vechicles: [...getters.liabilityVehicles],
			liability_others: [...getters.liabilityOthers],
			broker_fee: 0,
			default_interest: 0,
			valuation: summaryData.valuation_fee ? summaryData.valuation_fee : 0,
			// deposit: summaryData.deposit_percent ? summaryData.deposit_percent : 0,
			// deposit: state?.selectedApplication.deposit ? Number(state?.selectedApplication?.deposit) : 0,
			funds_to_complete: { ...fundsToComplete },
			end_debt_data: { ...endDebtData },
			net_sales: { ...netSales },
			mortgage_payout: { ...mortgagePayout },
			summary_data: { ...summaryData },
			reviews_data: { ...descriptionData },
			...settlementData,
		};
		delete newObj.summary;
		//Get liability values for hem calculator
		const applicant_1_credit_card = newObj.liability_cards.filter(
			(item) => item.applicant_type == "primary"
		);
		const applicant_1_other_mortgage = newObj.assets_properties.filter(
			(item) => item.applicant_type == "primary"
		);
		const applicant_1_other_loan = newObj.liability_others.filter(
			(item) => item.applicant_type == "primary"
		);

		// Function to calculate applicant 1 and applicant 2 anual income
		// const { overAllTotal } = rootState.subTotal
		//const salaried = newObj.salaried;

		let app_1_income = subTotal.overAllTotal.applicant_1;
		/* ||salaried.reduce((a, b) => {
        if (b.applicant_type == "primary") {
          let amount = 0;
          switch (b.salary_frequency) {
            case "Weekly":
              if (b.jobType == "casual") {
                amount = b.salary_amount_used * 46;
              } else {
                amount = b.salary_amount_used * 52;
              }
              break;
            case "Fortnightly":
              amount = b.salary_amount_used * 26;
              break;
            case "Monthly":
              amount = b.salary_amount_used * 12;
              break;
            case "Quarterly":
              amount = b.salary_amount_used * 4;
              break;
            case "Yearly":
              amount = b.salary_amount_used * 1;
              break;
          }
          return a + parseInt(amount);
        }
      }, 0); */

		let app_2_income = subTotal.overAllTotal.applicant_2;
		/* salaried.reduce((a, b) => {
        if (b.applicant_type == "secondary") {
          let amount = 0;
          switch (b.salary_frequency) {
            case "Weekly":
              if (b.jobType == "casual") {
                amount = b.salary_amount_used * 46;
              } else {
                amount = b.salary_amount_used * 52;
              }
              break;
            case "Fortnightly":
              amount = b.salary_amount_used * 26;
              break;
            case "Monthly":
              amount = b.salary_amount_used * 12;
              break;
            case "Quarterly":
              amount = b.salary_amount_used * 4;
              break;
            case "Yearly":
              amount = b.salary_amount_used * 1;
              break;
          }
          return a + parseInt(amount);
        }
      }, 0); */

		// Function to calculate applicant expenses
		// const expenses = newObj.expenses

		// let monthlyExpenses = expenses.reduce((a, b) => {
		//     return a + parseInt(b.amount)
		// }, 0);
		let monthlyExpenses =
			Number(subTotal.expenseTotal.applicant_1) +
			Number(subTotal.expenseTotal.applicant_2);

		// Function to calculate montly liability payment
		const { vehicleTotal, cardsTotal, personalLoanTotal, propertyTotal } =
			rootState.subTotal;
		const liabilityPayment =
			vehicleTotal.applicant_1 +
			vehicleTotal.applicant_2 +
			cardsTotal.applicant_1 +
			cardsTotal.applicant_2 +
			personalLoanTotal.applicant_1 +
			personalLoanTotal.applicant_2 +
			propertyTotal.applicant_1 +
			propertyTotal.applicant_2;

		// Income after tax calculator
		const incomeTaxResult = await _post(SERVER_URL_2 + "income-tax-calculator", {
			salaried: [
				...state.fullTimeIncome.applicant_1,
				...state.fullTimeIncome.applicant_2,
				...state.partTimeIncome.applicant_1,
				...state.partTimeIncome.applicant_2,
				...state.casualIncome.applicant_1,
				...state.casualIncome.applicant_2,
			],
			self_employed: [
				...state.selfIncome.applicant_1,
				...state.selfIncome.applicant_2,
			],
			additional_income: [
				...state.additionalIncome.applicant_1,
				...state.additionalIncome.applicant_2,
			],
		});
		//API to call calculate the calulated fields
		const payload = {
			incoming_properties: state.buyingProperty ? state.buyingProperty : [],
			outgoing_properties: state.sellingProperty ? state.sellingProperty : [],
			applicant_details: [...state.selectedApplication.applicant_details],
			broker_fee_inc_gst: state.selectedApplication.broker_fee_inc_gst
				? Number(state.selectedApplication.broker_fee_inc_gst)
				: 0,
			// total_savings_to_purchase: state?.selectedApplication?.additional_funds.add_fund_amount ? Number(state?.selectedApplication?.additional_funds.add_fund_amount) : 0,
			total_savings_to_purchase: fundsToComplete.total_savings_to_purchase
				? fundsToComplete.total_savings_to_purchase
				: 0,
			initial_amount_financed: 0,
			// savings_end_debt: 0,
			savings_end_debt: newObj?.calculated_fields
				? newObj.calculated_fields.savings_post
				: 0,
			add_funds_requested: state?.selectedApplication.additional_fund
				? Number(state?.selectedApplication?.additional_fund)
				: 0,
			constants: [...updatedValueArr],
			hem_req: {
				// applicant_1_annual_income: overAllTotal.applicant_1 ? overAllTotal.applicant_1 * 12 : 0,
				// applicant_2_annual_income: overAllTotal.applicant_2 ? overAllTotal.applicant_2 * 12 : 0,
				applicant_1_annual_income: app_1_income ? app_1_income : 0,
				applicant_2_annual_income: app_2_income ? app_2_income : 0,
				applicant_1_age:
					newObj.number_of_applicants > 0
						? newObj.applicant_details[0]?.age
						: 0,
				applicant_2_age:
					newObj.number_of_applicants > 1
						? newObj.applicant_details[1]?.age
						: 0,
				applicant_1_relationship: newObj?.applicant_1_relationship
					? newObj?.applicant_1_relationship
					: "",
				applicant_1_dependents: newObj.applicant_1_dependents
					? newObj.applicant_1_dependents
					: "0",
				applicant_2_relationship: state?.applicant_2_relationship || "",
				applicant_2_dependents: newObj.applicant_2_dependents
					? newObj.applicant_2_dependents
					: "0",
				savings_end_debt: newObj?.calculated_fields
					? newObj.calculated_fields.savings_post
					: 0,
				// current_mortgage: 0,
				ip_deposit_amount: newObj.incoming_properties[0]
					? newObj.incoming_properties[0].deposit_amount
					: 0,
				assets_properties: applicant_1_other_mortgage
					? applicant_1_other_mortgage
					: [],
				liability_cards: applicant_1_credit_card ? applicant_1_credit_card : [],
				liability_others: applicant_1_other_loan ? applicant_1_other_loan : [],
				application_expenses: monthlyExpenses ? monthlyExpenses : 0,
				monthly_liability_payment: liabilityPayment ? liabilityPayment : 0,
				...incomeTaxResult.data,
			},
			// other liablity
			other_liabilities: fundsToComplete.other_liability
				? fundsToComplete.other_liability
				: 0,
			// To calculate tax
			salaried: [
				...state.fullTimeIncome.applicant_1,
				...state.fullTimeIncome.applicant_2,
				...state.partTimeIncome.applicant_1,
				...state.partTimeIncome.applicant_2,
				...state.casualIncome.applicant_1,
				...state.casualIncome.applicant_2,
			],
			self_employed: [
				...state.selfIncome.applicant_1,
				...state.selfIncome.applicant_2,
			],
			additional_income: [
				...state.additionalIncome.applicant_1,
				...state.additionalIncome.applicant_2,
			],
		};
		const { savingsTotalDetails, superTotalDetails, shareTotalDetails } =
			rootState.subTotal;
		payload.towards_purchase_total =
			savingsTotalDetails.towards_purchase_applicant_1 +
			savingsTotalDetails.towards_purchase_applicant_2 +
			superTotalDetails.towards_purchase_applicant_1 +
			superTotalDetails.towards_purchase_applicant_2 +
			shareTotalDetails.towards_purchase_applicant_1 +
			shareTotalDetails.towards_purchase_applicant_2;
		payload.post_settlement_total =
			savingsTotalDetails.post_settlement_applicant_1 +
			savingsTotalDetails.post_settlement_applicant_2 +
			superTotalDetails.post_settlement_applicant_1 +
			superTotalDetails.post_settlement_applicant_2 +
			shareTotalDetails.post_settlement_applicant_1 +
			shareTotalDetails.post_settlement_applicant_2;

		// setting serviciablity which will be found under summary tab
		payload.serviciability = serviciability;
		payload.application_date =rootState.applications.loanCreatedDate;

		// posting to server
		const result = await _post(
			SERVER_URL_2 + "v1/" + "service-calculator-version",
			payload
		);
		let data = result.data;
		// let hemObj = data && data.hem ? { ...data.hem[0], ...data.hem[1] } : {}
		let hemObj = {};
		if (data.hem) {
			data.hem.map((obj) => {
				hemObj = {
					...hemObj,
					...obj,
				};
			});
		}
		delete data.hem;
		data = {
			...data,
			...hemObj,
		};
		newObj.calculated_fields = {
			...newObj.calculated_fields,
			...data,
			end_debt: data.end_debt ? Number(data.end_debt) : 0,
			valuation_fee_by_brigit: ratesData.valuation_fee_by_brigit
				? ratesData.valuation_fee_by_brigit
				: 0,
			total_savings_to_purchase: fundsToComplete.total_savings_to_purchase
				? fundsToComplete.total_savings_to_purchase.toString()
				: "0",

			// funds to compelete
			savings: fundsToComplete.savings ? fundsToComplete.savings.toString() : "0",
			...incomeTaxResult.data,
		};
		// updated calc
		newObj.calculated_fields.towards_purchase_total =
			savingsTotalDetails.towards_purchase_applicant_1 +
			savingsTotalDetails.towards_purchase_applicant_2 +
			superTotalDetails.towards_purchase_applicant_1 +
			superTotalDetails.towards_purchase_applicant_2 +
			shareTotalDetails.towards_purchase_applicant_1 +
			shareTotalDetails.towards_purchase_applicant_2;
		/* newObj.calculated_fields.funds_to_complete_purchase = Number(
        newObj.calculated_fields.funds_to_complete_purchase
      ); */
		//-newObj.calculated_fields.towards_purchase_total;
		newObj.calculated_fields.loan_amount = parseInt(
			newObj.summary_data.setup_fee_amount +
				newObj.calculated_fields.funds_to_complete_purchase
		);
		/* newObj.calculated_fields.funds_to_complete_purchase =
        newObj.calculated_fields.funds_to_complete_purchase.toString(); */
		newObj = {
			...newObj,
			initial_decision: initial_decision,
			final_decision: final_decision,
		};
		newObj.version = state.selectedVersionNumber;
		await _post(APP_URL + "save-application-version", newObj);
  },

    async runHemCalculator({ commit }, payload) {
      const commReference = await _get(SERVER_URL_2 + "common_references");
      commit(
        "setCommReferenceFields",
        commReference.data && commReference.data.length
          ? commReference.data[0]
          : {}
      );
      let finalObj = {
        applicant_1_annual_income: 0,
        applicant_2_annual_income: 0,
        applicant_1_relationship: payload?.selectedApplication
          ?.applicant_1_relationship
          ? payload?.selectedApplication?.applicant_1_relationship
          : "",
        applicant_1_dependents: payload.no_of_dependents
          ? payload.no_of_dependents
          : "0",
        applicant_2_dependents: payload.applicant_2_dependents
          ? payload.applicant_2_dependents
          : "0",
        savings_end_debt: 0,
        // current_mortgage: 0,
        ip_deposit_amount: payload.incoming_properties[0]
          ? payload.incoming_properties[0].deposit_amount
          : 0,
      };
      let currentMortgage = 0;
      const outgoing_properties = payload.outgoing_properties.map((obj) => {
        currentMortgage = currentMortgage + obj.outstanding_loan_amount;
        return {
          postal_code: obj.postal_code,
          country: obj.country.toLowerCase(),
          zone: obj.zone,
          premium: obj.premium,
          category: obj.category,
          mortage_against: obj.mortage_against,
          outstanding_loan_amount: obj.outstanding_loan_amount,
          deposit_amount: obj.deposit_amount,
          sale_price: obj.sale_price,
          estimated_price: obj.estimated_price,
          property_sold: obj.property_sold,
          deposit_held_in_trust: obj.deposit_held_in_trust,
        };
      });
      // finalObj.current_mortgage = currentMortgage;
      finalObj.outgoing_properties = outgoing_properties;
      const result = await _post(SERVER_URL_2 + "hem-calculator", finalObj);
      commit(
        "setHemCalculatorFields",
        result.data && result.data.length ? result.data : {}
      );
    },

    //BuyingProperty
    setBuyingProperty({ commit }, payload) {
      commit("setBuyingProperty", payload);
      // dispatch('getOldVersionData')
    },
    setBuyingPropertyUpdated({ commit }, payload) {
      commit("setBuyingPropertyUpdated", payload);
      // dispatch('getOldVersionData')
    },
    //SellingProperty
    setSellingProperty({ commit }, payload) {
      commit("setSellingProperty", payload);
      // dispatch('getOldVersionData')
    },
    setSellingPropertyUpdated({ commit }, payload) {
      commit("setSellingPropertyUpdated", payload);
      // dispatch('getOldVersionData')
    },
    //Incomes
    //Full-Time
    setFullTimeIncome({ commit }, payload) {
      commit("setFullTimeIncome", payload);
      // dispatch('getOldVersionData')
    },
    setFullTimeUpdated({ commit }, payload) {
      commit("setFullTimeUpdated", payload);
      // dispatch('getOldVersionData')
    },
    //Part-Time
    setPartTimeIncome({ commit }, payload) {
      commit("setPartTimeIncome", payload);
      // dispatch('getOldVersionData')
    },
    setPartTimeUpdated({ commit }, payload) {
      commit("setPartTimeUpdated", payload);
      // dispatch('getOldVersionData')
    },
    //Casual
    setCasualIncome({ commit }, payload) {
      commit("setCasualIncome", payload);
      // dispatch('getOldVersionData')
    },
    setCasualUpdated({ commit }, payload) {
      commit("setCasualUpdated", payload);
      // dispatch('getOldVersionData')
    },
    //Self employed
    setSelfEmployeeIncome({ commit }, payload) {
      commit("setSelfEmployeeIncome", payload);
      // dispatch('getOldVersionData')
    },
    setSelfEmpUpdated({ commit }, payload) {
      commit("setSelfEmpUpdated", payload);
      // dispatch('getOldVersionData')
    },
    //Additional Income
    setAdditionalIncome({ commit }, payload) {
      commit("setAdditionalIncome", payload);
      // dispatch('getOldVersionData')
    },
    //ExpenseData
    //MontlyExpenses
    setExpenses({ commit }, payload) {
      commit("setExpenses", payload);
      // dispatch('getOldVersionData')
    },
    //Applicant relationship
    setApplicantRelationship({ commit }, payload) {
      commit("setApplicantRelationship", payload);
      // dispatch('getOldVersionData')
    },
    //Number of dependents
    setNumberOfDependent({ commit }, payload) {
      commit("setNumberOfDependent", payload);
      // dispatch('getOldVersionData')
    },
    //Assets
    setAssets({ commit }, payload) {
      commit("setAssets", payload);
      // dispatch('getOldVersionData')
    },
    setSavingsUpdated({ commit }, payload) {
      commit("setSavingsUpdated", payload);
      // dispatch('getOldVersionData')
    },
    setAssetProperty({ commit }, payload) {
      commit("setAssetProperty", payload);
      // dispatch('getOldVersionData')
    },
    //Liability
    setLiability({ commit }, payload) {
      commit("setLiability", payload);
      // dispatch('getOldVersionData')
    },
    setCreditCardUpdated({ commit }, payload) {
      commit("setCreditCardUpdated", payload);
      // dispatch('getOldVersionData')
    },

    //Validations
    setValidation({ commit }, payload) {
      commit("setValidation", payload);
    },
    checkValidation({ commit }, payload) {
      commit("checkValidation", payload);
    },

    //Update application status
    async updateApplicantStatus(storeState, payload) {
      let data = {
        status: payload.status,
      };

      if (payload.reason) {
        data["crm_reason"] = payload.reason;
      }
      if (payload.text) {
        data["text"] = payload.text;
      }
      await _put(
        `${APP_URL}update-application-status?applicationId=${payload.appId}`,
        data
      );
    },

    //Extra APIs
    async fetchStampDuty(storeState, payload) {
      const res = await _post(`${SERVER_URL_2}find-stamp-duty`, payload);
      const { result } = res.data;
      return result ? result : "0";
    },
    async fetchZoneByPostal(storeState, payload) {
      const res = await _get(`${SERVER_URL_2}post_code?post_code=${payload}`);
      const { result } = res.data;
      return {
        zone: result?.sp_category
          ? result?.sp_category.replace("-", "_").toLowerCase()
          : "",
        premium: result.premium ? result.premium : false,
        category: result.category ? result.category : "",
        high_density_area: result.high_density_area
          ? result.high_density_area == "true"
            ? true
            : false
          : false,
      };
    },
    async runPriceFinder({ state, commit }) {
      let payload = {
        incoming_properties: state.buyingProperty,
        outgoing_properties: state.sellingProperty,
      };
      const res = await _post(
        `${APP_URL}run-pricefinder-for-properties`,
        payload
      );
      commit("setPricefinderProperty", res.data);
    },
    setOldApplication({ commit }, payload) {
      commit("setOldApplication", payload);
    },
    async getOldVersionData({ rootState, commit, state, getters }, payload) {
      const { fundsToComplete, summaryData, ratesData } = rootState.summary;
      // endDebtData, netSales, mortgagePayout,repaymentData
      //Update the Setupfee, Broker fee, Caps loan rate percent
      ratesData.setup_fee = ratesData.setup_fee ? ratesData.setup_fee / 100 : 0;
      ratesData.broker_fee = ratesData.broker_fee
        ? ratesData.broker_fee / 100
        : 0;
      ratesData.cap_loan_rate = ratesData.cap_loan_rate
        ? ratesData.cap_loan_rate / 100
        : 0;
      ratesData.valuation_fee_by_brigit = ratesData.valuation_fee_by_brigit
        ? ratesData.valuation_fee_by_brigit
        : 0;

      // const { credit_history_review,
      //     summary_notes,
      //     property_notes,
      //     income_review,
      //     expense_review,
      //     asset_review,
      //     liability_review } = rootState.loanDescription
      // let descriptionData = {
      //     credit_history_review: credit_history_review,
      //     summary_notes: summary_notes,
      //     property_notes: property_notes,
      //     income_review: income_review,
      //     expense_review: expense_review,
      //     asset_review: asset_review,
      //     liability_review: liability_review,
      // }
      fundsToComplete.settlement_charge = fundsToComplete.settlement_charge
        ? fundsToComplete.settlement_charge.toString()
        : "0";
      fundsToComplete.funds_to_complete = fundsToComplete.funds_to_complete
        ? fundsToComplete.funds_to_complete.toString()
        : "0";
      summaryData.loan_term = summaryData.loan_term
        ? summaryData.loan_term.toString()
        : "0";
      //Updated values from the application
      // let updatedValues = {
      //     //Summary part
      //     setup_fee: summaryData.setup_fee ? summaryData.setup_fee / 100 : 0,
      //     broker_fee: summaryData.broker_fee ? summaryData.broker_fee / 100 : 0,
      //     cap_loan_rate: summaryData.cap_loan_rate ? summaryData.cap_loan_rate / 100 : 0,
      //     valuation_fee: summaryData.valuation_fee ? summaryData.valuation_fee : 0,
      //     deposit_percent: summaryData.deposit_percent ? summaryData.deposit_percent : 0,
      //     //Net sales proceeds
      //     savings_post: netSales.savings_post ? netSales.savings_post : 0,
      //     //Property rates
      //     interest_free_term: ratesData.interest_free_term ? ratesData.interest_free_term : 0,
      //     loan_term: ratesData.loan_term ? ratesData.loan_term.toString() : "6",
      //     //Liability payment
      //     residual_loan_amount: repaymentData.residual_loan_amount ? Number(repaymentData.residual_loan_amount) : 0,
      //     service_rate: repaymentData.service_rate ? repaymentData.service_rate : 0,
      //     implied_loan_term: repaymentData.implied_loan_term ? repaymentData.implied_loan_term.toString() : "0",
      //     loan_type: repaymentData.loan_type ? repaymentData.loan_type : "",
      // }
      // let updatedValueArr = [];
      // updatedValueArr.push(updatedValues)
      let newObj = {
        ...payload,
        // //Buying
        // incoming_properties: state.buyingProperty ? state.buyingProperty : [],
        // properties_intend_to_buy: state.buyingProperty ? state.buyingProperty.length : 0,
        // //Selling
        // outgoing_properties: state.sellingProperty ? state.sellingProperty : [],
        // properties_intend_to_sell: state.sellingProperty ? state.sellingProperty.length : 0,
        // //Income
        // salaried: [
        //     ...state.fullTimeIncome.applicant_1, ...state.fullTimeIncome.applicant_2,
        //     ...state.partTimeIncome.applicant_1, ...state.partTimeIncome.applicant_2,
        //     ...state.casualIncome.applicant_1, ...state.casualIncome.applicant_2,
        // ],
        // self_employed: [...state.selfIncome.applicant_1, ...state.selfIncome.applicant_2],
        additional_income: [
          ...state.additionalIncome.applicant_1,
          ...state.additionalIncome.applicant_2,
        ],
        // //Expenses
        expenses: getters.expenseFinalData ? getters.expenseFinalData : [],
        no_of_dependents: state.no_of_dependents ? state.no_of_dependents : "0",
        applicant_1_relationship: state.applicant_1_relationship
          ? state.applicant_1_relationship
          : "",
        applicant_2_relationship: state.applicant_2_relationship
          ? state.applicant_2_relationship
          : "",
        // //Assets
        // assets: [...getters.assetSavings, ...getters.assetShares, ...getters.assetSuperannuation],
        // assets_properties: [...getters.assetsProperty],
        // liability_cards: [...getters.liabilityCards],
        // liability_vechicles: [...getters.liabilityVehicles],
        // liability_others: [...getters.liabilityOthers],
        // broker_fee: 0,
        // default_interest: 0,
        valuation: payload?.calculated_fields?.valuation_fee
          ? payload.calculated_fields.valuation_fee
          : 0,
        deposit: payload?.deposit ? payload.deposit : 0,
        setup_fee: payload?.calculated_fields?.setup_fee
          ? (Number(payload.calculated_fields.setup_fee) * 100).toFixed(3)
          : 0,
        broker_fee: payload?.calculated_fields?.broker_fee
          ? (payload.calculated_fields.broker_fee * 100).toFixed(3)
          : 0,
        qouted_setup_fee: payload?.calculated_fields?.qouted_setup_fee
          ? (
              (Number(payload.calculated_fields.setup_fee) +
                Number(payload.calculated_fields.broker_fee)) *
              100
            ).toFixed(3)
          : 0,
        cap_loan_rate: payload?.calculated_fields?.cap_loan_rate
          ? payload.calculated_fields.cap_loan_rate * 100
          : 0,
        savings_post: payload?.calculated_fields?.savings_post
          ? payload.calculated_fields.savings_post
          : 0,
        total_savings_to_purchase: payload?.calculated_fields
          ?.total_savings_to_purchase
          ? payload.calculated_fields.total_savings_to_purchase
          : 0,
        interest_free_term: payload?.calculated_fields?.interest_free_term
          ? payload?.calculated_fields?.interest_free_term
          : 0,
        loan_term: payload?.calculated_fields?.loan_term
          ? payload?.calculated_fields?.loan_term.toString()
          : "6",
        valuation_fee_by_brigit: payload?.calculated_fields
          ?.valuation_fee_by_brigit
          ? payload?.calculated_fields?.valuation_fee_by_brigit
          : 0,
        // funds_to_complete: { ...fundsToComplete },
        // end_debt_data: { ...endDebtData },
        // net_sales: { ...netSales },
        // mortgage_payout: { ...mortgagePayout },
        // summary_data: { ...summaryData },
        // reviews_data: { ...descriptionData },
        // ...settlementData,
        legal_disbursements: payload.legal_disbursements
          ? payload.legal_disbursements
          : 0,
        land_titles_office_reg_fee: payload.land_titles_office_reg_fee
          ? payload.land_titles_office_reg_fee
          : 0,
        settlement_fees: payload.settlement_fees ? payload.settlement_fees : 0,
      };
      delete newObj.summary;
      (newObj.applicant_2_dependents = payload.applicant_2_dependents
        ? payload.applicant_2_dependents
        : "0"),
        (newObj.applicant_2_relationship = payload.applicant_2_relationship
          ? payload.applicant_2_relationship
          : "0"),
        //Get liability values for hem calculator
        // const applicant_1_credit_card = newObj.liability_cards.filter(item => item.applicant_type == 'primary')
        // const applicant_1_other_mortgage = newObj.assets_properties.filter(item => item.applicant_type == 'primary');
        // const applicant_1_other_loan = newObj.liability_others.filter(item => item.applicant_type == 'primary');
        //API to call calculate the calulated fields
        // const payload = {
        //     incoming_properties: state.buyingProperty ? state.buyingProperty : [],
        //     outgoing_properties: state.sellingProperty ? state.sellingProperty : [],
        //     applicant_details: [...state.selectedApplication.applicant_details],
        //     broker_fee_inc_gst: state.selectedApplication.broker_fee_inc_gst ? Number(state.selectedApplication.broker_fee_inc_gst) : 0,
        //     total_savings_to_purchase: state?.selectedApplication?.additional_funds.add_fund_amount ? Number(state?.selectedApplication?.additional_funds.add_fund_amount) : 0,
        //     initial_amount_financed: 0,
        //     savings_end_debt: 0,
        //     add_funds_requested: state?.selectedApplication.additional_fund ? Number(state?.selectedApplication?.additional_fund) : 0,
        //     constants: [...updatedValueArr],
        //     hem_req: {
        //         applicant_1_annual_income: 0,
        //         applicant_2_annual_income: 0,
        //         applicant_1_age: newObj.number_of_applicants > 0 ? newObj.applicant_details[0]?.age : 0,
        //         applicant_2_age: newObj.number_of_applicants > 1 ? newObj.applicant_details[1]?.age : 0,
        //         applicant_1_relationship: newObj?.applicant_1_relationship ? newObj?.applicant_1_relationship : '',
        //         no_of_dependents: newObj.no_of_dependents ? newObj.no_of_dependents : "0",
        //         savings_end_debt: 0,
        //         current_mortgage: 0,
        //         ip_deposit_amount: newObj.incoming_properties[0] ? newObj.incoming_properties[0].deposit_amount : 0,
        //         assets_properties: applicant_1_other_mortgage ? applicant_1_other_mortgage : [],
        //         liability_cards: applicant_1_credit_card ? applicant_1_credit_card : [],
        //         liability_others: applicant_1_other_loan ? applicant_1_other_loan : []
        //     }
        // }
        // const result = await _post(SERVER_URL_2 + 'service-calculator-version', payload)
        // let data = result.data
        // let hemObj = data && data.hem ? { ...data.hem[0], ...data.hem[1] } : {}
        // delete data.hem
        // data = { ...data, ...hemObj }
        // newObj.calculated_fields = {
        //     ...newObj.calculated_fields,
        //     ...data,
        //     end_debt: data.end_debt ? Number(data.end_debt) : 0,
        // }
        commit("setOldVersionData", newObj);
      return newObj;
    },
    setAssetsEmpty({ commit }) {
      commit("setAssetsEmpty");
    },
  },
};
