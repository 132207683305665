<template>
  <div class="std-padding-rv">
    <div class="flex align-items-center justify-content-between">
      <h1 class="module-title">Tasks</h1>
      <div class="flex">
        <div class="flex align-items-center" v-if="selected != 'My tasks'">
          <div class="dashboard-option">Assigned to</div>
          <Dropdown
            width="150px"
            class="ml-3"
            :items="assigneeList"
            optionLabel="assignedTo"
            @change="onChangeassignee"
          />
        </div>
        <div class="flex align-items-center ml-3">
          <div class="dashboard-option">Tasks</div>
          <Dropdown
            width="150px"
            class="ml-3"
            :items="items"
            :value="selected"
            @change="onChange"
          />
        </div>
      </div>
    </div>
    <Tasks
      :selectedTask="selected"
      class="mt-5"
      :title="selected"
      :assignedTo="assignedToId"
      :assignedToType="assignedToType"
    />
  </div>
</template>

<script>
import Tasks from "./TasksList.vue";
import { _get } from "../../../services/crudService";

export default {
  name: "task",
  components: {
    Tasks,
  },
  data() {
    return {
      items: ["My tasks", "New tasks", "All tasks"],
      selected: "My tasks",
      assigneeList: [],
      assignedToId: "",
      assignedToType: "",
    };
  },

  methods: {
    onChange(value) {
      return (this.selected = value);
    },
    onChangeassignee(value) {
      this.assignedToId = value.assingedToId;
      this.assignedToType = value.userType;
    },
  },
  async created() {
    const result = await _get(
      "https://api.fl0.com/techlend/dev/all_users/flows/get-task-assigned-to-api"
    );
    if (result && result.data && result.data.length) {
      this.assigneeList = result.data;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";
::v-deep .p-dropdown {
  background: unset !important;
}
.dashboard-option {
  color: rgba(2, 0, 56, 0.3);
  font-size: 1rem;
  font-weight: 600;
  font-family: Gordita-medium;
}
</style>
