<template>
  <Dialog
    :draggable="false"
    :visible="show"
    :modal="show"
    :style="{ width: '650px' }"
  >
    <h3 class="sec-color-txt hk-sb">{{ title }}</h3>
    <template #header> </template>
    <div class="modal-content">
      <!-- <component
          @selectGroup="groupSelection"
          @nxtBtn="changeStep"
          :selectedGroup="selectedGroup"
          :close="close"
          :permissionList="permissions"
          @changePermission="changePermission"
          @getGroupPermissions="getGroupPermissions"
          :groupPermissions="groupPermissions"
          :btnText="btnText"
          :is="component"
          :userId="userId"
          @userCreated="userCreated"
        >
        </component> -->
      <keep-alive>
        <component
          @nxtBtn="changeStep"
          @selectGroup="groupSelection"
          :close="close"
          :btnText="btnText"
          :is="component"
          @userCreated="userCreated"
        >
        </component>
      </keep-alive>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "primevue/dialog";
import AddUser from "./AddUser.vue";
import AddUserFour from "./AddUserFour/Index.vue";
import AddUserThree from "./AddUserThree.vue";
import AddUserTwo from "./AddUserTwo/Index.vue";
import { _get } from "../../../../../../services/crudService";
import { SERVER_URL } from "../../../../../../../constants";
import { mapActions } from "vuex";

export default {
  props: ["show", "title", "btnText"],
  components: {
    Dialog,
    AddUser,
    AddUserTwo,
    AddUserThree,
    AddUserFour,
  },
  data() {
    return {
      collapsed: true,
      component: "AddUser",
      selectedGroup: "",
      permissions: {},
      groupPermissions: [],
      test: {},
    };
  },

  methods: {
    ...mapActions({
      setGroupPermissionList: "user/setGroupPermissionList",
      setPermissions: "user/setPermissions",
      setLoadingPermissions: "user/setLoadingPermissions",
    }),
    close() {
      this.$emit("close");
    },
    changeStep(value) {
      this.component = value;
    },
    userCreated() {
      this.$emit("save");
    },
    async groupSelection(value) {
      this.selectedGroup = value;

      try {
        this.setLoadingPermissions(true);
        const response = await _get(
          SERVER_URL + `get-group-by-id?id=${this.selectedGroup}`,
          { type: "group" }
        );

        let taskTypePermissions = [];
        let permissions = [];
        let filteredTaskType = [];

        if (response) {
          this.setPermissions(response.data.result[0]);

          taskTypePermissions = response.data.result[0].taskPermissions;

          response.data.result.map((list) => {
            const values = Object.keys(list);

            values.map((key) => {
              if (list[key] === true) {
                permissions.push(key);
              }
            });
          });

          taskTypePermissions.map((list) => {
            const values = Object.keys(list);

            values.map((key) => {
              if (list[key] === true) {
                filteredTaskType.push(key);
              }
            });
          });

          let data = [...permissions, { taskPermissions: filteredTaskType }];

          this.test = data;

          this.setGroupPermissionList(data);

          this.setLoadingPermissions(false);
        }
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },

    toggle() {
      this.collapsed = !this.collapsed;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.title-wrapper {
  border-bottom: 1px solid $secondary-color;
  padding-bottom: 0.625rem;
}

.title {
  font-size: 1.75rem;
  font-family: Gordita-bold;
  margin-left: 0.625rem;
  color: #020038;
  margin-left: -1rem;
}

::v-deep .col-9,
.col-3 {
  padding-top: 0;
  padding-bottom: 0;
}

.mr-b {
  margin-bottom: 1.875rem;
}

.mr-b-5px {
  margin-bottom: 5px;
}

.form-inputs {
  margin-top: 2.5rem;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.box {
  background: #ffffff;
  border: 1px solid rgba(2, 0, 56, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 1.25rem;
}

p {
  margin: 0;
}
.lt-txt {
  font-weight: 600;
}
.lt-text {
  font-weight: 600;
}
.items-center {
  margin-top: -2rem;
}
.title-wrapper[data-v-6a01628b] {
  border-bottom: none !important;
  padding-bottom: 0.625rem;
}
.mt-5 {
  background: #fff;
  margin-top: -2rem !important;
}
.flex-content {
  gap: 3rem;
}
.sec-color-text {
  font-size: 1.45rem;
  font-family: Gordita-bold;
  color: #020038;
  font-weight: 600;
}
.back {
  font-weight: 700;
  color: rgba(2, 0, 56, 0.4);
  font-size: 1rem;
}
</style>
