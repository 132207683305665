<template>
  <div class="std-padding-rv">
    <div class="flex align-items-center justify-content-between">
      <h1 class="module-title">Dashboard</h1>
      <div class="flex align-items-center">
        <!-- <div class="dashboard-option">Overview</div>
        <Dropdown
          placeholder="Current month"
          width="220px"
          class="ml-3"
          :items="items"
        />
        <Datefilter
          icon="calendar-icon"
          placeholder="Filter by date"
          class="ml-3"
        /> -->

        <Datefilter
          class="mr-2 full-width datefilter-component"
          numberOfMonths="2"
          @filter="getFilteredData($event)"
        />
      </div>
    </div>
    <Dashboard
      :startDateFilter="startDate"
      :endDateFilter="endDate"
      class="mt-5"
    />
  </div>
</template>

<script>
import Dashboard from "./DashboardPage.vue";
import Datefilter from "./Datefilter.vue";

export default {
  name: "index",
  components: {
    Dashboard,
    Datefilter,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
    };
  },
  methods: {
    async getFilteredData(event) {
      if (event) {
        this.startDate = event[0].toISOString();
        this.endDate = event[1].toISOString();
      } else {
        this.getApp(1, 10);
      }
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";
::v-deep .p-dropdown {
  background: unset !important;
}
::v-deep .datepicker-layout {
  background: unset !important;
}
::v-deep .datepicker-layout .p-inputtext {
  background: unset !important;
}
.dashboard-option {
  color: rgba(2, 0, 56, 0.3);
  font-size: 18px;
  font-weight: 600;
  font-family: Gordita-bold;
}
</style>
