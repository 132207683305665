<template>
  <div class="position-relative" v-click-outside="closeShowList">
    <span class="p-input-icon-left">
      <i class="pi pi-filter" @click="showList = !showList" />
      <InputText
        @click="showList = !showList"
        type="text"
        placeholder="Filter by loan amount"
        readonly
      />
    </span>

    <Card v-if="showList" class="filter-style">
      <template #content>
        <div class="option-title">From</div>
        <InputWithIcon
          @onInput="getFormData($event, 'from')"
          placeholder="Enter amount"
          :icon="dollar"
          :format="true"
          type="number"
        />
        <div class="mt-3 option-title">To</div>
        <InputWithIcon
          @onInput="getFormData($event, 'to')"
          placeholder="Enter amount"
          :icon="dollar"
          :format="true"
          type="number"
        />
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Dollar from "@/assets/dollar.svg";

export default {
  name: "Filter",
  components: {
    Card,
    InputText,
  },
  data() {
    return {
      showList: false,
      dollar: Dollar,
      formData: {
        from: "",
        to: "",
      },
      statusList: [
        { id: 1, label: "Open", value: false },
        {
          id: 2,
          label: "Repaid",
          value: true,
        },
        { id: 3, label: "Dropped off", value: false },
        {
          id: 4,
          label: "Withdrawn",
          value: true,
        },
        { id: 5, label: "Rejected", value: false },
      ],
      loanList: [
        { id: 1, label: "Application submission", value: false },
        {
          id: 2,
          label: "Conditional offer",
          value: true,
        },
        { id: 3, label: "Supporting documents", value: false },
        {
          id: 4,
          label: "Unconditional offer",
          value: true,
        },
        { id: 5, label: "Loan settlement", value: false },
      ],
    };
  },
  methods: {
    closeShowList() {
      this.showList = false;
      this.$emit("getFilterValue", {});
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };

      if (this.formData.to && this.formData.from) {
        this.$emit("getFilterValue", this.formData);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.p-card {
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  min-height: 242px !important;
}

::v-deep .p-card-content {
  font-size: 15px !important;
}
.filter-style {
  position: absolute !important;
  z-index: 10;
  width: 210px;
}
.option-title {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 600;
  font-size: 14px;
}

// :deep .input-field {
//   padding: 0 0 0 0.5rem !important;
// }

.filter-style :deep .p-inputtext {
  padding-left: 0.7rem !important;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ced4da !important;
  border-color: #ced4da !important;
}
::v-deep .p-card-body {
  padding: 1.225rem !important;
}
</style>