<template>
  <div
    :class="inValid && 'invalid-field'"
    class="datepicker-layout flex align-items-center"
  >
    <i class="pi pi-calendar ml-2" />
    <Calendar
      v-model="editValue"
      :numberOfMonths="numberOfMonths"
      :placeholder="placeholder"
      :minDate="minDate"
      :maxDate="maxDate"
      :disabled="disable"
      :manualInput="false"
      dateFormat="dd/mm/yy"
      :monthNavigator="true"
      :yearNavigator="true"
      :yearRange="yearRange"
      @date-select="onChange"
      :style="{
        height: `${height}`,
        width: `${width}`,
        border: `1px solid ${color} !important`,
        color: `${color}`,
      }"
    >
    </Calendar>
  </div>
</template>

<script>
import Calendar from "primevue/calendar";
import moment from "moment";

export default {
  name: "Datefilter",
  components: {
    Calendar,
  },
  props: [
    "numberOfMonths",
    "height",
    "width",
    "placeholder",
    "required",
    "label",
    "value",
    "maxDate",
    "maxYear",
    "minYear",
    "minDate",
    "minYer",
    "yearRange",
    "disable",
    "inValid",
  ],
  data() {
    return {
      editValue: "",
      displayValue: "",
      isOpen: true,
      menu: false,
    };
  },
  methods: {
    onClear() {
      this.editValue = "";
    },
    onChange(value) {
      let dateValue = moment(value).format("YYYY-MM-DD");
      this.$emit("change", new Date(dateValue).toISOString());
      // this.$emit("change", new Date(value).toISOString());
    },
  },
  mounted() {
    this.editValue = this.value ? new Date(this.value) : null;
    // this.minYear = new Date().getFullYear()
    // this.minDate = new Date()
  },
};
</script>

<style lang="scss">
@import "@/styles/global.scss";
.invalid-field {
  border: 1px solid #f44336 !important;
}

.p-datepicker table td {
  padding: 0 !important;
}
.p-datepicker table td > span {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 0 !important;
  font-size: 14px !important;
}
.p-datepicker table th > span {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 4px !important;
  font-size: 14px !important;
}

.datepicker-layout .p-inputtext {
  padding-left: 0.5rem !important;
}

.p-datepicker table td > span.p-highlight {
  background: #fdf0e8;
}
.p-datepicker .p-datepicker-header {
  border-bottom: unset !important;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: unset !important;
}
.p-datepicker {
  padding: 1rem 2rem !important;
}
.datepicker-layout {
  border: 1px solid rgba(100, 97, 170, 0.3);
  border-radius: 4px !important;
  .p-inputtext {
    border: unset !important;
    font-family: $font-family !important;
  }
}
.p-calendar {
  width: 100% !important;
}
.p-monthpicker-month {
  border: unset !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  border: unset !important;
  background: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  border: unset !important;
  background: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  box-shadow: unset !important;
}

.p-datepicker table td.p-datepicker-today > span {
  background: #a4bfe0 !important;
}
</style>
