<template>
  <div class="mr-top card-box">
    <Panel :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>
      <template #header>
        <div @click="toggle" class="sub-title full-width pointer">Others</div>
      </template>
      <!-- v-if="transOutCount > 0" -->
      <div
        @click="openTransactionModal"
        class="
          flex
          align-items-end
          justify-content-end
          mt-3
          pointer
          hk-sb
          lt-txt-color
        "
      >
        <span class="mr-2">+</span> New other charges
      </div>
      <Spinner v-if="isloading" class="flex justify-content-between mt-3" />
      <DataTable
        id="inTransaction"
        class="mt-2"
        :rows="5"
        dataKey="id"
        :value="statements"
        v-else
      >
        <template #empty> No data found. </template>

        <Column field="list_no" header="No."> </Column>
        <Column field="type" header="Type">
          <template #body="slotProps">
            {{
              slotProps.data.type ? removeUnderscore(slotProps.data.type) : "-"
            }}
          </template>
        </Column>
        <Column field="amount" header="Amount">
          <template #body="slotProps">
            {{
              slotProps.data.amount ? formatAmount(slotProps.data.amount) : "-"
            }}
          </template>
        </Column>
        <Column field="transactionDate" header="Transaction Date">
          <template #body="slotProps">
            {{
              slotProps.data.transactionDate
                ? formatDate(slotProps.data.transactionDate)
                : "-"
            }}
          </template>
        </Column>
        <Column field="status" header="Status">
          <template #body="slotProps">
            <div>
              <a
                class="color-highlight pointer"
                @click="openTransactionModal(slotProps.data)"
                >{{ slotProps.data.status }}</a
              >
            </div>
          </template>
        </Column>
        <Column field="notes" header="Notes">
          <template #body="slotProps">
            {{ slotProps.data?.notes || "-" }}
          </template>
        </Column>
        <Column field="created_at" header="Date Created">
          <template #body="slotProps">
            {{
              slotProps.data.created_at
                ? formatDate(slotProps.data.created_at)
                : "-"
            }}
          </template>
        </Column>
        <Column field="createdBy" header="Created by">
          <template #body="slotProps">
            {{ slotProps.data.createdBy || "-" }}
          </template>
        </Column>
      </DataTable>
    </Panel>
    <Transaction
      v-if="showTransactionModal"
      @save="refreshKey++"
      transaction_type="others"
      :title="selectedData ? 'Edit transaction in' : 'New transaction in'"
      :show="showTransactionModal"
      @close="showTransactionModal = false"
      :selectedData="selectedData"
    />
  </div>
</template>

<script>
import Panel from "primevue/panel";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Transaction from "./OtherTransactions.vue";
import { _get } from "../../../../../services/crudService";
import { APP_URL } from "../../../../../../constants";
import moment from "moment";

export default {
  components: { Panel, DataTable, Column, Transaction },
  props: ["key"],
  data() {
    return {
      collapsed: false,
      showTransactionModal: false,
      isloading: false,
      statements: [],
      selectedData: null,
      refreshKey: 10,
      transOutCount: 0,
    };
  },
  watch: {
    key() {
      this.getList(true);
    },
    refreshKey() {
      this.clearList();
      this.getList(true);
    },
  },
  methods: {
    removeUnderscore(value) {
      return (
        value.charAt(0).toUpperCase() + value.slice(1).replaceAll("_", " ")
      );
    },
    toggle() {
      this.collapsed = !this.collapsed;
    },
    openTransactionModal(data) {
      if (data.Id) {
        console.log(data);

        this.selectedData = data;
      } else {
        this.selectedData = null;
      }
      this.showTransactionModal = true;
    },
    formatAmount(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      return formatter.format(value);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    clearList() {
      this.statements = [];
    },
    async getList(reRender) {
      try {
        this.isloading = true;

        const response = await _get(
          APP_URL +
            `get_transaction_report?application_id=${this.$route.params.id}&transactionType=others`
        );

        // const getDischargeReq = await _get(
        //   APP_URL + `discharge_request?app_id=${this.$route.params.id}`
        // );

        if (response) {
          this.isloading = false;
          // this.statements = response.data.result
          let idx = 0;

          response.data.result &&
            response.data.result.map((list) => {
              // if (list.status === "completed") {
              this.statements.push({ list_no: idx + 1, ...list });
              idx++;
              // }
            });
        }

        // if (getDischargeReq) {
        //   this.isloading = false;
        //   // this.statements = response.data.result
        //   let index = this.statements.length;
        //   getDischargeReq.data.result &&
        //     getDischargeReq.data.result.map((list) => {
        //       if (list.status === "completed") {
        //         this.statements.push({
        //           list_no: index + 1,
        //           Id: list.Id,
        //           amount: list.cash_repayment_amount,
        //           status: list.status,
        //           createdBy:
        //             list.applicant_first_name + " " + list.applicant_last_name,
        //           transactionDate: list.cash_repayment_date,
        //         });
        //         index++;
        //       }
        //     });

        //   console.log(this.statements);
        // }
        reRender && this.$emit("transactionInCount", this.statements.length);
      } catch (error) {
        this.isloading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },
  async created() {
    this.renderKey = 1;
    this.getList(false);
    const response = await _get(
      APP_URL +
        `get_transaction_report?application_id=${this.$route.params.id}&transactionType=others`
    );
    this.transOutCount = response?.data?.result
      ? response.data.result.length
      : 0;
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid #02003826 !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
  padding: 1rem 1rem 0.5rem 1rem !important;
}

::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  //   border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
.color-highlight {
  color: $primary-color !important;
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.color-highlight {
  color: $primary-color !important;
}

.card-box {
  background: #ffffff;
  padding: 20px;
  border: 1px solid rgba(2, 0, 56, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
}

.mr-top {
  margin-top: 2.5rem;
}
</style>
