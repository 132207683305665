<template>
  <div>
    <Panel :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>
      <template #header>
        <div class="sec-color-text hk-sb">Initial LVR %</div>
      </template>
      <div class="content-wrapper flex">
        <div class="col-10">
          <div class="flex align-items-center">
            <p class="col-8 lt-txt-color heading hk-sb">Parameter</p>
            <div class="col-4 lt-txt-color heading hk-sb">Value</div>
          </div>
          <div class="flex align-items-center">
            <p class="label col-3">Residential LVR</p>
            <div class="col-5">
              <Dropdown
                width="100%"
                value="Less than or equal to"
                :items="dropDownValue"
              />
            </div>
            <div class="col-4">
              <InputWithIcon
                placeholder="500,000"
                width="100%"
                :icon="perchentage"
              />
            </div>
          </div>

          <div class="flex align-items-center">
            <p class="label col-3">Commercial LVR</p>
            <div class="col-5">
              <Dropdown
                width="100%"
                value="Less than or equal to"
                :items="dropDownValue"
              />
            </div>
            <div class="col-4">
              <InputWithIcon
                placeholder="500,000"
                width="100%"
                :icon="perchentage"
              />
            </div>
          </div>

          <div class="flex align-items-center">
            <p class="label col-3">Rural residential LVR</p>
            <div class="col-5">
              <Dropdown
                width="100%"
                value="Less than or equal to"
                :items="dropDownValue"
              />
            </div>
            <div class="col-4">
              <InputWithIcon
                placeholder="500,000"
                width="100%"
                :icon="perchentage"
              />
            </div>
          </div>

          <div class="flex align-items-center">
            <p class="label col-3">Vacant land LVR</p>
            <div class="col-5">
              <Dropdown
                width="100%"
                value="Less than or equal to"
                :items="dropDownValue"
              />
            </div>
            <div class="col-4">
              <InputWithIcon
                placeholder="500,000"
                width="100%"
                :icon="perchentage"
              />
            </div>
          </div>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import Panel from "primevue/panel";
import Perchentage from "@/assets/perchentage.svg";

export default {
  components: { Panel },
  data() {
    return {
      collapsed: true,
      perchentage: Perchentage,
      dropDownValue: ["Less than or equal to"],
    };
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid #02003826 !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

.heading {
  font-size: 16px;
}

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

.highlight-fields {
  .label {
    font-family: Gordita-bold !important;
  }
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

p {
  margin: 0;
}

:deep .p-inputtext {
  margin-top: 0 !important;
}

:deep .icon-container img {
  margin-bottom: 0 !important;
}

:deep .col-8,
.col-3 {
  padding-left: 0 !important;
}

.mr-b-5px {
  margin-bottom: 5px;
}

.mr-top {
  margin-top: 2rem;
}

.mr-top-10px {
  margin-top: 10px;
}

.flex-gap {
  gap: 0.625rem;
}

.label {
  font-size: 15px;
}
.align-items {
  gap: 20rem;
  color: rgba(2, 0, 56, 0.3);
  font-family: Gordita-medium;
  font-weight: 600;
}
.label-content {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
  white-space: nowrap;
  margin-left: -1rem;
}
.call {
  gap: 3rem;
}
.sec-color-text {
  font-size: 20px;
  font-family: Gordita-bold;
  color: #020038;
  font-weight: 600;
}
.flex-content-item {
  gap: 6rem;
}

::v-deep .p-float-label {
  margin: 0 !important;
}
</style>
