<template>
  <div class="pl-4 pr-4 pb-4">
    <Card>
      <template #content>
        <Spinner v-if="isloading" class="flex justify-content-between" />
        <div v-else>
          <div class="flex justify-content-between">
            <div class="module-sub-title">Current application progress</div>
            <div class="flex align-items-center">
              <div class="pipeline flex align-items-center mr-4">
                <div class="mr-3">Deal owner</div>
                <TextField
                  width="100%"
                  type="text"
                  v-model="searchValue"
                  @onInput="getSearchValue($event)"
                  :placeholder="'Deal owner'"
                />
              </div>
              <div class="pipeline flex align-items-center mr-4">
                <div class="mr-3">Pipeline</div>
                <!-- <TextField
                  width="100%"
                  type="text"
                  :disable="true"
                  :placeholder="stageName"
                /> -->
                <!-- <Dropdown
                  width="160px"
                  class="ml-3"
                  :items="pipelineOpts"
                  :value="selectedPipelineOpt"
                  @change="pipelineOptChange"
                  placeholder="-select-"
                /> -->
                <div class="pipeline-opts-wrapper">
                  <div
                    @click="openPipelineOpt"
                    class="
                      current-stage
                      flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <div :key="refreshKey" class="text">
                      {{ displaySelectedPipeline() }}
                    </div>
                    <i class="pi pi-angle-down"></i>
                  </div>
                  <div
                    v-click-outside="clickOutsidePipeline"
                    v-if="displayPipelineOpt"
                    class="opts-wrapper"
                  >
                    <div
                      v-for="(opt, idx) of pipelineOpts"
                      :key="idx"
                      :class="renderActiveClassPipeline(opt.value, opt.disable)"
                      @click="changeStagePipeline(opt.value, opt.disable)"
                    >
                      {{ opt.name }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="pipeline flex align-items-center">
                <div class="mr-3">Stage</div>

                <div class="pipeline-opts-wrapper">
                  <div
                    @click="openOptList"
                    class="
                      current-stage
                      flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <div :key="refreshKey" class="text">
                      {{ displaySelectedStage() }}
                    </div>
                    <i class="pi pi-angle-down"></i>
                  </div>
                  <div
                    v-click-outside="clickOutside"
                    v-if="displayOptList"
                    class="opts-wrapper"
                  >
                    <div
                      v-for="(opt, idx) of stageListOpts"
                      :key="idx"
                      :class="renderActiveClass(opt.value, opt.disable)"
                      @click="changeStage(opt.value, opt.disable)"
                    >
                      {{ opt.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              flex
              align-items-center
              justify-content-between
              mt-5
              overflow-x
            "
          >
            <div class="flex align-items-center">
              <img v-if="submission" src="@/assets/app_sub_active.svg" />
              <img v-else src="@/assets/app_sub_icon.svg" />
              <div
                class="ml-2 option-width"
                :class="
                  submission ? 'completed-status' : 'not-completed-status'
                "
              >
                Application submission
              </div>
            </div>
            <div class="flex align-items-center ml-5">
              <img
                v-if="conditionalOff"
                src="@/assets/conditional_off_active.svg"
              />
              <img v-else src="@/assets/conditonal_off_icon.svg" />
              <div
                class="ml-2 option-width"
                :class="
                  conditionalOff ? 'completed-status' : 'not-completed-status'
                "
              >
                Conditional offer
              </div>
            </div>
            <div class="flex align-items-center ml-5">
              <img
                v-if="supportingDoc"
                src="@/assets/supporting_doc_active.svg"
              />
              <img v-else src="@/assets/supporting_doc_icon.svg" />
              <div
                class="ml-2 option-width"
                :class="
                  supportingDoc ? 'completed-status' : 'not-completed-status'
                "
              >
                Supporting documents
              </div>
            </div>
            <div class="flex align-items-center ml-5">
              <img
                v-if="unConditionalOff"
                src="@/assets/uncondtional_off_active.svg"
              />
              <img v-else src="@/assets/unconditonal_off_icon.svg" />
              <div
                class="ml-2 option-width"
                :class="
                  unConditionalOff ? 'completed-status' : 'not-completed-status'
                "
              >
                Unconditional offer
              </div>
            </div>
            <div class="flex align-items-center ml-5">
              <img
                v-if="loanSettlement"
                src="@/assets/loan_settle_active.svg"
              />
              <img v-else src="@/assets/loan_settle_icon.svg" />
              <div
                class="ml-2 option-width"
                :class="
                  loanSettlement ? 'completed-status' : 'not-completed-status'
                "
              >
                Loan settlement
              </div>
            </div>
            <div class="flex align-items-center ml-5">
              <img v-if="loanFunded" src="@/assets/loan-funded-active.svg" />
              <img v-else src="@/assets/loan-funded-inactive.svg" />
              <div
                class="ml-2 option-width"
                :class="
                  loanFunded ? 'completed-status' : 'not-completed-status'
                "
              >
                Loan funded
              </div>
            </div>
            <div class="flex align-items-center ml-5">
              <img v-if="loanRepaid" src="@/assets/loan-repaid-active.svg" />
              <img v-else src="@/assets/loan-repaid-inactive.svg" />
              <div
                class="ml-2 option-width"
                :class="
                  loanRepaid ? 'completed-status' : 'not-completed-status'
                "
              >
                Loan repaid
              </div>
            </div>
          </div>
          <!-- <RecentActivity class="mt-4" /> -->
          <Outcomes tabLocation="overview" class="mt-4" />
          <SpecialConditions class="mt-4" />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "primevue/card";
// import RecentActivity from "./RecentActivity.vue"
import Outcomes from "./Outcomes.vue";
import SpecialConditions from "./SpecialConditions.vue";
import { _get, _put } from "../../../../services/crudService";
import { APP_URL, SERVER_URL } from "../../../../../constants";
import { mapActions } from "vuex";

export default {
  name: "Overview",
  components: {
    // RecentActivity,
    SpecialConditions,
    Outcomes,
    Card,
  },
  data() {
    return {
      displayOptList: false,
      displayPipelineOpt: false,
      stageRefreshKey: 10,
      refreshKey: 10,
      selectedStage: "",
      currentStage: "",
      loanDetails: [],
      selectedPipelineOpt: "applications",
      pipelineOpts: [
        {
          name: "Applications",
          value: "applications",
          disable: false,
        },
        {
          name: "Funded",
          value: "funded",
          disable: true,
        },
        {
          name: "Arrears",
          value: "arrears",
          disable: true,
        },
      ],
      stageListOpts: [
        {
          name: "Application in progress",
          value: "application_in_progress",
          disable: false,
        },
        { name: "Assessment ready", value: "assessment_ready", disable: false },
        {
          name: "Workshopping application",
          value: "workshopping_application",
          disable: false,
        },

        {
          name: "Pre-approval",
          value: "pre-approval",
          disable: false,
        },
        {
          name: "Conditional offer",
          value: "conditional_offer",
          disable: true,
        },
        {
          name: "Supporting document",
          value: "supporting_document",
          disable: true,
        },
        {
          name: "Unconditional offer",
          value: "unconditional_offer",
          disable: true,
        },
        {
          name: "Solicitors instructed",
          value: "solicitor_instructed",
          disable: false,
        },
        {
          name: "Document Issued",
          value: "document_issued",
          disable: false,
        },
        {
          name: "Document Received",
          value: "document_received",
          disable: false,
        },
        {
          name: "Settlement Booked",
          value: "settlement_booked",
          disable: false,
        },
        { name: "Loan funded", value: "loan_funded", disable: false },
      ],
      isloading: false,
      appStatus: [],
      submission: false,
      conditionalOff: false,
      supportingDoc: false,
      unConditionalOff: false,
      loanSettlement: false,
      loanFunded: false,
      loanRepaid: false,
      stageName: "",
    };
  },
  methods: {
    ...mapActions({
      reloadAppSummary: "loanOverview/reloadAppSummary",
    }),
    openOptList() {
      this.displayOptList = !this.displayOptList;
    },
    openPipelineOpt() {
      this.displayPipelineOpt = !this.displayPipelineOpt;
    },
    clickOutsidePipeline() {
      this.displayPipelineOpt = false;
    },
    clickOutside() {
      this.displayOptList = false;
    },
    renderActiveClassPipeline(value, disable) {
      let className;
      if (disable) {
        return (className = "disable-list");
      } else {
        className = "list";
      }

      if (value === this.selectedPipelineOpt) {
        return (className = className + " list-active");
      }

      return className;
    },
    displaySelectedPipeline(value) {
      this.pipelineOpts.map((list) => {
        if (this.selectedPipelineOpt === list.value) {
          return (value = list.name);
        }
      });
      return value || "-select-";
    },
    displaySelectedStage() {
      // return "-select-";
      let value = this.currentStage;
      this.stageListOpts.map((list) => {
        if (this.selectedStage === list.value) {
          return (value = list.name);
        }
        if (this.currentStage === list.value) {
          return (value = list.name);
        }
      });
      return value || "-select-";
    },
    changeStage(value, disable) {
      if (disable) return;
      this.selectedStage = value;
      this.displayOptList = false;
      this.refreshKey++;
      this.stageRefreshKey++;
    },
    renderActiveClass(value, disable) {
      let className;
      if (disable) {
        return (className = "disable-list");
      } else {
        className = "list";
      }

      if (value === this.selectedStage) {
        return (className = className + " list-active");
      }
      return className;
    },
    async onChangeStage() {
      try {
        this.isloading = true;
        const response = await _put(
          SERVER_URL +
            `update_application_pipeline_status?id=${this.$route.params.id}&status=${this.appStatus.status}&crm_status=${this.appStatus.crm_status}`
        );

        if (response) {
          this.isloading = false;
          this.currentStage = this.selectedStage;
          this.getAppStatusList();
          this.reloadAppSummary();
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Application moved successfully",
            life: 3000,
          });
        }
      } catch (err) {
        this.isloading = false;
        this.selectedStage = this.appStatus.crm_status;

        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
    },
    async getAppStatusList() {
      try {
        this.isloading = true;
        const getAppStatus = await _get(
          APP_URL + `application_status?id=${this.$route.params.id}`
        );

        if (getAppStatus) {
          this.appStatus = getAppStatus.data.result;
          this.selectedStage = getAppStatus.data.result.crm_status;
          this.isloading = false;
        }
      } catch (error) {
        this.isloading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
      this.stageConditon();
    },
    stageConditon() {
      switch (this.appStatus.crm_status) {
        case "application_in_progress":
          this.stageListOpts[0].disable = false;
          this.stageListOpts[1].disable = false;
          this.stageListOpts[2].disable = true;
          this.stageListOpts[3].disable = true;
          this.stageListOpts[4].disable = true;
          this.stageListOpts[5].disable = true;
          this.stageListOpts[6].disable = true;
          this.stageListOpts[7].disable = true;
          this.stageListOpts[8].disable = true;
          this.stageListOpts[9].disable = true;
          this.stageListOpts[10].disable = true;
          this.stageListOpts[11].disable = true;
          break;
        case "assessment_ready":
          this.stageListOpts[0].disable = true;
          this.stageListOpts[1].disable = false;
          this.stageListOpts[2].disable = false;
          this.stageListOpts[3].disable = true;
          this.stageListOpts[4].disable = true;
          this.stageListOpts[5].disable = true;
          this.stageListOpts[6].disable = true;
          this.stageListOpts[7].disable = true;
          this.stageListOpts[8].disable = true;
          this.stageListOpts[9].disable = true;
          this.stageListOpts[10].disable = true;
          this.stageListOpts[11].disable = true;
          break;
        case "workshopping_application":
          this.stageListOpts[0].disable = true;
          this.stageListOpts[1].disable = true;
          this.stageListOpts[2].disable = false;
          this.stageListOpts[3].disable = false;
          this.stageListOpts[4].disable = true;
          this.stageListOpts[5].disable = true;
          this.stageListOpts[6].disable = true;
          this.stageListOpts[7].disable = true;
          this.stageListOpts[8].disable = true;
          this.stageListOpts[9].disable = true;
          this.stageListOpts[10].disable = true;
          this.stageListOpts[11].disable = true;
          break;
        case "pre-approval":
          this.stageListOpts[0].disable = true;
          this.stageListOpts[1].disable = true;
          this.stageListOpts[2].disable = true;
          this.stageListOpts[3].disable = false;
          this.stageListOpts[4].disable = true;
          this.stageListOpts[5].disable = true;
          this.stageListOpts[6].disable = true;
          this.stageListOpts[7].disable = true;
          this.stageListOpts[8].disable = true;
          this.stageListOpts[9].disable = true;
          this.stageListOpts[10].disable = true;
          this.stageListOpts[11].disable = true;
          break;
        case "solicitor_instructed":
          this.stageListOpts[0].disable = true;
          this.stageListOpts[1].disable = true;
          this.stageListOpts[2].disable = true;
          this.stageListOpts[3].disable = true;
          this.stageListOpts[4].disable = true;
          this.stageListOpts[5].disable = true;
          this.stageListOpts[6].disable = true;
          this.stageListOpts[7].disable = false;
          this.stageListOpts[8].disable = false;
          this.stageListOpts[9].disable = true;
          this.stageListOpts[10].disable = true;
          this.stageListOpts[11].disable = true;
          break;
        case "document_issued":
          this.stageListOpts[0].disable = true;
          this.stageListOpts[1].disable = true;
          this.stageListOpts[2].disable = true;
          this.stageListOpts[3].disable = true;
          this.stageListOpts[4].disable = true;
          this.stageListOpts[5].disable = true;
          this.stageListOpts[6].disable = true;
          this.stageListOpts[7].disable = true;
          this.stageListOpts[8].disable = false;
          this.stageListOpts[9].disable = false;
          this.stageListOpts[10].disable = true;
          this.stageListOpts[11].disable = true;
          break;
        case "document_received":
          this.stageListOpts[0].disable = true;
          this.stageListOpts[1].disable = true;
          this.stageListOpts[2].disable = true;
          this.stageListOpts[3].disable = true;
          this.stageListOpts[4].disable = true;
          this.stageListOpts[5].disable = true;
          this.stageListOpts[6].disable = true;
          this.stageListOpts[7].disable = true;
          this.stageListOpts[8].disable = true;
          this.stageListOpts[9].disable = false;
          this.stageListOpts[10].disable = false;
          this.stageListOpts[11].disable = true;
          break;
        case "settlement_booked":
          this.stageListOpts[0].disable = true;
          this.stageListOpts[1].disable = true;
          this.stageListOpts[2].disable = true;
          this.stageListOpts[3].disable = true;
          this.stageListOpts[4].disable = true;
          this.stageListOpts[5].disable = true;
          this.stageListOpts[6].disable = true;
          this.stageListOpts[7].disable = true;
          this.stageListOpts[8].disable = true;
          this.stageListOpts[9].disable = true;
          this.stageListOpts[10].disable = false;
          this.stageListOpts[11].disable = false;
          break;
        case "loan_funded":
          this.stageListOpts[0].disable = true;
          this.stageListOpts[1].disable = true;
          this.stageListOpts[2].disable = true;
          this.stageListOpts[3].disable = true;
          this.stageListOpts[4].disable = true;
          this.stageListOpts[5].disable = true;
          this.stageListOpts[6].disable = true;
          this.stageListOpts[7].disable = true;
          this.stageListOpts[8].disable = true;
          this.stageListOpts[9].disable = true;
          this.stageListOpts[10].disable = true;
          this.stageListOpts[11].disable = false;
          break;
        default:
          this.stageListOpts[0].disable = true;
          this.stageListOpts[1].disable = true;
          this.stageListOpts[2].disable = true;
          this.stageListOpts[3].disable = true;
          this.stageListOpts[4].disable = true;
          this.stageListOpts[5].disable = true;
          this.stageListOpts[6].disable = true;
          this.stageListOpts[7].disable = true;
          this.stageListOpts[8].disable = true;
          this.stageListOpts[9].disable = true;
          this.stageListOpts[10].disable = true;
          this.stageListOpts[11].disable = true;
          break;
      }
    },
  },
  watch: {
    async stageRefreshKey() {
      await this.onChangeStage();
    },

    appStatus() {
      switch (this.appStatus.status) {
        case "created":
          this.submission = true;
          this.stageName = "Application submission";
          break;
        case "conditional_offer":
          this.submission = true;
          this.conditionalOff = true;
          this.stageName = "Conditional offer";
          break;
        case "supporting_documents":
          this.submission = true;
          this.conditionalOff = true;
          this.supportingDoc = true;
          this.stageName = "Supporting documents";
          break;
        case "unconditional_offer":
          this.submission = true;
          this.conditionalOff = true;
          this.supportingDoc = true;
          this.unConditionalOff = true;
          this.stageName = "Unconditional offer";
          break;
        case "loan_settlement":
          this.submission = true;
          this.conditionalOff = true;
          this.supportingDoc = true;
          this.unConditionalOff = true;
          this.loanSettlement = true;
          this.stageName = "Loan settlement";
          break;
        case "loan_funded":
          this.submission = true;
          this.conditionalOff = true;
          this.supportingDoc = true;
          this.unConditionalOff = true;
          this.loanSettlement = true;
          this.loanFunded = true;
          this.stageName = "Loan funded";
          break;
        case "loan_repaid":
          this.submission = true;
          this.conditionalOff = true;
          this.supportingDoc = true;
          this.unConditionalOff = true;
          this.loanFunded = true;
          this.loanSettlement = true;
          this.loanRepaid = true;
          this.stageName = "Loan repaid";
          break;
      }
    },
  },
  async created() {
    await this.getAppStatusList();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
// .option-width {
//   max-width: 100px;
// }
.completed-status {
  color: #2cdd80;
  font-weight: 600;
}
.not-completed-status {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 600;
}
.overflow-x {
  overflow-x: auto;
}

p {
  margin: 0;
}

img {
  -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 11.5.1 */
  -moz-animation: fadein 1.5s; /* Firefox < 16 */
  -ms-animation: fadein 1.5s; /* Internet Explorer */
  -o-animation: fadein 1.5s; /* Opera < 11.5.1 */
  animation: fadein 1.5s;
}

.pipeline-opts-wrapper {
  position: relative;
  height: 40px;

  .current-stage {
    padding: 0.5rem;
    border: 1px solid rgba(2, 0, 56, 0.15);
    width: 200px;
    cursor: pointer;
    height: 40px;
  }

  .opts-wrapper {
    position: absolute;
    top: 2.7rem;
    padding: 0.5rem 0;
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06) !important;
    border-radius: 8px !important;
    max-height: 200px;
    min-width: 200px;
    z-index: 99;
    overflow: scroll;
  }

  .list-active {
    background: #e3f2fd !important;
  }

  .list {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
    font-size: 1rem;
    color: #495057;
    cursor: pointer;

    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      background: #eeeeee !important;
    }
  }

  .disable-list {
    margin: 0;
    padding: 0.5rem 1rem !important;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #495057;
  }

  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
