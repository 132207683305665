<template>
  <div class="pl-4 pr-4 pb-4">
    <Card class="box-shadow std-padding">
      <template #title>
        <h3 class="customer-name sec-color-txt hk-sb">Account overview</h3>
      </template>
      <template #content>
        <Spinner v-if="isloading" class="flex justify-content-between" />
        <div v-else>
          <div class="flex justify-content-between account-overview">
            <div class="col col-5 flex justify-content-between">
              <div class="title">Staff name</div>
              <div>{{ userDetails.firstName }} {{ userDetails.lastName }}</div>
            </div>
            <div class="col col-6 flex justify-content-between">
              <div class="title">Staff username</div>
              <div>{{ userDetails.userName }}</div>
            </div>
          </div>
          <div v-if="!isloading">
            <div class="flex align-items-center mt-3">
              <div class="input-field mr-6">
                <p class="label">Mobile</p>
                <TextField
                  :value="userDetails.phoneNumber"
                  placeholder="Enter your phone number"
                  width="100%"
                  @onInput="getFormData($event, 'phoneNumber')"
                  type="tel"
                />
              </div>
              <div class="input-field">
                <p class="label">Email</p>
                <TextField
                  :value="userDetails.email"
                  placeholder="Enter your email address"
                  @onInput="getFormData($event, 'email')"
                  width="100%"
                />
              </div>
            </div>
            <div class="mt-4 flex align-items-center">
              <div class="input-field mr-3">
                <p class="label">Address line 1</p>
                <TextField
                  :value="userDetails.add_1"
                  @onInput="getFormData($event, 'add_1')"
                  placeholder="Your addreess line 1"
                  width="100%"
                />
              </div>
              <div class="input-field mr-3">
                <p class="label">Address line 2</p>
                <TextField
                  :value="userDetails.add_2"
                  @onInput="getFormData($event, 'add_2')"
                  placeholder="Your addreess line 2"
                  width="100%"
                />
              </div>
              <div class="input-field">
                <p class="label">City</p>
                <TextField
                  :value="userDetails.city"
                  @onInput="getFormData($event, 'city')"
                  placeholder="Enter your city"
                  width="100%"
                />
              </div>
            </div>
            <div class="mt-4 flex align-items-center">
              <div class="input-field mr-3">
                <p class="label">State</p>
                <TextField
                  :value="userDetails.state"
                  placeholder="Enter your state"
                  @onInput="getFormData($event, 'state')"
                  width="100%"
                />
              </div>
              <div class="input-field mr-3">
                <p>Postal code</p>
                <NumberField
                  :value="userDetails.postalCode"
                  @onInput="getFormData($event, 'postalCode')"
                  placeholder="Enter your postal code"
                  width="100%"
                  maxValue="4"
                />
              </div>
              <div class="input-field">
                <p class="label">Country</p>
                <Dropdown
                  :value="userDetails.country"
                  placeholder="Select your
                country"
                  width="100%"
                  :items="['Australia']"
                  @change="getFormData($event, 'country')"
                />
              </div>
            </div>
          </div>

          <div class="account-overview mt-4"></div>
          <div class="mt-3">
            <p>Password</p>
            <div class="mt-4 flex align-items-center">
              <div class="input-field mr-3">
                <p class="label">Current password</p>
                <TextField
                  :value="userPass.currentPassword"
                  placeholder="Current password"
                  @onInput="getPassData($event, 'currentPassword')"
                  width="100%"
                  type="password"
                />
              </div>
              <div class="input-field mr-3">
                <p>New password</p>
                <TextField
                  :value="userPass.newPassword"
                  placeholder="New password"
                  width="100%"
                  type="password"
                  @onInput="getPassData($event, 'newPassword')"
                />
              </div>
              <div class="input-field">
                <p class="label">Confirm new password</p>
                <TextField
                  :value="userPass.confirmPassword"
                  placeholder="Confirm new password"
                  @onInput="getPassData($event, 'confirmPassword')"
                  width="100%"
                  type="password"
                />
              </div>
            </div>
          </div>
          <br />
          <div class="mb-4 flex items-center">
            <Button
              :disabled="isSubmitDisabled()"
              @click="saveChanges"
              label="Save changes"
              type="primary"
              :loading="isBtnloading"
            />
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "primevue/card";
import { _get, _put } from "../../../../services/crudService";
import { SERVER_URL } from "../../../../../constants";
import { required, sameAs, email, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
// import { checkMobileNumber } from "../../../../services/validatePhNo";

export default {
  components: { Card },
  data() {
    return {
      userDetails: {
        phoneNumber: "",
        email: "",
        add_1: "",
        add_2: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
      },
      userPass: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      isloading: false,
      isBtnloading: false,
      refreshKey: 10,
    };
  },
  watch: {
    refreshKey() {
      this.getUserProfile();
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    if (
      this.userPass.currentPassword !== "" ||
      this.userPass.newPassword !== "" ||
      this.userPass.confirmPassword !== ""
    ) {
      return {
        userPass: {
          currentPassword: { required },
          newPassword: { required },
          confirmPassword: {
            required,
            sameAsPassword: sameAs(this.userPass.newPassword),
          },
        },
      };
    } else {
      return {
        userDetails: {
          phoneNumber: {
            required,
            maxLength: maxLength(10),
          },
          email: { required, email },
          add_1: { required },
          add_2: { required },
          city: { required },
          state: { required },
          postalCode: { required },
          country: { required },
        },
      };
    }
  },
  methods: {
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getFormData(value, fieldName) {
      this.userDetails[fieldName] = value;
      this.userDetails = { ...this.userDetails };

      if (fieldName === "phoneNumber") {
        this.userDetails.phoneNumber = value.toString();
      }
    },
    getPassData(value, fieldName) {
      this.userPass[fieldName] = value;
      this.userPass = { ...this.userPass };
    },
    async saveChanges() {
      try {
        this.isBtnloading = true;
        if (typeof this.userDetails.postalCode === "string") {
          this.userDetails.postalCode = parseInt(this.userDetails.postalCode);
        }
        const response = await _put(
          SERVER_URL + "upate_crm_user_profile",
          this.userDetails
        );
        if (response) {
          this.isBtnloading = false;
          this.refreshKey++;

          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "User details updated successfully",
            life: 3000,
          });
        }

        if (
          this.userPass.currentPassword !== "" ||
          this.userPass.newPassword !== "" ||
          this.userPass.confirmPassword !== ""
        ) {
          this.isBtnloading = true;
          const data = {
            currentPassword: this.userPass.currentPassword,
            newPassword: this.userPass.newPassword,
          };
          const resetPass = await _put(SERVER_URL + "crm_user_reset_pwd", data);

          if (resetPass) {
            this.isBtnloading = false;
            this.refreshKey++;
            this.userPass.currentPassword = "";
            this.userPass.newPassword = "";
            this.userPass.confirmPassword = "";
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "User password updated successfully",
              life: 3000,
            });
          }
        }
      } catch (error) {
        this.isBtnloading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    async getUserProfile() {
      try {
        this.isloading = true;
        const response = await _get(SERVER_URL + "get_crm_user_profile");

        if (response) {
          this.userDetails = response.data.result[0];
          this.isloading = false;
        }
      } catch (err) {
        this.isloading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },
  async created() {
    await this.getUserProfile();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.pm-color {
  color: #2cdd80;
}

.std-padding {
  padding: 1rem;
}

p {
  margin: 0;
  font-size: 1rem !important;
}

.label {
  font-family: Gordita-medium;
  color: $secondary-color;
  margin-bottom: 5px;
}

.input-field {
  width: 100% !important;
}
.account-overview {
  border-bottom: 1px solid #02003826;
}
.title {
  color: $light-text-color;
}
.flex-column {
  flex-direction: column;
}
.account-wrapper {
  padding: 1.875rem;
}
// .items-center{
//   float:left;
// }
</style>
