<template>
  <Dialog
    :draggable="false"
    :visible="show"
    position="top"
    :modal="show"
    :style="{ width: '680px' }"
  >
    <template #header>
      <h1 class="modal-title">Merge Selected</h1>
    </template>
    <div class="modal-content">
      <div class="flex align-items-center">
        <p class="col-2 flex justify-content-end text hk-md">Name</p>
        <div
          v-for="(item, index) in selectedCards"
          :key="index"
          class="col-5 flex fg-10 align-items-center"
        >
          <Checkbox2
            :id="item"
            :name="item.institution"
            :value="cards.institution == item.institution"
            @getInputValue="(e) => selectCards(e, item)"
            :key="refreshKey"
          />
          <div class="mb-2" :for="item.institution">{{ item.institution }}</div>
        </div>
      </div>

      <div :key="refreshKey" class="flex align-items-center">
        <p class="col-2"></p>
        <div class="col-10">
          <div>
            <p class="label">Institution name</p>
            <TextField :value="institutionName" :disable="true" width="100%" />
          </div>
        </div>
      </div>
      <!-- Credit limit owing -->
      <div :key="refreshKey" class="form-inputs mt-2">
        <div class="p-grid align-items-center header">
          <p class="p-col-2"></p>
          <p class="p-col flex justify-content-start label">
            Application Value
          </p>
          <p class="p-col label">Verified Value</p>
          <p class="p-col label">Manual Value</p>
        </div>

        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">
            Credit Limit on card
          </p>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedCreditApplicationValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedCreditVerifiedValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div class="p-col flex align-items-center justify-content-center">
            <TextField
              :value="calculatedCreditManualValue"
              :disable="true"
              width="100%"
            />
          </div>
        </div>
      </div>

      <!-- Credit limit owing -->
      <div :key="refreshKey" class="form-inputs mt-2">
        <div class="p-grid align-items-center header">
          <p class="p-col-2"></p>
          <p class="p-col flex justify-content-start label">
            Application Value
          </p>
          <p class="p-col label">Verified Value</p>
          <p class="p-col label">Manual Value</p>
        </div>

        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Amount Owning</p>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedAmountApplicationValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedAmountVerifiedValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div class="p-col flex align-items-center justify-content-center">
            <TextField
              :value="calculatedAmountManualValue"
              :disable="true"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button
          label="Merge selected"
          type="primary"
          @click="saveMerged"
          :disabled="!institutionName"
        />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">
          Close
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Dialog from "primevue/dialog";
import Checkbox2 from "@/components/common/Checkbox2";

export default {
  name: "AddTask",
  props: ["show", "selectedCards", "allCards", "disable"],
  components: { Dialog, Checkbox2 },
  data() {
    return {
      cards: {},
      otherCards: {},
      refreshKey: 10,
      institutionName: "",
      //Credit card limit
      calculatedCreditApplicationValue: null,
      calculatedCreditVerifiedValue: null,
      calculatedCreditManualValue: null,
      //Amount owing
      calculatedAmountApplicationValue: null,
      calculatedAmountVerifiedValue: null,
      calculatedAmountManualValue: null,
    };
  },
  computed: {
    ...mapState({
      creditCardUpdated: (state) => state.applications.creditCardUpdated,
    }),
  },
  methods: {
    ...mapActions({
      setLiability: "applications/setLiability",
      setCreditCardUpdated: "applications/setCreditCardUpdated",
    }),
    saveMerged() {
      let allArr = this.allCards.filter(
        (item) =>
          item.institution != this.cards.institution &&
          item.institution != this.otherCards.institution
      );
      let arr = [
        ...allArr,
        {
          credit_limit_used: this.calculatedCreditManualValue,
          amount_owing_used: this.calculatedAmountManualValue,
          credit_limit_verified: this.calculatedAmountVerifiedValue,
          credit_limit_manual: this.calculatedCreditManualValue,
          credit_limit: this.calculatedCreditApplicationValue,
          amount_owning: this.calculatedAmountApplicationValue,
          amount_owing_verified: this.calculatedAmountVerifiedValue,
          amount_owing_manual: this.calculatedAmountManualValue,
          institution: this.institutionName,
          closeLiability: false,
          applicant_type: !this.disable ? "primary" : "secondary",
          credit_limit_used_value: "manual_value",
          amount_owing_used_value: "manual_value",
        },
      ];
      this.setLiability({
        data: arr,
        type: !this.disable ? "primary" : "secondary",
        liability_type: "cards",
      });
      this.setCreditCardUpdated(this.creditCardUpdated + 1);
      this.$emit("mergeSelected");
    },
    selectCards(value, cards) {
      this.cards = {
        ...cards,
        credit_limit_verified: cards.credit_limit_verified
          ? Number(cards.credit_limit_verified)
          : 0,
        credit_limit_manual: cards.credit_limit_manual
          ? Number(cards.credit_limit_manual)
          : 0,
        credit_limit: cards.credit_limit ? Number(cards.credit_limit) : 0,
        amount_owning: cards.amount_owning ? Number(cards.amount_owning) : 0,
        amount_owing_verified: cards.amount_owing_verified
          ? Number(cards.amount_owing_verified)
          : 0,
        amount_owing_manual: cards.amount_owing_manual
          ? Number(cards.amount_owing_manual)
          : 0,
      };
      this.otherCards = this.selectedCards.find(
        (item) => item.institution != cards.institution
      );
      this.otherCards = {
        ...this.otherCards,
        credit_limit_verified: this.otherCards.credit_limit_verified
          ? Number(this.otherCards.credit_limit_verified)
          : 0,
        credit_limit_manual: this.otherCards.credit_limit_manual
          ? Number(this.otherCards.credit_limit_manual)
          : 0,
        credit_limit: this.otherCards.credit_limit
          ? Number(this.otherCards.credit_limit)
          : 0,
        amount_owning: this.otherCards.amount_owning
          ? Number(this.otherCards.amount_owning)
          : 0,
        amount_owing_verified: this.otherCards.amount_owing_verified
          ? Number(this.otherCards.amount_owing_verified)
          : 0,
        amount_owing_manual: this.otherCards.amount_owing_manual
          ? Number(this.otherCards.amount_owing_manual)
          : 0,
      };
      this.institutionName = this.cards.institution;
      //Credit card limit
      this.calculatedCreditApplicationValue =
        this.cards.credit_limit + this.otherCards.credit_limit;
      this.calculatedCreditVerifiedValue =
        this.cards.credit_limit_verified +
        this.otherCards.credit_limit_verified;
      this.calculatedCreditManualValue =
        this.cards.credit_limit_manual + this.otherCards.credit_limit_manual;
      //Amount owing
      this.calculatedAmountApplicationValue =
        this.cards.amount_owning + this.otherCards.amount_owning;
      this.calculatedAmountVerifiedValue =
        this.cards.amount_owing_verified +
        this.otherCards.amount_owing_verified;
      this.calculatedAmountManualValue =
        this.cards.amount_owing_manual + this.otherCards.amount_owing_manual;
      this.refreshKey += 1;
    },
    close() {
      this.refreshKey += 1;
      this.$emit("close");
    },
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.checkbox-list {
  border: 1px solid rgba(2, 0, 56, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
}

.color-highlight {
  color: $secondary-color;
  font-family: Gordita-medium;
  font-size: 14px;
}

.modal-title {
  font-family: Gordita-medium-;
  font-size: 20px;
  color: $secondary-color;
}

.text,
label,
.label {
  font-family: Gordita-medium-;
  font-size: 14px;
  color: $secondary-color;
}

.color-highlight {
  color: $primary-color;
  text-decoration: underline;
}

p {
  margin: 0;
}

.fg-10 {
  gap: 10px;
}
::v-deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ced4da !important;
  border-color: #2196f3;
}
</style>
