<template>
  <Dialog
    :draggable="false"
    :visible="show"
    :modal="show"
    :style="{ width: '580px' }"
  >
    <template #header>
      <h1 class="modal-title">{{ title }}</h1>
    </template>
    <Spinner v-if="isLoading" class="flex" />
    <div v-else class="modal-content">
      <div>
        <p class="label">Type</p>
        <TextField
          v-if="
            title == 'Edit transaction out' || title == 'Edit transaction in'
          "
          width="100%"
          type="text"
          :value="formatType(formData.type)"
          @onInput="getSearchValue($event)"
          placeholder="Search"
          :key="searchKey"
          :disable="true"
        />
        
        <Dropdown
          width="100%"
          v-else
          :items="transactionType"
          :value="formData.type"
          @change="getFormData($event, 'type')"
          placeholder="please select"
          optionLabel="name"
        />
      </div>
      <div
        v-if="
          formData.type === 'partial_discharge' ||
          formData.type === 'full_discharge'
        "
        class="discharge my-2"
      >
        <div class="flex align-items-center">
          <InputWithIcon
            placeholder="Enter amount"
            label="Outstanding balance"
            type="number"
            width="100%"
            :icon="salary"
            class="col mr-2 disable-input"
            maxValue="999999999"
            @change="getDischargeData($event, 'outstanding_balance')"
            :key="refreshKey"
            :value="discharge.outstanding_balance"
            :disabled="true"
            :mode="'decimal'"
            :maxFractionDigits="2"
            :format="true"
          />

          <InputWithIcon
            placeholder="Enter amount"
            label="Transaction amount"
            type="number"
            width="100%"
            :icon="salary"
            class="col disable-input"
            maxValue="999999999"
            @change="getDischargeData($event, 'transaction_amount')"
            :key="refreshKey"
            :value="discharge.transaction_amount"
            :disabled="true"
            :mode="'decimal'"
            :maxFractionDigits="2"
            :format="true"
          />
        </div>

        <div class="flex align-items-center">
          <InputWithIcon
            placeholder="Enter amount"
            label="Special attendance fee"
            type="number"
            width="100%"
            :icon="salary"
            class="col mr-2 disable-input"
            maxValue="999999999"
            @change="getDischargeData($event, 'spl_attendance_fee')"
            :key="refreshKey"
            :value="discharge.spl_attendance_fee"
            :mode="'decimal'"
            :maxFractionDigits="2"
            :format="true"
          />

          <InputWithIcon
            placeholder="Enter amount"
            label="Legal discharge fee"
            type="number"
            width="100%"
            :icon="salary"
            class="col disable-input"
            maxValue="999999999"
            :value="discharge.legal_fees"
            @change="getDischargeData($event, 'legal_fees')"
            :key="refreshKey"
            :mode="'decimal'"
            :maxFractionDigits="2"
            :format="true"
          />
        </div>

        <div class="flex align-items-center" :key="key1">
          <InputWithIcon
            placeholder="Enter amount"
            label="Amount deducted from balance"
            type="number"
            width="100%"
            :icon="salary"
            class="col mr-2 disable-input"
            @change="getDischargeData($event, 'amount_deducted_from_balance')"
            :disable="true"
            maxValue="999999999"
            :key="refreshKey"
            :value="discharge.amount_deducted_from_balance"
            :mode="'decimal'"
            :maxFractionDigits="2"
            :format="true"
          />

          <InputWithIcon
            placeholder="Enter amount"
            label="Remaining balance"
            type="number"
            width="100%"
            :icon="salary"
            class="col disable-input"
            @change="getDischargeData($event, 'remaining_balance')"
            :disabled="true"
            maxValue="999999999"
            :key="refreshKey"
            :value="discharge.remaining_balance"
            :mode="'decimal'"
            :maxFractionDigits="2"
            :format="true"
          />
        </div>
        <div class="flex">
          <div class="col-6">
            <p class="label">Discharge date</p>
            <DatePicker2
              width="100%"
              placeholder="DD/MM/YY"
              @change="getDischargeData($event, 'discharge_date')"
              :value="discharge.discharge_date"
              :maxYear="maxYear"
              :minYear="minYear"
              :key="refreshKey"
            />
          </div>
        </div>
      </div>
      <div class="flex align-items-end mt-2 mb-3">
        <div class="col mr-2">
          <!-- <p class="label">Amount</p> -->
          <InputWithIcon
            placeholder="Enter amount"
            label="Amount"
            type="number"
            @change="getFormData($event, 'amount')"
            width="100%"
            :icon="salary"
            maxValue="999999999"
            :value="formData.amount || formData.payout_amount"
            :mode="'decimal'"
            :maxFractionDigits="2"
            :format="true"
          />
        </div>
        <div class="col">
          <p class="label">Transaction date</p>
          <DatePicker2
            @change="getFormData($event, 'transactionDate')"
            :value="formData.transactionDate"
            type="number"
            width="100%"
            placeholder="DD/MM/YY"
            :maxYear="maxYear"
            :minYear="minYear"
          />
        </div>
      </div>
      <div>
        <p class="label">Notes</p>
        <TextArea
          :value="formData.notes"
          width="100%"
          @onInput="getFormData($event, 'notes')"
          placeholder="Leave your note here..."
        />
      </div>
      <div class="mt-2">
        <p class="label">Status</p>
        <Dropdown
          :value="formData.status"
          placeholder="please select"
          @change="getFormData($event, 'status')"
          width="100%"
          :items="statusOpt"
          optionLabel="name"
          optionValue="value"
          :disable="statusDisabled"
        />
      </div>
    </div>
    <div v-if="saveDisabled && formData.type != 'partial_discharge'">Remaining balance should not be 0</div>
    <template #footer>
      <div class="flex align-items-center">
        <Button
          :disabled="isSubmitDisabled() || saveDisabled"
          :label="selectedData ? 'Save transaction' : 'Add transaction'"
          type="primary"
          @click="save"
          :loading="isloading"
        />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">
          Close
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Salary from "@/assets/dollar.svg";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { _get, _post, _put } from "../../../../../services/crudService";
import { APP_URL } from "../../../../../../constants";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: ["show", "transaction_type", "title", "selectedData", "outlist"],
  components: { Dialog },
  data() {
    return {
      key1: 45,
      key2: 55,
      statusDisabled: false,
      isloading: false,
      maxDate: null,
      maxYear: null,
      minYear: null,
      refreshKey: 10,
      transactionData: {},
      formData: {
        type: "",
        amount: 0,
        transactionDate: "",
        notes: "",
        status: "",
        application_id: this.$route.params.id,
        transactionType: this.$props.transaction_type,
        is_gst: false,
      },
      discharge: {
        outstanding_balance: 0,
        spl_attendance_fee: 0,
        legal_fees: 0,
        amount_deducted_from_balance: 0,
        discharge_date: 0,
        remaining_balance: 0,
        transaction_amount: 0,
      },
      salary: Salary,
      transactionType: [
        {
          name: "Loan Deposit charged",
          value: "loan_deposit_charged",
          is_gst: false,
        },
        {
          name: "Valuation fee charged",
          value: "valuation_fee_charged",
          is_gst: true,
        },
        {
          name: "Principal Funding",
          value: "principal_funding",
          is_gst: false,
        },
        {
          name: "Legal Attendance charged",
          value: "legal_attendance_charged",
          is_gst: true,
        },
        {
          name: "Legal Disbursements charged",
          value: "legal_disbursements_charged",
          is_gst: true,
        },
        { name: "Stripe Fee", value: "stripe_fee", is_gst: true },
        { name: "Referral Fee", value: "referral_fee", is_gst: true },

        {
          name: "Legal discharge charged",
          value: "legal_discharge_charged",
          is_gst: false,
        },
      ],
      statusOpt: [
        { name: "Draft", value: "draft" },
        { name: "Completed", value: "completed" },
      ],
      saveDisabled: false,
      isLoading: true,
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    if (
      this.formData.type === "partial_discharge" ||
      this.formData.type === "full_discharge"
    ) {
      return {
        discharge: {
          // outstanding_balance: { required },
          spl_attendance_fee: { required },
          legal_fees: { required },
          amount_deducted_from_balance: { required },
          discharge_date: { required },
          // remaining_balance: { required },
          // transaction_amount: { required },
        },
        formData: {
          type: { required },
          amount: { required },
          transactionDate: { required },
          notes: { required },
          status: { required },
          application_id: { required },
          transactionType: { required },
        },
      };
    } else {
      return {
        formData: {
          type: { required },
          amount: { required },
          transactionDate: { required },
          notes: { required },
          status: { required },
          application_id: { required },
          transactionType: { required },
        },
      };
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    formatType(value) {
      value = value.replaceAll("_", " ");
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    getFormData(value, fieldName) {
      if (fieldName == "type") {
        this.formData.type = value.value;
        this.formData.is_gst = value.is_gst;
      } else {
        this.formData[fieldName] = value;
      }
      this.formData = { ...this.formData };

      this.discharge.amount_deducted_from_balance =
        this.formData.amount +
        this.discharge.spl_attendance_fee +
        this.discharge.legal_fees;
      this.discharge.remaining_balance =
        this.discharge.outstanding_balance - this.discharge.transaction_amount;
      this.key1 += 1;
      this.key2 += 1;
      if (
        value == "partial_discharge" &&
        this.discharge.remaining_balance > 0
      ) {
        this.saveDisabled = true;
      } else {
        this.saveDisabled = false;
      }
    },
    getDischargeData(value, fieldName) {
      this.discharge[fieldName] = value;
      this.discharge = { ...this.discharge };
      if (fieldName == "spl_attendance_fee" || fieldName == "legal_fees") {
        this.discharge.amount_deducted_from_balance =
          this.formData.amount +
          this.discharge.legal_fees +
          this.discharge.spl_attendance_fee;
        this.discharge.remaining_balance =
          this.discharge.outstanding_balance -
          this.discharge.transaction_amount;
        this.key1 += 1;
        this.key2 += 1;
      }

      if (
        this.formData.type == "partial_discharge" &&
        this.discharge.remaining_balance > 0
      ) {
        this.saveDisabled = true;
      } else {
        this.saveDisabled = false;
      }
    },
    clearData() {
      this.formData = {
        type: "",
        amount: 0,
        transactionDate: "",
        notes: "",
        status: "",
        application_id: "",
        transactionType: "",
        is_gst: false,
      };
      this.discharge = {
        outstanding_balance: "",
        spl_attendance_fee: 0,
        legal_fees: 0,
        amount_deducted_from_balance: 0,
        discharge_date: "",
        remaining_balance: 0,
        transaction_amount: 0,
      };
    },
    async save() {
      try {
        this.isloading = true;

        let data = {};

        if (
          this.formData.type === "partial_discharge" ||
          this.formData.type === "full_discharge"
        ) {
          data = { ...this.formData, ...this.discharge };
        } else {
          data = { ...this.formData };
        }

        if (this.$props.selectedData) {
          const response = await _put(
            APP_URL + `discharge_request?id=${this.$props.selectedData.Id}`,
            data
          );

          if (response) {
            this.$emit("close");
            this.$emit("save");
            this.clearData();

            this.isloading = false;
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Transaction updated successfully",
              life: 3000,
            });
          }
        } else {
          const response = await _post(
            APP_URL + "insert_transaction_report",
            data
          );

          if (response) {
            this.$emit("close");
            this.$emit("save");
            this.clearData();

            this.isloading = false;
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Transaction added successfully",
              life: 3000,
            });
          }
        }
      } catch (error) {
        this.isloading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
  },
  async created() {
    this.isLoading = true;
    // let transaction_type = [];
    // console.log("this.outlist ", this.outlist);
    // this.transactionType.map((obj) => {
    //   let index = this.outlist && this.outlist.findIndex((i) => i.type == obj.value);
    //   if (index == -1) {
    //     transaction_type.push(obj);
    //   }
    // });
    // this.transactionType = transaction_type;

    if (this.$props.selectedData) {
      this.formData = this.$props.selectedData;
      this.formData.transactionDate = this.formData.discharge_date
        ? this.formatDate(this.formData.discharge_date)
        : this.formatDate(this.formData.transactionDate);
      this.formData.application_id = this.$route.params.id;
      this.formData.transactionType = this.$props.transaction_type;
    } else {
      this.formData = {};
      this.formData.application_id = this.$route.params.id;
      this.formData.transactionType = this.$props.transaction_type;
    }
    this.maxDate = new Date();
    this.minYear = new Date().getFullYear() - 70;
    this.maxYear = this.maxDate.getFullYear();

    const res = await _get(
      APP_URL + `transaction_summary?application_id=${this.$route.params.id}`
    );

    if (res) {
      this.discharge = res.data[0] || {
        outstanding_balance: 0,
        spl_attendance_fee: 0,
        legal_fees: 0,
        amount_deducted_from_balance: 0,
        discharge_date: "",
        remaining_balance: 0,
        transaction_amount: 0,
      };
    }
    this.discharge.remaining_balance = 0;
    this.discharge.outstanding_balance = 0;
    this.discharge.spl_attendance_fee = this.discharge.spl_attendance_fee
      ? this.discharge.spl_attendance_fee
      : 0;
    this.discharge.legal_fees = this.discharge.legal_fees
      ? this.discharge.legal_fees
      : 0;
    this.discharge.amount_deducted_from_balance =
      this.formData.amount +
      this.discharge.spl_attendance_fee +
      this.discharge.legal_fees;
    // Calcualte outstanding balance
    let inResult = await _get(
      APP_URL +
        `get_transaction_report?application_id=${this.$route.params.id}&transactionType=in`
    );
    let outResult = await _get(
      APP_URL +
        `get_transaction_report?application_id=${this.$route.params.id}&transactionType=out`
    );
    inResult =
      inResult && inResult.data && inResult.data.result
        ? [...inResult.data.result]
        : [];
    outResult =
      outResult && outResult.data && outResult.data.result
        ? [...outResult.data.result]
        : [];
    let outData = [],
      inData = [];
    outData = [...outResult];
    inData = [...inResult];
    if (inData.length) {
      let inTotal = inData.reduce((prev, next) => {
        return prev + Number(next.amount);
      }, 0);
      let outTotal = outData.reduce((prev, next) => {
        if (next.status == "completed") return prev + Number(next.amount);
      }, 0);
      inTotal = inTotal ? inTotal : 0;
      outTotal = outTotal ? outTotal : 0;
      // let xData = inData.map((x) => x.status == "completed");
      this.discharge.outstanding_balance = inTotal - outTotal;
    } else {
      this.discharge.outstanding_balance = outData.reduce((prev, next) => {
        if (next.status == "completed") return prev + Number(next.amount);
      }, 0);
    }

    this.discharge.transaction_amount = this.discharge.transaction_amount
      ? this.discharge.transaction_amount
      : 0;
    this.discharge.remaining_balance =
      this.discharge.outstanding_balance -
      this.discharge.amount_deducted_from_balance;
    this.discharge.discharge_date = this.formData.transactionDate;

    this.key1 += 1;
    this.key2 += 1;
    this.statusDisabled = this.formData.status == "completed" ? true : false;
    this.refreshKey++;
    this.isLoading = false;
  },
  unmounted() {
    this.clearData();
  },
  computed: {
    ...mapGetters({
      loanCreatedDate: "applications/loanCreatedDate",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.timestamp {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 600;
  font-size: 14px;
}
.divider {
  border: 1px solid rgba(2, 0, 56, 0.15);
}

:deep .col-6 {
  padding: 0 !important;
}

.label,
:deep .p-float-label {
  margin: 0;
  font-family: Gordita-medium;
  font-size: 14px;
}
</style>
