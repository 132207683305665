<template>
  <Button
    :label="label"
    :disabled="disable"
    :class="ButtonClass()"
    :style="{
      height: `${height}`,
      width: `${width}`,
      border: `1px solid ${color} !important`,
      color: `${color}`,
      background: `${bgcolor}`,
    }"
    :icon="icon"
    :iconPos="iconPos"
    :loading="loading"
  />
</template>

<script>
import Button from "primevue/button"

export default {
  name: "Buttons",
  props: [
    "label",
    "icon",
    "button_class",
    "type",
    "bgcolor", // background color of button
    "color", // color of text
    "height",
    "width",
    "iconPos", // position of icon
    "disable",
    "loading",
  ],
  components: {
    Button,
  },
  methods: {
    ButtonClass() {
      const classes = []
      classes.push(this.button_class)
      if (this.type === "primary") {
        classes.push("primary-btn")
      } else if (this.type === "outline") {
        classes.push("p-button-outlined")
      } else if (this.type === "disable") {
        classes.push("disable-btn")
      }
      return classes
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.primary-btn {
  color: $white-color;
  background: $primary-color;
  border: unset !important;
  border-radius: 4px;
}
.p-button {
  font-weight: 800;
  box-shadow: unset !important;
}

.disable-btn {
  background: rgba(2, 0, 56, 0.2) !important;
  border: none !important;
}
// .p-button:enabled:active {
//   background: $primary-color !important;
//   border: unset !important;
// }
// .p-button:enabled:hover {
//   background: $primary-color !important;
//   border: unset !important;
// }
.p-button.p-button-outlined{
  background: #fff;
    color: #2CDD80;
    border: 1px solid #2CDD80;
}
.p-button.p-button-outlined:enabled:hover {
    background: #fff;
    color: #2CDD80;
    border: 1px solid  #2CDD80;
}
.p-button:enabled:hover {
    background:#2CDD80;
    color: #ffffff;
    border-color: #2CDD80;
}
</style>
>
