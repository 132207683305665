<template>
  <div class="mr-top">
    <Panel :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>
      <template #header>
        <div @click="toggle" class="sec-color-text hk-sb full-width pointer">
          Outcomes
        </div>
      </template>
      <Card>
        <template #content>
          <Spinner v-if="isloading" class="flex justify-content-between mt-3" />
          <DataTable
            v-else
            :expandedRows="expandedRows"
            @row-expand="onRowExpand"
            @row-collapse="onRowCollapse"
            responsiveLayout="scroll"
            dataKey="id"
            :value="lists"
            :rowStyleClass="
              lists?.result === 'Warning 1' ? 'status-pass' : 'row-accessories'
            "
            :rowClass="renderClass"
            id="outcomes"
          >
            <template #empty> <div class="p-2">No data found.</div> </template>

            <Column field="data_source" style="width: 210px" header="Data source">
              <template #body="slotProps">
                <div class="table-column">
                  {{ slotProps.data.data_source }}
                </div>
              </template>
            </Column>
            <Column field="status" style="width: 200px" header="Status">
              <template #body="slotProps">
                <div v-if="slotProps.data?.result_type === 'Pass'">
                  <div class="table-column">completed</div>
                </div>
                <div v-else>
                  <div class="table-column">Review Required</div>
                </div>
              </template>
            </Column>
            <Column field="field" header="Field">
              <template #body="slotProps">
                <div class="table-column">
                  {{ slotProps.data.field || "-" }}
                </div>
              </template>
            </Column>
            <Column field="description" style="width: 200px" header="Value">
              <template #body="slotProps">
                <div class="table-column">
                  {{ slotProps.data.description || "-" }}
                </div>
              </template>
            </Column>
            <Column field="result" header="Result">
              <template #body="slotProps">
                <div class="table-column">
                  {{ slotProps.data.result || "-" }}
                </div>
              </template>
            </Column>
            <Column field="result_type" header="Result type">
              <template #body="slotProps">
                <div class="table-column">
                  {{ slotProps.data.result_type || "-" }}
                </div>
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </Panel>
  </div>
</template>

<script>
import Panel from "primevue/panel";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { _get, _post } from "../../../../services/crudService";
import { APP_URL, SERVER_URL_2 } from "../../../../../constants";
export default {
  components: { Panel, Card, DataTable, Column },
  props: ["selectedOutcome", "selectedVersion", "playVersion", "key", "tabLocation"],
  data() {
    return {
      collapsed: false,
      lists: [],
      warning: [],
      fail: [],
      exception: [],
      pass: [],
      reviewed: [],
      isloading: false,
      type: [],
    };
  },
  watch: {
    selectedOutcome(value) {
      if (value === "All") {
        this.lists = this.lists = [
          ...this.fail,
          ...this.warning.filter((list) => list.result === "Warning 2"),
          ...this.warning.filter((list) => list.result === "Warning 1"),
          ...this.exception,
          ...this.reviewed,
          ...this.pass,
        ];
        this.$emit("result", this.lists);
      } else if (value === "Pass") {
        return (this.lists = [...this.pass]);
      } else if (value === "Fails") {
        return (this.lists = [...this.fail]);
      } else if (value === "Warnings") {
        return (this.lists = [
          ...this.warning.filter((list) => list.result === "Warning 2"),
          ...this.warning.filter((list) => list.result === "Warning 1"),
        ]);
      } else if (value === "Exceptions") {
        return (this.lists = [...this.exception]);
      }
    },
    playVersion() {
      this.getList(this.$props.selectedVersion);
    },
    key() {
      this.getList(this.$props.selectedVersion);
    },
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
    renderClass(data) {
      if (data.result_type === "Warning" && data.result === "Warning 2") {
        return "highlight-bg1";
      } else if (data.result_type === "Warning" && data.result === "Warning 1") {
        return "highlight-bg2";
      } else if (data.result_type === "Fail") {
        return "highlight-bg3";
      } else if (data.result_type === "Exception") {
        return "highlight-bg4";
      } else if (data.result_type === "Pass" || data.result_type === "Reviewed") {
        return;
      } else return;
    },
    async getList() {
      this.isloading = true;

      try {
        if (this.selectedVersion) {
          const response = await _post(
            SERVER_URL_2 +
              `run-decision-seeker-engine?application_id=${this.$route.params.id}&application_version=${this.selectedVersion}`
          );

          if (response) {
            this.warning = response.data?.warning;
            this.exception = response.data?.exception;
            this.reviewed = response.data?.reviewed;
            this.pass = response.data?.pass;
            this.fail = response.data?.fail;

            this.isloading = false;
          }
        } else {
          let result = await _get(
            APP_URL + "get-application-versions?applicationId=" + this.$route.params.id
          );
          let application_version = result?.data?.latest_application_version;
          const response = await _post(
            SERVER_URL_2 +
              `run-decision-seeker-engine?application_id=${this.$route.params.id}&application_version=${application_version}`
          );

          if (response) {
            this.warning = response.data?.warning;
            this.exception = response.data?.exception;
            this.pass = response.data?.pass;
            this.reviewed = response.data?.reviewed;
            this.fail = response.data?.fail;

            this.isloading = false;
          }
        }
      } catch (error) {
        console.log(error);
        this.isloading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }

      this.fail
        .filter((list) => list.result === "Fail")
        .map((list) => {
          list.Id &&
            this.type.push({
              Id: list?.Id,
              field: list?.field,
              description: list?.description,
              notes: list?.notes ? list.notes : "",
              result: list?.result,
              result_type: list?.result_type,
            });
        });

      this.warning
        .filter((list) => list.result === "Warning 2" || list.result === "Fail")
        .map((list) => {
          list.Id &&
            this.type.push({
              Id: list?.Id,
              field: list?.field,
              description: list?.description,
              notes: list?.notes ? list.notes : "",
              result: list?.result,
              result_type: list?.result_type,
            });
        });

      this.exception
        .filter((list) => list.result === "Warning 2" || list.result === "Fail")
        .map((list) => {
          list.Id &&
            this.type.push({
              Id: list?.Id,
              field: list?.field,
              description: list?.description,
              notes: list?.notes ? list.notes : "",
              result: list?.result,
              result_type: list?.result_type,
            });
        });

      this.$emit("getTypeOpts", this.type);
    },
  },
  async created() {
    await this.getList();
    if (this.$props.selectedOutcome === "All") {
      this.lists = this.lists = [
        ...this.fail,
        ...this.warning.filter((list) => list.result === "Warning 2"),
        ...this.warning.filter((list) => list.result === "Warning 1"),
        ...this.exception,
        ...this.reviewed,
        ...this.pass,
      ];
      this.$emit("result", this.lists);
    } else if (this.$props.selectedOutcome === "Pass") {
      return (this.lists = [...this.pass]);
    } else if (this.$props.selectedOutcome === "Fails") {
      return (this.lists = [...this.fail]);
    } else if (this.$props.selectedOutcome === "Warnings") {
      return (this.lists = [
        ...this.warning.filter((list) => list.result === "Warning 2"),
        ...this.warning.filter((list) => list.result === "Warning 1"),
      ]);
    } else if (this.$props.selectedOutcome === "Exceptions") {
      return (this.lists = [...this.exception]);
    } else {
      this.lists = this.lists = [
        ...this.fail,
        ...this.warning.filter((list) => list.result === "Warning 2"),
        ...this.warning.filter((list) => list.result === "Warning 1"),
        ...this.exception,
      ];
      if (this.$props.tabLocation === "decisionSeeker") {
        this.lists = this.lists.concat(this.pass);
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .highlight-bg1 {
  background-color: rgba(245, 174, 69, 0.89) !important;
}
::v-deep .highlight-bg2 {
  background-color: rgba(252, 239, 69, 0.863) !important;
}
::v-deep .highlight-bg3 {
  background-color: rgba(240, 108, 108, 0.89) !important;
}
::v-deep .highlight-bg4 {
  background-color: rgb(164, 191, 224) !important;
}

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid #02003826 !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
  padding: unset !important;
}

.table-column {
  display: block;
  padding: 0.5rem 0;
  margin: 5px 15px;
}

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

.highlight-fields {
  .label {
    font-family: Gordita-bold !important;
  }
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

::v-deep .p-col-4,
.p-col-2 {
  padding-bottom: 0;
  padding-top: 0;
}

.mr-b-5px {
  margin-bottom: 5px;
}

.mr-top {
  margin-top: 2.5rem;
}

.mr-top-10px {
  margin-top: 10px;
}

.flex-gap {
  gap: 0.625rem;
}
.align-items {
  gap: 26.5rem;
  margin-left: -0.5rem;
  color: rgba(2, 0, 56, 0.3);
  font-family: Gordita-medium;
  font-weight: 600;
}
.label-content {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
  white-space: nowrap;
  margin-left: -1rem;
  font-weight: 600;
}
.call {
  gap: 4rem;
}
.input-text {
  margin-top: -1rem;
}
.sec-color-text {
  font-size: 1.15rem;
  font-family: Gordita-bold;
  color: #020038;
  font-weight: 600;
  padding-bottom: 1rem;
}
.flex-content-item {
  gap: 2rem;
}
.col-4 {
  flex: 0 0 auto;
  padding: unset !important;
  width: 33.3333%;
}
::v-deep .p-float-label {
  margin-bottom: unset !important;
}
.col-6 {
  flex: 0 0 auto;
  padding: unset !important;
  width: 50%;
}
::v-deep .p-panel {
  margin-top: 2.5rem;
  border: 1px solid rgba(2, 0, 56, 0.2);
  padding: 1rem;
  border-radius: 4px;
}
::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}

::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
  padding: 0rem 0 !important;
}
.color-highlight {
  color: $primary-color !important;
  text-decoration: none;
}

::v-deep .p-paginator-bottom {
  display: none !important;
}
p {
  font-size: 1rem;
  margin: 0;
}

.sec-content {
  background: rgba(2, 0, 56, 0.03) !important;
  width: 100%;
  padding: 1rem 1rem;
}
::v-deep .p-card {
  background: #ffffff;
  color: #495057;
  box-shadow: unset !important;
  border-radius: 3px;
}
::v-deep .p-panel {
  margin-top: 2.5rem;
  border: 1px solid #ced4da !important;
  padding: 1rem;
  border-radius: 4px;
}
::v-deep .p-card .p-card-content {
  padding: 0 0 !important;
}
::v-deep .p-card .p-card-body {
  padding: 0 !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
::v-deep .p-panel .p-panel-content {
  padding: 0rem !important;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 0 none;
}
::v-deep .p-datatable-wrapper {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}
</style>
