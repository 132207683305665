<template>
  <div>
    <div class="content">
      <div class="form-inputs mt-4">
        <div
          v-if="
            latestData.assets_properties && latestData.assets_properties.length
          "
          class="p-grid align-items-center header"
        >
          <p class="col"></p>
          <p class="col label">Application Values</p>
          <p class="col label">Current Values</p>
          <p class="col label">Current Value Source</p>
          <div class="col"></div>
        </div>

        <div v-for="(item, i) in latestData.assets_properties" :key="i">
          <InputRow
            :value1="originalAssets[i] ? originalAssets[i].amount_owning : ''"
            :value2="item.amount_owning"
            :label="`Property ${i + 1}- $`"
          />
        </div>

        <InputRow
          v-if="
            latestData.assets_properties && latestData.assets_properties.length
          "
          label="Subtotal"
          :value1="originalTotal"
          :value2="latestTotal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputRow from "../../InputRow.vue";
export default {
  components: { InputRow },
  computed: {
    ...mapGetters({
      originalData: "loans/originalData",
      latestData: "loans/latestData",
    }),
  },
  watch: {
    latestData() {
      this.originalAssets = this.originalData.assets_properties;
      this.originalAssets.map((obj) => {
        if (obj.amount_owning) this.originalTotal += obj.amount_owning;
      });
      if (this.latestData && this.latestData.assets_properties) {
        this.latestData.assets_properties.map((obj) => {
          if (obj.amount_owning) this.latestTotal += obj.amount_owning;
        });
      }
    },
  },
  data() {
    return {
      originalAssets: [],
      originalTotal: 0,
      latestTotal: 0,
    };
  },
  created() {
    this.originalAssets = this.originalData.assets_properties;
    this.originalAssets.map((obj) => {
      if (obj.amount_owning) this.originalTotal += obj.amount_owning;
    });
    if (this.latestData && this.latestData.assets_properties) {
      this.latestData.assets_properties.map((obj) => {
        if (obj.amount_owning) this.latestTotal += obj.amount_owning;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
</style>
