export default {
    namespaced: true,
    state: {
        credit_history_review: "",
        summary_notes: "",
        property_notes: "",
        income_review: "",
        expense_review: "",
        asset_review: "",
        liability_review: "",
        initial_decision: "",
        final_decision: ""
    },
    getters: {
        credit_history_review: ({ credit_history_review }) => credit_history_review,
        summary_notes: ({ summary_notes }) => summary_notes,
        property_notes: ({ property_notes }) => property_notes,
        income_review: ({ income_review }) => income_review,
        expense_review: ({ expense_review }) => expense_review,
        asset_review: ({ asset_review }) => asset_review,
        liability_review: ({ liability_review }) => liability_review,
    },
    mutations: {
        setReviewData(storeState, payload) {
            switch (payload.type) {
                case "credit_history_review":
                    storeState.credit_history_review = payload.value
                    break;
                case "summary_notes":
                    storeState.summary_notes = payload.value
                    break;
                case "property_notes":
                    storeState.property_notes = payload.value
                    break;
                case "income_review":
                    storeState.income_review = payload.value
                    break;
                case "expense_review":
                    storeState.expense_review = payload.value
                    break;
                case "asset_review":
                    storeState.asset_review = payload.value
                    break;
                case "liability_review":
                    storeState.liability_review = payload.value
                    break;
                case "initial_decision":
                    storeState.initial_decision = payload.value
                    break;
                case "final_decision":
                    storeState.final_decision = payload.value
                    break;
            }

        }
    },
    actions: {
        setReviewData({ commit }, payload) {
            commit("setReviewData", payload)
        }
    },
}
