<template>
  <div>
    <Spinner v-if="isloading" class="flex justify-content-between" />
    <div v-else>
      <div class="flex align-items-center justify-content-between std-padding-x mr-b">
        <div class="flex align-items-center">
          <p class="hk-b lt-txt-color fs-18px">Application Version</p>
          <Dropdown
            class="ml-4"
            :disable="!restrictUser('toggleApplicationVersion')"
            placeholder="Select Version"
            :items="applicationVersion"
            optionLabel="version"
            optionValue="version"
            :value="selectedVersion"
            @change="setSelectedVersion"
          />
        </div>
        <div class="icons">
          <img src="@/assets/play.svg" class="pointer" @click="showSaveVersion" alt="" />
          <!-- <img
            src="@/assets/mail-icon.svg"
            class="pointer"
            v-if="restrictUser('sendOfferToApplicants')"
            alt=""
          />-->
          <img
            class="pointer"
            @click="exportFile"
            v-if="restrictUser('sendOfferToApplicants')"
            src="@/assets/external-link.svg"
            alt=""
          />
        </div>
      </div>
      <div class="pl-4 pr-4 pb-4">
        <Card>
          <template #title>
            <div class="flex justify-content-between align-items-center content-items">
              <div class="title flex align-items-center">
                Outcomes and special conditions
              </div>
              <div class="flex align-items-center items-content">
                <div
                  v-if="typeOpts.length"
                  @click="showAddException"
                  class="col flex white-sp-nowrap align-items-center ml-3 task-opt"
                >
                  <span class="mr-2">+</span> New exception
                </div>
                <div
                  v-if="displayAddSpecialConditionBtn()"
                  @click="showAddSpecialCondition"
                  class="col flex white-sp-nowrap align-items-center ml-3 task-opt"
                >
                  <span class="mr-2">+</span> New special condition
                </div>
                <div class="flex align-items-center items-content">
                  <div class="relationship">Outcome</div>
                  <Dropdown
                    class="ml-1"
                    placeholder="All"
                    :items="outComeOpts"
                    :value="selectedOutcome"
                    @change="setSeleectedOutcome"
                  />
                </div>
              </div>
            </div>
          </template>
          <template #content>
            <Outcomes
              :selectedVersion="selectedVersion"
              :selectedOutcome="selectedOutcome"
              :playVersion="playSelectedVersion"
              tabLocation="decisionSeeker"
              @getTypeOpts="getTypeOpts"
              :key="refreshException"
              @result="result"
            />
            <SpecialConditions
              :selectedVersion="selectedVersion"
              :selectedOutcome="selectedOutcome"
              :playVersion="playSelectedVersion"
              :key="refreshSpecialCondition"
              @edit="editSplCondition"
              :editAccess="displayAddSpecialConditionBtn()"
            />
            <!-- <OutgoingProperty /> -->
            <SendOffer :show="displaySendEmail" @close="displaySendEmail = false" />
            <SaveVersion :show="displaySaveVersion" @close="displaySaveVersion = false" />
            <AddSpecialConditons
              v-if="displaySpecialCondition"
              :show="displaySpecialCondition"
              @close="displaySpecialCondition = false"
              :selectedSplCondition="selectedSplCondition"
              :appStatus="appStatus"
              @save="refreshSpecialCondition++"
            />
            <Exception
              v-if="displayAddException"
              :show="displayAddException"
              @close="displayAddException = false"
              @save="refreshException++"
              :opts="typeOpts"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import SendOffer from "./SendOffer.vue";
import SaveVersion from "./SaveVersion.vue";
import Card from "primevue/card";
import { APP_URL } from "../../../../../../constants";
import { _get, _post } from "../../../../../services/crudService";
import { mapGetters } from "vuex";
import Outcomes from "../Outcomes.vue";
import SpecialConditions from "../SpecialConditions.vue";
import AddSpecialConditons from "./AddSpecialCondition.vue";
import Exception from "./Exception.vue";

export default {
  components: {
    Card,
    SendOffer,
    SaveVersion,
    SpecialConditions,
    Outcomes,
    AddSpecialConditons,
    Exception,
  },
  data() {
    return {
      appStatus: {},
      outComesResult: [],
      refreshSpecialCondition: 10,
      refreshException: 10,
      displaySendEmail: false,
      displaySaveVersion: false,
      displaySpecialCondition: false,
      displayAddException: false,
      selectedSplCondition: null,
      applicationVersion: [],
      selectedVersion: 1,
      isloading: false,
      selectedOutcome: "All",
      outComeOpts: ["All", "Warnings", "Fails", "Exceptions", "Pass"],
      playSelectedVersion: 10,
      typeOpts: [],
    };
  },
  computed: {
    ...mapGetters({ condition: "auth/userPermission" }),
  },
  methods: {
    restrictUser(value) {
      if (this.outComesResult.length > 1) {
        return this.condition[value];
      } else return false;
    },
    displayAddSpecialConditionBtn() {
      switch (this.appStatus.crm_status) {
        case "application_in_progress":
          return true;
        case "assessment_ready":
          return true;
        case "workshopping_application":
          return true;
        case "pre-approval":
          return true;
        case "conditional_offer":
          return true;
        case "supporting_documents":
          return true;
        case "unconditional_offer":
          return true;
        case "solicitor_instructed":
          return true;
        case "document_issued":
          return true;
        default:
          return false;
      }
    },
    getTypeOpts(value) {
      this.typeOpts = value;
    },
    result(value) {
      this.outComesResult = value;
    },
    async exportFile() {
      const response = await _post(APP_URL + "decision_seeker_csv", {
        result: this.outComesResult,
      });

      if (response) {
        var a = document.createElement("a");
        a.href = "data:text/csv;base64," + response.data;
        // a.download = fileName;
        a.click();
      }
    },

    showAddException() {
      this.displayAddException = true;
    },
    showAddSpecialCondition() {
      this.selectedSplCondition = null;
      return (this.displaySpecialCondition = true);
    },
    editSplCondition(data) {
      this.displaySpecialCondition = true;
      this.selectedSplCondition = data;
    },
    showSendOffer() {
      return (this.displaySendEmail = true);
    },
    showSaveVersion() {
      this.playSelectedVersion++;
    },
    setSelectedVersion(value) {
      this.selectedVersion = value;
    },
    setSeleectedOutcome(value) {
      this.selectedOutcome = value;
    },
    async getVersion() {
      try {
        this.isloading = true;
        const response = await _get(
          APP_URL + `get-all-application-summary?id=${this.$route.params.id}`
        );

        if (response) {
          // this.applicationVersion = response.data
          response.data.map((list) =>
            this.applicationVersion.push({ version: list.version })
          );

          this.selectedVersion = this.applicationVersion[0].version;
          this.refreshException += 1;
          this.isloading = false;
        }
      } catch (error) {
        this.isloading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    async getAppStatus() {
      const response = await _get(
        APP_URL + `application_status?id=${this.$route.params.id}`
      );

      if (response) {
        this.appStatus = {
          loan_status: response.data.result.status,
          crm_status: response.data.result.crm_status,
        };
      }
    },
  },
  async created() {
    await this.getVersion();
    await this.getAppStatus();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.std-padding-x {
  padding: 0 2.188rem;
}

p {
  margin: 0;
}

.mr-b {
  margin-bottom: 1.875rem;
}

.icons {
  display: flex;
  gap: 1.5rem;
}
.p-card {
  box-shadow: unset !important;
  border-radius: 6px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  height: 100% !important;
}
::v-deep .p-card-title {
  color: #020038 !important;
}
::v-deep .p-card-content {
  font-size: 14px !important;
  color: #020038 !important;
  font-weight: 500;
  font-size: 14px;
}
.relationship {
  color: rgba(2, 0, 56, 0.4);
  font-weight: 600;
  font-size: 16px;
}
.items-content {
  gap: 1rem;
}
::v-deep .date-version {
  background: #eef4fa !important;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
}
::v-deep .p-card .p-card-body {
  padding: 0rem !important;
}
::v-deep .p-card .p-card-content {
  padding: 0rem !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  padding-bottom: 2rem !important;
}
::v-deep .p-card-title {
  border-bottom: 1px solid #ced4da !important;
  padding-bottom: 1rem !important;
}
.content-items {
  padding: 1rem;
}

.task-opt {
  font-size: 1rem !important;
  color: $light-text-color !important;
  cursor: pointer;
  white-space: nowrap;
}
</style>
