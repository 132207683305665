<template>
  <div class="mr-top">
    <Panel :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>
      <template #header>
        <div @click="toggle" class="sub-title full-width pointer">
          {{ title }}
        </div>
      </template>

      <Savings :disable="title === 'Applicant 1' ? false : true" />
      <Shares :disable="title === 'Applicant 1' ? false : true" />
      <Superannuation :disable="title === 'Applicant 1' ? false : true" />
      <!-- <AdditionalProperties :disable="title === 'Applicant 1' ? false : true" /> -->
      <TotalValue
        :disable="title === 'Applicant 1' ? false : true"
        :overallValue="
          title === 'Applicant 1' ? overallValue_1 : overallValue_2
        "
      />

      <!-- <TotalValue
        :title="
          title === 'Applicant 1'
            ? 'Applicant 1 - Income Overview'
            : 'Applicant 2 - Income Overview'
        "
      /> -->
    </Panel>
  </div>
</template>

<script>
import Panel from "primevue/panel";
import TotalValue from "./SubTotalValue.vue";
import Savings from "./Savings.vue";
import Shares from "./Shares.vue";
import Superannuation from "./Superannuation.vue";
// import AdditionalProperties from "./AdditionalProperties.vue";

export default {
  props: ["title"],
  components: {
    Panel,
    Savings,
    Shares,
    Superannuation,
    // AdditionalProperties,
    TotalValue,
  },
  data() {
    return {
      collapsed: false,
      overallValue_1: ["Applicant 1 Total Savings", "-"],
      overallValue_2: ["Applicant 2 Total Savings", "-"],
    };
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.mr-top {
  margin-top: 2.5rem;
}

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid #02003826 !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
