export default {
    namespaced: true,
    state: {
        selectedUserId: null,
        selectedGroup: null,
        info: {},
        loadingPermissions: false,
        taskListPermission: [
            {
                id: 1,
                name: "checkAllTasks",
                label: "Check all tasks"
            },
            {
                id: 2,
                name: "viewTasks",
                label: "View tasks"
            },
            {
                id: 3,
                name: "checkTasks",
                label: "Check tasks"
            },
            {
                id: 4,
                name: "addNewTasks",
                label: "Add new tasks"
            },
            {
                id: 5,
                name: "ModifyExistingTasks",
                label: "Modify existing tasks"
            },
            {
                id: 6,
                name: "deleteTasks",
                label: "Delete tasks"
            }
        ],
        pipelinePermission: [
            {
                id: 1,
                name: "viewPipeline",
                label: "View pipeline"
            },
            {
                id: 2,
                name: "checkAllPipelines",
                label: "Check all pipelines"
            },
            {
                id: 3,
                name: "addNewPipeline",
                label: "Add new pipeline"
            },
            {
                id: 4,
                name: "ModifyExistingPipeline",
                label: "Modify existing pipeline"
            },
            {
                id: 5,
                name: "deletePipeline",
                label: "Delete pipeline"
            }
        ],
        appPermission: [
            {
                id: 1,
                name: "checkAllApplications",
                label: "Check all applications"
            },
            { id: 2, name: "viewApplications", label: "View applications" },
            {
                id: 3,
                name: "toggleApplicationVersion",
                label: "Toggle application version"
            },
            { id: 4, name: "ModifyApplications", label: "Modify applications" },
            {
                id: 5,
                name: "sendOfferToApplicants",
                label: "Send offer to applicants"
            },
            { id: 6, name: "exportApplications", label: "Export applications" },
            { id: 7, name: "saveApplications", label: "Save applications" },
            { id: 8, name: "runDecisionEngine", label: "Run descision engine" },
            { id: 9, name: "splitselected", label: "Split selected" },
            { id: 10, name: "mergeSelected", label: "Merged selected" }
        ],
        taskTypePermission: [],
        getPermissions: {},
        getTasktypePermission: {},
        groupPermissionList: {},  // Edit group
        groupInfo: {}   // Edit group
    },
    getters: {
        selectedUserId: ({ selectedUserId }) => selectedUserId,
        taskListPermission: ({ taskListPermission }) => taskListPermission,
        pipelinePermission: ({ pipelinePermission }) => pipelinePermission,
        appPermission: ({ appPermission }) => appPermission,
        taskTypePermission: ({ taskTypePermission }) => taskTypePermission,
        isBtnDisable: ({ isBtnDisable }) => isBtnDisable,
        getPermissions: ({ getPermissions }) => getPermissions,
        getTasktypePermission: ({ getTasktypePermission }) => getTasktypePermission,
        groupPermissionList: ({ groupPermissionList }) => groupPermissionList,
        groupInfo: ({ groupInfo }) => groupInfo,
        info: ({ info }) => info,
        selectedGroup: ({ selectedGroup }) => selectedGroup,
        loadingPermissions: ({ loadingPermissions }) => loadingPermissions
    },
    mutations: {
        setLoadingPermissions(state, payload) {
            state.loadingPermissions = payload
        },
        setSelectedUserId(state, payload) {
            state.selectedUserId = payload
        },
        setSelectedGroup(state, payload) {
            state.selectedGroup = payload
        },
        setSelctedGroupId(state, payload) {
            state.selectedUserId = payload
        },
        setInfo(state, payload) {
            state.info = payload
        },
        setPermissions(state, payload) {
            state.getPermissions = { ...state.getPermissions, ...payload }

        },
        getTasktypePermission(state, payload) {
            state.getTasktypePermission = { ...state.getTasktypePermission, ...payload }
        },
        setTaskTypePermission(state, payload) {
            payload.data?.result && payload.data?.result.length && payload.data.result.map((task) => {
                if (task.taskTypes !== "") {
                    state.taskTypePermission.push({
                        label: task.taskTypes,
                        name: task.taskTypes,
                    });

                }
            });

        },
        setGroupPermissionList(state, payload) {
            state.groupPermissionList = payload
        },
        setPermissionList(state) {
            state.taskListPermission.forEach((ele) => {
                state.getPermissions[ele.name] = false;
            });
            state.pipelinePermission.forEach((ele) => {
                state.getPermissions[ele.name] = false;
            });
            state.appPermission.forEach((ele) => {
                state.getPermissions[ele.name] = false;
            });
            state.taskTypePermission.forEach((ele) => {
                state.getTasktypePermission[ele.name] = false;
            });
        }

    },
    actions: {
        setLoadingPermissions(context, payload) {
            context.commit('setLoadingPermissions', payload)
        },
        setSelctedGroupId(context, payload) {
            context.commit('setSelctedGroupId', payload)
        },
        setInfo(context, payload) {
            context.commit("setInfo", payload)
        },
        setPermissions(context, payload) {
            context.commit("setPermissions", payload)
        },
        getTasktypePermission(context, payload) {
            context.commit("getTasktypePermission", payload)
        },
        setTaskTypePermission(context, payload) {
            context.commit("setTaskTypePermission", payload)
        },
        setPermissionList(context) {
            context.commit("setPermissionList")
        },
        setGroupPermissionList(context, payload) {
            context.commit("setGroupPermissionList", payload)
        },
        setSelectedGroup(context, payload) {
            context.commit("setSelectedGroup", payload)
        },
        setSelectedUserId(context, payload) {
            context.commit('setSelectedUserId', payload)
        },
    },
}
