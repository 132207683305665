<template>
  <div>
    <div class="title p-d-flex justify-content-between">
      <p class="title-text">Incoming Properties</p>
    </div>
    <div class="form-inputs flex mt-2">
      <div :class="incomingPropertiesCount === 1 ? 'col' : 'p-col-6'">
        <FormInputs
          @validateIncomingProp="validateIncoming1"
          id="1"
          :fieldValues="value[0]"
          :properties="incomingPropertiesCount"
          :responseValue="responseValue"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FormInputs from "./FormInputs.vue";

export default {
  components: { FormInputs },
  props: ["responseValue"],
  data() {
    return {
      disableAdd: false,
      disableMinus: false,
      value: [],
      validateProp1: true,
      validateProp2: true,
    };
  },
  watch: {
    incomingPropertiesCount(value) {
      if (value >= 2 && !this.disableAdd) {
        this.disableAdd = true;
        this.disableMinus = false;
      } else if (value <= 1 && !this.disableMinus) {
        this.disableMinus = true;
        this.disableAdd = false;
      }
    },
    incomingPropertiesValue() {
      this.value = this.incomingPropertiesValue;
    },
  },
  computed: {
    ...mapGetters({
      incomingPropertiesValue: "servicingCalc/incomingProperties",
      incomingPropertiesCount: "servicingCalc/incomingPropertiesCount",
    }),
  },
  methods: {
    ...mapActions({
      changeIncomingPropertyCount: "servicingCalc/changeIncomingPropertyCount",
    }),
    validateIncoming1(value) {
      this.$emit("validateIncoming", value);
      this.validateProp1 = value;
    },
    validateIncoming2(value) {
      this.$emit("validateIncoming", value);
      this.validateProp2 = value;
    },
    increment() {
      this.changeIncomingPropertyCount(2);
      this.$emit("validateIncoming", this.validateProp2);
    },
    decrement() {
      this.changeIncomingPropertyCount(1);
      this.$emit("validateIncoming", this.validateProp1);
    },
  },
  created() {
    this.value = this.incomingPropertiesValue;
    if (this.incomingPropertiesCount >= 2 && !this.disableAdd) {
      this.disableAdd = true;
      this.disableMinus = false;
    } else if (this.incomingPropertiesCount <= 1 && !this.disableMinus) {
      this.disableMinus = true;
      this.disableAdd = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.title {
  border-bottom: 1px solid rgba(2, 0, 56, 0.15);
  padding-bottom: 10px;

  .title-text {
    font-family: Gordita-bold;
    color: $secondary-color;
    font-size: 20px;
  }

  .count {
    user-select: none;

    p {
      font-size: 18px;
      font-family: Gordita-medium-;
      color: $secondary-color;
    }
  }
}

.pointer {
  cursor: pointer;
}

.flex-gap-20 {
  gap: 20px;
}

p {
  margin: 0;
}
</style>
