<template>
  <div class="mr-top">
    <div class="title">{{ title }}</div>
    <div class="heading flex align-items-center justify-content-between">
      <div class="title">Liabilities</div>
      <div class="right-col flex align-items-center">
        <div
          @click="openSplitSelected"
          v-if="restrictUser('splitselected')"
          class="flex align-items-center pointer"
        >
          <img src="@/assets/scissors.svg" alt="" />
          <p class="lt-txt-color fs-14 hk-b ml-2">Split selected</p>
        </div>
        <div
          @click="openMergeSelected"
          v-if="restrictUser('mergeSelected')"
          class="flex align-items-center pointer ml-4"
        >
          <img src="@/assets/minimize-2.svg" alt="" />
          <p class="lt-txt-color fs-14 hk-b ml-2">Merge selected</p>
        </div>
      </div>
    </div>
    <CreditCards
      :key="refreshKey"
      @cardsSelected="cardsSelected"
      :disable="title === 'Applicant 1' ? false : true"
    />
    <MergeSelected
      :disable="title === 'Applicant 1' ? false : true"
      :selectedCards="selectedCards"
      :allCards="allCards"
      :show="showMergeSelected"
      @mergeSelected="mergeSelected"
      @close="showMergeSelected = false"
    />
    <SplitSelected
      :disable="title === 'Applicant 1' ? false : true"
      :selectedCards="selectedCards"
      :allCards="allCards"
      :show="showSplitSelected"
      @splitSelected="splitSelected"
      @close="showSplitSelected = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CreditCards from "./CreditCards.vue";
import MergeSelected from "./MergeSelected.vue";
import SplitSelected from "./SplitSelected.vue";

export default {
  components: { CreditCards, MergeSelected, SplitSelected },
  props: ["title"],
  data() {
    return {
      details: [],
      allCards: [],
      refreshKey: 10,
      selectedCards: [],
      showMergeSelected: false,
      showSplitSelected: false,
      category: {
        id: 1,
        label: "Full time income - Business Name",
      },
    };
  },
  computed: {
    ...mapGetters({ condition: "auth/userPermission" }),
  },
  methods: {
    restrictUser(value) {
      return this.condition[value];
    },
    openSplitSelected() {
      if (this.selectedCards.length != 1) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Select only one liability to split!",
          life: 3000,
        });
        return;
      }
      return (this.showSplitSelected = true);
    },
    openMergeSelected() {
      if (this.selectedCards.length != 2) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Select two liabilities to merge!",
          life: 3000,
        });
        return;
      }
      if (this.selectedCards.length === 2) {
        if (
          this.selectedCards[0].institution == this.selectedCards[1].institution
        ) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Select different institution name!",
            life: 3000,
          });
          return;
        }
      }
      return (this.showMergeSelected = true);
    },
    cardsSelected(payload) {
      this.allCards = payload.allCards;
      this.selectedCards = payload.selectedCards;
    },
    mergeSelected() {
      this.showMergeSelected = false;
      this.refreshKey += 1;
    },
    splitSelected() {
      this.showSplitSelected = false;
      this.refreshKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

.heading {
  border-bottom: 1px solid rgba(2, 0, 56, 0.15);
  padding-bottom: 13px;
}

p {
  margin: 0;
}

.mr-top {
  margin-top: 40px;
}
</style>
