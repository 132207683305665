<template>
  <div class="application-header-layout pt-4 pl-4 pr-4 pb-0">
    <h1 class="module-title white-color">Brokers</h1>
    <div class="flex h-100 mt-4 bold-600">
      <div v-for="(item, i) in list" :key="i">
        <div
          :class="
            item.isActive ? 'application-option' : 'application-option-inactive'
          "
          class="mr-1 cursor-pointer"
          @click="onSelectItem(i)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplicationHeader",
  data() {
    return {
      list: [
        { title: "Broker details", isActive: true },
        { title: "Applications", isActive: false },
        { title: "Communications", isActive: false },
        { title: "Tasks", isActive: false },
        { title: "Documents", isActive: false },
      ],
    };
  },
  methods: {
    onSelectItem(i) {
      this.list.map((obj) => {
        obj.isActive = false;
      });
      this.list[i].isActive = true;
      switch (i) {
        case 0:
          this.$router.push({
            name: "BrokerDetail",
            query: {
              mobile: this.$route.query.mobile,
              email: this.$route.query.email,
              firstName: this.$route.query.firstName,
            },
          });
          break;
        case 1:
          this.$router.push({
            name: "BrokerApplication",
            query: {
              mobile: this.$route.query.mobile,
              email: this.$route.query.email,
              firstName: this.$route.query.firstName,
            },
          });
          break;
        case 2:
          this.$router.push({
            name: "BrokerCommunication",
            query: {
              mobile: this.$route.query.mobile,
              email: this.$route.query.email,
              firstName: this.$route.query.firstName,
            },
          });
          break;
        case 3:
          this.$router.push({
            name: "BrokerTask",
            query: {
              mobile: this.$route.query.mobile,
              email: this.$route.query.email,
              firstName: this.$route.query.firstName,
            },
          });
          break;
        case 4:
          this.$router.push({
            name: "BrokerDocuments",
            query: {
              mobile: this.$route.query.mobile,
              email: this.$route.query.email,
              firstName: this.$route.query.firstName,
            },
          });
          break;
      }
    },
    routeNavigation() {
      switch (this.$route.name) {
        case "BrokerDetail":
          this.onSelectItem(0);
          break;
        case "BrokerApplication":
          this.onSelectItem(1);
          break;
        case "BrokerCommunication":
          this.onSelectItem(2);
          break;
        case "BrokerTask":
          this.onSelectItem(3);
          break;
        case "BrokerDocuments":
          this.onSelectItem(4);
          break;
      }
    },
  },

  watch: {
    $route() {
      this.routeNavigation();
    },
  },
  created() {
    this.routeNavigation();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.application-header-layout {
  min-height: 122px;
  width: 100%;
  background: #9193ad;
}
.white-color {
  color: $white-color;
}
.application-option {
  padding: 5px 10px;
  background: #eef4fa;
  border-radius: 8px 8px 0px 0px;
  color: #020038;
  font-family: Gordita-medium- !important;
}
.application-option-inactive {
  background: rgba(2, 0, 56, 0.2);
  border-radius: 8px 8px 0px 0px;
  color: $white-color;
  padding: 5px 10px;
  font-family: Gordita-medium- !important;
}
.h-100 {
  height: 100%;
}
</style>
