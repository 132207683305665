<template>
  <div class="std-padding-rv">
    <div class="flex align-items-center justify-content-between mb-4">
      <div class="flex align-items-center">
        <div class="title module-title flex align-items-center">Reports</div>
      </div>
    </div>
    <Card>
      <template #title>
        <div class="flex justify-content-between align-items-center pr-3">
          <div class="hk-b title-content fs-18px">
            <img src="@/assets/reporting.svg" width="40" height="40" />
          </div>
          <div class="flex align-items-center items-content pr-2">
            <div class="flex align-items-center flex-gap">
              <div class="label">Report name</div>
              <Dropdown
                class="align-items-center"
                :items="items"
                value="Status"
                placeholder="Select"
                width="200px"
                optionLabel="name"
                optionValue="key"
                @change="getSelectedReport"
              />
            </div>
            <div class="flex align-items-center flex-gap ml-3">
              <div class="label">Reporting period from</div>
              <DatePicker2
                placeholder="DD/MM/YYYY"
                style="width: 100%"
                :maxDate="maxDate"
                :manualInput="true"
                @change="fetchDate($event, 'from')"
                :minYear="minYear"
                :maxYear="maxYear"
                id="from-date"
              />
              <span class="label">to</span>
              <DatePicker2
                placeholder="DD/MM/YYYY"
                style="width: 100%"
                :maxDate="maxDate"
                id="to-date"
                :manualInput="true"
                @change="fetchDate($event, 'to')"
                :value="toDate"
                :minYear="minYear"
                :maxYear="maxYear"
                :minDate="new Date(fromDate)"
                :key="datePicker2Key"
              />
            </div>
            <div class="ml-2">
              <Button
                :disable="isSubmitDisabled()"
                @click="generateReport"
                label="Generate"
                type="primary"
                :loading="isLoading"
              />
            </div>
          </div>
        </div>
      </template>
      <template #content>
        <ReportsTable :key="tableKey" />
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "primevue/card";
import ReportsTable from "./ReportsTable.vue";
import { _get } from "../../../services/crudService";
import { APP_URL } from "../../../../constants";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";

export default {
  components: {
    Card,
    ReportsTable,
  },
  data() {
    return {
      selectedReport: "",
      items: [],
      minDate: null,
      maxYear: "",
      minYear: "2022",
      fromDate: "",
      toDate: "",
      tableKey: 0,
      maxDate: new Date(),
      isLoading: false,
      datePicker2Key: 10,
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      selectedReport: { required },
      // fromDate: { required },
      toDate: { required },
    };
  },
  methods: {
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getSelectedReport(value) {
      this.selectedReport = value;
    },
    fetchDate(value, field) {
      if (value == "") {
        if (field == "from") this.fromDate = "";
        else this.toDate = "";
      }
      if (value && typeof value != "object") {
        if (field == "from") {
          this.fromDate = value;
          if (!moment(value).isBefore(this.toDate)) {
            this.toDate = "";
            this.datePicker2Key += 1;
          }
        } else {
          this.toDate = value;
        }
      }
    },
    async generateReport() {
      try {
        this.isLoading = true;
        let url = "";
        let from = this.fromDate ? moment(this.fromDate).format("YYYY-MM-DD") : "";
        let to = this.toDate ? moment(this.toDate).format("YYYY-MM-DD") : "";
        url = this.fromDate
          ? `report?from_date=${from}&to_date=${to}&key=${this.selectedReport}`
          : `report?to_date=${to}&key=${this.selectedReport}`;
        await _get(APP_URL + url);
        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "Report generated successfully",
          life: 3000,
        });
        this.tableKey += 1;
      } catch (err) {
        if (err && err.response && err.response.data && err.response?.data?.message) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response?.data?.message,
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
      this.isLoading = false;
    },
  },
  async created() {
    const result = await _get(APP_URL + "list-of-reports");
    if (result.data && result.data.result) {
      this.items = result.data.result;
    }
    this.fromDate = "";
    this.toDate = "";
    this.maxYear = new Date().getFullYear();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.p-card {
  box-shadow: unset !important;
  border-radius: 6px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  height: 100% !important;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06) !important;
  border-radius: 8px !important;
}
::v-deep .p-card-title {
  padding: 1rem !important;
  color: #020038 !important;
}
::v-deep .p-card-content {
  font-size: 14px !important;
  color: #020038 !important;
  font-weight: 500;
  font-size: 14px;
}
::v-deep .p-card-body {
  padding: unset !important;
}

.label {
  color: $light-text-color;
  font-family: Gordita-medium-;
  font-size: 16px;
  white-space: nowrap;
}

.flex-gap {
  gap: 15px;
}

.report-content {
  // border-bottom: 1px solid rgba(2, 0, 56, 0.15);
  height: 60vh;
}
.text-field {
  font-size: 1rem;
  font-weight: 600;
  color: rgba(2, 0, 56, 0.3);
}

.title {
  font-size: 36px;
  font-family: Gordita-bold;
  color: $secondary-color;
}
.title-content {
  font-family: Gordita-medium-;
  font-size: 20px;
  color: $secondary-color;
}
.back {
  font-size: 1rem;
  color: rgba(2, 0, 56, 0.3);
}
::v-deep .datepicker-layout .p-inputtext {
  padding-left: 1rem !important;
}
.align-text-center {
  text-align: center;
}
</style>
