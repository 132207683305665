<template>
  <div class="sidebar-container mt-4">
    <nav>
      <div class="header">
        <div class="header-top">
          <div class="nav-items">
            <div v-for="item in menuItems" :key="item.id" class="wrap">
              <router-link :to="item.link" class="a-link">
                <div v-if="item.display" class="nav-item p-d-flex">
                  <div class="icon-wrapper p-d-flex p-ai-center pi-jc-center">
                    <img
                      class="icon"
                      width="29"
                      height="29"
                      :src="require(`@/assets/${item.icon}.svg`)"
                    />
                    <img
                      width="29"
                      height="29"
                      class="icon-active"
                      :src="require(`@/assets/${item.icon}_active.svg`)"
                    />
                  </div>
                  <p class="nav-text">{{ item.text }}</p>
                </div>
              </router-link>
            </div>

            <Spinner
              v-if="isloading"
              class="mt-3 flex justify-content-between"
            />

            <div v-else-if="brokerSummary" class="mt-4 broker-summary">
              <div class="application-summary mt-3 ml-5 capital">
                {{ summary.firstName }}
                {{ summary.lastName }}
              </div>
              <div class="flex ml-5 mr-5 mt-3 pb-3 justify-content-between">
                <div class="flex align-items-center">
                  <img src="@/assets/call-1.svg" />
                  <div class="sub-title-value ml-1">CALL</div>
                </div>
                <div class="flex align-items-center">
                  <img src="@/assets/text.svg" />
                  <div class="sub-title-value ml-1">TEXT</div>
                </div>
                <div class="flex align-items-center">
                  <img src="@/assets/email.svg" />
                  <div class="sub-title-value ml-1">EMAIL</div>
                </div>
              </div>
            </div>

            <div
              class="mt-3"
              v-else-if="
                displaySummary &&
                summary &&
                summary.application_root &&
                summary.application_root.length &&
                summary.calculated_values &&
                summary.calculated_values.length
              "
            >
              <div class="mt-2 divider"></div>
              <div class="application-summary mt-3 ml-5">
                Application summary
              </div>
              <div class="flex ml-4">
                <div class="col-6 col-5">
                  <div class="sub-title opacity bold-600">Loan ID</div>
                  <div class="sub-title-value">
                    {{ summary.application_root[0].loan_id || "-" }}
                  </div>
                </div>
                <div
                  v-if="
                    summary.application_root[0].created_user_type === 'broker'
                  "
                  class="col-6"
                >
                  <div class="sub-title opacity bold-600">Broker ID</div>
                  <div class="sub-title-value">
                    {{
                      summary.application_root[0].created_user_type === "broker"
                        ? summary.brokerId
                        : "-"
                    }}
                  </div>
                </div>
              </div>
              <div class="mt-2 divider"></div>
              <div class="col ml-4 mt-2">
                <div class="sub-title opacity bold-600">Primary Applicant</div>
                <div
                  v-for="applicant of summary.applicants"
                  :key="applicant.Id"
                >
                  <div
                    v-if="applicant.applicant_type === 'primary'"
                    class="sub-title-value capital"
                  >
                    <span v-if="applicant.preferred_name">
                      {{ applicant.preferred_name }}
                      {{ applicant.last_name }}
                    </span>
                    <span v-else>
                      {{ applicant.first_name }}
                      {{ applicant.last_name }}
                    </span>
                  </div>
                </div>
                <div class="sub-title mt-1">
                  <span class="opacity">Status:</span>
                  <span class="ml-2">
                    {{
                      applicationStatus(summary.application_root[0].loan_status)
                    }}</span
                  >
                </div>
                <div class="sub-title mt-1">
                  <span class="opacity">Stage: </span
                  ><span class="ml-1">
                    {{
                      applicationStatus(summary.application_root[0].crm_status)
                    }}
                  </span>
                </div>
                <div class="sub-title flex mt-1">
                  <span class="opacity">Type: </span>
                  <div class="ml-1">
                    <span>
                      {{
                        summary.application_root[0].isPreApproval
                          ? "Pre-approval"
                          : "Conditional offer"
                      }}
                    </span>
                    <br />
                    <span>
                      {{ loanType(summary.application_root[0].loan_purpose) }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="mt-2 divider"></div>
              <div class="flex ml-5 mr-5 mt-3 justify-content-between">
                <div class="sub-title opacity">Loan amount</div>
                <div class="sub-title">
                  {{
                    summary?.calculated_values[0].initial_amount_financed
                      ? "$" +
                        formatCurrency(
                          Number(
                            summary.calculated_values[0].initial_amount_financed
                          )
                        )
                      : "-"
                  }}
                </div>
              </div>
              <div class="flex ml-5 mr-5 justify-content-between">
                <div class="sub-title opacity">Set-up fee</div>
                <div class="sub-title">
                  {{
                    totalSetupFee
                      ? (totalSetupFee * 100).toFixed(2) + " %"
                      : "-"
                  }}
                </div>
              </div>

              <div class="flex ml-5 mr-5 justify-content-between">
                <div class="sub-title opacity">Interest rate</div>
                <div class="sub-title">
                  {{
                    summary.calculated_values[0].cap_loan_rate
                      ? summary.calculated_values[0].cap_loan_rate * 100 + " %"
                      : "-"
                  }}
                </div>
              </div>
              <div class="flex ml-5 mr-5 justify-content-between">
                <div class="sub-title opacity">LVR</div>
                <div class="sub-title">
                  {{
                    summary.calculated_values[0].initial_lvr
                      ? (
                          summary.calculated_values[0].initial_lvr * 100
                        ).toFixed(2) + " %"
                      : "-"
                  }}
                </div>
              </div>
              <div class="flex ml-5 mr-5 justify-content-between">
                <div class="sub-title opacity">End debt</div>
                <div class="sub-title">
                  {{
                    summary.calculated_values[0].end_debt
                      ? "$" +
                        formatCurrency(summary.calculated_values[0].end_debt)
                      : "-"
                  }}
                </div>
              </div>
              <div
                class="
                  flex
                  ml-5
                  mr-5
                  align-items-center
                  justify-content-between
                "
              >
                <div class="sub-title opacity">Application date</div>
                <div class="sub-title">
                  {{ formatDateFilter(summary.application_root[0].createdAt) }}
                </div>
              </div>
              <div class="flex ml-5 mr-5 justify-content-between">
                <div class="sub-title opacity">Settlement date</div>
                <div class="sub-title">
                  {{
                    displaySettlementDate(
                      summary.properties_buyings &&
                        summary.properties_buyings.length
                        ? summary.properties_buyings[0].settlement_date
                        : "",
                      summary.application_root[0].funded_date
                    )
                  }}
                </div>
              </div>
              <div class="flex ml-5 mr-5 justify-content-between">
                <div class="sub-title opacity">Loan maturity date</div>
                <div class="sub-title">
                  {{ displayLoanMaturity }}
                </div>
              </div>
              <div class="flex ml-5 mr-5 justify-content-between">
                <div class="sub-title opacity">End of IFP date</div>
                <div class="sub-title">
                  {{ displayEndIfp }}
                </div>
              </div>
              <div class="mt-2 divider"></div>
              <div
                class="flex flex-wrap ml-5 mr-5 mt-3 justify-content-between"
              >
                <div>
                  <div class="sub-title opacity">Net surplus</div>
                  <div class="sub-title">
                    {{ displayNetSurplus }}
                  </div>
                </div>
                <div>
                  <div class="sub-title opacity">LTI</div>
                  <div class="sub-title">
                    {{ displayLTI }}
                  </div>
                </div>
                <div>
                  <div class="sub-title opacity">DTI</div>
                  <div class="sub-title">
                    {{ displayDTI }}
                  </div>
                </div>
              </div>
              <div class="flex ml-5 mr-5 mt-3 pb-6 justify-content-between">
                <div class="flex align-items-center">
                  <img src="@/assets/call-1.svg" />
                  <div class="sub-title-value ml-1">CALL</div>
                </div>
                <div class="flex align-items-center">
                  <img src="@/assets/text.svg" />
                  <div class="sub-title-value ml-1">TEXT</div>
                </div>
                <div class="flex align-items-center">
                  <img src="@/assets/email.svg" />
                  <div class="sub-title-value ml-1">EMAIL</div>
                </div>
              </div>
            </div>
            <div v-if="showBroker">
              <div class="mt-2 divider"></div>
              <div class="application-summary mt-3 ml-5">Bob Baker</div>

              <div class="flex ml-5 mr-5 mt-3 pb-6 justify-content-between">
                <div class="flex align-items-center">
                  <img src="@/assets/call-1.svg" />
                  <div class="sub-title-value ml-1">CALL</div>
                </div>
                <div class="flex align-items-center">
                  <img src="@/assets/text.svg" />
                  <div class="sub-title-value ml-1">TEXT</div>
                </div>
                <div class="flex align-items-center">
                  <img src="@/assets/email.svg" />
                  <div class="sub-title-value ml-1">EMAIL</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { _get } from "../../services/crudService";
import { APP_URL, SERVER_URL } from "../../../constants";

export default {
  props: ["menuItems", "title"],
  data() {
    return {
      showApplicationSummary: false,
      showBroker: false,
      summary: null,
      isloading: false,
      displaySummary: false,
      brokerSummary: false,
      user: "",
    };
  },
  watch: {
    async updateAppSummary() {
      if (this.$route.name === "CustomerDetail") {
        try {
          this.isloading = true;
          const response = await _get(
            APP_URL + `application_by_id?id=${this.active_application}`
          );
          if (response) {
            this.summary = response.data.result;
            console.log("summary: ",this.summary.application_root[0].created_at)
            // this.user = JSON.parse(response.data.user);

            // console.log(this.user);
            await this.setAppBrokerId(
              response.data?.user ? response.data.user.Id : null
            );
            await this.setApplicationStatus(
              this.summary.application_root.length > 0 &&
                this.summary.application_root[0]
                ? this.summary.application_root[0].loan_status
                : ""
            );
            await this.setApplicationStatusReason(
              this.summary.application_root.length > 0 &&
                this.summary.application_root[0]
                ? this.summary.application_root[0].crm_reason
                : ""
            );
            await this.setApplicationStatusReasonText(
              this.summary.application_root.length > 0 &&
                this.summary.application_root[0]
                ? this.summary.application_root[0].text
                : ""
            );
            this.summary.brokerId = response.data?.user?.broker_id;
            this.showSummary();

            this.isloading = false;
          }
        } catch (error) {
          this.summary = [];

          this.isloading = false;
        }
      } else {
        try {
          this.isloading = true;

          const response = await _get(
            APP_URL + `application_by_id?id=${this.$route.params.id}`
          );
          if (response) {
            this.summary = response.data.result;
            // this.user = JSON.parse(response.data.user);
            console.log("summary:2 ",this.summary.application_root[0].created_at)
            // console.log(this.user);
            await this.setAppBrokerId(
              response.data?.user ? response.data.user.Id : null
            );
            await this.setApplicationStatus(
              this.summary.application_root.length > 0 &&
                this.summary.application_root[0]
                ? this.summary.application_root[0].loan_status
                : ""
            );
            await this.setApplicationStatusReason(
              this.summary.application_root.length > 0 &&
                this.summary.application_root[0]
                ? this.summary.application_root[0].crm_reason
                : ""
            );
            await this.setApplicationStatusReasonText(
              this.summary.application_root.length > 0 &&
                this.summary.application_root[0]
                ? this.summary.application_root[0].text
                : ""
            );
            this.summary.brokerId = response.data?.user?.broker_id;
            this.isloading = false;
            this.showSummary();
          }
        } catch (error) {
          this.summary = [];

          this.isloading = false;
        }
      }
    },
    async $route() {
      this.showSummary();
      if (
        this.$route.name === "CustomerDetail" &&
        (!this.summary ||
          this.summary.application_id !== this.active_application)
      ) {
        try {
          this.isloading = true;
          const response = await _get(
            APP_URL + `application_by_id?id=${this.active_application}`
          );
          if (response) {
            this.summary = response.data.result;
            // this.user = JSON.parse(response.data.user);

            // console.log(this.user);
            await this.setAppBrokerId(
              response.data?.user ? response.data.user.Id : null
            );
            await this.setApplicationStatus(
              this.summary.application_root.length > 0 &&
                this.summary.application_root[0]
                ? this.summary.application_root[0].loan_status
                : ""
            );
            await this.setApplicationStatusReason(
              this.summary.application_root.length > 0 &&
                this.summary.application_root[0]
                ? this.summary.application_root[0].crm_reason
                : ""
            );
            await this.setApplicationStatusReasonText(
              this.summary.application_root.length > 0 &&
                this.summary.application_root[0]
                ? this.summary.application_root[0].text
                : ""
            );
            this.summary.brokerId = response.data?.user?.broker_id;
            this.isloading = false;
          }
        } catch (error) {
          this.summary = [];

          this.isloading = false;
        }
      }

      if (
        this.$route.name === "ApplicationOverview" &&
        (!this.summary || this.summary.application_id != this.$route.params.id)
      ) {
        try {
          this.isloading = true;
          const response = await _get(
            APP_URL + `application_by_id?id=${this.$route.params.id}`
          );
          if (response) {
            this.summary = response.data.result;
            // this.user = JSON.parse(response.data.user);

            // console.log(this.user);
            await this.setAppBrokerId(
              response.data?.user ? response.data.user.Id : null
            );
            await this.setApplicationStatus(
              this.summary.application_root.length > 0 &&
                this.summary.application_root[0]
                ? this.summary.application_root[0].loan_status
                : ""
            );
            await this.setApplicationStatusReason(
              this.summary.application_root.length > 0 &&
                this.summary.application_root[0]
                ? this.summary.application_root[0].crm_reason
                : ""
            );
            await this.setApplicationStatusReasonText(
              this.summary.application_root.length > 0 &&
                this.summary.application_root[0]
                ? this.summary.application_root[0].text
                : ""
            );
            this.summary.brokerId = response.data?.user?.broker_id;
            this.isloading = false;
          }
        } catch (error) {
          this.summary = [];
          this.isloading = false;
        }
      }

      if (
        this.$route.name === "BrokerDetail" &&
        (!this.summary || this.summary.Id != this.$route.params.id)
      ) {
        try {
          this.isloading = true;
          const response = await _get(
            SERVER_URL + "get_broker_crm_by_id?id=" + this.$route.params.id
          );
          if (response) {
            this.summary = response.data[0];
            this.isloading = false;
          }
        } catch (error) {
          this.summary = [];

          this.isloading = false;
        }
      }
    },
  },
  methods: {
    ...mapActions({
      setSummaryValues: "summary/setSummaryValues",
      setApplicationStatus: "applications/setApplicationStatus",
      setApplicationStatusReason: "applications/setApplicationStatusReason",
      setApplicationStatusReasonText:
        "applications/setApplicationStatusReasonText",
      setAppBrokerId: "applications/setAppBrokerId",
      setUserData: "loans/setUserData",
      setLoanCreatedAt: "applications/setLoanCreatedAt",
    }),
    displaySettlementDate(s_d, f_d) {
      if (f_d) {
        return this.formatDateFilter(f_d);
      } else if (s_d) {
        return this.formatDateFilter(s_d);
      } else return "-";
    },
    formatDate(date) {
      const momentDate = moment(date).format("DD/MM/YYYY");

      return momentDate;
    },
    formatCurrency(val) {
      return val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    loanType(type) {
      switch (type) {
        case "renovate":
          return "Renovate before sell";
        case "cash_advance":
          return "Cash advance";
        case "buy_now":
          return "Buy before sell";
        case "equity_release":
          return "Equity Release";
        default:
          return "-";
      }
    },
    showSummary() {
      if (
        this.$route.path.startsWith("/loan/") ||
        this.$route.path.startsWith("/customers/")
      ) {
        this.brokerSummary = false;
        this.displaySummary = true;
        return;
      } else if (this.$route.path.startsWith("/brokers/")) {
        this.displaySummary = false;
        this.brokerSummary = true;
        return;
      } else {
        this.brokerSummary = false;
        this.displaySummary = false;
        return;
      }
    },
    applicationStatus(value) {
      let name;
      if (value) {
        if (value == "created") {
          name = "Application submission";
        } else {
          let string = value.replaceAll("_", " ").toString();
          name = string.charAt(0).toUpperCase() + string.slice(1) || "-";
        }
        return name;
      }
    },
    formatDateFilter(value) {
      if (value) {
        const localDate = new Date(value).toLocaleString("en-US", {
          timeZone: "Australia/Sydney",
        });

        return moment(localDate).format("DD/MM/YYYY");
      }
    },
    async getApplicationSummary() {
      if (
        this.$route.name === "ApplicationOverview" ||
        (!this.summary && this.$route.path.startsWith("/loan/"))
      ) {
        try {
          this.isloading = true;
          const response = await _get(
            APP_URL + `application_by_id?id=${this.$route.params.id}`
          );
          if (response) {
            this.summary = response.data.result;
            // this.user = JSON.parse(response.data.user);
            console.log("summary: " , this.summary);
            this.setUserData(response.data.user || {});
            // console.log(this.user);
            await this.setAppBrokerId(
              response.data?.user ? response.data.user.Id : null
            );
            await this.setLoanCreatedAt(
              this.summary.application_root.length > 0
                ? this.summary.application_root[0].createdAt
                : ""
            );
            await this.setApplicationStatus(
              this.summary.application_root.length > 0 &&
                this.summary.application_root[0]
                ? this.summary.application_root[0].loan_status
                : ""
            );
            await this.setApplicationStatusReason(
              this.summary.application_root.length > 0 &&
                this.summary.application_root[0]
                ? this.summary.application_root[0].crm_reason
                : ""
            );
            await this.setApplicationStatusReasonText(
              this.summary.application_root.length > 0 &&
                this.summary.application_root[0]
                ? this.summary.application_root[0].text
                : ""
            );
            this.summary.brokerId = response.data?.user?.broker_id;
            this.isloading = false;
            this.showSummary();
          }
        } catch (error) {
          this.summary = [];

          this.isloading = false;
        }
      }

      if (
        this.$route.name === "CustomerDetail" ||
        (!this.summary && this.$route.path.startsWith("/customers/"))
      ) {
        try {
          this.isloading = true;
          const response = await _get(
            APP_URL + `application_by_id?id=${this.active_application}`
          );
          if (response) {
            this.summary = response.data.result;
            // this.user = JSON.parse(response.data.user);

            // console.log(this.user);
            this.setUserData({});
            await this.setAppBrokerId(
              response.data?.user ? response.data.user.Id : null
            );
            await this.setApplicationStatus(
              this.summary.application_root.length > 0 &&
                this.summary.application_root[0]
                ? this.summary.application_root[0].loan_status
                : ""
            );
            await this.setApplicationStatusReason(
              this.summary.application_root.length > 0 &&
                this.summary.application_root[0]
                ? this.summary.application_root[0].crm_reason
                : ""
            );
            await this.setApplicationStatusReasonText(
              this.summary.application_root.length > 0 &&
                this.summary.application_root[0]
                ? this.summary.application_root[0].text
                : ""
            );
            this.summary.brokerId = response.data?.user?.broker_id;
            this.isloading = false;
            this.showSummary();
          }
        } catch (error) {
          this.summary = [];

          this.isloading = false;
        }
      }

      if (
        (this.$route.name === "BrokerDetail" && !this.summary) ||
        (!this.summary && this.$route.path.startsWith("/brokers/"))
      ) {
        try {
          this.isloading = true;
          const response = await _get(
            SERVER_URL + "get_broker_crm_by_id?id=" + this.$route.params.id
          );
          if (response) {
            this.summary = response.data[0];
            this.isloading = false;
            this.setUserData({});
            this.showSummary();
          }
        } catch (error) {
          this.summary = [];

          this.isloading = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      active_application: "customers/active_application",
      updateAppSummary: "loanOverview/updateAppSummary",
    }),
    totalSetupFee() {
      return (
        (this.summary?.calculated_values[0].setup_fee ?? 0) +
        (this.summary?.calculated_values[0].broker_fee ?? 0)
      );
    },
    displayLTI() {
      let value = this.summary.calculated_values[0].LTI || "-";

      if (typeof value === "string") {
        return value;
      } else {
        return value.toFixed(2);
      }
    },
    displayNetSurplus() {
      let value = this.summary.calculated_values[0].net_surplus || "-";

      if (typeof value === "string") {
        return value;
      } else {
        return "$" + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      }
    },
    displayDTI() {
      let value = this.summary.calculated_values[0].DTI || "-";

      if (typeof value === "string" && value === "-") {
        return value;
      } else if (typeof value === "string") {
        return Number(value).toFixed(2);
      } else {
        return value.toFixed(2);
      }
    },
    settlementDate() {
      return this.summary?.properties_buyings &&
        this.summary.properties_buyings.length
        ? this.summary.properties_buyings[0].settlement_date
        : null;
    },
    displayLoanMaturity() {
      if (this.settlementDate && this.summary.calculated_values[0].loan_term) {
        const loanTerm = new Number(
          this.summary.calculated_values[0].loan_term
        );
        let maturityDate = new Date(this.settlementDate);
        maturityDate.setMonth(maturityDate.getMonth() + loanTerm);
        return moment(String(maturityDate)).format("DD/MM/YYYY");
      }
      return "-";
    },
    displayEndIfp() {
      if (
        this.settlementDate &&
        this.summary.calculated_values[0].interest_free_term
      ) {
        const ifpTerm = new Number(
          this.summary.calculated_values[0].interest_free_term
        );
        let ifpEndDate = new Date(this.settlementDate);
        ifpEndDate.setMonth(ifpEndDate.getMonth() + ifpTerm);
        return moment(String(ifpEndDate)).format("DD/MM/YYYY");
      }
      return "-";
    },
  },
  async created() {
    await this.getApplicationSummary();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.broker-summary {
  border-top: 0.5px solid #ffffff;
}

.sidebar-container {
  background-color: $secondary-color;
  font-family: Gordita-medium;

  nav {
    width: 100%;
    margin-top: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .header {
      width: 100%;
      .a-link {
        text-decoration: none;
      }

      .nav-item {
        gap: 1.2rem;
        padding-top: 0.4rem !important;
        padding-bottom: 0.4rem !important;
        align-items: self-end;
      }

      .header-top {
        width: 100%;
        .nav-item {
          // width: 82%;
          padding-left: 2rem;
        }
      }

      .icon-wrapper {
        .icon {
          font-size: 1rem;
          // color: $secondary-text-color;
        }

        .icon-active {
          display: none;
        }
      }

      .router-link-active .nav-item {
        width: 100%;
        border-right: 3.7px solid $sky-blue-color;
        background: linear-gradient(
          90deg,
          rgba(84, 183, 249, 0) 29.33%,
          rgba(84, 183, 249, 0.1) 74.81%
        );
      }

      .router-link-active .icon-active {
        color: $white-color !important;
        display: block;
      }

      .router-link-active .icon {
        display: none;
      }

      .router-link-active .nav-text {
        color: $white-color !important;
      }

      .nav-text {
        font-size: 1.1rem;
        color: $secondary-text-color;
        margin: 0;
        white-space: nowrap;
        font-family: Gordita;
        font-weight: bold;
      }

      // HEADER BOTTOM
      .header-bottom {
        border-top: 1px solid $secondary-text-color;
        margin: auto 3rem;
        margin-top: 1.3rem;

        .nav-items {
          &:first-child {
            margin-top: 1rem;
          }
        }
      }
    }
    .footer {
      position: absolute;
      bottom: 1rem;
      p {
        color: $secondary-text-color;
        line-height: 1.7;
        margin: 0;
        font-size: 0.95rem;
      }
    }
  }
}
.divider {
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.application-summary {
  // font-weight: 600;
  font-size: 20px;
  color: #ffffff;
}
.sub-title {
  font-size: 16px;
  color: #ffffff;
}
.opacity {
  opacity: 0.7;
}
.sub-title-value {
  color: #2cdd80;
  font-size: 14px;
}
</style>
