<template>
  <div>
    <p class="title">Write a message</p>
    <div class="form-inputs">
      <div class="flex flex-gap align-items-center">
        <div class="col">
          <p class="label">To</p>
          <TextField
            placeholder="Destination email address"
            width="100%"
            :value="formData.sendToEmail"
            @onInput="getFormData($event, 'sendToEmail')"
            :key="refreshKey"
          />
        </div>
        <div class="col disable-input">
          <p class="label">From</p>
          <TextField
            :value="from"
            :disable="true"
            placeholder="Sender email address"
            width="100%"
            :key="refreshKey"
          />
        </div>
      </div>
      <div class="mt-2">
        <p class="label">Subject</p>
        <TextField
          :value="formData.subject"
          @onInput="getFormData($event, 'subject')"
          placeholder="Email subject"
          width="100%"
        />
      </div>
      <div class="mt-2">
        <div class="mt-2">Body</div>
        <Editor
          :textValue="formData.body"
          @getInputValue="getFormData($event, 'body')"
          class="mt-2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { _get } from "../../../../services/crudService";
import { SERVER_URL } from "../../../../../constants";
export default {
  props: [
    "sendToAddress",
    "showSendToInputs",
    "selectedEmail",
    "customerName",
    "genAppId",
  ],
  data() {
    return {
      formData: {
        sendToEmail: this.$props.selectedEmail || "",
        subject: "Your Bridgit loan scenario",
        body: "",
      },
      from: "",
      refreshKey: 10,
    };
  },
  watch: {
    selectedEmail(value) {
      this.formData.sendToEmail = value;
      this.refreshKey++;
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    if (this.showSendToInputs) {
      return {
        formData: {
          sendToEmail: { required, email },
          subject: { required },
          body: { required },
        },
      };
    }
  },
  methods: {
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };

      this.$emit("isSendToValidate", this.v$.$invalid);
      this.$emit("getSendToData", this.formData);
    },
    async getUserDetails() {
      const response = await _get(SERVER_URL + "get_crm_user_profile");

      this.from = response.data.result[0].email;

      this.refreshKey++;
    },
  },
  created() {
    this.getUserDetails();
    this.formData.body = `Thanks for taking the time to go through the details of your bridging loan requirements with us today </br></br>We have filled out the application with the information discussed, so you can easily continue and submit your application, once you are ready to proceed.</br></br>.To continue your application, click the button below.</br>.Please be sure to review the information already filled out and update if required.
    `;
    this.$emit("getSendToData", this.formData);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.title {
  font-family: Gordita-medium-;
  font-size: 16px;
  color: $secondary-color;
}

p {
  margin: 0;
}

:deep .col,
:deep .col-6 {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.flex-gap {
  gap: 10px;
}

.label,
:deep .label-text {
  font-size: 14px;
  font-family: Gordita-medium;
  color: $secondary-color;
}
</style>
