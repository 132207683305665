<template>
  <div>
    <div class="p-grid align-items-center">
      <div class="p-col-6">
        <p class="label mr-b-5">Intial Decision</p>
        <TextArea
          width="100%"
          height="80px"
          :value="reviewFormData.initial_decision"
          resize="none"
          placeholder="Description"
          @onInput="getFormData($event, 'initial_decision')"
        />
      </div>

      <div class="p-col-6">
        <p class="label mr-b-5">Final Decision</p>
        <TextArea
          width="100%"
          height="80px"
          resize="none"
          placeholder="Description"
          :value="reviewFormData.final_decision"
          @onInput="getFormData($event, 'final_decision')"
        />
      </div>
    </div>

    <div class="mr-t-5">
      <p class="label mr-b-5">Credit History Review</p>
      <TextArea
        width="100%"
        height="80px"
        resize="none"
        placeholder="Description"
        @onInput="getFormData($event, 'credit_history_review')"
        :value="reviewFormData.credit_history_review"
      />
    </div>

    <div class="mr-t-5">
      <p class="label mr-b-5">Summary Notes</p>
      <TextArea
        width="100%"
        height="80px"
        resize="none"
        placeholder="Description"
        @onInput="getFormData($event, 'summary_notes')"
        :value="reviewFormData.summary_notes"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      reviewFormData: {
        credit_history_review: { required },
        summaryNotes: { required },
      },
    };
  },
  emits: ["reviewFormData"],
  data() {
    return {
      reviewFormData: {
        credit_history_review: "",
        summaryNotes: "",
        initial_decision: "",
        final_decision: "",
      },
    };
  },
  computed: {
    ...mapGetters({ selectedApplication: "applications/selectedApplication" }),
  },
  methods: {
    ...mapActions({ setReviewData: "loanDescription/setReviewData" }),
    getFormData(value, fieldName) {
      this.reviewFormData[fieldName] = value;
      this.reviewFormData = { ...this.reviewFormData };
      this.setReviewData({ value: value, type: fieldName });
      this.$emit("validateReviewForm", this.v$.$invalid);
    },
    setData() {
      this.reviewFormData.summary_notes =
        this.selectedApplication?.reviews_data?.summary_notes || "";
      this.reviewFormData.credit_history_review =
        this.selectedApplication?.reviews_data?.credit_history_review || "";
      this.setReviewData({
        value: this.reviewFormData.summary_notes,
        type: "summary_notes",
      });
      this.setReviewData({
        value: this.reviewFormData.credit_history_review,
        type: "credit_history_review",
      });

      this.reviewFormData.initial_decision =
        this.selectedApplication?.initial_decision || "";
      this.setReviewData({
        value: this.reviewFormData.initial_decision,
        type: "initial_decision",
      });

      this.reviewFormData.final_decision =
        this.selectedApplication?.final_decision || "";
      this.setReviewData({
        value: this.reviewFormData.final_decision,
        type: "final_decision",
      });
    },
  },
  created() {
    this.setData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.flex-gap {
  gap: 20px;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.mr-b-5 {
  margin-bottom: 5px;
}

.mr-t-5 {
  margin-bottom: 5px;
}
</style>
