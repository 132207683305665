<template>
  <div>
    <div class="title p-d-flex justify-content-between">
      <p class="title-text">Fees</p>
    </div>
    <div class="mt-2">
      <div class="flex align-items-center justify-content end disable-input">
        <p class="label p-col-6">Pricing Calculator Price</p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          width="100%"
          :disable="true"
          height="30px"
          placeholder="Enter amount"
          :value="formData.pricing_calc_price"
          @onInput="getFormData($event, 'pricing_calc_price')"
          :maxValue="9"
          prefix="$"
          id="fee_pricing_calc_price"
        />
      </div>
      <div class="flex align-items-center justify-content end mt-1">
        <p class="label p-col-6">
          Additional Funds Req<span style="color: red"> * </span>
        </p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          width="100%"
          :value="insertValue('add_funds_requested')"
          height="30px"
          @onInput="getFormData($event, 'add_funds_requested')"
          placeholder="Enter amount"
          :maxValue="9"
          :inValid="v$.formData.add_funds_requested.$invalid && onSave"
          prefix="$"
          id="fee_add_funds_requested"
        />
      </div>
      <div class="flex align-items-center justify-content end mt-1">
        <p class="label text-highlight p-col-6">
          Total Savings<span style="color: red"> * </span>
        </p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          width="100%"
          height="30px"
          placeholder="Enter amount"
          :value="insertValue('total_savings_to_purchase')"
          @onInput="getFormData($event, 'total_savings_to_purchase')"
          :inValid="v$.formData.total_savings_to_purchase.$invalid && onSave"
          :maxValue="9"
          prefix="$"
          id="fee_total_savings_to_purchase"
        />
      </div>
      <div class="flex align-items-center justify-content end mt-1">
        <p class="label text-highlight p-col-6">
          Savings to Contribute to End debt<span style="color: red"> * </span>
        </p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          width="100%"
          height="30px"
          placeholder="Enter amount"
          :value="insertValue('savings_end_debt')"
          @onInput="getFormData($event, 'savings_end_debt')"
          :maxValue="9"
          :inValid="v$.formData.savings_end_debt.$invalid && onSave"
          prefix="$"
          id="fee_savings_end_debt"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, maxValue } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["responseValue"],
  data() {
    return {
      formData: {
        pricing_calc_price: 0,
        add_funds_requested: 0,
        total_savings_to_purchase: 0,
        savings_end_debt: 0,
      },
      refreshKey: 10,
    };
  },
  watch: {
    // responseValue(result) {
    //   this.refreshKey++;
    //   for (const [key, value] of Object.entries(result)) {
    //     if (typeof value === "number") {
    //       this.formData[key] = value.toFixed(2);
    //     } else {
    //       this.formData[key] = value;
    //     }
    //   }
    // },

    calculatedValues(result) {
      this.refreshKey++;

      for (const [key, value] of Object.entries(result)) {
        if (typeof value === "number") {
          this.formData[key] = value.toFixed(2);
        } else {
          this.formData[key] = value;
        }
        if (value === -1) {
          this.formData[key] = "-";
        }
      }
    },
    fees(result) {
      for (const [key, value] of Object.entries(result)) {
        this.formData[key] = value;
      }
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        pricing_calc_price: { required },
        add_funds_requested: { required, maxValue: maxValue(999999999) },
        total_savings_to_purchase: {
          required,
          maxValue: maxValue(999999999),
        },
        savings_end_debt: {
          required,
          maxValue: maxValue(999999999),
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      calculatedValues: "servicingCalc/calculatedValues",
      onSave: "servicingCalc/onSave",
      fees: "servicingCalc/fees",
    }),
  },
  methods: {
    ...mapActions({
      addFees: "servicingCalc/addFees",
    }),
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
      this.addFees({ value, fieldName });
      this.$emit("validateFees1", this.v$.$invalid);
    },
    insertValue(field) {
      if (this.formData[field] === 0) {
        return this.formData[field];
      } else {
        return this.formData[field];
      }
    },
  },
  mounted() {
    this.$emit("validateFees1", this.v$.$invalid);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.title {
  border-bottom: 1px solid rgba(2, 0, 56, 0.15);
  padding-bottom: 10px;

  .title-text {
    font-family: Gordita-bold;
    color: $secondary-color;
    font-size: 20px;
  }
}

p {
  margin: 0;
}

.label {
  padding-right: 0;
  line-height: 19px !important;
  font-size: 14px;
  font-family: Gordita-medium;
  color: $secondary-color;
  text-align: end;
}

.text-highlight {
  font-family: Gordita-bold !important;
}

:deep .p-col-6 {
  padding-top: 0;
  padding-bottom: 0;
}
</style>

<InputNumber
  class="input-field"
  :placeholder="placeholder"
  v-model="editValue"
  :style="{
    height: `${height}`,
    width: `${width}`,
    border: `1px solid ${color} !important`,
    color: `${color}`,
  }"
  :minFractionDigits="minFractionDigits"
  :mode="mode"
  :suffix="suffix"
  @input="onNumberInput"
  :key="refreshKey"
/>
