<template>
  <div class="mr-top" :key="refreshKey">
    <div class="heading flex align-items-center justify-content-between">
      <p class="sub-title">Personal Loans/other liabilities</p>
      <Button @click="addOther" type="primary" label="Add Loan" />
    </div>

    <div class="form-inputs mr-top-10px">
      <div v-for="(item, i) in details" :key="i" class="p-grid align-items-end">
        <!-- Col-1 -->

        <div class="p-col-10">
          <!--Row-1 -->

          <div class="form-inputs mr-top-10px">
            <div class="p-grid align-items-end mr-b-5px btn-icon">
              <div class="p-col-3 p-d-flex flex-gap">
                <Button
                  class="full-width"
                  type="outline"
                  label="Remove Liability"
                  @click="removeOther(i)"
                />
                <!-- <img src="@/assets/Completed.svg" alt="" /> -->
              </div>

              <div class="p-col-5">
                <p class="label">Details</p>
                <TextField
                  @onInput="(e) => getFormData(e, 'details', i)"
                  :value="item.details"
                  width="100%"
                  :inValid="
                    v$.details.$silentErrors[0]?.$response.$data[i].details
                      .$invalid && saveBtnClicked
                  "
                />
              </div>

              <div class="p-col-4">
                <p class="label">Asset Value</p>
                <NumberField
                  mode="decimal"
                  :format="true"
                  prefix="$"
                  @onInput="(e) => getFormData(e, 'asset_value', i)"
                  type="number"
                  :value="item.asset_value"
                  width="100%"
                  :inValid="
                    v$.details.$silentErrors[0]?.$response.$data[i].asset_value
                      .$invalid && saveBtnClicked
                  "
                />
              </div>
            </div>
          </div>

          <!--Row-2 -->

          <div class="form-inputs mr-top-10px">
            <div class="p-grid align-items-end mr-b-5px">
              <div class="p-col-3">
                <p class="label">Monthly Repayment</p>
                <NumberField
                  mode="decimal"
                  :format="true"
                  prefix="$"
                  @onInput="(e) => getFormData(e, 'monthly_repayment', i)"
                  type="number"
                  :value="item.monthly_repayment"
                  width="100%"
                  :inValid="
                    v$.details.$silentErrors[0]?.$response.$data[i]
                      .monthly_repayment.$invalid && saveBtnClicked
                  "
                />
              </div>

              <div class="p-col-3">
                <p class="label">Liability Limit</p>
                <NumberField
                  mode="decimal"
                  :format="true"
                  prefix="$"
                  @onInput="(e) => getFormData(e, 'liability_limit', i)"
                  type="number"
                  :value="item.liability_limit"
                  width="100%"
                  :inValid="
                    v$.details.$silentErrors[0]?.$response.$data[i]
                      .liability_limit.$invalid && saveBtnClicked
                  "
                />
              </div>

              <div class="p-col-3">
                <p class="label">Amount Owing</p>
                <NumberField
                  mode="decimal"
                  :format="true"
                  prefix="$"
                  @onInput="(e) => getFormData(e, 'amount_owning', i)"
                  type="number"
                  :value="item.amount_owning"
                  width="100%"
                  :inValid="
                    v$.details.$silentErrors[0]?.$response.$data[i]
                      .amount_owning.$invalid && saveBtnClicked
                  "
                />
              </div>

              <div class="p-col-3">
                <p class="label">Close Liability?</p>
                <ToggleSwitch
                  :isChecked="item.closeLiability"
                  @onChange="(e) => getFormData(e, 'closeLiability', i)"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Col-2 -->

        <div
          v-if="i === details.length - 1"
          class="p-col-2 mr-b-5px highlight-fields"
        >
          <div class="disable-input">
            <p class="label">Sub Total</p>
            <TotalField
              mode="decimal"
              :format="true"
              prefix="$"
              :value="subTotal"
              :disable="true"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["disable"],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      details: {
        $each: helpers.forEach({
          details: { required },
          asset_value: { required },
          monthly_repayment: { required },
          liability_limit: { required },
          amount_owning: { required },
        }),
      },
    };
  },
  data() {
    return {
      refreshKey: 10,
      disableFields: this.$props.disable,
      details: [],
    };
  },
  methods: {
    ...mapActions({
      setLiability: "applications/setLiability",
      setValidation: "applications/setValidation",
      setPersonalLoanTotal: "subTotal/setPersonalLoanTotal",
      setCloseLiabilityPersonaltotal: "subTotal/setCloseLiabilityPersonaltotal",
    }),
    addOther() {
      let newObj = {
        details: "",
        asset_value: 0,
        monthly_repayment: 0,
        liability_limit: 0,
        amount_owning: 0,
        closeLiability: false,
      };
      this.details = [...this.details, newObj];
      return (this.disableFields = false);
    },
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    removeOther(i) {
      this.details.splice(i, 1);
      if (this.details.length === 0) {
        this.setPersonalLoanTotal({
          data: 0,
          type: !this.disable ? "primary" : "secondary",
        });
      }
      this.refreshKey += 1;
    },
    getFormData(value, field, index) {
      this.details[index][field] = value;
      this.details = [...this.details];
    },
    setData() {
      if (this.selectedApplication.application_id) {
        if (
          this.selectedApplication.liability_others &&
          this.selectedApplication.liability_others.length > 0
        ) {
          this.details = !this.disable
            ? this.selectedApplication.liability_others.filter(
                (item) => item.applicant_type === "primary"
              )
            : this.selectedApplication.liability_others.filter(
                (item) => item.applicant_type === "secondary"
              );
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      saveBtnClicked: "applications/saveBtnClicked",
      selectedApplication: "applications/selectedApplication",
    }),
    subTotal: {
      get: function () {
        let closeLiabilitytotal = 0;
        let total = this.details.reduce((previousValue, currentValue) => {
          if (!currentValue.closeLiability) {
            return (
              Number(previousValue) + Number(currentValue?.monthly_repayment)
            );
          } else {
            closeLiabilitytotal =
              closeLiabilitytotal + Number(currentValue?.monthly_repayment);
            return previousValue;
          }
        }, 0);
        this.setPersonalLoanTotal({
          data: total,
          type: !this.disable ? "primary" : "secondary",
        });
        this.setCloseLiabilityPersonaltotal({
          data: closeLiabilitytotal,
          type: !this.disable ? "primary" : "secondary",
        });
        return total;
      },
    },
  },
  created() {
    this.setData();
  },
  watch: {
    details: {
      handler() {
        this.setLiability({
          data: this.details,
          type: !this.disable ? "primary" : "secondary",
          liability_type: "others",
        });
        this.setValidation({
          type: "liability_others",
          value: this.isSubmitDisabled(),
        });
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

::v-deep .p-col-10,
.p-col-3,
.p-col-2,
.p-col-9,
.p-col-6,
.p-col-5,
.p-col-4 {
  padding-bottom: 0;
  padding-top: 0;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.full-width {
  width: 100%;
}

.highlight-fields {
  .label {
    font-family: Gordita-bold !important;
  }
}

.heading {
  background: rgba(2, 0, 56, 0.03);
  padding: 12px;

  p {
    margin: 0;
  }
}

.mr-b-5px {
  margin-bottom: 15px;
}

.mr-top {
  margin-top: 2.5rem;
}

.mr-top-10px {
  margin-top: 10px;
}

.flex-gap {
  gap: 0.625rem;
}

.flex-gap-1 {
  gap: 0.313rem;
}

.flex-gap-2 {
  gap: 1.875rem;
}
</style>
