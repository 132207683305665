<template>
  <div>
    <Card class="position-relative box-shadow">
      <template #title>
        <div class="module-sub-title">{{ title }}</div>
        <div class="flex align-items-center justify-content-betweens overflow-auto">
          <div class="col white-sp-nowrap">
            Total tasks
            <span class="hk-sb ml-2 sec-color-txt">
              {{ (statements && statements.totalCount) || 0 }}
            </span>
          </div>
          <div class="flex align-items-center">
            <span class="p-input-icon-left ml-2 full-width">
              <i class="pi pi-search" />
              <TextField
                width="100%"
                type="text"
                v-model="value1"
                placeholder="Search"
                @onInput="getSearchValue($event)"
              />
            </span>
            <div class="col ml-3 flex align-items-center">
              Status
              <Dropdown
                :key="selectedTask"
                :items="status"
                :value="statusSelected"
                @change="getDropdownValue($event)"
                width="120px"
                height="34px"
                class="ml-3"
              />
            </div>
            <!-- <div
              v-if="selectedTask === 'All tasks'"
              class="col ml-3 flex align-items-center"
            >
              Assigned&nbsp;to
              <Dropdown
                :key="selectedTask"
                :items="status"
                :value="statusSelected"
                @change="getDropdownValue($event)"
                width="120px"
                height="34px"
                class="ml-3"
              />
            </div> -->
            <div
              @click="addNewTasks"
              v-if="restrictUser('addNewTasks')"
              class="col flex white-sp-nowrap align-items-center ml-3 cursor-pointer task-opt"
            >
              <span class="mr-2">+</span> New tasks
            </div>

            <!-- <div
              @click="completeTasks"
              v-if="restrictUser('ModifyExistingTasks')"
              class="col flex white-sp-nowrap align-items-center ml-3"
              :class="disableComplete ? '' : 'pointer task-opt'"
            >
              <span class="mr-2">+</span> Complete
            </div>

            <div
              @click="cancelTasks"
              v-if="restrictUser('ModifyExistingTasks')"
              class="col flex white-sp-nowrap align-items-center ml-3"
              :class="disableCancel ? '' : 'pointer task-opt'"
            >
              <span class="mr-2">+</span> Cancel
            </div>

            <div
              @click="OpenReAssign"
              v-if="restrictUser('ModifyExistingTasks')"
              class="
                col
                cursor-pointer
                pointer
                task-opt
                flex
                white-sp-nowrap
                align-items-center
                ml-3
                pointer
              "
            >
              <span class="mr-2">+</span> Re-assign
            </div> -->
          </div>
        </div>
      </template>

      <template #content>
        <Spinner v-if="isLoading" class="flex justify-content-between" />
        <DataTable
          v-else
          @page="onChangePage($event)"
          :rows="10"
          dataKey="Id"
          :value="statements.result"
          :rowsPerPageOptions="[10, 20, 30]"
          :rowHover="true"
          class="p-datatable-customers"
        >
          <template #empty> No data found. </template>
          <template #loading> Loading data. Please wait. </template>
          <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->

          <Column field="Id" header="">
            <template #body="slotProps">
              <div class="flex align-items-center justify-content-center">
                <input
                  type="checkbox"
                  class="table-checkbox pointer"
                  name="slotProps.data.Id"
                  :value="{
                    Id: slotProps.data.Id,
                    status: slotProps.data.status,
                    assignTo: slotProps.data.assignTo,
                    regardingTo: slotProps.data.regardingTo,
                    regardingToId: slotProps.data.regardingToId,
                    progressType: slotProps.data.progress_type,
                    application_id: slotProps.data.application_id,
                    crm_status: slotProps.data.crm_status,
                    taskId: [{ Id: slotProps.data.Id }],
                  }"
                  v-model="selectedRow"
                />
              </div>
            </template>
          </Column>

          <Column field="taskTitle" header="Task">
            <template #body="slotProps">
              <a
                class="color-highlight cursor-pointer"
                @click="goToEditTask(slotProps.data.Id)"
                >{{ slotProps.data.taskTitle }}</a
              >
            </template>
          </Column>
          <Column field="loan_id" header="Loan ID">
            <template #body="slotProps"
              ><a class="color-highlight pointer" @click="goToApp(slotProps.data)">{{
                slotProps.data.loan_id ? slotProps.data.loan_id : "-"
              }}</a>
            </template>
          </Column>
          <Column field="keyContact" header="Key contact">
            <template #body="slotProps"
              ><a
                v-if="slotProps.data.primary_applicant"
                class="color-highlight pointer"
                @click="goToCustomer(slotProps.data)"
                >{{ slotProps.data.primary_applicant }}</a
              >
              <p v-else>
                {{
                  slotProps.data.primary_applicant
                    ? slotProps.data.primary_applicant
                    : "-"
                }}
              </p>
            </template>
          </Column>
          <Column field="task_type" header="Type">
            <template #body="slotProps">
              {{ displayTaskType(slotProps.data.task_type, slotProps.data.taskType) }}
            </template>
          </Column>
          <Column field="status" header="Status">
            <template #body="slotProps">
              {{ slotProps.data.status ? slotProps.data.status : "-" }}
            </template>
          </Column>
          <Column field="assignedToType" header="Assigned to">
            <template #body="slotProps">
              {{ slotProps.data.assignedToType ? slotProps.data.assignedToType : "-" }}
            </template>
          </Column>
          <Column field="dueDate" header="Due date">
            <template #body="slotProps" class="not-assigned">
              <div style="width: 100%; text-align: end">
                <span class="not-assigned" style="text-align: end">
                  {{ slotProps.data.dueDate ? formatDate(slotProps.data.dueDate) : "-" }}
                </span>
              </div>
            </template>
          </Column>
          <Column field="dateCompleted" header="Date completed">
            <template #body="slotProps" class="not-assigned">
              <div style="width: 100%; text-align: end">
                <span class="not-assigned" style="text-align: end">
                  {{
                    slotProps.data.dateCompleted
                      ? formatDate(slotProps.data.dateCompleted)
                      : "N/A"
                  }}
                </span>
              </div>
            </template>
          </Column>
        </DataTable>

        <Paginator
          class="mt-2"
          :rows="10"
          :totalRecords="statements && statements.totalCount"
          :rowsPerPageOptions="[10, 20, 30]"
          template="RowsPerPageDropdown  CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} of {totalRecords} tasks"
          @page="onChangePage($event)"
        ></Paginator>
      </template>
    </Card>
    <AddTask
      :show="showAddTask"
      @save="refreshKey++"
      @close="showAddTask = false"
      v-if="showAddTask"
      :key="addTaskKey"
    />
    <EditTask
      v-if="showEditTask"
      :key="editTaskKey"
      @save="refreshKey++"
      :show="showEditTask"
      :taskId="selectedTaskId"
      @close="showEditTask = false"
      portal="user"
    />
    <ReAssignTask
      v-if="showReAssign"
      @save="refreshKey++"
      :key="reAssignKey"
      :show="showReAssign"
      :taskId="selectedTaskId"
      @close="showReAssign = false"
      portal="user"
    />
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Card from "primevue/card";
import AddTask from "../Applications/ApplicationData/Task/AddTask.vue";
import EditTask from "../Applications/ApplicationData/Task/EditTask.vue";
import ReAssignTask from "../Applications/ApplicationData/Task/ReAssign.vue";
import { _get, _put } from "../../../services/crudService";
import Paginator from "primevue/paginator";
import { SERVER_URL } from "../../../../constants";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "index",
  components: {
    DataTable,
    Column,
    Paginator,
    Card,
    EditTask,
    AddTask,
    ReAssignTask,
  },
  props: ["title", "selectedTask", "assignedTo", "assignedToType"],
  watch: {
    async selectedTask() {
      this.statusSelected = "open";
      await this.getTasks(1, 10);
    },
    async assignedTo() {
      this.statusSelected = "open";
      await this.getTasks(1, 10);
    },
    searchValue(value) {
      if (value !== "") {
        clearTimeout(this.updateSearch);

        this.updateSearch = setTimeout(async () => {
          this.getTasks(1, 10, value);
        }, 800);
      }
    },

    selectedRow() {
      if (this.selectedRow.length === 0) {
        this.disableComplete = false;
        this.disableCancel = false;
      }

      this.selectedRow.map((task) => {
        if (
          (task.status === "Open" || task.status === "open") &&
          task.assignTo !== "User" &&
          task.assignTo !== "Group"
        ) {
          return (this.disableComplete = true);
        }
        if (task.status === "completed" || task.status === "review") {
          return (this.disableCancel = true);
        }
        this.disableComplete = false;
        this.disableCancel = false;
      });
    },
    refreshKey() {
      this.getTasks(1, 10);
    },
  },

  data() {
    return {
      showAddTask: false,
      showEditTask: false,
      showReAssign: false,
      disableComplete: false,
      disableCancel: false,
      status: ["open", "complete"],
      selectedRow: [],
      statusSelected: "open",
      selectionKeys: null,
      selectedCustomers: null,
      statements: [],
      isLoading: true,
      selectedRequests: null,
      selectionMode: null,
      selectedTaskId: "",
      task: this.$props.selectedTask,
      refreshKey: 0,
      updateSearch: null,
    };
  },
  computed: {
    ...mapGetters({ condition: "auth/userPermission" }),
  },
  methods: {
    addNewTasks() {
      this.showAddTask = true;
    },
    displayTaskType(task_type, taskType) {
      if (task_type) {
        return task_type;
      } else if (taskType) {
        return taskType;
      } else return "-";
    },
    goToEditTask(id) {
      if (this.restrictUser("ModifyExistingTasks")) {
        this.selectedTaskId = id;

        this.showEditTask = true;
      }
    },
    formatDate(date) {
      let dateFormat = date.includes("/");
      if (dateFormat === false) {
        return moment(date).format("DD/MM/YYYY HH:MM");
      } else {
        if (dateFormat) {
          let date1 = date.split("/");
          let dateFormat1 = (date1[2] + "-" + date1[1] + "-" + date1[0]).toString();
          return moment(dateFormat1).format("DD/MM/YYYY HH:MM");
        }
      }
    },
    async getDropdownValue(value, row = 10, page = 1) {
      this.isLoading = true;
      this.showAddTask = false;

      if (this.selectedTask === "My tasks") {
        const response = await _get(
          SERVER_URL +
            `get_my_tasks_crm?pageSize=${row}&pageIndex=${page}&field=status&value=${value}`
        );
        this.statements = response.data;
        this.isLoading = false;
      } else if (this.selectedTask === "All tasks") {
        const response = await _get(
          SERVER_URL +
            `get_All_tasks_crm?pageSize=${row}&pageIndex=${page}&field=status&value=${value}&assignedToId=${this.assignedTo}&assignedType=${this.assignedToType}`
        );
        this.statements = response.data;
        this.isLoading = false;
      } else if (this.selectedTask === "New tasks") {
        const response = await _get(
          SERVER_URL +
            `get_new_tasks_crm?pageSize=${row}&pageIndex=${page}&field=status&value=${value}&assignedToId=${this.assignedTo}`
        );
        this.statements = response.data;
        this.isLoading = false;
      }
    },
    async getSearchValue(value, row = 10, page = 1, search = "") {
      this.isLoading = true;

      if (this.selectedTask === "My tasks") {
        const response = await _get(
          SERVER_URL +
            `get_my_tasks_crm?pageSize=${row}&pageIndex=${page}&field=status&value=${value}&search=${search}`
        );
        this.statements = response.data;
        this.isLoading = false;
      } else if (this.selectedTask === "All tasks") {
        const response = await _get(
          SERVER_URL +
            `get_All_tasks_crm?pageSize=${row}&pageIndex=${page}&field=status&value=${value}&search=${search}&assignedToId=${this.assignedTo}&assignedType=${this.assignedToType}`
        );
        this.statements = response.data;
        this.isLoading = false;
      } else if (this.selectedTask === "New tasks") {
        const response = await _get(
          SERVER_URL +
            `get_new_tasks_crm?pageSize=${row}&pageIndex=${page}&field=status&value=${value}&search=${search}&assignedToId=${this.assignedTo}`
        );
        this.statements = response.data;
        this.isLoading = false;
      }
    },
    OpenReAssign() {
      this.reAssignKey += 1;
      if (this.selectedRow.length && this.selectedRow.length === 1) {
        this.showReAssign = true;

        this.selectedTaskId = this.selectedRow[0].Id;
      }
    },
    async completeTasks() {
      if (this.selectedRow.length !== 0 && !this.disableComplete) {
        let obj = {
          taskId: [],
          application_id: this.selectedRow[0].application_id,
          progressType: this.selectedRow[0].progressType,
          crm_status: this.selectedRow[0].crm_status,
        };

        this.selectedRow.map((list) => {
          obj["taskId"].push({ Id: list.Id });
        });

        this.isloading = true;

        await _put(SERVER_URL + "update_task_completed?portal=application", obj)
          .then(() => {
            this.selectedRow = [];
            this.refreshKey++;
            this.isloading = false;

            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Task updated successfully",
              life: 3000,
            });
          })
          .catch(() => {
            this.isloading = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Cannot complete this task(s)",
              life: 3000,
            });
          });
      }
    },
    async cancelTasks() {
      if (this.selectedRow.length !== 0 && !this.disableCancel) {
        let data = [];
        this.selectedRow.map((Id) => {
          data.push({ id: Id.Id, status: "cancelled" });
        });

        try {
          const response = await _put(SERVER_URL + "cancel_tasks", data);

          response &&
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Task Cancelled successfully",
              life: 3000,
            });

          if (response) {
            this.selectedRow = [];
            this.refreshKey++;
          }
        } catch (err) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
    },
    goToCus() {
      //   this.$emit("change")
      this.$router.push("/customers/c1/detail");
    },
    goToApp(link) {
      if (link.application_id) {
        this.$router.push(
          `/loan/${link.application_id}/overview?loanId=${link.loan_id}&declaration=true`
        );
      }
    },
    goToCustomer(data) {
      if (data.primary_applicant_id) {
        this.$router.push(
          `/customers/${data.primary_applicant_id}/detail?mobile=${data.mobile}&email=${data.mobile}&firstName=${data.first_name}`
        );
      }
    },
    onChangePage(value) {
      this.getTasks(value.page + 1, value.rows);
    },
    async getTasks(page, row) {
      this.isLoading = true;
      this.showAddTask = false;

      try {
        if (this.selectedTask === "My tasks") {
          const response = await _get(
            SERVER_URL +
              `get_my_tasks_crm?pageSize=${row}&pageIndex=${page}&field=status&value=${this.statusSelected}`
          );
          this.statements = response.data;
          this.isLoading = false;
        } else if (this.selectedTask === "All tasks") {
          const response = await _get(
            SERVER_URL +
              `get_All_tasks_crm?pageSize=${row}&pageIndex=${page}&field=status&value=${this.statusSelected}&assignedToId=${this.assignedTo}&assignedType=${this.assignedToType}`
          );
          this.statements = response.data;
          this.isLoading = false;
        } else if (this.selectedTask === "New tasks") {
          const response = await _get(
            SERVER_URL +
              `get_new_tasks_crm?pageSize=${row}&pageIndex=${page}&field=status&value=${this.statusSelected}&assignedToId=${this.assignedTo}`
          );
          this.statements = response.data;
          this.isLoading = false;
        }
      } catch (err) {
        this.isLoading = false;
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
    },
    restrictUser(value) {
      return this.condition[value];
    },
  },

  async created() {
    await this.getTasks(1, 10);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.p-card {
  box-shadow: unset !important;
  border-radius: 0.25rem !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
}
::v-deep .p-card-title {
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem !important;
}

:deep .p-card-body {
  padding: 30px !important;
}

:deep .p-dropdown .p-dropdown-label {
  line-height: 17px !important;
  color: $secondary-color !important;
}

::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
.color-highlight {
  color: $primary-color !important;
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}
::v-deep .p-inputtext {
  font-family: Gordita-medium !important;
}
.not-assigned {
  color: rgba(2, 0, 56, 0.3);
  text-align: end;
  td {
    text-align: end !important;
  }
}
::v-deep .p-inputtext {
  color: black !important;
}

:deep .p-focus {
  border-color: gray !important;
  box-shadow: unset !important;
}

:deep .p-checkbox-box:hover {
  border-color: gray !important;
}

.white-sp-nowrap {
  white-space: nowrap;
}

.module-sub-title {
  margin-bottom: 15px;
}

.overflow-auto {
  overflow: auto;
}

.white-sp-nowrap {
  white-space: nowrap;
}

.text-right {
  text-align: right !important;
  white-space: nowrap;
}

p {
  margin: 0;
}

.table-checkbox {
  width: 18px !important;
  height: 18px !important;
}

:deep .input-field {
  padding-left: 2rem !important;
}
</style>
