<template>
  <div>
    <div v-for="(item, i) in details" :key="i">
      <div class="sub-title">
        <Checkbox2
          :id="item"
          :name="item.self_emp_trading_name"
          :value="item"
          @getInputValue="(e) => selectIncome(e, item)"
          class="mb-2"
        />
        <label>
          {{ `Self-employement income - ${item.self_emp_trading_name}` }}
        </label>
      </div>

      <div class="form-inputs mt-4">
        <div class="p-grid align-items-center header">
          <p class="p-col-2"></p>
          <p class="p-col-2 label">Frequency</p>
          <p class="p-col-2 label">Application Value</p>
          <p class="p-col-2 label">Manual Value</p>
          <p class="p-col-2 label">Used Value</p>
        </div>

        <!--Salary -->
        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Income</p>
          <div class="p-col-2">
            <Dropdown
              :items="frequencyOpt"
              :value="item.self_emp_frequency"
              @change="(e) => getFormData(e, 'self_emp_frequency', i)"
              width="100%"
              placeholder="label"
            />
          </div>
          <div
            class="p-col-2 relative flex align-items-center justify-content-center disable-input"
          >
            <NumberField
              prefix="$"
              :value="item.self_emp_salary"
              :fullWidth="true"
              :disable="true"
              width="100%"
              placeholder="label"
            />
            <div class="orange-dot dot"></div>
          </div>

          <div class="p-col-2 relative flex align-items-center justify-content-center">
            <NumberField
              type="number"
              :value="item.self_emp_salary_manual"
              @onInput="(e) => getFormData(e, 'self_emp_salary_manual', i)"
              :fullWidth="true"
              prefix="$"
              width="100%"
            />
            <div class="blue-dot dot"></div>
          </div>
          <div class="p-col-2 relative flex align-items-center justify-content-center">
            <Dropdown
              class="full-width"
              :items="usedValueOpts"
              optionLabel="name"
              optionValue="value"
              :value="item.self_emp_salary_used_value"
              @change="(e) => getFormData(e, 'self_emp_salary_used_value', i)"
              width="100%"
              placeholder="label"
            />
            <div
              :class="{
                'orange-dot': item.self_emp_salary_used_value === 'application_value',
                'green-dot': item.self_emp_salary_used_value === 'verified_value',
                'blue-dot': item.self_emp_salary_used_value === 'manual_value',
              }"
              class="dot dot-position"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox2 from "@/components/common/Checkbox2";
import { mapState, mapActions } from "vuex";

export default {
  props: ["disable"],
  components: { Checkbox2 },
  data() {
    return {
      details: [],
      frequencyOpt: ["Weekly", "Fortnightly", "Monthly", "Quarterly", "Yearly"],
      usedValueOpts: [
        {
          name: "App",
          value: "application_value",
        },
        {
          name: "Manual",
          value: "manual_value",
        },
      ],
      category: {
        id: 1,
        label: "Self employment income - Business Name",
      },
      selectedIncome: [],
    };
  },
  computed: {
    ...mapState({
      selfIncome: (state) => state.applications.selfIncome,
      selfEmpUpdated: (state) => state.applications.selfEmpUpdated,
    }),
  },
  methods: {
    ...mapActions({
      setSelfEmployeeIncome: "applications/setSelfEmployeeIncome",
      setSelfEmpUpdated: "applications/setSelfEmpUpdated",
    }),
    selectIncome(value, incomes) {
      this.selectedIncome = !value
        ? this.selectedIncome.filter(
            (item) => item.self_emp_trading_name != incomes.self_emp_trading_name
          )
        : [...this.selectedIncome, incomes];
      this.$emit("incomeSelected", {
        selectedIncome: this.selectedIncome,
        allIncome: this.details,
        type: "self_employed",
      });
    },
    getFormData(value, field, income) {
      this.details[income][field] = value;
      this.details = [...this.details];
      //Updating the used value based on the dropdown selected
      switch (field) {
        case "self_emp_salary_manual":
          if (this.details[income].self_emp_salary_used_value == "manual_value") {
            this.details[income]["self_emp_salary_used"] = this.details[income][
              "self_emp_salary_manual"
            ]
              ? this.details[income]["self_emp_salary_manual"]
              : 0;
          }
          break;
      }

      //Salary
      if (field == "self_emp_salary_used_value") {
        switch (value) {
          case "application_value":
            this.details[income]["self_emp_salary_used"] = this.details[income][
              "self_emp_salary"
            ]
              ? this.details[income]["self_emp_salary"]
              : 0;
            break;
          case "verified_value":
            this.details[income]["self_emp_salary_used"] = this.details[income][
              "self_emp_salary_verified"
            ]
              ? this.details[income]["self_emp_salary_verified"]
              : 0;
            break;
          case "manual_value":
            this.details[income]["self_emp_salary_used"] = this.details[income][
              "self_emp_salary_manual"
            ]
              ? this.details[income]["self_emp_salary_manual"]
              : 0;
            break;
        }
      }

      this.details[income].total_income_amount_manual = Number(
        this.details[income].self_emp_salary_manual
      );

      this.setSelfEmployeeIncome({
        data: this.details,
        type: !this.disable ? "primary" : "secondary",
      });
      this.setSelfEmpUpdated(this.selfEmpUpdated + 1);
    },
    setData() {
      this.details = !this.disable
        ? this.selfIncome.applicant_1
        : this.selfIncome.applicant_2;
    },
  },
  created() {
    this.setData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

.sub-title {
  margin: 0;
  margin-top: 1.25rem;
  background: rgba(2, 0, 56, 0.03);
  padding: 10px;
  display: flex;
  align-items: center;
}

:deep .p-field-checkbox {
  margin-bottom: 0;
}

::v-deep .p-col-2 {
  padding-left: 0;
}

.relative {
  position: relative;
}

.dot-position {
  right: 45px !important;
}

.dot {
  position: absolute;
  right: 20px;
}

.header {
  ::v-deep .p-col-2 {
    padding-bottom: 0;
  }
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

p {
  margin: 0;
}
</style>
