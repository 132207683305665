<template>
  <div class="pl-4 pr-4 pb-4">
    <Spinner v-if="isLoading" class="flex justify-content-between" />
    <Card v-show="!isLoading">
      <template #title>
        <div class="title-wrapper">
          <div class="std-padding-x flex">
            <h3
              @click="changingTab('SummaryPart')"
              :class="component === 'SummaryPart' ? 'title-active' : 'lt-txt-color'"
              class="title"
            >
              Summary
            </h3>
            <h3
              @click="changingTab('VerfiedValues')"
              :class="component === 'VerfiedValues' ? 'title-active' : 'lt-txt-color'"
              class="title ml-5"
            >
              Verified values
            </h3>
          </div>
        </div>
      </template>
      <template #content>
        <SummaryPart v-show="component === 'SummaryPart'" />
        <VerfiedValues :key="verifyRefreshKey" v-show="component === 'VerfiedValues'" />

        <!-- <component :is="component" :cmp="component"></component> -->
      </template>
    </Card>
    <Serviceability
      @versionSaved="versionSaved"
      v-show="component === 'SummaryPart' && !isLoading"
      class="mt-5"
    />

    <ConfirmDialog
      v-if="showConfirmDialog"
      :show="showConfirmDialog"
      @close="showConfirmDialog = false"
      @save="leavePage"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Card from "primevue/card";
import SummaryPart from "./SummaryPart/index.vue";
import VerfiedValues from "./Verified-values/index.vue";
import Serviceability from "./SummaryPart/Serviceability/index.vue";
import { detailedDiff } from "deep-object-diff";
import ConfirmDialog from "./confirmDialog.vue";
const _ = require("lodash");

export default {
  components: {
    Card,
    Serviceability,
    SummaryPart,
    VerfiedValues,
    ConfirmDialog,
  },
  data() {
    return {
      component: "SummaryPart",
      verifyRefreshKey: 10,
      summaryRefreshKey: 20,
      isLoading: false,
      showConfirmDialog: false,
      toRoutePath: "",
      discardChanges: false,
    };
  },
  computed: {
    ...mapGetters({
      oldApplication: "applications/oldApplication",
    }),
  },
  methods: {
    leavePage() {
      this.discardChanges = true;
      this.showConfirmDialog = false;
      this.$router.push(this.toRoutePath);
    },
    ...mapActions({
      getApplicationVersions: "applications/getApplicationVersions",
      setSelectedApplicationEmpty: "applications/setSelectedApplicationEmpty",
      getNewVersionData: "applications/getNewVersionData",
      setIncomeEmpty: "subTotal/setIncomeEmpty",
      setAssetsEmpty: "applications/setAssetsEmpty",
    }),
    async versionSaved() {
      this.setSelectedApplicationEmpty();
      this.isLoading = true;
      await this.getApplicationVersions(this.$route.params.id);
      this.isLoading = false;
    },
    changingTab(cmp) {
      if (cmp === "VerfiedValues") {
        this.verifyRefreshKey += 1;
      }
      this.summaryRefreshKey += 1;
      return (this.component = cmp);
    },
    paths(dataObj) {
      const reducer = (aggregator, val, key) => {
        let paths = [key];
        if (_.isObject(val)) {
          paths = _.reduce(val, reducer, []);
          paths = _.map(paths, (path) => key + "." + path);
        }
        aggregator.push(...paths);
        return aggregator;
      };
      const arrayIndexRegEx = /\.(\d+)/gi;
      let paths = _.reduce(dataObj, reducer, []);
      paths = _.map(paths, (path) => path.replace(arrayIndexRegEx, "[$1]"));

      return paths;
    },
    ordinal_suffix_of(i) {
      var j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    },
  },
  async beforeRouteLeave(to, from, next) {
    let oldApplication = { ...this.oldApplication };
    delete oldApplication.summary;
    let newApplication = await this.getNewVersionData();
    const resultObj = await detailedDiff(oldApplication, newApplication);
    console.log(resultObj, "--");

    // Fetch updated values
    let changedValues = [];

    const updatedPaths = this.paths(resultObj.updated);
    let originalObject = this.oldApplication;
    delete originalObject.summary;
    let updatedObject = await this.getNewVersionData();
    changedValues = [];

    updatedPaths.forEach((path) => {
      const original = _.get(originalObject, path);
      const updatedValue = _.get(updatedObject, path);
      const fullPath = path;

      changedValues.push({
        original,
        updatedValue,
        label: fullPath,
      });
    });

    // Fetch add values
    const addedPaths = this.paths(resultObj.added);

    addedPaths.forEach((path) => {
      const original = "";
      const updatedValue = _.get(updatedObject, path);
      const fullPath = path;

      changedValues.push({
        original,
        updatedValue,
        label: fullPath,
      });
    });

    let list = [];

    changedValues.map((obj) => {
      const sentence = obj.label.split(".");
      const mappedSentence = sentence.map((item, i) => {
        // check to see if it is [0] or [1] whatever
        let digit = item.match(/\d+/);
        if (item.match(digit) && i == 0) {
          let val = item.split("[");
          let numberValue = this.ordinal_suffix_of(Number(digit) + 1);
          return val[0] + " " + numberValue;
        }
        return item;
      });
      obj.label = mappedSentence.join(" ");
      if (obj.label == "qouted_setup_fee") obj.label = "quoted_setup_fee";
      if (obj.updatedValue != "" || obj.original != "") {
        if (
          (obj.original == "" && obj.updatedValue == "0") ||
          (obj.updatedValue == "" && obj.original == "0") ||
          obj.original == obj.updatedValue
        ) {
          console.log("insdie if");
        } else {
          if (obj.label != "no_of_dependents") list.push(obj);
        }
        //   &&
        //   obj.updatedValue != obj.original &&
        //   obj.updatedValue != "" &&
        //   obj.original != "0"
        // )
      }
    });

    changedValues = list;

    if (changedValues.length && !this.discardChanges) {
      this.toRoutePath = to.fullPath;
      this.showConfirmDialog = true;
      next(false);
    } else {
      this.toRoutePath = "";
      next();
    }
  },
  async created() {
    this.setSelectedApplicationEmpty();
    this.setIncomeEmpty();
    this.setAssetsEmpty();
    this.isLoading = true;
    await this.getApplicationVersions(this.$route.params.id);
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-card .p-card-body {
  padding: 0;
}

.std-padding-x {
  padding: 0 2.188rem;
}

p {
  margin: 0;
}

.mr-b {
  margin-bottom: 1.875rem;
}

.title-wrapper {
  border-bottom: 1px solid #02003826;

  .title {
    font-size: 1.25rem;
    padding: 1.25rem 0;
    color: $light-text-color;
    font-family: Gordita-medium-;
    margin: 0;
    cursor: pointer;
  }

  .title-active {
    border-bottom: 3.5px solid $primary-color;
    color: $primary-color;
  }
}
.fs-18px {
  font-size: 1.125rem;
}
</style>
