<template>
  <div class="datepicker-layout flex align-items-center">
    <i class="pi pi-calendar ml-2" />
    <Calendar
      v-model="editValue"
      :numberOfMonths="numberOfMonths"
      selectionMode="range"
      placeholder="Filter by date"
    >
      <template #footer>
        <div class="flex justify-content-end align-items-center mt-4 pb-3">
          <div @click="onClear" class="cursor-pointer">Clear all</div>
          <Button
            @click="onClickSubmit"
            class="ml-3"
            type="primary"
            label="Filter by date"
          />
        </div>
        <div class="error">{{ errorMessage }}</div>
      </template>
    </Calendar>
  </div>
</template>

<script>
import Calendar from "primevue/calendar"

export default {
  name: "Datefilter",
  components: {
    Calendar,
  },
  props: [
    "numberOfMonths",
    "placeholder",
    "required",
    "label",
    "value",
    "minDate",
    "maxDate",
    "yearRange",
  ],
  data() {
    return {
      editValue: "",
      displayValue: "",
      isOpen: true,
      menu: false,
      buttonKey: 0,
      errorMessage: "",
    }
  },
  methods: {
    onClear() {
      this.editValue = ""
    },
    onClickSubmit() {
      if (this.editValue && this.editValue.length) {
        this.$emit("filter", this.editValue)
        this.errorMessage = null
      } else {
        this.errorMessage = "Please select from and to date"
      }
    },
    getDate(value) {
      this.editValue = value
      this.buttonKey += 1
    },
    isSubmitDisabled() {
      if (this.editValue && this.editValue.length && this.editValue == 2) {
        return false
      } else {
        return true
      }
    },
  },
  watch: {
    editValue() {
      this.$emit("change", this.editValue)
      this.buttonKey += 1
    },
  },
  mounted() {
    this.editValue = this.value
  },
}
</script>

<style lang="scss">
@import "@/styles/global.scss";

.p-datepicker table td {
  padding: 0 !important;
}
.p-datepicker table td > span {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 0 !important;
  font-size: 14px !important;
}
.p-datepicker table th > span {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 4px !important;
  font-size: 14px !important;
}
.p-datepicker table td > span.p-highlight {
  background: #fdf0e8 !important;
}
.p-datepicker .p-datepicker-header {
  border-bottom: unset !important;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: unset !important;
}
.p-datepicker {
  padding: 1rem 2rem !important;
}
.datepicker-layout {
  border: 1px solid rgba(100, 97, 170, 0.3);
  border-radius: 4px !important;
  background: $white-color !important;
  .p-inputtext {
    border: unset !important;
    font-family: $font-family !important;
    background: $white-color !important;
  }
  .p-inputtext:enabled:focus {
    box-shadow: unset !important;
  }
}
.error {
  color: red;
  font-size: 14px;
  font-weight: 500;
}
</style>
