<template>
  <Dialog
    :draggable="false"
    :visible="show"
    position="top"
    :modal="show"
    :style="{ width: '680px' }"
  >
    <template #header>
      <h1 class="modal-title">Merge Selected</h1>
    </template>
    <div class="modal-content">
      <div class="flex align-items-center">
        <p class="col-2 flex justify-content-end text hk-md">Trading Name</p>
        <div
          v-for="(item, index) in selectedIncome"
          :key="index"
          class="col-5 flex fg-10 align-items-center"
        >
          <Checkbox2
            :id="item"
            :name="item.self_emp_trading_name"
            :value="income.self_emp_trading_name == item.self_emp_trading_name"
            @getInputValue="(e) => selectIncome(e, item)"
            :key="refreshKey"
          />
          <div class="mb-2" :for="item.self_emp_trading_name">
            {{ item.self_emp_trading_name }}
          </div>
        </div>
      </div>

      <div :key="refreshKey" class="flex align-items-center">
        <p class="col-2"></p>
        <div class="col-10">
          <div>
            <p class="label">Trading name</p>
            <TextField :value="employerName" :disable="true" width="100%" />
          </div>
        </div>
      </div>
      <!-- Salary -->
      <div :key="refreshKey" class="form-inputs mt-2">
        <div class="p-grid align-items-center header">
          <p class="p-col-2"></p>
          <p class="p-col flex justify-content-start label">Application Value</p>
          <p class="p-col label">Manual Value</p>
        </div>

        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Income</p>
          <div class="p-col flex align-items-center justify-content-center disable-input">
            <TextField
              :value="calculatedSalaryApplicationValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div class="p-col flex align-items-center justify-content-center">
            <TextField
              :value="calculatedSalaryManualValue"
              :disable="true"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button
          label="Merge selected"
          type="primary"
          @click="saveMerged"
          :disabled="!employerName"
        />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">Close</div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Dialog from "primevue/dialog";
import Checkbox2 from "@/components/common/Checkbox2";

export default {
  name: "AddTask",
  props: ["show", "selectedIncome", "allIncome", "disable", "type"],
  components: { Dialog, Checkbox2 },
  data() {
    return {
      income: {},
      otherIncome: {},
      refreshKey: 10,
      employerName: "",
      //Salary
      calculatedSalaryApplicationValue: null,
      calculatedSalaryVerifiedValue: null,
      calculatedSalaryManualValue: null,
    };
  },
  computed: {
    ...mapState({
      selfEmpUpdated: (state) => state.applications.selfEmpUpdated,
    }),
  },
  methods: {
    ...mapActions({
      setSelfEmployeeIncome: "applications/setSelfEmployeeIncome",
      setSelfEmpUpdated: "applications/setSelfEmpUpdated",
    }),
    saveMerged() {
      let allArr = this.allIncome.filter(
        (item) =>
          item.self_emp_trading_name != this.income.self_emp_trading_name &&
          item.self_emp_trading_name != this.otherIncome.self_emp_trading_name
      );
      let arr = [
        ...allArr,
        {
          self_emp_trading_name: this.employerName,
          self_emp_salary: this.calculatedSalaryApplicationValue,
          self_emp_salary_used: this.calculatedSalaryManualValue,
          self_emp_salary_used_value: "manual_value",
          applicant_type: !this.disable ? "primary" : "secondary",
          self_emp_frequency: "Monthly",
          self_emp_salary_manual: this.calculatedSalaryManualValue,
        },
      ];
      this.setSelfEmployeeIncome({
        data: arr,
        type: !this.disable ? "primary" : "secondary",
      });
      this.setSelfEmpUpdated(this.selfEmpUpdated + 1);
      this.$emit("mergeSelected");
    },
    selectIncome(value, income) {
      console.log("income ", income);
      this.income = {
        ...income,
        self_emp_salary_manual: income.self_emp_salary_manual
          ? Number(income.self_emp_salary_manual)
          : 0,
        self_emp_salary: income.self_emp_salary ? Number(income.self_emp_salary) : 0,
        self_emp_salary_used: income.self_emp_salary_used
          ? Number(income.self_emp_salary_used)
          : 0,
      };
      this.otherIncome = this.selectedIncome.find(
        (item) => item.self_emp_trading_name != income.self_emp_trading_name
      );
      this.otherIncome = {
        ...this.otherIncome,
        self_emp_salary_manual: this.otherIncome.self_emp_salary_manual
          ? Number(this.otherIncome.self_emp_salary_manual)
          : 0,
        self_emp_salary: this.otherIncome.self_emp_salary
          ? Number(this.otherIncome.self_emp_salary)
          : 0,
        self_emp_salary_used: this.otherIncome.self_emp_salary_used
          ? Number(this.otherIncome.self_emp_salary_used)
          : 0,
      };
      this.employerName = this.income.self_emp_trading_name;
      //Salary
      this.calculatedSalaryApplicationValue =
        this.income.self_emp_salary + this.otherIncome.self_emp_salary;
      this.calculatedSalaryManualValue =
        this.income.self_emp_salary_manual + this.otherIncome.self_emp_salary_manual;
      this.refreshKey += 1;
    },
    close() {
      this.refreshKey += 1;
      this.$emit("close");
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.checkbox-list {
  border: 1px solid rgba(2, 0, 56, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
}

.color-highlight {
  color: $secondary-color;
  font-family: Gordita-medium;
  font-size: 14px;
}

.modal-title {
  font-family: Gordita-medium-;
  font-size: 20px;
  color: $secondary-color;
}

.text,
label,
.label {
  font-family: Gordita-medium-;
  font-size: 14px;
  color: $secondary-color;
}

.color-highlight {
  color: $primary-color;
  text-decoration: underline;
}

p {
  margin: 0;
}

.fg-10 {
  gap: 10px;
}
::v-deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ced4da !important;
  border-color: #2196f3;
}
</style>
