<template>
  <div class="ql-container">
    <Editor v-model="value" editorStyle="height: 150px">
      <template #toolbar>
        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
          <button class="ql-strike"></button>
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
          <button class="ql-link" value="bullet"></button>
          <button class="ql-image" value="bullet"></button>
        </span>
      </template>
    </Editor>
  </div>
</template>

<script>
import Editor from "primevue/editor"
export default {
  name: "EditorComponent",
  components: {
    Editor,
  },
  props: ["textValue"],
  data() {
    return {
      value: this.$props.textValue || "",
      formats: [
        ["bold", "italic"],
        ["link", "image"],
      ],
    }
  },
  watch: {
    value(text) {
      this.$emit("getInputValue", text)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.p-editor-container {
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid rgba(2, 0, 56, 0.15);
  border-radius: 4px !important;
}
::v-deep .ql-container.ql-snow {
  border: unset !important;
}
::v-deep .p-editor-content {
  border-radius: 0px !important;
}
::v-deep .p-editor-toolbar.ql-snow {
  border: unset !important;
}
::v-deep .p-editor-toolbar {
  border-radius: 0px !important;
  background: #ffffff !important;
}
</style>
