<template>
  <Card class="login-card">
    <template #title>
      <p class="hk-b header-text">Log in to your account</p></template
    >
    <template #content>
      <div class="mb-3">
        <div class="mb-1 label">E-mail or username</div>

        <TextField
          @getInputValue="getFormData($event, 'email')"
          placeholder="Enter your email or username"
          :value="formData.email"
          width="100%"
        />
      </div>

      <div class="mb-1 label">Password</div>
      <TextField
        type="password"
        placeholder="Enter your password"
        @getInputValue="getFormData($event, 'password')"
        width="100%"
        :value="formData.password"
      />
      <Button
        :disabled="isSubmitDisabled()"
        @click="signin"
        class="mt-5"
        label="Sign in"
        width="100%"
        type="primary"
      />
      <div class="apply-now flex justify-content-center mt-3"></div>
    </template>
  </Card>
</template>

<script>
import Card from "primevue/card";
import { mapActions } from "vuex";
import { required, email } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { _post } from "../../../services/crudService";
import { SERVER_URL } from "../../../../constants";

export default {
  name: "Login",
  components: {
    Card,
  },
  data() {
    return {
      formData: {
        email: "",
        password: "",
      },
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        email: { required, email },
        password: { required },
      },
    };
  },
  methods: {
    ...mapActions({ setLogin: "auth/setLogin" }),
    async signin() {
      try {
        const response = await _post(SERVER_URL + "login", this.formData);

        if (response) {
          this.setLogin(response);
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
      this.$router.push("/dashboard");
    },
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  height: 100% !important;
  width: 408px;
}

.label {
  font-family: Gordita-medium;
  font-size: 16px;
}

.header-text {
  font-size: 28px;
  margin: 0;
}

:deep .p-card-body {
  padding: 2.5rem 2.5rem 2rem 2.5rem !important;
}

:deep .p-float-label {
  margin: 0 !important;
}

::v-deep .p-card-title {
  color: #020038 !important;
  margin: 0 !important;
}

::v-deep .p-card-content {
  font-size: 14px !important;
  color: #020038 !important;
  font-weight: 500;
  font-size: 14px;
  padding: 0 !important;
  margin-top: 24px;
}
.apply-now {
  color: rgba(2, 0, 56, 0.4) !important;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  text-align: center;
}
</style>
