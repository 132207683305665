<template>
  <div class="mr-b">
    <Card>
      <template #title>
        <div class="title-wrapper">
          <div
            class="
              std-padding-x
              flex
              align-items-center
              justify-content-between
            "
          >
            <p class="title">Serviceability</p>
            <Button
              @click="openReqDialog"
              label="Request income & expenses"
              type="outline"
            />
            <!-- <h3 class="title ml-5">Verified values</h3> -->
          </div>
        </div>
      </template>

      <template #content>
        <IncomeDetails :key="refreshKey" />
        <Expenses :key="refreshKey" />
        <Assets :key="refreshKey" />
        <Liabilities :key="refreshKey" />
        <Footer
          @versionSaved="versionSaved"
          :key="refreshKey"
          class="std-margin-x mt-5"
        />
      </template>
    </Card>
    <RequestDialog :show="showReqDialog" @close="showReqDialog = false" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Card from "primevue/card";
import IncomeDetails from "./IncomeDetails/index.vue";
import Expenses from "./Expenses/index.vue";
import Assets from "./Assets/index.vue";
import Liabilities from "./Liabilities/index.vue";
import Footer from "./Footer.vue";
import RequestDialog from "./RequestDialog.vue";

export default {
  components: {
    IncomeDetails,
    Card,
    Expenses,
    Assets,
    Liabilities,
    Footer,
    RequestDialog,
  },
  data() {
    return {
      collapsed: false,
      showReqDialog: false,
      refreshKey: 10,
    };
  },
  computed: {
    ...mapGetters({
      selectedApplication: "applications/selectedApplication",
    }),
  },
  methods: {
    versionSaved() {
      this.$emit("versionSaved");
    },
    toggle() {
      this.collapsed = !this.collapsed;
    },
    openReqDialog() {
      return (this.showReqDialog = true);
    },
  },
  watch: {
    selectedApplication() {
      this.refreshKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.std-padding-x {
  padding: 0 2.188rem;
}

.std-margin-x {
  margin: 0 2.188rem;
}

.flex-gap {
  gap: 0.625rem;
}

.title-wrapper {
  padding: 1.25rem 0 1rem 0;
}

.title {
  font-size: 2rem;
  font-family: Gordita-bold;
  color: $secondary-color;
}

.box {
  background: #ffffff;
  border: 1px solid #2cdd80;
  box-sizing: border-box;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 1.25rem;
  color: #2cdd80;
}

p {
  margin: 0;
}
</style>
