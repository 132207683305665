<template>
  <div>
    <div
      class="flex flex-wrap align-items-center justify-content-between std-padding-x mr-b"
    >
      <div class="flex align-items-center">
        <div class="flex align-items-center mr-4">
          <p class="hk-b lt-txt-color fs-18px">Application Version</p>
          <Dropdown
            :key="selectorKey"
            class="ml-4"
            placeholder="Select Version"
            :items="applicationVersions"
            optionLabel="version"
            :disable="!restrictUser('toggleApplicationVersion')"
            optionValue=""
            :value="selectedVersion"
            @change="setSelectedVersion"
          />
        </div>
        <div class="flex align-items-center">
          <p class="hk-b lt-txt-color fs-18px">Status</p>
          <Dropdown
            :key="statusSelectorKey"
            :items="statusOpt"
            class="ml-3"
            placeholder="please select"
            :value="applicationStatus"
            optionValue="value"
            optionLabel="title"
            @change="updateStatus"
            id="status"
          />
        </div>
        <!-- <div
          @click="changeStatus"
          v-if="showConfirmIcon"
          class="check-icon pointer ml-3"
        >
          <div class="pi pi-check"></div>
        </div> -->
        <div v-if="showReason" class="flex align-items-center">
          <div class="flex align-items-center ml-3">
            <p class="hk-b lt-txt-color fs-18px">Reason</p>
            <Dropdown
              :key="refreshKey"
              :items="reasonsList()"
              class="ml-3"
              placeholder="please select"
              :value="selectedReason"
              @change="selectReason"
              maxWidth="220px"
              id="reason"
            />
          </div>
          <TextField
            :key="textKey"
            class="ml-2"
            placeholder="Enter a reason"
            width="100%"
            @onInput="getFormData($event)"
            :value="textReason"
            id="manualReason"
          />
          <!-- <div
            @click="updateLoanStatus"
            :class="activeCheckIcon && 'check-icon-active'"
            class="check-icon pointer ml-3"
          >
            <div class="pi pi-check"></div>
          </div> -->
        </div>
        <div
          @click="updateLoanStatus"
          :class="activeCheckIcon && 'check-icon-active'"
          class="check-icon pointer ml-3"
        >
          <div class="pi pi-check"></div>
        </div>
        <ProgressSpinner
          v-if="isloading"
          class="ml-3"
          style="width: 30px; height: 30px"
        />
      </div>
      <div class="icons">
        <img class="pointer" @click="goToDecisionSeeker" src="@/assets/play.svg" alt="" />
        <!-- <img
          v-if="restrictUser('sendOfferToApplicants')"
          class="pointer"
          @click="showSendOffer"
          src="@/assets/mail-icon.svg"
          alt=""
        /> -->
        <img
          class="pointer"
          v-if="restrictUser('sendOfferToApplicants')"
          @click="showExportDialog"
          src="@/assets/external-link.svg"
          alt=""
        />
      </div>
    </div>

    <Summary :key="summaryKey" />
    <PropertyDetails :key="summaryKey" />

    <SendOffer :show="displaySendEmail" @close="displaySendEmail = false" />
    <Export
      :key="exportKey"
      :show="displayExportDialog"
      @close="displayExportDialog = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Summary from "./Summary/index.vue";
import PropertyDetails from "./PropertyDetails/index.vue";
import SendOffer from "./SendOffer.vue";
import Export from "./Export.vue";
import lodash from "lodash";
import ProgressSpinner from "primevue/progressspinner";

export default {
  components: { Summary, PropertyDetails, SendOffer, Export, ProgressSpinner },
  data() {
    return {
      selectedVersion: {},
      applicationStatus: "",
      isloading: false,
      loanStatus: "",
      showReason: false,
      showConfirmIcon: false,
      activeCheckIcon: false,
      selectorKey: 10,
      selectedReason: "",
      showTextReason: false,
      textReason: "",
      statusSelectorKey: 20,
      summaryKey: 0,
      refreshKey: 40,
      textKey: 50,
      exportKey: 70,
      displaySendEmail: false,
      displayExportDialog: false,
      reasons: ["Customer unable to afford loan", "Other"],
      onHoldReasons: [
        "Purchase Fell Through",
        "Client Still Researching Options",
        "Client Aiming for Simultaneous Settlement",
        "Other",
      ],
      droppdedOffReasons: [
        "Incomplete application",
        "Issues with Title",
        "Less than the minimum loan amount",
        "No longer require bridging - Other",
        "No longer require bridging - No longer buying",
        "No longer require bridging - Other Funder",
        "No longer require bridging - Settlement dates Lined up",
        "No response",
        "Require longer-term bridging",
        "Unknown",
        "Other",
      ],
      withdrawnReasons: [
        "Bridging too expensive",
        "Did not want to refinance an existing mortgage",
        "Issues with Title",
        "Less than the minimum loan amount",
        "No longer require bridging - Other",
        "No longer require bridging - No longer buying",
        "No longer require bridging - Other Funder",
        "No longer require bridging - Settlement dates Lined up",
        "Not a bridging scenario",
        "Require longer-term bridging",
        "Unknown",
        "Other",
      ],
      rejectedReasons: [
        "Does not service",
        "Issues with Title",
        "Less than the minimum loan amount",
        "Not a bridging scenario",
        "Not within Policy",
        "Not within Policy - Construction",
        "Not within Policy - Credit Score",
        "Not within Policy - LVR",
        "Not within Policy - Prop Category",
        "Other",
      ],
      writtenOffReasons: ["Other"],
      statusOpt: [
        {
          title: "Open",
          value: "open",
        },
        {
          title: "On Hold",
          value: "on_hold",
        },
        {
          title: "Dropped Off",
          value: "dropped_off",
        },
        {
          title: "Withdrawn",
          value: "withdrawn",
        },
        {
          title: "Rejected",
          value: "rejected",
        },
        {
          title: "Written Off",
          value: "written_off",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      applicationVersions: "applications/applicationVersions",
      selectedApplication: "applications/selectedApplication",
      currentApplicationStatus: "applications/currentApplicationStatus",
      condition: "auth/userPermission",
      currentApplicationReason: "applications/currentApplicationReason",
      currentApplicationReasonText: "applications/currentApplicationReasonText",
    }),
  },
  methods: {
    ...mapActions({
      getApplicationVersions: "applications/getApplicationVersions",
      setSelectedApplication: "applications/setSelectedApplication",
      setSelectedApplicationEmpty: "applications/setSelectedApplicationEmpty",
      updateApplicantStatus: "applications/updateApplicantStatus",
      getOldVersionData: "applications/getOldVersionData",
      setSaveBtnClicked: "applications/setSaveBtnClicked",
      setApplicationStatus: "applications/setApplicationStatus",
      reloadAppSummary: "loanOverview/reloadAppSummary",
      setIncomeEmpty: "subTotal/setIncomeEmpty",
      setAssetsEmpty: "applications/setAssetsEmpty",
    }),
    reasonsList() {
      switch (this.applicationStatus) {
        case "on_hold":
          return this.onHoldReasons;
        case "dropped_off":
          return this.droppdedOffReasons;
        case "withdrawn":
          return this.withdrawnReasons;
        case "rejected":
          return this.rejectedReasons;
        case "written_off":
          return this.writtenOffReasons;
        default:
          return;
      }
    },
    restrictUser(value) {
      return this.condition[value];
    },
    getFormData(value) {
      this.textReason = value;
    },
    selectReason(value) {
      this.selectedReason = value;
      this.activeCheckIcon = false;
      if (value == "Other" || value == "No longer require bridging - Other") {
        this.showTextReason = true;
      } else {
        this.showTextReason = false;
        this.textReason = "";
      }
    },

    async updateLoanStatus() {
      let reason = "";
      reason = this.selectedReason;
      if (!reason) return;

      try {
        this.isloading = true;
        await this.updateApplicantStatus({
          appId: this.$route.params.id,
          status: this.applicationStatus,
          reason,
          text: this.textReason,
        });
        this.isloading = false;
        this.activeCheckIcon = true;
        this.$toast.add({
          severity: "success",
          detail: "Application Status Updated Successfully",
          life: 3000,
        });
      } catch (err) {
        this.isloading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Oops! Error in updating application status",
          life: 3000,
        });
      }
    },
    async changeStatus() {
      try {
        this.isloading = true;
        await this.updateApplicantStatus({
          appId: this.$route.params.id,
          status: this.currentApplicationStatus,
        });
        this.isloading = false;
        this.showConfirmIcon = false;

        this.$toast.add({
          severity: "success",
          detail: "Application Status Updated Successfully",
          life: 3000,
        });
      } catch (err) {
        this.isloading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Oops! Error in updating application status",
          life: 3000,
        });
      }
    },
    async updateStatus(value) {
      this.activeCheckIcon = false;

      // if (this.loanStatus !== value && value == "open") {
      //   this.showConfirmIcon = true;
      // } else {
      //   this.showConfirmIcon = false;
      // }

      this.setApplicationStatus(value);

      if (value !== "open") {
        this.showReason = true;
        return;
      }

      this.showReason = false;
      // try {
      //   await this.updateApplicantStatus({
      //     appId: this.$route.params.id,
      //     status: value,
      //   });

      //   this.$toast.add({
      //     severity: "success",
      //     detail: "Application Status Updated Successfully",
      //     life: 3000,
      //   });
      // } catch (err) {
      //   this.$toast.add({
      //     severity: "error",
      //     summary: "Error",
      //     detail: "Oops! Error in updating application status",
      //     life: 3000,
      //   });
      // }
    },
    async setSelectedVersion(version) {
      this.setIncomeEmpty();
      this.setAssetsEmpty();
      await this.setSelectedApplication(version);
      this.selectedVersion = version;
      this.summaryKey += 1;
    },
    showSendOffer() {
      return (this.displaySendEmail = true);
    },
    showExportDialog() {
      this.exportKey += 1;
      return (this.displayExportDialog = true);
    },
    goToDecisionSeeker() {
      return this.$router.push({
        name: "DecisionSeeker",
        query: {
          loanId: this.$route.query.loanId,
          created_user_type: this.$route.query.created_user_type,
        },
      });
    },
    getVersion(value) {
      console.log(JSON.parse(value.summary));
    },
  },
  async created() {
    this.setSaveBtnClicked(false);
    this.selectedVersion = this.selectedApplication;
    this.applicationStatus = this.currentApplicationStatus;
    this.reasonsList();
    this.selectedReason = this.currentApplicationReason;
    this.textReason = this.currentApplicationReasonText;
    this.showTextReason = this.textReason;
    if (this.applicationStatus !== "open") {
      this.showReason = true;
    } else {
      this.showReason = false;
    }
    this.refreshKey += 1;
    this.selectorKey += 1;
    this.textKey += 1;
    this.reloadAppSummary();
    await this.getOldVersionData(lodash.cloneDeep(this.selectedApplication));
    // this.loanStatus = this.applicationStatus;
  },
  async mounted() {
    await this.getOldVersionData(lodash.cloneDeep(this.selectedApplication));
    this.activeCheckIcon = true;
  },
  watch: {
    currentApplicationStatus() {
      this.applicationStatus = this.currentApplicationStatus;
      this.statusSelectorKey += 1;
      if (this.applicationStatus !== "open") {
        this.showReason = true;
      } else {
        this.showReason = false;
      }
    },
    currentApplicationReason() {
      this.reasonsList();
      this.selectedReason = this.currentApplicationReason;
      this.textReason = this.currentApplicationReasonText;
      this.showTextReason = this.textReason;
      this.refreshKey += 1;
      this.textKey += 1;
      if (this.applicationStatus !== "open") {
        this.showReason = true;
      } else {
        this.showReason = false;
      }
    },
    currentApplicationReasonText() {
      this.reasonsList();
      this.selectedReason = this.currentApplicationReason;
      this.textReason = this.currentApplicationReasonText;
      this.showTextReason = this.textReason;
      this.refreshKey += 1;
      this.textKey += 1;
      if (this.applicationStatus !== "open") {
        this.showReason = true;
      } else {
        this.showReason = false;
      }
    },

    async applicationVersions() {
      let version = { version: 1 };
      if (this.applicationVersions.length > 0) {
        version = this.applicationVersions[0];
      }
      await this.setSelectedApplication(version);
      this.selectedVersion = version;
      this.selectorKey += 1;
      this.summaryKey += 1;
      await this.getOldVersionData(lodash.cloneDeep(this.selectedApplication));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.check-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid $primary-text-color;
  color: $primary-text-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.check-icon-active {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: $primary-color;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}

.std-padding-x {
  padding: 0 2.188rem;
}

p {
  margin: 0;
}

.mr-b {
  margin-bottom: 1.875rem;
}

.icons {
  display: flex;
  gap: 1.5rem;
}
</style>
