<template>
  <Dialog
    :draggable="false"
    :visible="show"
    :modal="show"
    :style="{ width: '680px' }"
  >
    <template #header>
      <h1 class="modal-title">Send email</h1>
      <i class="icon pi pi-times" @click="close" style="margin-left: auto" />
    </template>
    <Spinner v-if="isloading" class="flex justify-content-between" />
    <div v-else class="modal-content">
      <div>Email Template<span class="rq-txt">*</span></div>
      <div class="flex">
        <Dropdown
          :items="template"
          width="100%"
          placeholder="-select-"
          optionLabel="name"
          class="mt-2 w-100"
          @change="onChange"
        />
        <div style="width: 30px" v-if="statementMonthList.length"></div>
        <Dropdown
          :items="statementMonthList"
          width="100%"
          placeholder="-select-"
          optionLabel="name"
          class="mt-2 w-100 mw-50"
          v-if="statementMonthList.length"
          @change="(e) => {selectedMonth = e.month;selectedYear = e.year}"
        />
      </div>

      <div class="flex mt-3">
        <div class="col-6" :key="dropdownKey">
          <div>To<span class="rq-txt">*</span></div>
          <!-- <TextField
            width="100%"
            placeholder="cathy@carter.com.au"
            class="mt-2"
            @getInputValue="getFormData($event, 'to')"
          /> -->
          <!-- <AutoComplete
            width="100%"
            v-model="toAddress"
            :suggestions="filteredList"
            @complete="searchCustomer($event)"
            placeholder="Enter recipient address"
            field="email"
          /> -->
          <Dropdown
            :key="formData.to"
            :items="emailList"
            @change="selectedEmail"
            placeholder="-select-"
            :value="formData.to"
            width="100%"
            class="full-width"
          />
        </div>
        <div class="col-6 mr-0">
          <div>From</div>
          <TextField
            :disable="true"
            width="100%"
            placeholder="Enter your email address"
            value="hello@bridgit.com.au"
            @onInput="getFormData($event, 'from')"
          />
          <!-- <AutoComplete
            width="100%"
            v-model="formData.from"
            :suggestions="filteredList"
            @complete="searchBroker($event)"
            placeholder="Enter your email address"
            field="email"
            class="full-width"
          /> -->
        </div>
      </div>

      <div class="mt-3">Subject<span class="rq-txt">*</span></div>
      <TextField
        :key="refreshKey"
        width="100%"
        placeholder="Subject"
        class="mt-2"
        @onInput="getFormData($event, 'subject')"
        :value="formData.subject"
        :disable="disableSubmit"
      />

      <div v-if="displayBodyField" class="mt-3">
        <div class="mt-2">Body<span class="rq-txt">*</span></div>
        <Editor @getInputValue="getFormData($event, 'body')" class="mt-2" />
      </div>
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button
          :disabled="isSubmitDisabled()"
          label="Send"
          type="primary"
          @click="save"
          :loading="isBtnLoading"
        />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">
          Close
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
// import AutoComplete from "primevue/autocomplete";
import { _get, _post } from "../../../../services/crudService";
import { APP_URL, SERVER_URL } from "../../../../../constants";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters } from "vuex";

export default {
  props: ["show", "portal"],
  components: { Dialog },
  data() {
    return {
      template: [],
      isloading: false,
      selectedCountry1: null,
      filteredList: [],
      emailList: [],
      displayBodyField: true,
      disableSubmit: false,
      toAddress: "",
      refreshKey: 10,
      isBtnLoading: false,
      summary: {},
      brokerEmail: "",
      emailTo: "customer",
      formData: {
        to: "",
        subject: "",
        body: "",
        templateId: "",
        description: "",
        status: "",
        application_id: "",
        applicant_id: "",
        applicant_type: this.$props.portal,
        tab: "",
        first_name: "",
      },
      dropdownKey: 0,
      statementMonthList: [],
      selectedMonth: "",
      selectedYear: "",
    };
  },
  watch: {
    toAddress(value) {
      if (value && value.email) {
        this.formData.to = value.email;
      }
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    if (!this.displayBodyField) {
      return {
        formData: {
          to: { required },
          subject: { required },
        },
      };
    } else {
      return {
        formData: {
          to: { required },
          subject: { required },
          body: { required },
        },
      };
    }
  },
  computed: {
    ...mapGetters({
      active_application: "customers/active_application",
      selectedApplication:"applications/selectedApplication"
    }),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    isSubmitDisabled() {
      if (this.statementMonthList.length && !this.selectedMonth) return true;
      return this.v$.$invalid;
    },
    selectedEmail(value) {
      this.formData.to = value;
    },
    async onChange(value) {
      this.emailTo = value.userType;
      console.log("value", value);
      if (value.name === "Customer: Monthly statement") {
        const { data } = await _get(
          APP_URL +
            `monthly-statement-options?applicationId=${this.$route.params.id}`
        );
        console.log(data);
        this.statementMonthList = data?.monthWithYear || [];
      } else {
        this.statementMonthList = [];
        this.selectedMonth = "";
      }
      this.getEmails();
      if (value.type === "system") {
        this.displayBodyField = false;
        this.disableSubmit = true;
      } else {
        this.displayBodyField = true;
        this.disableSubmit = false;
      }

      this.formData.templateId = value.id;
      this.formData.status = value.status ? value.status : "";
      this.formData.subject = value.email_subject ? value.email_subject : "";
      this.refreshKey++;
      if (value.status == "solicitor_instructed") {
        this.emailList = ["settlements@bridgit.com.au"];
        this.formData.to = this.emailList[0];
      } else {
        await this.getEmails();
      }
      this.dropdownKey += 1;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    applicantName(value) {
      if (value) {
        let name;
        let id;

        const primaryApp = value.find(
          (app) => app.applicant_type === "primary"
        );
        // value.map((app) => {
        //   if (app.applicant_type === "primary") {
        //     id = app.customer_id;
        //     name = app.first_name;
        //     return;
        //   } else {
        //     id = app.customer_id;
        //     name = app.first_name;
        //     return;
        //   }
        // });

        name = primaryApp.first_name;
        id = primaryApp.customer_id;
        return { name, id };
      }
    },
    async save() {
      try {
        this.isBtnLoading = true;
       
        if (this.$props.portal === "loan") {
          this.formData.application_id = this.$route.params.id || "";
          const applicant = this.applicantName(this.summary.applicants);

          this.formData.applicant_id = applicant.id || "";
          this.formData.first_name = applicant.name || "";
          this.formData.tab = this.$props.portal;
        } else if (this.$props.portal === "customer") {
          this.formData.application_id = this.active_application || "";
          this.formData.applicant_id = this.$route.params.id || "";
          this.formData.tab = this.$props.portal;
          this.formData.first_name = this.$route.query.firstName || "";
        } else if (this.$props.portal === "broker") {
          this.formData.application_id = "";
          this.formData.applicant_id = this.$route.params.id || "";
          this.formData.tab = this.$props.portal;
          this.formData.first_name = this.$route.query.firstName || "";
        }

        if (this.statementMonthList.length && this.selectedMonth) {
          this.formData.month = this.selectedMonth.toString();
          this.formData.year = this.selectedYear.toString();
        }
        const insertCommunication = await _post(
          APP_URL + "communication-send-email-api",
          { ...this.formData, type: this.emailTo }
        );

        if (insertCommunication) {
          this.isBtnLoading = false;
          this.$emit("close");
          this.$emit("save");
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Email send successfully",
            life: 3000,
          });
        }
      } catch (error) {
        this.isBtnLoading = false;

        console.log(error);

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    searchCustomer(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredList = [...this.emailList];
        } else {
          this.filteredList = this.emailList.filter((list) => {
            if (list.email) {
              return list.email.includes(event.query.toLowerCase());
            }
          });
        }
      }, 250);
    },
    async getUserDetails() {
      const response = await _get(SERVER_URL + "get_crm_user_profile");

      this.formData.from = response.data.result[0].email;
    },
    async getTemplates() {
      const templates = await _get(APP_URL + "email-comms-templates");

      console.log("crm_status",this.summary.application_root[0].crm_status);

      if (this.$props.portal === "loan") {
        templates.data.system.map((list) => {
          if (this.summary.application_root[0].created_user_type === "broker") {
            this.template.push({
              name: list.name,
              id: list?.templateId,
              type: list.type || "",
              email_subject: list.email_subject || "",
              status: list.status || "",
              userType: list.userType,
            });
          } else {
            if (list.userType === "customer") {
              this.template.push({
                name: list.name,
                id: list?.templateId,
                type: list.type || "",
                email_subject: list.email_subject || "",
                status: list.status || "",
                userType: list.userType,
              });
            }
          }
        });
      } else if (this.$props.portal === "customer") {
        templates.data.customer.map((list) => {
          this.template.push({
            name: list.name,
            id: list?.templatedId,
            type: list.type || "",
            email_subject: list.email_subject || "",
            status: list.status || "",
          });
        });
      } else if (this.$props.portal === "broker") {
        templates.data.broker.map((list) => {
          this.template.push({
            name: list.name,
            id: list?.templatedId,
            type: list.type || "",
            email_subject: list.email_subject || "",
            status: list.status || "",
          });
        });
      }
      if (this.summary.application_root.length > 0 && this.summary.application_root[0].crm_status !== "loan_funded"){
        this.template = this.template.filter(item => item.name !== "Customer: Monthly statement")
      }
    },
    async getEmails() {
      this.emailList = [];
      if (this.emailTo == "customer") {
        {
          this.summary &&
            this.summary.applicants.map((list) =>
              this.emailList.push(list.email)
            );

          if (this.emailList.length === 1) {
            this.formData.to = this.emailList[0];
          }
        }
      } else {
        this.formData.to = this.brokerEmail;
        this.emailList.push(this.brokerEmail);
      }
      this.emailList = [...this.emailList, "hello@bridgit.com.au"];
    },
    async getAppSummary() {
      if (this.$props.portal === "loan") {
        try {
          const response = await _get(
            APP_URL + `application_by_id?id=${this.$route.params.id}`
          );
          if (response) {
            this.summary = response.data.result;
                   console.log("active_application", this.summary)

            if (typeof response.data.user == "object") {
              this.brokerEmail = response.data.user.email;
            }
          }
        } catch (error) {
          console.log(error);
        }
      } else if (this.$props.portal === "customer") {
        try {
          const response = await _get(
            APP_URL + `application_by_id?id=${this.active_application}`
          );
          if (response) {
            this.summary = response.data.result;
          }
        } catch (error) {
          console.log(error);
        }
      } else if (this.$props.portal === "broker") {
        const response = await _get(
          SERVER_URL + "get_broker_crm_by_id?id=" + this.$route.params.id
        );
        if (response) {
          this.summary.applicants = [{ email: response.data[0].email }];
        }
      }
    },
  },
  async created() {
    try {
      this.isloading = true;
      await this.getAppSummary();
      await this.getEmails();
      await this.getTemplates();
      this.isloading = false;
    } catch (error) {
      this.isloading = false;
      console.log(error);
      this.$toast.add({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong",
        life: 3000,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
.mw-50 {
  min-width: 50%;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

:deep .p-autocomplete,
:deep .p-autocomplete-input {
  width: 100%;
}

.rq-txt {
  color: red !important;
}
</style>
