<template>
  <div class="mr-top card-box">
    <Panel :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>
      <template #header>
        <div @click="toggle" class="sub-title full-width pointer">
          Discharge Requests
        </div>
      </template>

      <!-- <div
        v-if="transOutCount > 0"
        @click="openTransactionModal"
        class="
          flex
          align-items-end
          justify-content-end
          mt-3
          pointer
          hk-sb
          lt-txt-color
        "
      >
        <span class="mr-2">+</span> New transaction in
      </div> -->
      <Spinner v-if="isloading" class="flex justify-content-between mt-3" />
      <DataTable
        id="dischargeReq"
        :scrollable="true"
        class="mt-2"
        :rows="5"
        dataKey="id"
        :value="statements"
        v-else
      >
        <template #empty> No data found. </template>

        <Column
          field="discharge_date"
          header="Discharge date"
          headerStyle="min-width: 170px"
        >
          <template #body="slotProps">
            <div class="dishchargeDateData">
              {{
                slotProps.data.discharge_date
                  ? formatDate(slotProps.data.discharge_date)
                  : "-"
              }}
            </div>
          </template>
        </Column>
        <Column field="type" header="Type" headerStyle="min-width: 170px">
          <template #body="slotProps">
            <div class="dishchargeDateData">
              {{
                slotProps.data.type
                  ? removeUnderscore(slotProps.data.type)
                  : "-"
              }}
            </div>
          </template>
        </Column>
        <Column
          field="properties_being_discharged"
          header="Properties being discharge"
          headerStyle="min-width: 170px"
        >
          <template #body="slotProps">
            <div class="dishchargeDateData">
              {{ slotProps.data.properties_beign_discharged || "-" }}
            </div>
          </template>
        </Column>

        <Column
          field="repaid_by"
          header="Repaid by way of"
          headerStyle="min-width: 170px"
        >
          <template #body="slotProps">
            <div class="dishchargeDateData">
              {{ displayRepaidBy(slotProps.data.repaid_by) || "-" }}
            </div>
          </template>
        </Column>
        <Column
          field="solicitor_first_name"
          header="Solicitors name"
          headerStyle="min-width: 170px"
        >
          <template #body="slotProps">
            <div class="dishchargeDateData">
              {{
                solicitorName(
                  slotProps.data.solicitor_first_name,
                  slotProps.data.solicitor_last_name
                )
              }}
            </div>
          </template>
        </Column>
        <Column
          field="solicitor_company"
          header="Solicitors company"
          headerStyle="min-width: 180px"
        >
          <template #body="slotProps">
            <div class="dishchargeDateData">
              {{ slotProps.data.solicitor_company }}
            </div>
          </template>
        </Column>
        <Column
          field="solicitor_email"
          header="Solicitors email"
          headerStyle="min-width: 170px"
        >
          <template #body="slotProps">
            <div class="dishchargeDateData email__Data">
              {{ slotProps.data.solicitor_email }}
            </div>
          </template>
        </Column>
        <Column
          field="solicitor_address"
          header="Solicitors address"
          headerStyle="min-width: 170px"
        >
          <template #body="slotProps">
            <div class="dishchargeDateData">
              {{ slotProps.data.solicitor_address }}
            </div>
          </template>
        </Column>
        <Column field="status" header="Status" headerStyle="min-width: 170px">
          <template #body="slotProps">
            <div class="dishchargeDateData">
              <div v-if="slotProps.data.status === 'draft'">
                <a
                  class="color-highlight pointer"
                  @click="openTransactionModal(slotProps.data)"
                  >{{ slotProps.data.status }}</a
                >
              </div>
              <div v-else>{{ slotProps.data.status }}</div>
            </div>
          </template>
        </Column>
        <Column
          field="payout_amount"
          header="Payout amount"
          headerStyle="min-width: 170px"
        >
          <template #body="slotProps">
            <div class="dishchargeDateData">
              {{
                slotProps.data.payout_amount
                  ? formatAmount(slotProps.data.payout_amount)
                  : "-"
              }}
            </div>
          </template>
        </Column>
        <Column
          field="closing_balance"
          header="Closing balance"
          headerStyle="min-width: 170px"
        >
          <template #body="slotProps">
            <div class="dishchargeDateData">
              {{
                slotProps.data.closing_balance
                  ? formatAmount(slotProps.data.closing_balance)
                  : "-"
              }}
            </div>
          </template>
        </Column>
      </DataTable>
    </Panel>
    <Transaction
      v-if="showTransactionModal"
      @save="refreshKey++"
      transaction_type="in"
      :title="selectedData ? 'Edit transaction in' : 'New transaction in'"
      dischargeTitle="Edit discharge request"
      :show="showTransactionModal"
      @close="showTransactionModal = false"
      :selectedData="selectedData"
    />
  </div>
</template>

<script>
import Panel from "primevue/panel";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Transaction from "./Transaction.vue";
import { _get } from "../../../../../services/crudService";
import { APP_URL } from "../../../../../../constants";
import moment from "moment";

export default {
  components: { Panel, DataTable, Column, Transaction },
  props: ["key"],
  data() {
    return {
      collapsed: false,
      showTransactionModal: false,
      isloading: false,
      statements: [],
      selectedData: null,
      refreshKey: 10,
    };
  },
  watch: {
    key() {
      this.getList(true);
    },
    refreshKey() {
      this.clearList();
      this.getList(true);
    },
  },
  methods: {
    displayRepaidBy(data) {
      let value = "";
      if (data) {
        data.length &&
          data.map((list, idx) => {
            if (idx === data.length - 1) {
              value += list;
            } else {
              value += list + ", ";
            }
          });
      } else return (value = "-");
      return value;
    },
    solicitorName(firstName, lastName) {
      if (firstName) {
        if (lastName) {
          return firstName + " " + lastName;
        } else return firstName;
      } else return "-";
    },
    removeUnderscore(value) {
      return (
        value.charAt(0).toUpperCase() + value.slice(1).replaceAll("_", " ")
      );
    },
    toggle() {
      this.collapsed = !this.collapsed;
    },
    openTransactionModal(data) {
      if (data.Id) {
        this.selectedData = data;
      } else {
        this.selectedData = null;
      }
      this.showTransactionModal = true;
    },
    formatAmount(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      return formatter.format(value);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    clearList() {
      this.statements = [];
    },
    async getList(reRender) {
      try {
        this.isloading = true;

        // const response = await _get(
        //   APP_URL +
        //     `get_transaction_report?application_id=${this.$route.params.id}&transactionType=in`
        // );

        const getDischargeReq = await _get(
          APP_URL + `discharge_request?app_id=${this.$route.params.id}`
        );

        // if (response) {
        //   this.isloading = false;
        //   // this.statements = response.data.result
        //   response.data.result &&
        //     response.data.result.map((list, idx) => {
        //       if (list.status === "draft") {
        //         this.statements.push({ list_no: idx + 1, ...list });
        //       }
        //     });
        // }

        if (getDischargeReq) {
          this.isloading = false;
          // this.statements = response.data.result
          getDischargeReq.data.result &&
            getDischargeReq.data.result.map((list) => {
              if (list.status === "draft") {
                this.statements.push({
                  ...list,
                  Id: list.Id,
                  repaid_by: JSON.parse(list.repaid_by),
                  // amount: list.cash_repayment_amount,
                  // status: list.status,
                  // createdBy:
                  //   list.applicant_first_name + " " + list.applicant_last_name,
                  // transactionDate: list.cash_repayment_date,
                });
              }
            });
        }
        reRender && this.$emit("dischargeReqCount", this.statements.length);
      } catch (error) {
        this.isloading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },
  created() {
    this.getList(false);
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

.dishchargeDateData {
  min-width: 140px;
  max-width: 140px;
}
.email__Data {
  word-wrap: break-word;
}

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid #02003826 !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
  padding: 1rem 1rem 0.5rem 1rem !important;
}

::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  //   border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
.color-highlight {
  color: $primary-color !important;
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.color-highlight {
  color: $primary-color !important;
}

.card-box {
  background: #ffffff;
  padding: 20px;
  border: 1px solid rgba(2, 0, 56, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
}

.mr-top {
  margin-top: 2.5rem;
}
</style>
