<template>
  <Dialog
    :draggable="false"
    :visible="show"
    position="top"
    :modal="show"
    :style="{ width: '680px' }"
  >
    <template #header>
      <h1 class="modal-title">Split Selected</h1>
    </template>
    <div class="modal-content">
      <div class="flex align-items-center">
        <p class="col-2 flex justify-content-end text hk-md">Name</p>
        <div
          v-for="(item, index) in selectedSavings"
          :key="index"
          class="col-5 flex fg-10 align-items-center"
        >
          <div class="mb-2" :for="item.institution">{{ item.institution }}</div>
        </div>
      </div>

      <div :key="refreshKey" class="flex align-items-center">
        <p class="col-2"></p>
        <div class="col-10">
          <div>
            <p class="label">Institution name</p>
            <TextField
              :value="institutionName"
              width="100%"
              @change="(e) => setInstitutionName(e)"
            />
          </div>
        </div>
      </div>

      <div :key="refreshKey" class="form-inputs mt-2">
        <div class="p-grid align-items-center header">
          <p class="p-col-2"></p>
          <p class="p-col flex justify-content-start label">
            Application Value
          </p>
          <p class="p-col label">Verified Value</p>
          <p class="p-col label">Manual Value</p>
        </div>

        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Amount</p>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedApplicationValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedVerifiedValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div class="p-col flex align-items-center justify-content-center">
            <TextField
              :value="calculatedManualValue"
              :disable="true"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button
          label="Split selected"
          type="primary"
          @click="saveSplited"
          :disabled="!institutionName"
        />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">
          Close
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Dialog from "primevue/dialog";

export default {
  name: "AddTask",
  props: ["show", "selectedSavings", "allSavings", "disable"],
  components: { Dialog },
  data() {
    return {
      savings: {},
      otherSavings: {},
      refreshKey: 10,
      institutionName: "",
      calculatedApplicationValue: null,
      calculatedVerifiedValue: null,
      calculatedManualValue: null,
    };
  },
  computed: {
    ...mapState({
      savingsUpdated: (state) => state.applications.savingsUpdated,
    }),
  },
  methods: {
    ...mapActions({
      setAssets: "applications/setAssets",
      setSavingsUpdated: "applications/setSavingsUpdated",
    }),
    setInstitutionName(e) {
      this.institutionName = e.target.value;
    },
    saveSplited() {
      let allArr = this.allSavings.map((item) => {
        if (item.institution === this.selectedSavings[0].institution) {
          item.amount_verified = 0;
          return item;
        }
        return item;
      });
      let arr = [
        ...allArr,
        {
          amount: 0,
          institution: this.institutionName,
          amount_used: this.calculatedManualValue,
          amount_verified: this.calculatedVerifiedValue,
          amount_manual: this.calculatedManualValue,
          amount_used_value: "manual_value",
          applicant_type: !this.disable ? "primary" : "secondary",
          useSavings: true,
        },
      ];
      this.setAssets({
        data: arr,
        type: !this.disable ? "primary" : "secondary",
        asset_type: "savings",
      });
      this.setSavingsUpdated(this.savingsUpdated + 1);
      this.$emit("splitSelected");
    },
    close() {
      this.refreshKey += 1;
      this.$emit("close");
    },
  },
  watch: {
    selectedSavings() {
      if (this.selectedSavings.length > 0) {
        this.calculatedManualValue = 0;
        this.calculatedApplicationValue = 0;
        this.calculatedVerifiedValue = this.selectedSavings[0].amount_verified;
        this.refreshKey += 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.checkbox-list {
  border: 1px solid rgba(2, 0, 56, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
}

.color-highlight {
  color: $secondary-color;
  font-family: Gordita-medium;
  font-size: 14px;
}

.modal-title {
  font-family: Gordita-medium-;
  font-size: 20px;
  color: $secondary-color;
}

.text,
label,
.label {
  font-family: Gordita-medium-;
  font-size: 14px;
  color: $secondary-color;
}

.color-highlight {
  color: $primary-color;
  text-decoration: underline;
}

p {
  margin: 0;
}

.fg-10 {
  gap: 10px;
}
::v-deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ced4da !important;
  border-color: #2196f3;
}
</style>
