<template>
	<div class="mr-top">
		<Panel :toggleable="true" :collapsed="collapsed">
			<template #icons>
				<div @click="toggle" class="icon-wrapper">
					<span v-if="collapsed" class="icon pi pi-angle-down"></span>
					<span v-else class="icon pi pi-angle-up"></span>
				</div>
			</template>
			<template #header>
				<div @click="toggle" class="sub-title full-width pointer">
					Serviceability
				</div>
			</template>
			<div class="form-inputs mr-top-10px">
				<div class="p-grid align-items-end">
					<div class="p-col-2 serviceability__text mb-3">
						<p class="label">Serviceability</p>
					</div>
					<div class="p-col-10 p-grid align-items-center mr-b-5px">
						<div class="p-col-4 disable-input">
							<p class="label">+ Total Monthly Income after Tax</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="incomeTotal ? incomeTotal : 0"
								:disable="true"
								width="100%"
								:key="refreshKey"
							/>
						</div>

						<div class="p-col-4 disable-input">
							<p class="label">- Total Monthly Expenses</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="expenseTotal ? expenseTotal : 0"
								:disable="true"
								width="100%"
								:key="refreshKey"
							/>
						</div>

						<div class="p-col-4 disable-input">
							<p class="label">- Total other liabilities</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="liabilityTotal ? liabilityTotal : 0"
								:disable="true"
								width="100%"
								:key="refreshKey"
							/>
						</div>
					</div>
					<!-- <div class="p-col-0 highlight-fields" v-if="false">
            <div class="disable-input">
              <p class="label">Monthly Net Surplus</p>
              <NumberField
                mode="decimal"
                :format="true"
                prefix="$"
                :disable="true"
                :value="overAllTotal.toFixed(2)"
                width="100%"
                :key="refreshKey"
              />
            </div>

            <div class="flex align-items-end mr-top-10px flex-gap">
              <div class="disable-input">
                <p class="label">LTI</p>
                <TextField :value="lti" :disable="true" width="100%" />
              </div>
              <div class="disable-input">
                <p class="label">DTI</p>
                <TextField :value="dti" :disable="true" width="100%" />
              </div>  
            </div>
          </div> -->
				</div>
				<div class="">
					<ServicibilityFields
						label="P/I End debt 6 months"
						name="end_debt_6_months"
						:value="servicibility"
						@onChange="handleChange"
					/>
				</div>
				<div class="">
					<ServicibilityFields
						label="I/O Peak Debt 12 Months"
						name="peak_debt_12_months"
						:value="servicibility"
						@onChange="handleChange"
					/>
				</div>
				<div class="">
					<ServicibilityFields
						label="P/I End Debt 12 Months (Refinanceability)"
						name="end_debt_12_months"
						:value="servicibility"
						@onChange="handleChange"
					/>
				</div>
				<div class="">
					<ServicibilityFields
						label="I/O End Debt 12 Months (Affordability)"
						name="end_debt_12_months_affordability"
						:value="servicibility"
						@onChange="handleChange"
					/>
				</div>
			</div>
			<!-- {{ selectedApplication.calculated_fields }} -->
		</Panel>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Panel from "primevue/panel";
import ServicibilityFields from "./ServicibilityFields.vue";

export default {
	components: { Panel, ServicibilityFields },
	computed: {
		...mapGetters({
			overAllTotalData: "subTotal/overAllTotal",
			expenseTotalData: "subTotal/expenseTotal",
			personalLoanTotal: "subTotal/personalLoanTotal",
			cardsTotal: "subTotal/cardsTotal",
			vehicleTotal: "subTotal/vehicleTotal",
			selectedApplication: "applications/selectedApplication",
			propertyTotal: "subTotal/propertyTotal",
			serviciability: "summary/serviciability",
		}),
	},
	data() {
		return {
			collapsed: true,
			incomeTotal: 0,
			expenseTotal: 0,
			liabilityTotal: 0,
			overAllTotal: 0,
			refreshKey: 10,
			lti: 0,
			dti: "",
			servicibility: {
				end_debt_6_months: {
					interest_rate: 0.0649,
					buffer: 0,
					selected: true,
					lti: 0,
					dti: 0,
				},
				end_debt_12_months: {
					interest_rate: 0.0649,
					selected: false,
					buffer: 0.0,
					lti: 0,
					dti: 0,
				},
				peak_debt_12_months: {
					interest_rate: 0.0649,
					buffer: 0.01,
					selected: false,
					lti: 0,
					dti: 0,
				},
				end_debt_12_months_affordability: {
					interest_rate: 0.0649,
					buffer: 0.01,
					selected: false,
					lti: 0,
					dti: 0,
				},
			},
		};
	},
	methods: {
		...mapActions({
			setMonthlySurplus: "subTotal/setMonthlySurplus",
			setServiciability: "summary/setServiciability",
		}),
		toggle() {
			this.collapsed = !this.collapsed;
		},
		setLiabilityTotal() {
			//const { calculated_fields } = this.selectedApplication;
			/*  let newMonthlyRepayment = calculated_fields?.new_loan_repayment_monthly
        ? Number(calculated_fields?.new_loan_repayment_monthly)
        : 0; */
			this.liabilityTotal =
				this.cardsTotal.applicant_1 +
				this.cardsTotal.applicant_2 +
				this.personalLoanTotal.applicant_2 +
				this.vehicleTotal.applicant_2 +
				this.personalLoanTotal.applicant_1 +
				this.vehicleTotal.applicant_1 +
				this.propertyTotal.applicant_1 +
				this.propertyTotal.applicant_2;
			// this.liabilityTotal = calculated_fields?.total_commitments
			//   ? Number(calculated_fields?.total_commitments)
			//   : 0;
		},
		setAllTotal() {
			const { calculated_fields } = this.selectedApplication;
			this.overAllTotal = calculated_fields?.net_surplus
				? Number(calculated_fields?.net_surplus)
				: 0;
			this.setMonthlySurplus(this.overAllTotal);
			this.refreshKey += 1;
		},
		setLD() {
			if (this.selectedApplication.application_id) {
				const { calculated_fields } = this.selectedApplication;
				this.lti = calculated_fields.LTI
					? Number(calculated_fields.LTI).toFixed(2)
					: 0;
				this.dti = calculated_fields.DTI
					? Number(calculated_fields.DTI).toFixed(2)
					: 0;
			}
		},
		clear12monthSelected() {
			this.servicibility.end_debt_12_months.selected = false;
			this.servicibility.peak_debt_12_months.selected = false;
			this.servicibility.end_debt_12_months_affordability.selected = false;
		},
		clear6monthSelected() {
			this.servicibility.end_debt_6_months.selected = false;
			if (
				!this.servicibility.peak_debt_12_months.selected &&
				!this.servicibility.end_debt_12_months_affordability.selected
			) {
				this.servicibility.peak_debt_12_months.selected = true;
				return;
			}
		},
		handleChange(target) {
			const { name, value } = target;
			this.servicibility[name] = value;
			if (name == "end_debt_6_months" && value.selected) {
				this.clear12monthSelected();
			}
			if (name == "end_debt_12_months" && value.selected) {
				this.clear6monthSelected();
				this.servicibility.end_debt_12_months.selected = true;
			}
			if (name == "peak_debt_12_months" && value.selected) {
				this.clear6monthSelected();
				this.servicibility.end_debt_12_months_affordability.selected = false;
				this.servicibility.end_debt_12_months.selected = true;
			}
			if (name == "end_debt_12_months_affordability" && value.selected) {
				this.clear6monthSelected();
				this.servicibility.end_debt_12_months.selected = true;
				this.servicibility.peak_debt_12_months.selected = false;
			}
			console.log("servicibility: ", this.servicibility);
		},
	},
	created() {
		// this.incomeTotal =
		//   this.overAllTotalData.applicant_1 + this.overAllTotalData.applicant_2;
		// this.incomeTotal = this.incomeTotal.toFixed();
		if (this.selectedApplication.calculated_fields?.serviciabilies) {
			this.setServiciability(
				this.selectedApplication.calculated_fields.serviciabilies
			);
			this.servicibility =
				this.selectedApplication.calculated_fields.serviciabilies;
		}
		let app1 = this.selectedApplication?.calculated_fields
			?.applicant_1_annualIncomeAfterTax
			? this.selectedApplication?.calculated_fields.applicant_1_annualIncomeAfterTax
			: 0;
		let app2 = this.selectedApplication?.calculated_fields
			?.applicant_2_annualIncomeAfterTax
			? this.selectedApplication?.calculated_fields.applicant_2_annualIncomeAfterTax
			: 0;
		this.incomeTotal = ((app1 + app2) / 12).toFixed(5);
		// this.incomeTotal = this.selectedApplication?.calculated_fields
		//   ?.applicant_1_annualIncomeAfterTax
		//   ? (
		//       this.selectedApplication.calculated_fields.applicant_1_annualIncomeAfterTax / 12
		//     ).toFixed(5)
		//   : 0;
		this.expenseTotal =
			Number(this.expenseTotalData.applicant_1) +
			Number(this.expenseTotalData.applicant_2);
		this.expenseTotal = this.expenseTotal.toFixed();
		this.setLD();
		this.setLiabilityTotal();
		this.setAllTotal();
	},
	watch: {
		// overAllTotalData: {
		//   handler() {
		//     this.incomeTotal =
		//       this.overAllTotalData.applicant_1 + this.overAllTotalData.applicant_2;
		//     this.incomeTotal = this.incomeTotal.toFixed(2);
		//     this.incomeTotal = this.selectedApplication?.calculated_fields
		//       ?applicant_1_annualIncomeAfterTax
		//       ? (
		//           this.selectedApplication.calculated_fieldsapplicant_1_annualIncomeAfterTax /
		//           12
		//         ).toFixed()
		//       : 0;
		//     this.setAllTotal();
		//   },
		//   deep: true,
		// },
		expenseTotalData: {
			handler() {
				this.expenseTotal =
					Number(this.expenseTotalData.applicant_1) +
					Number(this.expenseTotalData.applicant_2);
				this.expenseTotal = this.expenseTotal.toFixed();
				this.setAllTotal();
			},
			deep: true,
		},
		personalLoanTotal: {
			handler() {
				this.setLiabilityTotal();
				this.setAllTotal();
			},
			deep: true,
		},
		cardsTotal: {
			handler() {
				this.setLiabilityTotal();
				this.setAllTotal();
			},
			deep: true,
		},
		vehicleTotal: {
			handler() {
				this.setLiabilityTotal();
				this.setAllTotal();
			},
			deep: true,
		},
		propertyTotal: {
			handler() {
				this.setLiabilityTotal();
				this.setAllTotal();
			},
			deep: true,
		},
		servicibility: {
			handler() {
				this.setServiciability(this.servicibility);
			},
			deep: true,
		},
		serviciability: {
			handler() {
				this.servicibility = this.serviciability;
			},
		},
	},
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-panel .p-panel-content {
	padding-left: 0;
	padding-right: 0;
}

::v-deep .p-panel-header {
	background: unset !important;
	border: none !important;
	border-bottom: 1px solid #02003826 !important;
	padding: 0 !important;
	padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
	border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
	display: none !important;
}

.sub-title {
	font-size: 1.25rem;
	color: $secondary-color;
	font-family: Gordita-bold;
}

.highlight-fields {
	.label {
		font-family: Gordita-bold !important;
	}
}
.serviceability__text {
	display: flex;
	align-items: flex-end;
	/*  width: 142px; */
	margin-left: 5px;
}
.label {
	color: $secondary-color;
	font-size: 0.875rem;
	font-family: Gordita-medium;
	margin: 0;
}

.icon-wrapper {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 2px solid rgba(2, 0, 56, 0.4);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

::v-deep .p-col-4,
.p-col-2 {
	padding-bottom: 0;
	padding-top: 0;
}

.mr-b-5px {
	margin-bottom: 5px;
}

.mr-top {
	margin-top: 2.5rem;
}

.mr-top-10px {
	margin-top: 10px;
}

.flex-gap {
	gap: 0.625rem;
}
</style>
