<template>
  <div>
    <div class="content">
      <div class="form-inputs mt-4">
        <div class="p-grid align-items-center header" v-if="latest.length">
          <p class="col"></p>
          <p class="col label">Application Values</p>
          <p class="col label">Current Values</p>
          <p class="col label">Current Value Source</p>
          <div class="col"></div>
        </div>
        <div v-for="(item, i) in latest" :key="i">
          <br />
          <InputRow
            label="Salary"
            :value1="original[i] ? original[i].salary_amount : ''"
            :value2="
              item.salary_amount_used_value === 'application_value'
                ? item.salary_amount
                : item.salary_amount_used_value === 'manual_value'
                ? item.salary_amount_manual
                : item.salary_amount_used_value === 'verified_value'
                ? item.salary_amount_verified
                : item.salary_amount
            "
            :value3="
              item.salary_amount_used_value === 'application_value'
                ? 'App value'
                : item.salary_amount_used_value === 'manual_value'
                ? 'Manual value'
                : item.salary_amount_used_value === 'verified_value'
                ? 'Verified value'
                : 'App value'
            "
          />
          <InputRow
            label="Salary ratio %"
            :value1="
              calculateRatio(
                item.salary_amount_used,
                monthlySubtotal(i, 'original')
              )
            "
            :value2="
              calculateRatio(
                item.salary_amount_used,
                monthlySubtotal(i, 'latest')
              )
            "
          />
          <InputRow
            label="Salary packaged"
            :value1="original[i] ? original[i].salary_packaged : ''"
            :value2="
              item.salary_packaged_used_value === 'application_value'
                ? item.salary_packaged
                : item.salary_packaged_used_value === 'manual_value'
                ? item.salary_packaged_manual
                : item.salary_packaged_used_value === 'verified_value'
                ? item.salary_packaged_verified
                : item.salary_packaged
            "
            :value3="
              item.salary_packaged_used_value === 'application_value'
                ? 'App value'
                : item.salary_packaged_used_value === 'manual_value'
                ? 'Manual value'
                : item.salary_packaged_used_value === 'verified_value'
                ? 'Verified value'
                : 'App value'
            "
          />
          <InputRow
            label="Salary packaged ratio %"
            :value1="
              calculateRatio(
                item.salary_packaged_used,
                monthlySubtotal(i, 'original')
              )
            "
            :value2="
              calculateRatio(
                item.salary_packaged_used,
                monthlySubtotal(i, 'latest')
              )
            "
          />
          <InputRow
            label="Overtime"
            :value1="original[i] ? original[i].overtime_pay_amount : ''"
            :value2="
              item.overtime_pay_amount_used_value === 'application_value'
                ? item.overtime_pay_amount
                : item.overtime_pay_amount_used_value === 'manual_value'
                ? item.overtime_pay_amount_manual
                : item.overtime_pay_amount_used_value === 'verified_value'
                ? item.overtime_pay_amount_verified
                : item.overtime_pay_amount
            "
            :value3="
              item.overtime_pay_amount_used_value === 'application_value'
                ? 'App value'
                : item.overtime_pay_amount_used_value === 'manual_value'
                ? 'Manual value'
                : item.overtime_pay_amount_used_value === 'verified_value'
                ? 'Verified value'
                : 'App value'
            "
          />
          <InputRow
            label="Overtime ratio %"
            :value1="
              calculateRatio(
                item.overtime_pay_amount_used,
                monthlySubtotal(i, 'original')
              )
            "
            :value2="
              calculateRatio(
                item.overtime_pay_amount_used,
                monthlySubtotal(i, 'latest')
              )
            "
          />
          <InputRow
            label="Commission"
            :value1="original[i] ? original[i].commissions_amount : ''"
            :value2="
              item.commissions_used_value === 'application_value'
                ? item.commissions_amount
                : item.commissions_used_value === 'manual_value'
                ? item.commissions_amount_manual
                : item.commissions_used_value === 'verified_value'
                ? item.commissions_amount_verified
                : item.commissions_amount
            "
            :value3="
              item.commissions_used_value === 'application_value'
                ? 'App value'
                : item.commissions_used_value === 'manual_value'
                ? 'Manual value'
                : item.commissions_used_value === 'verified_value'
                ? 'Verified value'
                : 'App value'
            "
          />
          <InputRow
            label="Commission ratio %"
            :value1="
              calculateRatio(
                item.commissions_used,
                monthlySubtotal(i, 'original')
              )
            "
            :value2="
              calculateRatio(
                item.commissions_used,
                monthlySubtotal(i, 'latest')
              )
            "
          />
          <InputRow
            label="Bonuses"
            :value1="original[i] ? original[i].bonuses_amount : ''"
            :value22="item.bonuses_amount"
            :value2="
              item.bonuses_amount_used_value === 'application_value'
                ? item.bonuses_amount
                : item.bonuses_amount_used_value === 'manual_value'
                ? item.bonuses_amount_manual
                : item.bonuses_amount_used_value === 'verified_value'
                ? item.bonuses_amount_verified
                : item.bonuses_amount
            "
            :value3="
              item.bonuses_amount_used_value === 'application_value'
                ? 'App value'
                : item.bonuses_amount_used_value === 'manual_value'
                ? 'Manual value'
                : item.bonuses_amount_used_value === 'verified_value'
                ? 'Verified value'
                : 'App value'
            "
          />
          <InputRow
            label="Bonuses ratio %"
            :value1="
              calculateRatio(
                item.bonuses_amount_used,
                monthlySubtotal(i, 'original')
              )
            "
            :value2="
              calculateRatio(
                item.bonuses_amount_used,
                monthlySubtotal(i, 'latest')
              )
            "
          />
          <InputRow
            label="Monthly sub-total"
            :value1="monthlySubtotal(i, 'original')"
            :value2="monthlySubtotal(i, 'latest')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputRow from "../../InputRow.vue";
export default {
  props: ["applicant"],
  components: { InputRow },
  computed: {
    ...mapGetters({
      originalData: "loans/originalData",
      latestData: "loans/latestData",
    }),
  },
  data() {
    return {
      original: [],
      latest: [],
      originalTotal: 0,
      latestTotal: 0,
    };
  },
  methods: {
    setData() {
      this.latest = [];
      let applicant_type = this.applicant == "1" ? "primary" : "secondary";
      this.latestData.salaried.map((obj) => {
        if (
          obj.jobType === "full_time" &&
          obj.applicant_type === applicant_type
        ) {
          let salary_amount =
            obj.salary_amount_used_value === "application_value"
              ? obj.salary_amount
              : obj.salary_amount_used_value === "manual_value"
              ? obj.salary_amount_manual
              : obj.salary_amount_used_value === "verified_value"
              ? obj.salary_amount_verified
              : obj.salary_amount;
          let salary_packaged =
            obj.salary_packaged_used_value === "application_value"
              ? obj.salary_packaged
              : obj.salary_packaged_used_value === "manual_value"
              ? obj.salary_packaged_manual
              : obj.salary_packaged_used_value === "verified_value"
              ? obj.salary_packaged_verified
              : obj.salary_packaged;
          let overtime_pay_amount =
            obj.overtime_pay_amount_used_value === "application_value"
              ? obj.overtime_pay_amount
              : obj.overtime_pay_amount_used_value === "manual_value"
              ? obj.overtime_pay_amount_manual
              : obj.overtime_pay_amount_used_value === "verified_value"
              ? obj.overtime_pay_amount_verified
              : obj.overtime_pay_amount;
          let commissions_amount =
            obj.commissions_used_value === "application_value"
              ? obj.commissions_amount
              : obj.commissions_used_value === "manual_value"
              ? obj.commissions_amount_manual
              : obj.commissions_used_value === "verified_value"
              ? obj.commissions_amount_verified
              : obj.commissions_amount;
          let bonuses_amount =
            obj.bonuses_amount_used_value === "application_value"
              ? obj.bonuses_amount
              : obj.bonuses_amount_used_value === "manual_value"
              ? obj.bonuses_amount_manual
              : obj.bonuses_amount_used_value === "verified_value"
              ? obj.bonuses_amount_verified
              : obj.bonuses_amount;
          this.latestTotal =
            this.latestTotal +
            salary_amount +
            salary_packaged +
            overtime_pay_amount +
            commissions_amount +
            bonuses_amount;
          this.latest.push(obj);
        }
      });
      this.original = [];
      this.originalData.salaried.map((obj) => {
        if (
          obj.jobType === "full_time" &&
          obj.applicant_type === applicant_type
        ) {
          this.originalTotal =
            this.originalTotal +
            obj.salary_amount +
            obj.salary_packaged +
            obj.overtime_pay_amount +
            obj.commissions_amount +
            obj.bonuses_amount;
          this.original.push(obj);
        }
      });
    },
    calculateRatio(value, total) {
      if (value && total) {
        let calc = value / total;
        let percent = calc * 100;
        return percent.toFixed(2);
      }
      return 0;
    },
    //Get updated values based on frequency
    getUpdatedValues(valueField, frequencyField, index, type) {
      let value = "",
        frequency = "";
      if (type === "original") {
        value = this.original[index][valueField];
        frequency = this.original[index][frequencyField];
      } else {
        value = this.latest[index][valueField];
        frequency = this.latest[index][frequencyField];
      }
      switch (frequency) {
        case "Weekly":
          return value * 4;
        case "Fortnightly":
          return value * 2;
        case "Monthly":
          return value * 1;
        case "Quarterly":
          return value / 3;
        case "Yearly":
          return value / 12;
      }
      return 0;
    },
    monthlySubtotal(i, type) {
      let total =
        Number(
          this.getUpdatedValues(
            "salary_amount_used",
            "salary_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getUpdatedValues(
            "salary_packaged_used",
            "salary_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getUpdatedValues(
            "overtime_pay_amount_used",
            "overtime_pay_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getUpdatedValues(
            "commissions_used",
            "commissions_frequency",
            i,
            type
          )
        ) +
        Number(
          this.getUpdatedValues(
            "bonuses_amount_used",
            "bonuses_frequency",
            i,
            type
          )
        );
      return total ? total.toFixed(2) : 0;
    },
  },
  watch: {
    latestData() {
      this.setData();
    },
  },
  created() {
    this.setData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
</style>
