export default {
    namespaced: true,
    state: {
        updateAppSummary: 10,
    },
    getters: {
        updateAppSummary: (state) => state.updateAppSummary,

    },
    mutations: {
        reloadAppSummary(state) {
            state.updateAppSummary++
        },

    },
    actions: {
        reloadAppSummary(context) {
            context.commit("reloadAppSummary")
        },

    },
}
