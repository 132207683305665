<template>
  <Dialog
    :draggable="false"
    :visible="show"
    :modal="show"
    :style="{ width: '580px' }"
  >
    <template #header>
      <h1 class="modal-title">Loan Scenario</h1>
    </template>
    <div class="modal-content">
      <div class="mt-2">
        <p class="label">Customer</p>

        <div class="search-field flex align-items-center mt-1">
          <AutoComplete
            forceSelection
            class="full-width"
            width="100%"
            v-model="selectedCustomer"
            :suggestions="filteredCustomerList"
            @complete="List($event)"
            placeholder="Enter customer name"
            field="name"
            :dropdown="false"
            :modelValue="selectedCustomer"
          />
        </div>
      </div>

      <NewCustomer
        @getCustomerDetails="getCustomerDetails"
        @isCustomerDetailsValid="isCustomerDetailsValid"
        v-if="newCustomer"
        class="mt-2"
      />

      <div class="mt-3">
        <p class="label">Broker</p>

        <AutoComplete
          forceSelection
          class="full-width"
          width="100%"
          v-model="selectedBroker"
          :suggestions="filteredBrokerList"
          @complete="getBrokers($event)"
          placeholder="Enter broker name"
          field="name"
          :dropdown="false"
          :modelValue="selectedBroker"
        />
      </div>

      <NewBroker
        @getBrokerDetails="getBrokerDetails"
        @isBrokersDetailsValid="isBrokersDetailsValid"
        class="mt-2"
        v-if="newBroker"
      />

      <div class="mt-3">
        <p class="label">Notes</p>
        <TextArea
          placeholder="Enter comment"
          width="100%"
          height="80px"
          resize="none"
          @onInput="getNotes"
          :value="notes"
        />
      </div>

      <div class="mt-2">
        <p class="label">Send to</p>
        <Dropdown
          :items="sendToOpts"
          placeholder="-Select-"
          width="100%"
          @change="showSendTo"
        />
      </div>
    </div>
    <SendTo
      :sendToAddress="sendToAddress"
      :genAppId="genAppId"
      v-if="showSendToInputs"
      @isSendToValidate="isSendToValidate"
      @getSendToData="getSendToData"
      :showSendToInputs="showSendToInputs"
      class="mt-3"
      :selectedEmail="selectedEmail"
      :customerName="
        this.customerDetails &&
        this.customerDetails.firstName + ' ' + this.customerDetails.lastName
      "
    />

    <div class="mt-4">
      <Checkbox
        @getInputValue="checkboxValue"
        :category="checkbox"
        name="task"
      />
    </div>
    <AddTask
      v-if="showAddTaskForm"
      @validateTask="isTaskValidate"
      :taskSelected="showAddTaskForm"
      @taskData="taskData"
      :placeholder="assignedto"
      :regards="regardingto"
      class="mt-4"
    />

    <template #footer>
      <div class="flex align-items-center">
        <Button
          :loading="isloading"
          :disabled="isSubmitDisabled()"
          label="Save loan scenario"
          type="primary"
          @click="save"
        />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">
          Close
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import NewCustomer from "./NewCustomer.vue";
import NewBroker from "./NewBroker.vue";
import SendTo from "./SendTo.vue";
import AutoComplete from "primevue/autocomplete";
import AddTask from "./AddTaskForm.vue";
import { _get, _post } from "../../../../services/crudService";
import { APP_URL, SERVER_URL } from "../../../../../constants";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["show", "genAppId"],
  components: { Dialog, NewCustomer, SendTo, AddTask, AutoComplete, NewBroker },
  data() {
    return {
      checkbox: {
        id: 1,
        label: "Create task?",
      },
      sendToOpts: ["Do not send"],
      regardingto: "Loan",
      assignedto: "Users",
      sendToValue: "",
      isloading: false,
      newCustomer: false,
      newBroker: false,
      showSendToInputs: false,
      showAddTaskForm: false,
      selectedBrokerName: null,
      selectedCustomerName: null,
      sendToAddress: null,
      filteredCustomerList: [],
      filteredBrokerList: [],
      selectedCustomer: "",
      selectedBroker: "",
      customerList: [],
      brokerList: [],
      showAddNewOpt: false,
      task: {},
      taskValidate: false,
      notes: "",
      customerDetails: {},
      brokerDetails: {},
      sendToData: {},
      customerDetailsValidate: true,
      brokerDetailsValidate: true,
      sendToValidate: false,
      selectedEmail: null,
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    if (this.selectedCustomer) {
      return {
        selectedCustomer: { required },
        notes: { required },
        sendToValue: { required },
      };
    }
    if (this.selectedBroker) {
      return {
        selectedBroker: { required },
        sendToValue: { required },
        notes: { required },
      };
    } else {
      return {
        selectedBroker: { required },
        selectedCustomer: { required },
        sendToValue: { required },
        notes: { required },
      };
    }
  },

  watch: {
    selectedCustomer(value) {
      if (value && value.name) {
        if (value.name === "Add new customer") {
          this.newCustomer = true;
        } else {
          this.customerDetails = {
            firstName: value.firstName,
            lastName: value.lastName
          };
        }
        this.selectedEmail = value.email;
        this.sendToOpts = ["Customer", "Do not send"];
      } else {
        this.newCustomer = false;
        this.sendToOpts = ["Do not send"];
      }

      if (this.selectedCustomer && this.selectedBroker) {
        this.sendToOpts = ["Customer", "Broker", "Do not send"];
      }
    },
    selectedBroker(value) {
      if (value && value.name) {
        if (value.name === "Add new broker") {
          this.newBroker = true;
        } else {
          this.brokerDetails = {
            firstName: value.firstName,
            lastName: value.lastName
          };
        }
        this.selectedEmail = value.email;
        this.sendToOpts = ["Broker", "Do not send"];
      } else {
        this.newBroker = false;
        this.sendToOpts = ["Do not send"];
      }
      // if (value) {
      //   this.selectedEmail = value.email;
      //   this.sendToOpts = ["Broker", "Do not send"];
      // } else {
      //   this.sendToOpts = ["Do not send"];
      // }

      if (this.selectedCustomer && this.selectedBroker) {
        this.sendToOpts = ["Customer", "Broker", "Do not send"];
      }
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
    getNotes(value) {
      this.notes = value;
    },
    isSubmitDisabled() {
      if (this.showAddTaskForm) {
        if (
          this.v$.$invalid ||
          this.taskValidate ||
          this.customerDetailsValidate ||
          this.brokerDetailsValidate ||
          this.sendToValidate
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return this.v$.$invalid;
      }
      // return this.v$.$invalid
    },
    isTaskValidate(value) {
      this.taskValidate = value;
    },
    isCustomerDetailsValid(value) {
      this.customerDetailsValidate = value;
    },
    isBrokersDetailsValid(value) {
      this.brokerDetailsValidate = value;
    },
    isSendToValidate(value) {
      this.sendToValidate = value;
    },
    getSendToData(value) {
      this.sendToData = value;
    },

    toggleAddNewOpt() {
      this.showAddNewOpt = !this.showAddNewOpt;
    },
    taskData(value) {
      return (this.task = value);
    },
    getCustomerDetails(value) {
      this.customerDetails = value;
    },
    getBrokerDetails(value) {
      this.brokerDetails = value;
    },
    getCustomer(value) {
      if (value.value === "Add new customer") {
        this.selectedCustomerName = null;
        return (this.newCustomer = true);
      } else {
        this.selectedCustomerName = value.value;
        return (this.newCustomer = false);
      }
    },
    showSendTo(value) {
      this.sendToValue = value;
      if (value === "Customer") {
        this.showSendToInputs = true;
        this.sendToAddress = this.selectedCustomerName;
        if (this.selectedBroker) {
          this.selectedEmail = this.selectedCustomer.email;
        }
      } else if (value === "Broker") {
        this.showSendToInputs = true;
        this.sendToAddress = this.selectedBrokerName;
        if (this.selectedBroker) {
          this.selectedEmail = this.selectedBroker.email;
        }
      } else {
        this.showSendToInputs = false;
      }
    },
    checkboxValue(value) {
      if (value) {
        return (this.showAddTaskForm = true);
      } else {
        return (this.showAddTaskForm = false);
      }
    },
    getBrokerName(value) {
      this.selectedBrokerName = value.value;
    },
    async save() {
      try {
        let data = {};
        data = {
          broker: this.selectedBroker.name,
          brokerId: this.selectedBroker.Id,
          generate_application_crm_id: this.$props.genAppId,
          type: this.sendToValue,
          ...this.customerDetails,
          ...this.brokerDetails,
        };
        if (this.showSendToInputs) {
          data = { ...this.sendToData, ...data };
        }


        if (this.showAddTaskForm) {
          this.isloading = true;

          const response = await _post(
            APP_URL +
              `generate_applications_crm_save_version?id=${this.$props.genAppId}`,
            data
          );

          if (response) {
            const insertTask = await _post(
              SERVER_URL + "insert_task",
              this.task
            );

            if (insertTask) {
              this.isloading = false;
              this.$emit("close");

              if (this.showSendToInputs) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: "Loan scenario send successfully",
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: "Loan scenario saved successfully",
                  life: 3000,
                });
              }
            }
          }

          // Promise.all([
          //   _post(APP_URL + "insert_loan_scenario_crm", data),
          //   _post(SERVER_URL + "insert_task", this.task),
          // ])
          //   .then((result) => {
          //     if (result) {
          //       this.isloading = false
          //       this.$emit("close")
          //     }
          //   })
          //   .catch((err) => {
          //     throw err
          //   })
        } else {
          this.isloading = true;
          const response = await _post(
            APP_URL +
              `generate_applications_crm_save_version?id=${this.$props.genAppId}`,
            data
          );

          if (response) {
            this.isloading = false;
            this.$emit("close");
          }
        }
      } catch (error) {
        this.isloading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    async List(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredCustomerList = [...this.customerList];
        } else {
          this.filteredCustomerList = this.customerList.filter((list) => {
            return list.name.toLowerCase().includes(event.query.toLowerCase());
          });

          if (!this.filteredCustomerList.length) {
            this.filteredCustomerList.push({ name: "Add new customer" });
            // return (this.v$.$invalid = true)
          }

          this.filteredCustomerList.length &&
            this.filteredCustomerList.map((list) => {
              if (list.name !== event.query) {
                // return (this.v$.$invalid = true)
              }
            });
        }
      }, 250);
    },

    async getBrokers(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredBrokerList = [...this.brokerList];
        } else {
          this.filteredBrokerList =
            this.brokerList &&
            this.brokerList.filter((list) => {
              return list.name
                .toLowerCase()
                .includes(event.query.toLowerCase());
            });

          if (!this.filteredBrokerList.length) {
            this.filteredBrokerList.push({ name: "Add new broker" });
            // return (this.v$.$invalid = true)
          }

          this.filteredBrokerList &&
            this.filteredBrokerList.length &&
            this.filteredBrokerList.map((list) => {
              if (list.name !== event.query) {
                // return (this.v$.$invalid = true)
              }
            });
        }
      }, 250);
    },

    async getCustomerList() {
      const response = await _get(SERVER_URL + "get_customers_no_applicantion");

      if (response) {
        this.customerList = response.data.result;
        this.customerList.map(
          (list) => (list.name = `${list.firstName} ${list.lastName}`)
        );
        this.customerList.push({ name: "Add new customer" });
      }
    },

    async getBrokerList() {
      const response = await _get(SERVER_URL + "get_broker_crm");

      if (response) {
        this.brokerList = response.data.result;
        this.brokerList &&
          this.brokerList.length &&
          this.brokerList.map(
            (list) => (list.name = `${list.firstName} ${list.lastName}`)
          );
        this.brokerList.push({ name: "Add new broker" });
      }
    },
    // getCustomersList() {
    //   const response = await _get(
    //     SERVER_URL + "get_customers_no_applicantion"
    //   );

    //   if(response) {

    //   }
    // }
  },
  created() {
    this.getCustomerList();
    this.getBrokerList();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

p {
  margin: 0;
}

:deep .p-field-checkbox {
  margin-bottom: 0 !important;
}

:deep .p-autocomplete-input,
.p-autocomplete {
  width: 100% !important;
}

.label,
:deep .label-text {
  font-size: 14px;
  font-family: Gordita-medium;
  color: $secondary-color;
}

.search-field {
  position: relative;
}

.arrow {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  cursor: pointer;
}

.add-new-opt {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.137) !important;
  padding: 1rem;
  border: 0.5px solid #51507621;
  font-size: 0.9rem;
  font-family: Gordita-medium;
  cursor: pointer;
  position: absolute;
  left: 0;
  z-index: 99;

  &:hover {
    background: #fafafa;
  }
}
</style>
