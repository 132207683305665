<template>
  <Dialog
    :draggable="false"
    :visible="show"
    :modal="show"
    :style="{ width: '580px' }"
  >
    <h1 class="modal-title">Add relationship</h1>
    <div class="modal-content">
      <div class="mt-2">
        <div class="col">
          <div>Customer</div>
          <AutoComplete
            forceSelection
            class="full-width"
            width="35rem"
            v-model="selectedUser"
            :suggestions="filteredUserList"
            @complete="getUserList($event)"
            placeholder="Enter name"
            field="name"
            :modelValue="selectedUser"
          />
        </div>
      </div>

      <div class="details mt-3">
        <div class="title">Customer details</div>
        <div class="flex mt-2">
          <div class="col mr-2">
            <div>First name</div>
            <TextField
              width="100%"
              :value="formData.firstName"
              @onInput="getFormData($event, 'firstName')"
              placeholder="Customer's first name"
            />
          </div>
          <div class="col">
            <div>Last name</div>
            <TextField
              :value="formData.lastName"
              width="100%"
              @onInput="getFormData($event, 'lastName')"
              placeholder="Customer's last name"
            />
          </div>
        </div>

        <div class="flex mt-3">
          <div class="col mr-2">
            <div>Phone number</div>
            <TextField
              :value="formData.phoneNumber"
              width="100%"
              @onInput="getFormData($event, 'phoneNumber')"
              placeholder="Enter your phone number"
              maxValue="12"
              :key="refreshKey"
            />
          </div>
          <div class="col">
            <div>Relationship to primary applicant</div>
            <Dropdown
              :value="formData.relationshipToApplicant"
              width="100%"
              @change="getFormData($event, 'relationshipToApplicant')"
              placeholder="-Select-"
              :items="relationshipList"
              optionLabel="name"
              optionValue="value"
            />
          </div>
        </div>
        <div class="mt-3">
          <div>Email address</div>
          <TextField
            :value="formData.email"
            @onInput="getFormData($event, 'email')"
            width="100%"
            placeholder="Enter email address"
          />
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex align-items-center">
        <Button
          :disabled="isSubmitDisabled()"
          label="Add relationship"
          type="primary"
          @click="save"
          :loading="isloading"
        />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">
          Close
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import AutoComplete from "primevue/autocomplete";
import { required, email, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { _post, _get } from "../../../../services/crudService";
import { SERVER_URL } from "../../../../../constants";

export default {
  name: "AddTask",
  props: ["show"],
  components: { Dialog, AutoComplete },
  data() {
    return {
      selectedUser: "",
      filteredUserList: [],
      usersList: [],
      isloading: false,
      refreshKey: 10,
      relationshipList: [
        { name: "Married/Defacto", value: "married" },
        { name: "Siblings", value: "siblings" },
        { name: "Friends", value: "friend" },
        { name: "Other", value: "other" },
      ],
      customerList: ["Add new customer"],
      formData: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        relationshipToApplicant: "",
        email: "",
      },
      selctedCustomer: "Add new customer",
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        firstName: { required },
        lastName: { required },
        phoneNumber: {
          required,
          maxLength: maxLength(10),
        },
        relationshipToApplicant: { required },
        email: { required, email },
      },
    };
  },
  watch: {
    selectedUser(value) {
      if (value && value.Id) {
        this.getAssignedToId(value);
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getSelectedCustomer(value) {
      return (this.selctedCustomer = value.value);
    },
    async getAssignedToId(value) {
      this.formData.Id = value.Id;
      const result = await _get(
        SERVER_URL + "get_customer?id=" + this.formData.Id
      );
      this.response = result.data.result;
      this.formData.firstName = this.response[0].firstName;
      this.formData.lastName = this.response[0].lastName;
      this.formData.phoneNumber = this.response[0].mobile;
      this.formData.email = this.response[0].email;
      this.refreshKey++;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    async getUserList(event) {
      const cusotmerList = await _get(SERVER_URL + "get_customers");
      this.usersList = cusotmerList.data.result;
      this.usersList.map((obj) => {
        obj.name = `${obj.firstName} ${obj.lastName}`;
      });
      if (cusotmerList) {
        this.customerList = cusotmerList.data.result;
        this.customerList.map(
          (list) => (list.name = `${list.firstName} ${list.lastName}`)
        );
        this.customerList.push({ name: "Add new customer" });
      }
      this.loadingUserList = false;
      if (!event.query.trim().length) {
        this.filteredUserList = [...this.usersList];
      } else {
        this.filteredUserList = this.usersList.filter((list) => {
          return list.name.toLowerCase().includes(event.query.toLowerCase());
        });

        if (!this.filteredUserList.length) {
          this.filteredUserList.push({ name: "Add new customer" });
        }

        this.filteredUserList.length &&
          this.filteredUserList.map((list) => {
            if (list.name !== event.query) {
              return (this.v$.$invalid = true);
            }
          });
      }
    },
    async save() {
      try {
        this.isloading = true;
        const data = { ...this.formData };
        data["customer_id"] = this.$route.params.id;
        const response = await _post(
          SERVER_URL + "add_customer_relationship",
          data
        );

        if (response) {
          this.$emit("save");
          this.$emit("close");
          this.isloading = false;
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Relationship added successfully",
            life: 3000,
          });
        }
      } catch (error) {
        this.isloading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}

.full-width {
  width: 33rem;
}

.details {
  .title {
    font-family: Gordita-medium-;
    font-size: 1rem;
  }
}

.label {
  font-family: Gordita-medium;
  color: $secondary-color;
  font-size: 14px;
}

::v-deep .p-inputtext {
  font-family: Gordita-medium !important;
  width: inherit;
}

::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
</style>
