<template>
  <div class="std-padding-x mr-b">
    <div class="flex justify-content-between align-items-center mt-2">
      <h3 class="sec-color-txt hk-sb">Group list</h3>
      <div class="flex justify-content-end">
        <div
          class="mr-4 flex align-items-center"
          @click="showAddGroup"
          style="cursor: pointer"
        >
          <img class="mr-2" src="@/assets/plus-inactive.svg" />
          <p class="lt-txt-color">Add new group</p>
        </div>
      </div>
    </div>

    <Spinner v-if="isLoading" class="flex justify-content-between" />

    <DataTable
      v-else
      responsiveLayout="scroll"
      :rows="10"
      dataKey="id"
      :value="groupList.data"
      :rowsPerPageOptions="[5, 10, 20, 30]"
    >
      <Column field="name" header="Title">
        <template #body="slotProps">
          {{ slotProps.data.name || "-" }}
        </template>
      </Column>
      <Column field="count" header="Members">
        <template #body="slotProps">
          {{ slotProps.data.count || "-" }}
        </template>
      </Column>
      <Column field="" header="" />
      <Column field="" header="" />
      <Column field="view" header=" " :expander="true">
        <template #body="slotProps">
          <div
            class="color-highlight flex align-items-center pointer"
            @click="showEditGroup(slotProps.data.id)"
            style="text-align: end !important; width: 100%"
          >
            <div style="width: 100%">Edit group</div>
          </div>
        </template>
      </Column>
    </DataTable>

    <Paginator
      class="mt-2"
      :rows="5"
      :totalRecords="groupList && groupList.totalCount"
      :rowsPerPageOptions="[5, 10, 20, 30]"
      template="RowsPerPageDropdown  CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
      currentPageReportTemplate="Showing {first} of {totalRecords} groups"
      @page="onChangePage($event)"
    ></Paginator>
    <div class="flex justify-content-between align-items-center mt-2">
      <h3 class="sec-color-txt hk-sb">Task types</h3>
      <div class="flex justify-content-end">
        <div class="mr-4 flex align-items-center">
          <img class="mr-2" src="@/assets/plus-inactive.svg" />

          <p @click="addNewTaskType" class="lt-txt-color pointer">
            Add task type
          </p>
        </div>
      </div>
    </div>

    <Spinner v-if="taskTypeSpinner" class="flex justify-content-between" />
    <div v-else v-for="list of taskTypes" :key="list.Id">
      <TaskTypes
        @deletingTaskType="taskTypeSpinner = true"
        @taskTypeChange="taskTypeKey++"
        :list="list"
      />
    </div>

    <AddGroup
      v-if="displayAddGroup"
      :show="displayAddGroup"
      @close="displayAddGroup = false"
      @save="refreshKey++"
      v-cloak
      title="Add new group"
      btnText="Add new group"
    />
    <AddGroup
      v-if="displayEditGroup"
      :show="displayEditGroup"
      @close="displayEditGroup = false"
      title="Edit new group"
      btnText="Save group"
      :groupId="selectedGroupId"
      @save="refreshKey++"
    />
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ThreeDot from "@/assets/three_dot.svg";
import AddGroup from "./AddGroup/Index.vue";
import TaskTypes from "./taskTypes.vue";
import { _get, _post } from "../../../../../services/crudService";
import Paginator from "primevue/paginator";
import { SERVER_URL } from "../../../../../../constants";
import { mapActions } from "vuex";

export default {
  components: {
    DataTable,
    Column,
    Paginator,
    AddGroup,
    TaskTypes,
  },
  data() {
    return {
      threedot: ThreeDot,
      refreshKey: 10,
      displayAddGroup: false,
      displayEditGroup: false,
      groupList: [],
      isLoading: false,
      taskTypes: [],
      taskTypeKey: 0,
      taskTypeSpinner: false,
      selectedGroupId: null,
    };
  },
  watch: {
    taskTypeKey() {
      return this.getTaskTypes();
    },
    refreshKey() {
      this.getGroup(1, 5);
    },
  },
  methods: {
    ...mapActions({
      setSelctedGroupId: "group/setSelctedGroupId",
    }),
    showAddGroup() {
      this.displayAddGroup = true;
      this.setSelctedGroupId(null);
    },
    showEditGroup(id) {
      this.displayEditGroup = true;
      this.setSelctedGroupId(id);
    },
    async addNewTaskType() {
      this.taskTypeSpinner = true;
      const response = await _post(SERVER_URL + "insert_task_types", {
        type: "group",
      });

      if (response) {
        this.$toast.add({
          severity: "success",
          summary: "Task type added successfully",
          life: 3000,
        });
        this.taskTypeKey++;
      }
    },
    onChangePage(value) {
      this.getGroup(value.page + 1, value.rows);
    },
    async getGroup(page, row) {
      this.isLoading = true;
      const response = await _get(
        SERVER_URL + `get-group-count?pageSize=${row}&pageIndex=${page}`
      );

      if (response) {
        this.isLoading = false;
        return (this.groupList = response.data);
      }
    },
    async getTaskTypes() {
      this.taskTypeSpinner = true;
      const response = await _get(SERVER_URL + "get_task_types?type=group");

      if (response) {
        this.taskTypes = response.data.result;
        this.taskTypeSpinner = false;
      }
    },
  },
  created() {
    this.getGroup(1, 5);
    this.getTaskTypes();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}

::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
.color-highlight {
  color: $primary-color !important;
  text-decoration: none;
}

// ::v-deep .p-paginator-bottom {
//   // display: none !important;
// }
p {
  font-size: 1rem;
  margin: 0;
}

.sec-content {
  background: rgba(2, 0, 56, 0.03) !important;
  width: 100%;
  padding: 1rem 1rem;
}

.std-padding-x {
  padding: 0 1rem;
}

.lt-txt-color {
  color: rgba(2, 0, 56, 0.4);
  font-weight: 600;
}
.p-card {
  margin-top: -4rem;
  box-shadow: none;
}
::v-deep .icons {
  border: unset !important;
}
::v-deep .col-5 {
  padding: unset !important;
}
::v-deep .col-4 {
  padding: unset !important;
}
::v-deep .p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 0.5rem 1rem !important;
}
</style>
