<template>
  <div class="authentication-wrapper">
    <Header />
    <div class="login-screen flex justify-content-center"><Login /></div>
    <Footer />
  </div>
</template>

<script>
import Header from "./Header.vue"
import Footer from "./Footer.vue"
import Login from "./Login.vue"

export default {
  name: "index",
  components: {
    Header,
    Footer,
    Login,
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.login-screen {
  width: 100%;
  margin: 2rem auto;
}
.authentication-wrapper {
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  display: flex;
  background: linear-gradient(180deg, #eef4fa 0%, #ffffff 100%);
}
</style>
