<template>
  <div class="flex flex-gap mt-3">
    <div class="col-4">
      <div class="">
        <InputWithIcon
          :value="formData.taskTypes"
          placeholder="Enter your task type"
          width="100%"
          :icon="threedot"
          @onInput="onChangeTaskType"
        />
      </div>
    </div>
    <div class="col-5">
      <div class="attachment flex align-items-center">
        Attachment required?
        <div class="toggle">
          <ToggleSwitch
            :isChecked="formData.attachmentRequired"
            @onChange="onChangeCheck"
          />
        </div>
        <img
          @click="deleteTaskType"
          class="pointer"
          src="@/assets/delete.svg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ThreeDot from "@/assets/three_dot.svg"
import { _delete, _put } from "../../../../../services/crudService"
import { SERVER_URL } from "../../../../../../constants"

export default {
  props: ["list"],
  data() {
    return {
      threedot: ThreeDot,
      updatedTask: null,
      formData: {
        taskTypes: this.$props.list.taskTypes || "",
        attachmentRequired: this.$props.list.attachmentRequired || false,
      },
    }
  },
  methods: {
    onChangeTaskType(value) {
      this.formData.taskTypes = value
      console.log(value)

      clearTimeout(this.updatedTask)

      this.updatedTask = setTimeout(async () => {
        try {
          const response = await _put(
            SERVER_URL + `update_task_types?id=${this.$props.list.Id}`,
            this.formData
          )

          if (response) {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Task type updated successfully",
              life: 3000,
            })
          }
        } catch (err) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          })
        }
      }, 800)
    },
    onChangeCheck(value) {
      this.formData.attachmentRequired = value
      clearTimeout(this.updatedTask)

      this.updatedTask = setTimeout(async () => {
        try {
          const response = await _put(
            SERVER_URL + `update_task_types?id=${this.$props.list.Id}`,
            this.formData
          )

          if (response) {
            this.$toast.add({
              severity: "success",
              summary: "Task type updated successfully",
              life: 3000,
            })
          }
        } catch (error) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          })
        }
      }, 200)
    },
    async deleteTaskType() {
      this.$emit("deletingTaskType")
      const response = await _delete(
        SERVER_URL + `delete_task_types?id=${this.$props.list.Id}`
      )

      if (response) {
        this.$toast.add({
          severity: "success",
          summary: "Task type deleted successfully",
          life: 3000,
        })

        this.$emit("taskTypeChange")
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.attachment {
  color: #333;
  font-weight: 600;
}

.toggle {
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-right: 1rem;
}

:deep .field {
  margin-bottom: 0px !important;
}

:deep .p-inputtext {
  margin-top: 0 !important;
}

:deep .p-float-label {
  margin-bottom: 0 !important;
}

.flex-gap {
  gap: 1.5rem;
}
</style>
