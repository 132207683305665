<template>
	<div class="total-value-wrapper mr-top">
		<p class="total">{{ title }}</p>
		<div :key="refreshKey" class="flex flex-gap align-items-center">
			<div>
				<p class="title">New loan repayment monthly</p>
				<p class="value">
					${{
						newMonthlyRepayment && !disable
							? Math.round(newMonthlyRepayment).toLocaleString()
							: 0
					}}
				</p>
			</div>
			<div>
				<p class="title">Credit cards / Overdrafts Monthly</p>
				<p class="value">
					${{
						totalMonthlyCard? totalMonthlyCard.toLocaleString(undefined, {
									minimumFractionDigits: 2,})
							: 0
					}}
				</p>
			</div>
			<div>
				<p class="title">Total monthly liability repayments</p>
				<p class="value">
					${{
						totalMonthlyRepayment
							? Math.round(totalMonthlyRepayment).toLocaleString()
							: 0
					}}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	data() {
		return {
			totalMonthlyRepayment: 0,
			totalMonthlyCard: 0,
			newMonthlyRepayment: 0,
			refreshKey: 0,
		};
	},
	props: ["title", "disable"],
	computed: {
		...mapGetters({
			personalLoanTotal: "subTotal/personalLoanTotal",
			cardsTotal: "subTotal/cardsTotal",
			vehicleTotal: "subTotal/vehicleTotal",
			selectedApplication: "applications/selectedApplication",
			propertyTotal: "subTotal/propertyTotal",
		}),
	},
	methods: {
		setData() {
			const { calculated_fields } = this.selectedApplication;

			this.newMonthlyRepayment = calculated_fields?.new_loan_repayment_monthly
				? Number(calculated_fields?.new_loan_repayment_monthly)
				: 0;
			if (!this.disable) {
				// this.totalMonthlyRepayment =
				//   this.personalLoanTotal.applicant_1 + this.vehicleTotal.applicant_1;
				this.totalMonthlyCard = this.cardsTotal.applicant_1;
				this.totalMonthlyRepayment =
					this.newMonthlyRepayment +
					this.totalMonthlyCard +
					this.personalLoanTotal.applicant_1 +
					this.vehicleTotal.applicant_1 +
					this.propertyTotal.applicant_1;
			} else {
				// this.totalMonthlyRepayment =
				//   this.personalLoanTotal.applicant_2 + this.vehicleTotal.applicant_2;
				this.totalMonthlyCard = this.cardsTotal.applicant_2;
				this.totalMonthlyRepayment =
					/* this.newMonthlyRepayment + */
					this.totalMonthlyCard +
					this.personalLoanTotal.applicant_2 +
					this.vehicleTotal.applicant_2 +
					this.propertyTotal.applicant_2;
			}
			this.refreshKey += 1;
		},
	},
	created() {
		this.setData();
	},
	watch: {
		personalLoanTotal: {
			handler() {
				this.setData();
			},
			deep: true,
		},
		cardsTotal: {
			handler() {
				this.setData();
			},
			deep: true,
		},
		vehicleTotal: {
			handler() {
				this.setData();
			},
			deep: true,
		},
		propertyTotal: {
			handler() {
				this.setData();
			},
			deep: true,
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.total-value-wrapper {
	background: #02003808;
	border: 1px solid #02003866;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 20px;
}

p {
	margin: 0;
}

.total {
	font-family: Gordita-bold;
	font-size: 1.313rem;
	color: $secondary-color;
	text-transform: uppercase;
}

.flex-gap {
	gap: 2.5rem;
}

.title {
	color: $secondary-color;
	font-family: Gordita-medium;
	font-size: 1.125rem;
}

.value {
	color: $secondary-color;
	font-family: Gordita-bold;
	font-size: 1.313rem;
}
.mr-top {
	margin-top: 2.5rem;
}

.mr-top-1 {
	margin-top: 1rem !important;
}
</style>
