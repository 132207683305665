<template>
  <div class="application-header-layout pt-4 pl-4 pr-4 pb-0">
    <h1 class="module-title white-color">Customers</h1>
    <div class="flex h-100 mt-4 bold-600">
      <div v-for="(item, i) in list" :key="i">
        <router-link :to="routeLink(i)" class="a-link">
          <div class="mr-1 cursor-pointer application-option application-option-inactive">
            {{ item.title }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplicationHeader",
  data() {
    return {
      list: [
        { title: "Customer details", isActive: true },
        { title: "Applications", isActive: false },
        { title: "Communications", isActive: false },
        { title: "Tasks", isActive: false },
      ],
    };
  },
  methods: {
    onSelectItem(i) {
      this.list.map((obj) => {
        obj.isActive = false;
      });
      this.list[i].isActive = true;
      if (this.$route.params.id) {
        switch (i) {
          case 0:
            this.$router.push({
              name: "CustomerDetail",
              params: this.$route.params.id,
              query: {
                mobile: this.$route.query.mobile,
                email: this.$route.query.email,
                firstName: this.$route.query.firstName,
              },
            });
            break;
          case 1:
            this.$router.push({
              name: "CustomerApplication",
              params: this.$route.params.id,
              query: {
                mobile: this.$route.query.mobile,
                email: this.$route.query.email,
                firstName: this.$route.query.firstName,
              },
            });
            break;
          case 2:
            this.$router.push({
              name: "CustomerCommunication",
              query: {
                mobile: this.$route.query.mobile,
                firstName: this.$route.query.firstName,
                email: this.$route.query.email,
              },
            });
            break;
          case 3:
            this.$router.push({
              name: "CustomerTasks",
              params: this.$route.params.id,
              query: {
                mobile: this.$route.query.mobile,
                firstName: this.$route.query.firstName,
                email: this.$route.query.email,
              },
            });
            break;
        }
      }
    },
    routeLink(i) {
      if (this.$route.params.id) {
        switch (i) {
          case 0:
            return (
              `/customers/${this.$route.params.id}/detail?mobile=` +
              this.$route.query.mobile +
              "&firstName=" +
              this.$route.query.firstName +
              "&email=" +
              this.$route.query.email
            );

          case 1:
            return (
              `/customers/${this.$route.params.id}/applications?mobile=` +
              this.$route.query.mobile +
              "&firstName=" +
              this.$route.query.firstName +
              "&email=" +
              this.$route.query.email
            );

          case 2:
            return (
              `/customers/${this.$route.params.id}/communication?mobile=` +
              this.$route.query.mobile +
              "&firstName=" +
              this.$route.query.firstName +
              "&email=" +
              this.$route.query.email
            );

          case 3:
            return (
              `/customers/${this.$route.params.id}/task?mobile=` +
              this.$route.query.mobile +
              "&firstName=" +
              this.$route.query.firstName +
              "&email=" +
              this.$route.query.email
            );
        }
      }
    },
    routeNavigation() {
      switch (this.$route.name) {
        case "CustomerDetail":
          this.onSelectItem(0);
          break;
        case "CustomerApplication":
          this.onSelectItem(1);
          break;
        case "CustomerCommunication":
          this.onSelectItem(2);
          break;
        case "CustomerTasks":
          this.onSelectItem(3);
          break;
        case "Customer":
          this.onSelectItem(0);
          break;
      }
    },
  },
  watch: {
    $route() {
      // this.routeNavigation();
    },
  },
  created() {
    // this.routeNavigation();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.application-header-layout {
  min-height: 122px;
  width: 100%;
  background: #9193ad;
}
.white-color {
  color: $white-color;
}
.application-option {
  padding: 5px 10px;
  background: #eef4fa;
  border-radius: 8px 8px 0px 0px;
  color: #020038;
}
.application-option-inactive {
  background: rgba(2, 0, 56, 0.2);
  border-radius: 8px 8px 0px 0px;
  color: $white-color;
  padding: 5px 10px;
}
.h-100 {
  height: 100%;
}
.router-link-active .application-option {
  padding: 5px 10px;
  background: #eef4fa;
  border-radius: 8px 8px 0px 0px;
  color: #020038;
}
.application-option-inactive {
  background: rgba(2, 0, 56, 0.2);
  border-radius: 8px 8px 0px 0px;
  color: $white-color;
  padding: 5px 10px;
}
.a-link {
  text-decoration: none;
}
</style>
