<template>
  <div class="total-value-wrapper mr-top">
    <p class="total">{{ title }}</p>
    <div class="flex flex-gap align-items-center">
      <div>
        <p class="title">Total Yearly Income</p>
        <p class="value">${{ Math.round(yearlyTotal).toLocaleString() }}</p>
      </div>
      <div>
        <p class="title">Income after tax</p>
        <p class="value">
          $<span
            v-if="
              selectedApplication.calculated_fields &&
              selectedApplication.calculated_fields
                .applicant_1_annualIncomeAfterTax &&
              title === 'Applicant 1 - Income Overview'
            "
            >{{
              selectedApplication.calculated_fields.applicant_1_annualIncomeAfterTax
                .toFixed(3)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")
            }}
          </span>
          <span
            v-else-if="
              selectedApplication.calculated_fields &&
              selectedApplication?.calculated_fields
                ?.applicant_2_annualIncomeAfterTax &&
              title === 'Applicant 2 - Income Overview'
            "
            >{{
              selectedApplication.calculated_fields.applicant_2_annualIncomeAfterTax
                .toFixed(3)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")
            }}
          </span>
          <span v-else>0</span>
        </p>
      </div>
      <div>
        <p class="title">Total monthly Income after tax</p>
        <p class="value">${{ monthlyTotalIncomeAfterTax }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      incomeDetails: [],
      monthlyTotal: 0,
      yearlyTotal: 0,
      monthlyTotalIncomeAfterTax: 0,
    };
  },
  props: ["title", "disable"],
  computed: {
    ...mapGetters({
      fullTimeIncomeTotal: "subTotal/fullTimeIncomeTotal",
      partTimeIncomeTotal: "subTotal/partTimeIncomeTotal",
      casualIncomeTotal: "subTotal/casualIncomeTotal",
      selfIncomeTotal: "subTotal/selfIncomeTotal",
      additionalIncomeTotal: "subTotal/additionalIncomeTotal",
      selectedApplication: "applications/selectedApplication",
    }),
  },
  methods: {
    ...mapActions({ setOverallIncomeTotal: "subTotal/setOverallIncomeTotal" }),
    setData() {
      let total = 0;
      console.log("yearlyTotal", this.fullTimeIncomeTotal);
      if (!this.disable) {
        total += this.fullTimeIncomeTotal.applicant_1.reduce(
          (prev, value) => prev + value,
          0
        );
        total += this.partTimeIncomeTotal.applicant_1.reduce(
          (prev, value) => prev + value,
          0
        );
        total += this.casualIncomeTotal.applicant_1.reduce(
          (prev, value) => prev + value,
          0
        );
        total += this.selfIncomeTotal.applicant_1.reduce(
          (prev, value) => prev + value,
          0
        );
        total += this.additionalIncomeTotal.applicant_1;
      } else {
        total += this.fullTimeIncomeTotal.applicant_2.reduce(
          (prev, value) => prev + value,
          0
        );
        total += this.partTimeIncomeTotal.applicant_2.reduce(
          (prev, value) => prev + value,
          0
        );
        total += this.casualIncomeTotal.applicant_2.reduce(
          (prev, value) => prev + value,
          0
        );
        total += this.selfIncomeTotal.applicant_2.reduce(
          (prev, value) => prev + value,
          0
        );
        total += this.additionalIncomeTotal.applicant_2;
      }
      this.monthlyTotal = total ? total : 0;
      this.yearlyTotal = total ? total * 1 : 0;
      this.setOverallIncomeTotal({
        data: this.monthlyTotal,
        type: !this.disable ? "primary" : "secondary",
      });
    },
  },
  created() {
    if (this.title == "Applicant 1 - Income Overview") {
      if (
        this.selectedApplication.calculated_fields &&
        this.selectedApplication.calculated_fields
          .applicant_1_annualIncomeAfterTax
      ) {
        this.monthlyTotalIncomeAfterTax = (
          Number(
            this.selectedApplication.calculated_fields
              .applicant_1_annualIncomeAfterTax
          ) / 12
        ).toLocaleString();
      }
    } else {
      if (
        this.selectedApplication.calculated_fields &&
        this.selectedApplication.calculated_fields
          .applicant_2_annualIncomeAfterTax
      ) {
        this.monthlyTotalIncomeAfterTax = (
          Number(
            this.selectedApplication.calculated_fields
              .applicant_2_annualIncomeAfterTax
          ) / 12
        ).toLocaleString();
      }
    }
    this.setData();
  },
  watch: {
    fullTimeIncomeTotal: {
      handler() {
        this.setData();
      },
      deep: true,
    },
    partTimeIncomeTotal: {
      handler() {
        this.setData();
      },
      deep: true,
    },
    casualIncomeTotal: {
      handler() {
        this.setData();
      },
      deep: true,
    },
    selfIncomeTotal: {
      handler() {
        this.setData();
      },
      deep: true,
    },
    additionalIncomeTotal: {
      handler() {
        this.setData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.total-value-wrapper {
  background: #02003808;
  border: 1px solid #02003866;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px;
}

p {
  margin: 0;
}

.total {
  font-family: Gordita-bold;
  font-size: 1.313rem;
  color: $secondary-color;
  text-transform: uppercase;
}

.flex-gap {
  gap: 2.5rem;
}

.title {
  color: $secondary-color;
  font-family: Gordita-medium;
  font-size: 1.125rem;
}

.value {
  color: $secondary-color;
  font-family: Gordita-bold;
  font-size: 1.313rem;
}
.mr-top {
  margin-top: 2.5rem;
}

.mr-top-1 {
  margin-top: 1rem !important;
}
</style>
