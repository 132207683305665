<template>
  <div class="total-value-wrapper mr-top">
    <div v-if="totalValues" class="total-values">
      <div class="flex flex-gap align-items-center">
        <div v-for="value of totalValues" :key="value.id">
          <p class="title">{{ value.text }}</p>
          <p class="value">{{ value.value }}</p>
        </div>
      </div>
    </div>

    <div
      :class="totalValues && 'mr-top-1'"
      class="total flex align-items-center justify-content-between"
    >
      <p class="title">{{ overallValue[0] }}</p>
      <p class="value">${{ Math.round(total).toLocaleString() }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["totalValues", "overallValue", "disable"],
  computed: {
    ...mapGetters({
      savingsTotal: "subTotal/savingsTotal",
      sharesTotal: "subTotal/sharesTotal",
      superTotal: "subTotal/superTotal",
      propertyTotal: "subTotal/propertyTotal",
      assetSavings: "applications/assetSavings",
      assetShares: "applications/assetShares",
      assetSuperannuation: "applications/assetSuperannuation",
    }),
    total() {
      let savingsAmount = 0,
        sharesAmount = 0,
        superAmount = 0;
      let total = 0;
      if (!this.disable) {
        savingsAmount = this.assetSavings.reduce((a, b) => {
          if (b.applicant_type == "primary") {
            return a + b.amount_used;
          } else {
            return a;
          }
        }, 0);
        sharesAmount = this.assetShares.reduce((a, b) => {
          if (b.applicant_type == "primary") {
            return a + b.amount;
          } else {
            return a;
          }
        }, 0);
        superAmount = this.assetSuperannuation.reduce((a, b) => {
          if (b.applicant_type == "primary") {
            return a + b.amount;
          } else {
            return a;
          }
        }, 0);
        total = savingsAmount + sharesAmount + superAmount;
      } else {
        savingsAmount = this.assetSavings.reduce((a, b) => {
          if (b.applicant_type == "secondary") {
            return a + b.amount_used;
          } else {
            return a;
          }
        }, 0);
        sharesAmount = this.assetShares.reduce((a, b) => {
          if (b.applicant_type == "secondary") {
            return a + b.amount;
          } else {
            return a;
          }
        }, 0);
        superAmount = this.assetSuperannuation.reduce((a, b) => {
          if (b.applicant_type == "secondary") {
            return a + b.amount;
          } else {
            return a;
          }
        }, 0);
        total = savingsAmount + sharesAmount + superAmount;
      }

      // total += !this.disable
      //   ? this.savingsTotal.applicant_1
      //   : this.savingsTotal.applicant_2;
      // total += !this.disable
      //   ? this.sharesTotal.applicant_1
      //   : this.sharesTotal.applicant_2;
      // total += !this.disable ? this.superTotal.applicant_1 : this.superTotal.applicant_2;
      return total;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.total-value-wrapper {
  background: #02003808;
  border: 1px solid #02003866;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px;

  p {
    margin: 0;
  }

  .flex-gap {
    gap: 2.5rem;
  }

  .total-values {
    border-bottom: 1px solid #02003826;
    padding-bottom: 20px;

    .title {
      color: $secondary-color;
      font-family: Gordita;
      font-size: 1.125rem;
    }

    .value {
      color: $secondary-color;
      font-family: Gordita-bold;
      font-size: 1.313rem;
    }
  }

  .total {
    .title {
      font-family: Gordita-bold;
      font-size: 1.313rem;
      color: $secondary-color;
      text-transform: uppercase;
    }

    .value {
      font-family: Gordita-bold;
      font-size: 1.313rem;
      color: $secondary-color;
    }
  }
}
.mr-top {
  margin-top: 2.5rem;
}

.mr-top-1 {
  margin-top: 1rem !important;
}
</style>
