<template>
  <Dialog
    :draggable="false"
    :visible="show"
    position="top"
    :modal="show"
    :style="{ width: '680px' }"
  >
    <template #header>
      <h1 class="modal-title">Merge Selected</h1>
    </template>
    <div class="modal-content">
      <div class="flex align-items-center">
        <p class="col-2 flex justify-content-end text hk-md">Name</p>
        <div
          v-for="(item, index) in selectedIncome"
          :key="index"
          class="col-5 flex fg-10 align-items-center"
        >
          <Checkbox2
            :id="item"
            :name="item.name_of_employer"
            :value="income.name_of_employer == item.name_of_employer"
            @getInputValue="(e) => selectIncome(e, item)"
            :key="refreshKey"
          />
          <div class="mb-2" :for="item.name_of_employer">
            {{ item.name_of_employer }}
          </div>
        </div>
      </div>

      <div :key="refreshKey" class="flex align-items-center">
        <p class="col-2"></p>
        <div class="col-10">
          <div>
            <p class="label">Employer name</p>
            <TextField :value="employerName" :disable="true" width="100%" />
          </div>
        </div>
      </div>
      <!-- Salary -->
      <div :key="refreshKey" class="form-inputs mt-2">
        <div class="p-grid align-items-center header">
          <p class="p-col-2"></p>
          <p class="p-col flex justify-content-start label">
            Application Value
          </p>
          <p class="p-col label">Verified Value</p>
          <p class="p-col label">Manual Value</p>
        </div>

        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Salary</p>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedSalaryApplicationValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedSalaryVerifiedValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div class="p-col flex align-items-center justify-content-center">
            <TextField
              :value="calculatedSalaryManualValue"
              :disable="true"
              width="100%"
            />
          </div>
        </div>
      </div>

      <!-- Salary packaged -->
      <div :key="refreshKey" class="form-inputs mt-2">
        <div class="p-grid align-items-center header">
          <p class="p-col-2"></p>
          <p class="p-col flex justify-content-start label">
            Application Value
          </p>
          <p class="p-col label">Verified Value</p>
          <p class="p-col label">Manual Value</p>
        </div>

        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Packaged</p>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedSalaryPackageApplicationValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedSalaryVerifiedValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div class="p-col flex align-items-center justify-content-center">
            <TextField
              :value="calculatedSalaryPackageManualValue"
              :disable="true"
              width="100%"
            />
          </div>
        </div>
      </div>

      <!-- Overtime -->
      <div :key="refreshKey" class="form-inputs mt-2">
        <div class="p-grid align-items-center header">
          <p class="p-col-2"></p>
          <p class="p-col flex justify-content-start label">
            Application Value
          </p>
          <p class="p-col label">Verified Value</p>
          <p class="p-col label">Manual Value</p>
        </div>

        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Overtime</p>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedOvertimeApplicationValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedOvertimeVerifiedValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div class="p-col flex align-items-center justify-content-center">
            <TextField
              :value="calculatedOvertimeManualValue"
              :disable="true"
              width="100%"
            />
          </div>
        </div>
      </div>

      <!-- Commission -->
      <div :key="refreshKey" class="form-inputs mt-2">
        <div class="p-grid align-items-center header">
          <p class="p-col-2"></p>
          <p class="p-col flex justify-content-start label">
            Application Value
          </p>
          <p class="p-col label">Verified Value</p>
          <p class="p-col label">Manual Value</p>
        </div>

        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Commission</p>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedCommissionApplicationValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedCommissionVerifiedValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div class="p-col flex align-items-center justify-content-center">
            <TextField
              :value="calculatedCommissionManualValue"
              :disable="true"
              width="100%"
            />
          </div>
        </div>
      </div>

      <!-- Bonuses -->
      <div :key="refreshKey" class="form-inputs mt-2">
        <div class="p-grid align-items-center header">
          <p class="p-col-2"></p>
          <p class="p-col flex justify-content-start label">
            Application Value
          </p>
          <p class="p-col label">Verified Value</p>
          <p class="p-col label">Manual Value</p>
        </div>

        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Bonuses</p>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedBonusesApplicationValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedBonusesVerifiedValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div class="p-col flex align-items-center justify-content-center">
            <TextField
              :value="calculatedBonusesManualValue"
              :disable="true"
              width="100%"
            />
          </div>
        </div>
      </div>

      <!-- Total Income -->
      <div :key="refreshKey" class="form-inputs mt-2">
        <div class="p-grid align-items-center header">
          <p class="p-col-2"></p>
          <p class="p-col flex justify-content-start label">
            Application Value
          </p>
          <p class="p-col label">Verified Value</p>
          <p class="p-col label">Manual Value</p>
        </div>

        <div class="p-grid align-items-center">
          <p class="p-col-2 flex justify-content-end label">Total Income</p>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedTotalApplicationValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div
            class="
              p-col
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :value="calculatedTotalVerifiedValue"
              :disable="true"
              width="100%"
            />
          </div>
          <div class="p-col flex align-items-center justify-content-center">
            <TextField
              :value="calculatedTotalManualValue"
              :disable="true"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button
          label="Merge selected"
          type="primary"
          @click="saveMerged"
          :disabled="!employerName"
        />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">
          Close
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Dialog from "primevue/dialog";
import Checkbox2 from "@/components/common/Checkbox2";

export default {
  name: "AddTask",
  props: ["show", "selectedIncome", "allIncome", "disable", "type"],
  components: { Dialog, Checkbox2 },
  data() {
    return {
      income: {},
      otherIncome: {},
      refreshKey: 10,
      employerName: "",
      //Salary
      calculatedSalaryApplicationValue: null,
      calculatedSalaryVerifiedValue: null,
      calculatedSalaryManualValue: null,
      //Salary Package
      calculatedSalaryPackageApplicationValue: null,
      calculatedSalaryPackageVerifiedValue: null,
      calculatedSalaryPackageManualValue: null,
      //Overtime
      calculatedOvertimeApplicationValue: null,
      calculatedOvertimeVerifiedValue: null,
      calculatedOvertimeManualValue: null,
      //Commission
      calculatedCommissionApplicationValue: null,
      calculatedCommissionVerifiedValue: null,
      calculatedCommissionManualValue: null,
      //Bonuses
      calculatedBonusesApplicationValue: null,
      calculatedBonusesVerifiedValue: null,
      calculatedBonusesManualValue: null,
      //Total
      calculatedTotalApplicationValue: null,
      calculatedTotalVerifiedValue: null,
      calculatedTotalManualValue: null,
    };
  },
  computed: {
    ...mapState({
      fullTimeUpdated: (state) => state.applications.fullTimeUpdated,
      partTimeUpdated: (state) => state.applications.partTimeUpdated,
      casualUpdated: (state) => state.applications.casualUpdated,
    }),
  },
  methods: {
    ...mapActions({
      setFullTimeIncome: "applications/setFullTimeIncome",
      setPartTimeIncome: "applications/setPartTimeIncome",
      setCasualIncome: "applications/setCasualIncome",
      setFullTimeUpdated: "applications/setFullTimeUpdated",
      setPartTimeUpdated: "applications/setPartTimeUpdated",
      setCasualUpdated: "applications/setCasualUpdated",
    }),
    saveMerged() {
      let allArr = this.allIncome.filter(
        (item) =>
          item.name_of_employer != this.income.name_of_employer &&
          item.name_of_employer != this.otherIncome.name_of_employer
      );
      let arr = [
        ...allArr,
        {
          name_of_employer: this.employerName,
          salary_amount: this.calculatedSalaryApplicationValue,
          salary_amount_used: this.calculatedSalaryManualValue,
          salary_packaged_used: this.calculatedSalaryPackageManualValue,
          overtime_pay_amount_used: this.calculatedOvertimeManualValue,
          commissions_used: this.calculatedBonusesManualValue,
          bonuses_amount_used: this.calculatedCommissionApplicationValue,
          salary_packaged: this.calculatedSalaryPackageApplicationValue,
          salary_frequency: "Monthly",
          overtime_pay_amount: this.calculatedOvertimeApplicationValue,
          overtime_pay_frequency: "Monthly",
          commissions_amount: this.calculatedCommissionApplicationValue,
          commissions_frequency: "Monthly",
          bonuses_amount: this.calculatedBonusesApplicationValue,
          bonuses_frequency: "Monthly",
          applicant_type: !this.disable ? "primary" : "secondary",
          jobType: this.type,
          salary_amount_verified: this.calculatedSalaryVerifiedValue,
          salary_amount_manual: this.calculatedSalaryManualValue,
          salary_amount_used_value: "manual_value",
          salary_packaged_verified: this.calculatedSalaryPackageVerifiedValue,
          salary_packaged_manual: this.calculatedSalaryPackageManualValue,
          salary_packaged_used_value: "manual_value",
          overtime_pay_amount_verified: this.calculatedOvertimeVerifiedValue,
          overtime_pay_amount_manual: this.calculatedOvertimeManualValue,
          overtime_pay_amount_used_value: "manual_value",
          commissions_amount_verified: this.calculatedCommissionVerifiedValue,
          commissions_amount_manual: this.calculatedCommissionManualValue,
          commissions_amount_used_value: "manual_value",
          bonuses_amount_verified: this.calculatedBonusesVerifiedValue,
          bonuses_amount_manual: this.calculatedBonusesManualValue,
          bonuses_amount_used_value: "manual_value",
          total_income_amount_verified: this.calculatedTotalVerifiedValue,
          total_income_amount_manual: this.calculatedTotalManualValue,
          total_income_amount_used_value: "manual_value",
        },
      ];
      if (this.type == "full_time") {
        this.setFullTimeIncome({
          data: arr,
          type: !this.disable ? "primary" : "secondary",
        });

        this.setFullTimeUpdated(this.fullTimeUpdated + 1);
      } else if (this.type == "part_time") {
        this.setPartTimeIncome({
          data: arr,
          type: !this.disable ? "primary" : "secondary",
        });
        this.setPartTimeUpdated(this.partTimeUpdated + 1);
      } else {
        this.setCasualIncome({
          data: arr,
          type: !this.disable ? "primary" : "secondary",
        });
        this.setCasualUpdated(this.casualUpdated + 1);
      }
      this.$emit("mergeSelected");
    },
    selectIncome(value, income) {
      this.income = {
        ...income,
        salary_amount: income.salary_amount ? Number(income.salary_amount) : 0,
        salary_packaged: income.salary_packaged
          ? Number(income.salary_packaged)
          : 0,
        overtime_pay_amount: income.overtime_pay_amount
          ? Number(income.overtime_pay_amount)
          : 0,
        commissions_amount: income.commissions_amount
          ? Number(income.commissions_amount)
          : 0,
        bonuses_amount: income.bonuses_amount
          ? Number(income.bonuses_amount)
          : 0,
        salary_amount_verified: income.salary_amount_verified
          ? Number(income.salary_amount_verified)
          : 0,
        salary_amount_manual: income.salary_amount_manual
          ? Number(income.salary_amount_manual)
          : 0,
        salary_packaged_verified: income.salary_packaged_verified
          ? Number(income.salary_packaged_verified)
          : 0,
        salary_packaged_manual: income.salary_packaged_manual
          ? Number(income.salary_packaged_manual)
          : 0,
        overtime_pay_amount_verified: income.overtime_pay_amount_verified
          ? Number(income.overtime_pay_amount_verified)
          : 0,
        overtime_pay_amount_manual: income.overtime_pay_amount_manual
          ? Number(income.overtime_pay_amount_manual)
          : 0,
        commissions_amount_verified: income.commissions_amount_verified
          ? Number(income.commissions_amount_verified)
          : 0,
        commissions_amount_manual: income.commissions_amount_manual
          ? Number(income.commissions_amount_manual)
          : 0,
        bonuses_amount_verified: income.bonuses_amount_verified
          ? Number(income.bonuses_amount_verified)
          : 0,
        bonuses_amount_manual: income.bonuses_amount_manual
          ? Number(income.bonuses_amount_manual)
          : 0,
        total_income_amount_verified: income.total_income_amount_verified
          ? Number(income.total_income_amount_verified)
          : 0,
        total_income_amount_manual: income.total_income_amount_manual
          ? Number(income.total_income_amount_manual)
          : 0,
      };
      this.otherIncome = this.selectedIncome.find(
        (item) => item.name_of_employer != income.name_of_employer
      );
      this.otherIncome = {
        ...this.otherIncome,
        salary_amount: this.otherIncome.salary_amount
          ? Number(this.otherIncome.salary_amount)
          : 0,
        salary_packaged: this.otherIncome.salary_packaged
          ? Number(this.otherIncome.salary_packaged)
          : 0,
        overtime_pay_amount: this.otherIncome.overtime_pay_amount
          ? Number(this.otherIncome.overtime_pay_amount)
          : 0,
        commissions_amount: this.otherIncome.commissions_amount
          ? Number(this.otherIncome.commissions_amount)
          : 0,
        bonuses_amount: this.otherIncome.bonuses_amount
          ? Number(this.otherIncome.bonuses_amount)
          : 0,
        salary_amount_verified: this.otherIncome.salary_amount_verified
          ? Number(this.otherIncome.salary_amount_verified)
          : 0,
        salary_amount_manual: this.otherIncome.salary_amount_manual
          ? Number(this.otherIncome.salary_amount_manual)
          : 0,
        salary_packaged_verified: this.otherIncome.salary_packaged_verified
          ? Number(this.otherIncome.salary_packaged_verified)
          : 0,
        salary_packaged_manual: this.otherIncome.salary_packaged_manual
          ? Number(this.otherIncome.salary_packaged_manual)
          : 0,
        overtime_pay_amount_verified: this.otherIncome
          .overtime_pay_amount_verified
          ? Number(this.otherIncome.overtime_pay_amount_verified)
          : 0,
        overtime_pay_amount_manual: this.otherIncome.overtime_pay_amount_manual
          ? Number(this.otherIncome.overtime_pay_amount_manual)
          : 0,
        commissions_amount_verified: this.otherIncome
          .commissions_amount_verified
          ? Number(this.otherIncome.commissions_amount_verified)
          : 0,
        commissions_amount_manual: this.otherIncome.commissions_amount_manual
          ? Number(this.otherIncome.commissions_amount_manual)
          : 0,
        bonuses_amount_verified: this.otherIncome.bonuses_amount_verified
          ? Number(this.otherIncome.bonuses_amount_verified)
          : 0,
        bonuses_amount_manual: this.otherIncome.bonuses_amount_manual
          ? Number(this.otherIncome.bonuses_amount_manual)
          : 0,
        total_income_amount_verified: this.otherIncome
          .total_income_amount_verified
          ? Number(this.otherIncome.total_income_amount_verified)
          : 0,
        total_income_amount_manual: this.otherIncome.total_income_amount_manual
          ? Number(this.otherIncome.total_income_amount_manual)
          : 0,
      };
      this.employerName = this.income.name_of_employer;
      //Salary
      this.calculatedSalaryApplicationValue =
        this.income.salary_amount + this.otherIncome.salary_amount;
      this.calculatedSalaryVerifiedValue =
        this.income.salary_amount_verified +
        this.otherIncome.salary_amount_verified;
      this.calculatedSalaryManualValue =
        this.income.salary_amount_manual +
        this.otherIncome.salary_amount_manual;
      //Salary Package
      this.calculatedSalaryPackageApplicationValue =
        this.income.salary_packaged + this.otherIncome.salary_packaged;
      this.calculatedSalaryPackageVerifiedValue =
        this.income.salary_packaged_verified +
        this.otherIncome.salary_packaged_verified;
      this.calculatedSalaryPackageManualValue =
        this.income.salary_packaged_manual +
        this.otherIncome.salary_packaged_manual;
      //Overtime
      this.calculatedOvertimeApplicationValue =
        this.income.overtime_pay_amount + this.otherIncome.overtime_pay_amount;
      this.calculatedOvertimeVerifiedValue =
        this.income.overtime_pay_amount_verified +
        this.otherIncome.overtime_pay_amount_verified;
      this.calculatedOvertimeManualValue =
        this.income.overtime_pay_amount_manual +
        this.otherIncome.overtime_pay_amount_manual;
      //Commission
      this.calculatedCommissionApplicationValue =
        this.income.commissions_amount + this.otherIncome.commissions_amount;
      this.calculatedCommissionVerifiedValue =
        this.income.commissions_amount_verified +
        this.otherIncome.commissions_amount_verified;
      this.calculatedCommissionManualValue =
        this.income.commissions_amount_manual +
        this.otherIncome.commissions_amount_manual;
      //Bonuses
      this.calculatedBonusesApplicationValue =
        this.income.bonuses_amount + this.otherIncome.bonuses_amount;
      this.calculatedBonusesVerifiedValue =
        this.income.bonuses_amount_verified +
        this.otherIncome.bonuses_amount_verified;
      this.calculatedBonusesManualValue =
        this.income.bonuses_amount_manual +
        this.otherIncome.bonuses_amount_manual;
      //Total
      (this.calculatedTotalApplicationValue = 0),
        (this.calculatedTotalVerifiedValue =
          this.income.total_income_amount_verified +
          this.otherIncome.total_income_amount_verified);
      this.calculatedTotalManualValue =
        this.income.total_income_amount_manual +
        this.otherIncome.total_income_amount_manual;
      this.refreshKey += 1;
    },
    close() {
      this.refreshKey += 1;
      this.$emit("close");
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.checkbox-list {
  border: 1px solid rgba(2, 0, 56, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
}

.color-highlight {
  color: $secondary-color;
  font-family: Gordita-medium;
  font-size: 14px;
}

.modal-title {
  font-family: Gordita-medium-;
  font-size: 20px;
  color: $secondary-color;
}

.text,
label,
.label {
  font-family: Gordita-medium-;
  font-size: 14px;
  color: $secondary-color;
}

.color-highlight {
  color: $primary-color;
  text-decoration: underline;
}

p {
  margin: 0;
}

.fg-10 {
  gap: 10px;
}
::v-deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ced4da !important;
  border-color: #2196f3;
}
</style>
