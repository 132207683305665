<template>
  <div>
    <div class="std-padding-rv" v-if="!isRoute">
      <h1 class="module-title">Loans</h1>
      <ApplicationList @changes="changeRoute" class="mt-5" />
    </div>
    <ApplicationData v-else />
  </div>
</template>

<script>
import ApplicationList from "./ApplicationsList.vue";
import ApplicationData from "./ApplicationData/index.vue";
export default {
  name: "index",
  components: {
    ApplicationList,
    ApplicationData,
  },
  data() {
    return {
      isRoute: false,
    };
  },
  watch: {
    $route() {
      if (this.$route.name == "Application") {
        this.isRoute = false;
      } else {
        this.isRoute = true;
      }
    },
  },
  methods: {
    changeRoute({ id, loanId, declaration }) {
      // this.isRoute = true;
      this.$router.push(
        `/loan/${id}/overview?loanId=` + loanId + "&declaration=" + declaration
      );
    },
  },
  created() {
    if (this.$route.name == "Application") {
      this.isRoute = false;
    } else {
      this.isRoute = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
</style>
