<template>
  <div class="mr-b">
    <Panel class="box" :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>
      <template #header>
        <div
          @click="toggle"
          class="title-wrapper flex align-items-center full-width pointer"
        >
          <img src="@/assets/recent.svg" alt="" />
          <p class="title">Recent Activity</p>
        </div>
      </template>
      <Spinner v-if="isLoading" class="flex" />
      <DataTable v-else class="mt-2" :rows="5" dataKey="id" :value="statements">
        <Column field="created_by" header="Date">
          <template #body="slotProps">
            {{ formatDate(slotProps.data.created_by) }}
          </template>
        </Column>
        <Column field="created_by" header="Time">
          <template #body="slotProps">
            {{ formatTime(slotProps.data.created_by) }}
          </template>
        </Column>
        <Column field="description" header="Description">
          <template #body="slotProps"
            ><p class="pointer" @click="openActivityDialog(slotProps.data)">
              {{ slotProps.data.description }}
              <span class="txt-underline">Click here</span> to view value
              changes.
            </p>
          </template>
        </Column>
        <Column field="type" header="Type">
          <template #body>Application </template>
        </Column>
        <Column field="firstName" header="User">
          <template #body="slotProps"
            >{{ slotProps.data.firstName }} {{ slotProps.data.lastName }}
          </template>
        </Column>
      </DataTable>
    </Panel>

    <ActivityDialog
      :item="changeLogItem"
      :show="showActivityDialog"
      @close="showActivityDialog = false"
      :key="changeLogItemKey"
      :originalApp="originalApp"
      :latestApp="latestApp"
    />
  </div>
</template>

<script>
import Panel from "primevue/panel";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ActivityDialog from "./ActivityDialog.vue";
import { _get } from "../../../../../services/crudService";
import moment from "moment";
import { APP_URL } from "../../../../../../constants";
// import _ from "lodash";
import { detailedDiff } from "deep-object-diff";

export default {
  components: {
    Panel,
    DataTable,
    Column,
    ActivityDialog,
  },
  data() {
    return {
      collapsed: false,
      showActivityDialog: false,
      statements: [],
      isLoading: false,
      changeLogItem: [],
      changeLogItemKey: 0,
      originalApp: {},
      latestApp: {},
    };
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
    async openActivityDialog(data) {
      const result = await _get(
        APP_URL + "get-change-log-details?id=" + data.Id
      );
      this.originalApp = result.data.old_application;
      this.latestApp = result.data.new_application;
      const resultObj = await this.difference(
        result.data.old_application,
        result.data.new_application
      );
      // let newArray = [];
      // this.changeLogItem = [];
      // let keyArray = Object.keys(resultObj);
      // keyArray.map((obj) => {
      //   newArray = [...newArray, obj];
      //   if (typeof resultObj[obj] == "object") {
      //     if (Array.isArray(resultObj[obj])) {
      //       // newArray = [...newArray, obj];
      //       this.changeLogItem = newArray;
      //       Object.keys(resultObj[obj]).map((i) => {
      //         if (typeof resultObj[obj][i] == "object") {
      //           newArray = [...newArray, resultObj[obj][i]];
      //           this.changeLogItem = newArray;
      //         } else {
      //           newArray = [...newArray, { i: resultObj[obj][i] }];
      //           this.changeLogItem = newArray;
      //         }
      //       });
      //     } else {
      //       newArray = [...newArray, resultObj[obj]];
      //       this.changeLogItem = newArray;
      //     }
      //   } else {
      //     newArray.push({ value: resultObj[obj] });
      //   }
      //   this.changeLogItem = newArray;
      // });
      this.changeLogItem = resultObj;
      this.changeLogItemKey += 1;
      return (this.showActivityDialog = true);
    },
    difference(object, base) {
      // function changes(object, base) {
      //   return _.transform(object, function (result, value, key) {
      //     if (!_.isEqual(value, base[key])) {
      //       result[key] =
      //         _.isObject(value) && _.isObject(base[key])
      //           ? changes(value, base[key])
      //           : value;
      //     }
      //   });
      // }
      // return changes(object, base);
      return detailedDiff(base, object);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatTime(date) {
      return moment(date).format("hh:mm a");
    },
  },
  async created() {
    this.isLoading = true;
    const result = await _get(
      APP_URL + "get-application-change-logs?id=" + this.$route.params.id
    );
    this.statements = result?.data?.result ? result.data.result : [];
    this.statements.map((obj) => {
      if (obj.user) {
        let item = JSON.parse(obj.user);
        obj.firstName = item.firstName;
        obj.lastName = item.lastName;
        obj.created_by = new moment(obj.created_by).add(600, 'm').toDate();
      }
    });
    this.statements.sort(
      (a, b) => new Date(b.latest_version) - new Date(a.latest_version)
    );
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid $secondary-color !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid #02003826 !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
  padding: 1rem 1rem 0.5rem 1rem !important;
}

::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  //   border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
.color-highlight {
  color: $primary-color !important;
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.txt-underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.std-padding-x {
  padding: 0 2.188rem;
}

.flex-gap {
  gap: 0.625rem;
}

.title {
  font-size: 2rem;
  font-family: Gordita-bold;
  margin-left: 0.625rem;
  color: $secondary-color;
}

.box {
  background: #ffffff;
  border: 1px solid rgba(2, 0, 56, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 1.25rem;
  max-height: 325px;
  overflow: auto;
}

p {
  margin: 0;
}
</style>
