<template>
  <div>
    <!-- <div v-if="!token">
      <router-view></router-view>
    </div> -->
    <Toolbar />
    <div class="flex">
      <div class="sidebar-component">
        <Sidebar :menuItems="menuItems" title="hello" />
      </div>
      <div
        class="router-component"
        :class="isCommunication ? 'communication' : ''"
      >
        <router-view></router-view>
      </div>
    </div>
    <!-- <div class="chat">
      <img src="@/assets/chat1.svg" />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: "Index",
  components: {},
  data() {
    return {
      viewPipeline: true,
      isCommunication: false,
      menuItems: [
        {
          id: 1,
          text: "Dashboard",
          icon: "dashboard",
          link: "/dashboard",
          display: true,
        },
        {
          id: 2,
          text: "Tasks",
          icon: "task",
          link: "/tasks",
          display: true,
        },
        {
          id: 3,
          text: "Pipeline",
          icon: "contact",
          link: "/pipeline",
          display: true,
        },
        {
          id: 4,
          text: "Loans",
          icon: "application",
          link: "/loan",
          display: true,
        },
        {
          id: 5,
          text: "Customers",
          icon: "customer",
          link: "/customers",
          display: true,
        },
        {
          id: 6,
          text: "Brokers",
          icon: "broker",
          link: "/brokers",
          display: true,
        },
        {
          id: 7,
          text: "Reporting",
          icon: "reporting",
          link: "/reporting",
          display: true,
        },
        // {
        //   id: 8,
        //   text: "Communications",
        //   icon: "communication",
        //   link: "/communication",
        //   display : true
        // },
        {
          id: 9,
          icon: "calculator-icon",
          text: "Servicing calculator",
          link: "/servicing-calculator",
          display: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      condition: "auth/userPermission",
      isLoggedIn: "auth/isLoggedIn",
      token: "auth/token",
    }),
  },
  created() {
    this.viewPipeline = this.condition.viewPipeline;
    this.isLoggedIn = cookies.get("token");
    this.isCommunication = this.$route.path === "/communication" ? true : false;
  },
  watch: {
    $route() {
      this.isCommunication =
        this.$route.path === "/communication" ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.sidebar-component {
  background-color: $secondary-color;
  overflow: auto;
  height: calc(100vh - 80px);
  position: relative;
  width: 330px;
}

.router-component {
  background: #f1f6fb;
  // min-height: 100vh;
  height: calc(100vh - 80px);
  overflow: auto;
  width: 100%;
}
.communication {
  // margin-left: -200px;
  z-index: 1000;
}
.chat {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 99;
  background-color: $primary-color;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
</style>
