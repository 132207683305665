<template>
  <div>
    <div class="flex align-items-center justify-content end">
      <p :class="textAlign" class="label p-col-6">
        State <span style="color: red"> * </span>
      </p>
      <Dropdown
        class="p-col-6"
        width="100%"
        height="30px"
        placeholder="please select"
        type="text"
        :key="refreshKey"
        :value="formData?.state"
        :items="statesList"
        :inValid="v$.formData.state.$invalid && onSave"
        @change="getFormData($event, 'state')"
        id="outgoing_state"
      />
    </div>
    <div class="flex align-items-center justify-content end mt-1">
      <p :class="textAlign" class="label p-col-6">
        PostCode <span style="color: red"> * </span>
      </p>
      <NumberField
        class="p-col-6"
        width="100%"
        height="30px"
        :value="formData?.postal_code"
        :key="refreshKey2"
        placeholder="Enter postcode"
        @onInput="getFormData($event, 'postal_code')"
        :inValid="v$.formData.postal_code.$invalid && onSave"
        :maxValue="4"
        id="outgoing_postal_code"
      />
    </div>
    <div class="flex align-items-center justify-content end mt-1">
      <p :class="textAlign" class="label p-col-6">
        Estimated Valuation $ <span style="color: red"> * </span>
      </p>
      <NumberField
        class="p-col-6"
        width="100%"
        height="30px"
        :key="refreshKey"
        :value="formData?.estimated_price"
        :inValid="v$.formData.estimated_price.$invalid && onSave"
        placeholder="Enter amount"
        @onInput="getFormData($event, 'estimated_price')"
        :maxValue="9"
        prefix="$"
        id="outgoing_estimated_price"
      />
    </div>
    <div class="flex align-items-center justify-content end mt-1">
      <p :class="textAlign" class="label p-col-6">
        Current Mortgage $ <span style="color: red"> * </span>
      </p>
      <NumberField
        class="p-col-6"
        :key="refreshKey"
        width="100%"
        height="30px"
        :value="formData?.current_mortgage"
        type="number"
        placeholder="Enter amount"
        @onInput="getFormData($event, 'current_mortgage')"
        :maxValue="9"
        :inValid="v$.formData.current_mortgage.$invalid && onSave"
        prefix="$"
        id="outgoing_current_mortgage"
      />
    </div>
    <div class="flex align-items-center justify-content end mt-1">
      <p :class="textAlign" class="label p-col-6">Settlement Date</p>
      <div class="p-col-6">
        <DatePicker2
          placeholder="DD/MM/YYYY"
          style="width: 100%"
          @change="getFormData($event, 'settlement_date')"
          :value="formatDate(formData?.settlement_date)"
          height="30px"
          :key="refreshKey2"
          :minDate="minDate"
          :maxYear="maxYear"
          :minYear="minYear"
          id="outgoing_settlement_date"
        />
      </div>
    </div>
    <div class="flex align-items-center justify-content-center mt-1">
      <p :class="textAlign" class="label p-col-6">
        Has the property sold? <span style="color: red"> * </span>
      </p>
      <div class="dropdown p-col-6">
        <Dropdown
          width="100%"
          height="30px"
          optionLabel="name"
          :key="refreshKey"
          optionValue="value"
          placeholder="Please select"
          :value="formData?.property_sold"
          :items="propertyTo"
          :inValid="v$.formData.property_sold.$invalid && onSave"
          @change="getFormData($event, 'property_sold')"
          id="outgoing_property_sold"
        />
      </div>
    </div>
    <div class="flex align-items-center justify-content end mt-1">
      <p :class="textAlign" class="label p-col-6">
        Deposit held in trust? <span style="color: red"> * </span>
      </p>
      <div class="dropdown p-col-6">
        <Dropdown
          width="100%"
          height="30px"
          placeholder="Please select"
          :key="refreshKey"
          :value="formData?.deposit_held_in_trust"
          optionLabel="name"
          optionValue="value"
          :inValid="v$.formData.deposit_held_in_trust.$invalid && onSave"
          :items="depositTo"
          @change="getFormData($event, 'deposit_held_in_trust')"
          id="outgoing_deposit_held_in_trust"
        />
      </div>
    </div>

    <div class="flex align-items-center justify-content end mt-1">
      <p :class="textAlign" class="label p-col-6">
        Deposit Amount $ <span style="color: red"> * </span>
      </p>
      <NumberField
        class="p-col-6"
        width="100%"
        height="30px"
        :value="formData?.deposit_amount"
        placeholder="Enter amount"
        @onInput="getFormData($event, 'deposit_amount')"
        :maxValue="9"
        :key="refreshKey"
        :inValid="v$.formData.deposit_amount.$invalid && onSave"
        prefix="$"
        id="outgoing_deposit_amount"
      />
    </div>

    <div class="flex align-items-center justify-content-center mt-1 disable-input">
      <p :class="textAlign" class="label p-col-6">Property Zone</p>
      <TextField
        class="p-col-6"
        width="100%"
        :disable="true"
        height="30px"
        :value="formData?.zone?.replace(/_/g, ' ')"
        placeholder="property zone"
        @onInput="getFormData($event, 'zone')"
        :key="refreshKey"
        id="outgoing_zone"
      />
    </div>
    <div class="flex align-items-center justify-content-center mt-1">
      <p :class="textAlign" class="label p-col-6">
        Property Type <span style="color: red"> * </span>
      </p>
      <Dropdown
        class="p-col-6"
        width="100%"
        height="30px"
        :value="formData?.type"
        placeholder="please select"
        :items="propertyType"
        :key="refreshKey"
        :inValid="v$.formData.type.$invalid && onSave"
        optionLabel="name"
        optionValue="value"
        @change="getFormData($event, 'type')"
        id="outgoing_type"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SERVER_URL_2, STATES } from "../../../../../constants";
import { required, maxValue, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { _get } from "../../../../services/crudService";
import moment from "moment";

export default {
  props: ["properties", "id", "fieldValues", "responseValue"],
  data() {
    return {
      minDate: null,
      refreshKey: 10,
      refreshKey2: 10,
      maxYear: null,
      minYear: null,
      formData: {
        state: "",
        postal_code: "",
        estimated_price: 0,
        current_mortgage: 0,
        settlement_date: "",
        property_sold: "",
        deposit_held_in_trust: 0,
        deposit_amount: 0,
        zone: "",
        type: "",
        category: "",
      },
      statesList: STATES,
      propertyTo: [
        { name: "Sold", value: "sold" },
        { name: "No", value: "no" },
        { name: "List for sale", value: "list_for_sale" },
      ],
      depositTo: [
        { name: "Yes", value: true },
        { value: false, name: "No" },
      ],
      propertyType: [
        { name: "Residential", value: "residential" },
        { name: "Rural Residential", value: "rural_residential" },
        { name: "Off the plan Residential", value: "off_the_plan_residential" },
        { name: "Commercial", value: "commercial" },
        { name: "Vacant Land", value: "vacant_land" },
      ],
    };
  },
  watch: {
    // responseValue(result) {
    //   this.refreshKey++;

    //   for (const [key, value] of Object.entries(result)) {
    //     if (typeof value === "number") {
    //       this.formData[key] = value.toFixed(2);
    //     } else {
    //       this.formData[key] = value;
    //     }
    //   }
    // },
    fieldValues() {
      this.formData = this.$props.fieldValues;
      this.$emit("validateOutgoingProp", this.v$.$invalid);
      this.refreshKey2++;
      this.refreshKey++;
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        state: { required },
        postal_code: { required, minLength: minLength(3) },
        estimated_price: { required, maxValue: maxValue(999999999) },
        current_mortgage: { required, maxValue: maxValue(999999999) },
        settlement_date: { required },
        property_sold: { required },
        deposit_held_in_trust: { required },
        deposit_amount: { required, maxValue: maxValue(999999999) },
        type: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      onSave: "servicingCalc/onSave",
    }),
    textAlign() {
      if (this.$props.properties === 1) {
        return "text-al-start";
      } else {
        return "text-al-end";
      }
    },
  },
  methods: {
    ...mapActions({
      addOutgoingProperties: "servicingCalc/addOutgoingProperties",
    }),
    formatDate(date) {
      if (date) {
        return moment(date).format("DD/MM/YYYY");
      } else return;
      // return date;
    },
    async getFormData(value, fieldName) {
      if (value == "Invalid Date") return;

      this.formData[fieldName] = value;
      this.formData = { ...this.formData };

      if (fieldName === "postal_code" && this.formData.postal_code > 999) {
        try {
          const result = await _get(SERVER_URL_2 + `post_code?post_code=${value}`);
          if (result) {
            this.$emit("validateOutgoingProp", this.v$.$invalid);
            this.formData.zone = result.data.result.sp_category
              .replace("-", "_")
              .toLowerCase();
            this.formData.category = result.data.result.category;
            this.refreshKey++;
          }
        } catch (error) {
          this.$emit("validateOutgoingProp", true);
          this.formData.stamp_duty = 0;
          this.refreshKey++;
        }
      } else {
        this.$emit("validateOutgoingProp", this.v$.$invalid);
      }

      this.addOutgoingProperties({
        value,
        fieldName,
        id: this.$props.id,
        zone: this.formData.zone,
        category: this.formData.category,
        type_used: this.formData.type,
        outstanding_loan_amount: this.formData.current_mortgage,
      });
      // this.$emit("validateOutgoingProp", this.v$.$invalid)
    },
  },
  created() {
    this.minDate = new Date();
    this.minYear = new Date().getFullYear();
    this.maxYear = this.minYear + 50;

    this.formData = this.$props.fieldValues;
    this.$emit("validateOutgoingProp", this.v$.$invalid);
    this.refreshKey2++;
    this.refreshKey++;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

.text-al-end {
  text-align: end;
}

.text-al-start {
  text-align: start;
}

:deep .dropdown .p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
  line-height: 15px;
}

:deep .p-dropdown .p-dropdown-label {
  line-height: 15px;
}

.label {
  padding-right: 0;
  line-height: 19px !important;
  font-size: 14px;
  font-family: Gordita-medium;
  color: $secondary-color;
}

:deep .p-col-6 {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
