import Vue from 'vue';
// import store from '../../store/store'
import { ROUTES, TOKEN } from '../../../constants';
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

/**
 * Authentication Redirect
 */
const authRedirect = async () => {
    if (Vue.$cookies.get(TOKEN)) {
        return ROUTES.DASHBOARD
    }
    return ROUTES.SIGNIN
}

/**
 * params {Object} to
 * params {Object} from
 * params {callback} next
 */
const authGuard = async (to, from, next) => {
    // Change Document Title if Route has Meta Title
    // Get Nearest Available Route Title
    // const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // If Route with a Meta Title is found then Set the Document Title to that value
    // if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Get Authorize Meta
    // const { authorize } = to.meta;

    // Get Current User Token
    const currentToken = cookies.get(TOKEN);

    // Get Current user Role
    // const userRole = store.state.admin.authentication.role;

    // Get Current user Role
    // const userPermissions = store.state.admin.authentication.permissions;

    /**
     * If the Session Token exists, and the Route doesn't startsWith '/auth',
     * then the permisison to the Route is authorized.
     */
    if (currentToken) {
        /** 
         * Check if To Route Path startsWith '/auth'.
         * If Yes then redirect to default Landing Route.
        */
        if (to.path === '/' || to.path.startsWith(ROUTES.AUTH)) {
            return next({ path: await authRedirect() });
        } else {
            /**
             * Check If the Route has Authorize Meta.
             * If Yes then check if the Current User Role has permission to access
             * the Requested Route. If Not then redirect to deafult Landing Page.
             */
            // if (authorize.length && !authorize.includes(userRole)) {
            //     return next({ path: await authRedirect() });
            // }

            // Else Grant Permission to acess the Route
            return next();
        }
    }

    /**
     * If there is no Session Token and
     * the Request Route is a Authorized Route then
     * redirect to Signin Page with return Url query with Requested Route.
     */
    if (
        !to.path.startsWith(ROUTES.AUTH)
    ) {
        return next({
            path: ROUTES.SIGNIN,
            // query: {
            //     url: to.path
            // }
        });
    }
    /**
     * If the Requested Route is not Found then
     * redirect to Page not Found page.
     */
    next();
}

export {
    authGuard
}