<template>
  <div class="datepicker-layout flex align-items-center">
    <i class="pi pi-calendar ml-2" />
    <Calendar
      v-model="editValue"
      :numberOfMonths="numberOfMonths"
      :placeholder="placeholder"
      dateFormat="dd/mm/yy"
      :minDate="minDate"
      :manualInput="false"
      :monthNavigator="true"
      :yearNavigator="true"
      :yearRange="`${minYear}:${maxYear}`"
      :style="{
        height: `${height}`,
        width: `${width}`,
        border: `1px solid ${color} !important`,
        color: `${color}`,
      }"
      :key="key"
    >
    </Calendar>
  </div>
</template>

<script>
import Calendar from "primevue/calendar";
import moment from "moment";

export default {
  name: "Datefilter",
  components: {
    Calendar,
  },
  props: [
    "numberOfMonths",
    "placeholder",
    "height",
    "width",
    "border",
    "color",
    "required",
    "label",
    "minDate",
    "value",
    "minYear",
    "key",
  ],
  data() {
    return {
      editValue: "",
      displayValue: "",
      maxDate: null,
      isOpen: true,
      menu: false,
      maxYear: null,
    };
  },
  methods: {
    onClear() {
      this.value = "";
    },
  },
  watch: {
    editValue() {
      let dateValue = moment(this.editValue).format("YYYY-MM-DD");

      dateValue !== "Invalid date" &&
        this.$emit("change", new Date(dateValue).toISOString());
      // this.$emit("change", moment(this.editValue).format("DD/MM/YYYY"));
    },
  },
  mounted() {
    this.editValue = this.value;
    this.maxYear = new Date().getFullYear();
    this.maxYear = this.maxYear + 50;
  },
};
</script>

<style lang="scss">
@import "@/styles/global.scss";

.p-datepicker table td {
  padding: 0 !important;
}
.p-datepicker table td > span {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 0 !important;
  font-size: 14px !important;
}
.p-datepicker table th > span {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 4px !important;
  font-size: 14px !important;
}

.datepicker-layout .p-inputtext {
  padding-left: 0.5rem !important;
}

.p-datepicker table td > span.p-highlight {
  background: #fdf0e8;
}
.p-datepicker .p-datepicker-header {
  border-bottom: unset !important;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: unset !important;
}
.p-datepicker {
  padding: 1rem 2rem !important;
}
.datepicker-layout {
  border: 1px solid rgba(100, 97, 170, 0.3);
  border-radius: 4px !important;
  .p-inputtext {
    border: unset !important;
    font-family: $font-family !important;
  }
}
.p-calendar {
  width: 100% !important;
}
.p-monthpicker-month {
  border: unset !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  border: unset !important;
  background: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  border: unset !important;
  background: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  box-shadow: unset !important;
}

.p-datepicker table td.p-datepicker-today > span {
  background: #a4bfe0 !important;
}
</style>
