<template>
  <div class="mr-top card-box">
    <Panel :toggleable="true" :collapsed="collapsed">
      <template #icons>
        <div @click="toggle" class="icon-wrapper">
          <span v-if="collapsed" class="icon pi pi-angle-down"></span>
          <span v-else class="icon pi pi-angle-up"></span>
        </div>
      </template>
      <template #header>
        <div @click="toggle" class="sub-title full-width pointer">
          Forcasted Loan Schedule
        </div>
      </template>

      <Spinner v-if="isloading" class="flex justify-content-between mt-3" />

      <DataTable
        id="forcastedLoans"
        v-else
        class="mt-2"
        :rows="5"
        dataKey="id"
        :value="statements"
      >
        <template #empty> Loan not settled </template>

        <Column field="period" header="Period"> </Column>
        <Column field="from" header="From">
          <template #body="slotProps">
            {{ slotProps.data.from_date ? formatDate(slotProps.data.from_date) : "-" }}
          </template>
        </Column>
        <Column field="to" header="To">
          <template #body="slotProps">
            {{ slotProps.data.to_date ? formatDate(slotProps.data.to_date) : "-" }}
          </template>
        </Column>
        <Column field="opening_balance" header="Opening Balance">
          <template #body="slotProps">
            {{
              slotProps.data.opening_balance
                ? formatAmount(slotProps.data.opening_balance)
                : "-"
            }}
          </template>
        </Column>
        <Column field="loan_advance" header="Loan Advance">
          <template #body="slotProps">
            {{
              slotProps.data.loan_advance
                ? formatAmount(slotProps.data.loan_advance)
                : "-"
            }}
          </template>
        </Column>
        <Column field="interest_cap" header="Interest Capitalised">
          <template #body="slotProps">
            {{ roundUpValue(slotProps.data.interest_cap) }}
          </template>
        </Column>
        <Column field="loan_repayment" header="Loan Repayment">
          <template #body="slotProps">
            {{
              slotProps.data.loan_repayment
                ? formatAmount(slotProps.data.loan_repayment)
                : "-"
            }}
          </template>
        </Column>
        <Column field="closing_bal" header="Closing Balance">
          <template #body="slotProps">
            {{
              slotProps.data.closing_bal ? formatAmount(slotProps.data.closing_bal) : "-"
            }}
          </template>
        </Column>
      </DataTable>
    </Panel>
  </div>
</template>

<script>
import Panel from "primevue/panel";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import moment from "moment";
import { APP_URL } from "../../../../../../constants";
import { _get } from "../../../../../services/crudService";

export default {
  components: { Panel, DataTable, Column },
  props: ["key"],
  data() {
    return {
      isloading: false,
      collapsed: false,
      statements: [],
    };
  },
  watch: {
    key() {
      this.getList();
    },
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
    roundUpValue(value) {
      if (value) {
        return value.toFixed(2);
      } else {
        return "-";
      }
    },
    formatAmount(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      return formatter.format(value);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async getList() {
      try {
        this.isloading = true;

        const response = await _get(
          APP_URL + `forcasted-loan?app_id=${this.$route.params.id}`
        );

        if (response) {
          this.isloading = false;
          this.statements = response.data.error ? [] : response.data;
          // response.data.map((list, idx) => {
          //   this.statements.push({ list_no: idx + 1, ...list })
          // })
        }
      } catch (error) {
        this.isloading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-panel .p-panel-content {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

::v-deep .p-panel-header {
  background: unset !important;
  border: none !important;
  border-bottom: 1px solid #02003826 !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
  border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
  display: none !important;
}

::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
  padding: 1rem 1rem 0.5rem 1rem !important;
}

::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  //   border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
.color-highlight {
  color: $primary-color !important;
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}

.sub-title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgba(2, 0, 56, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.card-box {
  background: #ffffff;
  padding: 20px;
  border: 1px solid rgba(2, 0, 56, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
}

.mr-top {
  margin-top: 2.5rem;
}
</style>
