<template>
  <div class="pl-4 pr-4 pb-4">
    <Card class="std-padding-x box-shadow">
      <template #title>
        <p class="title">Integrations</p>
      </template>

      <template #content>
        <Spinner v-if="isloading" class="flex justify-content-between" />
        <div v-else>
          <div class="content-wrapper">
            <div class="content">
              <div class="form-inputs mt-4">
                <div class="p-grid align-items-center header">
                  <p class="col-3"></p>
                  <p class="col-2 label"></p>
                  <p class="col-2 label">Last Run</p>
                  <p class="col-1 label"></p>
                  <div class="col-4"></div>
                </div>
                <InputRow
                  label="Credit Check"
                  icon="NoRelevant"
                  :disable="creditCheckbutton"
                  :lastRun="creditCheckLastRun"
                  :btnType="creditCheckbuttonType"
                  :placeholder="creditCheckPlaceholder"
                  :border="creditBorder"
                  :errorMsg="creditError"
                  :buttonType="creditRunBtn"
                  @runIntegration="runIntegration"
                />

                <InputRow
                  label="ID Matrix"
                  :removeIcon="true"
                  icon="Completed"
                  :disable="matrixIDbutton"
                  :lastRun="matrixIDLastRun"
                  :btnType="matrixIDbuttonType"
                  :placeholder="matrixIDPlaceholder"
                  :border="matrixBorder"
                  :errorMsg="matrixError"
                  :buttonType="idMatrixRunBtn"
                  @runIntegration="runIntegration"
                />

                <InputRow
                  label="Pricefinder"
                  icon="Completed"
                  :disable="pricefinderbutton"
                  :lastRun="pricefinderLastRun"
                  :btnType="pricefinderType"
                  :placeholder="pricefinderPlaceholder"
                  :border="pricefinderBorder"
                  :errorMsg="pricefinderError"
                  :buttonType="pricefinderRunBtn"
                  @runIntegration="runIntegration"
                />

                <InputRow
                  label="Fetch Title ID"
                  :removeIcon="true"
                  icon="NoRelevant"
                  :disable="titleSearchbutton"
                  :lastRun="titleCheckLastRun"
                  :btnType="tileCheckType"
                  :border="titleCheckBorder"
                  :placeholder="titleCheckPlaceholder"
                  :errorMsg="titleCheckError"
                  :buttonType="titleChecRunBtn"
                  @runIntegration="runIntegration"
                />

                <InputRow
                  label="Fetch Title"
                  icon="NoRelevant"
                  :disable="titleEnquirybutton"
                  :lastRun="titleEnquiryLastRun"
                  :btnType="titleEnquiryType"
                  :border="titleEnquiryBorder"
                  :placeholder="titleEnquiryPlaceholder"
                  :errorMsg="titleEnquiryError"
                  :buttonType="titleEnquiryRunBtn"
                  @runIntegration="runIntegration"
                />

                <InputRow
                  label="Aply Id"
                  icon="NoRelevant"
                  :disable="aplyIDbutton"
                  :lastRun="aplyIDLastRun"
                  :btnType="aplyIDType"
                  :border="aplyIDBorder"
                  :placeholder="aplyIDPlaceholder"
                  :errorMsg="aplyIDError"
                  :buttonType="aplyIDRunBtn"
                  @runIntegration="runIntegration"
                />
              </div>
            </div>
            <span v-for="docs of integrationDocuments" :key="docs.id">
              <span
                class="document-container"
                @click="getDocument(docs.binary, docs.fileName)"
                >{{ docs.fileName }}</span
              >
            </span>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "primevue/card";
import InputRow from "./InputRow.vue";
import { _get } from "../../../../../services/crudService";
import { APP_URL } from "../../../../../../constants";
import moment from "moment";

export default {
  components: { Card, InputRow },
  data() {
    return {
      refreshKey: 10,
      creditCheckLastRun: "",
      matrixIDLastRun: "",
      pricefinderLastRun: "",
      titleCheckLastRun: "",
      titleEnquiryLastRun: "",
      creditCheckbuttonType: "",
      creditCheckPlaceholder: "",
      creditCheckbutton: false,
      creditBorder: "",
      matrixIDbutton: false,
      matrixIDbuttonType: "",
      matrixIDPlaceholder: "",
      matrixBorder: "",
      pricefinderType: "",
      pricefinderPlaceholder: "",
      pricefinderbutton: false,
      pricefinderBorder: "",
      integrationArray: [],
      creditRunBtn: "",
      idMatrixRunBtn: "",
      pricefinderRunBtn: "",
      titleChecRunBtn: "",
      titleEnquiryRunBtn: "",
      titleSearchbutton: false,
      tileCheckType: "",
      titleCheckBorder: "",
      titleCheckPlaceholder: "",
      titleEnquirybutton: false,
      titleEnquiryBorder: "",
      titleEnquiryPlaceholder: "",
      titleEnquiryType: "",
      isloading: false,
      creditError: "",
      matrixError: "",
      pricefinderError: "",
      titleCheckError: "",
      titleEnquiryError: "",
      integrationDocuments: [],
      aplyIDbutton: false,
      aplyIDLastRun: "",
      aplyIDType: "",
      aplyIDBorder: "",
      aplyIDPlaceholder: "",
      aplyIDError: "",
      aplyIDRunBtn: "",
    };
  },
  created() {
    this.getIntegration();
  },
  methods: {
    async getIntegration() {
      try {
        this.isloading = true;
        const response = await _get(
          APP_URL + `get-integration-status?id=${this.$route.params.id}`
        );

        if (response) {
          this.isloading = false;
          this.integrationArray = response.data;
          this.getIntegrationDetails(this.integrationArray);
        }
      } catch (error) {
        this.isloading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    getIntegrationDetails(data) {
      if (data.result.credit_check_latest_run) {
        this.creditCheckLastRun = moment(
          data.result.credit_check_latest_run
        ).format("DD/MM/YYYY - hh:mm A");
      }
      if (data.result.matrix_id_latest_run) {
        this.matrixIDLastRun = moment(data.result.matrix_id_latest_run).format(
          "DD/MM/YYYY - hh:mm A"
        );
      }
      if (data.result.pricefinder_latest_run) {
        this.pricefinderLastRun = moment(
          data.result.pricefinder_latest_run
        ).format("DD/MM/YYYY - hh:mm A");
      }
      if (data.result.title_search_latest_run) {
        this.titleCheckLastRun = moment(
          data.result.title_search_latest_run
        ).format("DD/MM/YYYY - hh:mm A");
      }
      if (data.result.title_check_latest_run) {
        this.titleEnquiryLastRun = moment(
          data.result.title_check_latest_run
        ).format("DD/MM/YYYY - hh:mm A");
      }
      if (data.result.aplyid_latest_run) {
        this.aplyIDLastRun = moment(data.result.aplyid_latest_run).format(
          "DD/MM/YYYY - hh:mm A"
        );
      }

      if (data.result.credit_check_result === "pass") {
        this.creditCheckbutton = false;
        this.creditCheckbuttonType = "primary";
        this.creditBorder = "green-border";
        this.creditCheckPlaceholder = "Complete";
        this.creditRunBtn = "disable";
      } else if (data.result.credit_check_result === "fail") {
        this.creditCheckbutton = false;
        this.creditCheckbuttonType = "disable";
        this.creditCheckPlaceholder = "Failed";
        this.creditBorder = "red-border";
        this.creditRunBtn = "primary";
      } else {
        this.creditCheckbutton = true;
        this.creditCheckbuttonType = "disable";
        this.creditCheckPlaceholder = "N/A";
        this.creditBorder = "";
        this.creditRunBtn = "primary";
      }

      if (data.result.matrix_id_result === "pass") {
        this.matrixIDbutton = false;
        this.matrixIDbuttonType = "primary";
        this.matrixBorder = "green-border";
        this.idMatrixRunBtn = "disable";
        this.matrixIDPlaceholder = "Complete";
      } else if (data.result.matrix_id_result === "fail") {
        this.matrixIDbutton = false;
        this.matrixIDbuttonType = "disable";
        this.matrixIDPlaceholder = "Failed";
        this.matrixBorder = "red-border";
        this.idMatrixRunBtn = "primary";
      } else {
        this.matrixIDbutton = true;
        this.matrixIDbuttonType = "disable";
        this.matrixIDPlaceholder = "N/A";
        this.matrixBorder = "";
        this.idMatrixRunBtn = "primary";
      }

      if (data.result.pricefinder_result === "pass") {
        this.pricefinderbutton = false;
        this.pricefinderType = "primary";
        this.pricefinderBorder = "green-border";
        this.pricefinderRunBtn = "disable";
        this.pricefinderPlaceholder = "Complete";
      } else if (data.result.pricefinder_result === "fail") {
        this.pricefinderbutton = false;
        this.pricefinderType = "disable";
        this.pricefinderPlaceholder = "Failed";
        this.pricefinderBorder = "red-border";
        this.pricefinderRunBtn = "primary";
      } else {
        this.pricefinderbutton = true;
        this.pricefinderType = "disable";
        this.pricefinderPlaceholder = "N/A";
        this.pricefinderBorder = "";
        this.pricefinderRunBtn = "primary";
      }

      if (data.result.title_check_result === "pass") {
        this.titleSearchbutton = false;
        this.tileCheckType = "primary";
        this.titleCheckBorder = "green-border";
        this.titleChecRunBtn = "disable";
        this.titleCheckPlaceholder = "Complete";
      } else if (data.result.title_search_result === "fail") {
        this.titleSearchbutton = false;
        this.tileCheckType = "disable";
        this.titleCheckPlaceholder = "Failed";
        this.titleCheckBorder = "red-border";
        this.titleChecRunBtn = "primary";
      } else {
        this.titleSearchbutton = true;
        this.tileCheckType = "disable";
        this.titleCheckPlaceholder = "N/A";
        this.titleChecRunBtn = "primary";
        this.titleCheckBorder = "";
      }
      if (data.result.title_search_result === "pass") {
        this.titleSearchbutton = false;
        this.titleEnquiryType = "primary";
        this.titleEnquiryBorder = "green-border";
        this.titleEnquiryRunBtn = "disable";
        this.titleEnquiryPlaceholder = "Complete";
      } else if (data.result.title_check_result === "fail") {
        this.titleSearchbutton = false;
        this.titleEnquiryType = "disable";
        this.titleEnquiryPlaceholder = "Failed";
        this.titleEnquiryBorder = "red-border";
        this.titleEnquiryRunBtn = "primary";
      } else {
        this.titleSearchbutton = true;
        this.titleEnquiryType = "disable";
        this.titleEnquiryPlaceholder = "N/A";
        this.titleEnquiryBorder = "";
        this.titleEnquiryRunBtn = "primary";
      }

      // if (data.result.decision_seeker_result === "pass") {
      //   this.titleEnquirybutton = false;
      //   this.titleEnquiryRunBtn = "disable";
      //   this.titleEnquiryBorder = "green-border";
      //   this.titleEnquiryPlaceholder = "Complete";
      //   this.titleEnquiryType = "primary";
      // } else if (data.result.decision_seeker_result === "fail") {
      //   this.titleEnquirybutton = false;
      //   this.titleEnquiryBorder = "red-border";
      //   this.titleEnquiryPlaceholder = "Failed";
      //   this.titleEnquiryType = "disable";
      //   this.titleEnquiryRunBtn = "primary";
      // } else {
      //   this.titleEnquirybutton = true;
      //   this.titleEnquiryBorder = "";
      //   this.titleEnquiryPlaceholder = "N/A";
      //   this.titleEnquiryType = "disable";
      //   this.titleEnquiryRunBtn = "primary";
      // }

      if (data.result.aplyid_result === "pass") {
        this.aplyIDbutton = false;
        this.aplyIDRunBtn = "disable";
        this.aplyIDBorder = "green-border";
        this.aplyIDPlaceholder = "Complete";
        this.aplyIDType = "primary";
      } else if (data.result.aplyid_result === "fail") {
        this.aplyIDbutton = false;
        this.aplyIDBorder = "red-border";
        this.aplyIDPlaceholder = "Failed";
        this.aplyIDType = "disable";
        this.aplyIDRunBtn = "primary";
      } else {
        this.aplyIDbutton = true;
        this.aplyIDBorder = "";
        this.aplyIDPlaceholder = "N/A";
        this.aplyIDType = "disable";
        this.aplyIDRunBtn = "primary";
      }
    },
    async runIntegration(event) {
      if (event === "Credit Check") {
        try {
          const response = await _get(
            APP_URL + `run-credit-check?id=${this.$route.params.id}`
          );

          if (response) {
            this.isloading = false;
            this.getIntegration();
            this.creditError = response.data.message;
          }
        } catch (error) {
          this.isloading = false;
          this.getIntegration();
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      } else if (event === "ID Matrix") {
        try {
          const response = await _get(
            APP_URL + `run-id-matrix?id=${this.$route.params.id}`
          );

          if (response) {
            this.isloading = false;
            this.getIntegration();
            this.matrixError = response.data.message;
          }
        } catch (error) {
          this.isloading = false;
          this.getIntegration();
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      } else if (event === "Pricefinder") {
        try {
          const response = await _get(
            APP_URL + `run-pricefinder?id=${this.$route.params.id}`
          );

          if (response) {
            this.isloading = false;
            this.getIntegration();
            this.pricefinderError = response.data.message;
          }
        } catch (error) {
          this.isloading = false;
          this.getIntegration();
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      } else if (event === "Fetch Title ID") {
        try {
          const response = await _get(
            APP_URL + `run-title-check?id=${this.$route.params.id}`
          );

          if (response) {
            this.isloading = false;
            this.getIntegration();
            this.titleCheckError = response.data.result;
          }
        } catch (error) {
          this.isloading = false;
          this.getIntegration();
          this.$emit("getIntgeration");
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      } else if (event === "Fetch Title") {
        try {
          const response = await _get(
            APP_URL + `get-title-enquiry-status?id=${this.$route.params.id}`
          );

          if (response) {
            this.isloading = false;
            let result = response.data.result;
            if (response.data.status !== "fail") {
              result.map((data, index) => {
                if (data.binary) {
                  result[index].fileName = data.add_1;
                }
              });
              this.integrationDocuments = result;
            }
            this.getIntegration();
          }
        } catch (error) {
          this.isloading = false;
          this.getIntegration();
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      } else if (event === "Aply Id") {
        try {
          const response = await _get(
            APP_URL + `send-aply-link?id=${this.$route.params.id}`
          );

          if (response) {
            this.isloading = false;
            this.getIntegration();
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: response.data.message,
              life: 3000,
            });
          }
        } catch (error) {
          this.isloading = false;
          this.getIntegration();
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
    },
    getDocument(event, file) {
      var a = document.createElement("a"); //Create <a>
      a.href = "data:application/pdf;base64," + event; //Image Base64 Goes here
      a.download = file; //File name Here
      a.click();
      // window.open(event);
      // let pdfWindow = window.open(event);
      // pdfWindow.document.write(
      //   `<iframe src="data:application/pdf;base64,${event}"  width='100%' height='100%'></iframe>`
      // );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.title {
  font-size: 20px;
  color: $secondary-color;
  font-family: Gordita-medium-;
}

.std-padding-x {
  padding: 20px;
}

.box-shadow {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.content-wrapper {
  border: 1px solid rgba(2, 0, 56, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1rem;
}

p {
  margin: 0;
}

.error-text,
.col-text {
  font-size: 14px;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.header {
  ::v-deep .col-2 {
    padding-bottom: 10px;
  }
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium-;
  margin: 0;
}
.document-container {
  color: $primary-color;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  margin-left: 60px;
}
</style>
