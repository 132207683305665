<template>
  <div>
    <div class="title p-d-flex justify-content-between">
      <p class="title-text">LVR</p>
    </div>
    <!-- <div v-if="isloading">loading</div> -->
    <div class="mt-2">
      <div class="flex align-items-center justify-content end disable-input">
        <p class="label p-col-6">Initial LVR</p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          :disable="true"
          width="100%"
          height="30px"
          placeholder="label"
          :value="formData.initial_lvr"
          id="lvr_initial_lvr"
        />
      </div>
      <div class="flex align-items-center justify-content end disable-input mt-1">
        <p class="label p-col-6">Peak Debt LVR</p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          :disable="true"
          width="100%"
          height="30px"
          :value="formData.peak_debt_LVR"
          placeholder="label"
          id="lvr_peak_debt_LVR"
        />
      </div>
      <div class="flex align-items-center justify-content end disable-input mt-1">
        <p class="label p-col-6">End Debt LVR</p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          :disable="true"
          width="100%"
          height="30px"
          :value="formData.end_debt_LVR"
          placeholder="label"
          id="lvr_end_debt_LVR"
        />
      </div>
      <div class="flex align-items-center justify-content end disable-input mt-1">
        <p class="label p-col-6">Initial LVR Within Range?</p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          :disable="true"
          width="100%"
          height="30px"
          :value="formData.initial_lvr_within_range"
          placeholder="label"
          id="lvr_initial_lvr_within_range"
        />
      </div>
      <div class="flex align-items-center justify-content end disable-input mt-1">
        <p class="label p-col-6">Outgoing LVR MAX</p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          :disable="true"
          width="100%"
          height="30px"
          :value="formData.op_lvr_max"
          placeholder="label"
          id="lvr_op_lvr_max"
        />
      </div>
      <div class="flex align-items-center justify-content end disable-input mt-1">
        <p class="label p-col-6">Incoming LVR max</p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          :disable="true"
          width="100%"
          height="30px"
          :value="formData.ip_lvr_max"
          placeholder="label"
          id="lvr_ip_lvr_max"
        />
      </div>
      <div class="flex align-items-center justify-content end disable-input mt-1">
        <p @click="calc" class="label p-col-6">WALVR</p>
        <NumberField
          :key="refreshKey"
          class="p-col-6"
          :disable="true"
          width="100%"
          height="30px"
          :value="formData.walvr"
          placeholder="label"
          id="lvr_walvr"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["responseValue", "isloading"],
  data() {
    return {
      refreshKey: 10,

      formData: {
        end_debt: 0,
        end_debt_LVR: 0,
        final_score: 0,
        ip_lvr_max: 0,
        op_lvr_max: 0,
        initial_lvr: 0,
        initial_lvr_within_range: 0,
        loan_amount: 0,
        peak_debt: 0,
        peak_debt_LVR: 0,
        qouted_setup_fee: 0,
        setup_fee: 0,
        walvr: 0,
      },
    };
  },
  watch: {
    calculatedValues(result) {
      this.refreshKey++;

      for (const [key, value] of Object.entries(result)) {
        if (typeof value === "number") {
          this.formData[key] = value.toFixed(2);
        } else {
          this.formData[key] = value;
        }
        if (value === -1) {
          this.formData[key] = "-";
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      calculatedValues: "servicingCalc/calculatedValues",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.title {
  border-bottom: 1px solid rgba(2, 0, 56, 0.15);
  padding-bottom: 10px;

  .title-text {
    font-family: Gordita-bold;
    color: $secondary-color;
    font-size: 20px;
  }
}

p {
  margin: 0;
}

.label {
  padding-right: 0;
  line-height: 19px !important;
  font-size: 14px;
  font-family: Gordita-medium;
  color: $secondary-color;
  text-align: end;
}

.text-highlight {
  font-family: Gordita-bold !important;
}

:deep .p-col-6 {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
