<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
	<div class="mr-top">
		<Panel :toggleable="true" :collapsed="collapsed">
			<template #icons>
				<div @click="toggle" class="icon-wrapper">
					<span v-if="collapsed" class="icon pi pi-angle-down"></span>
					<span v-else class="icon pi pi-angle-up"></span>
				</div>
			</template>
			<template #header>
				<div @click="toggle" class="sub-title full-width pointer">End Debt</div>
			</template>
			<div :key="refreshKey" class="form-inputs mr-top-10px">
				<div class="p-grid align-items-start">
					<!-- Col-1 -->

					<div class="p-col-8">
						<!-- Row-1 -->
						<div class="p-grid align-items-center mr-b-5px">
							<div class="p-col-4 disable-input">
								<p class="label">Outgoing Max LVR %</p>
								<TextField
									:value="
										calculatedFields.op_lvr_max
											? calculatedFields.op_lvr_max.toFixed(2)
											: '-'
									"
									:disable="true"
									width="100%"
									id="OutgoingMaxLVR%"
								/>
							</div>

							<div class="p-col-4 disable-input">
								<p class="label">Incoming Max LVR %</p>
								<TextField
									:value="
										calculatedFields.ip_lvr_max
											? calculatedFields.ip_lvr_max.toFixed(2)
											: '-'
									"
									:disable="true"
									width="100%"
									id="IncomingMaxLVR%"
								/>
							</div>

							<div class="p-col-4 disable-input">
								<p class="label">WALVR %</p>
								<TextField
									:value="
										calculatedFields.walvr &&
										calculatedFields.walvr > 0
											? calculatedFields.walvr.toFixed(2)
											: '-'
									"
									:disable="true"
									width="100%"
									id="walvr"
								/>
							</div>
						</div>

						<!-- Row-2 -->

						<div class="p-grid align-items-center mr-b-5px">
							<div class="p-col-6 disable-input">
								<p class="label">Interest (Capitalising Loan) %</p>
								<TextField
									:value="calculatedFields.cap_loan_rate"
									:disable="true"
									width="100%"
									id="InterestCapitalisingLoan"
								/>
							</div>

							<div class="p-col-6 disable-input">
								<p class="label">Peak Debt</p>
								<NumberField
									mode="decimal"
									:format="true"
									prefix="$"
									:value="
										calculatedFields.peak_debt ||
										calculatedFields.peak_debt
									"
									:disable="true"
									width="100%"
									id="peakDebt"
								/>
							</div>
						</div>
					</div>

					<!-- Col-2 -->

					<div class="p-col-2">
						<div class="mr-b-5px mt-2 disable-input">
							<p class="label">Initial LVR %</p>
							<TextField
								:value="
									calculatedFields.initial_lvr
										? (calculatedFields.initial_lvr * 100).toFixed(3)
										: 0
								"
								:disable="true"
								width="100%"
								id="initialLvr"
							/>
						</div>

						<div class="mt-2 disable-input padding-y">
							<p class="label">Peak Debt LVR %</p>
							<TextField
								:value="
									calculatedFields.peak_debt_LVR
										? Number(calculatedFields.peak_debt_LVR).toFixed(
												3
										  )
										: 0
								"
								:disable="true"
								width="100%"
								id="peak_debt_LVR"
							/>
						</div>

						<div class="mt-2 disable-input padding-y">
							<p class="label">End Debt LVR %</p>
							<TextField
								:value="
									calculatedFields.end_debt_LVR
										? Number(calculatedFields.end_debt_LVR).toFixed(3)
										: 0
								"
								:disable="true"
								width="100%"
								id="end_debt_LVR"
							/>
						</div>
					</div>

					<!-- Col-3 -->

					<div class="p-col-2 highlight-fields">
						<div class="mr-b-5px mt-2 disable-input">
							<p class="label">Shaded Value</p>
							<NumberField
								:value="calculatedFields.shaded_value"
								:disable="true"
								width="100%"
								:format="true"
								mode="decimal"
								id="shaded_value"
							/>
						</div>

						<div class="padding-y mt-2 disable-input">
							<p class="label">Selling Costs</p>
							<NumberField
								:value="
									calculatedFields.sale_cost
										? calculatedFields.sale_cost.toFixed(2)
										: 0
								"
								:disable="true"
								width="100%"
								mode="decimal"
								:format="true"
								id="selligCosts"
							/>
						</div>

						<div class="padding-y mt-2 disable-input">
							<p class="label">End Debt</p>
							<NumberField
								mode="decimal"
								:format="true"
								prefix="$"
								:value="
									calculatedFields.end_debt || calculatedFields.end_debt
								"
								:disable="true"
								width="100%"
								id="endDebt"
							/>
						</div>
					</div>
				</div>
			</div>
		</Panel>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Panel from "primevue/panel";

export default {
	components: { Panel },
	data() {
		return {
			collapsed: true,
			calculatedFields: {
				op_lvr_max: 0,
				ip_lvr_max: 0,
				walvr: 0,
				cap_loan_rate: 0,
				peak_debt: 0,
				initial_lvr: 0,
				peak_debt_LVR: 0,
				end_debt_LVR: 0,
				shaded_value: 0,
				sale_cost: 0,
				end_debt: 0,
			},
			refreshKey: 0,
		};
	},
	computed: {
		...mapGetters({ selectedApplication: "applications/selectedApplication" }),
	},
	methods: {
		...mapActions({ setEndDebtData: "summary/setEndDebtData" }),
		toggle() {
			this.collapsed = !this.collapsed;
		},
		setData() {
			if (this.selectedApplication.application_id) {
				const { calculated_fields } = this.selectedApplication;
				Object.keys(calculated_fields).map((key) => {
					if (key in this.calculatedFields) {
						this.calculatedFields[key] = calculated_fields[key];
					}
				});
				//All fields
				this.calculatedFields = {
					...this.calculatedFields,
				};
				this.calculatedFields.op_lvr_max = this.calculatedFields.op_lvr_max
					? Number(this.calculatedFields.op_lvr_max) * 100
					: 0;
				this.calculatedFields.ip_lvr_max = this.calculatedFields.ip_lvr_max
					? Number(this.calculatedFields.ip_lvr_max) * 100
					: 0;
				this.calculatedFields.walvr = this.calculatedFields.walvr
					? Number(this.calculatedFields.walvr) * 100
					: 0;
				this.calculatedFields.cap_loan_rate = this.calculatedFields.cap_loan_rate
					? Number(this.calculatedFields.cap_loan_rate) * 100
					: 0;
				this.calculatedFields.initial_lvr = this.calculatedFields.initial_lvr
					? Number(this.calculatedFields.initial_lvr)
					: 0;
				this.calculatedFields.peak_debt_LVR = this.calculatedFields.peak_debt_LVR
					? Number(this.calculatedFields.peak_debt_LVR) * 100
					: 0;
				this.calculatedFields.end_debt_LVR = this.calculatedFields.end_debt_LVR
					? Number(this.calculatedFields.end_debt_LVR) * 100
					: 0;
				this.setEndDebtData(this.calculatedFields);
			}
		},
	},
	created() {
		this.setData();
	},
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-panel .p-panel-content {
	padding-left: 0;
	padding-right: 0;
}

::v-deep .p-panel-header {
	background: unset !important;
	border: none !important;
	border-bottom: 1px solid #02003826 !important;
	padding: 0 !important;
	padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
	border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
	display: none !important;
}

.sub-title {
	font-size: 1.25rem;
	color: $secondary-color;
	font-family: Gordita-bold;
}

.padding-y {
	padding: 0.5rem 0 !important;
}

.icon-wrapper {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 2px solid rgba(2, 0, 56, 0.4);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

::v-deep .p-col-4 disable-input,
.p-col-2 {
	padding-bottom: 0;
	padding-top: 0;
}

.label {
	color: $secondary-color;
	font-size: 0.875rem;
	font-family: Gordita-medium;
	margin: 0;
}

.highlight-fields {
	.label {
		font-family: Gordita-bold !important;
	}
}

.mr-b-5px {
	margin-bottom: 5px;
}

.mr-top {
	margin-top: 2.5rem;
}

.mr-top-10px {
	margin-top: 10px;
}

.flex-gap {
	gap: 0.625rem;
}
</style>
