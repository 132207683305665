<template>
  <div>
    <ApplicationHeader />
    <router-view class="mt-5"></router-view>
  </div>
</template>

<script>
import ApplicationHeader from "./ApplicationHeader.vue"
export default {
  components: {
    ApplicationHeader,
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
</style>
