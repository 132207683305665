// import Vue from 'vue';
import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

import PrimeVue from "primevue/config"
import "primeflex/primeflex.css"
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"
import "primevue/resources/themes/saga-blue/theme.css"
import VueCookies from "vue3-cookies"
import ToastService from "primevue/toastservice"
import vClickOutside from "click-outside-vue3"
import ConfirmationService from 'primevue/confirmationservice';

// Common componets
import TextField from "./components/common/TextField.vue"
import Buttons from "./components/common/Button.vue"
import Checkbox from "./components/common/Checkbox.vue"
import CheckboxList from "./components/common/CheckboxList.vue"
import SummaryDatePicker from "./components/common/SummaryDatePicker.vue"
import DatePicker from "./components/common/DatePIcker.vue"
import DatePicker2 from "./components/common/DatePicker2.vue"
import Dropdown from "./components/common/Dropdown.vue"
import Toolbar from "./components/common/Toolbar.vue"
import Sidebar from "./components/common/Sidebar.vue"
import InputWithIcon from "./components/common/InputFieldWithIcon.vue"
import TextArea from "./components/common/TextArea.vue"
import Editor from "./components/common/Editor.vue"
import ToggleSwitch from "./components/common/ToggleSwitch.vue"
import Spinner from "./components/common/Spinner.vue"
import SingleCheckBox from "./components/common/SingleCheckbox.vue"
import NumberField from "./components/common/NumberField.vue"
import TotalField from "./components/common/TotalField.vue"

const app = createApp(App)

// In vue 3 production tip is false in default
// Vue.config.productionTip = false;

app.use(PrimeVue)
app.use(store)
app.use(VueCookies)
app.use(ToastService)
app.use(vClickOutside)
app.use(ConfirmationService);

// Common components
app.component("TextField", TextField)
app.component("Button", Buttons)
app.component("Dropdown", Dropdown)
app.component("Toolbar", Toolbar)
app.component("Checkbox", Checkbox)
app.component("CheckboxList", CheckboxList)
app.component("DatePicker", DatePicker)
app.component("DatePicker2", DatePicker2)
app.component("SummaryDatePicker", SummaryDatePicker)
app.component("Sidebar", Sidebar)
app.component("InputWithIcon", InputWithIcon)
app.component("TextArea", TextArea)
app.component("Editor", Editor)
app.component("ToggleSwitch", ToggleSwitch)
app.component("Spinner", Spinner)
app.component("SingleCheckbox", SingleCheckBox)
app.component("NumberField", NumberField)
app.component("TotalField", TotalField),

    app.use(router).mount("#app")
