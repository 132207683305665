<template>
  <div class="pl-4 pr-4 pb-4">
    <Card class="p-3">
      <template #title>
        <div class="flex align-items-center justify-content-between">
          <span class="module-sub-title">Notes</span>
          <div
            class="flex align-items-center ml-3 cursor-pointer total-task"
            @click="addNewNote"
          >
            <span class="mr-2">+</span> New note
          </div>
        </div></template
      >
      <template #content>
        <Spinner v-if="isloading" class="flex justify-content-between" />
        <DataTable
          v-else
          class="border-box"
          :rows="10"
          dataKey="id"
          :value="statements.result"
          :rowsPerPageOptions="[10, 20, 30]"
        >
          <!-- <Column field="stage" header="Note type"></Column> -->
          <template #empty> No data found. </template>

          <Column field="body" header="Note">
            <template #body="slotProps">
              {{ slotProps.data.body ? removeTags(slotProps.data.body) : "-" }}
            </template>
          </Column>
          <Column field="createdBy" header="Created by">
            <template #body="slotProps">
              {{ slotProps.data.createdBy || "-" }}
            </template>
          </Column>
          <Column field="createdBy" header="Date completed">
            <template #body="slotProps">
              {{ slotProps.data.createdAt ? formatDate(slotProps.data.createdAt) : "-" }}
            </template>
          </Column>
          <Column field="body" header=" " :expander="true">
            <template #body="slotProps">
              <div
                class="color-highlight flex align-items-center pointer"
                @click="editNotes(slotProps.data.Id, slotProps.data.body)"
                style="text-align: end !important; width: 100%"
              >
                <div style="width: 100%">Edit</div>
              </div>
            </template>
          </Column>
        </DataTable>
        <Paginator
          class="mt-2"
          :rows="10"
          :totalRecords="statements && statements.totalCount"
          :rowsPerPageOptions="[10, 20, 30]"
          template="RowsPerPageDropdown  CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} of {totalRecords} notes"
          @page="onChangePage($event)"
        ></Paginator>
      </template>
    </Card>
    <NewNote
      v-if="showAddNote"
      @save="refreshKey++"
      :show="showAddNote"
      @close="closeNote"
      :notesId="notesId"
      :notesData="notesData"
    />
  </div>
</template>

<script>
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import NewNote from "./AddNote.vue";
import Paginator from "primevue/paginator";
import { _get } from "../../../../../services/crudService";
import { APP_URL } from "../../../../../../constants";
import moment from "moment";

export default {
  name: "Notes",
  components: {
    Card,
    Paginator,
    DataTable,
    Column,
    NewNote,
  },
  data() {
    return {
      showAddNote: false,
      isloading: false,
      notesId: "",
      refreshKey: 10,
      notesData: "",
      statements: [],
    };
  },

  watch: {
    refreshKey() {
      this.getNotes(1, 10);
    },
  },
  methods: {
    editNotes(id, data) {
      this.notesId = id;
      this.notesData = data;
      this.showAddNote = true;
    },
    closeNote() {
      this.showAddNote = false;
      this.notesId = "";
      this.notesData = "";
    },
    addNewNote() {
      this.showAddNote = true;
    },
    formatDate(date) {
      date = new moment(date).add(600, "m").toDate();
      return moment(date).format("DD/MM/YYYY hh:mm a");
    },
    removeTags(str) {
      if (str === null || str === "") return false;
      else str = str.toString();
      return str.replace(/(<([^>]+)>)/gi, "");
    },
    onChangePage(value) {
      this.getNotes(value.page + 1, value.rows);
    },
    async getNotes(page, row) {
      try {
        this.isloading = true;
        const response = await _get(
          APP_URL +
            `get_notes?application_id=${this.$route.params.id}&pageSize=${row}&pageIndex=${page}`
        );

        if (response) {
          this.statements = response.data;
          this.isloading = false;
        }
      } catch (error) {
        this.isloading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },
  async created() {
    await this.getNotes(1, 10);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.color-highlight {
  color: $primary-color !important;
  text-decoration: none;
}

.p-card {
  box-shadow: unset !important;
  border-radius: 6px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  height: 100% !important;
}
::v-deep .p-card-title {
  color: #020038 !important;
}
::v-deep .p-card-content {
  text-align: center !important;
  font-size: 14px !important;
  color: #020038 !important;
  font-weight: 500;
  font-size: 14px;
}
::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}
.total-task {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 600;
  font-size: 16px;
}
</style>
