<template>
  <div>
    <div class="content">
      <div class="form-inputs mt-4">
        <div class="p-grid align-items-center header">
          <p class="col"></p>
          <p class="col label">Application Values</p>
          <p class="col label">Current Values</p>
          <p class="col label">Current Value Source</p>
          <div class="col"></div>
        </div>
        <InputRow
          label="Purchase price"
          :value1="originalData.purchase_price"
          :value2="
            latestData.purchase_price_used_value === 'application_value'
              ? latestData.purchase_price
              : latestData.purchase_price_used_value === 'avm_value'
              ? latestData.purchase_price_avm
              : latestData.purchase_price_used_value === 'cos_value'
              ? latestData.purchase_price_cos
              : latestData.purchase_price_used_value === 'fv_value'
              ? latestData.purchase_price_fv
              : latestData.purchase_price
          "
          :value3="
            latestData.purchase_price_used_value === 'application_value'
              ? 'App value'
              : latestData.purchase_price_used_value === 'avm_value'
              ? 'Avm value'
              : latestData.purchase_price_used_value === 'cos_value'
              ? 'Cos value'
              : latestData.purchase_price_used_value === 'fv_value'
              ? 'Fv value'
              : 'App value'
          "
        />
        <InputRow
          label="Deposit Amount"
          :value1="originalData.deposit_amount"
          :value2="latestData.deposit_amount"
        />
        <InputRow
          label="Stamp Duty"
          :value1="originalData.stamp_duty"
          :value2="latestData.stamp_duty"
        />
        <InputRow
          label="Address Line 1"
          :value1="originalData.add_1"
          :value2="latestData.add_1"
        />
        <InputRow
          label="Address Line 2"
          :value1="originalData.add_2 ? originalData.add_2 : '-'"
          :value2="latestData.add_2 ? latestData.add_2 : '-'"
        />
        <InputRow
          label="City"
          :value1="originalData.city"
          :value2="latestData.city"
        />
        <InputRow
          label="State"
          :value1="originalData.state"
          :value2="latestData.state"
        />
        <InputRow
          label="Country"
          :value1="originalData.country"
          :value2="latestData.country"
        />
        <InputRow
          label="Postcode"
          :value1="originalData.postal_code"
          :value2="latestData.postal_code"
        />
        <InputRow
          label="Propety Type"
          :value1="originalData.type"
          :value2="
            latestData.type_used_value === 'application_value'
              ? latestData.type
              : latestData.type_used_value === 'type_avm'
              ? latestData.type_avm
              : latestData.type_used_value === 'type_cos'
              ? latestData.type_cos
              : latestData.type_used_value === 'type_fv'
              ? latestData.type_fv
              : latestData.type
          "
          :value3="
            latestData.type_used_value === 'application_value'
              ? 'App value'
              : latestData.type_used_value === 'type_avm'
              ? 'Avm value'
              : latestData.type_used_value === 'type_cos'
              ? 'Cos value'
              : latestData.type_used_value === 'type_fv'
              ? 'Fv value'
              : 'App value'
          "
        />
        <InputRow
          label="Settlement Date"
          :value1="originalData.settlement_date"
          :value2="latestData.settlement_date"
        />
        <InputRow
          label="Deposit Paid"
          :value1="originalData.deposit_type"
          :value2="latestData.deposit_type"
        />
        <!-- <InputRow label="Finance clause date" />
        <InputRow label="Valuation status" />
        <InputRow label="Valuation date" />
        <InputRow label="Folio " />
        <InputRow label="Mortgage" />
        <InputRow label="LVR" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import InputRow from "../../InputRow.vue";
export default {
  components: { InputRow },
  props: ["originalData", "latestData"],
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
</style>
