import CrmComponent from "../views/crm/index.vue"
import DashboardComponent from "../views/crm/Dashboard/index.vue"
import TaskComponent from "../views/crm/Tasks/index.vue"
import PipelineComponent from "../views/crm/Pipeline/index.vue"

import ReportingComponent from "../views/crm/Reporting/index.vue"
import ServicingCalculator from "../views/crm/ServicingCalculator/index.vue"
import Login from "../views/crm/Login/index.vue"
import OverviewComponent from "../views/crm/Applications/ApplicationData/Overview.vue"
import DocumentsComponent from "../views/crm/Applications/ApplicationData/Document/Documents.vue"
import CommunicationComponent from "../views/crm/Applications/ApplicationData/Communication.vue"

// Loans
import ApplicationComponent from "../views/crm/Applications/index.vue"
import ApplicationDataComponent from "../views/crm/Applications/ApplicationData/index.vue"
import ApplicantCusotmerComponent from "../views/crm/Applications/ApplicationData/CustomerDetails.vue"
import ApplicantTasksComponent from "../views/crm/Applications/ApplicationData/Task/Tasks.vue"
import ApplicationNotes from "../views/crm/Applications/ApplicationData/Notes/Notes.vue"
import ApplicationDetails from "../views/crm/Applications/ApplicationData/ApplicationDetails/index.vue"
import TransactionReport from "../views/crm/Applications/ApplicationData/Transaction-report/index.vue"
import LoanAudit from "../views/crm/Applications/ApplicationData/Audit/index.vue"
import LoanIntegrations from "../views/crm/Applications/ApplicationData/Integrations/index.vue"
import DecisionSeeker from "../views/crm/Applications/ApplicationData/DecisionSeeker/Index.vue"

// Customers
import CusotmerComponent from "../views/crm/Customer/index.vue"
import CustomerDetail from "../views/crm/Customer/CustomerData/CustomerDetails.vue"
import CustomerCommunication from "../views/crm/Customer/CustomerData/Communication.vue"
import CustomerTasks from "../views/crm/Customer/CustomerData/Task/index.vue"
import CustomerDataComponent from "../views/crm/Customer/CustomerData/index.vue"
import CustomerApplication from "../views/crm/Customer/CustomerData/CustomerApplications.vue"

// Brokers
import BrokerComponent from "../views/crm/Broker/index.vue"
import BrokerDataComponent from "../views/crm/Broker/BrokerData/index.vue"
import BrokerApplication from "../views/crm/Broker/BrokerData/Application.vue"
import BrokerCommunciation from "../views/crm/Broker/BrokerData/Communication.vue"
import BrokerDetail from "../views/crm/Broker/BrokerData/Details.vue"
import BrokerTask from "../views/crm/Broker/BrokerData/Task/index.vue"
import BrokerDocuments from "../views/crm/Broker/BrokerData/Documents/index.vue"

// Settings
import ContactComponent from "../views/crm/Contact/index.vue"
import AccountComponent from "../views/crm/Contact/Account/index.vue"
import SystemComponent from "../views/crm/Contact/System/index.vue"
import UsersComponent from "../views/crm/Contact/System/Users/users.vue"
import GroupComponent from "../views/crm/Contact/System/Group/group.vue"
import PolicyComponent from "../views/crm/Contact/System/Policy/Index.vue"

// Communications
import Communication from "../views/crm/Communication/index.vue"

import { authGuard } from "../services/Authentication/authGuard"

export const BrokerRoutes = [
  {
    path: "/",
    component: CrmComponent,
    children: [
      {
        path: "/loan",
        component: ApplicationComponent,
        name: "Application",
        beforeEnter: authGuard,
        children: [
          {
            path: "/loan/:id",
            component: ApplicationDataComponent,
            name: "ApplicationData",
          },
          {
            path: "/loan/:id/overview",
            component: OverviewComponent,
            name: "ApplicationOverview",
          },
          {
            path: "/loan/:id/details",
            component: ApplicationDetails,
            name: "ApplicationDetails",
          },
          {
            path: "/loan/:id/customer",
            component: ApplicantCusotmerComponent,
            name: "ApplicantCusotmer",
          },
          {
            path: "/loan/:id/notes",
            component: ApplicationNotes,
            name: "ApplicationNotes",
          },
          {
            path: "/loan/:id/documents",
            component: DocumentsComponent,
            name: "Documents",
          },
          {
            path: "/loan/:id/audit",
            component: LoanAudit,
            name: "LoanAudit",
          },
          {
            path: "/loan/:id/transaction",
            component: TransactionReport,
            name: "TransactionReport",
          },
          {
            path: "/loan/:id/tasks",
            component: ApplicantTasksComponent,
            name: "ApplicantTasksComponent",
          },
          {
            path: "/loan/:id/communication",
            component: CommunicationComponent,
            name: "CommunicationComponent",
          },
          {
            path: "/loan/:id/integration",
            component: LoanIntegrations,
            name: "LoanIntegrations",
          },
          {
            path: "/loan/:id/desicion-seeker",
            component: DecisionSeeker,
            name: "DecisionSeeker",
          },
        ],
      },
      {
        path: "/communication",
        component: Communication,
        name: "Communication",
        beforeEnter: authGuard,
      },
      {
        path: "/dashboard",
        component: DashboardComponent,
        name: "Dashboard",
        beforeEnter: authGuard,
      },
      {
        path: "/settings",
        component: ContactComponent,
        name: "settings",
        beforeEnter: authGuard,
        children: [
          {
            path: "/settings/account",
            component: AccountComponent,
            name: "AccountComponent",
          },
          {
            path: "/settings/system/",
            component: SystemComponent,
            name: "SystemComponent",
            children: [
              {
                path: "/settings/system/users",
                component: UsersComponent,
                name: "UsersComponent",
              },
              {
                path: "/settings/system/group",
                component: GroupComponent,
                name: "GroupComponent",
              },
              {
                path: "/settings/system/policy",
                component: PolicyComponent,
                name: "PolicyComponent",
              },
            ],
          },
        ],
      },
      {
        path: "/tasks",
        component: TaskComponent,
        name: "Tasks",
        beforeEnter: authGuard,
      },
      {
        path: "/pipeline",
        component: PipelineComponent,
        name: "Pipeline",
        beforeEnter: authGuard,
      },
      {
        path: "/customers",
        component: CusotmerComponent,
        name: "Customer",
        beforeEnter: authGuard,
        children: [
          {
            path: "/customers/:id",
            component: CustomerDataComponent,
            name: "CustomerDataComponent",
          },
          {
            path: "/customers/:id/detail",
            component: CustomerDetail,
            name: "CustomerDetail",
          },
          {
            path: "/customers/:id/applications",
            component: CustomerApplication,
            name: "CustomerApplication",
          },
          {
            path: "/customers/:id/communication",
            component: CustomerCommunication,
            name: "CustomerCommunication",
          },
          {
            path: "/customers/:id/task",
            component: CustomerTasks,
            name: "CustomerTasks",
          },
        ],
      },
      {
        path: "/brokers",
        component: BrokerComponent,
        name: "Broker",
        beforeEnter: authGuard,
        children: [
          {
            path: "/brokers/:id",
            component: BrokerDataComponent,
            name: "BrokerDataComponent",
          },
          {
            path: "/brokers/:id/detail",
            component: BrokerDetail,
            name: "BrokerDetail",
          },
          {
            path: "/brokers/:id/application",
            component: BrokerApplication,
            name: "BrokerApplication",
          },
          {
            path: "/brokers/:id/communication",
            component: BrokerCommunciation,
            name: "BrokerCommunication",
          },
          {
            path: "/brokers/:id/task",
            component: BrokerTask,
            name: "BrokerTask",
          },
          {
            path: "/brokers/:id/documents",
            component: BrokerDocuments,
            name: "BrokerDocuments",
          },
        ],
      },
      {
        path: "/reporting",
        component: ReportingComponent,
        name: "Reporting",
        beforeEnter: authGuard,
      },
      {
        path: "/communications",
        component: ContactComponent,
        name: "Communications",
        beforeEnter: authGuard,
      },
      {
        path: "/servicing-calculator/draft_id/:id",
        component: ServicingCalculator,
        name: "ServicingCalculatorDraft",
        beforeEnter: authGuard,
      },
      {
        path: "/servicing-calculator",
        component: ServicingCalculator,
        name: "ServicingCalculator",
        beforeEnter: authGuard,
      },
    ],
  },
  {
    path: "/login",
    component: Login,
    name: "Login",
    beforeEnter: authGuard,
  },
]

export default BrokerRoutes
