<template>
  <Card class="std-padding box-shadow">
    <template #content>
      <Spinner v-if="isloading" class="flex justify-content-between" />
      <div v-else>
        <div class="dashboard-option">Average loan value</div>
        <div class="dashboard-number">
          {{ (data && formatAmount(data.average_loan_amount)) || 0 }}
        </div>
        <Indicator
          :type="data.increment ? 'increase' : 'decrease'"
          :value="data.percentage_result || 0"
        />
        <div class="flex mt-3">
          <div class="col">
            <div class="dashboard-option">Application in progress</div>
            <div class="dashboard-number-2">
              {{ (data && data.loan_stage.application_in_progress) || 0 }}
            </div>
            <Indicator
              :type="filterType('application_in_progress')"
              :value="filterValue('application_in_progress')"
            />
          </div>
          <div class="col">
            <div class="dashboard-option">Workshopping application</div>
            <div class="dashboard-number-2">
              {{ (data && data.loan_stage.workshopping_application) || 0 }}
            </div>
            <Indicator
              :type="filterType('workshopping_application')"
              :value="filterValue('workshopping_application')"
            />
          </div>
          <div class="col">
            <div class="dashboard-option">Pre-approval</div>
            <div class="dashboard-number-2">
              {{ (data && data.loan_stage["pre-approval"]) || 0 }}
            </div>
            <Indicator
              :type="filterType('pre-approval')"
              :value="filterValue('pre-approval')"
            />
          </div>
          <div class="col">
            <div class="dashboard-option">Conditional offer</div>
            <div class="dashboard-number-2">
              {{ (data && data.loan_stage.conditional_offer) || 0 }}
            </div>
            <Indicator
              :type="filterType('conditional_offer')"
              :value="filterValue('conditional_offer')"
            />
          </div>
        </div>
        <div class="flex mt-3">
          <div class="col">
            <div class="dashboard-option">Supporting documents</div>
            <div class="dashboard-number-2">
              {{ (data && data.loan_stage.supporting_documents) || 0 }}
            </div>
            <Indicator
              :type="filterType('supporting_documents')"
              :value="filterValue('supporting_documents')"
            />
          </div>
          <div class="col">
            <div class="dashboard-option">Unconditional offer</div>
            <div class="dashboard-number-2">
              {{ (data && data.loan_stage.unconditional_offer) || 0 }}
            </div>
            <Indicator
              :type="filterType('unconditional_offer')"
              :value="filterValue('unconditional_offer')"
            />
          </div>
          <div class="col">
            <div class="dashboard-option">Solicitors instructed</div>
            <div class="dashboard-number-2">
              {{ (data && data.loan_stage.solicitor_instructed) || 0 }}
            </div>
            <Indicator
              :type="filterType('solicitor_instructed')"
              :value="filterValue('solicitor_instructed')"
            />
          </div>
          <div class="col">
            <div class="dashboard-option">Documents issued</div>
            <div class="dashboard-number-2">
              {{ (data && data.loan_stage.document_issued) || 0 }}
            </div>
            <Indicator
              :type="filterType('document_issued')"
              :value="filterValue('document_issued')"
            />
          </div>
        </div>
        <div class="flex mt-3">
          <div class="col">
            <div class="dashboard-option">Documents received</div>
            <div class="dashboard-number-2">
              {{ (data && data.loan_stage.document_received) || 0 }}
            </div>
            <Indicator
              :type="filterType('document_received')"
              :value="filterValue('document_received')"
            />
          </div>
          <div class="col">
            <div class="dashboard-option">Settlements booked</div>
            <div class="dashboard-number-2">
              {{ (data && data.loan_stage.settlement_booked) || 0 }}
            </div>
            <Indicator
              :type="filterType('settlement_booked')"
              :value="filterValue('settlement_booked')"
            />
          </div>
          <div class="col">
            <div class="dashboard-option">Loan funded</div>
            <div class="dashboard-number-2">
              {{ (data && data.loan_stage.loan_funded) || 0 }}
            </div>
            <Indicator
              :type="filterType('loan_funded')"
              :value="filterValue('loan_funded')"
            />
          </div>
          <div class="col">
            <div class="dashboard-option">Arrears</div>
            <div class="dashboard-number-2">
              {{ (data && data.loan_stage.arrears) || 0 }}
            </div>
            <Indicator
              :type="filterType('arrears')"
              :value="filterValue('arrears')"
            />
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from "primevue/card";
import Indicator from "./ProgressIndicator.vue";
import { APP_URL } from "../../../../constants";
import { _get } from "../../../services/crudService";

export default {
  name: "dashboardpage",
  components: {
    Card,
    Indicator,
  },
  props: ["startDateFilter", "endDateFilter"],
  watch: {
    startDateFilter() {
      if (this.startDateFilter && this.endDateFilter) {
        this.getAnalytics(this.startDateFilter, this.endDateFilter);
      }
    },
  },
  data() {
    return {
      data: null,
      isloading: false,
      startDate: null,
      endDate: null,
      percentage: null,
    };
  },
  methods: {
    formatAmount(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });

      return formatter.format(value);
    },
    filterValue(status) {
      let value;
      this.percentage &&
        this.percentage.find((data) => {
          if (data.stage === status) {
            return (value = data.percentage_result);
          }
        });
      if (typeof value === "number") {
        return value.toFixed(1);
      } else return 0;
      // return value || 0;
    },
    filterType(status) {
      let type;
      this.percentage &&
        this.percentage.find((data) => {
          if (data.stage === status) {
            if (data.increment) {
              return (type = "increase");
            } else {
              return (type = "decrease");
            }
          }
        });

      return type;
    },
    async getAnalytics(startDate, endDate) {
      try {
        this.isloading = true;
        const response = await _get(
          APP_URL + `dashboard_crm?start=${startDate}&end=${endDate}`
        );
        if (response) {
          this.data = response.data.result;
          this.percentage = response.data.percentage || [];
          this.isloading = false;
        }
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });

        this.isloading = false;
      }
    },
  },
  async created() {
    this.endDate = new Date().setDate(new Date().getDate());
    this.startDate = new Date().setDate(
      new Date().getDate() - (new Date().getDate() - 1)
    );

    this.startDate = new Date(this.startDate);
    this.endDate = new Date(this.endDate);


    this.getAnalytics(this.startDate.toISOString(), this.endDate.toISOString());
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";
.p-card {
  box-shadow: unset !important;
  border-radius: 0.25rem !important;
  font-family: $font-family !important;
  height: 100% !important;
}

.std-padding {
  padding: 30px !important;
}

:deep .p-card-body {
  padding: 0px !important;
}

:deep .p-card-content {
  padding: 0px !important;
}

::v-deep .p-card-content {
  color: #020038 !important;
  font-weight: bold !important;
}
.dashboard-option {
  color: rgba(2, 0, 56, 0.3);
  font-size: 1rem;
}
.dashboard-number {
  font-size: 3.5rem;
  color: $primary-color;
}
.dashboard-number-2 {
  font-size: 3.5rem;
  color: #020038;
}
</style>
