<template>
  <div class="checkbox-wrapper">
    <h4 v-if="title" class="title">{{ title }}</h4>
    <div v-for="category of categories" :key="category.id">
      <div :class="customClass" class="p-field-checkbox sd">
        <Checkbox
          :id="category.name"
          :name="name"
          :value="category.name"
          v-model="value"
          @change="EmitToParent"
        />
        <label class="label-text" :for="category.label">
          {{ category.label }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "primevue/checkbox";

export default {
  props: {
    categories: {
      type: Array,
    },
    name: {
      type: String,
      required: true,
    },
    title: String,
    customClass: String,
    checkList: Array,
  },
  emits: ["getInputValue"],
  data() {
    return {
      value: this.$props.checkList || [],
    };
  },
  methods: {
    EmitToParent() {
      this.$emit("getInputValue", this.value);
    },
  },
  components: { Checkbox },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.label-text {
  font-size: 1rem;
}
::v-deep .p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: #020038;
}
::v-deep .p-checkbox .p-checkbox-box.p-highlight {
  border-color: unset !important;
  background-color: unset !important;
  background: unset !important;
  border: 2px solid rgba(2, 0, 56, 0.3) !important;
  border-radius: 2px !important;
}

.checkbox-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
