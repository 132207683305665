<template>
  <div class="pl-4 pr-4 pb-4">
    <Card class="box-shadow">
      <template #title>
        <h3 class="title">Transaction Summary</h3>
      </template>
      <template #content>
        <Spinner v-if="isloading" class="flex justify-content-between" />
        <div v-else>
          <div class="flex align-items-center">
            <div class="col">
              <p class="heading">Legal Fees</p>
              <p class="tex">$ {{ statement.legal_fees || 0 }}</p>
            </div>
            <div class="col">
              <p class="heading">Legal Disbursements</p>
              <p class="tex">$ {{ statement.legal_disbursements || 0 }}</p>
            </div>
            <div class="col">
              <p class="heading">PEXA Fees</p>
              <p class="tex">$ {{ statement.pexa_fees || 0 }}</p>
            </div>
            <div class="col">
              <p class="heading">Land Titles Office Registration Fee</p>
              <p class="tex">
                $ {{ statement.land_titles_office_reg_fee || 0 }}
              </p>
            </div>
            <div class="col">
              <p class="heading">Default Interest</p>
              <p class="tex">$ {{ statement.default_interest || 0 }}</p>
            </div>
          </div>

          <div class="flex align-items-star">
            <div class="col">
              <p class="heading">Broker Fee</p>
              <p class="tex">$ {{ statement.broker_fee || 0 }}</p>
            </div>
            <div class="col">
              <p class="heading">Valuation</p>
              <p class="tex">$ {{ statement.valuation || 0 }}</p>
            </div>
            <div class="col">
              <p class="heading">Deposit</p>
              <p class="tex">$ {{ statement.deposit || 0 }}</p>
            </div>
            <div class="col">
              <p class="heading">Live Outstanding Balance</p>
              <p class="tex">$ {{ statement.live_outstanding_balance || 0 }}</p>
            </div>
            <div class="col">
              <p class="heading primary-txt-color">Default interest %</p>
              <TextField
                :value="statement.default_interest_percentage || 0"
                placeholder="label"
                width="100%"
              />
            </div>
          </div>
        </div>

        <DischargeReq
          @dischargeReqCount="dischargeReqCount"
          :key="refreshKey"
        />
        <In
          :transOutCount="transOutCount"
          :key="refreshKey"
          @transactionInCount="transactionInCount"
        />
        <Out @transactionListCount="transactionListCount" />
        <Other @transactionListCount="transactionListCount" />
        <ContractedLs :key="refreshKey" />
        <ForecastedLs :key="refreshKey" />
        <LiveLoanSchedule :key="refreshKey" />
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "primevue/card";
import In from "./In.vue";
import Out from "./Out.vue";
import DischargeReq from "./DischargeReq.vue";
import ContractedLs from "./ContractedLs.vue";
import ForecastedLs from "./ForecastedLs.vue";
import LiveLoanSchedule from "./LiveLoanSchedule.vue";
import { APP_URL } from "../../../../../../constants";
import { _get } from "../../../../../services/crudService";
import Other from "./Other.vue";

export default {
  components: {
    Card,
    In,
    Out,
    ContractedLs,
    ForecastedLs,
    LiveLoanSchedule,
    DischargeReq,
    Other,
  },
  data() {
    return {
      isloading: false,
      transOutCount: 0,
      statement: [],
      refreshKey: 10,
    };
  },
  watch: {
    refreshKey() {
      this.getSummary(true);
    },
  },
  methods: {
    transactionListCount(value) {
      this.transOutCount = value;
      this.refreshKey++;
    },
    transactionInCount() {
      this.refreshKey++;
    },
    dischargeReqCount() {
      this.refreshKey++;
    },
    async getSummary() {
      try {
        this.isloading = true;
        const response = await _get(
          APP_URL +
            `transaction_summary?application_id=${this.$route.params.id}`
        );

        if (response) {
          this.statement = response.data[0] || {};
          this.isloading = false;
        }
      } catch (error) {
        this.isloading = false;
      }
    },
  },

  created() {
    this.getSummary();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

.title {
  font-size: 20px;
  color: $secondary-color;
  font-family: Gordita-medium-;
  margin-top: 1rem;
}

.heading {
  font-size: 14px;
  font-family: Gordita-medium-;
  color: $light-text-color;
}

.text {
  font-family: Gordita-medium-;
  font-size: 14px;
  color: $secondary-color;
}

.primary-txt-color {
  color: $secondary-color;
}
</style>
