<template>
  <div class="p-grid align-items-center" :key="formKey">
    <p class="col flex justify-content-end label">{{ label }}</p>
    <div class="col flex align-items-center disable-input">
      <TextField
        class="full-width"
        :disable="true"
        width="100%"
        placeholder="label"
        :value="firstValue"
      />
    </div>
    <div class="col flex align-items-center disable-input">
      <TextField
        class="full-width"
        :value="secondValue"
        :disable="true"
        width="100%"
        placeholder="label"
      />
    </div>
    <div class="col relative flex align-items-center disable-input">
      <TextField
        class="full-width"
        :disable="true"
        width="100%"
        placeholder="App value"
        :value="thirdValue"
      />
      <div class="orange-dot dot"></div>
    </div>
    <div class="col"></div>
  </div>
</template>

<script>
export default {
  props: ["label", "value1", "value2", "value3"],
  data() {
    return {
      formKey: 0,
      firstValue: "",
      secondValue: "",
      thirdValue: "",
    };
  },
  created() {
    this.firstValue = this.value1;
    this.secondValue = this.value2;
    this.thirdValue = this.value3;
    this.formKey += 1;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
</style>
