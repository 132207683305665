<template>
  <div>
    <p class="title">Broker details</p>
    <div class="form-inputs">
      <div class="flex flex-gap align-items-center">
        <div class="col">
          <p class="label">First Name</p>
          <TextField
            :value="formData.firstName"
            @onInput="getFormData($event, 'firstName')"
            placeholder="Broker's first name"
            width="100%"
          />
        </div>
        <div class="col">
          <p class="label">Last Name</p>
          <TextField
            :value="formData.lastName"
            @onInput="getFormData($event, 'lastName')"
            placeholder="Broker's last name"
            width="100%"
          />
        </div>
      </div>
      <div class="flex flex-gap align-items-center">
        <div class="col-6">
          <p class="label">Phone number</p>
          <TextField
            :value="formData.phoneNumber"
            @onInput="getFormData($event, 'phoneNumber')"
            placeholder="Phone number"
            width="100%"
            maxValue="12"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
// import { checkMobileNumber } from "../../../../services/validatePhNo";
export default {
  data() {
    return {
      formData: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
      },
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        firstName: { required },
        lastName: { required },
        phoneNumber: {
          required,
          maxLength: maxLength(10),
        },
      },
    };
  },
  methods: {
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };

      if (fieldName === "phoneNumber") {
        this.formData.phoneNumber = value.toString();
      }

      this.$emit("isBrokersDetailsValid", this.v$.$invalid);
      this.$emit("getBrokerDetails", this.formData);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.title {
  font-family: Gordita-medium-;
  font-size: 16px;
  color: $secondary-color;
}

p {
  margin: 0;
}

:deep .col,
:deep .col-6 {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.flex-gap {
  gap: 10px;
}

.label,
:deep .label-text {
  font-size: 14px;
  font-family: Gordita-medium;
  color: $secondary-color;
}
</style>
