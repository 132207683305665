<template>
  <div class="mr-top">
    <div class="heading mt-4 flex align-items-center justify-content-between">
      <div class="title">{{ title }}</div>
      <div class="right-col flex align-items-center pointer">
        <div
          @click="openSplitSelected"
          v-if="restrictUser('splitselected')"
          class="flex align-items-center"
        >
          <img src="@/assets/scissors.svg" alt="" />
          <p class="lt-txt-color fs-14 hk-b ml-2">Split selected</p>
        </div>
        <div
          @click="openMergeSelected"
          v-if="restrictUser('mergeSelected')"
          class="flex align-items-center ml-4 pointer"
        >
          <img src="@/assets/minimize-2.svg" alt="" />
          <p class="lt-txt-color fs-14 hk-b ml-2">Merge selected</p>
        </div>
      </div>
    </div>

    <FullTimeIncome
      @incomeSelected="incomeSelected"
      :key="fullTimeRefreshKey"
      :disable="title === 'Applicant 1' ? false : true"
    />

    <div class="heading flex align-items-center mt-4 justify-content-between">
      <div class="title">{{ title }}</div>
      <div class="right-col flex align-items-center">
        <div
          @click="openSplitSelected"
          v-if="restrictUser('splitselected')"
          class="flex align-items-center pointer"
        >
          <img src="@/assets/scissors.svg" alt="" />
          <p class="lt-txt-color fs-14 hk-b ml-2">Split selected</p>
        </div>
        <div
          @click="openMergeSelected"
          v-if="restrictUser('mergeSelected')"
          class="flex align-items-center ml-4 pointer"
        >
          <img src="@/assets/minimize-2.svg" alt="" />
          <p class="lt-txt-color fs-14 hk-b ml-2">Merge selected</p>
        </div>
      </div>
    </div>
    <PartTimeIncome
      @incomeSelected="incomeSelected"
      :key="partTimeRefreshKey"
      :disable="title === 'Applicant 1' ? false : true"
    />

    <div class="heading flex align-items-center mt-4 justify-content-between">
      <div class="title">{{ title }}</div>
      <div class="right-col flex align-items-center">
        <div
          @click="openSplitSelected"
          v-if="restrictUser('splitselected')"
          class="flex align-items-center pointer"
        >
          <img src="@/assets/scissors.svg" alt="" />
          <p class="lt-txt-color fs-14 hk-b ml-2">Split selected</p>
        </div>
        <div
          @click="openMergeSelected"
          v-if="restrictUser('mergeSelected')"
          class="flex align-items-center ml-4 pointer"
        >
          <img src="@/assets/minimize-2.svg" alt="" />
          <p class="lt-txt-color fs-14 hk-b ml-2">Merge selected</p>
        </div>
      </div>
    </div>
    <CasualIncome
      @incomeSelected="incomeSelected"
      :key="casualRefreshKey"
      :disable="title === 'Applicant 1' ? false : true"
    />

    <div class="heading flex align-items-center mt-4 justify-content-between">
      <div class="title">{{ title }}</div>
      <div class="right-col flex align-items-center">
        <div
          @click="openSelfSplitSelected"
          v-if="restrictUser('splitselected')"
          class="flex align-items-center pointer"
        >
          <img src="@/assets/scissors.svg" alt="" />
          <p class="lt-txt-color fs-14 hk-b ml-2">Split selected</p>
        </div>
        <div
          @click="openSelfEmpMergeSelected"
          v-if="restrictUser('mergeSelected')"
          class="flex align-items-center ml-4 pointer"
        >
          <img src="@/assets/minimize-2.svg" alt="" />
          <p class="lt-txt-color fs-14 hk-b ml-2">Merge selected</p>
        </div>
      </div>
    </div>
    <SelfEmployedIncome
      @incomeSelected="incomeSelected"
      :disable="title === 'Applicant 1' ? false : true"
      :key="selfEmpRefreshKey"
    />
    <MergeSelected
      :disable="title === 'Applicant 1' ? false : true"
      @mergeSelected="mergeSelected"
      :show="showMergeSelected"
      @close="showMergeSelected = false"
      :selectedIncome="selectedIncome"
      :allIncome="allIncome"
      :type="selectedType"
    />
    <SelfEmpMerge
      :disable="title === 'Applicant 1' ? false : true"
      @mergeSelected="mergeSelected"
      :show="showMergeSelected1"
      @close="showMergeSelected1 = false"
      :selectedIncome="selectedIncome"
      :allIncome="allIncome"
      :type="selectedType"
    />
    <SplitSelected
      :disable="title === 'Applicant 1' ? false : true"
      @splitSelected="splitSelected"
      :show="showSplitSelected"
      @close="showSplitSelected = false"
      :selectedIncome="selectedIncome"
      :allIncome="allIncome"
      :type="selectedType"
    />
    <SelfEmpSplit
      :disable="title === 'Applicant 1' ? false : true"
      @splitSelected="splitSelected"
      :show="showSplitSelected1"
      @close="showSplitSelected1 = false"
      :selectedIncome="selectedIncome"
      :allIncome="allIncome"
      :type="selectedType"
    />
  </div>
</template>

<script>
import FullTimeIncome from "./FullTimeIncome.vue";
import PartTimeIncome from "./PartTimeIncome.vue";
import CasualIncome from "./CasualIncome.vue";
import SelfEmployedIncome from "./SelfEmployed.vue";
import MergeSelected from "./MergeSelected.vue";
import SplitSelected from "./SplitSelected.vue";
import SelfEmpSplit from "./SelfEmpSplit.vue";
import SelfEmpMerge from "./SelfEmpMerge.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    FullTimeIncome,
    PartTimeIncome,
    CasualIncome,
    MergeSelected,
    SplitSelected,
    SelfEmployedIncome,
    SelfEmpSplit,
    SelfEmpMerge,
  },
  props: ["title"],
  data() {
    return {
      casualRefreshKey: 0,
      partTimeRefreshKey: 10,
      fullTimeRefreshKey: 20,
      selfEmpRefreshKey: 30,
      selectedIncome: [],
      allIncome: [],
      selectedType: "",
      category: {
        id: 1,
        label: "Full time income - Business Name",
      },
      showMergeSelected: false,
      showSplitSelected: false,
      showSplitSelected1: false,
      showMergeSelected1: false,
    };
  },
  computed: {
    ...mapGetters({ condition: "auth/userPermission" }),
  },
  methods: {
    restrictUser(value) {
      return this.condition[value];
    },
    openSplitSelected() {
      if (this.selectedIncome.length != 1) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Select only one income to split!",
          life: 3000,
        });
        return;
      }
      return (this.showSplitSelected = true);
    },
    openSelfSplitSelected() {
      if (this.selectedIncome.length != 1) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Select only one income to split!",
          life: 3000,
        });
        return;
      }
      return (this.showSplitSelected1 = true);
    },
    openSelfEmpMergeSelected() {
      if (this.selectedIncome.length != 2) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Select two incomes to merge!",
          life: 3000,
        });
        return;
      }
      if (this.selectedIncome.length === 2) {
        if (
          this.selectedIncome[0].self_emp_trading_name ==
          this.selectedIncome[1].self_emp_trading_name
        ) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Select different trading name!",
            life: 3000,
          });
          return;
        }
      }
      return (this.showMergeSelected1 = true);
    },
    openMergeSelected() {
      if (this.selectedIncome.length != 2) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Select two incomes to merge!",
          life: 3000,
        });
        return;
      }
      if (this.selectedIncome.length === 2) {
        if (
          this.selectedIncome[0].name_of_employer ==
          this.selectedIncome[1].name_of_employer
        ) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Select different employer name!",
            life: 3000,
          });
          return;
        }
      }
      return (this.showMergeSelected = true);
    },
    splitSelected() {
      this.fullTimeRefreshKey += 1;
      this.partTimeRefreshKey += 1;
      this.casualRefreshKey += 1;
      this.selfEmpRefreshKey += 1;
      this.showSplitSelected = false;
      this.showSplitSelected1 = false;
    },
    mergeSelected() {
      this.fullTimeRefreshKey += 1;
      this.partTimeRefreshKey += 1;
      this.casualRefreshKey += 1;
      this.selfEmpRefreshKey += 1;
      this.showMergeSelected = false;
      this.showMergeSelected1 = false;
    },
    incomeSelected(income) {
      switch (income.type) {
        case "full_time":
          this.casualRefreshKey += 1;
          this.partTimeRefreshKey += 1;
          this.selectedIncome = income.selectedIncome;
          this.allIncome = income.allIncome;
          this.selectedType = income.type;
          break;
        case "part_time":
          this.casualRefreshKey += 1;
          this.fullTimeRefreshKey += 1;
          this.selectedIncome = income.selectedIncome;
          this.allIncome = income.allIncome;
          this.selectedType = income.type;
          break;
        case "casual":
          this.partTimeRefreshKey += 1;
          this.fullTimeRefreshKey += 1;
          this.selectedIncome = income.selectedIncome;
          this.allIncome = income.allIncome;
          this.selectedType = income.type;
          break;
        case "self_employed":
          this.partTimeRefreshKey += 1;
          this.fullTimeRefreshKey += 1;
          this.selectedIncome = income.selectedIncome;
          this.allIncome = income.allIncome;
          this.selectedType = income.type;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
}

.heading {
  border-bottom: 1px solid rgba(2, 0, 56, 0.15);
  padding-bottom: 13px;
}

.pointer {
  cursor: pointer;
}

p {
  margin: 0;
}
</style>
