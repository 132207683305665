<template>
  <div>
    <Fees1 :responseValue="responseValue" @validateFees1="validateFees1" />
    <Fees2
      :responseValue="responseValue"
      @validateFees2="validateFees2"
      class="mt-2"
    />
  </div>
</template>

<script>
import Fees1 from "./Fees1.vue";
import Fees2 from "./Fees2.vue";
export default {
  components: { Fees1, Fees2 },
  props: ["responseValue", "isloading"],
  methods: {
    validateFees1(value) {
      this.$emit("validateFees", value);
    },

    validateFees2(value) {
      this.$emit("validateFees", value);
    },
  },

};
</script>

<style></style>
