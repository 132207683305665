<template>
  <div class="std-padding-x policy-wrappper">
    <div>
      <div class="flex justify-content-between mb-5">
        <p class="title">Loan policy</p>
        <div class="flex align-items-center">
          <div class="flex align-items-center">
            <img class="mr-2" src="@/assets/plus-inactive.svg" />
            <p class="lt-txt-color">Add value field</p>
          </div>
        </div>
      </div>
      <InitialLvr />
      <EndDebt />
      <InitialAmount />
      <Income />
      <Serviceability />
      <Tasks />
      <Footer />
    </div>
  </div>
</template>

<script>
import InitialLvr from "./InitialLvr.vue";
import EndDebt from "./EndDebt.vue";
import InitialAmount from "./InitialAmount.vue";
import Income from "./Income.vue";
import Serviceability from "./Serviceability.vue";
import Tasks from "./Tasks.vue";
import Footer from "./Footer.vue";
export default {
  components: {
    InitialLvr,
    EndDebt,
    InitialAmount,
    Income,
    Serviceability,
    Tasks,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.std-padding-x {
  padding: 0 1rem;
}

.flex-gap {
  gap: 0.625rem;
}

.highlight-fields {
  ::v-deep .label {
    font-family: Gordita-bold !important;
  }
}

.policy-wrappper {
  background: #ffffff;
}

.title-wrapper-1 {
  // border-bottom: 1px solid $secondary-color;
  padding-bottom: 0.625rem;
}

.title {
  font-size: 1.75rem;
  font-family: Gordita-bold;
  // margin-left: 0.625rem;
  color: #020038;
}

.mr-b {
  margin-bottom: 1.875rem;
}

.mr-b-5px {
  margin-bottom: 5px;
}

.form-inputs {
  margin-top: 2.5rem;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.box {
  background: #ffffff;
  border: 1px solid rgba(2, 0, 56, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 1.25rem;
}

p {
  margin: 0;
}
.lt-txt {
  font-weight: 600;
}
.lt-text {
  font-weight: 600;
}
</style>
