<template>
  <div>
    <div class="content">
      <div class="form-inputs mt-4">
        <div class="p-grid align-items-center header">
          <p class="col"></p>
          <p class="col label">Application Values</p>
          <p class="col label">Current Values</p>
          <p class="col label">Current Value Source</p>
          <div class="col"></div>
        </div>

        <InputRow
          label="Current Mortgage"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.current_mortgage
              : '0'
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.current_mortgage
              : '0'
          "
        />
        <InputRow
          label="Monthly Repayment"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.monthly_repayment
              : '0'
          "
          :value2="
             originalData.calculated_fields
              ? originalData.calculated_fields.monthly_repayment
              : '0'
          "
        />
        <InputRow label="1 Months Interest" :value1="0" :value2="0" />
        <InputRow
          label="Buffer"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.buffer
              : '0'
          "
          :value2="
             originalData.calculated_fields
              ? originalData.calculated_fields.buffer
              : '0'
          "
        />
        <InputRow
          label="Discharge Fee"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.discharge_fee
              : '0'
          "
          :value2="
            originalData.calculated_fields
              ? originalData.calculated_fields.discharge_fee
              : '0'
          "
        />
        <InputRow
          label="Mortgage Payout Estimate"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.mortgage_payout_estimate
              : '0'
          "
          :value2="
           originalData.calculated_fields
              ? originalData.calculated_fields.mortgage_payout_estimate
              : '0'
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputRow from "../../InputRow.vue";
import { mapGetters } from "vuex";
export default {
  components: { InputRow },
  computed: {
    ...mapGetters({
      originalData: "loans/originalData",
      latestData: "loans/latestData",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
</style>
