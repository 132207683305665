<template>
  <div class="flex align-items-center mt-3">
    <Button
      class="mt-3 mb-4"
      label="Save values"
      type="primary"
      @click="step = 3"
    />
    <div class="back mt-3 mb-4 ml-4 cursor-pointer" @click="close">Cancel</div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit("close")
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
  color: white;
  font-size: 0.875rem;
}

.footer-wrapper {
  gap: 2.25rem;
  margin-top: 2.5rem;
  height: 60px;
  border-radius: 4px;
  padding: 10px;
}
.back {
  font-weight: 700;
  color: rgba(2, 0, 56, 0.4);
  font-size: 1rem;
}
</style>
