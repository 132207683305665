<template>
  <div class="pl-4 pr-4 pb-4">
    <Card class="p-3">
      <template #title>
        <span class="module-sub-title">Tasks</span>
      </template>
      <template #content>
        <span class="p-input-icon-left full-width">
          <i class="pi pi-search" />
          <InputText
            type="text"
            style="width: 100%"
            v-model="searchValue"
            placeholder="Search"
            @onInput="getSearchValue"
          />
        </span>
        <div class="flex flex-gap justify-content-between opt mt-3">
          <div class="col">
            <div class="filter-title">Status</div>
            <Dropdown
              width="100%"
              @change="getDropdownValue($event, 'status')"
              :items="statusList"
              optionLabel="name"
              optionValue="value"
              placeholder="-select-"
              :value="selectedStatus"
            />
          </div>
          <div class="col">
            <div class="filter-title">Task</div>
            <Dropdown
              width="100%"
              @change="getDropdownValue($event, 'task_type')"
              :items="statgeList"
              placeholder="-select-"
              optionLabel="taskTypes"
              optionValue="taskTypes"
            />
          </div>
          <div class="col">
            <div class="filter-title">Assigned to</div>
            <Dropdown
              width="100%"
              @change="getDropdownValue($event, 'assigned_to')"
              :items="tagsList"
              placeholder="-select-"
              optionLabel="name"
              optionValue="value"
            />
          </div>
          <div class="col">
            <div class="filter-title">From date</div>
            <DatePicker2
              width="100%"
              @change="getDropdownValue($event, 'from_date')"
              placeholder="DD/MM/YYY"
              :value="dropDownValue.fromDate"
            />
          </div>
          <div class="col">
            <div class="filter-title">To date</div>
            <DatePicker2
              width="100%"
              @change="getDropdownValue($event, 'to_date')"
              placeholder="DD/MM/YYY"
              :value="dropDownValue.toDate"
            />
          </div>
        </div>
        <div class="divider mt-2"></div>
        <div class="mt-2 flex align-items-center justify-content-between mt-3 total-task">
          <div>
            Total tasks:
            <span class="total-task-count">{{
              (statements && statements.totalCount) || 0
            }}</span>
          </div>
          <div class="flex align-items-center">
            <div
              class="flex align-items-center task-opt ml-3 cursor-pointer"
              @click="addNewTasks"
            >
              <div v-if="restrictUser('addNewTasks')">
                <span class="mr-2">+</span> New tasks
              </div>
            </div>
            <!-- <div
              @click="completeTasks"
              v-if="restrictUser('ModifyExistingTasks')"
              class="flex align-items-center ml-3"
              :class="disableComplete ? '' : 'cursor-pointer task-opt'"
            >
              <div><span class="mr-2">+</span> Complete</div>
            </div>
            <div
              @click="cancelTasks"
              v-if="restrictUser('ModifyExistingTasks')"
              class="flex align-items-center ml-3"
              :class="disableCancel ? '' : 'cursor-pointer task-opt'"
            >
              <div><span class="mr-2">+</span> Cancel</div>
            </div>
            <div
              @click="openResignAssign"
              v-if="restrictUser('ModifyExistingTasks')"
              class="
                flex
                cursor-pointer
                pointer
                task-opt
                align-items-center
                ml-3
                pointer
              "
            >
              <div><span class="mr-2">+</span> Re-assign</div>
            </div> -->
          </div>
        </div>
        <Spinner v-if="isloading" class="flex justify-content-between" />

        <DataTable
          v-else
          class="mt-4"
          :rows="10"
          selectionMode="multiple"
          data-key="id"
          :value="statements.result"
          :rowsPerPageOptions="[10, 20, 30]"
        >
          <!-- <Column selectionMode="multiple"></Column> -->

          <template #empty>No data found.</template>

          <Column field="Id" header>
            <template #body="slotProps">
              <div
                v-if="slotProps.data.taskTitle !== 'Send Conditional Offer to Customer'"
                class="flex align-items-center justify-content-center"
              >
                <input
                  type="checkbox"
                  class="table-checkbox pointer"
                  name="slotProps.data.Id"
                  :value="{
                    Id: slotProps.data.Id,
                    status: slotProps.data.status,
                    assignTo: slotProps.data.assignTo,
                    progressType: slotProps.data.progress_type,
                    regardingTo: slotProps.data.regardingTo,
                    regardingToId: slotProps.data.regardingToId,
                    application_id: slotProps.data.application_id,
                    crm_status: slotProps.data.crm_status,
                    taskId: [{ Id: slotProps.data.Id }],
                  }"
                  v-model="selectedRow"
                />
              </div>
            </template>
          </Column>
          <Column field="taskTitle" header="Task" style="max-width: 30rem">
            <template #body="slotProps">
              <a class="color-highlight" @click="goToEditTask(slotProps.data.Id)">{{
                slotProps.data.taskTitle || "-"
              }}</a>
            </template>
          </Column>
          <Column field="attachment" header="File">
            <template #body="slotProps">{{
              slotProps.data.attachment ? "File" : "-"
            }}</template>
          </Column>
          <Column field="task_type" header="Type">
            <template #body="slotProps">
              <div>{{ slotProps.data.task_type || "-" }}</div>
            </template>
          </Column>
          <Column field="status" header="Status"></Column>
          <Column field="assignedToType" header="Assigned to">
            <template #body="slotProps">
              <div>{{ slotProps.data.assignedToType || "-" }}</div>
            </template>
          </Column>
          <Column field="dueDate" header="Due date">
            <template #body="slotProps" class="not-assigned">
              <div>
                <span class="not-assigned">
                  {{ slotProps.data.dueDate ? formatDate(slotProps.data.dueDate) : "-" }}
                </span>
              </div>
            </template>
          </Column>
          <Column field="dateCompleted" header="Date completed" class="date-completed">
            <template #body="slotProps" class="not-assigned">
              <div>
                <span class="not-assigned">
                  {{
                    slotProps.data.dateCompleted
                      ? formatDate(slotProps.data.dateCompleted)
                      : "N/A"
                  }}
                </span>
              </div>
            </template>
          </Column>
        </DataTable>
        <Paginator
          class="mt-2"
          :rows="10"
          :totalRecords="statements && statements.totalCount"
          :rowsPerPageOptions="[10, 20, 30]"
          template="RowsPerPageDropdown  CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} of {totalRecords} tasks"
          @page="onChangePage($event)"
        ></Paginator>
      </template>
    </Card>
    <!-- v-click-outside="(showAddTask = false)" -->
    <AddTask
      v-if="showAddTask"
      :show="showAddTask"
      @save="refreshKey++"
      @close="showAddTask = false"
      :key="addTaskKey"
      assigned="User"
      regards="Loan"
      :regardId="this.$route.params.id"
      portal="Loan"
    />
    <EditTask
      v-if="showEditTask"
      :key="editTaskKey"
      :show="showEditTask"
      :taskId="selectedTaskId"
      @close="showEditTask = false"
      portal="application"
      @save="refreshKey++"
    />
    <ReAssignTask
      v-if="showReAssign"
      :key="reAssignKey"
      :show="showReAssign"
      :taskId="selectedTaskId"
      @close="showReAssign = false"
      portal="application"
      @save="refreshKey++"
    />
  </div>
</template>

<script>
import Card from "primevue/card";
import { mapActions, mapGetters } from "vuex";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import AddTask from "./AddTask.vue";
import EditTask from "./EditTask.vue";
import Paginator from "primevue/paginator";
import ReAssignTask from "./ReAssign.vue";
import { _get, _put } from "../../../../../services/crudService";
import { APP_URL, SERVER_URL } from "../../../../../../constants";
import moment from "moment";

export default {
  name: "Tasks",
  components: {
    Card,
    Paginator,
    DataTable,
    Column,
    InputText,
    AddTask,
    EditTask,
    ReAssignTask,
  },
  data() {
    return {
      regardingto: "Customer",
      assignto: "User",
      selectedStatus: "open",
      currentStatus: "",
      showAddTask: false,
      showEditTask: false,
      showReAssign: false,
      disableComplete: false,
      disableCancel: false,
      statements: [],
      ownerList: ["All"],
      statusList: [
        { name: "Open", value: "open" },
        { name: "Review", value: "review" },
        { name: "Completed", value: "completed" },
        { name: "Cancelled", value: "cancelled" },
      ],
      tagsList: [{ name: "All", value: "all" }],
      statgeList: [],
      isloading: true,
      searchValue: "",
      refreshKey: 10,
      selectedRow: [],
      selectedTaskId: "",
      dropDownValue: {
        status: "open",
        task_type: "",
        assigned_to: "",
        from_date: "",
        to_date: "",
      },
      isFirstTime: true,
    };
  },
  watch: {
    searchValue(value) {
      if (value !== "") {
        clearTimeout(this.updateSearch);

        this.updateSearch = setTimeout(async () => {
          this.getTasks(1, 10, value);
        }, 800);
      }
    },
    selectedRow() {
      if (this.selectedRow.length === 0) {
        this.disableComplete = false;
        this.disableCancel = false;
      }

      this.selectedRow.map((task) => {
        if (
          (task.status === "Open" || task.status === "open") &&
          task.assignTo !== "User" &&
          task.assignTo !== "Group"
        ) {
          return (this.disableComplete = true);
        }
        if (task.status === "completed" || task.status === "review") {
          return (this.disableCancel = true);
        }
        this.disableComplete = false;
        this.disableCancel = false;
      });
    },
    refreshKey() {
      this.reloadAppSummary();
      this.getDropdownValue(this.selectedStatus, "status");
    },
  },
  computed: {
    ...mapGetters({ condition: "auth/userPermission" }),
  },
  methods: {
    ...mapActions({
      reloadAppSummary: "loanOverview/reloadAppSummary",
    }),
    formatDate(date) {
      let d = moment(date).format("DD/MM/YYYY HH:MM");

      if (d === "Invalid date") {
        return date || "-";
      } else {
        return d;
      }
    },

    addNewTasks() {
      this.addTaskKey += 1;
      this.showAddTask = true;
    },
    goToEditTask(id) {
      this.selectedTaskId = id;
      this.editTaskKey += 1;
      this.showEditTask = true;
    },
    getSearchValue(value) {
      this.searchValue = value;
    },
    async getDropdownValue(value, fieldName) {
      if (this.isFirstTime) return;
      if (value == "Invalid Date") {
        return;
      }
      if (fieldName === "status") {
        this.selectedStatus = value;
      }

      this.dropDownValue[fieldName] = value;
      try {
        this.isloading = true;

        const getAppStatus = await _get(
          APP_URL + `application_status?id=${this.$route.params.id}`
        );

        if (getAppStatus) {
          const response = await _get(
            SERVER_URL +
              `v2/get_tasks_crm_loan?regardingToId=${
                this.$route.params.id
              }&progressType=${getAppStatus.data.result.status}&crm_status=${
                getAppStatus.data.result?.crm_status
              }&status=${this.dropDownValue.status || ""}&task_type=${
                this.dropDownValue.task_type || ""
              }&assigned_to=${this.dropDownValue.assigned_to || ""}&from_date=${
                this.dropDownValue.from_date || ""
              }&to_date=${this.dropDownValue.to_date || ""}`
          );

          if (response) {
            this.isloading = false;
            let data = response.data.result;
            let openStatus = data.filter((res) => res.status === "open");
            let reviewStatus = data.filter((res) => res.status === "review");
            let completedStatus = data.filter((res) => res.status === "completed");
            let cancelledStatus = data.filter((res) => res.status === "cancelled");
            this.statements.result = [
              ...openStatus,
              ...reviewStatus,
              ...completedStatus,
              ...cancelledStatus,
            ];
            this.statements.totalCount = response.data.totalCount;
          }
        }
      } catch (error) {
        this.isloading = false;
      }
    },
    openResignAssign() {
      this.reAssignKey += 1;
      if (this.selectedRow.length && this.selectedRow.length === 1) {
        this.showReAssign = true;
        this.selectedTaskId = this.selectedRow[0].Id;
      }
    },

    async getTaskType() {
      try {
        this.statgeList = [];
        this.isLoading = true;
        const response = await _get(SERVER_URL + `get_task_types?type=group`);
        if (response) {
          this.statgeList = response.data.result;
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },

    async getAssignTo() {
      try {
        this.tagsList = [];
        this.isLoading = true;
        const response = await _get(
          SERVER_URL + `get_assigned_to_customer?application_id=${this.$route.params.id}`
        );
        if (response) {
          this.tagsList = response.data.result;

          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }

      this.tagsList.push(
        { name: "Customer", value: "customer" },
        { name: "Broker", value: "broker" }
      );
      this.tagsList.map((obj) => {
        if (obj.firstName && obj.lastName) {
          obj.name = `${obj.firstName} ${obj.lastName}`;
          obj.value = obj.filterName;
        }
      });
    },
    async completeTasks() {
      if (this.selectedRow.length !== 0 && !this.disableComplete) {
        let obj = {
          taskId: [],
          application_id: this.selectedRow[0].application_id,
          progressType: this.selectedRow[0].progressType,
          crm_status: this.selectedRow[0].crm_status,
        };

        this.selectedRow.map((list) => {
          obj["taskId"].push({ Id: list.Id });
        });

        this.isloading = true;

        await _put(SERVER_URL + "update_task_completed?portal=application", obj)
          .then(() => {
            this.selectedRow = [];
            this.refreshKey++;
            this.isloading = false;

            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Task updated successfully",
              life: 3000,
            });
          })
          .catch(() => {
            this.isloading = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Cannot complete this task(s)",
              life: 3000,
            });
          });
      }
    },
    async cancelTasks() {
      if (this.selectedRow.length !== 0 && !this.disableCancel) {
        this.isloading = true;

        let data = [];
        this.selectedRow.map((Id) => {
          data.push({ id: Id.Id, status: "cancelled" });
        });

        try {
          const response = await _put(SERVER_URL + "cancel_tasks", data);

          response &&
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Task Cancelled successfully",
              life: 3000,
            });

          if (response) {
            this.selectedRow = [];
            this.refreshKey++;
            this.isloading = false;
          }
        } catch (err) {
          this.isloading = false;

          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
    },
    onChangePage(value) {
      this.getTasks(value.page + 1, value.rows);
    },
    async getTasks(page, row, search = "") {
      try {
        this.isloading = true;

        const getAppStatus = await _get(
          APP_URL + `application_status?id=${this.$route.params.id}`
        );

        if (getAppStatus) {
          const response = await _get(
            SERVER_URL +
              `v2/get_tasks_crm_loan?regardingToId=${this.$route.params.id}&progressType=${getAppStatus.data.result.status}&crm_status=${getAppStatus.data.result?.crm_status}&status=open&pageSize=${row}&pageIndex=${page}&search=${search}`
          );

          if (response) {
            this.isloading = false;

            let data = response.data.result;
            let openStatus = data.filter((res) => res.status === "open");
            let reviewStatus = data.filter((res) => res.status === "review");
            let completedStatus = data.filter((res) => res.status === "completed");
            let cancelledStatus = data.filter((res) => res.status === "cancelled");
            this.statements.result = [
              ...openStatus,
              ...reviewStatus,
              ...completedStatus,
              ...cancelledStatus,
            ];
            this.statements.totalCount = response.data.totalCount;
          }
        }
      } catch (error) {
        this.isloading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    restrictUser(value) {
      return this.condition[value];
    },
  },

  async created() {
    this.isFirstTime = true;
    await this.getTasks(1, 10);
    this.getTaskType();
    this.getAssignTo();
    this.isFirstTime = false;
    // this.addNewTask = this.condition.addNewTasks;
    // this.modify = this.condition.ModifyExistingTasks;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.p-card {
  box-shadow: unset !important;
  border-radius: 6px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  height: 100% !important;
}
::v-deep .p-card-title {
  color: #020038 !important;
}
::v-deep .p-card-content {
  text-align: center !important;
  font-size: 14px !important;
  color: #020038 !important;
  font-weight: 500;
}
::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}
.p-dropdown {
  border: unset !important;
}
::v-deep .p-inputtext {
  text-align: left !important;
}
.filter-title {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 600;
  font-size: 1rem;
  text-align: left;
}
.col {
  padding: unset !important;
}
.divider {
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
.color-highlight {
  color: $primary-color !important;
}
.total-task {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 600;
  font-size: 16px;
}
.total-task-count {
  color: #020038;
}
:deep .p-focus {
  border-color: gray !important;
  box-shadow: 0 0 0 0.2rem #ced4da !important;
}

.opt :deep .p-dropdown {
  border: none !important;
}

.flex-gap {
  gap: 1rem;
}

:deep .datepicker-layout {
  border: none !important;
}

.table-checkbox {
  width: 18px !important;
  height: 18px !important;
}
</style>
