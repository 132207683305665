<template>
  <div class="std-padding-rv">
    <div class="flex align-items-center justify-content-between">
      <h1 class="module-title">Pipeline</h1>
      <div class="flex align-items-center">
        <div class="dashboard-option">Pipeline</div>
        <Dropdown
          width="160px"
          class="ml-3"
          :items="items"
          :value="selected"
          @change="pipelineOptChange"
        />
        <Button class="ml-3" type="primary" label="Edit stages" />
      </div>
    </div>
    <Pipeline
      :applications="applications"
      :funded="funded"
      :arrears="arrears"
      :selectedOpt="selected"
      :isloading="isLoading"
      :appStage="appStageList"
      class="mt-5 full-width"
    />
  </div>
</template>

<script>
import Pipeline from "./Pipeline.vue";
export default {
  name: "PipelineComponent",
  components: {
    Pipeline,
  },
  data() {
    return {
      items: ["Applications", "Funded", "Arrears"],
      selected: "Applications",
      loanList: [],
      applications: true,
      funded: false,
      arrears: false,
      isLoading: false,
      appStageList: {},
      fundedStageList: [],
      arrearsStageList: [],
    };
  },
  methods: {
    pipelineOptChange(value) {
      this.selected = value;
      if (value === "Applications") {
        this.applications = true;
        this.funded = false;
        this.arrears = false;
      } else if (value === "Funded") {
        this.funded = true;
        this.applications = false;
        this.arrears = false;
      } else if (value === "Arrears") {
        this.arrears = true;
        this.funded = false;
        this.applications = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";
::v-deep .p-dropdown {
  background: unset !important;
}
.dashboard-option {
  color: rgba(2, 0, 56, 0.3);
  font-size: 1rem;
  font-weight: 600;
  font-family: Gordita-medium;
}
</style>
