<template>
  <div class="mr-t-20">
    <p class="label mr-b-5">{{ title }}</p>
    <TextArea
      width="100%"
      height="80px"
      resize="none"
      :value="description"
      placeholder="Description"
      @onInput="(e) => getFormData(e)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["title"],
  data() {
    return {
      description: "",
    };
  },
  computed: {
    ...mapGetters({ selectedApplication: "applications/selectedApplication" }),
  },
  methods: {
    ...mapActions({ setReviewData: "loanDescription/setReviewData" }),
    getFormData(value) {
      this.description = value;
      this.setReviewData({ value: this.description, type: "asset_review" });
    },
  },
  created() {
    this.description =
      this.selectedApplication?.reviews_data?.asset_review || "";
    this.setReviewData({ value: this.description, type: "asset_review" });
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.flex-gap {
  gap: 20px;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.mr-t-20 {
  margin-top: 20px;
}
</style>
