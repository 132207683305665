<template>
  <div>
    <div class="content">
      <div class="form-inputs mt-4">
        <div class="p-grid align-items-center header">
          <p class="col"></p>
          <p class="col label">Application Values</p>
          <p class="col label">Current Values</p>
          <p class="col label">Current Value Source</p>
          <div class="col"></div>
        </div>

        <InputRow
          label="Yield %"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.yield_value * 100
              : 0
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.yield_value * 100
              : 0
          "
        />
        <InputRow
          label="Loan Rate %"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.cap_loan_rate * 100
              : 0
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.cap_loan_rate * 100
              : 0
          "
        />
        <InputRow
          label="Set-up Fee %"
          :value1="original_setup_fee_percent * 100"
          :value2="latest_setup_fee_percent * 100"
        />
        <InputRow
          label="Set-up Fee $"
          :value1="original_setup_fee_amount"
          :value2="latest_setup_fee_amount"
        />
        <InputRow
          label="Broker Fee %"
          :value1="original_broker_fee_percent * 100"
          :value2="latest_broker_fee_percent * 100"
        />
        <InputRow
          label="Broker Fee $"
          :value1="original_broker_fee_amount"
          :value2="latest_broker_fee_amount"
        />
        <InputRow
          label="Valuation Fee $"
          :value1="
            originalData.calculated_fields
              ? originalData.calculated_fields.valuation_fee
              : 0
          "
          :value2="
            latestData.calculated_fields
              ? latestData.calculated_fields.valuation_fee
              : 0
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputRow from "../../InputRow.vue";
export default {
  components: { InputRow },
  computed: {
    ...mapGetters({
      originalData: "loans/originalData",
      latestData: "loans/latestData",
    }),
  },
  data() {
    return {
      // original amount
      original_setup_fee_amount: 0,
      original_broker_fee_amount: 0,
      // original percent
      original_broker_fee_percent: 0,
      original_setup_fee_percent: 0,
      // latest amount
      latest_setup_fee_amount: 0,
      latest_broker_fee_amount: 0,
      // latest percent
      latest_broker_fee_percent: 0,
      latest_setup_fee_percent: 0,
    };
  },
  created() {
    // original
    this.original_broker_fee_percent = this.originalData.calculated_fields
      .broker_fee
      ? this.originalData.calculated_fields.broker_fee
      : 0;
    this.original_setup_fee_percent = this.originalData.calculated_fields
      .setup_fee
      ? this.originalData.calculated_fields.setup_fee
      : 0;

    let setupAmount = this.originalData.calculated_fields.setup_fee
      ? this.originalData.calculated_fields.setup_fee
      : 0;
    let brokerAmount = this.originalData.calculated_fields.broker_fee
      ? this.originalData.calculated_fields.broker_fee
      : 0;
    let totalAmount = this.originalData.calculated_fields
      .initial_amount_financed
      ? this.originalData.calculated_fields.initial_amount_financed
      : 0;
    this.original_total_fee_percent = this.originalData.calculated_fields
      .qouted_setup_fee
      ? this.originalData.calculated_fields.qouted_setup_fee
      : 0;
    this.original_setup_fee_amount = setupAmount * totalAmount;
    this.original_broker_fee_amount = brokerAmount * totalAmount;

    // latest
    this.latest_broker_fee_percent = this.latestData.calculated_fields
      .broker_fee
      ? this.latestData.calculated_fields.broker_fee
      : 0;
    this.latest_setup_fee_percent = this.latestData.calculated_fields.setup_fee
      ? this.latestData.calculated_fields.setup_fee
      : 0;

    let setupAmount1 = this.latestData.calculated_fields.setup_fee
      ? this.latestData.calculated_fields.setup_fee
      : 0;
    let brokerAmount1 = this.latestData.calculated_fields.broker_fee
      ? this.latestData.calculated_fields.broker_fee
      : 0;
    let totalAmount1 = this.latestData.calculated_fields.initial_amount_financed
      ? this.latestData.calculated_fields.initial_amount_financed
      : 0;
    this.latest_total_fee_percent = this.latestData.calculated_fields
      ?.qouted_setup_fee
      ? this.latestData.calculated_fields?.qouted_setup_fee
      : 0;
    this.latest_setup_fee_amount = setupAmount1 * totalAmount1;
    this.latest_broker_fee_amount = brokerAmount1 * totalAmount1;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.header {
  ::v-deep .col {
    padding-bottom: 0;
  }
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}
</style>
