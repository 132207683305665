<template>
  <div>
    <div class="input-wrapper">
      <InputNumber
        :mode="mode"
        :minFractionDigits="minFractionDigits"
        :id="id"
        :inputId="id"
        class="input-field"
        :placeholder="placeholder"
        :format="format ? format : false"
        :max="max"
        :disabled="disable"
        :prefix="prefix"
        min="0"
        v-model="editValue"
        autocomplete="off"
        :key="refreshKey || key"
        :style="{
          height: `${height}`,
          width: `${width}`,
          border: `1px solid ${color} !important`,
          color: `${color}`,
        }"
      />
    </div>
  </div>
</template>

<script>
import InputNumber from "primevue/inputnumber";

export default {
  name: "TextField",
  props: [
    "icon",
    "placeholder",
    "label",
    "disable",
    "height",
    "width",
    "border",
    "color",
    "value",
    "required",
    "errorMessages",
    "key",
    "type",
    "disabled",
    "format",
    "max",
    "mode",
    "minFractionDigits",
    "prefix",
    "suffix",
    "maxValue",
    "id"
  ],
  data() {
    return {
      editValue: "",
      refreshKey: 10,
    };
  },
  components: { InputNumber },
  methods: {},

  watch: {
    value() {
      this.editValue = this.value;
      this.refreshKey += 1;
    },
  },
  mounted() {
    this.editValue = this.value;
    this.refreshKey += 1;
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  display: flex;
  align-items: center;
}
.input-field {
  border-color: unset;
  outline: 0 none;
  outline-offset: 0;
  box-shadow: unset !important;
  border-radius: unset !important;
}

.icons {
  font-weight: bold !important;
  height: 100%;
  font-size: 1.2rem;
  padding: 10px;
  min-width: 40px;
  // border-right: 1px solid #ced4da;
  border: 1px solid #ced4da;
  margin-top: 1rem;
}

.p-inputtext {
  border: 1px solid rgba(2, 0, 56, 0.15);
  border-radius: 4px !important;
  font-family: Gordita-medium !important;
}
::v-deep .p-inputtext {
  font-family: Gordita-medium !important;
  width: 100%;
}
// .p-inputtext:enabled:focus {
//   border-color: rgba(2, 0, 56, 0.15);
//   border: 1px solid rgba(2, 0, 56, 0.3);
//   box-shadow: unset;
// }
.p-inputtext:enabled:hover {
  border-color: rgba(2, 0, 56, 0.15);
  border: 1px solid rgba(100, 97, 170, 0.3);
  box-shadow: unset;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.label {
  color: #020038;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem rgba(2, 0, 56, 0.3) !important;
  border-color: rgba(2, 0, 56, 0.15);
}
</style>
