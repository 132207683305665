<template>
  <div class="pl-4 pr-4 pb-4">
    <Card class="box-shadow p-4">
      <template #title>
        <h3 class="module-sub-title">Documents</h3>
      </template>
      <template #content>
        <div>
          <Spinner v-if="isloading" class="flex justify-content-between" />

          <div v-else class="mt-5 border-box">
            <div class="flex justify-content-between">
              <span class="module-sub-title flex">Files</span>
              <div class="upload cursor-pointer" @click="uploadNew">
                + Upload New file
              </div>
            </div>
            <DataTable dataKey="id" :value="files.result">
              <template #empty> No data found. </template>

              <Column field="name" header="File name">
                <template #body="slotProps">
                  <a
                    class="color-highlight pointer"
                    @click="openFiles(slotProps.data.Id)"
                  >
                    {{ displayFileName(slotProps.data.document_name) }}
                  </a>
                </template>
              </Column>
              <Column field="taskID" header="Task">
                <template #body="slotProps">
                  {{
                    slotProps.data.taskID?.taskTitle
                      ? slotProps.data?.taskID?.taskTitle
                      : "-"
                  }}
                </template>
              </Column>
              <Column field="documentType" header="Document type">
                <template #body="slotProps">
                  {{ slotProps.data.documentType || "-" }}
                </template>
              </Column>
              <Column field="status" header="Status">
                <template #body="slotProps">
                  {{ slotProps.data?.status.replace("_", " ") || "-" }}
                </template>
              </Column>
              <Column field="notes" header="Notes">
                <template #body="slotProps">
                  {{ slotProps.data.notes || "-" }}
                </template>
              </Column>
              <Column field="total" header="Total">
                <template #body="slotProps">
                  {{
                    slotProps.data?.documents_files
                      ? slotProps.data?.documents_files.length
                      : "1"
                  }}
                </template>
              </Column>
              <Column field="created_at" header="Created">
                <template #body="slotProps">
                  {{ formatDate(slotProps.data.created_at) }}
                </template>
              </Column>
            </DataTable>
            <Paginator
              class="mt-2"
              :rows="10"
              :totalRecords="files && files.totalCount"
              :rowsPerPageOptions="[10, 20, 30]"
              template="RowsPerPageDropdown  CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
              currentPageReportTemplate="Showing {first} of {totalRecords} files"
              @page="onChangePageFile($event)"
            ></Paginator>
          </div>
        </div>
        <AddNewDoc
          v-if="uploadNewDoc"
          @save="refreshKey++"
          :show="uploadNewDoc"
          @close="uploadNewDoc = false"
        />
      </template>
    </Card>

    <Files
      @save="refreshKey++"
      :show="showFiles"
      @close="showFiles = false"
      :filesId="filesId"
      :modalRefreshKey="modalRefreshKey"
      portal="broker"
    />
  </div>
</template>

<script>
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import AddNewDoc from "./NewFileUpload.vue";
import Files from "./EditFile.vue";
import { _get } from "../../../../../services/crudService";
import { SERVER_URL } from "../../../../../../constants";
import Paginator from "primevue/paginator";
import moment from "moment";

export default {
  name: "Documents",
  components: {
    Card,
    DataTable,
    Column,
    AddNewDoc,
    Files,
    Paginator,
  },
  data() {
    return {
      uploadNewDoc: false,
      showSupportingDoc: false,
      showFiles: false,
      refreshKey: 10,
      isloading: false,
      statements: [],
      files: [],
      isFileLoading: false,
      filesId: "",
      modalRefreshKey: 10,
    };
  },
  watch: {
    refreshKey() {
      this.isloading = true;
      this.getFiles();
    },
  },
  methods: {
    displayFileName(name) {
      if (!name) return "-";
      var str = name;
      if (str.includes("/")) {
        if (str.length) str = /\/(.+)/.exec(str)[1];
      }
      return str;
    },
    formatDate(date) {
      if (date) {
        date = new moment(date).add(600, "m").toDate();
        return moment(date).format("DD/MM/YYYY hh:mm a");
      } else return "-";
    },
    uploadNew() {
      this.uploadNewDoc = true;
    },
    openSupportingDoc() {
      return (this.showSupportingDoc = true);
    },
    openFiles(value) {
      this.modalRefreshKey += 1;
      this.filesId = value;
      this.showFiles = true;
    },

    async onChangePageFile(value) {
      this.isFileLoading = true;
      await this.getFiles(value.page + 1, value.rows);
      this.isFileLoading = false;
    },

    async getFiles(page = 1, row = 10) {
      try {
        this.isloading = true;
        const response = await _get(
          SERVER_URL +
            `get-all-documents-api?portal=broker&applicationID=${this.$route.params.id}&pageSize=${row}&pageIndex=${page}&type=file`
        );

        if (response) {
          this.isloading = false;

          this.files = response.data;
        }
      } catch (error) {
        this.isloading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },
  created() {
    this.isloading = true;
    this.getFiles();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.p-card {
  box-shadow: unset !important;
  border-radius: 6px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  height: 100% !important;
}
::v-deep .p-card-title {
  color: #020038 !important;
}
::v-deep .p-card-content {
  text-align: center !important;
  font-size: 14px !important;
  color: #020038 !important;
  font-weight: 500;
  font-size: 14px;
}
::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}

.color-highlight {
  color: $primary-color !important;
}
.upload {
  font-weight: 600;
  font-size: 16px;
  color: rgba(2, 0, 56, 0.4);
}
</style>
