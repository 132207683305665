<template>
  <div>
    <label class="p-float-label hk-grotest-medium"
      >{{ label }}<span v-if="required" class="required"></span
    ></label>
    <div class="input-wrapper" :style="{ width: width }">
      <span v-if="icon" class="icon-container"
        ><img width="10" height="10" :src="icon" class="icons field"
      /></span>
      <InputNumber
        :mode="mode"
        :minFractionDigits="minFractionDigits"
        id="integeronly"
        v-if="type === 'numberDecimal'"
        class="input-field"
        :placeholder="placeholder"
        :format="format ? format : false"
        :max="max"
        min="0"
        v-model="editValue"
        @input="onNumberInput"
        autocomplete="off"
        :key="refreshKey"
        :disabled="disabled"
      />

      <InputNumber
        :mode="mode"
        v-else-if="type === 'number'"
        id="integeronly"
        class="input-field"
        :placeholder="placeholder"
        :format="true"
        :max="max"
        min="0"
        v-model="editValue"
        @input="onNumberInput"
        :key="refreshKey"
        :disabled="disabled"
        :minFractionDigits="minFractionDigits"
        :maxFractionDigits="maxFractionDigits"
        @keydown="keydown"
        autocomplete="off"
      />
      <InputText
        v-else
        v-model="editValue"
        class="input-field"
        @input="onInput"
        :placeholder="placeholder"
        :type="type"
        :disabled="disabled"
        min="0"
        :style="{
          width: '100%',
          height: `${height}`,
          border: `1px solid ${color} !important`,
          color: `${color}`,
        }"
        autocomplete="off"
      />
    </div>
    <label v-if="errorMessages" class="error-text required bold-400">{{
      errorMessages
    }}</label>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";

export default {
  name: "TextField",
  props: [
    "icon",
    "placeholder",
    "label",
    "height",
    "width",
    "border",
    "color",
    "value",
    "required",
    "errorMessages",
    "type",
    "disabled",
    "format",
    "max",
    "mode",
    "minFractionDigits",
    "maxValue",
    "locale",
    "maxFractionDigits",
    "maxDigit",
  ],
  data() {
    return {
      editValue: "",
      refreshKey: 10,
    };
  },
  components: { InputText, InputNumber },
  methods: {
    onInput({ target }) {
      this.$emit("onInput", target.value);
    },
    onNumberInput(value) {
      let maxValue = this.maxValue ? this.maxValue : 999999999;
      if (value.value > maxValue && !this.minFractionDigits) {
        this.$emit("onInput", this.editValue);
        this.refreshKey += 1;
        return;
      }
      if (!this.minFractionDigits) {
        this.editValue = value.value;
      }
      this.$emit("onInput", value.value);
    },
    keydown(e) {
      let max = this.maxDigit ? this.maxDigit : 8;
      if (this.editValue && this.editValue.toString().length > max) {
        e.preventDefault();
        return false;
      }
    },
  },
  watch: {
    editValue() {
      this.$emit("change", this.editValue);
    },
  },
  mounted() {
    this.editValue = this.value;
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  display: flex;
  align-items: center;
}
.input-field {
  border-color: unset;
  outline: 0 none;
  width: 100%;
  outline-offset: 0;
  box-shadow: unset !important;
  border-radius: unset !important;
}

.icons {
  font-weight: bold !important;
  height: 42px;
  font-size: 1.2rem;
  padding: 10px;
  min-width: 40px;
  border: 1px solid #ced4da;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-bottom: 11px;
  background: #ffffff;
}

::v-deep .field {
  margin-bottom: unset !important;
}

::v-deep .p-inputtext {
  border: 1px solid rgba(2, 0, 56, 0.15);
  border-radius: 4px !important;
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
  height: 42px;
  width: 100%;
}
::v-deep .p-inputtext:enabled:focus {
  border-color: rgba(2, 0, 56, 0.15);
  border: 1px solid rgba(100, 97, 170, 0.3);
  box-shadow: unset;
}
::v-deep .p-inputtext:enabled:hover {
  border-color: rgba(2, 0, 56, 0.15);
  border: 1px solid rgba(100, 97, 170, 0.3);
  box-shadow: unset;
}
::v-deep .p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem rgba(2, 0, 56, 0.3) !important;
  border-color: rgba(2, 0, 56, 0.15);
}
.required {
  color: red;
}
.error-text {
  font-size: 14px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
