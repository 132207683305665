<template>
	<div :class="fullWidth && 'full-width'">
		<!-- {{ value }} -->
		<Dropdown
			v-model="selectedValue"
			:options="items"
			:optionLabel="optionLabel"
			:placeholder="placeholder"
			:style="{ width: `${width}`, height: `${height}`, maxWidth: maxWidth }"
			@change="onSelectChange"
			:disabled="disable"
			:optionValue="optionValue"
			:class="inValid && 'p-invalid'"
			:inputId="id"
      :id="id"
		/>
	</div>
</template>

<script>
import Dropdown from "primevue/dropdown";

export default {
	name: "Select",
	components: {
		Dropdown,
	},
	props: [
		"width",
		"height",
		"items",
		"value",
		"placeholder",
		"optionLabel",
		"errorMessages",
		"required",
		"label",
		"disable",
		"fullWidth",
		"optionValue",
		"inValid",
		"maxWidth",
		"id",
	],
	data() {
		return {
			selectedValue: "",
		};
	},
	methods: {
		onSelectChange() {
			this.$emit("change", this.selectedValue);
		},
	},
	created() {
		this.selectedValue = this.value;
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.p-dropdown:not(.p-disabled):hover {
	border: 1px solid rgba(2, 0, 56, 0.15);
}
.p-dropdown:not(.p-disabled).p-focus {
	border: 1px solid rgba(2, 0, 56, 0.15);
	box-shadow: none !important;
}
.p-dropdown {
	border: 1px solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-dropdown-item {
	font-family: Gordita-medium !important;
}
::v-deep .p-dropdown-panel .p-dropdown-items {
	font-family: Gordita-medium;
}
</style>
