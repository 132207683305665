<template>
	<div class="std-padding-x mr-b">
		<Panel class="box" :toggleable="true" :collapsed="collapsed">
			<template #icons>
				<div @click="toggle" class="icon-wrapper">
					<span v-if="collapsed" class="icon pi pi-angle-down"></span>
					<span v-else class="icon pi pi-angle-up"></span>
				</div>
			</template>
			<template #header>
				<div
					@click="toggle"
					class="title-wrapper flex align-items-center full-width pointer"
				>
					<img src="@/assets/property.svg" alt="" />
					<p class="title">Property Details</p>
					<Button
						class="ml-3"
						label="Run Pricefinder"
						@click.stop="runPropertyPricefinder"
						type="primary"
						:loading="isLoading"
					/>
				</div>
			</template>

			<IncomingProperties :key="propertyRefreshKey" />
			<OutgoingProperties :refreshComponent="refreshKey" />
			<Rates />
			<ReviewForm title="Property Notes" />
			<Footer />
		</Panel>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Panel from "primevue/panel";
import IncomingProperties from "./IncomingProperties.vue";
import OutgoingProperties from "./OutgoingProperties.vue";
import Rates from "./Rates.vue";
import ReviewForm from "./ReviewForm.vue";
import Footer from "./Footer";

export default {
	components: {
		Panel,
		IncomingProperties,
		OutgoingProperties,
		Rates,
		ReviewForm,
		Footer,
	},
	data() {
		return {
			collapsed: true,
			isLoading: false,
			propertyRefreshKey: 10,
			refreshKey: 0,
		};
	},
	computed: {
		...mapState({
			buyingPropertyUpdated: (state) => state.applications.buyingPropertyUpdated,
			sellingPropertyUpdated: (state) => state.applications.sellingPropertyUpdated,
		}),
	},
	methods: {
		...mapActions({
			runPriceFinder: "applications/runPriceFinder",
			setBuyingPropertyUpdated: "applications/setBuyingPropertyUpdated",
			setSellingPropertyUpdated: "applications/setSellingPropertyUpdated",
		}),
		toggle() {
			this.collapsed = !this.collapsed;
		},
		async runPropertyPricefinder() {
			this.isLoading = true;
			try {
				await this.runPriceFinder();
				this.setBuyingPropertyUpdated(this.buyingPropertyUpdated + 1);
				this.setSellingPropertyUpdated(this.sellingPropertyUpdated + 1);
				this.refreshKey += 1;
				this.propertyRefreshKey += 1;

				this.isLoading = false;
				this.$toast.add({
					severity: "success",
					summary: "Success",
					detail: "Property Pricefinder - Success",
					life: 3000,
				});
			} catch (err) {
				this.isLoading = false;
				this.$toast.add({
					severity: "error",
					summary: "Error",
					detail: "Oops! Error in Pricefinder",
					life: 3000,
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

::v-deep .p-panel .p-panel-content {
	padding-left: 0;
	padding-right: 0;
}

::v-deep .p-panel-header {
	background: unset !important;
	border: none !important;
	border-bottom: 1px solid $secondary-color !important;
	padding: 0 !important;
	padding-bottom: 10px !important;
}

::v-deep .p-panel .p-panel-content {
	border: none !important;
}

::v-deep .p-panel .p-panel-header .p-panel-header-icon {
	display: none !important;
}

.icon-wrapper {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 2px solid rgba(2, 0, 56, 0.4);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.std-padding-x {
	padding: 0 2.188rem;
}

.flex-gap {
	gap: 0.625rem;
}

.title {
	font-size: 2rem;
	font-family: Gordita-bold;
	margin-left: 0.625rem;
	color: $secondary-color;
}

.box {
	background: #ffffff;
	border: 1px solid rgba(2, 0, 56, 0.4);
	box-sizing: border-box;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	padding: 1.25rem;
}

p {
	margin: 0;
}
</style>
