<template>
  <div class="title-wrapper">
    <div class="flex flex-gap align-items-center">
      <div v-for="(item, i) in list" :key="i">
        <div
          :class="item.isActive ? 'title-active' : 'lt-txt-color'"
          class="title"
          @click="onSelectItem(i)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplicationHeader",
  data() {
    return {
      list: [
        { title: "Users", isActive: true },
        { title: "Groups", isActive: false },
        { title: "Policy", isActive: false },
      ],
    };
  },
  methods: {
    onSelectItem(i) {
      this.list.map((obj) => {
        obj.isActive = false;
      });
      this.list[i].isActive = true;
      switch (i) {
        case 0:
          this.$router.push({
            name: "UsersComponent",
          });
          break;
        case 1:
          this.$router.push({ name: "GroupComponent" });
          break;
        case 2:
          this.$router.push({ name: "PolicyComponent" });
          break;
      }
    },
    routeNavigation() {
      switch (this.$route.name) {
        case "UsersComponent":
          this.onSelectItem(0);
          break;
        case "GroupComponent":
          this.onSelectItem(1);
          break;
        case "PolicyComponent":
          this.onSelectItem(2);
          break;
      }
    },
  },
  watch: {
    $route() {
      this.routeNavigation();
    },
  },
  created() {
    this.routeNavigation();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

@import "@/styles/global.scss";

::v-deep .p-card .p-card-body {
  padding: 0;
}

p {
  margin: 0;
}

.flex-gap {
  gap: 2.3rem;
}

.mr-b {
  margin-bottom: 1.875rem;
}

.title-wrapper {
  border-bottom: 1px solid #02003826;
  padding: 0 1rem;

  .title {
    font-size: 1.25rem;
    padding: 1.25rem 0;
    color: $light-text-color;
    font-family: Gordita-medium-;
    margin: 0;
    cursor: pointer;
  }

  .title-active {
    border-bottom: 3.5px solid $primary-color;
    color: $primary-color;
  }
}
.p-card {
  box-shadow: none;
}
.fs-18px {
  font-size: 1.125rem;
}
</style>
