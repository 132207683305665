<template>
  <div class="p-grid align-items-center">
    <div class="col-3 flex justify-content-end">
      <p class="flex align-items-center mr-2 justify-content-center label">
        {{ label }}
      </p>
      <div v-if="!removeIcon">
        <img
          v-if="iconDisabled"
          :src="require(`@/assets/NoRelevant.svg`)"
          alt=""
        />
        <img
          v-else
          class="pointer"
          @click="downloadFile"
          :src="require(`@/assets/Completed.svg`)"
          alt=""
        />
      </div>

      <!-- <img src="@/assets/NoRelevant.svg" alt="" Completed/> -->
    </div>

    <div
      class="col-2 flex align-items-center"
      :class="disable ? 'disable-input' : ''"
    >
      <TextField
        class="full-width"
        :inputClass="border"
        :disable="disable"
        width="100%"
        :placeholder="placeholder"
      />
    </div>
    <div class="col-2 flex align-items-center">
      <p class="col-text lt-txt-color">{{ lastRun }}</p>
    </div>
    <div
      v-if="placeholder !== 'Failed'"
      class="col relative flex align-items-center"
    >
      <Button
        @click="runIntegration(label)"
        label="Run"
        :type="buttonType"
        :loading="isloading"
      />
    </div>
    <div
      v-if="placeholder === 'Failed'"
      class="col relative flex align-items-center"
    >
      <Button
        @click="runIntegration(label)"
        label="Rerun"
        :type="buttonType"
        :loading="isloading"
      />
    </div>
    <div class="col-4">
      <p class="error-text">
        {{ errorMsg }}
      </p>
    </div>
  </div>
</template>


<script>
import { SERVER_URL } from "../../../../../../constants";
import { _get } from "../../../../../services/crudService";
import Fileupload from "../../ApplicationData/Document/Fileupload.vue";

export default {
  props: [
    "errorMsg",
    "lastRun",
    "placeholder",
    "icon",
    "border",
    "label",
    "disable",
    "btnType",
    "buttonType",
    "removeIcon",
  ],
  data() {
    return {
      iconDisabled: true,
      isloading: false,
      files: [],
    };
  },
  components: [Fileupload],
  methods: {
    async runIntegration(label) {
      this.isloading = true;
      this.$emit("runIntegration", label);
    },
    async downloadFile() {
      let documents_files = this.files.map(
        (ele) => ele?.documents_files[0]?.document_url
      );

      for await (const file of documents_files) {
        this.downloadDocument(file);
      }
    },
    async downloadDocument(fileName) {
      try {
        const response = await _get(
          SERVER_URL + `get-documents-api?fileName=${fileName}`
        );
        if (response) {
          this.isloading = false;
          const docName = fileName.split(".");
          var a = document.createElement("a");
          a.href =
            "data:image/" + docName[1] + ";base64," + response.data.content; //Image Base64 Goes here
          a.download = fileName;
          a.click();
        }
      } catch (error) {
        this.isloading = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
  },
  async created() {
    try {
      if (this.$props.label === "Credit Check") {
        const response = await _get(
          SERVER_URL + `get-credit-documents?id=${this.$route.params.id}`
        );

        if (response) {
          if (response.data.files.length >= 1) {
            this.iconDisabled = false;
            this.files = response.data.files;
          }
        }
      }

      if (this.$props.label === "Title enquiry status") {
        const response = await _get(
          SERVER_URL + `get-title-documents?id=${this.$route.params.id}`
        );

        if (response) {
          if (response.data.files.length >= 1) {
            this.iconDisabled = false;
            this.files = response.data.files;
          }
        }
      }

      if (this.$props.label === "Pricefinder") {
        const response = await _get(
          SERVER_URL + `get-pricefinder-documents?id=${this.$route.params.id}`
        );

        if (response) {
          if (response.data.files.length >= 1) {
            this.iconDisabled = false;
            this.files = response.data.files;
          }
        }
      }

      if (this.$props.label === "Aply Id") {
        const response = await _get(
          SERVER_URL + `get-aplyid-documents?id=${this.$route.params.id}`
        );

        if (response) {
          if (response.data.files.length >= 1) {
            this.iconDisabled = false;
            this.files = response.data.files;
          }
        }
      }
    } catch (error) {
      this.iconDisabled = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
p {
  margin: 0;
}

.error-text,
.col-text {
  font-size: 14px;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .col {
  padding-left: 0;
  padding-right: 10px !important;
}

.relative {
  position: relative;
}

.dot {
  position: absolute;
  right: 25px;
}

.disable-input :deep .input-field {
  background: rgba(84, 84, 105, 0.05) !important;
}

.header {
  ::v-deep .col-2 {
    padding-bottom: 10px;
  }
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium-;
  margin: 0;
}
</style>
