<template>
  <Dialog
    :draggable="false"
    :visible="show"
    :modal="show"
    :style="{ width: '580px' }"
  >
    <template #header>
      <h1 class="modal-title">Request Income and Expenses</h1>
    </template>
    <div class="modal-content">
      <p class="text">
        Select which applicant you would like to send the income and expenses
        link to, or select both to send the link to both applicants.
      </p>
      <div class="checkbox mt-2">
        <div
          v-for="list of customerList"
          :key="list.id"
          class="
            flex
            align-items-center
            checkbox-list
            justify-content-between
            mb-2
          "
        >
          <div class="flex fg-10 align-items-center">
            <Checkbox2
              :id="list"
              :name="list.Id"
              :value="list"
              @getInputValue="(e) => selectCustomer(e, list.Id)"
            />
            <label :for="list.Id"
              >{{ list.first_name }} {{ list.last_name }}</label
            >
          </div>
          <p class="color-highlight">{{ list.Id }}</p>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button label="Send link" type="primary" @click="save" />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">
          Close
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Dialog from "primevue/dialog";
import Checkbox2 from "@/components/common/Checkbox2";

export default {
  name: "AddTask",
  props: ["show"],
  components: { Dialog, Checkbox2 },
  data() {
    return {
      selectedCustomer: [],
      customerList: [],
    };
  },
  computed: {
    ...mapGetters({ selectedApplication: "applications/selectedApplication" }),
  },
  methods: {
    ...mapActions({
      getApplicantsFromApplication: "applications/getApplicantsFromApplication",
      sendApplicantMail: "applications/sendApplicantMail",
    }),
    selectCustomer(value, applicant) {
      this.selectedCustomer = !value
        ? this.selectedCustomer.filter((item) => item === applicant)
        : [...this.selectedCustomer, applicant];
    },
    async save() {
      let applicants = [];
      this.customerList.map((customer) => {
        this.selectedCustomer.map((selectedCustomer) => {
          let newObj = {};
          if (customer.Id == selectedCustomer) {
            newObj["email"] = customer.email;
            newObj["applicantionId"] = this.$route.params.id;
            newObj["userType"] = customer.applicant_type;
            applicants.push(newObj);
          }
        });
      });
      if (applicants.length == 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select atleast one Applicant",
          life: 3000,
        });
      }
      if (applicants.length > 0) {
        try {
          await this.sendApplicantMail(applicants);
          this.$emit("close");

          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Request mail sent successfully",
            life: 3000,
          });
        } catch (err) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Error in sending mail to Applicant",
            life: 3000,
          });
        }
      }
    },
    close() {
      this.$emit("close");
    },
    async getData() {
      try {
        let applicants = await this.getApplicantsFromApplication(
          this.$route.params.id
        );
        applicants.map((item) => {
          item.label = item.first_name;
          item.name = item.first_name;
          item.id = item.Id;
        });
        this.customerList = applicants;
      } catch (err) {
        this.customerList = [];
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.checkbox-list {
  border: 1px solid rgba(2, 0, 56, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
}

.color-highlight {
  color: $secondary-color;
  font-family: Gordita-medium;
  font-size: 14px;
}

.modal-title {
  font-family: Gordita-bold;
  font-size: 20px;
  color: $secondary-color;
}

.text,
label {
  font-family: Gordita-medium-;
  font-size: 14px;
  color: $secondary-color;
}

.color-highlight {
  color: $primary-color;
  text-decoration: underline;
}

p {
  margin: 0;
}

.fg-10 {
  gap: 10px;
}
</style>
