<template>
  <Toolbar>
    <template #left>
      <img src="@/assets/bridgit.svg" />
    </template>

    <template #right>
      <div class="right-col p-d-flex p-ai-center">
        <div v-click-outside="clickOutsideSearch" class="global-search">
          <div class="d-flex align-items-center">
            <div class="p-input-icon-left search-input-wrapper">
              <i class="pi pi-search" />
              <InputText
                type="text"
                v-model="searchValue"
                placeholder="Search"
                @input="getSearchValue"
              />
            </div>
            <div v-if="loadingSearch" class="search-spinner mt-1">
              <ProgressSpinner style="width: 20px; height: 20px" />
            </div>
          </div>
          <div
            v-if="showGlobalSearch && globalSearchList.length > 0"
            class="global-search-list"
          >
            <div v-if="showTask" class="global-search-container">
              <span class="search-type">Tasks</span>
              <div v-for="result of globalSearchList" :key="result.id">
                <span
                  v-if="result.type === 'task'"
                  class="search-values pointer"
                  @click="redirect(result.id, result.type)"
                  >{{ result.name }}</span
                >
              </div>
              <span @click="navigatePage('task')" class="show-more-option"
                >+ Show more task</span
              >
            </div>
            <div v-if="showLoans" class="global-search-container">
              <span class="search-type">Loans</span>
              <div v-for="result of globalSearchList" :key="result.id">
                <span
                  v-if="result.type === 'loans'"
                  class="search-values pointer"
                  @click="redirect(result.id, result.type, result.loan_id)"
                  >{{ result.name }}</span
                >
              </div>
              <span @click="navigatePage('loans')" class="show-more-option"
                >+ Show more loans</span
              >
            </div>
            <div v-if="showCustomers" class="global-search-container">
              <span class="search-type">Customers</span>
              <div v-for="result of globalSearchList" :key="result.id">
                <span
                  v-if="result.type === 'customers'"
                  class="search-values pointer"
                  @click="redirect(result.id, result.type)"
                  >{{ result.name }}</span
                >
              </div>
              <span @click="navigatePage('customers')" class="show-more-option"
                >+ Show more customer</span
              >
            </div>
            <div v-if="showBrokers" class="global-search-container">
              <span class="search-type">Brokers</span>
              <div v-for="result of globalSearchList" :key="result.id">
                <span
                  v-if="result.type === 'brokers'"
                  class="search-values pointer"
                  @click="redirect(result.id, result.type)"
                  >{{ result.name }}</span
                >
              </div>
              <span @click="navigatePage('brokers')" class="show-more-option"
                >+ Show more brokers</span
              >
            </div>
          </div>
        </div>
        <div v-click-outside="clickOutside" class="notifications">
          <div
            @click="toggleNotification"
            class="bell-icon pointer icon-wrapper"
          >
            <img v-if="!showNotifyIcon" src="@/assets/bell.svg" />
            <img v-if="showNotifyIcon" src="@/assets/bell-notification.svg" />
          </div>

          <div v-if="showNotification" class="notification-list">
            <div class="title hk-sb"><p>Notifications</p></div>
            <div v-for="notify of notificationList" :key="notify.user_id">
              <div :class="notify.read ? 'list' : 'list active'">
                <p>{{ notify.message }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="p-d-flex p-ai-center user-avatar-name-wrapper">
          <div v-click-outside="clickOutsideLogout" class="user-name">
            <h3 @click="toggleLogout" class="name pointer">
              {{ this.username || "CRM" }}
            </h3>
            <div @click="signout" v-if="showLogout" class="logout">
              <p class="hk-sb">Logout</p>
            </div>
          </div>
          <div class="drop-down-menu">
            <div @click="toggleDropdownMenu" class="icon-wrapper">
              <img src="@/assets/settings.svg" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Toolbar>
</template>

<script>
import Toolbar from "primevue/toolbar";
import InputText from "primevue/inputtext";
import { mapActions, mapGetters } from "vuex";
import { SERVER_URL } from "../../../constants";
import { _get, _put } from "../../services/crudService";
import ProgressSpinner from "primevue/progressspinner";

export default {
  name: "ToolbarComponent",
  components: {
    Toolbar,
    InputText,
    ProgressSpinner,
  },
  data() {
    return {
      loadingSearch: false,
      showNotification: true,
      notificationList: [],
      updateNotification: [],
      showNotifyIcon: false,
      showGlobalSearch: false,
      globalSearchList: [],
      updateSearch: null,
      showTask: false,
      showLoans: false,
      showCustomers: false,
      showBrokers: false,
      searchValue: "",
      showLogout: false,
    };
  },
  computed: {
    ...mapGetters({
      username: "auth/username",
    }),
  },
  methods: {
    ...mapActions({
      setLogin: "auth/setLogin",
      setLogout: "auth/setLogout",
    }),
    redirect(id, type, loan_id) {
      switch (type) {
        case "task":
          this.$router.push("/tasks");
          this.showGlobalSearch = false;
          this.searchValue = "";
          break;
        case "loans":
          this.$router.push(`/loan/${id}/overview?loanId=${loan_id}`);
          this.showGlobalSearch = false;
          this.searchValue = "";
          break;
        case "customers":
          this.$router.push(`/customers/${id}/detail`);
          this.showGlobalSearch = false;
          this.searchValue = "";
          break;
        case "brokers":
          this.$router.push(`/brokers/${id}/detail`);
          this.showGlobalSearch = false;
          this.searchValue = "";
          break;
      }
    },
    toggleDropdownMenu() {
      this.$router.push("/settings/account");
    },
    toggleLogout() {
      this.showLogout = !this.showLogout;
    },
    signout() {
      this.setLogout(false);
      this.$router.push("/login");
      this.showLogout = false;
    },
    async toggleNotification() {
      this.showNotification = !this.showNotification;
      try {
        this.isLoading = true;
        const response = await _get(SERVER_URL + `get_notification_crm`);
        if (response) {
          this.notificationList = response.data.result;
          this.notificationList &&
            this.notificationList.map((data) => {
              if (data.read === false) {
                this.showNotifyIcon = true;
              }
            });
          this.isLoading = false;
        }
      } catch (error) {
        this.isloading = false;
        console.log(error);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    async clickOutside() {
      if (this.showNotification === true) {
        this.showNotification = false;
        try {
          this.isLoading = true;
          this.updateNotification = this.notificationList.map((list) => {
            return list.Id;
          });
          const data = {
            id: this.updateNotification,
          };
          const response = await _put(
            SERVER_URL + `update_crm_notification`,
            data
          );
          if (response) {
            this.showNotifyIcon = false;
            this.isLoading = false;
          }
        } catch (error) {
          this.isloading = false;
          console.log(error);

          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }
    },
    clickOutsideLogout() {
      this.showLogout = false;
    },
    async getSearchValue({ target }) {
      this.globalSearchList = [];
      this.showTask = false;
      this.showLoans = false;
      this.showCustomers = false;
      this.showBrokers = false;
      this.showGlobalSearch = false;
      clearTimeout(this.updateSearch);
      this.updateSearch = setTimeout(async () => {
        try {
          if (target.value !== "") {
            this.loadingSearch = true;
            const response = await _get(
              SERVER_URL + `global-search?value=${target.value}`
            );
            if (response) {
              this.showGlobalSearch = true;
              this.globalSearchList = response.data.data;
              this.globalSearchList.map((data) => {
                if (data.type === "brokers") {
                  this.showBrokers = true;
                }
                if (data.type === "loans") {
                  this.showLoans = true;
                }

                if (data.type === "task") {
                  this.showTask = true;
                }
                if (data.type === "customers") {
                  this.showCustomers = true;
                }
              });
              this.loadingSearch = false;
            }
          } else {
            return (this.loadingSearch = false);
          }
        } catch (error) {
          this.showGlobalSearch = false;
          this.loadingSearch = false;
          console.log(error);

          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }, 800);
    },
    clickOutsideSearch() {
      this.showGlobalSearch = false;
    },
    navigatePage(event) {
      this.searchValue = "";
      this.showGlobalSearch = false;
      if (event === "brokers") {
        this.$router.push("/brokers");
      } else if (event === "loans") {
        this.$router.push("/loan");
      } else if (event === "task") {
        this.$router.push("/tasks");
      } else if (event === "customers") {
        this.$router.push("/customers");
      }
    },
  },
  mounted() {
    this.toggleNotification();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.p-toolbar {
  background: $white-color;
  border: unset !important;
  padding: 0;
  padding: 0 1rem;
  height: 80px;
}

.global-search {
  position: relative;
  .global-search-list {
    position: absolute;
    top: 3rem;
    right: 0;
    width: 300px;
    max-height: 500px;
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 1rem 1rem;
    z-index: 99;
    height: 300px;
    overflow: auto;

    .global-search-container {
      display: flex;
      flex-direction: column;
    }

    .search-type {
      font-family: Gordita;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: #020038;
      padding: 10px 10px 5px 10px;
      text-transform: capitalize;
      border-bottom: 1px solid rgba(2, 0, 56, 0.15);
    }
    .show-more-option {
      font-family: Gordita;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-decoration-line: underline;
      color: $primary-color;
      padding: 5px 10px 10px 10px;
      cursor: pointer;
    }
    .search-values {
      font-family: Gordita;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: rgba(2, 0, 56, 0.4);
      padding: 10px;
    }

    &:after {
      content: "";
      position: absolute;
      display: block;
      top: -13px;
      right: 1%;
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      border-bottom: 12px solid $white-color;
      font-size: 0;
      line-height: 0;
      width: 0;
    }

    p {
      margin: 0;
    }

    .title {
      border-bottom: 1px solid rgba(2, 0, 56, 0.15);
      font-size: 1rem;
      padding: 0 1rem;
      padding-bottom: 7px;
      margin-bottom: 10px;
    }

    .list {
      margin: 0.3rem 0;
      cursor: default;

      p {
        padding: 0 1rem;
      }
    }

    .active {
      background: #4aec960e;
      padding: 5px 0;
    }
  }
}

.notifications {
  position: relative;

  .notification-list {
    position: absolute;
    top: 3rem;
    overflow: auto;
    right: 0;
    width: 300px;
    max-height: 500px;
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 1rem 0;
    z-index: 99;
    overflow: auto;

    &:after {
      content: "";
      position: absolute;
      display: block;
      top: -13px;
      right: 1%;
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      border-bottom: 12px solid $white-color;
      font-size: 0;
      line-height: 0;
      width: 0;
    }

    p {
      margin: 0;
    }

    .title {
      border-bottom: 1px solid rgba(2, 0, 56, 0.15);
      font-size: 1rem;
      padding: 0 1rem;
      padding-bottom: 7px;
      margin-bottom: 10px;
    }

    .list {
      margin: 0.3rem 0;
      cursor: default;

      p {
        padding: 0 1rem;
      }
    }

    .active {
      background: #4aec960e;
      padding: 5px 0;
    }
  }
}

.right-col {
  margin: 0 !important;
  padding: 0 !important;

  gap: 20px;

  .bell-icon {
    position: relative;
    .icon {
      font-size: 1.5rem;

      &:after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: green;
        border-radius: 50%;
        right: 0;
        top: 2px;
      }
    }
  }

  .user-avatar-name-wrapper {
    border-left: 1px solid rgba(2, 0, 56, 0.3);
    padding-left: 20px;
    gap: 1rem;
    position: relative;
    height: 30px;

    .user-name {
      .name {
        margin: 0;
        font-weight: 600;
        font-size: 18px;
        color: #020038;
      }
    }

    .drop-down-menu {
      .icon {
        width: 25px;
        height: 25px;
        border: 1px solid $secondary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        color: $secondary-color;
      }

      .menu-items {
        position: absolute;
        top: 4rem;
        right: 0;
        background-color: $white-color;
        width: 230px;
        padding: 1rem 1rem;
        border-radius: 5px;
        box-shadow: 0px 2px 3px #686868;
        z-index: 99;
        border: none;
        user-select: none;

        &:after {
          content: "";
          position: absolute;
          display: block;
          top: -13px;
          right: 4%;
          border-right: 12px solid transparent;
          border-left: 12px solid transparent;
          border-bottom: 12px solid $white-color;
          font-size: 0;
          line-height: 0;
          width: 0;
        }

        .item-list {
          cursor: pointer;

          .text {
            font-size: 1.05rem;
            margin: 0;
            margin: 0.5rem 0;
          }
        }
      }
    }
  }

  .user-name {
    position: relative;

    p {
      margin: 0;
      font-size: 1rem;
      text-align: center;
    }

    .logout {
      cursor: pointer;
      position: absolute;
      top: 4rem;
      right: 0;
      background-color: $white-color;
      width: 100px;
      padding: 0.6rem 0.3rem;
      box-shadow: 0px 2px 3px #686868;
      z-index: 99;
      border: none;
      border-radius: 4px;
      user-select: none;

      &:after {
        content: "";
        position: absolute;
        display: block;
        top: -13px;
        right: 4%;
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
        border-bottom: 12px solid $white-color;
        font-size: 0;
        line-height: 0;
        width: 0;
      }
    }
  }

  .search-input-wrapper {
    position: relative;
  }
  .search-spinner {
    position: absolute;
    right: 1rem;
  }
}
</style>
