<template>
  <div>
    <div class="sub-title">
      <p>Credit/Store Cards</p>
    </div>

    <div class="form-inputs mt-4">
      <div class="p-grid align-items-center header">
        <p class="col label"></p>
        <p class="col label">Application Value</p>
        <p class="col label">Verified Value</p>
        <p class="col label">Manual Value</p>
        <p class="col label">Used Value</p>
      </div>
      <div v-for="(item, i) in details" :key="i">
        <!-- Col-1 -->
        <div
          v-if="item && !item.closeLiability"
          class="p-grid align-items-center"
        >
          <div class="col flex align-items-end justify-content-end">
            <Checkbox2
              :id="item"
              :name="item.institution"
              :value="item"
              @getInputValue="(e) => selectCards(e, item)"
              class="p-col-4"
            />
            <p class="label">{{ item.institution }} - Credit Limit on card</p>
          </div>

          <div
            class="
              col
              relative
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <NumberField
              prefix="$"
              :fullWidth="true"
              width="100%"
              :value="item.credit_limit"
              :disable="true"
            />
            <div class="orange-dot dot"></div>
          </div>
          <div
            class="
              col
              relative
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <NumberField
              prefix="$"
              :disable="true"
              :fullWidth="true"
              width="100%"
              :value="item.credit_limit_verified"
            />
            <div class="green-dot dot"></div>
          </div>
          <div
            class="col relative flex align-items-center justify-content-center"
          >
            <NumberField
              :fullWidth="true"
              width="100%"
              prefix="$"
              type="number"
              :value="item.credit_limit_manual"
              @onInput="(e) => getFormData(e, 'credit_limit_manual', i)"
            />
            <div class="blue-dot dot"></div>
          </div>
          <div
            class="col relative flex align-items-center justify-content-center"
          >
            <Dropdown
              class="full-width"
              optionLabel="name"
              optionValue="value"
              :items="usedValueOpts"
              :value="item.credit_limit_used_value"
              @change="(e) => getFormData(e, 'credit_limit_used_value', i)"
              width="100%"
              placeholder="label"
            />
            <div
              :class="{
                'orange-dot':
                  item.credit_limit_used_value === 'application_value',
                'green-dot': item.credit_limit_used_value === 'verified_value',
                'blue-dot': item.credit_limit_used_value === 'manual_value',
              }"
              class="dot dot-position"
            ></div>
          </div>
        </div>

        <!-- Col-2 -->
        <div
          v-if="item && !item.closeLiability"
          class="p-grid align-items-center"
        >
          <div class="col flex align-items-end justify-content-end">
            <p class="p-col-8 flex justify-content-end label">Amount Owning</p>
          </div>

          <div
            class="
              col
              relative
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :disable="true"
              :fullWidth="true"
              width="100%"
              :value="item.amount_owning"
            />
            <div class="orange-dot dot"></div>
          </div>
          <div
            class="
              col
              relative
              flex
              align-items-center
              justify-content-center
              disable-input
            "
          >
            <TextField
              :disable="true"
              :fullWidth="true"
              width="100%"
              :value="item.amount_owing_verified"
            />
            <div class="green-dot dot"></div>
          </div>
          <div
            class="col relative flex align-items-center justify-content-center"
          >
            <NumberField
              :fullWidth="true"
              width="100%"
              :value="item.amount_owing_manual"
              @onInput="(e) => getFormData(e, 'amount_owing_manual', i)"
            />
            <div class="blue-dot dot"></div>
          </div>
          <div
            class="col relative flex align-items-center justify-content-center"
          >
            <Dropdown
              class="full-width"
              :items="usedValueOpts"
              optionLabel="name"
              optionValue="value"
              :value="item.amount_owing_used_value"
              @change="(e) => getFormData(e, 'amount_owing_used_value', i)"
              width="100%"
              placeholder="label"
            />
            <div
              :class="{
                'orange-dot':
                  item.amount_owing_used_value === 'application_value',
                'green-dot': item.amount_owing_used_value === 'verified_value',
                'blue-dot': item.amount_owing_used_value === 'manual_value',
              }"
              class="dot dot-position"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Checkbox2 from "@/components/common/Checkbox2";

export default {
  props: ["disable"],
  components: { Checkbox2 },
  data() {
    return {
      details: [],
      selectedCards: [],
      category: {
        id: 1,
      },
      usedValueOpts: [
        {
          name: "App",
          value: "application_value",
        },
        {
          name: "Verified",
          value: "verified_value",
        },
        {
          name: "Manual",
          value: "manual_value",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      setLiability: "applications/setLiability",
      setCreditCardUpdated: "applications/setCreditCardUpdated",
    }),
    selectCards(value, cards) {
      this.selectedCards = !value
        ? this.selectedCards.filter(
            (item) => item.institution != cards.institution
          )
        : [...this.selectedCards, cards];
      this.$emit("cardsSelected", {
        selectedCards: this.selectedCards,
        allCards: this.details,
      });
    },
    setData() {
      this.details = !this.disable
        ? this.liabilityCards.applicant_1
        : this.liabilityCards.applicant_2;
    },
    getFormData(value, field, index) {
      this.details[index][field] = value;
      this.details = [...this.details];

      if (
        field == "amount_owing_manual" &&
        this.details[index]["amount_owing_used_value"] == "manual_value"
      ) {
        this.details[index]["amount_owing_used"] = this.details[index][
          "amount_owing_manual"
        ]
          ? this.details[index]["amount_owing_manual"]
          : 0;
      }

      if (field == "amount_owing_used_value") {
        switch (value) {
          case "application_value":
            this.details[index]["amount_owing_used"] = this.details[index][
              "amount_owing"
            ]
              ? this.details[index]["amount_owing"]
              : 0;
            break;
          case "verified_value":
            this.details[index]["amount_owing_used"] = this.details[index][
              "amount_owing_verified"
            ]
              ? this.details[index]["amount_owing_verified"]
              : 0;
            break;
          case "manual_value":
            this.details[index]["amount_owing_used"] = this.details[index][
              "amount_owing_manual"
            ]
              ? this.details[index]["amount_owing_manual"]
              : 0;
            break;
        }
      }
      if (
        field == "credit_limit_manual" &&
        this.details[index]["credit_limit_used_value"] == "manual_value"
      ) {
        this.details[index]["credit_limit_used"] = this.details[index][
          "credit_limit_manual"
        ]
          ? this.details[index]["credit_limit_manual"]
          : 0;
      }
      if (field == "credit_limit_used_value") {
        switch (value) {
          case "application_value":
            this.details[index]["credit_limit_used"] = this.details[index][
              "credit_limit"
            ]
              ? this.details[index]["credit_limit"]
              : 0;
            break;
          case "verified_value":
            this.details[index]["credit_limit_used"] = this.details[index][
              "credit_limit_verified"
            ]
              ? this.details[index]["credit_limit_verified"]
              : 0;
            break;
          case "manual_value":
            this.details[index]["credit_limit_used"] = this.details[index][
              "credit_limit_manual"
            ]
              ? this.details[index]["credit_limit_manual"]
              : 0;
            break;
        }
      }

      this.setLiability({
        data: this.details,
        type: !this.disable ? "primary" : "secondary",
        liability_type: "cards",
      });
      this.setCreditCardUpdated(this.creditCardUpdated + 1);
    },
  },
  computed: {
    ...mapState({
      liabilityCards: (state) => state.applications.liabilityCards,
      creditCardUpdated: (state) => state.applications.creditCardUpdated,
    }),
  },
  created() {
    this.setData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

.sub-title {
  margin: 0;
  margin-top: 1.25rem;
  background: rgba(2, 0, 56, 0.03);
  padding: 10px;

  p {
    font-family: Gordita-medium-;
    font-size: 18px;
    text-decoration: underline;
    color: $secondary-color;
  }
}

:deep .p-field-checkbox {
  margin-bottom: 0;
}

::v-deep .col {
  padding-left: 0;
}

.relative {
  position: relative;
}

.dot-position {
  right: 45px !important;
}

.dot {
  position: absolute;
  right: 20px;
}

.header {
  ::v-deep .p-col-2 {
    padding-bottom: 0;
  }
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
  padding-bottom: 0;
}

p {
  margin: 0;
}
</style>
