<template>
  <Card>
    <template #content>
      <div class="flex justify-content-between">
        <img src="@/assets/applications.svg" />
        <div class="flex">
          <Datefilter
            class="mr-2 full-width datefilter-component"
            numberOfMonths="2"
            @filter="getFilteredData($event)"
          />
          <FilterComponent @getFilterValue="getFilterValue" class="mr-2 full-width" />
          <span class="p-input-icon-left ml-2 full-width">
            <i class="pi pi-search" />
            <TextField
              width="100%"
              type="text"
              v-model="searchValue"
              @onInput="getSearchValue($event)"
              placeholder="Search"
              :key="searchKey"
            />
          </span>
        </div>
      </div>
      <Spinner v-if="isloading" class="flex justify-content-between mt-3" />
      <DataTable
        class="mt-2 highlight-bg"
        v-else
        :rows="10"
        selectionMode="multiple"
        data-key="id"
        :value="statements.result"
        :rowsPerPageOptions="[10, 20, 30]"
      >
        <template #empty><div class="p-3">No data found.</div></template>

        <Column field="Id" header="Loan ID" :style="{ maxWidth: '14rem' }">
          <template #body="slotProps">
            <a
              class="color-highlight table-column"
              :class="renderClass(slotProps.data)"
              @click="
                goToApp(
                  slotProps.data.Id,
                  slotProps.data.status,
                  slotProps.data.loan_id,
                  slotProps.data.applicant_1_declared,
                  slotProps.data.applicant_2_declared,
                  slotProps.data?.applicants?.length,
                  slotProps.data.createdAt
                )
              "
            >
              {{ slotProps.data.loan_id || "-" }}
            </a>
          </template>
        </Column>
        <Column field="broker_id" header="Broker ID">
          <template #body="slotProps">
            <div class="table-column" :class="renderClass(slotProps.data)">
              <span v-if="slotProps.data.broker_id">{{ slotProps.data.broker_id }}</span
              ><span v-else>-</span>
            </div>
          </template>
        </Column>
        <Column field="applicants" header="Key contact">
          <template #body="slotProps">
            <div
              v-if="slotProps.data.applicants && slotProps.data.applicants.length"
              :key="idx"
              class="table-column capital"
              :class="renderClass(slotProps.data)"
            >
              <!-- {{ slotProps.data.applicants[0].first_name }}
              {{ slotProps.data.applicants[0].middle_name }}
              {{ slotProps.data.applicants[0].last_name }}-->
              {{ applicantName(slotProps.data.applicants) }}
            </div>
            <div class="table-column" :class="renderClass(slotProps.data)" v-else>-</div>
          </template>
        </Column>
        <Column field="loan_id" header="Request">
          <template #body="slotProps">
            <div class="table-column" :class="renderClass(slotProps.data)">-</div>
          </template>
        </Column>
        <Column field="loan_term" header="Term">
          <template #body="slotProps">
            <div class="table-column" :class="renderClass(slotProps.data)">
              {{ slotProps.data.loan_term || "-" }}
            </div>
          </template>
        </Column>
        <Column field="calculated_values" header="Offer">
          <template #body="slotProps">
            <div class="table-column" :class="renderClass(slotProps.data)">
              {{ findLoanAmount(slotProps.data?.calculated_values) }}
              <!-- {{
                slotProps.data?.calculated_values
                  ? "$" +
                    formatCurrency(
                      slotProps.data?.calculated_values[0].loan_amount
                    )
                  : "-"
              }} -->
            </div>
          </template>
        </Column>
        <Column field="status" style="width: 10rem" header="Status">
          <template #body="slotProps">
            <div
              class="table-column"
              v-if="slotProps.data.status"
              :class="renderClass(slotProps.data)"
            >
              {{ slotProps.data.status.replace("_", " ") || "-" }}
            </div>
          </template>
        </Column>
        <Column field="declarations" style="width: 7rem" header="Declarations">
          <template #body="slotProps">
            <div class="table-column" :class="renderClass(slotProps.data)">
              {{
                slotProps.data && slotProps.data.applicants
                  ? appDeclaration(
                      slotProps.data.applicant_1_declared,
                      slotProps.data.applicant_2_declared,
                      slotProps.data.applicants.length
                    )
                  : "-"
              }}
            </div>
          </template>
        </Column>
        <Column field="createdAt" header="Created">
          <template #body="slotProps">
            <div class="table-column" :class="renderClass(slotProps.data)">
              <p v-if="slotProps.data.createdAt">
                {{ formatDate(slotProps.data.createdAt) }}
              </p>
              <p v-else>-</p>
            </div>
          </template>
        </Column>
      </DataTable>
      <Paginator
        class="mt-2"
        :rows="10"
        :totalRecords="statements && statements.totalCount"
        :rowsPerPageOptions="[10, 20, 30]"
        template="RowsPerPageDropdown  CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} of {totalRecords} active applications"
        @page="onChangePage($event)"
      ></Paginator>
    </template>
  </Card>
</template>

<script>
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Datefilter from "./DateFilter.vue";
import FilterComponent from "./Filter.vue";
import { _get } from "../../../services/crudService";
// import { _post } from "../../../services/crudService";
import Paginator from "primevue/paginator";
import { APP_URL } from "../../../../constants";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  name: "ApplicationsList",
  components: {
    Card,
    Paginator,
    DataTable,
    Column,
    Datefilter,
    FilterComponent,
  },
  data() {
    return {
      statements: [],
      searchValue: "",
      isloading: false,
      updateSearch: null,
      searchKey: 0,
    };
  },
  methods: {
    ...mapActions({
      setLoanCreatedAt: "applications/setLoanCreatedAt",
    }),
    findLoanAmount(data) {
      if (data) {
        data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        return "$" + this.formatCurrency(data[0].loan_amount);
      } else return "-";
    },
    renderClass(data) {
      let dec_1 = data.applicant_1_declared;
      let dec_2 = data.applicant_2_declared;
      let applicantCount = data && data.applicants && data.applicants.length;
      let status = data?.status;

      if (status === "draft") {
        return;
      }

      const declaration = this.appDeclaration(dec_1, dec_2, applicantCount);

      if (declaration == "1/1" || declaration == "2/2") {
        return;
      } else {
        return "highlight-bg";
      }
    },
    goToApp(id, status, loanId, dec_1, dec_2, applicantCount, createdAt) {
      // const declaration = this.appDeclaration(dec_1, dec_2, applicantCount);
      this.setLoanCreatedAt(createdAt);
      // if (declaration == "1/1" || declaration == "2/2") {
      //   this.$emit("changes", id);
      // }
      if (id) {
        if (status === "draft") {
          this.$router.push("/servicing-calculator/draft_id/" + id);
        } else {
          // this.setLoanId(loan_id);
          let declaration = false;
          if (applicantCount == 2) {
            if (dec_1 && dec_2) {
              declaration = true;
            } else if (dec_1) {
              declaration = false;
            } else {
              declaration = false;
            }
          } else if (applicantCount == 1) {
            if (dec_1) {
              declaration = true;
            } else {
              declaration = false;
            }
          } else {
            declaration = true;
          }

          this.$emit("changes", {
            id,
            loanId: loanId,
            declaration,
          });
        }
      }
    },
    appDeclaration(dec_1, dec_2, applicantCount) {
      if (applicantCount == 2) {
        if (dec_1 && dec_2) {
          return "2/2";
        } else if (dec_1) {
          return "1/2";
        } else {
          return "0/2";
        }
      } else if (applicantCount == 1) {
        if (dec_1) {
          return "1/1";
        } else {
          return "0/1";
        }
      } else {
        return "0/0";
      }
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatCurrency(val) {
      return val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    async getSearchValue(value) {
      // if (value === "") {
      //   await this.getApp(1, 10);
      //   return;
      // }
      clearTimeout(this.updateSearch);

      if (value === "draft") {
        this.updateSearch = setTimeout(async () => {
          this.isloading = true;
          try {
            const data = {
              name: value,
              role: "admin",
            };
            console.log(data);
            const getDraftApp = await _get(APP_URL + "generate_applications_crms_draft");
            if (getDraftApp) {
              this.statements.result = [];
              getDraftApp.data.map((list) => {
                this.statements.result.push({
                  loan_id: list.Id,
                  Id: list.Id,
                  status: list.status,
                });
              });
              this.isloading = false;
            }
          } catch (err) {
            this.isloading = false;
          }
        }, 1000);
      } else {
        this.updateSearch = setTimeout(async () => {
          if (value !== "") {
            this.isloading = true;
            try {
              // const data = {
              //   name: value,
              //   // role: "admin",
              // };
              const response = await _get(
                APP_URL + `active_applications_crm?pageSize=10&pageIndex=1&name=${value}`
              );
              if (response) {
                this.statements = response?.data || [];
                this.isloading = false;
              }
            } catch (err) {
              this.isloading = false;
            }
          } else {
            await this.getApp(1, 10);
            return;
          }
        }, 1000);
      }
    },
    async getFilteredData(event) {
      if (event) {
        const dateRange = {
          status: [],
          stage: [],
          startDate: event[0] ? moment(event[0]) : "",
          endDate: event[1] ? moment(event[1]) : "",
          role: "admin",
        };
        try {
          this.isloading = true;

          const response = await _get(
            APP_URL +
              `active_applications_crm?pageSize=10&pageIndex=1&from_date=${dateRange.startDate}&to_date=${dateRange.endDate}`
          );
          if (response) {
            this.statements = response.data;
            this.isloading = false;
          }
        } catch (err) {
          this.isloading = false;
        }
      } else {
        this.getApp(1, 10);
      }
    },
    onChangePage(value) {
      this.getApp(value.page + 1, value.rows);
    },
    applicantName(value) {
      if (value) {
        let name;
        value.find((app) => {
          if (app?.applicant_type === "primary") {
            if (app?.preferred_name) {
              if (app?.middle_name) {
                name = `${app.preferred_name} ${app.middle_name} ${app.last_name}`;
              } else {
                name = `${app.preferred_name}  ${app.last_name}`;
              }
            } else if (value.length === 1 || app?.applicant_type === "primary") {
              if (app?.middle_name) {
                name = `${app.first_name} ${app.middle_name} ${app.last_name}`;
              } else {
                name = `${app.first_name}  ${app.last_name}`;
              }
            }
          }
        });
        return name;
      }
    },
    async getFilterValue(value) {
      if (value.from && value.to && value.from < value.to) {
        try {
          this.isloading = true;
          this.searchValue = "";
          this.searchKey += 1;
          const response = await _get(
            APP_URL +
              `active_applications_crm?pageSize=10&pageIndex=1&from_amount=${value.from}&to_amount=${value.to}`
          );

          if (response) {
            this.statements = response.data;
          }
        } catch (err) {
          this.isloading = false;
        }
        let arr1 = [];
        let arr2 = [];
        this.statements.result.map((elem) => {
          const declaration =
            elem &&
            elem.applicants &&
            this.appDeclaration(
              elem.applicant_1_declared,
              elem.applicant_2_declared,
              elem.applicants.length
            );

          if (declaration == "1/1" || declaration == "2/2" || elem.status === "draft") {
            // this.statements.result.splice(index, 1);
            // this.statements.result.unshift(elem);
            arr1.push(elem);
          } else {
            arr2.push(elem);
          }
        });

        this.statements.result = [...arr1, ...arr2];

        this.isloading = false;
      }
    },
    async getApp(page, row) {
      try {
        this.isloading = true;
        const response = await _get(
          APP_URL + `active_applications_crm?pageSize=${row}&pageIndex=${page}`
        );

        const getDraftApp = await _get(APP_URL + "generate_applications_crms_draft");

        if (response) {
          this.statements = response.data;
          if (getDraftApp) {
            getDraftApp.data.map((list) => {
              this.statements.result.push({
                loan_id: list.Id,
                Id: list.Id,
                status: list.status,
              });
            });
          }
        }
      } catch (err) {
        console.log(err);
        this.isloading = false;
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong",
            life: 3000,
          });
        }
      }

      let arr1 = [];
      let arr2 = [];

      this.statements.result.map((elem) => {
        const declaration =
          elem &&
          elem.applicants &&
          this.appDeclaration(
            elem.applicant_1_declared,
            elem.applicant_2_declared,
            elem.applicants.length
          );

        if (declaration == "1/1" || declaration == "2/2" || elem.status === "draft") {
          // this.statements.result.splice(index, 1);
          // this.statements.result.unshift(elem);
          arr1.push(elem);
        } else {
          arr2.push(elem);
        }
      });

      this.statements.result = [...arr1, ...arr2];

      this.isloading = false;
    },
  },
  async created() {
    await this.getApp(1, 10);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.p-card {
  box-shadow: unset !important;
  border-radius: 0.25rem !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
}
::v-deep .p-card-title {
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem !important;
}
::v-deep .p-datatable .p-datatable-thead > tr > th {
  background: $white-color;
  color: rgba(2, 0, 56, 0.3) !important;
  font-size: 1rem;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-datatable .p-datatable-tbody > tr > td {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #020038 !important;
  font-family: Gordita-medium !important;
  border-bottom: 0.063rem solid rgba(2, 0, 56, 0.15);
  padding: 0rem 0 !important;
}

:deep .p-datatable .p-datatable-thead > tr > th {
  padding: 1rem 0 !important;
}

:deep .p-card-content {
  padding: 0px !important;
}

:deep .p-card-body {
  padding: 25px;
}

.color-highlight {
  color: $primary-color !important;
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: unset !important;
  color: $primary-color !important;
  border-color: unset !important;
}
::v-deep .p-link:focus {
  box-shadow: unset !important;
}
::v-deep .p-datepicker table td {
  padding: unset !important;
}
::v-deep .p-paginator .p-paginator-current {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 500;
  font-size: 0.875rem;
}

:deep .p-inputtext {
  padding-left: 2rem;
}

.table-column {
  display: block;
  padding: 1rem 0;
}

.highlight-bg {
  background: $pink !important;
}

p {
  margin: 0;
}
</style>
