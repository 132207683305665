<template>
  <Dialog
    :draggable="false"
    :visible="show"
    :modal="show"
    :style="{ width: '580px' }"
  >
    <template #header>
      <h1 class="modal-title">Re-assign Task</h1>
      <i class="icon pi pi-times" @click="close" style="margin-left: auto" />
    </template>
    <Spinner v-if="isLoading" class="flex justify-content-between" />

    <div v-else class="modal-content" :key="editKey">
      <div>Task type</div>
      <Dropdown
        optionLabel="taskTypes"
        :items="taskTypes"
        width="100%"
        placeholder="-Select-"
        class="mt-2"
        :disable="loadingTaskTypes && true"
        @change="getTaskType"
        :value="formData.taskType"
      />

      <div class="mt-2">Task title</div>
      <TextField
        @onInput="getFormData($event, 'taskTitle')"
        :value="formData.taskTitle"
        width="100%"
        placeholder="Task title"
        class="mt-2"
        v-if="formData.taskTitle"
        :key="refreshKey"
      />
      <div class="flex mt-2">
        <div class="col">
          <div>Assign to</div>
          <Dropdown
            :items="assignTo"
            width="100%"
            placeholder="-Select-"
            class="mt-2"
            @change="getAssignValue"
            :value="formData.assignTo"
          />
        </div>
        <div class="col ml-2">
          <div>{{ formData.assignTo || "-Select-" }}</div>
          <AutoComplete
            forceSelection
            class="mt-2"
            width="100%"
            v-model="selectedUser"
            :suggestions="filteredUserList"
            :disabled="loadingUserList"
            @complete="getUserList($event)"
            placeholder="Enter name"
            field="name"
            :modelValue="selectedUser"
          />

          <!-- <Dropdown
            :items="usersList"
            optionLabel="name"
            :disable="loadingUserList && true"
            width="100%"
            placeholder="-Select-"
            class="mt-2"
            @change="getAssignedToId"
            :value="formData.assignToType"
          /> -->
        </div>
      </div>
      <div class="flex mt-2">
        <div class="col">
          <div>Regarding</div>
          <Dropdown
            width="100%"
            @change="getRegValue"
            placeholder="-Select-"
            :items="regarding"
            class="mt-2"
            :value="formData.regardingTo"
          />
        </div>
        <div class="col ml-2">
          <div>{{ formData.regardingTo }}</div>
          <AutoComplete
            forceSelection
            class="mt-2"
            width="100%"
            v-model="selectedReg"
            :suggestions="filteredRegList"
            @complete="getRegList($event)"
            :placeholder="loanPlaceHolder"
            field="name"
            :modelValue="selectedReg"
            :disabled="loadingRegList"
          />

          <!-- <Dropdown
            :items="regardingList"
            optionLabel="name"
            width="100%"
            :disable="loadingRegList"
            placeholder="-Select-"
            class="mt-2"
            @change="getRegardingToId"
            :value="formData.regardingType"
          /> -->
        </div>
      </div>

      <div class="flex align-items-center mt-2">
        <div class="col">
          <div>Due date</div>
          <DatePicker
            placeholder="DD/MM/YYYY"
            style="width: 100%"
            class="mt-2"
            :minDate="minDate"
            :minYear="minDate.getFullYear()"
            :value="formData.dueDate"
            @change="getFormData($event, 'dueDate')"
          />
        </div>
        <div class="col ml-2 time-input">
          <div>Due time</div>
          <Calendar
            v-model="formData.dueTime"
            :showTime="true"
            :timeOnly="true"
            hourFormat="12"
            class="mt-2 full-width"
            placeholder="Enter time"
            @change="getTime"
          />
        </div>
      </div>
      <div class="mt-2">Description</div>
      <TextArea
        @onInput="getFormData($event, 'description')"
        placeholder="Task description"
        :value="formData.description"
        width="100%"
        class="mt-2"
      />
      <div class="bold-600 mt-2">Activity log</div>
      <div class="flex timestamp mt-2">
        <div class="col col-5">Timestamp</div>
        <div class="col col-7">Action</div>
      </div>
      <div class="divider mt-2"></div>
      <div class="flex mt-2" v-for="(item, i) in logs" :key="i">
        <div class="timestamp col col-5">{{ formatDate(item.createdAt) }}</div>
        <div class="col col-7">
          {{ item.actionDoneByName }} {{ item.action }} task
        </div>
      </div>
      <div @click="showForm" class="mt-2">
        Enter comment<span style="color: red"> * </span>
      </div>
      <TextArea
        width="100%"
        class="mt-2"
        @onInput="getFormData($event, 'comments')"
        placeholder="Enter comments"
        :value="formData.comments"
      />

      <div class="p-field-checkbox flex align-items-center mt-4">
        <input
          :disabled="disabledComplete && ''"
          type="checkbox"
          class="binary-checkbox"
          id="binary"
          v-model="formData.isCompleted"
          :binary="true"
        />
        <label class="label-text" for="binary">Mark task as complete</label>
      </div>
    </div>
    <template #footer>
      <div class="flex align-items-center">
        <Button
          :disabled="isSubmitDisabled()"
          label="Save task"
          type="primary"
          :loading="isloading"
          @click="save"
        />
        <div @click="close" class="ml-2 modal-close-button cursor-pointer">
          Close
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Calendar from "primevue/calendar";
import { _get, _put } from "../../../../../services/crudService";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { APP_URL, SERVER_URL } from "../../../../../../constants";
import moment from "moment";
import AutoComplete from "primevue/autocomplete";

export default {
  name: "EditTask",
  props: ["show", "preSelectReg", "taskId", "portal"],
  components: { Dialog, Calendar, AutoComplete },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      // selectedUser: { required },
      // selectedReg: { required },
      // formData: {
      //   taskTitle: { required },
      //   assignTo: { required },
      //   assignToId: { required },
      //   regardingTo: { required },
      //   regardingToId: { required },
      //   taskType: { required },
      //   description: { required },
      //   dueDate: { required },
      //   dueTime: { required },
      // },
      formData: {
        comments: { required },
      },
    };
  },
  data() {
    return {
      minDate: null,
      category: { id: 1, label: "Mark task as complete", value: false },
      assignTo: ["User", "Customer", "Broker", "Group"],
      regarding: ["Customer", "Broker", "Loan"],
      taskTypes: [],
      usersList: [],
      filteredUserList: [],
      filteredRegList: [],
      regardingList: [],
      refreshKey: 10,
      selectedUser: "",
      selectedReg: "",
      disabledComplete: false,
      time: null,
      appId: "",
      isLoading: false,
      isloading: false,
      loadingUserList: false,
      loadingRegList: false,
      loadingTaskTypes: false,
      formData: {
        taskTitle: "",
        assignTo: "User",
        assignToType: "",
        assignToId: "",
        regardingTo: "Customer",
        regardingType: "",
        regardingToId: "",
        taskType: "",
        task_type_id: "",
        dueDate: "",
        dueTime: "",
        description: "",
        type: "",
        status: "",
        progress_type: "",
        action: "re-assigned",
        application_id: "",
        attachment: "",
        isCompleted: false,
        comments: "",
        crm_status: "",
        portal: this.$props.portal,
      },
      editKey: 0,
      logs: [],
      status: false,
    };
  },
  watch: {
    time() {
      this.formData.dueTime = this.time;
    },
    async taskId() {
      await this.editTask();
    },
    selectedUser(value) {
      if (value && value.Id) {
        this.getAssignedToId(value);
      }
    },
    selectedReg(value) {
      if (value && value.Id) {
        this.getRegardingToId(value);
      }
    },
  },
  computed: {
    loanPlaceHolder() {
      if (this.formData.regardingTo === "Loan") {
        return "Enter Loan ID";
      }

      return "Enter name";
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY hh:mm a");
    },
    isSubmitDisabled() {
      return this.v$.$invalid;
    },

    async getUserList(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredUserList = [...this.usersList];
        } else {
          this.filteredUserList = this.usersList.filter((list) => {
            return list.name.toLowerCase().includes(event.query.toLowerCase());
          });

          if (!this.filteredUserList.length) {
            return (this.v$.$invalid = true);
          }

          this.filteredUserList.length &&
            this.filteredUserList.map((list) => {
              if (list.name !== event.query) {
                this.v$.$invalid = true;
                return;
              } else {
                this.v$.$invalid = false;
                return;
              }
            });
        }
      }, 250);
    },

    async getRegList(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredRegList = [...this.regardingList];
        } else {
          this.filteredRegList = this.regardingList.filter((list) => {
            return list.name
              .toString()
              .toLowerCase()
              .includes(event.query.toLowerCase());
          });

          if (!this.filteredRegList.length) {
            return (this.v$.$invalid = true);
          }

          this.filteredUserList.length &&
            this.filteredUserList.map((list) => {
              if (list.name !== event.query) {
                return (this.v$.$invalid = true);
              }
            });
        }
      }, 250);
    },
    async save() {
      // delete this.formData.crm_tasks_activity_logs

      const data = {
        taskTitle: this.formData.taskTitle,
        assignTo: this.formData.assignTo,
        assignToType: this.formData.assignToType,
        assignToId: this.formData.assignToId,
        regardingTo: this.formData.regardingTo,
        regardingType: this.formData.regardingType,
        regardingToId: this.formData.regardingToId,
        taskType: this.formData.taskType,
        task_type_id: this.formData.task_type_id,
        dueDate: this.formData.dueDate,
        dueTime: this.formData.dueTime,
        description: this.formData.description,
        type: this.formData.type,
        status: this.formData.status,
        progress_type: this.formData.progress_type,
        action: this.formData.action,
        application_id: this.formData.application_id,
        attachment: this.formData.attachment,
        isCompleted: this.formData.isCompleted,
        comments: this.formData.comments,
        crm_status: this.formData.crm_status,
        portal: this.formData.portal || this.$props.portal,
      };

      if (typeof data.regardingType === "object") {
        data.regardingType = data.regardingType.name.toString();
      }

      if (typeof data.assignToType === "object") {
        data.assignToType = data.assignToType.name;
      }

      if (typeof data.taskType === "object") {
        data.taskType = data.taskType.taskTypes;
      }

      if (data.attachment === "" || typeof data.attachment === "string") {
        data.attachment = false;
      }

      try {
        this.isloading = true;

        const response = await _put(
          SERVER_URL + "update_task?id=" + this.taskId,
          data
        );
        if (response) {
          this.$emit("close");
          this.$emit("save");

          this.isloading = false;

          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Task updated successfully",
            life: 3000,
          });
        }
      } catch (err) {
        this.isloading = false;

        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      }
    },
    getFormData(value, fieldName) {
      if (fieldName === "dueDate") {
        if (
          this.formData.dueDate === "" ||
          this.formData.dueDate === "Invalid date" ||
          !this.formData.dueDate
        ) {
          this.formData[fieldName] = value;
          this.formData = { ...this.formData };
        } else {
          this.formData[fieldName] = value;
          this.formData = { ...this.formData };
        }
      } else {
        this.formData[fieldName] = value;
        this.formData = { ...this.formData };
      }
    },
    updateRegardingOpt(assignTo) {
      if (assignTo === "Customer") {
        this.regarding = ["Loan"];
        this.formData.regardingTo = "Loan";
      } else if (assignTo === "Broker") {
        this.regarding = ["Loan"];
        this.formData.regardingTo = "Loan";
      } else {
        this.regarding = ["Customer", "Broker", "Loan"];
        // this.formData.regardingTo = "Customer"
      }
    },
    async getAssignedToId(value) {
      // this.formData.status = "Open";
      this.formData.assignToType = value.firstName;
      this.formData.assignToId = value.Id;

      if (value.active_application_id) {
        this.appId = value.active_application_id;
      }

      if (
        this.formData.regardingTo === "Loan" &&
        this.appId &&
        this.formData.assignTo === "Customer"
      ) {
        const appList = await _get(
          APP_URL + `application_by_customer_crm?application_id=${this.appId}`
        );
        this.regardingList = appList.data.result;
        this.regardingList.map((obj) => {
          obj.name = obj.loan_id;
        });
      } else if (
        this.formData.regardingTo === "Loan" &&
        this.formData.assignToId &&
        this.formData.assignTo === "Broker"
      ) {
        const appList = await _get(
          APP_URL + `application_by_broker_crm?id=${this.formData.assignToId}`
        );
        this.regardingList = appList.data.result;
        this.regardingList.map((obj) => {
          obj.name = obj.loan_id;
        });
      } else if (value === "Loan" && this.formData.assignToId) {
        const appList = await _get(APP_URL + "active_applications_list");
        this.regardingList = appList.data.result;
        this.regardingList.map((obj) => {
          obj.name = obj.loan_id;
        });
      }
    },
    getRegardingToId(value) {
      if (this.formData.regardingTo === "Loan") {
        this.formData.regardingType = value.Id;
        this.formData.regardingToId = value.Id;
        this.formData.application_id = value.Id;
      } else {
        this.formData.regardingType = value.firstName + " " + value.lastName;
        this.formData.regardingToId = value.Id;
        this.formData.application_id = value.Id;
      }
    },
    getTaskType(value) {
      this.formData.taskType = value.taskTypes;
      this.formData.taskTitle = value.taskTypes;
      this.refreshKey++;
      this.formData.task_type_id = value.Id;
      this.formData.attachment = value.attachmentRequired || false;
    },
    getStatus(value) {
      this.status = value;
      this.formData.status = value ? "Completed" : "Open";
    },
    async getAssignValue(value) {
      this.formData.assignTo = value;
      this.selectedUser = "";
      this.loadingUserList = true;
      this.loadingTaskTypes = true;
      this.loadingRegList = true;
      this.selectedReg = "";

      if (
        (this.formData.status === "Open" || this.formData.status === "open") &&
        this.formData.assignTo !== "User" &&
        this.formData.assignTo !== "Group"
      ) {
        this.disabledComplete = true;
      } else {
        this.disabledComplete = false;
      }
      this.updateRegardingOpt(value);

      if (
        value === "User" ||
        (value === "Group" && this.regardingTo === "Loan")
      ) {
        const appList = await _get(APP_URL + "active_applications_list");
        this.regardingList = appList.data.result;
        this.regardingList.map((obj) => {
          obj.name = obj.loan_id;
        });
        this.loadingRegList = false;
      }

      // if (value === "User") {
      //   const result = await _get(SERVER_URL + "get_task_types?type=user");
      //   this.taskTypes = result.data.result;
      //   this.loadingTaskTypes = false;
      // } else {
      //   const result = await _get(SERVER_URL + "get_task_types?type=group");
      //   this.taskTypes = result.data.result;
      //   this.loadingTaskTypes = false;
      // }

      if (value === "User") {
        const userResponse = await _get(SERVER_URL + "crm_user");
        this.usersList = userResponse.data.result;
        this.usersList.map((obj) => {
          obj.name = `${obj?.firstName} ${obj.lastName}`;
        });
        this.loadingUserList = false;
      } else if (value === "Customer") {
        const cusotmerList = await _get(SERVER_URL + "get_customers");
        this.usersList = cusotmerList.data.result;
        this.usersList.map((obj) => {
          obj.name = `${obj.firstName} ${obj.lastName}`;
        });
        this.loadingUserList = false;
        this.loadingRegList = false;
      } else if (value === "Broker") {
        const brokersList = await _get(SERVER_URL + "get_broker_crm");
        this.usersList = brokersList.data.result;
        this.usersList.map((obj) => {
          obj.name = `${obj.firstName} ${obj.lastName}`;
        });
        this.loadingUserList = false;
        this.loadingRegList = false;
      } else if (value === "Group") {
        const groupList = await _get(SERVER_URL + "get-group");
        this.usersList = groupList.data.result;
        this.usersList.map((obj) => {
          obj.name = obj.title;
        });
        this.loadingUserList = false;
        this.loadingRegList = false;
      }
    },

    async getRegValue(value) {
      this.formData.regardingTo = value;
      this.loadingRegList = true;
      this.selectedReg = "";
      this.loanPlaceHolder;

      if (
        value === "Loan" &&
        this.appId &&
        this.formData.assignTo === "Customer"
      ) {
        const appList = await _get(
          APP_URL + `application_by_customer_crm?application_id=${this.appId}`
        );
        this.regardingList = appList.data.result;
        this.regardingList.map((obj) => {
          obj.name = obj.loan_id;
        });
        this.loadingRegList = false;
      } else if (
        value === "Loan" &&
        this.formData.assignToId &&
        this.formData.assignTo === "Broker"
      ) {
        const appList = await _get(
          APP_URL + `application_by_broker_crm?id=${this.formData.assignToId}`
        );
        this.regardingList = appList.data.result;
        this.regardingList.map((obj) => {
          obj.name = obj.loan_id;
        });
        this.loadingRegList = false;
      } else if (value === "Loan" && this.formData.assignToId) {
        const appList = await _get(APP_URL + "active_applications_list");
        this.regardingList = appList.data.result;
        this.regardingList.map((obj) => {
          obj.name = obj.loan_id;
        });
        this.loadingRegList = false;
      }

      if (value === "Customer") {
        const cusotmerList = await _get(SERVER_URL + "get_customers");
        this.regardingList = cusotmerList.data.result;
        this.regardingList.map((obj) => {
          obj.name = `${obj.firstName} ${obj.lastName}`;
        });
        this.loadingRegList = false;
      } else if (value === "Broker") {
        const brokersList = await _get(SERVER_URL + "get_broker_crm");
        if (brokersList) {
          this.regardingList = brokersList.data.result;
          this.regardingList.map((obj) => {
            obj.name = `${obj.firstName} ${obj.lastName}`;
          });
          this.loadingRegList = false;
        }
      }
    },
    async editTask() {
      this.isLoading = true;
      if (this.$props.taskId) {
        const result = await _get(SERVER_URL + "task_by_id?Id=" + this.taskId);

        this.formData.taskTitle = result.data.taskTitle;
        this.formData.description = result.data.description;
        this.formData.assignTo = result.data.assignTo;
        this.formData.assignToType = result.data.assignToType;
        this.formData.assignToId = result.data.assignToId;
        this.formData.regardingTo = result.data.regardingTo;
        this.formData.regardingType = result.data.regardingType;
        this.formData.regardingToId = result.data.regardingToId;
        this.formData.type = result.data.type;
        this.formData.task_type_id = result.data.task_type_id;
        this.formData.taskType = result.data.taskType;
        this.formData.application_id = result.data.application_id;
        this.formData.comments = result.data.comments;
        this.formData.progress_type = result.data.progress_type;
        this.formData.crm_status = result.data.crm_status;
        // this.time = moment(result.data.dueTime).format("hh:mm a")
        this.formData.dueTime = result.data.dueTime;
        this.formData.dueDate = result.data.dueDate;
        this.formData.status = result.data.status;
        this.formData.isCompleted =
          result.data.status === "completed" ? true : false;
        this.logs = result.data.crm_tasks_activity_logs;
        // this.status = result.data.status === "Open" ? false : true
        if (this.formData.assignTo === "Customer") {
          this.appId = this.formData.regardingToId;
        }

        // Set assigned dropdown

        if (this.formData.assignTo) {
          await this.getAssignValue(this.formData.assignTo);
          this.formData.assignToType = this.usersList.find(
            (obj) => obj.Id === this.formData.assignToId
          );

          if (this.formData.assignToId) {
            if (
              this.formData?.assignToType?.firstName &&
              this.formData.assignToType?.lastName
            ) {
              this.usersList.find((list) => {
                if (this.formData.assignToId === list.Id) {
                  this.formData.assignToType[
                    "name"
                  ] = `${this.formData?.assignToType?.firstName} ${this.formData?.assignToType?.lastName}`;
                }
              });
            }
          }
        }

        // Set regarding dropdown
        await this.getRegValue(this.formData.regardingTo);
        this.formData.regardingType = this.regardingList.find(
          (obj) => obj.Id === this.formData.regardingToId
        );
        // this.formData.regardingType[
        //   "name"
        // ] = `${this.formData.regardingType.firstName} ${this.formData.regardingType.lastName}`

        //task type drop down set

        if (this.formData.taskType) {
          if (this.formData.assignTo === "User") {
            const resultData = await _get(
              SERVER_URL + "get_task_types?type=user"
            );
            this.taskTypes = resultData.data.result;
            this.formData.taskType = this.taskTypes.find(
              (obj) => obj.Id === this.formData.task_type_id
            );
          } else {
            const resultData = await _get(
              SERVER_URL + "get_task_types?type=group"
            );
            this.taskTypes = resultData.data.result;
            this.formData.taskType = this.taskTypes.find(
              (obj) => obj.Id === this.formData.task_type_id
            );
          }
        }

        this.isLoading = false;
        this.selectedReg = this.formData.regardingType;

        this.selectedUser = this.formData.assignToType;

        this.editKey += 1;

        if (
          (this.formData.status === "Open" ||
            this.formData.status === "open") &&
          this.formData.assignTo !== "User" &&
          this.formData.assignTo !== "Group"
        ) {
          this.disabledComplete = true;
        } else {
          this.disabledComplete = false;
        }
      }
    },
  },
  mounted() {},
  async created() {
    this.minDate = new Date();

    await this.editTask();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
.col {
  padding: unset;
}
::v-deep .datepicker-layout .p-inputtext {
  border-left: 1px solid rgba(96, 92, 204, 0.3) !important;
  margin-left: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.timestamp {
  color: rgba(2, 0, 56, 0.3);
  font-weight: 600;
  font-size: 14px;
}
.divider {
  border: 1px solid rgba(2, 0, 56, 0.15);
}

.binary-checkbox {
  width: 18px !important;
  height: 18px !important;
}

:deep .p-autocomplete-input,
.p-autocomplete {
  width: 100% !important;
}

.label-text {
  font-size: 1rem;
}
</style>
