<template>
  <div>
    <div class="sub-title">
      <p>Savings</p>
    </div>

    <div class="form-inputs mt-4">
      <div class="p-grid align-items-center header">
        <p class="col label"></p>
        <p class="col label">Application Value</p>
        <p class="col label">Verified Value</p>
        <p class="col label">Manual Value</p>
        <p class="col label">Used Value</p>
      </div>

      <!-- Col-1 -->
      <div v-for="(item, i) in details" :key="i">
        <div v-if="item" class="p-grid align-items-center">
          <div class="col flex">
            <Checkbox2
              :id="item"
              :name="item.institution"
              :value="item"
              @getInputValue="(e) => selectSavings(e, item)"
              class="p-col-4"
            />
            <p class="p-col-8 flex justify-content-end label">
              {{ item.institution }}
            </p>
          </div>

          <div class="col relative flex align-items-center justify-content-center">
            <NumberField
              prefix="$"
              :disable="true"
              :value="item.amount"
              :fullWidth="true"
              width="100%"
              placeholder="label"
            />
            <div class="orange-dot dot"></div>
          </div>
          <div class="col relative flex align-items-center justify-content-center">
            <NumberField
              prefix="$"
              :disable="true"
              :value="item.amount_verified"
              :fullWidth="true"
              width="100%"
              placeholder="label"
            />
            <div class="green-dot dot"></div>
          </div>
          <div class="col relative flex align-items-center justify-content-center">
            <NumberField
              :fullWidth="true"
              prefix="$"
              type="number"
              :value="item.amount_manual"
              @onInput="(e) => getFormData(e, 'amount_manual', i)"
              width="100%"
            />
            <div class="blue-dot dot"></div>
          </div>
          <div class="col relative flex align-items-center justify-content-center">
            <Dropdown
              class="full-width"
              :items="usedValueOpts"
              optionLabel="name"
              optionValue="value"
              :value="item.amount_used_value"
              @change="(e) => getFormData(e, 'amount_used_value', i)"
              width="100%"
              placeholder="label"
            />
            <div
              :class="{
                'orange-dot': item.amount_used_value === 'application_value',
                'green-dot': item.amount_used_value === 'verified_value',
                'blue-dot': item.amount_used_value === 'manual_value',
              }"
              class="dot dot-position"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Checkbox2 from "@/components/common/Checkbox2";

export default {
  props: ["disable"],
  components: { Checkbox2 },
  data() {
    return {
      details: [],
      usedValueOpts: [
        {
          name: "App",
          value: "application_value",
        },
        {
          name: "Verified",
          value: "verified_value",
        },
        {
          name: "Manual",
          value: "manual_value",
        },
      ],
      selectedSavings: [],
    };
  },
  methods: {
    ...mapActions({
      setAssets: "applications/setAssets",
      setSavingsUpdated: "applications/setSavingsUpdated",
    }),
    selectSavings(value, savings) {
      this.selectedSavings = !value
        ? this.selectedSavings.filter((item) => item.institution != savings.institution)
        : [...this.selectedSavings, savings];
      this.$emit("savingsSelected", {
        selectedSavings: this.selectedSavings,
        allSavings: this.details,
      });
    },
    setData() {
      this.details = !this.disable
        ? this.assetSavings.applicant_1
        : this.assetSavings.applicant_2;
    },
    getFormData(value, field, index) {
      this.details[index][field] = value;
      this.details = [...this.details];

      if (
        field == "amount_manual" &&
        this.details[index]["amount_used_value"] == "manual_value"
      ) {
        this.details[index]["amount_used"] = this.details[index]["amount_manual"]
          ? this.details[index]["amount_manual"]
          : 0;
      }

      if (field == "amount_used_value") {
        switch (value) {
          case "application_value":
            this.details[index]["amount_used"] = this.details[index]["amount"]
              ? this.details[index]["amount"]
              : 0;
            break;
          case "verified_value":
            this.details[index]["amount_used"] = this.details[index]["amount_verified"]
              ? this.details[index]["amount_verified"]
              : 0;
            break;
          case "manual_value":
            this.details[index]["amount_used"] = this.details[index]["amount_manual"]
              ? this.details[index]["amount_manual"]
              : 0;
            break;
        }
      }

      this.setAssets({
        data: this.details,
        type: !this.disable ? "primary" : "secondary",
        asset_type: "savings",
      });
      this.setSavingsUpdated(this.savingsUpdated + 1);
    },
  },
  computed: {
    ...mapState({
      assetSavings: (state) => state.applications.assetSavings,
      savingsUpdated: (state) => state.applications.savingsUpdated,
    }),
  },
  created() {
    this.setData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

.sub-title {
  margin: 0;
  margin-top: 1.25rem;
  background: rgba(2, 0, 56, 0.03);
  padding: 10px;

  p {
    font-family: Gordita-medium-;
    font-size: 18px;
    text-decoration: underline;
    color: $secondary-color;
  }
}

:deep .p-field-checkbox {
  margin-bottom: 0;
}

::v-deep .col {
  padding-left: 0;
}

.relative {
  position: relative;
}

.dot-position {
  right: 45px !important;
}

.dot {
  position: absolute;
  right: 20px;
}

.header {
  ::v-deep .p-col-2 {
    padding-bottom: 0;
  }
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
  padding-bottom: 0;
}

p {
  margin: 0;
}
</style>
