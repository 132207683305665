<template>
  <div>
    <div class="title">Outgoing Properties</div>
    <div v-for="(item, i) in sellingProperty" :key="i">
      <div class="content">
        <!-- <Checkbox name="address" :category="category" /> -->
        <label>{{ formatAddress(item) }}</label>

        <div class="form-inputs mt-4">
          <div class="p-grid align-items-center header">
            <p class="p-col-2"></p>
            <p class="p-col-2 label">App Value</p>
            <p class="p-col-2 label">AVM Value</p>
            <p class="p-col-2 label">FV Value</p>
            <p class="p-col-2 label">COS/Manual Value</p>
            <p class="p-col-2 label">Estimated price/ property type</p>
          </div>

          <div class="p-grid align-items-center">
            <p class="p-col-2 flex justify-content-end label">
              {{ item.property_sold == "sold" ? `Sale price` : `Estimated Price` }}
            </p>
            <div
              class="p-col-2 relative flex align-items-center justify-content-center disable-input"
            >
              <NumberField
                prefix="$"
                :disable="true"
                :fullWidth="true"
                width="100%"
                placeholder="label"
                :value="
                  item.property_sold == 'sold' ? item.sale_price : item.estimated_price
                "
              />
              <div class="orange-dot dot"></div>
            </div>
            <div
              class="p-col-2 relative flex align-items-center justify-content-center disable-input"
            >
              <NumberField
                prefix="$"
                :disable="true"
                :fullWidth="true"
                width="100%"
                placeholder="label"
                :value="item.purchase_price_avm"
              />
              <div class="green-dot dot"></div>
            </div>
            <div class="p-col-2 relative flex align-items-center justify-content-center">
              <NumberField
                :fullWidth="true"
                prefix="$"
                width="100%"
                placeholder="label"
                :value="item.purchase_price_fv"
                type="number"
                @onInput="(e) => getFormData(e, 'purchase_price_fv', i)"
              />
              <div class="green-dot dot"></div>
            </div>
            <div class="p-col-2 relative flex align-items-center justify-content-center">
              <NumberField
                prefix="$"
                :fullWidth="true"
                width="100%"
                :value="item.purchase_price_cos"
                type="number"
                @onInput="(e) => getFormData(e, 'purchase_price_cos', i)"
              />
              <div class="blue-dot dot"></div>
            </div>
            <div class="p-col-2 relative flex align-items-center justify-content-center">
              <Dropdown
                class="full-width"
                :items="usedValueOpts"
                optionLabel="name"
                optionValue="value"
                :value="item.purchase_price_used_value"
                width="100%"
                @change="(e) => getFormData(e, 'purchase_price_used_value', i)"
              />
              <div
                :class="{
                  'orange-dot': item.purchase_price_used_value === 'application_value',
                  'green-dot': item.purchase_price_used_value === 'avm_value',
                  'green-dot': item.purchase_price_used_value === 'fv_value',
                  'blue-dot': item.purchase_price_used_value === 'cos_value',
                }"
                class="dot dot-position"
              ></div>
            </div>
          </div>

          <div class="p-grid align-items-center">
            <p class="p-col-2 flex justify-content-end label">Property Type</p>
            <div
              class="p-col-2 relative flex align-items-center justify-content-center disable-input"
            >
              <TextField :disable="true" :value="item.type" width="100%" />
              <div class="orange-dot dot"></div>
            </div>
            <div
              class="p-col-2 relative flex align-items-center justify-content-center disable-input"
            >
              <TextField
                :disable="true"
                :fullWidth="true"
                width="100%"
                :value="item.type_avm"
              />
              <div class="green-dot dot"></div>
            </div>
            <div class="p-col-2 relative flex align-items-center justify-content-center">
              <Dropdown
                :value="item.type_fv"
                class="full-width"
                width="100%"
                :items="propertyTypes"
                optionLabel="title"
                optionValue="value"
                @change="(e) => getFormData(e, 'type_fv', i)"
              />
              <div class="green-dot dot dot-position"></div>
            </div>
            <div class="p-col-2 relative flex align-items-center justify-content-center">
              <Dropdown
                :value="item.type_cos"
                class="full-width"
                width="100%"
                :items="propertyTypes"
                optionLabel="title"
                optionValue="value"
                @change="(e) => getFormData(e, 'type_cos', i)"
              />
              <div class="blue-dot dot dot-position"></div>
            </div>
            <div class="p-col-2 relative flex align-items-center justify-content-center">
              <Dropdown
                class="full-width"
                :items="usedValueOpts"
                width="100%"
                optionLabel="name"
                optionValue="value"
                :value="item.type_used_value"
                @change="(e) => getFormData(e, 'type_used_value', i)"
              />
              <div
                :class="{
                  'orange-dot': item.type_used_value === 'application_value',
                  'green-dot': item.type_used_value === 'avm_value',
                  'green-dot': item.type_used_value === 'fv_value',
                  'blue-dot': item.type_used_value === 'cos_value',
                }"
                class="dot dot-position"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      property: [],
      usedValueOpts: [
        {
          name: "App",
          value: "application_value",
        },
        {
          name: "AVM",
          value: "avm_value",
        },
        {
          name: "FV",
          value: "fv_value",
        },
        {
          name: "COS",
          value: "cos_value",
        },
      ],
      propertyTypes: [
        {
          title: "Residential",
          value: "residential",
        },
        {
          title: "Rural Residential",
          value: "rural_residential",
        },
        {
          title: "Off the plan residential",
          value: "off_plan_residential",
        },
        {
          title: "Commercial",
          value: "commercial",
        },
        {
          title: "Vacant Land",
          value: "vacant_land",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      setSellingProperty: "applications/setSellingProperty",
      setSellingPropertyUpdated: "applications/setSellingPropertyUpdated",
    }),
    formatAddress({ add_1, add_2, city, state, country, postal_code }) {
      let fullAddress = "";
      fullAddress = add_1 ? fullAddress + add_1 + " " : fullAddress + "";
      fullAddress = add_2 ? fullAddress + add_2 + " " : fullAddress + "";
      fullAddress = city ? fullAddress + city + " " : fullAddress + "";
      fullAddress = state ? fullAddress + state.toUpperCase() + " " : fullAddress + "";
      fullAddress = country ? fullAddress + country + " " : fullAddress + "";
      fullAddress = postal_code ? fullAddress + postal_code : fullAddress + "";
      return fullAddress;
    },
    getFormData(value, field, property) {
      this.property[property][field] = value;
      this.property = [...this.property];
      //Set based on selected used value
      if (
        field == "purchase_price_cos" &&
        this.property[property].purchase_price_used_value == "cos_value"
      ) {
        this.property[property]["purchase_price_used"] = this.property[property][
          "purchase_price_cos"
        ]
          ? Number(this.property[property]["purchase_price_cos"])
          : 0;
      }
      //Set based on selected used value
      if (field == "purchase_price_used_value") {
        switch (value) {
          case "application_value":
            this.property[property]["purchase_price_used"] = this.property[property][
              "sale_price"
            ]
              ? Number(this.property[property]["sale_price"])
              : 0;
            break;
          case "avm_value":
            this.property[property]["purchase_price_used"] = this.property[property][
              "purchase_price_avm"
            ]
              ? Number(this.property[property]["purchase_price_avm"])
              : 0;
            break;
          case "fv_value":
            this.property[property]["purchase_price_used"] = this.property[property][
              "purchase_price_fv"
            ]
              ? Number(this.property[property]["purchase_price_fv"])
              : 0;
            break;
          case "cos_value":
            this.property[property]["purchase_price_used"] = this.property[property][
              "purchase_price_cos"
            ]
              ? Number(this.property[property]["purchase_price_cos"])
              : 0;
            break;
        }
      }
      //Set based on selected used value for type
      if (field == "type_cos" && this.property[property].type_used_value == "cos_value") {
        this.property[property]["type_used"] = this.property[property]["type_cos"]
          ? this.property[property]["type_cos"]
          : "";
      }
      if (field == "type_fv" && this.property[property].type_used_value == "fv_value") {
        this.property[property]["type_used"] = this.property[property]["type_fv"]
          ? this.property[property]["type_fv"]
          : "";
      }
      if (field == "type_used_value") {
        switch (value) {
          case "application_value":
            this.property[property]["type_used"] = this.property[property]["type"]
              ? this.property[property]["type"]
              : "";
            break;
          case "avm_value":
            this.property[property]["type_used"] = this.property[property]["type_avm"]
              ? this.property[property]["type_avm"]
              : "";
            break;
          case "fv_value":
            this.property[property]["type_used"] = this.property[property]["type_fv"]
              ? this.property[property]["type_fv"]
              : "";
            break;
          case "cos_value":
            this.property[property]["type_used"] = this.property[property]["type_cos"]
              ? this.property[property]["type_cos"]
              : "";
            break;
        }
      }
      this.setSellingProperty(this.property);
      this.setSellingPropertyUpdated(this.sellingPropertyUpdated + 1);
    },
  },
  created() {
    this.property = this.sellingProperty;
  },
  computed: {
    ...mapGetters({ sellingProperty: "applications/sellingProperty" }),
    ...mapState({
      sellingPropertyUpdated: (state) => state.applications.sellingPropertyUpdated,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

p {
  margin: 0;
}

:deep label {
  font-family: Gordita-medium-;
  font-size: 1.125rem;
  text-decoration: underline;
  color: $secondary-color;
}

::v-deep .p-col-2 {
  padding-left: 0;
}

.relative {
  position: relative;
}

.dot-position {
  right: 45px !important;
}

.dot {
  position: absolute;
  right: 20px;
}

.header {
  ::v-deep .p-col-2 {
    padding-bottom: 0;
  }
}

.label {
  color: $secondary-color;
  font-size: 0.875rem;
  font-family: Gordita-medium;
  margin: 0;
}

.title {
  font-size: 1.25rem;
  color: $secondary-color;
  font-family: Gordita-bold;
  padding: 10px 0;
  border-bottom: 1px solid #02003826 !important;
  margin-bottom: 2rem;
}
</style>
